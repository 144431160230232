/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh-green': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M14.573 11.442a7.119 7.119 0 0 1-6.3 3.798 7.115 7.115 0 0 1-6.013-3.307" _stroke="#11AB99" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/><path pid="1" d="M5.322 11.732L1.155 8.12.109 13.535l5.213-1.803z" _fill="#11AB99"/><path pid="2" d="M1.996 4.76A7.119 7.119 0 0 1 8.276 1c2.529 0 4.75 1.319 6.013 3.307" _stroke="#11AB99" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/><path pid="3" d="M11.227 4.508l4.168 3.612 1.045-5.414-5.213 1.802z" _fill="#11AB99"/>'
  }
})
