<template>
  <div class="stats__types" :class="{ 'stat-loading': questionLoading }">
    <div
      class="stats__type"
      @click="handleSetType('group')"
      :class="{ 'stats__type--active': answerType == 'group' }"
    >
      <svgicon name="pie-chart" height="22px" :fill="false" :original="false"></svgicon>
      <span>
        {{ $t('group_statistics.group_statistics') }}
      </span>
    </div>
    <div
      class="stats__type"
      @click="handleSetType('individual')"
      :class="{ 'stats__type--active': answerType == 'individual' }"
    >
      <svgicon name="stats" :fill="false" height="22px" :original="false"></svgicon>
      <span>
        {{ $t('group_statistics.individual_answers') }}
      </span>
    </div>
    <div
      class="stats__type"
      @click="handleSetType('inTime')"
      :class="{ 'stats__type--active': answerType == 'inTime' }"
    >
      <svgicon name="line-chart" :fill="false" height="22px" :original="false"></svgicon>
      <span>
        {{ $t('statistics_in_time.statistics_in_time') }}
      </span>
    </div>
    <div
      class="stats__type"
      @click="handleSetType('groupMessages')"
      :class="{ 'stats__type--active': answerType == 'groupMessages' }"
    >
      <svgicon name="envelope" :fill="false" height="22px" :original="false"></svgicon>
      <span>
        {{ $t('questionnaire_messages.for_group') }}
      </span>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'StatTypes',
    props: ['questionLoading'],
    computed: {
      ...mapGetters('stats', ['answerType']),
    },
    methods: {
      ...mapActions('stats', ['setType']),
      handleSetType(type) {
        this.$emit('expandAnswer');
        this.setType(type);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .stats {
    &__types {
      justify-content: space-between;
      max-height: 480px;
      text-align: center;
      &.stat-loading {
        cursor: not-allowed;
        .stats__type {
          pointer-events: none;
        }
      }
    }

    &__type {
      width: 94px;
      height: 112px;
      padding: 20px;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:first-of-type) {
        margin-top: 5px;
      }

      .svg--icon {
        stroke: $violet-5;
      }

      span {
        font-family: Roboto-Medium;
        font-size: 12px;
        color: $violet-5;
        margin-top: 15px;
      }

      &--active {
        background-color: $violet-5;
        .svg--icon {
          stroke: #fff;
        }
        span {
          color: #fff;
        }

        &:hover {
          cursor: pointer;
          background-color: #fff;
          .svg--icon {
            stroke: $violet-5;
          }
          span {
            color: $violet-5;
          }
        }
      }

      &:hover {
        background-color: $violet-5;
        cursor: pointer;
        .svg--icon {
          stroke: #fff;
        }
        span {
          color: #fff;
        }
      }
    }
  }
</style>
