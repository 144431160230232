/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'student': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<g data-name="Group 1960"><g data-name="Group 1541"><circle pid="0" data-name="Ellipse 20" cx="16.5" cy="16.5" r="16.5" _fill="#bababa"/></g><text transform="translate(16.723 21.92)" _fill="#fff" font-size="16" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-9.873" y="0">AJ</tspan></text></g>'
  }
})
