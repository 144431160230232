<template>
  <div class="method-answer__container">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="['method-answer', { 'method-answer--selected': option.value === memberAnswer }]"
    >
      <template-color :option="option" v-if="answerType === 'color' && option.show"></template-color>
      <template-image :option="option" v-else-if="answerType === 'img' && option.show"></template-image>
      <template-text v-else :option="option"></template-text>
    </div>
  </div>
</template>

<script>
import TemplateText from '@/components/groups/member/answer-templates/TemplateText.vue';
import TemplateColor from '@/components/groups/member/answer-templates/TemplateColor.vue';
import TemplateImage from '@/components/groups/member/answer-templates/TemplateImage.vue';

export default {
  name: 'TemplateYesNo',
  props: ['question', 'memberAnswer'],
  components: {
    TemplateText,
    TemplateColor,
    TemplateImage,
  },
  computed: {
    options() {
      const {
        answerOptions: { options },
      } = this.question;
      const addition = [
        {
          show: null,
          value: this.$t("member_did_not_reflect"),
        },
        {
          show: null,
          value: this.$t("member_skipped_alt"),
        },
      ];
      return [...options, ...addition];
    },
    answerType() {
      const {
        answerOptions: { type },
      } = this.question;
      return type;
    },
  },
};
</script>
<style lang="scss">
.textTypeAnswerMethod{
  .method-answer{
    margin-bottom: 5px;
    &--selected {
      padding: 0;
    }
    &__value {
      display: block;
      white-space: pre-wrap;
      text-align: left;
      word-break: break-word;
    }
  }
}
.method-answer {
  margin-left: 20px;
  &--selected {
    padding: 4px;

    .method-answer {
      &__value {
        color: $green-3;
        font-family: Roboto-Medium;
      }

      &__image,
      &__color {
        border: 2px solid $green-3;
        border-radius: 4px;
      }

      &__smileys {
        &__icon {
          height: 34px;
          width: 34px;
          padding: 4px;
          border: 1px solid $green-3;
          border-radius: 50%;
        }
      }
    }
  }

  &__container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &.textTypeAnswerMethod{
      flex-direction: column;
      align-items: flex-start;

    }
  }

  &__image {
    &__img {
      height: 56px;
      width: 56px;
      border-radius: 4px;
    }
  }

  &__color {
    &__bg {
      height: 56px;
      width: 56px;
      border-radius: 3px;
    }
  }

  &__image,
  &__color {
    height: 100px;
    width: 84px;
    padding: 6px;
    background-color: $grey-1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .method-answer {
      &__value {
        margin-top: 10px;
      }
    }
  }

  &__value {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: $grey-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
}
</style>
