<template>
  <div class="schedule-steps-container flex-global">
    <div
      v-for="(step, i) in steps"
      :key="i"
      :class="['schedule-step', { active: i === currentStep, previous: i <= currentStep - 1 }]"
      :style="{ zIndex: steps.length - i }"
    >
      {{ step }}
    </div>
  </div>
</template>
<script>
  export default {
    name: 'OptionsScheduleSteps',
    props: ['steps', 'currentStep'],
  };
</script>

<style lang="scss" scoped>
  .schedule-steps-container {
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 30px;
    width: fit-content;
    .schedule-step {
      margin-right: -20px;
      padding: 4px 30px;
      border: 1px solid $green-3;
      border-radius: 13px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: #fff;
      color: $grey-5;
      &.previous {
        background-color: $green-8;
        color: #fff;
      }
      &.active {
        background-color: $green-3;
        color: #fff;
      }
    }
  }
</style>
