/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-example': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<circle pid="0" cx="17" cy="17" r="17" _fill="#EFA620" fill-opacity=".25"/><path pid="1" d="M11.184 6h11.771v4.349H11.184a2.174 2.174 0 0 1 0-4.349v0z" _stroke="#919396" stroke-linejoin="round"/><path pid="2" d="M22.956 10.349H11.184a2.089 2.089 0 0 1-2.182-2.175v17.65a2.087 2.087 0 0 0 2.182 2.174H24.43v-17.65h-1.473z" _stroke="#919396" stroke-linejoin="round"/><path pid="3" d="M16.346 21.212c.01-.538.082-.97.219-1.299.137-.328.43-.713.882-1.155.45-.447.74-.761.868-.944.132-.186.232-.39.3-.608a2.44 2.44 0 0 0 .11-.759c0-.57-.153-1.016-.458-1.34-.3-.328-.722-.492-1.265-.492-.546 0-.988.158-1.326.472-.332.31-.503.731-.512 1.265h-.814c.01-.748.262-1.345.759-1.792.497-.45 1.128-.676 1.893-.676.784 0 1.402.23 1.853.69.456.456.684 1.076.684 1.86 0 .492-.112.954-.335 1.387-.224.429-.648.93-1.272 1.504-.515.433-.772 1.062-.772 1.887h-.814zm-.11 2.283c0-.155.049-.285.144-.39.1-.109.24-.164.417-.164.174 0 .31.055.41.165a.53.53 0 0 1 .158.39c0 .15-.053.277-.157.382-.1.1-.237.15-.41.15-.178 0-.317-.05-.418-.15a.547.547 0 0 1-.143-.383z" _fill="#919396"/>'
  }
})
