/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand': {
    width: 24.514,
    height: 24.514,
    viewBox: '0 0 24.514 24.514',
    data: '<g data-name="Group 3242"><g data-name="Group 3241"><g data-name="Group 2077" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-width="1.3"><g data-name="Group 1120"><path pid="0" data-name="Line 136" stroke-miterlimit="10" d="M15.169 9.345l5.808-5.808"/><path pid="1" data-name="Path 834" d="M15.744 3.536h5.232V8.77" stroke-linejoin="round"/></g><g data-name="Group 2008"><path pid="2" data-name="Line 136" stroke-miterlimit="10" d="M15.169 15.169l5.808 5.808"/><path pid="3" data-name="Path 834" d="M20.978 15.744v5.232h-5.233" stroke-linejoin="round"/></g><g data-name="Group 2007"><path pid="4" data-name="Line 136" stroke-miterlimit="10" d="M9.345 15.17l-5.808 5.808"/><path pid="5" data-name="Path 834" d="M8.77 20.979H3.538v-5.233" stroke-linejoin="round"/></g><g data-name="Group 2009"><path pid="6" data-name="Line 136" stroke-miterlimit="10" d="M9.345 9.345L3.537 3.537"/><path pid="7" data-name="Path 834" d="M3.536 8.77V3.538H8.77" stroke-linejoin="round"/></g></g></g></g>'
  }
})
