import axios from 'axios';
import moment from 'moment';
import i18n from '../../plugins/i18n';

import { calculateDuration, handleGetQuestionnaireParams } from './helpers/questionnaireHelpers';
import { handleEmptyQuestionProperties, reflectionDays } from './helpers/reflectionsHelpers';
import {
  handleResponse,
  handleCommitResponse,
  handleResponseError,
} from './helpers/responseHelpers';

const getDefaultState = () => ({
  templatesAll: [],
  templatesPublic: [],
  templatesPrivate: [],
  displayTemplates: 'all',
  reflectionList: [],
  groupReflectionList: [],
  currentDate: null,
  currentTemplate: null,
  currentReflection: null,
  editStatus: null,
  isLoadingDownload: false,
  selectedTemplateType: 'filter', // filter/exclude
  selectedTemplatePage: 1,
  selectedTemplateSort: 'dateDesc', // dateDesc/dateAsc/popularityDesc
  selectedTemplateDraftPage: 1,
  currentSelectedReflection: null,
  unparticipatedStudents: [],
});

const state = getDefaultState();

const mutations = {
  GET_TEMPLATES(state, templates) {
    state.templatesPublic = templates;
  },
  GET_PRIVATE_TEMPLATES(state, templates) {
    state.templatesPrivate = templates;
  },
  GET_ALL_TEMPLATES(state, templates) {
    state.templatesAll = templates;
  },
  SET_TEMPLATE_DISPLAY(state, status) {
    state.displayTemplates = status;
  },
  SET_TEMPLATE(state, template) {
    state.currentTemplate = template;
  },
  DELETE_TEMPLATE(state, { index, type }) {
    if (type === 'private') {
      state.templatesPrivate.splice(index, 1);
    } else if (type === 'public') state.templatesPublic.splice(index, 1);
  },
  DELETE_DRAFT(state, index) {
    state.templatesAll.splice(index, 1);
  },
  GET_REFLECTIONS(state, reflectionList) {
    state.reflectionList = reflectionList;
  },
  GET_REFLECTION(state, reflection) {
    state.reflection = reflection;
  },
  SET_CURRENT_REFLECTION(state, reflection) {
    state.currentReflection = reflection;
  },
  GET_REFLECTIONS_BY_GROUP(state, groups) {
    state.groupReflectionList = groups;
  },
  GET_UNPARTICIPATED_STUDENTS(state, students) {
    state.unparticipatedStudents = students;
  },
  RESET_UNPARTICIPATED_STUDENTS(state) {
    state.unparticipatedStudents = [];
  },
  SET_CURRENT_SELECTED_REFLECTION(state, reflection) {
    state.currentSelectedReflection = reflection;
  },
  SET_DATE(state, date) {
    state.currentDate = date;
  },
  SET_EDIT_STATUS(state, status) {
    state.editStatus = status;
  },
  TOGGLE_DOWNLOAD(state, response) {
    state.isLoadingDownload = response;
  },
  SET_TEMPLATE_TYPE(state, type) {
    state.selectedTemplateType = type;
  },
  SET_TEMPLATE_PAGE(state, page) {
    state.selectedTemplatePage = page;
  },
  SET_TEMPLATE_DRAFT_PAGE(state, page) {
    state.selectedTemplateDraftPage = page;
  },
  SET_TEMPLATE_SORT(state, page) {
    state.selectedTemplateSort = page;
  },
  RESET_TEMPLATE_QUERY(state) {
    state.selectedTemplateType = 'filter';
    state.selectedTemplatePage = 1;
    state.selectedTemplateSort = 'dateDesc';
  },
  RESET_TEMPLATE_DRAFT_QUERY(state) {
    state.selectedTemplateDraftPage = 1;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getTemplates({ commit }, payload) {
    const { q, limit, offset, clientId, action, orderBy, ...rest } = payload;
    return axios({
      method: 'GET',
      url: '/reflection/template/listPublic',
      params: {
        limit: limit || 10,
        offset,
        q,
        clientId,
        action,
        orderBy,
        ...rest.value,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_TEMPLATES'))
      .catch(handleResponseError);
  },
  getTemplateById({ commit, rootGetters }, payload) {
    return axios
      .get(`/reflection/template/get/${payload}`)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          handleGetQuestionnaireParams(data);
          commit('SET_TEMPLATE', data);
          commit('questionnaire/SET_QUESTIONNAIRE', data, { root: true });
          commit('questionnaire/SET_CURRENT_VIEW', 'list', { root: true });

          const methods = rootGetters['methods/questionTypeList'];
          const { questions } = data;
          calculateDuration(methods, questions, commit, true);

          return Promise.resolve({ msg: i18n.t('warnings.success_action_message'), success, data });
        }
        return Promise.resolve({ msg: i18n.t('warnings.cant_get_template_message'), success });
      })
      .catch(handleResponseError);
  },
  getPrivateTemplates({ commit }, payload) {
    const { q, limit, offset } = payload;
    return axios({
      method: 'GET',
      url: '/reflection/template/list',
      params: {
        limit: limit || 10,
        offset,
        q,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_PRIVATE_TEMPLATES'))
      .catch(handleResponseError);
  },
  getAllTemplates({ commit }, payload) {
    const { clientGroupId, q, offset, limit, draft } = payload;
    return axios({
      method: 'GET',
      url: '/reflection/questionnaire/list',
      params: {
        limit: limit || 10,
        draft: draft === false ? 'false' : draft,
        clientGroupId,
        q,
        offset,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_ALL_TEMPLATES'))
      .catch(handleResponseError);
  },
  downloadQuestionnaireStats({ commit }, payload) {
    commit('TOGGLE_DOWNLOAD', true);
    const id = payload;
    return axios({
      method: 'GET',
      url: `/reflection/export/exportByQuestionnaire/${id}`,
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    })
      .then((response) => {
        commit('TOGGLE_DOWNLOAD', false);
        const { data } = response;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Reflectus.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(handleResponseError);
  },
  setTemplateDisplay({ commit }, payload) {
    commit('SET_TEMPLATE_DISPLAY', payload);
  },
  updateTemplate(context, payload) {
    const { id, questions } = payload;
    handleEmptyQuestionProperties(questions);
    return axios
      .put(`/reflection/template/update/${id}`, payload)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  cloneTemplate(context, payload) {
    return axios
      .post(`/reflection/template/clone/${payload.id}`, {
        recommendations: payload.recommendations,
      })
      .then(handleResponse)
      .catch(handleResponseError);
  },
  shareTemplate(context, payload) {
    return axios
      .post(`/reflection/template/clone/${payload.id}`, payload.payload)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  createFromQuestionnaire(context, payload) {
    return axios
      .post(`/reflection/template/createFromQuestionnaire/${payload}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  deleteTemplate({ commit, state }, payload) {
    const { id, type } = payload;
    return axios
      .delete(`/reflection/template/delete/${id}`)
      .then((res) => {
        const { success } = res.data;
        let index;
        if (type === 'private') {
          index = state.templatesPrivate.findIndex((obj) => obj.id === id);
        } else if (type === 'public') {
          index = state.templatesPublic.findIndex((obj) => obj.id === id);
        }
        commit('DELETE_TEMPLATE', { index, type });
        if (success) {
          return Promise.resolve({ msg: i18n.t('warnings.template_deleted_message'), success });
        }
        return Promise.resolve({
          msg: i18n.t('warnings.cant_delete_template_message'),
          success: false,
        });
      })
      .catch(handleResponseError);
  },
  getReflections({ commit, state }) {
    const monday = moment(state.currentDate)
      .startOf('isoweek')
      .format('YYYY-MM-DD');
    const sunday = moment(state.currentDate)
      .endOf('isoweek')
      .format('YYYY-MM-DD');

    return axios
      .get(`/reflection/reflection/listByDates/${monday}/${sunday}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_REFLECTIONS'))
      .catch(handleResponseError);
  },
  getReflection({ commit }, payload) {
    return axios
      .get(`/reflection/reflection/get/${payload}`)
      .then((res) => handleCommitResponse(res, commit, 'SET_CURRENT_REFLECTION'))
      .catch(handleResponseError);
  },
  setCurrentReflection({ commit }, payload) {
    commit('SET_CURRENT_REFLECTION', payload);
    return Promise.resolve({ success: true, data: payload });
  },
  getReflectionsByGroup({ commit }, payload) {
    const { id, fromDate, toDate, questionnaireId, limit, offset, memberId } = payload;
    return axios({
      method: 'GET',
      url: '/reflection/reflection/list',
      params: {
        groupId: id,
        from: fromDate,
        to: toDate,
        questionnaireId,
        limit,
        offset,
        addStats: true,
        memberId,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_REFLECTIONS_BY_GROUP'))
      .catch(handleResponseError);
  },
  getUnparticipatedStudents({ commit }, id) {
    return axios({
      method: 'GET',
      url: `/reflection/reflection/listNotParticipatedStudents/${id}`,
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_UNPARTICIPATED_STUDENTS'))
      .catch(handleResponseError);
  },
  resendReflection(context, payload) {
    const { id, clients } = payload;
    return axios
      .post(`/reflection/reflection/resend/${id}`, {
        clients,
      })
      .then(handleResponse)
      .catch(handleResponseError);
  },
  updateReflection(context, payload) {
    return axios({
      method: 'PUT',
      url: `/reflection/reflection/update/${payload.id}`,
      data: payload,
    })
      .then(handleResponse)
      .catch(handleResponseError);
  },
  deleteReflection(context, payload) {
    return axios
      .delete(`/reflection/reflection/delete/${payload}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  deleteReflectionMember(context, payload) {
    const { reflectionId, clientId } = payload;
    return axios
      .delete(`/reflection/reflection/deleteReflectionMember/${reflectionId}/${clientId}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  skipReflection(context, payload) {
    const { id, date } = payload;
    return axios
      .post(`/reflection/schedule/skip/${id}/${date}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  setDate({ commit }, payload) {
    if (!payload) {
      commit('SET_DATE', moment());
    } else {
      commit('SET_DATE', moment(payload));
    }
  },
  setCurrentSelectedReflection({ commit }, payload) {
    commit('SET_CURRENT_SELECTED_REFLECTION', payload);
  },
  resetUnparticipatedStudents({ commit }) {
    commit('RESET_UNPARTICIPATED_STUDENTS');
  },
  setEditStatus({ commit }, payload) {
    commit('SET_EDIT_STATUS', payload);
  },
  setTemplateType({ commit }, payload) {
    commit('SET_TEMPLATE_TYPE', payload);
  },
  setTemplatePage({ commit }, payload) {
    commit('SET_TEMPLATE_PAGE', payload);
  },
  setTemplateDraftPage({ commit }, payload) {
    commit('SET_TEMPLATE_DRAFT_PAGE', payload);
  },
  setTemplateSort({ commit }, payload) {
    commit('SET_TEMPLATE_SORT', payload);
  },
  resetTemplateQuery({ commit }) {
    commit('RESET_TEMPLATE_QUERY');
  },
  resetTemplateDraftQuery({ commit }) {
    commit('RESET_TEMPLATE_DRAFT_QUERY');
  },
  resetReflectionsState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  templatesPublic(state) {
    return state.templatesPublic;
  },
  templatesPrivate(state) {
    return state.templatesPrivate;
  },
  templatesAll(state) {
    return state.templatesAll;
  },
  displayTemplates(state) {
    return state.displayTemplates;
  },
  currentTemplate(state) {
    return state.currentTemplate;
  },
  currentDate(state) {
    return state.currentDate;
  },
  currentReflection(state) {
    return state.currentReflection;
  },
  reflectionList(state) {
    return state.reflectionList;
  },
  groupReflectionList(state) {
    return state.groupReflectionList;
  },
  isLoadingDownload(state) {
    return state.isLoadingDownload;
  },
  reflectionsByDay(state) {
    const reflectionsByDay = state.reflectionList;
    const days = JSON.parse(JSON.stringify(reflectionDays));
    reflectionsByDay.forEach((reflection) => {
      const { start, end } = reflection;
      reflection.day = moment(start).format('dddd');
      reflection.weekend = moment(start).isoWeekday() === 6 || moment(start).isoWeekday() === 7;
      days.forEach((day) => {
        if (day.day === moment(start).format('dddd')) {
          const progress = `${Math.floor(Math.random() * 30)}`;
          const a = moment(start);
          const b = moment(end);
          const duration = b.diff(a, 'minutes');
          day.reflections.push({
            ...reflection,
            progress,
            duration,
          });
        }
        day.reflections.sort((current, next) => {
          if (current.start > next.start) return 1;
          return -1;
        });
      });
    });
    return days;
  },
  excel(state) {
    return state.excel;
  },
  editStatus(state) {
    return state.editStatus;
  },
  selectedTemplateType(state) {
    return state.selectedTemplateType;
  },
  selectedTemplatePage(state) {
    return state.selectedTemplatePage;
  },
  selectedTemplateSort(state) {
    return state.selectedTemplateSort;
  },
  selectedTemplateDraftPage(state) {
    return state.selectedTemplateDraftPage;
  },
  currentSelectedReflection(state) {
    return state.currentSelectedReflection;
  },
  unparticipatedStudents(state) {
    return state.unparticipatedStudents;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
