/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send-again': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M18.189 4.706C13.1 2.383 7.092 4.624 4.769 9.713A10.13 10.13 0 0 0 6.3 20.525m16.323-11.89a10.118 10.118 0 0 1 .572 9.491c-2.136 4.678-7.385 6.95-12.172 5.482" _stroke="#3B328B" stroke-width="1.5" stroke-linecap="round"/><path pid="1" d="M18.389 2.57l1.232 2.735-2.736 1.232" _stroke="#3B328B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M17.459 14.33a.5.5 0 0 0 0-.9l-5.412-2.62a.5.5 0 0 0-.718.45v2.036l3.515.584-3.515.584V16.5a.5.5 0 0 0 .717.45l5.413-2.62z" _fill="#3B328B"/><path pid="3" d="M11.509 25.846l-1.672-2.49 2.491-1.673" _stroke="#3B328B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
