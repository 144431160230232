/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-love': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" _fill="none" _stroke="#5454c6" stroke-linecap="round" stroke-linejoin="round" d="M15.72 10.8l3.81 3.81-3.81 3.82M23.35 18.43l-3.82-3.82 3.82-3.81"/><circle pid="1" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="2" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="3" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="4" d="M7.68 16.09a5.71 5.71 0 0 0 11.35 0z" _fill="#71523f"/><path pid="5" d="M9.43 19.59a5.71 5.71 0 0 0 7.71.12l-.05-.12a9.42 9.42 0 0 0-7.66 0z" _fill="#ff6669"/><path pid="6" data-name="&lt;Path&gt;" d="M11.51 9.47a2.28 2.28 0 0 0-2.23-2.38 2 2 0 0 0-1.89 1.48 2 2 0 0 0-1.9-1.48 2.27 2.27 0 0 0-2.23 2.36c0 .27 0 3 4.13 5.22 4.12-2.2 4.13-4.93 4.12-5.2zM23.45 9.47a2.28 2.28 0 0 0-2.23-2.37 2 2 0 0 0-1.89 1.48 2 2 0 0 0-1.89-1.48 2.27 2.27 0 0 0-2.23 2.36c0 .27 0 3 4.13 5.22 4.11-2.21 4.11-4.94 4.11-5.21z" _fill="#ff2223"/></g></g>'
  }
})
