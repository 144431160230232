/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'multiply': {
    width: 16,
    height: 16,
    viewBox: '0 0 8.63 8.63',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#5454c6" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5.5l3.81 3.81L.5 8.13"/><path pid="1" d="M8.13 8.13L4.31 4.31 8.13.5"/></g></g>'
  }
})
