/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-search': {
    width: 119.7,
    height: 81.5,
    viewBox: '0 0 119.7 81.5',
    data: '<g data-name="Group 3923"><g data-name="Group 3344"><path pid="0" data-name="Path 3363" d="M26.4 4.4h2.3v2.3a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.683.683 0 0 0 .7-.7V4.4h2.3a.683.683 0 0 0 .7-.7.683.683 0 0 0-.7-.7h-2.3V.7a.683.683 0 0 0-.7-.7.684.684 0 0 0-.7.7V3h-2.3a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2z" _fill="#474eb9"/><path pid="1" data-name="Path 3364" d="M7.7 66.6H4.9v-2.8a.684.684 0 0 0-.7-.7.684.684 0 0 0-.7.7v2.8H.7a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2h2.8v2.8a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.684.684 0 0 0 .7-.7V68h2.8a.684.684 0 0 0 .7-.7.684.684 0 0 0-.7-.7z" _fill="#474eb9"/><path pid="2" data-name="Path 3365" d="M119 56.5h-1.9v-2a.684.684 0 0 0-.7-.7.684.684 0 0 0-.7.7v1.9h-1.9a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2h1.9v1.9a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.684.684 0 0 0 .7-.7v-1.8h1.9a.684.684 0 0 0 .7-.7.684.684 0 0 0-.7-.7z" _fill="#474eb9"/><g data-name="Group 3922"><path pid="3" data-name="Path 3366" d="M57.9 11.5a35.038 35.038 0 0 0-35 35 35.038 35.038 0 0 0 35 35 35.038 35.038 0 0 0 35-35 35.038 35.038 0 0 0-35-35zm0 67.9a33.033 33.033 0 0 1-33-33 33.033 33.033 0 0 1 33-33 33.033 33.033 0 0 1 33 33 33.033 33.033 0 0 1-33 33z" _fill="#474eb9"/></g><path pid="4" data-name="Path 3367" d="M62.711 48.483a13.317 13.317 0 0 0 2.631-7.941 13.414 13.414 0 0 0-13.4-13.4 13.415 13.415 0 0 0-13.4 13.4 13.416 13.416 0 0 0 13.4 13.4 13.31 13.31 0 0 0 7.941-2.631l11.264 11.264a1.99 1.99 0 0 0 1.414.586 1.99 1.99 0 0 0 1.414-.586 2 2 0 0 0 0-2.828zm-20.169-7.941a9.41 9.41 0 0 1 9.4-9.4 9.41 9.41 0 0 1 9.4 9.4 9.41 9.41 0 0 1-9.4 9.4 9.41 9.41 0 0 1-9.4-9.4z" _fill="#474eb9"/></g></g>'
  }
})
