/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-icon': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<path pid="0" d="M6.5 1.68a1.487 1.487 0 1 1 0 2.974 1.487 1.487 0 0 1 0-2.975zm0 6.374c2.104 0 4.32 1.034 4.32 1.488v.779H2.18v-.78c0-.453 2.216-1.487 4.32-1.487zm0-7.72A2.833 2.833 0 1 0 6.5 6a2.833 2.833 0 1 0 0-5.667zm0 6.374c-1.891 0-5.667.95-5.667 2.834v2.125h11.334V9.542c0-1.884-3.776-2.834-5.667-2.834z" _fill="#11AB99"/>'
  }
})
