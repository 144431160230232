<template>
  <nav class="nav">
    <div class="nav__container">
      <div :class="['nav__logo', { 'nav__logo--login': !token }]">
        <div class="nav__logo__link" @click="handleNavigation('Home')">
          <svgicon name="app-logo-navigation" :original="true" height="44px"></svgicon>
        </div>
      </div>
      <ul class="nav__items" v-if="token">
        <li class="nav__item">
          <p @click="handleNavigation('Home')"
            :class="['nav__item__link', { 'nav__item__link--active': $route.name === 'Home' }]">
            <svgicon name="house" :original="true" height="2em"></svgicon>
          </p>
        </li>
        <li class="nav__item">
          <p @click="handleNavigation('MyGroups')" :to="{ name: 'MyGroups' }"
            :class="['nav__item__link', { 'nav__item__link--active': $route.name === 'MyGroups' }]">
            {{ $t('my_groups') }}
          </p>
        </li>
        <!-- <li class="nav__item">
          <p
            @click="handleNavigation('Methods')"
            :class="['nav__item__link', { 'nav__item__link--active': $route.name === 'Methods' }]"
          >
            Metodai
          </p>
        </li>-->
        <li class="nav__item">
          <p @click="handleNavigation('TemplatesDraft')" :class="[
            'nav__item__link',
            {
              'nav__item__link--active':
                $route.name === 'TemplatesDraft' || $route.name === 'EditReflection',
            },
          ]">
            {{ $t('my_questionnaires') }}
          </p>
        </li>
        <li class="nav__item">
          <p @click="handleNavigation('Templates')" :class="[
            'nav__item__link',
            {
              'nav__item__link--active':
                $route.name === 'Templates' || $route.name === 'EditTemplate',
            },
          ]">
            {{ $t('questionnaire_templates') }}
          </p>
        </li>
        <li class="nav__item">
          <p @click="handleNavigation('Lessons')"
            :class="['nav__item__link', { 'nav__item__link--active': $route.name === 'Lessons' }]">
            {{ $t('tutorials') }}
          </p>
        </li>
        <li class="nav__item">
          <p @click="handleNavigation('Faq')"
            :class="['nav__item__link', { 'nav__item__link--active': $route.name === 'Faq' }]">
            {{ $t('help') }}
          </p>
        </li>
      </ul>
      <menu-dropdown v-if="token" :key="navigationKey"></menu-dropdown>
      <menu-dropdown-language v-if="!token"></menu-dropdown-language>
      <!-- <div class="breadcrumbs__container">
        <breadcrumbs class="breadcrumbs"></breadcrumbs>
      </div> -->
      <div class="license-expiration-date-info-wrapper" v-if="token">
        <span class="expiration-title">{{ $t('license_end_title') }}:</span>
        <span class="expiration-date">{{ licenseExpirationDate }}</span>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MenuDropdown from '@/components/Menu/MenuDropdown.vue';
import MenuDropdownLanguage from '@/components/Menu/MenuDropdownLanguage.vue';
// import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';

export default {
  name: 'TopMenu',
  components: { MenuDropdown, MenuDropdownLanguage },
  data() {
    return {
      currentRouteName: null,
      dateFormat: 'YYYY [m.] MMMM D [d.]',
    };
  },
  computed: {
    ...mapGetters('user', ['user', 'token', 'navigationKey']),
    ...mapGetters('questionnaire', ['schedules']),
    licenseExpirationDate() {
      return this.moment(this.user.licenseData && this.user.licenseData.date ? this.user.licenseData.date : this.user.license).format(this.dateFormat);
    },
  },
  methods: {
    ...mapActions('meta', [
      'setQuestionnaireNavigation',
      'setOptionsNavigation',
      'setTemplateSearch',
      'resetGroupQuestionnaireQuery',
    ]),
    ...mapActions('reflections', ['resetTemplateQuery', 'resetTemplateDraftQuery']),
    handleNavigation(route) {
      const { name } = this.$route;
      this.setOptionsNavigation(false);

      if (
        name === 'NewReflection' ||
        name === 'EditReflection' ||
        name === 'EditTemplate' ||
        name === 'ReflectionOptions'
      ) {
        this.setQuestionnaireNavigation({
          modal: true,
          route,
        });
      } else {
        this.$router.push({ name: route });
        if (name === 'Group') {
          this.resetGroupQuestionnaireQuery();
        }
        if (name === 'Templates') {
          this.resetTemplateQuery();
          this.setTemplateSearch('');
        }
        if (name === 'TemplatesDraft') {
          this.resetTemplateDraftQuery();
          this.setTemplateSearch('');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  background-color: $green-3;
  margin-bottom: 10px;

  .nav__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Nunito-SemiBold;
    font-size: 12px;
    max-width: 1024px;
    max-height: 30px;
    margin: 0 auto;

    .nav__logo {
      z-index: 3;

      &__link {
        cursor: pointer;
        margin-top: 14px;
      }

      &--login {
        margin-right: auto;
      }
    }

    .nav__items {
      margin: 0;
      max-height: 30px;
      display: flex;
      width: 88%;
      align-items: center;
      justify-content: space-between;

      .nav__item {
        list-style: none;
        color: #fff;
        height: 30px;

        &__link {
          display: flex;
          align-items: center;
          height: 100%;
          text-decoration: none;
          color: #fff;
          text-transform: uppercase;
          padding: 0 10px;
          position: relative;

          &--active {
            background-color: $green-6;
          }

          &:hover {
            background-color: $green-6;
            cursor: pointer;
          }
        }
      }
    }

    .nav__select {
      select {
        border: none;
        background: #11ac9a;
        color: #fff;
      }
    }

    .license-expiration-date-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        color: #fff;

        &.expiration-date {
          font-family: 'Nunito-Bold';
        }
      }

      @media screen and (min-width: 1234px) {
        position: absolute;
        right: 30px;
      }
    }
  }
}
</style>
