/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-suspend': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" d="M2.5 5v1h5V5h-5zM5 .5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 9c-2.205 0-4-1.795-4-4s1.795-4 4-4 4 1.795 4 4-1.795 4-4 4z" _fill="#3371F2"/>'
  }
})
