/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-tear': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.43" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M21.24 7.31l-.42-.12c-.3-.08-.58-.16-.86-.26a7.6 7.6 0 0 1-1.13-.48 6.69 6.69 0 0 1-1-.66 10.06 10.06 0 0 1-1-.89.06.06 0 0 0-.1 0 2.54 2.54 0 0 0 .52 1.36 3.33 3.33 0 0 0 1.13 1 3.52 3.52 0 0 0 1.36.39h.05a2.94 2.94 0 0 0 1.42-.25.06.06 0 0 0 0-.11zM5.47 7.31l.42-.12c.3-.08.58-.16.86-.26a7.6 7.6 0 0 0 1.13-.48 6.69 6.69 0 0 0 1-.66 10.06 10.06 0 0 0 1-.89.06.06 0 0 1 .12.1 2.54 2.54 0 0 1-.52 1.36 3.33 3.33 0 0 1-1.13 1 3.52 3.52 0 0 1-1.36.39h-.11a2.94 2.94 0 0 1-1.42-.25.06.06 0 0 1 0-.11z" _fill="#3f3e40"/><path pid="6" d="M16.17 15.38a4.24 4.24 0 0 0-2.82-1 4.46 4.46 0 0 0-2.8 1 3.93 3.93 0 0 0-.94 1.16 3.17 3.17 0 0 0-.37 1.4.26.26 0 0 0 .46.16 5.88 5.88 0 0 1 .8-.87 4.53 4.53 0 0 1 .89-.59 4.46 4.46 0 0 1 2-.47 4.09 4.09 0 0 1 1.94.46A5 5 0 0 1 17 18.11a.26.26 0 0 0 .21.1h.08a.26.26 0 0 0 .17-.25 3.55 3.55 0 0 0-1.29-2.58z" _fill="#654735"/><path pid="7" d="M19.45 12.63c-4 4.52-3.52 7.12-3.52 7.12a3.52 3.52 0 1 0 7 0s.48-2.59-3.48-7.12z" _fill="#47c4e9"/><path pid="8" d="M16.84 22.1A3.52 3.52 0 0 0 23 19.75a5.82 5.82 0 0 0-.76-3.09c-.74 4.56-3.6 5.34-5.4 5.44z" _fill="#00bbe9"/></g></g>'
  }
})
