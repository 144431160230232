/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flags/flag-lt': {
    width: 26,
    height: 19,
    viewBox: '0 0 26 19',
    data: '<path pid="0" _fill="#fff" opacity=".5" d="M0 0h26v19H0z"/><path pid="1" _fill="#fdb913" d="M1 1h24v6H1z"/><path pid="2" _fill="#006a44" d="M1 7h24v5H1z"/><path pid="3" _fill="#c1272d" d="M1 12h24v6H1z"/>'
  }
})
