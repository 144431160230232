<template>
  <div class="template__3k" v-if="question">
    <div class="template__input-field">
      <input
        class="input-box input-box--small"
        type="number"
        :placeholder='$t("insert_value_placeholder")'
        v-model="part.answerOptions.maxLength"
        max="299"
        :disabled="!edit"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Template3k',
  props: ['number', 'question', 'edit', 'partIndex'],
  data() {
    return {
      i: 0,
    };
  },
  created() {
    if (!this.options.maxLength) {
      this.options.maxLength = 20;
    }
    if (this.part && !this.part.answerOptions) {
      this.part.answerOptions = {
        maxLength: 222,
      };
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questions']),
    options() {
      return this.question.answerOptions;
    },
    type() {
      return this.question.answerOptions.type;
    },
    view() {
      return this.question.answerOptions.view;
    },
    part() {
      return this.question.parts[this.partIndex];
    },
  },
  methods: {
    handleLengthInput(event) {
      const { value } = event.target;
      this.options.maxLength = parseInt(value, 10);
    },
  },
};
</script>
<style lang="scss">
.template {
  &__3k {
    .uppercase-title {
      margin-right: 5px;
    }
  }
}
</style>
