<template>
  <div class="custom-checkbox">
    <input type="checkbox" :id="`${boxValue}-${uniqueId}`" v-model="toggled" @change="emitValue" />
    <label :for="`${boxValue}-${uniqueId}`">
      <slot name="label"></slot>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'CustomCheckbox',
    props: ['checked', 'boxValue'],
    data() {
      return {
        toggled: null,
        uniqueId: null,
      };
    },
    created() {
      this.toggled = this.checked;
      this.uniqueId = Math.random() * 1000;
    },
    methods: {
      emitValue() {
        this.$emit('checkStatus', { checked: this.toggled, value: this.boxValue });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .custom-checkbox {
    input[type='checkbox'],
    input[type='radio'] {
      visibility: hidden;
    }
    width: 17px;
    height: 17px;
    position: relative;

    .checkbox-label {
      position: absolute;
      top: -2px;
      left: 23px;
      a {
        text-decoration: underline;
      }
    }

    label {
      cursor: pointer;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 0;
      left: 0;
      background: transparent;
      border: 1px solid $grey-2;

      &::after {
        opacity: 0.1;
        content: '';
        position: absolute;
        width: 17px;
        height: 7px;
        background: transparent;
        top: -2px;
        left: 1px;
        border: 3px solid $green-5;
        border-radius: 2px;
        border-top: none;
        border-right: none;
        transform: rotate(-53deg);
      }

      &:hover {
        &:after {
          opacity: 0.5;
        }
      }
    }

    input[type='checkbox']:checked + label:after {
      opacity: 1;
    }

    input[type='radio']:checked + label:after {
      opacity: 1;
    }

    &--disabled {
      label {
        cursor: not-allowed;
      }

      label:after {
        border-color: $grey-3;
        opacity: 1;
      }
    }
  }
</style>
