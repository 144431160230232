/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-big': {
    width: 13,
    height: 12,
    viewBox: '0 0 13 12',
    data: '<path pid="0" d="M12.333 6.833h-5v5H5.667v-5h-5V5.166h5v-5h1.666v5h5v1.667z" _fill="#fff"/>'
  }
})
