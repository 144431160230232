/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-services': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M1.833 3.75h11.334v1.417H1.832V3.75zM3.25.917h8.5v1.416h-8.5V.917zm9.916 5.666H1.833A1.42 1.42 0 0 0 .417 8v5.667c0 .779.637 1.416 1.416 1.416h11.334a1.42 1.42 0 0 0 1.416-1.416V8a1.42 1.42 0 0 0-1.417-1.417zm0 7.084H1.833V8h11.333v5.667zm-7.083-5.15v4.626l4.25-2.31-4.25-2.316z" _fill="#11AB99"/>'
  }
})
