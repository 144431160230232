/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info-circle-grey': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<circle pid="0" cx="7.287" cy="7.287" r="7.287" _fill="#919396"/><path pid="1" d="M6.556 3.924c.03.06.06.121.091.167.03.045.076.09.106.136.045.046.09.09.151.136.046.03.106.076.182.091a.75.75 0 0 0 .197.06c.076.016.136.016.197.016.06 0 .136 0 .197-.015.06-.015.12-.03.166-.06h.015c.06-.031.121-.061.182-.107.045-.03.09-.075.136-.12l.015-.016c.046-.045.076-.09.106-.136.03-.046.061-.091.076-.152 0-.015 0-.015.015-.03.03-.06.046-.136.06-.197.016-.06.016-.136.016-.197 0-.06 0-.136-.015-.197a1.403 1.403 0 0 0-.06-.196.86.86 0 0 0-.182-.273l-.03-.03c-.046-.046-.092-.076-.152-.121-.06-.03-.106-.06-.167-.091-.06-.03-.12-.046-.181-.06-.076-.016-.137-.016-.197-.016s-.136 0-.197.015c-.06.015-.121.03-.182.06h-.015c-.06.031-.121.061-.166.092-.061.045-.106.075-.152.12-.045.046-.09.092-.121.137a1.65 1.65 0 0 0-.09.167.852.852 0 0 0-.061.181c-.016.076-.016.137-.016.197s0 .136.016.197c.015.121.045.182.06.242zM8.661 9.466h-.348v-3.71a.39.39 0 0 0-.394-.393H6.33a.391.391 0 0 0-.393.394v.817c0 .212.166.394.393.394h.318v2.514H6.33a.391.391 0 0 0-.393.393v.818a.39.39 0 0 0 .393.393h2.332a.39.39 0 0 0 .394-.393v-.818a.404.404 0 0 0-.394-.409z" _fill="#fff"/>'
  }
})
