/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paysera-logo': {
    width: 19,
    height: 25,
    viewBox: '0 0 19 25',
    data: '<path pid="0" d="M2.102 24.997c-.63 0-1.26-.004-1.89.003-.166.002-.213-.059-.212-.22a9.856 9.856 0 0 1 2.28-6.15c1.771-2.144 4.492-3.4 7.24-3.417 2.49-.007 4.774-1.946 5.194-4.445.025-.157.11-.19.247-.184 1.282.048 2.565.025 3.848.01.142-.002.197.034.18.181-.348 3.8-3.097 7.19-6.688 8.288a8.84 8.84 0 0 1-2.252.413c-.634.03-1.268.038-1.889.217-2.235.614-3.904 2.745-3.93 5.105 0 .148-.06.202-.204.2-.641-.003-1.283-.001-1.924-.001zM16.885 9c-.636 0-1.271-.006-1.907.003-.165.002-.225-.057-.253-.217-.207-1.2-.745-2.226-1.621-3.053-2.28-2.257-6.147-1.804-7.914.868a5.053 5.053 0 0 0-.885 2.167c-.029.173-.092.236-.27.235-1.256-.008-2.51-.004-3.765-.005-.239 0-.258-.015-.232-.252.698-7.076 8.557-11.1 14.5-7.26 2.514 1.564 4.192 4.358 4.456 7.337.026.141-.039.179-.167.178C18.18 8.997 17.533 9 16.885 9zM0 19.31l.001-8.454c0-.252.001-.254.238-.254 1.249.002 2.498.006 3.746.004.158 0 .218.048.217.215-.005 1.4-.004 2.802 0 4.202 0 .13-.044.207-.156.274a11.073 11.073 0 0 0-3.841 3.796c-.048.08-.102.158-.153.237L0 19.31z" _fill="#5147A7"/>'
  }
})
