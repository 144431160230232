<template>
  <div class="group-reflection-header">
    <div class="group-reflection-header__top" v-if="questionnaire">
      <div class="group-reflection-header__top__title">
        <img
          class="group-reflection-header__top__title__icon"
          v-if="questionnaire.icon"
          :src="questionnaire.icon"
          alt="questionnaire Icon"
        />
        <svgicon
          class="group-reflection-header__top__title__icon"
          v-else
          name="logos/grey-logo"
          :original="true"
        ></svgicon>
        <header-h1>{{ questionnaire.title }}</header-h1>
      </div>
      <div class="group-reflection-header__top__actions">
        <div class="group-reflection-header__top__actions__actions" v-if="questionnaire.id">
          <router-link
            tag="span"
            class="uppercase-title"
            :to="{ name: 'ReflectionOptions', params: { id: questionnaire.id } }"
          >
            <svgicon
              name="plane"
              :original="true"
              height="1em"
              :fill="false"
              color="#919396"
            ></svgicon>
            {{ $t('group.group_schedule_settings') }}
          </router-link>
          <router-link
            tag="span"
            class="uppercase-title"
            :to="{ name: 'EditReflection', params: { id: questionnaire.id } }"
            @click.native="
              () => {
                toggleWarningModal();
                setEditStatus('edit');
              }
            "
          >
            <svgicon
              name="pen"
              :original="true"
              height="1em"
              :fill="false"
              color="#919396"
            ></svgicon>
            {{ $t('actions.edit_questionnaire_action') }}
          </router-link>
          <span class="uppercase-title" @click="handleStatsDownload">
            <svgicon
              name="excel"
              :original="true"
              height="1em"
              :fill="false"
              color="#919396"
            ></svgicon>
            {{ isLoadingDownload ? $t('exporting_dots') : $t('actions.save_as_excel') }}
          </span>
          <span class="uppercase-title delete-span" @click="handleQuestionnaireDelete">
            <svgicon
              name="bin"
              :original="true"
              height="1em"
              :fill="false"
              color="#919396"
            ></svgicon>
            {{ $t('actions.delete_questionnaire_action') }}
          </span>
        </div>
        <!-- <svgicon name="comments" class="group-reflection-header__top__actions__icon"></svgicon> -->
      </div>
    </div>
    <div class="group-reflection-header__bottom" v-if="currentGroup">
      <router-link
        :to="{ name: 'Group', params: { groupId: currentGroup.id } }"
        class="group-reflection-header__bottom__group"
      >
        <svgicon name="people" :original="true" height="1em"></svgicon>
        {{ currentGroup.title }}
      </router-link>
      <div class="group-reflection-header__bottom__date" v-if="formattedSchedule">
        {{ $t('starting_from') }}:
        <strong>{{ formattedSchedule }}</strong>
      </div>
      <div
        class="group-reflection-header__bottom__reflection-list"
        v-if="groupReflectionList && groupReflectionList.length > 0 && isStatsPage"
      >
        <svgicon
          v-if="isValidPrev && !questionLoading"
          name="page_prev"
          class="group-reflection-header__bottom__reflection-list__arrow"
          @click="handleReflectionChange('prev')"
        ></svgicon>
        <span class="group-reflection-header__bottom__reflection-list__reflection">
          {{ reflectionName }}
        </span>
        <svgicon
          v-if="isValidNext && !questionLoading"
          name="page_next"
          class="group-reflection-header__bottom__reflection-list__arrow"
          @click="handleReflectionChange('next')"
        ></svgicon>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

  export default {
    name: 'GroupReflectionHeader',
    components: {
      HeaderH1,
    },
    props: ['questionHasParts', 'questionLoading'],
    data() {
      return {
        currentReflectionIndex: 0,
      };
    },
    created() {
      this.handleCurrentReflection();
    },
    computed: {
      ...mapGetters('groups', ['currentGroup']),
      ...mapGetters('reflections', [
        'currentReflection',
        'groupReflectionList',
        'isLoadingDownload',
      ]),
      ...mapGetters('questionnaire', ['questionnaire']),
      reflectionName() {
        if (!this.groupReflectionList || !this.groupReflectionList.length) return false;
        const reflection = this.groupReflectionList[this.currentReflectionIndex];
        if (!reflection) return false;
        return reflection.name ? `${reflection.name} ${reflection.start}` : `${reflection.start}`;
      },
      isStatsPage() {
        return this.$route.name === 'GroupReflectionStats';
      },
      isValidNext() {
        if (this.currentReflectionIndex <= 0) return false;
        return true;
      },
      isValidPrev() {
        const total = this.groupReflectionList.length;
        if (total <= this.currentReflectionIndex + 1) return false;
        return true;
      },
      formattedSchedule() {
        const { schedules } = this.questionnaire;
        if (!schedules || !schedules.length) return false;
        return this.moment(`${schedules[0].fromDate}T${schedules[0].time}`).format('LLL');
      },
      rawReflectionDate() {
        const reflection = this.groupReflectionList[this.currentReflectionIndex];
        return reflection.start;
      },
    },
    methods: {
      ...mapActions('reflections', [
        'setCurrentReflection',
        'setEditStatus',
        'downloadQuestionnaireStats',
      ]),
      ...mapActions('questionnaire', ['getQuestionnaire']),
      ...mapActions('meta', ['toggleWarningModal']),
      handleCurrentReflection() {
        if (!this.currentReflection) return;
        if (!this.groupReflectionList || !this.groupReflectionList.length) return;
        this.currentReflectionIndex = this.groupReflectionList.findIndex(
          (reflection) => reflection.id === this.currentReflection.id
        );
      },
      handleReflectionChange(value) {
        const {
          params: { questionnaireId },
        } = this.$route;
        if (value === 'prev') {
          if (!this.isValidPrev) return;
          this.currentReflectionIndex += 1;
        } else {
          if (!this.isValidNext) return;
          this.currentReflectionIndex -= 1;
        }
        this.setCurrentReflection(this.groupReflectionList[this.currentReflectionIndex]).then(
          ({ data: { id } }) => {
            this.handleSetStatQuestion(id);
            this.getQuestionnaire({ questionnaireId, reflectionDate: this.rawReflectionDate });
          }
        );
      },
      handleSetStatQuestion(reflectionId) {
        this.$emit('handleReflectionChange', reflectionId);
      },
      handleStatsDownload() {
        if (!this.isLoadingDownload) {
          const { id } = this.questionnaire;
          this.downloadQuestionnaireStats(id);
        }
      },
      handleQuestionnaireDelete() {
        this.$emit('handleDeleteQuestionnaireModal');
      },
    },
  };
</script>
<style lang="scss">
  .group-reflection-header {
    display: flex;
    flex-direction: column;
    padding: 20px;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        display: flex;
        text-align: left;
        align-items: center;
        display: flex;

        h1 {
          flex: 1;
          margin-left: 15px;
        }

        &__icon {
          height: 42px;
          width: 42px;
          border-radius: 50%;
        }
      }

      &__actions {
        display: flex;
        align-items: center;

        &__actions {
          display: flex;
          flex-direction: column;
        }
      }

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;

        svg {
          margin-right: 5px;
          height: 14px;
          width: 14px;
        }
        &:nth-last-child(-n + 3) {
          margin-top: 10px;
        }

        &:hover {
          transform: scale(1.25);
          &.delete-span {
            color: $red-4;
            svg {
              path,
              rect {
                stroke: $red-4;
              }
            }
          }
        }
      }

      &__icon {
        margin-left: 20px;
        height: 42px;
        width: 42px;
        transition: all 0.3s ease-out;

        &:hover {
          cursor: pointer;
          transform: translateX(3px) scale(1.25);
        }
      }
    }

    &__bottom {
      display: flex;
      margin-top: 15px;

      span {
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-5;
        svg {
          margin-right: 5px;
        }

        &:not(:first-of-type) {
          margin-left: 20px;
        }
      }

      &__group {
        padding-right: 20px;
        border-right: 1px solid $grey-2;
      }

      &__date {
        margin-left: 20px;
        strong {
          text-transform: capitalize;
        }
      }

      &__reflection-list {
        margin-left: 20px;
        display: flex;
        align-items: center;

        &__arrow {
          height: 14px;
          width: 14px;
          cursor: pointer;

          &:first-of-type {
            margin-right: 8px;
          }
          &:last-of-type {
            margin-left: 8px;
          }
        }
      }
    }
  }
</style>
