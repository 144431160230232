/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'R': {
    width: 12.69,
    height: 22.524,
    viewBox: '0 0 12.69 22.524',
    data: '<g data-name="Group 3790"><g data-name="Group 3287" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 3338" d="M.75 11.364h5.775a5.307 5.307 0 0 0 5.307-5.307h0A5.307 5.307 0 0 0 6.525.75H.75v20.76"/><path pid="1" data-name="Line 1951" d="M6.291 11.364l5.385 10.146"/></g></g>'
  }
})
