/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'breadcrumb-arrow': {
    width: 4,
    height: 5,
    viewBox: '0 0 4 5',
    data: '<path pid="0" d="M3.068.586v3.623a.366.366 0 0 1-.624.259L.633 2.656a.364.364 0 0 1 0-.516L2.444.328a.365.365 0 0 1 .624.258z" _fill="#919396"/>'
  }
})
