export const listByReflection = '/reflection/statistics/listByReflection';
export const listByQuestionnaire = '/reflection/statistics/listByQuestionnaire';
export const listByQuestionnaireAndClient = '/reflection/statistics/listByQuestionnaireAndClientAsOwner';
export const listByReflectionAndGroup = '/reflection/statistics/listByReflectionAndGroup';
export const answerUpdate = '/reflection/answer/update';
export const distinctAnswers = '/reflection/answer/getDistinctAnswerValues';

export default {
  listByReflection,
  listByQuestionnaire,
  listByQuestionnaireAndClient,
  listByReflectionAndGroup,
  answerUpdate,
  distinctAnswers
};
