/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-big': {
    width: 8,
    height: 12,
    viewBox: '0 0 8 12',
    data: '<path pid="0" d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" _fill="#919396"/>'
  }
})
