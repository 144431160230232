/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/incomplete_sentence': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 3833"><g data-name="Group 1416" transform="translate(-61.896 -413.465)"><circle pid="0" data-name="Ellipse 270" cx="16.559" cy="16.559" r="16.559" transform="rotate(-.5 47443.363 -6885.837)" _fill="#6666ca"/><g data-name="Group 1415" _fill="#fff"><g data-name="Group 1413"><path pid="1" data-name="Path 945" d="M75.8 424.051h-6.757a.75.75 0 0 1 0-1.5H75.8a.75.75 0 0 1 0 1.5z"/><path pid="2" data-name="Path 946" d="M80.173 424.051h-1.757a.75.75 0 0 1 0-1.5h1.757a.75.75 0 0 1 0 1.5z"/><path pid="3" data-name="Path 947" d="M88.154 424.051h-5.271a.75.75 0 0 1 0-1.5h5.271a.75.75 0 0 1 0 1.5z"/><path pid="4" data-name="Path 948" d="M79.382 428.278H69.043a.75.75 0 0 1 0-1.5h10.339a.75.75 0 0 1 0 1.5z"/><path pid="5" data-name="Path 949" d="M88.154 428.278h-5.947a.75.75 0 0 1 0-1.5h5.947a.75.75 0 0 1 0 1.5z"/><path pid="6" data-name="Path 950" d="M72.729 432.506h-3.686a.75.75 0 0 1 0-1.5h3.686a.75.75 0 0 1 0 1.5z"/><path pid="7" data-name="Path 951" d="M88.154 432.506h-12.6a.75.75 0 0 1 0-1.5h12.6a.75.75 0 0 1 0 1.5z"/></g><g data-name="Group 1414"><path pid="8" data-name="Path 952" d="M75.582 437.034a.969.969 0 1 1-.969-.969.969.969 0 0 1 .969.969z"/><path pid="9" data-name="Path 953" d="M79.635 437.034a.969.969 0 1 1-.969-.969.969.969 0 0 1 .969.969z"/><path pid="10" data-name="Path 954" d="M83.688 437.034a.969.969 0 1 1-.969-.969.969.969 0 0 1 .969.969z"/></g></g></g></g>'
  }
})
