/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter-icon': {
    width: 18,
    height: 14,
    viewBox: '0 0 18 14',
    data: '<path pid="0" d="M1 2h16M1 7h16M1 12h16" _stroke="#5147A7" stroke-linecap="round"/><circle pid="1" cx="5" cy="2" r="2" _fill="#5147A7"/><circle pid="2" cx="13" cy="7" r="2" _fill="#5147A7"/><circle pid="3" cx="5" cy="12" r="2" _fill="#5147A7"/>'
  }
})
