<template>
  <button>
    <slot/>
  </button>
</template>
<script>
export default {
  name: 'Button4',
};
</script>
<style lang="scss" scoped>
button {
  border: none;
  background: $violet-5;
  color: #fff;
  border-radius: 45px;
  min-width: 102px;
  padding: 5px 20px;
  font-size: 14px;
  font-family: Roboto-Medium;
  &:hover {
    background: $violet-4;
    cursor: pointer;
  }
  &:active {
    color: $grey-2;
  }
  &:disabled {
    background: $grey-2;
    cursor: not-allowed;
  }
}
</style>
