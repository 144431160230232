/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-small': {
    width: 11,
    height: 7,
    viewBox: '0 0 11 7',
    data: '<path pid="0" d="M5.5.567L.75 5.317l1.116 1.116L5.5 2.807l3.634 3.626 1.116-1.116L5.5.567z" _fill="#505E67"/>'
  }
})
