/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-lick': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" d="M6.15 13.57a.57.57 0 0 1-.35-1l1.93-1.5-2-1.17a.57.57 0 0 1 .57-1L9 10.5a.57.57 0 0 1 .06.93l-2.58 2a.56.56 0 0 1-.33.14zM20.56 13.57a.56.56 0 0 1-.35-.12l-2.58-2a.57.57 0 0 1 .06-.93l2.71-1.59a.57.57 0 0 1 .57 1l-2 1.17 1.93 1.5a.57.57 0 0 1-.35 1z" _fill="#2c2c2c"/><path pid="4" d="M6.68 15.4C7.06 18 9.9 20 13.35 20s6.29-2 6.67-4.6z" _fill="#71523f"/><path pid="5" d="M9.37 15.4v5.21a4 4 0 1 0 8 0V15.4z" _fill="#ff6669"/><path pid="6" d="M13.35 21.34A.36.36 0 0 1 13 21v-5.6h.71V21a.36.36 0 0 1-.36.34z" _fill="#e24e58"/><path pid="7" d="M13.35 24.59a4 4 0 0 0 4-3.9 9 9 0 0 1-6.82 2.71 4 4 0 0 0 2.82 1.19z" _fill="#ff5f65"/></g></g>'
  }
})
