/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grip-tab': {
    width: 23.947,
    height: 3.947,
    viewBox: '0 0 23.947 3.947',
    data: '<g data-name="Group 4176"><path pid="0" data-name="Path 4279" d="M22.776 1.363V3.55a.191.191 0 0 1-.192.191h-.254a.191.191 0 0 1-.191-.19l-.004-1.737-1.737-.004a.191.191 0 0 1-.19-.191v-.255a.191.191 0 0 1 .19-.19h2.186a.191.191 0 0 1 .192.19z" _fill="#899ba3"/><g data-name="chevron-right"><path pid="1" data-name="Path 4279" d="M1.363 1.172H3.55a.191.191 0 0 1 .191.192v.254a.191.191 0 0 1-.19.191l-1.737.004L1.81 3.55a.191.191 0 0 1-.191.19h-.255a.191.191 0 0 1-.19-.19V1.364a.191.191 0 0 1 .19-.192z" _fill="#899ba3"/></g></g>'
  }
})
