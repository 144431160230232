<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <div class="modal-content-wrapper" v-if="!loading">
            <div v-if="areStudentsAvailable">
              <div class="header-title flex-global">
                <div class="icon-wrapper">
                  <svgicon name="large-send" :fill="false" :original="true" height="52px"></svgicon>
                </div>
                <div class="title-wrapper">
                  <h4>
                    {{ $t('group.group_reflection_send_again_title') }}
                  </h4>
                </div>
              </div>
              <div class="modal-border"></div>
              <div class="template-info flex-global">
                <img
                  class="template-icon"
                  v-if="questionnaire.icon"
                  :src="questionnaire.icon"
                  alt="template-icon"
                />
                <svgicon
                  class="template-icon"
                  v-else
                  name="methods/logo-grey"
                  :original="true"
                ></svgicon>
                <span>{{ questionnaire.title || '-' }}</span>
              </div>
              <p class="template-reflection-time">
                <span>
                  <svgicon name="clock" :original="true" height="14px"></svgicon>
                  {{ moment(reflectionDate).format('LLL') }}
                </span>
              </p>
              <div class="modal-content">
                <div class="display-box flex-global members-box">
                  <span class="display-box__label">{{ $t('group.group_members_alt') }}</span>
                  <div class="display-box__box">
                    <div class="display-box__overflow">
                      <div
                        v-for="student in studentList"
                        :key="`${student.id}`"
                        class="group-member-checkbox flex-global"
                      >
                        <div class="custom-checkbox">
                          <input
                            type="checkbox"
                            :id="student.id"
                            :value="student"
                            v-model="selectedStudents"
                          />
                          <label :for="student.id"></label>
                        </div>
                        <span class="member-name">{{ student.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="display-box flex-global selected-members-box">
                  <span class="display-box__label">{{ $t('group.selected_group_members') }}</span>
                  <div class="display-box__box">
                    <div class="display-box__clear-icon flex-global">
                      <svgicon
                        @click="clearAllSelectedStudents"
                        color="grey"
                        :fill="false"
                        height="10px"
                        name="multiply"
                      ></svgicon>
                    </div>
                    <div class="display-box__student-list flex-global">
                      <div
                        class="display-box__student-list-item"
                        v-for="student in selectedStudents"
                        :key="student.id"
                      >
                        <span>{{ student.name }}</span>
                        <svgicon
                          @click="clearSelectedStudent(student.id)"
                          color="#fff"
                          :fill="false"
                          height="8px"
                          name="multiply"
                        ></svgicon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__container__actions">
                <button-3 @click.native="handleModalToggle">
                  {{ $t('actions.cancel_action') }}
                </button-3>
                <button-4
                  @click.native="handleModalAction"
                  :disabled="disableButton || !selectedStudents || !selectedStudents.length"
                >
                  {{ $t('actions.save_action_2') }}
                </button-4>
              </div>
            </div>
            <div v-else>
              <h4 class="no-students-message">
                {{ $t('group.group_reflection_send_again_no_students_message') }}
              </h4>
            </div>
          </div>
          <loading v-else></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'SendReflectionAgainModal',
    components: {
      Button4,
      Button3,
      Loading,
    },
    props: ['loading', 'disableButton', 'questionnaire'],
    data() {
      return {
        selectedStudents: [],
      };
    },
    created() {
      this.handleGetStudents();
    },
    computed: {
      ...mapGetters('reflections', ['unparticipatedStudents', 'currentSelectedReflection']),
      reflectionId() {
        return this.currentSelectedReflection ? this.currentSelectedReflection.id : null;
      },
      reflectionDate() {
        return this.currentSelectedReflection ? this.currentSelectedReflection.start : null;
      },
      studentList() {
        return this.unparticipatedStudents && this.unparticipatedStudents.length
          ? this.unparticipatedStudents.map((student) => {
              return {
                id: student.id,
                name: `${student.name} ${student.lastName}`,
              };
            })
          : [];
      },
      areStudentsAvailable() {
        return this.unparticipatedStudents && this.unparticipatedStudents.length;
      },
    },
    methods: {
      ...mapActions('reflections', ['getUnparticipatedStudents']),
      handleModalToggle() {
        this.$emit('toggleModal');
      },
      clearAllSelectedStudents() {
        this.selectedStudents = [];
      },
      clearSelectedStudent(id) {
        this.selectedStudents = this.selectedStudents.filter((student) => student.id !== id);
      },
      handleModalAction() {
        const selectedStudentsIds = this.selectedStudents.map((student) => {
          return parseInt(student.id, 10);
        });
        this.$emit('handleSendReflectionAgainAction', {
          id: this.reflectionId,
          clients: selectedStudentsIds,
        });
      },
      handleGetStudents() {
        if (this.reflectionId) {
          this.getUnparticipatedStudents(this.reflectionId).then((res) => {
            const { success } = res;
            if(!success) this.handleModalToggle();
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    .no-students-message {
      color: $violet-5;
    }
    &__container {
      width: 461px;
      .modal-content-wrapper {
        width: 100%;
      }
      .header-title {
        justify-content: flex-start;
        padding: 0 10px;
        .icon-wrapper {
          svg {
            stroke: none;
          }
        }
        .title-wrapper {
          margin-left: 20px;
          h4 {
            width: 288px;
            font-family: Nunito-Regular;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            color: $violet-5;
          }
        }
      }
      .modal-border {
        margin-top: 24px;
        position: absolute;
        height: 3px;
        width: calc(100% + 40px);
        left: -40px;
        background-color: rgba(0, 0, 0, 0.3);
      }
      .template-info {
        justify-content: flex-start;
        margin-top: 43px;
        img {
          width: 33px;
          height: 33px;
          margin-right: 16px;
        }
        span {
          font-family: Roboto-Regular;
          font-style: normal;
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: $grey-5;
        }
      }
      .template-reflection-time {
        margin-top: 20px;
        text-align: left;
        span {
          font-family: Roboto-Regular;
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: $grey-5;
          svg {
            margin-right: 7px;
          }
        }
      }
      .modal-content {
        margin-top: 20px;
        .display-box {
          flex-direction: column;
          &__label {
            width: 100%;
            margin-bottom: 2px;
            font-family: Nunito-Regular;
            font-style: normal;
            font-size: 10px;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0.45px;
            text-transform: uppercase;
            text-align: left;
            color: $grey-3;
          }
          &__box {
            position: relative;
            width: 100%;
            padding: 2px 10px;
            padding-right: 0;
            border: 1px solid #b1b1b1;
          }
          &__overflow {
            height: 100%;
            overflow-y: auto;
          }
          &__clear-icon {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 3px;
            right: 3px;
            cursor: pointer;
          }
          &__student-list {
            justify-content: flex-start;
            flex-wrap: wrap;
          }
          &__student-list-item {
            margin-bottom: 4px;
            padding: 0 7px;
            border-radius: 2px;
            background-color: $grey-7;
            &:not(:last-child) {
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #fff;
            }
            svg {
              margin-left: 8px;
              cursor: pointer;
            }
          }
          &.members-box {
            .display-box__box {
              height: 235px;
            }
          }
          &.selected-members-box {
            margin-top: 20px;
            .display-box__box {
              min-height: 53px;
              padding: 4px 10px;
              padding-right: 20px;
              padding-bottom: 0;
            }
          }
        }
        .group-member-checkbox {
          justify-content: flex-start;
          height: 28px;
          .member-name {
            margin-left: 10px;
            font-family: Roboto-Regular;
            font-style: normal;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: $grey-5;
          }
        }
      }
    }
  }
</style>
