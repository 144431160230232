<template>
  <div class="not-found__page">

    <section id="not-found" class="not-found">
      <div class="not-found__box">
        <header-h1 v-if="msg">
          {{msg}}
        </header-h1>
        <header-h1 v-else>
          ¯\_(ツ)_/¯
        </header-h1>
        <div class="not-found__box__actions">
          <router-link :to="{name: 'Home'}">
          <button-3>{{ $t("actions.back_action") }}</button-3>
          </router-link>
        </div>
        <div class="not-found__box__icons">
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--dotted-square"
            name="404/dotted-square"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--half-circle"
            name="404/half-circle"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-1"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-2"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--question-mark"
            name="404/question-mark"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--weave"
            name="404/weave"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--x"
            name="404/x"
            :original="true"
          ></svgicon>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';


import Button3 from '@/components/elements/buttons/Button3.vue';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

export default {
  name: 'EmailResend',
  components: {
    Button3,
    HeaderH1,
  },
  data() {
    return {
      msg: null,
    };
  },
  created() {
    const { email } = this.$route.params;
    if (email) {
      this.resendEmail(email).then((res) => {
        const { success } = res;
        if (success) this.msg = 'Pašto patvirtinimo nuoroda nusiųsta';
      });
    }
  },
  methods: {
    ...mapActions('user', ['resendEmail']),
  },
};
</script>
