/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning-small': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M12 2.41l8.785 15.178H3.215L12 2.41zM1.197 16.42c-.899 1.552.221 3.5 2.018 3.5h17.57c1.797 0 2.917-1.948 2.018-3.5L14.018 1.243c-.898-1.552-3.138-1.552-4.036 0L1.197 16.42zm9.636-8.166v2.333A1.17 1.17 0 0 0 12 11.755a1.17 1.17 0 0 0 1.167-1.167V8.255A1.17 1.17 0 0 0 12 7.088a1.17 1.17 0 0 0-1.167 1.167zm0 5.833h2.334v2.333h-2.334v-2.333z" _fill="#3D3D3D"/>'
  }
})
