<template>
  <figure class="loading">
    <img :src="loading" alt="loading-image" class="loading__img">
  </figure>
</template>
<script>
import loading from '@/assets/gif/loading.gif';

export default {
  name: 'Loading',
  data() {
    return {
      loading,
    };
  },
};
</script>
<style lang="scss">
  .loading {
    margin: 0 auto;
    width: 200px;
    height: 160px;

    &__img {
      width: 100%;
      height: 100%;
    }
  }
</style>
