/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save': {
    width: 19.626,
    height: 19.626,
    viewBox: '0 0 19.626 19.626',
    data: '<g data-name="Group 3908"><g data-name="Group 3442"><path pid="0" data-name="Path 1185" d="M18.976 7.984v10.993H.65V.651h10.993z" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.3"/><path pid="1" data-name="Rectangle 4184" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.3" d="M3.763 12.443h11.912v6.534H3.763z"/><path pid="2" data-name="Path 1186" d="M12.378 1.385v5.8H3.763V.651" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.3"/><path pid="3" data-name="Rectangle 4185" _fill="#3b328b" d="M3.763.651H7.92v6.534H3.763z"/></g></g>'
  }
})
