/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '404/weave': {
    width: 54.846,
    height: 21.953,
    viewBox: '0 0 54.846 21.953',
    data: '<g data-name="Group 3908"><path pid="0" data-name="Path 3867" d="M46.544 10.047a9.977 9.977 0 0 1-7.888-3.832 6.183 6.183 0 0 0-9.717 0 10.037 10.037 0 0 1-15.778 0 6.183 6.183 0 0 0-9.717 0A1.928 1.928 0 0 1 .413 3.832a10.037 10.037 0 0 1 15.778 0 6.183 6.183 0 0 0 9.717 0 10.038 10.038 0 0 1 15.779 0 6.182 6.182 0 0 0 9.716 0 1.928 1.928 0 1 1 3.031 2.383 9.979 9.979 0 0 1-7.89 3.832zm7.89 8.075a1.928 1.928 0 0 0-3.031-2.384 6.18 6.18 0 0 1-9.716 0 10.039 10.039 0 0 0-15.779 0 6.182 6.182 0 0 1-9.717 0 10.037 10.037 0 0 0-15.778 0 1.928 1.928 0 1 0 3.031 2.384 6.183 6.183 0 0 1 9.717 0 10.038 10.038 0 0 0 15.778 0 6.183 6.183 0 0 1 9.717 0 10.038 10.038 0 0 0 15.778 0z" _fill="#f3a91f"/></g>'
  }
})
