/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tab-view-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Group 3831"><path pid="0" data-name="Rectangle 5801" _fill="#d1d1d1" d="M0 0h20v20H0z"/><path pid="1" data-name="Rectangle 5803" _fill="none" _stroke="#fff" stroke-linejoin="round" d="M7 4h9v9H7z"/><path pid="2" data-name="Path 3584" d="M3.7 6.068v9.973h10.638" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></g>'
  }
})
