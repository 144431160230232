<template>
  <div id="app">
    <top-menu></top-menu>
    <transition name="view-transition">
      <router-view style="margin-bottom: 60px;" :key="$route.fullPath" />
    </transition>
    <transition name="footer-transition">
      <Footer :key="$route.fullPath" />
    </transition>
    <modal
      v-if="loginModal"
      v-show="loginModal"
      @close="toggleModal({ type: 'login', status: false })"
      :login="true"
    ></modal>
    <error-modal v-if="apiError"></error-modal>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import 'vue2-timepicker/dist/VueTimepicker.css';

  import '@/components/icons';
  import '@/components/icons/emotes';
  import '@/components/icons/logos';
  import Footer from '@/components/base/Footer.vue';
  import Modal from '@/components/calendar/Modal.vue';
  import TopMenu from '@/components/Menu/TopMenu.vue';
  import ErrorModal from '@/components/base/ErrorModal.vue';

  export default {
    name: 'App',
    components: {
      Footer,
      Modal,
      TopMenu,
      ErrorModal,
    },
    watch: {
      $route: {
        handler(to, from) {
          if (to.params && to.params.orgId && to.params.orgId !== 0) {
            this.handleSetCurrentActiveOrganization(to.params.orgId);
          }
          const body = document.getElementsByTagName('body')[0];
          body.classList.add('page');
          if (from !== undefined) {
            body.classList.remove(`page--${from.name.toLowerCase()}`);
          }
          body.classList.add(`page--${to.name.toLowerCase()}`);
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters('user', ['loginModal']),
      ...mapGetters('meta', ['apiError']),
    },
    methods: {
      ...mapActions('user', ['toggleModal', 'setCurrentActiveOrganization']),
      handleSetCurrentActiveOrganization(id) {
        this.setCurrentActiveOrganization(id);
      },
    },
  };
</script>

<style lang="scss">
  @import 'global';
  @import 'fonts';
</style>
