/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tab-view-on': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Group 3828"><path pid="0" data-name="Rectangle 4397" _fill="#919396" d="M0 0h20v20H0z"/><path pid="1" data-name="Rectangle 4399" _fill="none" _stroke="#fff" stroke-linejoin="round" d="M7 4h9v9H7z"/><path pid="2" data-name="Path 1262" d="M3.7 6.068v9.973h10.638" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></g>'
  }
})
