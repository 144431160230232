/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.33.696l-.444-.444a.86.86 0 0 0-1.216 0L9.062.86l1.66 1.66.608-.608a.86.86 0 0 0 0-1.216zM10.258 3.03l-1.66-1.66-4.5 4.5-.462 1.97a.17.17 0 0 0 .21.203l1.912-.512 4.5-4.5z" _fill="#5147A7"/><path pid="1" d="M5.159 2.053H1.707A.707.707 0 0 0 1 2.76v7.045c0 .39.317.708.707.708h6.31c.392 0 .708-.317.708-.708V6.976" _stroke="#5147A7" stroke-width="1.52" stroke-miterlimit="10" stroke-linecap="round"/>'
  }
})
