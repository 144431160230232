/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-evil': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.67',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" d="M6.13 4.71a.68.68 0 0 0-.38-.55A12.43 12.43 0 0 1 1.47.29.69.69 0 0 0 .22.61c-.57 4.79.51 6.71 1 7.32a.68.68 0 0 0 .53.26.69.69 0 0 0 .4-.19l3.7-2.72a.68.68 0 0 0 .28-.57zM20.57 4.71a.68.68 0 0 1 .43-.56A12.43 12.43 0 0 0 25.24.29a.69.69 0 0 1 1.25.31c.57 4.79-.51 6.71-1 7.32a.68.68 0 0 1-.53.26.69.69 0 0 1-.41-.18l-3.7-2.72a.68.68 0 0 1-.28-.57z" _fill="#ff4141"/><ellipse pid="1" cx="13.35" cy="13.36" rx="13.35" ry="13.31" _fill="#ff4343"/><path pid="2" d="M20.34 2a13.22 13.22 0 0 1 2.1 7.17A13.36 13.36 0 0 1 2.1 20.52 13.34 13.34 0 1 0 20.34 2z" _fill="#ff4242"/><path pid="3" d="M1.81 14.77A12.4 12.4 0 0 1 24 7.23a12.39 12.39 0 1 0-20.54 13.7 12.28 12.28 0 0 1-1.65-6.16z" _fill="#ff5c5c"/><path pid="4" d="M7.88 15.32a.44.44 0 0 0-.72.48 6.7 6.7 0 0 0 12.38.12.44.44 0 0 0-.71-.48c-1.95 1.89-6.1 4.56-10.95-.12z" _fill="#654735"/><ellipse pid="5" cx="8.43" cy="11.71" rx=".98" ry="1.65" _fill="#2c2c2c"/><ellipse pid="6" cx="18.27" cy="11.71" rx=".98" ry="1.65" _fill="#2c2c2c"/><path pid="7" _fill="#2c2c2c" d="M11.21 9.2l-.9.9-2.99-2.98.9-.9 2.99 2.98zM16.39 10.1l-.89-.9 2.99-2.98.89.9-2.99 2.98z"/></g></g>'
  }
})
