/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/color-pick-close': {
    width: 18.989,
    height: 18.99,
    viewBox: '0 0 18.989 18.99',
    data: '<g data-name="Group 3150" _fill="none" _stroke="#5454c4"><path pid="0" data-name="Rectangle 4461" stroke-linejoin="round" d="M.5.5h17.99v17.99H.5z"/><g data-name="Group 2406" stroke-linecap="round" stroke-miterlimit="10"><path pid="1" data-name="Path 32" d="M12.899 5.819L9.547 9.17 6.195 5.82"/><path pid="2" data-name="Path 33" d="M6.195 12.522L9.547 9.17l3.352 3.352"/></g></g>'
  }
})
