/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grip': {
    width: 8,
    height: 13,
    viewBox: '0 0 8 13',
    data: '<g data-name="Group 4150" transform="translate(13393 -18096)" _fill="#b2b2b2"><circle pid="0" data-name="Ellipse 2026" cx="1.5" cy="1.5" r="1.5" transform="translate(-13393 18096)"/><circle pid="1" data-name="Ellipse 2029" cx="1.5" cy="1.5" r="1.5" transform="translate(-13393 18101)"/><circle pid="2" data-name="Ellipse 2031" cx="1.5" cy="1.5" r="1.5" transform="translate(-13393 18106)"/><circle pid="3" data-name="Ellipse 2027" cx="1.5" cy="1.5" r="1.5" transform="translate(-13388 18096)"/><circle pid="4" data-name="Ellipse 2028" cx="1.5" cy="1.5" r="1.5" transform="translate(-13388 18101)"/><circle pid="5" data-name="Ellipse 2030" cx="1.5" cy="1.5" r="1.5" transform="translate(-13388 18106)"/></g>'
  }
})
