<template>
  <p>
    <slot/>
  </p>
</template>
<script>
export default {
  name: 'Paragraph',
};
</script>
<style lang="scss" scoped>
@import "../../../scss/colors";
p {
  margin: 0;
  padding: 0;
  font-family: Roboto-Regular;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: $grey-5;
}
</style>
