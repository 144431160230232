var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group__header"},[_c('div',{staticClass:"spacer"}),(_vm.currentGroup)?_c('div',{staticClass:"group__header-title"},[_c('switch-nav',{key:'groupNav',staticClass:"group__header__nav",attrs:{"links":[
        {
          link: { name: 'Group', params: { groupId: _vm.currentGroup.id } },
          description: _vm.navigationTitles[0],
        },
        {
          link: { name: 'GroupMembers', params: { groupId: _vm.currentGroup.id } },
          description: _vm.navigationTitles[1],
        } ]}})],1):_vm._e(),_c('div',{staticClass:"group__header-actions"},[_c('div',{staticClass:"orange-button"},[_c('router-link',{attrs:{"to":{ name: 'CreateReflection' }}},[_c('button-4',[_vm._v(_vm._s(_vm.$t("actions.new_questionnaire_action")))])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }