/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/list': {
    width: 8.77,
    height: 9.973,
    viewBox: '0 0 8.77 9.973',
    data: '<g data-name="Group 1114"><g data-name="Group 211" _fill="none" _stroke="#33ca97" stroke-linecap="round" stroke-linejoin="round" stroke-width=".527"><path pid="0" data-name="Path 212" d="M3.178 9.709H.866a.6.6 0 0 1-.6-.6V.861a.6.6 0 0 1 .6-.6h5.678a.6.6 0 0 1 .6.6v3.6"/><path pid="1" data-name="Path 213" d="M8.272 5.936a.8.8 0 0 0-1.127 0L4.918 8.163l-.391 1.51 1.518-.383 2.227-2.227a.8.8 0 0 0 0-1.127z"/><path pid="2" data-name="Line 54" d="M6.814 6.321l1.024 1.024"/><g data-name="Group 210"><path pid="3" data-name="Line 55" d="M1.683 2.695h4.043"/><path pid="4" data-name="Line 56" d="M1.683 4.276h4.043"/><path pid="5" data-name="Line 57" d="M1.683 5.857h2.4"/></g></g></g>'
  }
})
