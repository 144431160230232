/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import moment from 'moment';
import i18n from '../../plugins/i18n';

import {
  handleQuestionTitle,
  handleQuestionOptionErrors,
  handleQuestionPartErrors,
  handleAllQuestionnaireErrors,
  handleQuestionErrors,
  handleAnswerOptionsErrors,
  calculateDuration,
  handleQuestions,
  handleGetQuestionnaireParams,
  handleChangeMethodId,
} from './helpers/questionnaireHelpers';

import {
  handleResponse,
  handleCommitResponse,
  handleResponseError,
} from './helpers/responseHelpers';

const getDefaultState = () => ({
  questionnaire: {
    title: null,
    description: null,
    clientGroupId: null,
    iconFileId: null,
    questions: [],
    schedules: [],
  },
  questionnaireIcon: null,
  questionTemplates: [],
  answerTemplate: [],
  groupQuestionnaireList: [],
  groupQuestionnaire: null,
  currentTab: 0,
  currentQuestion: null,
  currentAnswerTemplates: null,
  currentView: 'tabs',
  currentScheduleType: 'recurrent', // once/recurrent
  totalDuration: 0,
  questionnaireErrors: {
    title: null,
    description: null,
    questions: null,
    question: null,
    icon: null,
    part: null,
    option: null,
  },
  questionError: null,
  questionErrorCount: null,
  questionnaireSummary: null,
  noQuestionsError: null,
  durationDefaultValues: {
    minutes: 180,
    hours: 3,
    days: 1,
  },
  scheduleErrorCheck: false,
  currentParentId: null,
  questionExamples: [],
  questionnaireFilterData: null,
  scheduleIsEdit: false,
});

const state = getDefaultState();

const mutations = {
  GET_ANSWER_TEMPLATE(state, template) {
    state.answerTemplate = template;
  },
  ADD_QUESTION(state, question) {
    state.questionnaire.questions.push(question);
  },
  REMOVE_QUESTION(state, index) {
    state.questionnaire.questions.splice(index, 1);
    state.questionTemplates.splice(index, 1);
  },
  DUPLICATE_QUESTION(state, { index, question }) {
    state.questionnaire.questions.splice(index, 0, question);
    state.questionTemplates.splice(index, 0, question);
  },
  ADD_SCHEDULE(state, schedule) {
    state.questionnaire.schedules.push(schedule);
  },
  REMOVE_SCHEDULE(state, index) {
    state.questionnaire.schedules.splice(index, 1);
  },
  EDIT_CHILD_SCHEDULE(state, payload) {
    const found = state.questionnaire.schedules.find((sched) => sched.editId === payload.editId);

    if (payload && !payload.time) {
      const foundIndex = state.questionnaire.schedules.findIndex(
        (sched) => sched.editId === payload.editId
      );
      if (foundIndex > -1) {
        state.questionnaire.schedules.splice(foundIndex, 1);
      }
    } else {
      found.time = payload.time;
      found.duration = payload.duration;
      found.durationType = payload.durationType;
      found.fromDate = payload.fromDate;
      found.skip = payload.skip;
      found.toDate = payload.toDate;
      found.toDateEmpty = payload.toDateEmpty;
      found.weekStep = payload.weekStep;
    }
  },
  EDIT_ACTIVE_CHILD_SCHEDULE(state, payload) {
    const found = state.questionnaire.schedules.find((sched) => sched.id === payload.id);

    if (payload && !payload.time) {
      const foundIndex = state.questionnaire.schedules.findIndex(
        (sched) => sched.id === payload.id
      );
      if (foundIndex > -1) {
        state.questionnaire.schedules.splice(foundIndex, 1);
      }
    } else {
      found.time = payload.time;
      found.duration = payload.duration;
      found.durationType = payload.durationType;
      found.fromDate = payload.fromDate;
      found.skip = payload.skip;
      found.toDate = payload.toDate;
      found.toDateEmpty = payload.toDateEmpty;
      found.weekStep = payload.weekStep;
    }
  },
  SET_QUESTIONNAIRE_PROPERTY(state, { type, index, property, skipIndex }) {
    switch (type) {
      case 'id':
        state.questionnaire.id = property;
        break;
      case 'clientGroupId':
        state.questionnaire.clientGroupId = property;
        break;
      case 'time':
        state.questionnaire.schedules[index].time = property;
        break;
      case 'fromDate':
        state.questionnaire.schedules[index].fromDate = property;
        break;
      case 'toDate':
        state.questionnaire.schedules[index].toDate = property;
        state.questionnaire.schedules[index].toDateEmpty = false;
        break;

      case 'skip':
        state.questionnaire.schedules[index].skip[skipIndex] = property;
        break;
      case 'repeatType':
        // eslint-disable-next-line no-case-declarations
        const schedule = state.questionnaire.schedules[index];
        schedule.repeatType = property;
        switch (property) {
          case 'once':
            if (schedule.toDate) delete schedule.toDate;
            schedule.toDateEmpty = true;
            if (schedule.skip) schedule.skip = [];
            if (schedule.weekDays) delete schedule.weekDays;
            if (schedule.weekStep) delete schedule.weekStep;
            break;

          case 'daily':
            if (schedule.weekDays) delete schedule.weekDays;
            if (schedule.weekStep) delete schedule.weekStep;
            break;

          case 'custom':
            schedule.weekStep = 1;
            break;
          default:
            break;
        }
        break;
      case 'weekDays':
        if (!state.questionnaire.schedules[index].weekDays) {
          state.questionnaire.schedules[index].weekDays = [];
        }
        // eslint-disable-next-line no-case-declarations
        const weekDays = state.questionnaire.schedules[index].weekDays;

        if (weekDays.indexOf(property) !== -1) {
          weekDays.splice(weekDays.indexOf(property), 1);
        } else {
          weekDays.push(property);
        }
        break;
      case 'weekStep':
        state.questionnaire.schedules[index].weekStep = property;
        break;
      case 'duration':
        state.questionnaire.schedules[index].duration = property;
        break;
      case 'durationType':
        state.questionnaire.schedules[index].durationType = property;

        if (property === 'minutes') {
          state.questionnaire.schedules[index].duration = state.durationDefaultValues.minutes;
        }
        if (property === 'hours') {
          state.questionnaire.schedules[index].duration = state.durationDefaultValues.hours;
        }
        if (property === 'days') {
          state.questionnaire.schedules[index].duration = state.durationDefaultValues.days;
        }

        break;
      case 'title':
        state.questionnaire.title = property;
        break;
      case 'description':
        state.questionnaire.description = property;
        break;
      case 'questions':
        state.questionnaire.questions = property;
        break;
      case 'schedule':
        if (state.questionnaire.schedules.length) {
          state.questionnaire.schedules = [...state.questionnaire.schedules, ...property];
        }
        state.questionnaire.schedules = property;
        break;
      default:
        break;
    }
  },
  SET_SCHEDULE_PROPERTY(state, { type, index, skipIndex, property }) {
    const schedule = state.questionnaire.schedules[index];

    switch (type) {
      case 'time':
        schedule.time = property;
        break;
      case 'skip':
        if (property !== null) {
          schedule.skip[skipIndex] = property;
        } else {
          schedule.skip.splice(skipIndex, 1);
        }
        break;
      case 'editStatus':
        schedule.initialEditStatus = false;
        delete schedule.initialEditStatus;
        break;
      default:
        break;
    }
  },
  SET_QUESTION_PROPERTY(state, { index, optionIndex, type, property }) {
    const question = state.questionnaire.questions[index];
    switch (type) {
      case 'answerTemplate':
        question.answerTemplate = property;
        break;
      case 'value':
        question.answerOptions.options[optionIndex].value = property;
        break;
      case 'show':
        question.answerOptions.options[optionIndex].show = property;
        break;
      case 'title':
        question.title = property;
        break;
      default:
        break;
    }
  },
  SET_QUESTIONNAIRE_ICON(state, icon) {
    state.questionnaireIcon = icon;
  },
  SET_CURRENT_QUESTION(state, question) {
    state.currentQuestion = question;
  },
  SET_ANSWER_TEMPLATE(state, { index, type }) {
    state.questionnaire.questions[index].answerTemplate = type;
  },
  SET_OPTION_TYPE(state, { index, type }) {
    const { answerOptions } = state.questionnaire.questions[index];
    if (answerOptions.options) {
      answerOptions.options.forEach((option) => {
        option.show = null;
      });
    }
    answerOptions.type = type;
  },
  GET_CURRENT_ANSWER_TEMPLATES(state, template) {
    state.currentAnswerTemplates = template;
  },
  SET_CURRENT_TAB(state, tab) {
    state.currentTab = tab;
  },
  SET_CURRENT_VIEW(state, status) {
    state.currentView = status;
  },
  SET_SCHEDULE_TYPE(state, status) {
    state.currentScheduleType = status;
  },
  SET_TOTAL_DURATION(state, time) {
    state.totalDuration += time;
  },
  RESET_TOTAL_DURATION(state) {
    state.totalDuration = 0;
  },
  UNSET_QUESTIONNAIRE(state) {
    const questionnaire = {
      title: null,
      description: null,
      clientGroupId: null,
      questions: [],
      schedules: [],
    };

    state.questionnaire = questionnaire;
  },
  SET_QUESTIONNAIRE_SUMMARY(state, questionnaireSummary) {
    state.questionnaireSummary = questionnaireSummary;
  },
  SET_QUESTIONNAIRE(state, questionnaire) {
    state.questionnaire = questionnaire;
  },
  GET_QUESTIONNAIRE_BY_GROUP(state, questionnaires) {
    state.groupQuestionnaireList = questionnaires;
  },
  SET_GROUP_QUESTIONNAIRE(state, questionnaire) {
    state.groupQuestionnaire = questionnaire;
  },
  SET_QUESTIONNAIRE_ERRORS(state, { type, error, reset }) {
    Object.entries(state.questionnaireErrors).forEach(([key]) => {
      if (reset) {
        state.questionnaireErrors[key] = null;
      }
      if (type === key) {
        state.questionnaireErrors[key] = error;
      }
    });
  },
  SET_QUESTION_ERROR(state, { index, error }) {
    const question = state.questionnaire.questions[index];
    if (error === null) {
      delete question.error;
    } else {
      question.error = error;
    }
  },
  SET_QUESTION_ERROR_COUNT(state, amount) {
    state.questionErrorCount = amount;
  },
  SET_ANSWER_OPTION_ERROR(state, { questionIndex, optionKey, error }) {
    const question = state.questionnaire.questions[questionIndex];
    const { errors } = question.answerOptions;
    errors[optionKey] = error;
  },
  SET_PART_ERROR(state, { questionIndex, partIndex, error }) {
    const part = state.questionnaire.questions[questionIndex].parts[partIndex];
    if (error === null) {
      delete part.error;
    } else {
      part.error = error;
    }
  },
  SET_OPTION_ERROR(state, { questionIndex, optionIndex, error }) {
    const option = state.questionnaire.questions[questionIndex].answerOptions.options[optionIndex];
    if (error) {
      option.error = error;
    } else {
      delete option.error;
    }
  },
  SET_NO_QUESTIONS_ERROR(state) {
    state.noQuestionsError = i18n.t('warnings.no_methods_selected_warning');
    setTimeout(() => {
      state.noQuestionsError = null;
    }, 3000);
  },
  SET_SCHEDULE_ERROR_CHECK(state, value) {
    state.scheduleErrorCheck = value;
    const el = document.getElementsByClassName('schedule-error-message')[0];
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  },
  SET_CURRENT_PARENT_ID(state, payload) {
    state.currentParentId = payload;
  },
  GET_EXAMPLE_QUESTIONS(state, template) {
    state.questionExamples = template;
  },
  GET_QUESTIONNAIRE_FILTER_DATA(state, filterData) {
    state.questionnaireFilterData = filterData;
  },
  EDIT_QUESTION_WITH_EXAMPLE(state, payload) {
    const currentQuestion = state.questionnaire.questions[state.currentTab];
    const exampleQuestion = JSON.parse(JSON.stringify(payload));
    const { answerOptions: exampleQuestionAnswerOptions } = exampleQuestion;

    currentQuestion.title = exampleQuestion.title;
    if (exampleQuestion.methodId === 5) {
      // handle incomplete_sentences
      const { parts: exampleQuestionParts } = exampleQuestion;

      currentQuestion.answerOptions = {
        ...currentQuestion.answerOptions,
        maxLength: parseInt(exampleQuestionAnswerOptions.maxLength, 10),
        errors: {},
      };
      currentQuestion.parts[0] = {
        ...currentQuestion.parts[0],
        answerCount: parseInt(exampleQuestionParts[0].answerCount, 10),
        title: exampleQuestionParts[0].title,
        answerOptions: {
          maxLength: exampleQuestionAnswerOptions.maxLength,
        },
        maxLength: parseInt(exampleQuestionAnswerOptions.maxLength, 10),
      };
    } else if (exampleQuestion.methodId === 4) {
      // handle question
      currentQuestion.answerOptions = {
        ...currentQuestion.answerOptions,
        maxLength: parseInt(exampleQuestionAnswerOptions.maxLength, 10),
      };
    } else if (exampleQuestion.methodId === 8) {
      // handle thermometer
      currentQuestion.answerOptions = {
        ...currentQuestion.answerOptions,
        ...exampleQuestionAnswerOptions,
        errors: {},
      };
    } else {
      // handle remaining methods
      currentQuestion.answerOptions = {
        ...currentQuestion.answerOptions,
        options: exampleQuestionAnswerOptions.options,
        type: exampleQuestionAnswerOptions.type,
        view: exampleQuestionAnswerOptions.view,
      };
    }

    // specific edits for methods
    switch (exampleQuestion.methodId) {
      // yes/no
      case 1:
        currentQuestion.answerOptions.options[0].positive = true;
        currentQuestion.answerOptions.options[1].positive = false;
        break;
      // smileys
      case 3:
        currentQuestion.answerOptions.options.push({ value: null, show: null });
        break;
      default:
        break;
    }
  },
  SET_SCHEDULE_EDIT(state, payload) {
    state.scheduleIsEdit = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_QUESTIONNAIRE_FILTER_DATA(state) {
    state.questionnaireFilterData = null;
  },
};

const actions = {
  getAnswerTemplate({ commit }) {
    return axios
      .get('/reflection/answertemplate/list')
      .then((res) => handleCommitResponse(res, commit, 'GET_ANSWER_TEMPLATE'))
      .catch(handleResponseError);
  },
  getCurrentAnswerTemplates({ commit, getters }, payload) {
    const { availableAnswerTemplates } = payload;

    const templates = getters.answerTemplate;
    const template = templates
      .map((item) => {
        const answerTemplates = availableAnswerTemplates
          .map((template) => (template === item.code ? item : null))
          .filter((item) => item !== null);
        return answerTemplates;
      })
      .filter((item) => item.length >= 1);

    const templateArray = [];
    template.forEach((item) => {
      if (item[0]) {
        templateArray.push(item[0]);
      }
    });

    commit('GET_CURRENT_ANSWER_TEMPLATES', templateArray);
  },
  addQuestion({ commit }, payload) {
    const { id, duration, availableAnswerTemplates } = payload;
    const question = {
      title: null,
      methodId: id,
      answerTemplate: null,
    };

    if (availableAnswerTemplates.length === 1) {
      question.answerTemplate = availableAnswerTemplates[0];
    } else if (id === 4) {
      question.answerTemplate = 'text';
    }

    if (
      availableAnswerTemplates.length === 1 &&
      availableAnswerTemplates[0] === 'select' &&
      id !== 16
    ) {
      const defaults = JSON.parse(JSON.stringify(payload.answerOptions.defaults.select));
      question.answerOptions = defaults;
      if (id === 1) {
        question.answerOptions.options[0].positive = true;
        question.answerOptions.options[1].positive = false;
      }
      if (id === 12) {
        question.parts = [{ title: null, answerOptions: { ...defaults } }];
      }
      question.answerOptions.errors = {};
    } else if (id === 4) {
      question.answerOptions = {
        maxLength: 500,
        errors: {},
      };
    } else if (id === 8) {
      const defaults = JSON.parse(JSON.stringify(payload.answerOptions.defaults.slider));
      question.answerOptions = {
        errors: {},
        ...defaults,
      };
    } else if (id === 16) {
      question.answerOptions = {
        options: [
          { value: null, show: null },
          { value: null, show: null },
        ],
        type: 'text',
        view: 'box',
        errors: {},
      };
    } else question.answerOptions = { errors: {} };

    commit('SET_TOTAL_DURATION', duration);
    commit('ADD_QUESTION', question);
  },
  removeQuestion({ commit, dispatch, getters, rootGetters }, payload) {
    commit('REMOVE_QUESTION', payload);
    const selectedMethod = rootGetters['methods/selectedMethod'];

    if (selectedMethod) {
      const { duration } = selectedMethod;
      if (duration) {
        commit('SET_TOTAL_DURATION', duration * -1);
      }
    }
    dispatch('methods/onMethodUnset', null, { root: true });
    const questions = getters.questions;
    if (questions.length) {
      dispatch('setCurrentTab', questions.length - 1);
      dispatch('setCurrentQuestion');
    }
  },
  duplicateQuestion({ commit, getters, dispatch, rootGetters }, payload) {
    const question = JSON.parse(JSON.stringify(getters.currentQuestion));
    const { parts: newParts } = question;
    if (newParts && newParts.length) {
      newParts.forEach((part) => {
        part.id = null;
      });
    }
    commit('DUPLICATE_QUESTION', {
      index: payload,
      question: { ...question, id: null, parts: newParts },
    });
    dispatch('setCurrentTab', payload + 1);
    dispatch('setCurrentQuestion');
    const { duration } = rootGetters['methods/selectedMethod'];
    commit('SET_TOTAL_DURATION', duration);
  },
  addSchedule({ commit }, type) {
    const newSchedule = {
      time: moment()
        .add(5, 'minutes')
        .format('HH:mm'),
      fromDate: moment().format('YYYY-MM-DD'),
      duration: 180,
      repeatType: !type || type === 'once' ? 'once' : 'custom',
      durationType: 'minutes',
      // weekDays: [],
      skip: [],
      toDateEmpty: true,
      initialEditStatus: true,
    };
    const newScheduleCustom = {
      time: moment()
        .add(5, 'minutes')
        .format('HH:mm'),
      fromDate: moment().format('YYYY-MM-DD'),
      duration: 180,
      repeatType: !type || type === 'once' ? 'once' : 'custom',
      durationType: 'minutes',
      weekStep: 1,
      skip: [],
      toDateEmpty: true,
      initialEditStatus: true,
    };
    commit('ADD_SCHEDULE', type === 'once' ? newSchedule : newScheduleCustom);
  },
  addChildSchedule({ commit }, payload) {
    const {
      time,
      fromDate,
      toDate,
      duration,
      repeatType,
      durationType,
      weekDays,
      weekStep,
      skip,
      toDateEmpty,
      parentScheduleId,
      parentIndex,
      editId,
    } = payload;
    const schedule = {
      time,
      fromDate,
      toDate,
      duration,
      repeatType,
      durationType,
      weekDays,
      weekStep,
      skip,
      toDateEmpty,
      isChild: true,
      parentScheduleId,
      parentIndex,
      editId,
    };
    commit('ADD_SCHEDULE', schedule);
  },
  async handleRemoveSchedule({ state, commit }, payload) {
    try {
      if (payload.schedule.repeatType === 'custom') {
        const schedules = state.questionnaire.schedules;

        // remove child schedules
        for (let i = schedules.length - 1; i >= 0; i -= 1) {
          if (
            (schedules[i].id && schedules[i].parentScheduleId === payload.schedule.id) ||
            (!schedules[i].id && schedules[i].parentIndex === payload.i)
          ) {
            if (schedules[i].id !== undefined)
              await axios.delete(`/reflection/schedule/delete/${schedules[i].id}`);

            commit('REMOVE_SCHEDULE', i);
          }
        }
      }

      if (payload.schedule.id !== undefined)
        await axios.delete(`/reflection/schedule/delete/${payload.schedule.id}`);

      commit('REMOVE_SCHEDULE', payload.i);
      return Promise.resolve({ success: true });
    } catch (error) {
      return handleResponseError(error);
    }
  },
  async removeSchedule({ commit }, payload) {
    try {
      if (payload.schedule.id !== undefined)
        await axios.delete(`/reflection/schedule/delete/${payload.schedule.id}`);

      commit('REMOVE_SCHEDULE', payload.i);
      return Promise.resolve({ success: true });
    } catch (error) {
      return handleResponseError(error);
    }
  },
  async removeChildSchedule(context, payload) {
    try {
      if (payload.id !== undefined) await axios.delete(`/reflection/schedule/delete/${payload.id}`);

      return Promise.resolve({ success: true });
    } catch (error) {
      return handleResponseError(error);
    }
  },
  async editChildSchedule({ commit }, payload) {
    commit('EDIT_CHILD_SCHEDULE', payload);
    return Promise.resolve({ success: true });
  },
  async editActiveChildSchedule({ commit }, payload) {
    commit('EDIT_ACTIVE_CHILD_SCHEDULE', payload);
    return Promise.resolve({ success: true });
  },
  setQuestionnaireProperty({ commit }, payload) {
    commit('SET_QUESTIONNAIRE_PROPERTY', payload);
  },
  setScheduleProperty({ commit }, payload) {
    commit('SET_SCHEDULE_PROPERTY', payload);
  },
  setQuestionProperty({ commit }, payload) {
    commit('SET_QUESTION_PROPERTY', payload);
  },
  async setCurrentQuestion({ commit, getters, rootGetters, dispatch }, payload) {
    const question = await getters.questions[getters.currentTab || 0];
    if (!question) return;
    const { methodId } = question;
    const method = rootGetters['methods/questionTypeList'].find((method) => method.id === methodId);
    dispatch('methods/onMethodSelect', method, { root: true });

    if (payload) {
      commit('SET_CURRENT_QUESTION', payload);
      return;
    }
    commit('SET_CURRENT_QUESTION', question);
  },
  setAnswerTemplate({ commit }, payload) {
    commit('SET_ANSWER_TEMPLATE', payload);
  },
  setOptionType({ commit }, payload) {
    commit('SET_OPTION_TYPE', payload);
  },
  setCurrentTab({ commit }, payload) {
    commit('SET_CURRENT_TAB', payload);
  },
  setCurrentView({ commit }, payload) {
    commit('SET_CURRENT_VIEW', payload);
  },
  setScheduleType({ commit }, payload) {
    commit('SET_SCHEDULE_TYPE', payload);
  },
  editQuestionWithSelectedExample({ commit }, payload) {
    commit('EDIT_QUESTION_WITH_EXAMPLE', payload);
  },
  unsetQuestionnaire({ commit }) {
    commit('UNSET_QUESTIONNAIRE');
    commit('SET_CURRENT_VIEW', 'tabs');
    commit('SET_QUESTIONNAIRE_ERRORS', { reset: true });
    return Promise.resolve({ msg: 'Questionnaire unset', success: true });
  },
  createQuestionnaire(context, payload) {
    const { questions } = payload;
    handleQuestions(questions);

    return axios
      .post('/reflection/questionnaire/create', payload)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  createTemplate(context, payload) {
    const { questions, title, description, templateType, icon } = payload;

    handleQuestions(questions);

    const template = {
      title,
      description,
      recommendations: 'Rekomendacijos',
      public: false,
      questions,
      icon,
    };
    if (templateType === 'template') {
      template.public = false;
      return axios
        .post('/reflection/template/create', template)
        .then(handleResponse)
        .catch(handleResponseError);
    }
    delete payload.schedules;
    return axios
      .post('/reflection/questionnaire/create', payload)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  createFromTemplate(context, payload) {
    return axios
      .post(`/reflection/questionnaire/createFromTemplate/${payload}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  setQuestionnaireIcon({ commit }, payload) {
    commit('SET_QUESTIONNAIRE_ICON', payload);
  },
  uploadIcon({ getters }, payload) {
    const icon = getters.questionnaireIcon;
    if (!payload || !icon) return Promise.resolve({ success: false });
    const { id, type } = payload;
    const formData = new FormData();
    formData.append('file', icon);
    if (type === 'draft' || type === 'new') {
      return axios
        .post(`/reflection/questionnaire/uploadIcon/${id}`, formData)
        .then(handleResponse)
        .catch(handleResponseError);
    }

    return axios
      .post(`/reflection/template/uploadIcon/${id}`, formData)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  setQuestionnaireSummary({ state, commit, rootGetters }, payload) {
    const { clientGroupId, schedules } = state.questionnaire;
    const questionnaireSummary = {};
    const clientGroup = rootGetters['groups/groupList'];
    if (clientGroupId && clientGroup.length) {
      questionnaireSummary.group = clientGroup.find(
        (group) => group.id === clientGroupId * 1
      ).title;
    }
    if (schedules) {
      questionnaireSummary.schedules = schedules;
      questionnaireSummary.schedules.forEach((schedule) => {
        const { weekDays } = schedule;
        schedule.daysOfWeek = [];
        if (weekDays) {
          const stringWeekDays = [
            i18n.t('date_and_time.day_monday_abb'),
            i18n.t('date_and_time.day_tuesday_abb'),
            i18n.t('date_and_time.day_wednesday_abb'),
            i18n.t('date_and_time.day_thursday_abb'),
            i18n.t('date_and_time.day_friday_abb'),
            i18n.t('date_and_time.day_saturday_abb'),
            i18n.t('date_and_time.day_sunday_abb'),
          ];
          stringWeekDays.forEach((day, index) => {
            if (weekDays.includes(index + 1)) {
              schedule.daysOfWeek.push(day);
            }
          });
        }
      });
    }

    // ignore schedule error check on draft send action
    if(!payload || (payload && payload !== 'draftUpdate')) commit('SET_SCHEDULE_ERROR_CHECK', true);
    commit('SET_QUESTIONNAIRE_SUMMARY', questionnaireSummary);
  },
  getQuestionnaire({ commit, rootGetters }, payload) {
    const { questionnaireId, reflectionDate } = payload;
    return axios
      .get(`/reflection/questionnaire/get/${questionnaireId}`, {
        params: {
          reflectionDate,
        },
      })
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          const { questions } = data;
          handleGetQuestionnaireParams(data);

          commit('SET_QUESTIONNAIRE', data);
          commit('SET_CURRENT_VIEW', 'list');

          const methods = rootGetters['methods/questionTypeList'];
          calculateDuration(methods, questions, commit);

          return Promise.resolve({ success, data, msg: i18n.t('warnings.success_action_message') });
        }
        return Promise.resolve({
          msg: i18n.t('warnings.questionnaire_not_found_warning'),
          success: false,
        });
      })
      .catch(handleResponseError);
  },
  getQuestionnaireSchedule({ commit }, payload) {
    const id = payload;
    return axios
      .get(`/reflection/schedule/getByQuestionnaire/${id}`)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          const payload = {
            type: 'schedule',
            property: data,
          };
          commit('SET_QUESTIONNAIRE_PROPERTY', payload);
          return Promise.resolve({
            success,
            msg: i18n.t('warnings.questionnaire_schedules_added_message'),
            schedules: data,
          });
        }
        return Promise.resolve({
          success,
          msg: i18n.t('warnings.no_schedule_quest_found_message'),
        });
      })
      .catch(handleResponseError);
  },
  async updateQuestionnaire({ state, dispatch }) {
    const payload = JSON.parse(JSON.stringify(state.questionnaire));
    const { id, questions, schedules } = payload;
    handleQuestions(questions);
    handleChangeMethodId(questions);

    try {
      const res = await axios.put(`/reflection/questionnaire/update/${id}`, payload);
      const { id: resId } = res.data.data;

      dispatch('updateSchedule', { schedules, resId });

      return Promise.resolve({ success: true });
    } catch (error) {
      return handleResponseError(error);
    }
  },
  updateChildSchedule(context, payload) {
    const { schedule, id } = payload;
    return axios
      .put(`/reflection/schedule/update/${schedule.id}`, { ...schedule, parentScheduleId: id })
      .then(handleResponse)
      .catch(handleResponseError);
  },
  async updateSchedule({ commit, state, dispatch }, payload) {
    const { schedules, resId: id } = payload;
    let success = false;

    for (const schedule of schedules) {
      if (schedule.durationType === 'hours') schedule.duration *= 60;
      if (schedule.durationType === 'days') schedule.duration *= 1440;
      try {
        if (schedule.id) {
          const updateSchedule = await axios.put(
            `/reflection/schedule/update/${schedule.id}`,
            schedule
          );
          if (updateSchedule.success) success = true;
          handleResponse(updateSchedule);
        } else {
          schedule.questionnaireId = id;
          const createSchedule = await axios.post('/reflection/schedule/create', schedule);
          const { data } = createSchedule;

          if (schedule.repeatType === 'custom') {
            if (!schedule.isChild && !schedule.parentScheduleId) {
              commit('SET_CURRENT_PARENT_ID', data.data.id);
            } else {
              dispatch('updateChildSchedule', { schedule: data.data, id: state.currentParentId });
            }
          }

          if (createSchedule.success) success = true;
          handleResponse(createSchedule);
        }
      } catch (error) {
        handleResponseError(error);
      }
    }
    return Promise.resolve({ success });
  },
  deleteDraft({ commit, rootGetters }, payload) {
    return axios
      .delete(`/reflection/questionnaire/delete/${payload}`)
      .then(({ data: { success } }) => {
        if (!success)
          return Promise.resolve({
            success: false,
            msg: i18n.t('warnings.cant_delete_draft_message'),
          });

        const index = rootGetters['reflections/templatesAll'].findIndex(
          (obj) => obj.id === payload
        );
        commit('reflections/DELETE_DRAFT', index, { root: true });

        return Promise.resolve({ success, msg: i18n.t('warnings.success_action_message') });
      });
  },
  deleteQuestionnaire({ commit, rootGetters }, payload) {
    return axios.delete(`/reflection/questionnaire/delete/${payload}`).then((res) => {
      const { success } = res.data;
      if (success) {
        const templatesAll = rootGetters['reflections/templatesAll'];
        if (templatesAll.length) {
          const index = templatesAll.findIndex((obj) => obj.id === payload);
          commit('reflections/DELETE_DRAFT', index, { root: true });
        }
        return Promise.resolve({ success, msg: i18n.t('warnings.success_action_message') });
      }
      return Promise.resolve({ success: false, msg: i18n.t('warnings.cant_delete_quest_message') });
    });
  },
  deleteGroupQuestionnaire(context, payload) {
    return axios.delete(`/reflection/questionnaire/delete/${payload}`).then((res) => {
      const { success } = res.data;
      if (success) {
        return Promise.resolve({ success, msg: i18n.t('warnings.success_action_message') });
      }
      return Promise.resolve({ success: false, msg: i18n.t('warnings.cant_delete_quest_message') });
    });
  },
  getQuestionnairesByGroup({ commit }, payload) {
    const { id, limit, q, offset, clientId } = payload;
    return axios({
      method: 'GET',
      url: '/reflection/questionnaire/list',
      params: {
        clientGroupId: id,
        clientId,
        limit,
        offset,
        q,
      },
    }).then((res) => {
      const { success, data, totalCount } = res.data;
      const payload = {
        items: data,
        totalCount,
      };
      if (success) {
        commit('GET_QUESTIONNAIRE_BY_GROUP', payload);
        return Promise.resolve({ success, payload });
      }
      return Promise.resolve({ success, msg: i18n.t('warnings.questionnaire_not_found_warning') });
    });
  },
  setGroupQuestionnaire({ commit }, payload) {
    commit('SET_GROUP_QUESTIONNAIRE', payload);
  },
  setQuestionnaireErrors({ commit }, payload) {
    const { type, error } = payload;
    let msg;
    if (!error && error !== null) {
      switch (type) {
        case 'title':
          msg = i18n.t('warnings.create_title_message');
          break;
        case 'description':
          msg = i18n.t('warnings.create_desc_message');
          break;
        case 'questions':
          msg = i18n.t('warnings.add_at_least_one_question_message');
          break;
        case 'icon':
          msg = i18n.t('warnings.add_icon_message');
          break;
        default:
          break;
      }
      commit('SET_QUESTIONNAIRE_ERRORS', { type, error: msg });
    } else {
      commit('SET_QUESTIONNAIRE_ERRORS', payload);
    }
  },
  handleQuestionnaireErrors({ state, commit, rootGetters }) {
    const { title, questions, icon, description } = state.questionnaire;
    const methods = rootGetters['methods/questionTypeList'];

    questions.forEach((question, i) => {
      const { allowUnlimitedPartCount, answerOptions } = methods.find(
        (method) => method.id === question.methodId
      );

      handleQuestionTitle(question, commit, i);

      const { options } = question.answerOptions;
      if (options) {
        handleQuestionOptionErrors(options, commit, i, answerOptions.answerCount);
      }
      if (question.parts) {
        handleQuestionPartErrors(allowUnlimitedPartCount, question, i, commit);
      }
    });

    const questionsWithErrors = questions.filter((question) => question.error);
    const questionsWithPartErrors = questions.filter((question) => {
      if (question.parts) return question.parts.findIndex((part) => part.error) > -1;
      return false;
    });
    const questionsWithOptionErrors = questions.filter((question) => {
      const { options } = question.answerOptions;
      if (options) return options.findIndex((option) => option.error) > -1;
      return false;
    });
    const question = questionsWithErrors.length >= 1;
    const part = questionsWithPartErrors.length >= 1;
    const option = questionsWithOptionErrors.length >= 1;
    const indexOf = questions.findIndex((question) => question.error);
    const indexOfAnswerError = questions.findIndex((question) => {
      const { options } = question.answerOptions;
      let error = false;
      if (options) {
        options.forEach((option) => {
          if (option.error) error = true;
        });
      }
      return error;
    });

    if (questionsWithErrors.length) {
      commit('SET_CURRENT_TAB', indexOf);
      commit('SET_CURRENT_QUESTION', questions[indexOf]);
    }
    if (questionsWithOptionErrors.length) {
      commit('SET_CURRENT_TAB', indexOfAnswerError);
      commit('SET_CURRENT_QUESTION', questions[indexOfAnswerError]);
    }

    handleAllQuestionnaireErrors(state.questionnaire, commit, question, part, option);

    if (title && questions.length && icon && description && !question && !part && !option) {
      return Promise.resolve({ success: true });
    }
    return Promise.resolve({ success: false });
  },
  handleQuestionErrors({ commit, rootGetters, state }, { question, i }) {
    const methods = rootGetters['methods/questionTypeList'];
    const { options } = question.answerOptions;
    const { allowUnlimitedPartCount, answerOptions } = methods.find(
      (method) => method.id === question.methodId
    );

    handleQuestionTitle(question, commit, i);

    if (question.answerOptions) {
      handleAnswerOptionsErrors(question.answerOptions, i, commit);

      if (options) {
        handleQuestionOptionErrors(options, commit, i, answerOptions.answerCount);
      }
    }
    if (question.parts) {
      handleQuestionPartErrors(allowUnlimitedPartCount, question, i, commit);
    }

    return handleQuestionErrors(state.questionnaire.questions, commit);
  },
  getQuestionExamples({ commit }) {
    return axios
      .get('frontend/examplequestion/list')
      .then((res) => handleCommitResponse(res, commit, 'GET_EXAMPLE_QUESTIONS'))
      .catch(handleResponseError);
  },
  getQuestionnaireFilterData({ commit }) {
    return axios
      .get('frontend/questionnaireFilter/filterData')
      .then((res) => handleCommitResponse(res, commit, 'GET_QUESTIONNAIRE_FILTER_DATA'))
      .catch(handleResponseError);
  },
  handleNoQuestionsError({ commit }) {
    commit('SET_NO_QUESTIONS_ERROR');
  },
  resetQuestionnaireErrors({ commit }) {
    commit('SET_QUESTIONNAIRE_ERRORS', { reset: true });
  },
  setQuestionError({ commit }, payload) {
    commit('SET_QUESTION_ERROR', payload);
  },
  setAnswerOptionError({ commit }, payload) {
    commit('SET_ANSWER_OPTION_ERROR', payload);
  },
  setQuestionErrorCount({ commit }, payload) {
    commit('SET_QUESTION_ERROR_COUNT', payload);
  },
  setOptionError({ commit }, payload) {
    commit('SET_OPTION_ERROR', payload);
  },
  setScheduleIsEdit({ commit }, payload) {
    commit('SET_SCHEDULE_EDIT', payload);
  },
  resetQuestionnaireState({ commit }) {
    commit('RESET_STATE');
  },
  resetQuestionnaireFilterData({ commit }) {
    commit('RESET_QUESTIONNAIRE_FILTER_DATA');
  },
};

const getters = {
  questionnaire(state) {
    return state.questionnaire;
  },
  schedules(state) {
    return state.questionnaire.schedules;
  },
  questionnaireIcon(state) {
    return state.questionnaireIcon;
  },
  answerTemplate(state) {
    return state.answerTemplate;
  },
  questions(state) {
    return state.questionnaire.questions;
  },
  currentQuestion(state) {
    return state.currentQuestion;
  },
  questionAnswerTemplate(state) {
    if (state.currentQuestion) {
      return state.currentQuestion.answerTemplate;
    }
    return null;
  },
  questionAnswerType(state) {
    if (state.currentQuestion) {
      if (state.currentQuestion.answerOptions) {
        return state.currentQuestion.answerOptions.type;
      }
    }
    return null;
  },
  currentTab(state) {
    return state.currentTab;
  },
  currentView(state) {
    return state.currentView;
  },
  currentScheduleType(state) {
    return state.currentScheduleType;
  },
  totalDuration(state) {
    const seconds = state.totalDuration;
    let duration;
    if (seconds < 60) {
      duration = moment.utc(seconds * 1000).format('ss [sec]');
    }
    if (seconds >= 60 && seconds <= 60 * 60) {
      duration = moment.utc(seconds * 1000).format('mm [min] ss [sec]');
    }
    if (seconds > 60 * 60) {
      duration = moment.utc(seconds * 1000).format('hh [val] mm [min] ss [sec]');
    }
    return duration;
  },
  questionnaireSummary(state) {
    return state.questionnaireSummary;
  },
  groupQuestionnaireList(state) {
    return state.groupQuestionnaireList;
  },
  groupQuestionnaire(state) {
    return state.groupQuestionnaire;
  },
  errors(state) {
    return state.errors;
  },
  questionError(state) {
    return state.questionError;
  },
  questionErrorCount(state) {
    return state.questionErrorCount;
  },
  questionnaireErrors(state) {
    return state.questionnaireErrors;
  },
  selectedQuestionErrors(state) {
    return state.questionnaire.questions[state.currentTab].answerOptions.errors;
  },
  noQuestionsError(state) {
    return state.noQuestionsError;
  },
  scheduleErrorCheck(state) {
    return state.scheduleErrorCheck;
  },
  scheduleIsEdit(state) {
    return state.scheduleIsEdit;
  },
  currentParentId(state) {
    return state.currentParentId;
  },
  questionExamples(state) {
    return state.questionExamples;
  },
  questionnaireFilterData(state) {
    return state.questionnaireFilterData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
