import axios from 'axios';

import { answers, memberAnswers } from './helpers/memberAnswers.data';
import colors from './helpers/statsColors.data';
import {
  handleCommitResponse,
  handleResponse,
  handleResponseError,
} from './helpers/responseHelpers';
import statEndpoints from './api-endpoints/stats.endpoints';

const getDefaultState = () => ({
  answers,
  memberAnswers,
  currentView: 'tabs',
  currentTab: 0,
  answerType: 'group',
  groupStatQuestion: [],
  groupStatInTime: [],
  groupIndividualStats: [],
  memberStatsInTime: [],
  distinctAnswers: [],
  colors,
  groupMessages: [],
  memberMessages: [],
});

const state = getDefaultState();

const mutations = {
  TOGGLE_VIEW(state, view) {
    state.currentView = view;
  },
  SET_TAB(state, tab) {
    state.currentTab = tab;
  },
  SET_TYPE(state, type) {
    state.answerType = type;
  },
  SET_GROUP_STAT_QUESTION(state, question) {
    state.groupStatQuestion = question;
  },
  SET_ALL_GROUP_STAT_QUESTIONS(state, question) {
    state.groupStatQuestion.push(question);
  },
  SET_GROUP_STAT_QUESTION_PARTS(state, { questionIndex, question }) {
    const partQuestion = state.groupStatQuestion[questionIndex];
    if (!partQuestion)
      state.groupStatQuestion[questionIndex] = {
        parts: [question],
      };
    if (!partQuestion.parts) {
      state.groupStatQuestion[questionIndex] = {
        ...partQuestion,
        parts: [question],
      };
    }
    state.groupStatQuestion[questionIndex].parts.push(question);
  },
  SET_GROUP_STAT_IN_TIME(state, stats) {
    state.groupStatInTime = stats;
  },
  SET_ALL_GROUP_STAT_IN_TIME(state, stats) {
    state.groupStatInTime.push(stats);
  },
  SET_GROUP_STAT_IN_TIME_PARTS(state, { questionIndex, question }) {
    const partQuestion = state.groupStatInTime[questionIndex];
    if (!partQuestion)
      state.groupStatInTime[questionIndex] = {
        parts: [question],
      };
    if (!partQuestion.parts)
      state.groupStatInTime[questionIndex] = {
        ...partQuestion,
        parts: [question],
      };
    state.groupStatInTime[questionIndex].parts.push(question);
  },
  SET_INDIVIDUAL_STATS(state, stats) {
    state.groupIndividualStats = stats;
  },
  SET_ALL_INDIVIDUAL_STATS(state, stats) {
    state.groupIndividualStats.push(stats);
  },
  SET_ALL_INDIVIDUAL_STAT_PARTS(state, { questionIndex, question }) {
    const partQuestion = state.groupIndividualStats[questionIndex];
    if (!partQuestion)
      state.groupIndividualStats[questionIndex] = {
        parts: [question],
      };
    if (!partQuestion.parts)
      state.groupIndividualStats[questionIndex] = {
        ...partQuestion,
        parts: [question],
      };
    state.groupIndividualStats[questionIndex].parts.push(question);
  },
  SET_MEMBER_STATS_IN_TIME(state, stats) {
    state.memberStatsInTime = stats;
  },
  SET_ALL_MEMBER_STATS_IN_TIME(state, stats) {
    state.memberStatsInTime.push(stats);
  },
  SET_MEMBER_STAT_IN_TIME_PARTS(state, { questionIndex, question }) {
    const partQuestion = state.memberStatsInTime[questionIndex];
    if (!partQuestion) state.memberStatsInTime[questionIndex] = {};
    state.memberStatsInTime[questionIndex] = {
      ...partQuestion,
      parts: [question],
    };
    state.memberStatsInTime[questionIndex].parts.push(question);
  },
  SET_ALL_DISTINCT_ANSWERS(state, answers) {
    state.distinctAnswers = answers;
  },
  SET_ALL_GROUP_MESSAGES(state, messages) {
    state.groupMessages = messages;
  },
  SET_ALL_MEMBER_MESSAGES(state, messages) {
    state.memberMessages = messages;
  },
  RESET_GROUP_STATE(state) {
    state.groupStatQuestion = [];
  },
  RESET_INDIVIDUAL_STATE(state) {
    state.groupIndividualStats = [];
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_STATE_STAT(state) {
    Object.assign(state, getDefaultState());
    state.currentView = 'list';
    state.answerType = 'inTime';
  },
  RESET_ALL_MESSAGES(state) {
    state.groupMessages = [];
    state.memberMessages = [];
  },
};

const actions = {
  toggleView({ commit }, payload) {
    commit('TOGGLE_VIEW', payload);
  },
  setTab({ commit }, payload) {
    commit('SET_TAB', payload);
  },
  setType({ commit }, payload) {
    commit('SET_TYPE', payload);
  },
  resetMembersState({ commit }) {
    commit('RESET_STATE');
  },
  resetGroupState({ commit }) {
    commit('RESET_GROUP_STATE');
  },
  resetIndividualState({ commit }) {
    commit('RESET_INDIVIDUAL_STATE');
  },
  setGroupStatQuestion({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflection,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_GROUP_STAT_QUESTION'))
      .catch(handleResponseError);
  },
  setAllGroupStatQuestion({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflection,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_ALL_GROUP_STAT_QUESTIONS'))
      .catch(handleResponseError);
  },
  setGroupStatQuestionParts({ commit }, payload) {
    const params = payload;
    const { questionIndex } = payload;

    delete params.questionIndex;
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflection,
      params,
    })
      .then((res) => {
        const {
          data: { success, data },
        } = res;
        commit('SET_GROUP_STAT_QUESTION_PARTS', { questionIndex, question: data });
        return Promise.resolve({ success, data });
      })
      .catch(handleResponseError);
  },
  setGroupStatsInTime({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaire,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_GROUP_STAT_IN_TIME'))
      .catch(handleResponseError);
  },
  setAllGroupStatsInTime({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaire,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_ALL_GROUP_STAT_IN_TIME'))
      .catch(handleResponseError);
  },
  setGroupStatInTimeParts({ commit }, payload) {
    const params = payload;
    const { questionIndex } = payload;

    delete params.questionIndex;
    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaire,
      params,
    })
      .then((res) => {
        const {
          data: { success, data },
        } = res;
        commit('SET_GROUP_STAT_IN_TIME_PARTS', { questionIndex, question: data });
        return Promise.resolve({ success, data });
      })
      .catch(handleResponseError);
  },
  setIndividualStats({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflectionAndGroup,
      params: { ...payload },
    }).then((res) => handleCommitResponse(res, commit, 'SET_INDIVIDUAL_STATS'));
  },
  setAllIndividualStats({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflectionAndGroup,
      params: { ...payload },
    }).then((res) => handleCommitResponse(res, commit, 'SET_ALL_INDIVIDUAL_STATS'));
  },
  setIndividualStatParts({ commit }, payload) {
    const params = payload;
    const { questionIndex } = payload;

    delete params.questionIndex;
    return axios({
      method: 'GET',
      url: statEndpoints.listByReflectionAndGroup,
      params,
    })
      .then((res) => {
        const {
          data: { success, data },
        } = res;
        commit('SET_ALL_INDIVIDUAL_STAT_PARTS', { questionIndex, question: data });
        return Promise.resolve({ success, data });
      })
      .catch(handleResponseError);
  },
  setIndividualAnswer(_, payload) {
    const { id, answer } = payload;
    return axios({
      method: 'PUT',
      url: `${statEndpoints.answerUpdate}/${id}`,
      data: answer,
    })
      .then(handleResponse)
      .catch(handleResponseError);
  },
  setMemberStatsInTime({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaireAndClient,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_MEMBER_STATS_IN_TIME'))
      .catch(handleResponseError);
  },
  setAllMemberStatsInTime({ commit }, payload) {
    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaireAndClient,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_ALL_MEMBER_STATS_IN_TIME'))
      .catch(handleResponseError);
  },
  setMemberStatsInTimeParts({ commit }, payload) {
    const params = payload;
    const { questionIndex } = payload;

    delete params.questionIndex;

    return axios({
      method: 'GET',
      url: statEndpoints.listByQuestionnaireAndClient,
      params,
    })
      .then((res) => {
        const {
          data: { success, data },
        } = res;
        commit('SET_MEMBER_STAT_IN_TIME_PARTS', { questionIndex, question: data });
        return Promise.resolve({ success, data });
      })
      .catch(handleResponseError);
  },
  setDistinctAnswers({ commit }, payload) {
    const { id } = payload;
    return axios({
      method: 'GET',
      url: `${statEndpoints.distinctAnswers}/${id}`,
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'SET_ALL_DISTINCT_ANSWERS'))
      .catch(handleResponseError);
  },
  createMessage(context, payload) {
    return axios
      .post('/reflection/message/create', payload)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  getAllMessages({ commit }, payload) {
    return axios({
      method: 'GET',
      url: 'reflection/message/list',
      params: { ...payload },
    })
      .then((res) =>
        handleCommitResponse(
          res,
          commit,
          payload.clientId ? 'SET_ALL_MEMBER_MESSAGES' : 'SET_ALL_GROUP_MESSAGES'
        )
      )
      .catch(handleResponseError);
  },
  resetGroupStats({ commit }) {
    commit('SET_GROUP_STAT_QUESTION', []);
  },
  resetGroupIndividual({ commit }) {
    commit('SET_INDIVIDUAL_STATS', []);
  },
  resetGroupInTime({ commit }) {
    commit('SET_GROUP_STAT_IN_TIME', []);
  },
  resetMemberStatsInTime({ commit }) {
    commit('SET_MEMBER_STATS_IN_TIME', []);
  },
  resetDistinctAnswers({ commit }) {
    commit('SET_ALL_DISTINCT_ANSWERS', []);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
  resetStateStat({ commit }) {
    commit('RESET_STATE_STAT');
  },
  resetAllMessages({ commit }) {
    commit('RESET_ALL_MESSAGES');
  },
};

const getters = {
  answers(state) {
    return state.answers;
  },
  memberAnswers(state) {
    return state.memberAnswers;
  },
  currentView(state) {
    return state.currentView;
  },
  tabbedAnswers(state) {
    const tabbedAnswers = state.memberAnswers.map((answer, i) => ({
      ...answer,
      tab: i + 1,
    }));
    return tabbedAnswers;
  },
  selectedAnswer(state, getters) {
    return getters.tabbedAnswers.filter((answer) => answer.tab === state.currentTab)[0];
  },
  currentTab(state) {
    return state.currentTab;
  },
  answerType(state) {
    return state.answerType;
  },
  groupStatQuestion(state) {
    return state.groupStatQuestion;
  },
  groupStatInTime(state) {
    return state.groupStatInTime;
  },
  groupIndividualStats(state) {
    return state.groupIndividualStats;
  },
  memberStatsInTime(state) {
    return state.memberStatsInTime;
  },
  distinctAnswers(state) {
    return state.distinctAnswers;
  },
  colors(state) {
    return state.colors;
  },
  groupMessages(state) {
    return state.groupMessages;
  },
  memberMessages(state) {
    return state.memberMessages;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
