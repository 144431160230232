<template>
  <div class="template__modal">
    <svgicon
      v-if="!loginModal"
      @click="close"
      color="grey"
      :fill="false"
      name="multiply"
      class="modal__modal-close"
      height="9px"
    ></svgicon>
    <div class="modal__question" v-if="!reflection && !loginModal">
      <div class="question__container">
        <svgicon name="large-confirm" :original="true" height="80px"></svgicon>
        <header-h2>{{ $t("modals.calendar_modal_skip_reflection_check") }}</header-h2>
        <p>{{ $t("modals.calendar_modal_skip_message") }}</p>
        <div class="modal__actions">
          <button-4 @click.native="close">{{ $t("actions.cancel_action") }}</button-4>
          <button-3 @click.native="handleReflectionDelete">{{ $t("yes_cap") }}</button-3>
        </div>
      </div>
    </div>
    <div class="modal__reflection" v-if="reflection">
      <div class="card__left">
        <img
          v-if="reflectionData.questionnaire.icon"
          class="reflection__image"
          :src="reflectionData.questionnaire.icon"
          alt="Reflection Icon"
        />
        <svgicon v-else name="methods/logo-grey" :original="true" height="1.8em"></svgicon>
      </div>
      <div class="card__right">
        <div class="card__header">
          <header-h4>{{ reflectionData.questionnaire.title }}</header-h4>
          <div class="card__details">
            <span>
              <svgicon name="clock" :original="true" height="1em"></svgicon>
              {{ reflectionData.duration }} min
            </span>
            <span>
              <svgicon name="people" :original="true" height="1em"></svgicon>
              {{ reflectionData.clientGroup.title }}
            </span>
          </div>
        </div>
        <div class="card__content">
          <paragraph>{{ reflectionData.questionnaire.description }}</paragraph>
        </div>
        <div class="card__buttons">
          <button-4 @click.native="openQuestionnaire">{{ $t("modals.calendar_modal_view_questionnaire") }}</button-4>
        </div>
      </div>
    </div>
    <div class="modal__login expired-license-modal" v-if="(loginModal && reflection === undefined) && (error && error.error) === 'license_expired'">
      <svgicon class="modal__login-icon" name="time-out" :original="true" height="82px"></svgicon>
      <header-h2 class="modal__login-header">{{ $t("modals.license_modal_expired_license_header") }}</header-h2>
      <paragraph>{{ $t("modals.license_modal_expired_license_content_1") }} <a href="https://reflectus.lt/" target="_blank" rel="noopener">www.reflectus.lt</a>.</paragraph>
      <paragraph>{{ $t("modals.license_modal_expired_license_content_2") }}</paragraph>
      <div class="info__field info__field--actions">
        <button-4 @click.native="closeAndLeave">{{ $t("actions.got_it_action") }}</button-4>
      </div>
    </div>
    <div class="modal__login" v-else-if="loginModal">
      <svgicon class="modal__login-icon" name="time-out" :original="true" height="82px"></svgicon>
      <header-h2 class="modal__login-header">{{ $t("modals.login_modal_expired_session_message") }}</header-h2>
      <div class="info__field">
        <span>{{ $t("inputs.email_address") }}*</span>
        <input
          type="email"
          :placeholder='$t("your_email_placeholder")'
          v-model="email"
          @blur="$v.email.$touch()"
          @keydown.enter="submitData"
        />
        <div class="info__error" v-if="$v.email.$error">
          <span class="info__error-message" v-if="!$v.email.required">{{ $t("warnings.email_is_required") }}</span>
          <span class="info__error-message" v-if="!$v.email.email">{{ $t("warnings.invalid_email") }}</span>
        </div>
      </div>
      <div class="info__field">
        <span>{{ $t("inputs.password") }}</span>
        <input
          type="password"
          v-model="password"
          @blur="$v.password.$touch()"
          @keydown.enter="submitData"
        />
        <div class="info__error" v-if="$v.password.$error || error">
          <span class="info__error-message" v-if="!$v.password.required">{{ $t("warnings.password_is_required") }}</span>
          <span
            class="info__error-message"
            v-if="!$v.password.minLength"
          >{{ $t("warnings.password_length_warning") }}</span>
          <span class="info__error-message" v-if="error">{{ error }}</span>
        </div>
      </div>
      <div class="info__field info__field--actions">
        <button-3 @click.native="closeAndLeave">{{ $t("actions.cancel_action") }}</button-3>
        <button-4 @click.native="submitData" :disabled="!formIsValid">{{ $t("actions.login_action") }}</button-4>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';

import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
import Paragraph from '@/components/elements/typography/Paragraph.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'CalendarModal',
  components: {
    HeaderH2,
    HeaderH4,
    Paragraph,
    Button3,
    Button4,
  },
  props: ['method', 'reflection', 'data', 'loginModal', 'reflectionData'],
  data() {
    return {
      share: false,
      password: null,
      email: null,
    };
  },
  validations: {
    email: {
      email,
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  created() {
    if (this.user) {
      this.email = this.user.email;
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'error', 'registerModal']),
    ...mapGetters('reflections', ['editStatus', 'reflectionList']),
    formIsValid() {
      return !this.$v.email.$invalid && !this.$v.password.$invalid;
    },
    reflectionDuration() {
      const durationInMs = this.reflectionData.duration * 60 * 1000;
      let reflectionDuration;
      if (durationInMs < 60000) {
        reflectionDuration = this.moment.utc(reflectionDuration).format('ss [sec]');
      } else if (durationInMs > 60000 && durationInMs < 3.6e6) {
        reflectionDuration = this.moment.utc(reflectionDuration).format('mm [min]');
      } else if (durationInMs >= 3.6e6) {
        reflectionDuration = this.moment.utc(reflectionDuration).format('HH [val] mm [min]');
      }
      return reflectionDuration;
    },
  },
  methods: {
    ...mapActions('user', ['login', 'toggleModal', 'logout']),
    ...mapActions('reflections', ['setEditStatus', 'deleteReflection', 'skipReflection']),
    ...mapActions('meta', ['toggleWarningModal']),
    close() {
      this.$emit('close');
    },
    submitData() {
      if (this.email && this.password) {
        this.login({ email: this.email, password: this.password }).then((res) => {
          const { success } = res;
          if (success) {
            this.$router.go();
          }
        });
      }
    },
    closeAndLeave() {
      this.logout();
      this.toggleModal({ type: 'login', status: false });
      this.$router.push('/login');
    },
    openQuestionnaire() {
      const { questionnaireId } = this.reflectionData;
      this.close();
      if (this.editStatus !== 'view') this.setEditStatus('view');
      this.$router.push({ name: 'EditReflection', params: { id: questionnaireId } });
      this.toggleWarningModal();
    },
    handleReflectionDelete() {
      const { id, scheduleId, start } = this.reflectionData;
      if (id !== 0) {
        this.deleteReflection(this.reflectionData.id);
        const reflectionIndex = this.reflectionList.findIndex((reflection) => reflection.id === id);
        this.reflectionList.splice(reflectionIndex, 1);
      } else {
        this.skipReflection({
          id: scheduleId,
          date: this.moment(start).format('YYYY-MM-DD'),
        }).then((res) => {
          const { success } = res;
          if (success) {
            const reflectionIndex = this.reflectionList.findIndex(
              (reflection) => reflection.scheduleId === scheduleId && reflection.start === start
            );
            this.reflectionList.splice(reflectionIndex, 1);
          }
        });
      }
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.template__modal {
  position: relative;
  overflow: hidden;
  .modal__question {
    position: relative;
    padding: 20px;
    width: 300px;
    height: 270px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      margin-top: 10px;
    }

    p {
      margin: 0;
      margin-top: 5px;
      font-family: Nunito-Regular;
      font-size: 12px;
      color: $grey-5;
    }

    .modal__actions {
      margin-top: 20px;
      button {
        width: 100px;
        padding: 5px 25px;

        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  .modal__reflection {
    padding: 20px;
    width: 460px;
    height: 100%;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    text-align: left;
    .card__right {
      margin-left: 15px;
      position: relative;
      .card__header {
        position: relative;

        h4 {
          padding-right: 45px;
          min-height: 34px;
        }

        .card__icon {
          svg {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .card__details {
          margin-top: 10px;
          display: flex;
          align-items: center;
          span {
            font-family: Roboto-Regular;
            font-size: 12px;
            color: #505e67;

            &:nth-of-type(2) {
              margin-left: 20px;
            }

            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .card__content {
        margin-top: 10px;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 60px;
        }
      }
      .card__buttons {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }
    .card-left {
      svg {
        border-radius: 50%;
      }
    }
  }

  .reflection__image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .modal__modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }

  .modal__login {
    min-height: 304px;
    width: 300px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;

    .modal__login-icon {
      display: block;
      width: 120px;
      margin: 0 auto;
    }

    .modal__login-header {
      text-align: center;
      margin-top: 10px;
    }
    .info__field {
      display: flex;
      flex-direction: column;
      align-items: center;

      .info__error {
        display: flex;
        flex-direction: column;
        .info__error-message {
          margin-top: 3px;
          font-family: Roboto-Regular;
          font-size: 12px;
          color: $red-4;
          text-transform: initial;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &:first-of-type {
        margin-top: 30px;
      }
      &:last-of-type {
        margin-top: 15px;
      }

      &--actions {
        flex-direction: row;
      }

      span {
        align-self: flex-start;
        font-family: Nunito-Bold;
        font-size: 10px;
        text-transform: uppercase;
        color: $grey-3;
      }
      input {
        width: 260px;
        margin-top: 5px;
        height: 28px;
        border: 1px solid $grey-1;
        padding: 0 10px;
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-5;
        &:hover,
        &:active {
          border: 1px solid $grey-2;
        }
        &::placeholder {
          color: $grey-2;
        }
      }

      h3 {
        color: red;
      }

      &:nth-of-type(2) {
        margin-top: 16px;
      }
      &:nth-of-type(3) {
        margin-top: 20px;
      }
    }
    button {
      width: 100px;
      padding: 5px 10px;
      margin: 0 auto;
    }
    &.expired-license-modal{
      width: 500px;
      p{
        margin-top: 15px;
        text-align: justify;
      }
      .info__field{
        margin-top: 15px;
      }
    }
  }
}
</style>
