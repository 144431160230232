/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/color-pick-blank': {
    width: 37.049,
    height: 18.99,
    viewBox: '0 0 37.049 18.99',
    data: '<g data-name="Group 3151"><g data-name="Group 2580"><g data-name="Group 2416"><path pid="0" data-name="Rectangle 4461" _fill="none" _stroke="#5454c4" stroke-linejoin="round" d="M18.56.5h17.99v17.99H18.56z"/><g data-name="Group 2406" _fill="none" _stroke="#5454c4" stroke-linecap="round" stroke-miterlimit="10"><path pid="1" data-name="Path 32" d="M27.608 4.431v4.74h-4.74"/><path pid="2" data-name="Path 33" d="M27.608 13.911v-4.74h4.74"/></g><path pid="3" data-name="Rectangle 4462" _fill="#aac0ff" _stroke="#3b328b" stroke-linejoin="round" d="M.5.5h17.99v17.99H.5z"/><g data-name="Group 2407" transform="translate(.5 .5)" _fill="none" _stroke="#3b328b" stroke-linejoin="round"><path pid="4" data-name="Rectangle 4451" d="M0 0h17.99v17.99H0z"/><path pid="5" data-name="Path 2255" d="M-.004 12.842L4.46 8.321l3.6 3.514 5.367-5.319 4.559 4.616"/><ellipse pid="6" data-name="Ellipse 1035" cx="1.463" cy="1.463" rx="1.463" ry="1.463" transform="translate(6.601 3.583)"/></g><g data-name="Group 2412"><g data-name="Group 2411"><path pid="7" data-name="Rectangle 4465" _fill="#5454c4" _stroke="#5454c4" stroke-linejoin="round" d="M.5.499h17.983v17.983H.5z"/></g><g data-name="Group 2410" _fill="none" _stroke="#5454c4" stroke-linejoin="round"><path pid="8" data-name="Rectangle 4465" d="M.5.499h17.983v17.983H.5z"/><path pid="9" data-name="Path 2257" d="M.496 13.337l4.463-4.519 3.6 3.513 5.365-5.317 4.558 4.615"/></g></g></g><path pid="10" data-name="Path 2516" d="M7.856 10.096a7.428 7.428 0 0 0-.694 1.076 3.044 3.044 0 0 1 .978 1.17 8.95 8.95 0 0 0 1.222-.678c2.118-1.784 7.3-8.177 7.126-8.362s-6.767 4.749-8.632 6.794zm-1.305 1.573c-.92-.163-1.828.419-2.434 1.872a3.151 3.151 0 0 1-1.9 2c1.131.409 4.58 1.456 5.437-2.528a2.648 2.648 0 0 0-1.103-1.344z" _fill="#fff"/></g></g>'
  }
})
