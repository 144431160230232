/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-cool': {
    width: 16,
    height: 16,
    viewBox: '0 0 38.07 38.07',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="19.04" cy="19.04" r="19.04" _fill="#ffd053"/><path pid="1" d="M29 2.79A19 19 0 0 1 3 29.27 19 19 0 1 0 29 2.79z" _fill="#ffc448"/><path pid="2" d="M2.59 21a17.67 17.67 0 0 1 31.68-10.73A17.67 17.67 0 1 0 4.94 29.85 17.59 17.59 0 0 1 2.59 21z" _fill="#ffd77a"/><path pid="3" d="M11.23 23a.63.63 0 0 0-1 .69 9.54 9.54 0 0 0 17.65.18.63.63 0 0 0-1-.69c-2.82 2.72-8.74 6.56-15.65-.18z" _fill="#654735"/><path pid="4" d="M32.47 11.81l-7.73.64h-.27c-.07 0-2.56.31-5.33.47h-.2c-2.77-.16-5.27-.46-5.34-.47h-.27l-7.73-.64c-1.52 0-2.36.72-2.28 2 0 .05.55 3.06.55 3.06a6.52 6.52 0 0 0 1 2.81 3.54 3.54 0 0 0 1.34 1.12 3.75 3.75 0 0 0 .84.31 4.48 4.48 0 0 0 .77.07h3.34a5.38 5.38 0 0 0 2.38-.56A4.82 4.82 0 0 0 15.83 18a5.86 5.86 0 0 1 2.31-2.54 1.64 1.64 0 0 1 1.8 0 7.34 7.34 0 0 1 1.41 1.2 8.64 8.64 0 0 1 .8 1.15c.28.44.46 1 .76 1.41A3.88 3.88 0 0 0 24 20.38a5.24 5.24 0 0 0 2.59.87h3.67a4.48 4.48 0 0 0 .77-.07 3.74 3.74 0 0 0 .84-.31 3.54 3.54 0 0 0 1.34-1.12 6.52 6.52 0 0 0 1-2.81l.55-3.06c.07-1.35-.76-2.1-2.29-2.07z" _fill="#2b2426"/><path pid="5" d="M26.92 21.26h3.34a4.48 4.48 0 0 0 .77-.07 3.74 3.74 0 0 0 .84-.31 3.54 3.54 0 0 0 1.34-1.12 6.52 6.52 0 0 0 1-2.81l.55-3.06a2.49 2.49 0 0 0 0-.52c-3.56 5.51-7.88 6.85-10.65 7.1a5.27 5.27 0 0 0 2.5.81zM6.21 20.85a3.75 3.75 0 0 0 .84.31 4.48 4.48 0 0 0 .77.07h3.34a5.38 5.38 0 0 0 2.38-.56A4.82 4.82 0 0 0 15.83 18a4.76 4.76 0 0 1 1-1.52 22.08 22.08 0 0 1-11.21 4 4.48 4.48 0 0 0 .59.37z" _fill="#3f3e40"/></g></g>'
  }
})
