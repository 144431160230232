/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/thermometer': {
    width: 33.406,
    height: 33.406,
    viewBox: '0 0 33.406 33.406',
    data: '<g data-name="Group 3834"><g data-name="Group 1429" transform="translate(-381.787 -176.261)"><circle pid="0" data-name="Ellipse 272" cx="16.559" cy="16.559" r="16.559" transform="rotate(.5 -20006.743 43870.422)" _fill="#6666ca"/><path pid="1" data-name="Path 964" d="M401.407 195.5v-11.156a2.988 2.988 0 0 0-2.987-2.988 2.988 2.988 0 0 0-2.988 2.988V195.5a5.025 5.025 0 1 0 5.975 0z" _fill="#fff"/><path pid="2" data-name="Path 965" d="M399.9 196.617v-7.8a1.41 1.41 0 0 0-1.41-1.41h-.139a1.41 1.41 0 0 0-1.41 1.41v7.8a3.281 3.281 0 1 0 2.959 0z" _fill="#6666ca"/><g data-name="Group 1428" _fill="#fff"><path pid="3" data-name="Path 966" d="M404.417 191.616h-1.176a.375.375 0 0 1 0-.75h1.176a.375.375 0 0 1 0 .75z"/><path pid="4" data-name="Path 967" d="M404.417 184.692h-1.176a.375.375 0 0 1 0-.75h1.176a.375.375 0 0 1 0 .75z"/><path pid="5" data-name="Path 968" d="M404.417 187h-1.176a.375.375 0 0 1 0-.75h1.176a.375.375 0 0 1 0 .75z"/><path pid="6" data-name="Path 969" d="M404.417 189.308h-1.176a.375.375 0 0 1 0-.75h1.176a.375.375 0 0 1 0 .75z"/></g></g></g>'
  }
})
