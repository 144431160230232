/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faces': {
    width: 16,
    height: 16,
    viewBox: '0 0 33.12 33.12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="16.56" cy="16.56" r="16.56" _fill="#66c"/><circle pid="1" cx="16.56" cy="16.56" r="8.11" transform="rotate(-76.12 16.557 16.559)" stroke-miterlimit="10" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width=".75"/><circle pid="2" cx="13.72" cy="15.21" r=".83" stroke-miterlimit="10" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width=".75"/><circle pid="3" cx="19.4" cy="15.21" r=".83" stroke-miterlimit="10" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width=".75"/><path pid="4" d="M14.24 19a2.32 2.32 0 1 0 4.63 0z" stroke-linejoin="round" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width=".75"/></g></g>'
  }
})
