<template>
  <div>
    <p class="question-error-message" v-if="showQuestionErrorMessage">
      {{ $t('warnings.question_error_message') }}
    </p>
    <div class="reflections__buttons" v-if="type === 'new'">
      <button-3
        class="reflections__buttons__button reflections__buttons__button--back"
        @click.native="handleNavigation('CreateReflection')"
      >
        {{ backButtonTitle }}
      </button-3>
      <div v-if="!submit" class="reflections__buttons__container">
        <div class="no-methods-error-wrapper">
          <button-4
            :class="[
              'reflections__buttons__button',
              { 'reflections__buttons__button--saved': saved },
            ]"
            @click.native="handleNextStep(false)"
          >
            {{ $t('actions.save_action') }}
          </button-4>
          <p v-if="noQuestionsError">{{ noQuestionsError }}</p>
        </div>
        <button-4
          v-if="options"
          class="reflections__buttons__button reflections__buttons__button--share new-reflection-share-btn"
          @click.native="goToOptions"
        >
          <svgicon
            class="reflections__buttons__button__icon"
            name="share_template"
            :original="true"
          ></svgicon>
          {{ $t('actions.send_action') }}
        </button-4>
      </div>
      <button-4
        v-else
        class="reflections__buttons__button reflections__buttons__button--share"
        @click.native="toggleOptionsModal(null)"
      >
        <svgicon
          class="reflections__buttons__button__icon"
          name="share_template"
          :original="true"
        ></svgicon>
        {{ sendButtonTitle }}
      </button-4>
      <options-modal
        v-show="optionsModal"
        @closeModal="toggleOptionsModal"
        :type="modalType"
        :errors="errors"
      ></options-modal>
      <div class="reflection__buttons__message" v-if="msg"></div>
    </div>
    <div class="reflections__buttons" v-else-if="type === 'draft' || !type">
      <button-3
        class="reflections__buttons__button reflections__buttons__button--back"
        @click.native="handleNavigation('TemplatesDraft')"
      >
        {{ backButtonTitle }}
      </button-3>
      <div class="reflections__buttons__container" v-if="!submit">
        <button-4
          class="reflections__buttons__button"
          v-if="editStatus !== 'edit'"
          @click.native="handleEdit"
        >
          {{ $t('actions.edit_questionnaire_action') }}
        </button-4>
        <button-4
          v-if="editStatus === 'edit'"
          :class="[
            'reflections__buttons__button',
            { 'reflections__buttons__button--saved': saved },
          ]"
          @click.native="handleNextStep(false)"
        >
          {{ $t('actions.save_action') }}
        </button-4>
        <button-4
          v-if="editStatus === 'edit'"
          class="reflections__buttons__button reflections__buttons__button--share"
          @click.native="handleEditSend"
        >
          <svgicon
            class="reflections__buttons__button__icon"
            name="share_template"
            :original="true"
          ></svgicon>
          {{ $t('actions.send_action') }}
        </button-4>
      </div>
      <div v-else>
        <button-4
          class="reflections__buttons__button reflections__buttons__button--share"
          @click.native="toggleOptionsModal(null)"
        >
          <svgicon
            class="reflections__buttons__button__icon"
            name="share_template"
            :original="true"
          ></svgicon>
          {{ sendButtonTitle }}
        </button-4>
      </div>
      <options-modal
        v-show="optionsModal"
        @closeModal="toggleOptionsModal"
        :type="modalType"
      ></options-modal>
      <div
        class="warning-modal"
        v-if="warningModal && questionnaire.clientGroupId"
        @click="toggleWarningModal"
      >
        <div class="warning-modal__bg">
          <div class="warning-modal__modal" @click.stop>
            <div class="warning-modal__container">
              <h4 class="options-modal__container__heading">
                {{ $t('modals.questionnaire_warning_modal_message') }}
              </h4>
              <div class="options-modal__container__actions">
                <button-4 @click.native="toggleWarningModal">
                  {{ $t('actions.got_it_action') }}
                </button-4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="warning-modal send-warning-modal"
        v-if="sendWarning"
        @click="toggleSendWarningModal"
      >
        <div class="warning-modal__bg">
          <div class="warning-modal__modal" @click.stop>
            <div class="warning-modal__container">
              <svgicon
                @click="toggleSendWarningModal"
                color="grey"
                :fill="false"
                name="multiply"
                class="options-modal__close"
              ></svgicon>
              <svgicon
                class="options-modal__container__icon"
                name="large-question-mark"
                :original="true"
              ></svgicon>
              <h4 class="options-modal__container__heading">
                {{ $t('warnings.questionnaire_is_edited_warning') }}
              </h4>
              <div class="options-modal__container__actions">
                <button-3 @click.native="toggleSendWarningModal">
                  {{ $t('actions.cancel_action') }}
                </button-3>
                <button-4 @click.native="goToOptions">{{ $t('actions.continue_action') }}</button-4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reflections__buttons" v-else-if="type === 'template'">
      <button-3
        class="reflections__buttons__button reflections__buttons__button--back"
        @click.native="handleNavigation('Templates')"
      >
        {{ $t('actions.back_action') }}
      </button-3>
      <div
        class="reflections__buttons__container reflections__buttons__container--template"
        v-if="!submit"
      >
        <button-4
          class="reflections__buttons__button"
          @click.native="handleCloneTemplatePublic"
          v-if="isPublic"
        >
          {{ $t('actions.use_this_template_action') }}
        </button-4>
        <button-4 class="reflections__buttons__button" @click.native="handleCloneTemplate" v-else>
          {{ $t('actions.copy_this_template_action') }}
        </button-4>
        <!-- <button-3
          class="reflections__buttons__button"
          v-if="editStatus !== 'edit' && personal"
          @click.native="() => setEditStatus('edit')"
        >Redaguoti ruošinį</button-3>-->
        <button-4
          class="reflections__buttons__button"
          v-if="personal && !isPublic"
          @click.native="handleNextStep(true)"
        >
          {{ $t('actions.create_new_questionnaire_2_action') }}
        </button-4>
      </div>
      <div v-else>
        <button-4 class="reflections__buttons__button" @click.native="handleUpdate('template')">
          {{ $t('actions.save_action') }}
        </button-4>
      </div>
      <options-modal
        v-show="optionsModal"
        @closeModal="toggleOptionsModal"
        :type="modalType"
      ></options-modal>
      <template-copy-success-modal v-if="copySuccessModal">
        <template v-slot:modal-main-icon>
          <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
        </template>
        <template v-slot:modal-header>{{ $t('messages.template_save_success_message') }}</template>
        <template v-slot:modal-actions>
          <button-4 @click.native="closeCopyModal">{{ $t('actions.ok_action') }}</button-4>
        </template>
      </template-copy-success-modal>
    </div>
    <div class="reflection__buttons" v-else>
      <button-3
        class="reflections__buttons__button reflections__buttons__button--back"
        @click.native="handleNavigation('Back')"
      >
        {{ $t('actions.back_action') }}
      </button-3>
    </div>
    <div class="warning-modal" v-if="cancelScheduleModal" @click="toggleCancelSchedule">
      <div class="warning-modal__bg">
        <div class="warning-modal__modal" @click.stop>
          <div class="warning-modal__container">
            <svgicon
              @click="toggleCancelSchedule"
              color="grey"
              :fill="false"
              name="multiply"
              class="options-modal__close"
            ></svgicon>
            <svgicon
              class="options-modal__container__icon"
              name="large-question-mark"
              :original="true"
            ></svgicon>
            <h4 class="options-modal__container__heading">
              {{ $t('warnings.schedule_unsaved_changes_warning') }}
            </h4>
            <div class="options-modal__container__actions">
              <button-3 @click.native="toggleCancelSchedule">{{ $t('no_cap') }}</button-3>
              <button-4 @click.native="handleOptionsCancel">{{ $t('yes_cap') }}</button-4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <warning-modal
      v-if="isEditModal"
      @handleToggleAction="toggleIsEditModal"
      :message="$t('modals.finish_option_message')"
    />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import _ from 'lodash';
  import VueScrollTo from 'vue-scrollto';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import OptionsModal from '@/components/reflections/options/OptionsModal.vue';
  import TemplateCopySuccessModal from '@/components/base/TemplateCopySuccessModal.vue';
  import WarningModal from '@/components/base/WarningModal.vue';

  export default {
    name: 'CreateReflectionButtons',
    components: {
      Button3,
      Button4,
      OptionsModal,
      TemplateCopySuccessModal,
      WarningModal,
    },
    props: ['submit', 'type', 'personal'],
    data() {
      return {
        optionsModal: false,
        copySuccessModal: false,
        modalType: null,
        msg: null,
        options: false,
        saved: false,
        copiedQuestionnaireId: null,
        savedQuestionnaire: null,
        sendWarning: false,
        primaryQuestionnaire: null,
        showQuestionErrorMessage: false,
        cancelScheduleModal: false,
        isEditModal: false,
      };
    },
    created() {
      this.savePrimaryQuestionnaire();
    },
    computed: {
      ...mapGetters('reflections', ['editStatus']),
      ...mapGetters('questionnaire', [
        'questionnaire',
        'errors',
        'currentTab',
        'noQuestionsError',
        'currentView',
        'questionErrorCount',
        'scheduleIsEdit',
      ]),
      ...mapGetters('meta', ['questionnaireNavigation', 'optionsNavigation', 'warningModal']),
      isPublic() {
        return this.questionnaire.public;
      },
      backButtonTitle() {
        return this.$route.name === 'ReflectionOptions'
          ? this.$t('actions.cancel_action')
          : this.$t('actions.back_action');
      },
      sendButtonTitle() {
        return this.$route.name === 'ReflectionOptions'
          ? this.$t('actions.save_and_send_action')
          : this.$t('actions.send_action');
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'createQuestionnaire',
        'uploadIcon',
        'setQuestionnaireSummary',
        'createTemplate',
        'updateQuestionnaire',
        'handleQuestionnaireErrors',
        'setQuestionnaireProperty',
        'createFromTemplate',
        'handleQuestionErrors',
        'handleNoQuestionsError',
      ]),
      ...mapActions('reflections', ['setEditStatus', 'updateTemplate', 'cloneTemplate']),
      ...mapActions('meta', ['setQuestionnaireNavigation', 'toggleWarningModal']),
      toggleOptionsModal(type) {
        if (!type) this.modalType = null;
        else this.modalType = type;

        // if schedule option is in edit mode, toggle info message, else show options modal
        if (this.scheduleIsEdit) {
          this.toggleIsEditModal();
        } else {
          this.optionsModal = !this.optionsModal;
          if (this.$route.name !== 'ReflectionOptions') return;
          this.setQuestionnaireSummary(type);
        }
      },
      toggleIsEditModal() {
        this.isEditModal = !this.isEditModal;
      },
      toggleCancelSchedule() {
        this.cancelScheduleModal = !this.cancelScheduleModal;
      },
      savePrimaryQuestionnaire() {
        this.primaryQuestionnaire = JSON.parse(JSON.stringify(this.questionnaire));
      },
      async handleUpdate(type) {
        const errors = await this.handleErrorCheck();
        if (!errors) return;

        if (type === 'draft') {
          const { success } = await this.updateQuestionnaire();
          if (success) this.toggleOptionsModal('draftUpdate');
        } else {
          const { success } = await this.updateTemplate(this.questionnaire);
          if (success) this.toggleOptionsModal('templateUpdate');
        }
      },
      async handleNextStep(updateTemplate) {
        this.showQuestionErrorMessage = false;
        const errors = await this.handleErrorCheck('new');
        if (!errors) return;

        const update = await this.handleUpdateQuestionnaire();
        if (update) return;

        if (updateTemplate) {
          // update template here if needed
        }

        await this.handleCreateQuestionnaire();
      },
      async handleCloneTemplatePublic() {
        const { success, data: questionnaire } = await this.cloneTemplate({
          id: this.questionnaire.id,
        });
        if (success) {
          this.copySuccessModal = true;
          this.copiedQuestionnaireId = questionnaire.id;
        }
      },
      async handleCloneTemplate() {
        const { success } = await this.cloneTemplate({ id: this.questionnaire.id });
        if (success) this.toggleOptionsModal('templateCloned');
      },
      async handleRouteToQuestionnaire(id) {
        if (!id) return Promise.resolve(false);

        if (this.type === 'draft' || this.type === 'new') {
          await this.updateQuestionnaire();
          this.toggleOptionsModal('draftUpdate');
          return Promise.resolve(true);
        }
        const { success, data } = await this.createFromTemplate(id);
        if (success) this.$router.push({ name: 'EditReflection', params: { id: data.id } });
        return Promise.resolve(true);
      },
      handleNavigation(route) {
        if (this.$route.name === 'ReflectionOptions') {
          this.toggleCancelSchedule();
          return;
        }
        this.setQuestionnaireNavigation({ modal: true, route });
      },
      handleOptionsCancel() {
        this.toggleCancelSchedule();
        this.$router.push({ name: 'EditReflection', params: { id: this.questionnaire.id } });
      },
      checkQuestionnairePositions() {
        const { questions } = this.questionnaire;
        questions.forEach((question, index) => {
          question.position = index;
        });
      },
      handleEditSend() {
        if (!_.isEqual(this.primaryQuestionnaire, this.questionnaire)) {
          this.sendWarning = true;
        } else {
          this.goToOptions();
        }
      },
      goToOptions() {
        this.showQuestionErrorMessage = false;
        if (this.sendWarning) this.sendWarning = false;
        const { questions } = this.questionnaire;
        if (!questions.length) return;
        this.handleQuestionnaireErrors().then((res) => {
          const { success } = res;
          if (success) {
            if (!_.isEqual(this.savedQuestionnaire, this.questionnaire)) {
              this.handleUpdateQuestionnaire();
            }
            this.$router.push({
              name: 'ReflectionOptions',
              params: { id: this.questionnaire.id || null, type: this.type },
            });
          } else {
            this.handleQuestionnaireErrorScroll();
          }
        });
      },
      handleQuestionnaireErrorScroll() {
        this.showQuestionErrorMessage = true;
        let parentElement = null;
        if (this.currentView === 'list') {
          parentElement = document.querySelector('.list-view-questionnaire');
        } else {
          parentElement = document.querySelector('.tab-view-questionnaire');
        }
        const inputError = document.getElementsByClassName('reflection__textarea--error')[0];
        const iconError = document.getElementsByClassName(
          'reflection__details__field__icon__image--error'
        )[0];
        const questionError = parentElement.querySelector('.question-has-errors');
        const questionTemplateError = parentElement.querySelector('.template__input-text--error');

        if (inputError || iconError) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else if (questionError || questionTemplateError) {
          this.$emit('handleQuestionExpand');
          if (questionError) {
            setTimeout(() => {
              VueScrollTo.scrollTo(questionError, 500);
            }, 200);
          } else if (questionTemplateError) {
            setTimeout(() => {
              VueScrollTo.scrollTo(questionTemplateError, 500);
            }, 200);
          }
        }
      },
      async handleErrorCheck(type) {
        const { questions } = this.questionnaire;
        this.showQuestionErrorMessage = false;
        if (!questions.length) {
          if (type === 'new') {
            this.handleNoQuestionsError();
          }
          return false;
        }

        let questionsHaveErrors = false;

        if (this.currentView === 'list') {
          for (const [index, question] of questions.entries()) {
            questionsHaveErrors = await this.handleQuestionErrors({
              question,
              i: index,
            });
          }
        } else {
          questionsHaveErrors = await this.handleQuestionErrors({
            question: questions[this.currentTab],
            i: this.currentTab,
          });
        }

        if (questionsHaveErrors) {
          this.handleQuestionnaireErrorScroll();
          return false;
        }
        const questionnaireHasErrors = await this.handleQuestionnaireErrors();
        if (!questionnaireHasErrors.success) {
          this.handleQuestionnaireErrorScroll();
          return false;
        }
        return true;
      },
      async handleCreateQuestionnaire() {
        const { id, icon } = this.questionnaire;
        if (id) return;
        const payload = JSON.parse(JSON.stringify(this.questionnaire));
        payload.templateType = 'draft';
        const isFile = icon[0] === 'b';

        const { success, data } = await this.createTemplate(payload);
        if (success) {
          if (isFile) await this.uploadIcon({ id: data.id, type: payload.templateType });

          this.setQuestionnaireProperty({ type: 'id', property: data.id });
          this.options = true;
          this.saved = true;
          this.savedQuestionnaire = JSON.parse(JSON.stringify(this.questionnaire));
          this.toggleOptionsModal('draft');
          // eslint-disable-next-line no-restricted-globals
          history.pushState({}, null, `/reflections/${data.id}`);
        }
      },
      async handleUpdateQuestionnaire() {
        const { id } = this.questionnaire;
        if (!id) return Promise.resolve(false);

        this.savedQuestionnaire = JSON.parse(JSON.stringify(this.questionnaire));

        if (this.type === 'draft' || this.type === 'new') {
          if (this.type === 'draft') {
            this.checkQuestionnairePositions();
          }
          await this.updateQuestionnaire();
          this.toggleOptionsModal('draftUpdate');
          return Promise.resolve(true);
        }
        const { success, data } = await this.createFromTemplate(id);
        if (success) this.$router.push({ name: 'EditReflection', params: { id: data.id } });
        return Promise.resolve(true);
      },
      toggleSendWarningModal() {
        this.sendWarning = !this.sendWarning;
      },
      handleEdit() {
        this.toggleWarningModal();
        this.setEditStatus('edit');
      },
      closeCopyModal() {
        this.copySuccessModal = false;
        this.handleRouteToQuestionnaire(this.copiedQuestionnaireId);
      },
    },
    watch: {
      optionsNavigation(newValue) {
        if (newValue) {
          this.toggleOptionsModal(null);
        }
      },
    },
  };
</script>
<style lang="scss">
  @import 'mixins';
  .question-error-message {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: $red-4;
  }
  .reflections__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    align-items: flex-start;

    &__container {
      position: relative;

      .reflections__buttons {
        &__button {
          &:not(:first-of-type) {
            position: absolute;
            margin: 10px 0 0 0px;
          }
        }
      }

      &--template {
        display: flex;
        align-items: center;

        .reflections__buttons {
          &__button {
            &:not(:first-of-type) {
              position: relative;
              margin: 0 0 0 15px;
            }
          }
        }
      }
      .no-methods-error-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        button {
          width: 102px;
          margin-left: auto;
        }
        p {
          margin-top: 10px;
          margin-left: auto;
          font-size: 14px;
          color: $red-4;
        }
      }
    }

    &__button {
      &--back {
        margin-right: auto;
      }
      &--select {
        margin-right: 10px;
      }

      &--share {
        display: flex;
        align-items: center;
        &.above-questionnaire {
          margin-right: 15px;
        }
      }

      // &--saved {
      //   color: $grey-2 !important;
      // }

      &:last-of-type {
        margin-left: 10px;
      }

      &__icon {
        width: 14px;
        height: 16px;
        margin-right: 8px;
      }
    }
    .new-reflection-share-btn {
      margin-left: auto;
    }
  }
  .warning-modal {
    position: absolute;
    &__bg {
      margin: 0;
      z-index: 5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__modal {
      @include hardBoxShadow();
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }

    &__container {
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;

      &__icon {
        width: 120px;
        height: 80px;
      }
    }
    &.send-warning-modal {
      .options-modal__container__heading {
        max-width: initial;
      }
    }
  }
</style>
