<template>
  <div class="method-answer__container textTypeAnswerMethod">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="['method-answer', { 'method-answer--selected': option.value === memberAnswer }]"
    >
      <template-color
        :option="option"
        v-if="answerType === 'color' && option.show"
      ></template-color>
      <template-image
        :option="option"
        v-else-if="answerType === 'img' && option.show"
      ></template-image>
      <template-text v-else :option="option"></template-text>
    </div>
    <div v-if="!options" :class="['method-answer', 'method-answer--selected']">
      <template-text :option="{ value: memberAnswer }"></template-text>
    </div>
  </div>
</template>

<script>
import TemplateText from '@/components/groups/member/answer-templates/TemplateText.vue';
import TemplateColor from '@/components/groups/member/answer-templates/TemplateColor.vue';
import TemplateImage from '@/components/groups/member/answer-templates/TemplateImage.vue';

export default {
  name: 'TemplateQuestion',
  props: ['question', 'memberAnswer'],
  components: {
    TemplateText,
    TemplateColor,
    TemplateImage,
  },
  computed: {
    options() {
      const {
        answerOptions: { options },
      } = this.question;
      if (!options) return false;
      const addition = [
        {
          show: null,
          value: 'Nereflektavo',
        },
        {
          show: null,
          value: 'Skipped',
        },
      ];
      return [...options, ...addition];
    },
    maxLength() {
      const { anserOptions: maxLength } = this.question;
      return !!maxLength;
    },
    answerType() {
      const {
        answerOptions: { type },
      } = this.question;
      return type;
    },
  },
};
</script>
