<template>
  <div v-if="type === 'once'" class="reflection-options__data-wrapper type-once flex-global">
    <div>
      <span class="reflection-options__option-title">{{ $t('schedule.sending_date') }}</span>
      <div class="schedule-display-box flex-global">
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('date') }}</span>
          <span>{{ schedule.fromDate }}</span>
        </div>
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('time') }}</span>
          <span>{{ schedule.time }}</span>
        </div>
      </div>
    </div>
    <div>
      <span class="reflection-options__option-title">
        {{ $t('schedule.duration_of_reflection_three') }}
      </span>
      <div class="schedule-display-box duration-box flex-global">
        <span>{{ schedule.duration }} {{ currentDurationType }}</span>
      </div>
    </div>
  </div>
  <div class="reflection-options__data-wrapper type-custom flex-global" v-else>
    <div class="date-time-wrapper">
      <span class="reflection-options__option-title">{{ $t('schedule.option_duration') }}</span>
      <div class="schedule-display-box date-and-time flex-global">
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('from_cap') }}</span>
          <span>{{ schedule.fromDate || '-' }}</span>
        </div>
        <svgicon :fill="false" name="arrow-right" width="15px" height="10px"></svgicon>
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('to_cap') }}</span>
          <span>{{ schedule.toDate || '-' }}</span>
        </div>
      </div>
      <span class="reflection-options__option-title">{{ $t('schedule.option_exceptions') }}</span>
      <div class="schedule-display-box skip-days flex-global">
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('schedule.exception_dates') }}</span>
          <span class="skip-days-display-wrapper">{{ scheduleSkipDays }}</span>
        </div>
      </div>
    </div>
    <div class="week-days-wrapper">
      <span class="reflection-options__option-title">
        {{ $t('schedule.sending_time') }}
      </span>
      <div class="schedule-display-box week-days flex-global">
        <div class="schedule-display-inner flex-global">
          <span class="title">{{ $t('from_cap') }}</span>
          <div
            class="week-day-display-wrapper flex-global"
            v-for="(day, index) in weekDaysToDisplay"
            :key="index"
          >
            <span class="week-title">{{ day.display }}:</span>
            <div class="week-time-wrapper">
              <span
                :class="['week-time', { 'no-margin': !time.time }]"
                v-for="(time, index) in day.times"
                :key="`${index}-time-display-${time.time}`"
              >
                {{ time.time }}
              </span>
            </div>
          </div>
        </div>
        <div class="schedule-display-inner flex-global">
          <span>
            {{ $t('schedule.schedule_repeat_display_exp') }} {{ schedule.weekStep }}
            {{ $t('schedule.schedule_repeat_display_week_exp') }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <span class="reflection-options__option-title">
        {{ $t('schedule.duration_of_reflection_three') }}
      </span>
      <div class="schedule-display-box duration-box flex-global">
        <span>{{ schedule.duration }} {{ currentDurationType }}</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'OptionsDataDisplay',
    props: ['type', 'schedule', 'durationValues', 'weekDays'],
    computed: {
      currentDurationType() {
        const foundDurationValue = this.durationValues.find(
          (value) => value.type === this.schedule.durationType
        );
        const minutesDurationValue = this.durationValues.find((value) => value.type === 'minutes');
        return foundDurationValue ? foundDurationValue.value : minutesDurationValue.value;
      },
      scheduleSkipDays() {
        return this.schedule.skip.length ? this.schedule.skip.join(', ') : '-';
      },
      weekDaysToDisplay() {
        return this.weekDays.filter((day) => day.times.length && day.checked);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .reflection-options {
    &__data-wrapper {
      align-items: flex-start;
      margin-bottom: 10px;
      &.type-once {
        justify-content: flex-start;
        & > div {
          margin-right: 30px;
        }
      }
      &.type-custom {
        justify-content: flex-start;
        .date-time-wrapper {
          margin-right: 30px;
          width: 260px;
        }
        .week-days-wrapper {
          margin-right: 30px;
          width: 425px;
        }
        .schedule-display-box {
          &.date-and-time,
          &.skip-days {
            .schedule-display-inner {
              margin-right: 0;
            }
          }
          &.date-and-time {
            justify-content: space-between;
            margin-bottom: 70px;
          }
          &.skip-days {
            justify-content: flex-start;
            align-items: flex-start;
            height: 64px;
            overflow-y: auto;
            .skip-days-display-wrapper {
              width: 200px;
            }
          }
          &.week-days {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            min-width: 313px;
            max-width: 548px;
            min-height: 198px;
            overflow-y: auto;
            .week-day-display-wrapper {
              justify-content: flex-start;
              margin-bottom: 20px;
              .week-time-wrapper {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 225px;
              }
              .week-title {
                width: 125px;
                text-transform: capitalize;
              }
              .week-time {
                &:not(:last-child) {
                  margin-right: 10px;
                }
                &.no-margin {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
      .schedule-display-box {
        padding: 5px 0;
        border: 1px solid $grey-2;
        &.duration-box {
          justify-content: flex-start;
          min-width: 130px;
          padding: 5px 12px;
        }
        .schedule-display-inner {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-right: 40px;
          padding: 0 16px;
          .title {
            font-family: Nunito-Bold;
            font-size: 10px;
            font-weight: bold;
            line-height: 14px;
            letter-spacing: 0.5px;
            color: $grey-3;
          }
        }
      }
    }
    &__option-title {
      display: block;
      margin-bottom: 5px;
    }
  }
</style>
