/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app-logo-navigation': {
    width: 50,
    height: 44,
    viewBox: '0 0 50 44',
    data: '<path pid="0" d="M49.033 0v35.065a8.149 8.149 0 0 1-8.155 8.155H8.155A8.149 8.149 0 0 1 0 35.065V0h49.033z" _fill="#EFA620"/><path pid="1" d="M32.99 27.933l-2.25-4.174a7.867 7.867 0 0 0 1.143-1.291c.997-1.407 1.503-3.182 1.503-5.278 0-3.491-1.514-6.167-4.273-7.547-.07-.042-.14-.084-.228-.126-1.612-.789-3.208-.955-4.263-.955-.822 0-1.36.101-1.383.105l-.033.007a5.938 5.938 0 0 0-1.782.597c-.674-.342-1.434-.515-2.266-.515-1.053 0-1.995.255-2.813.765-1.13.735-1.779 1.898-1.779 3.192v17.716c0 1.374.717 2.576 1.97 3.298.784.448 1.681.675 2.664.675 1.008 0 1.92-.234 2.714-.697 1.2-.709 1.896-1.875 1.92-3.207l.847 1.647c.783 1.522 2.157 2.395 3.77 2.395 1.182 0 2.336-.432 3.344-1.257 1.43-1.202 1.73-2.543 1.73-3.456 0-.701-.196-1.373-.535-1.894z" _fill="#fff"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M19.158 26.006c-.66 0-1.25-.146-1.748-.443v4.866c0 .351.18.623.535.828.36.204.778.301 1.255.301.493 0 .915-.097 1.266-.301.347-.205.523-.477.523-.828v-4.916a3.505 3.505 0 0 1-1.83.493zm11.383 3.378l-3.608-6.689c1.104-.33 1.977-.953 2.63-1.872.652-.92.978-2.128.978-3.633 0-2.68-1.062-4.423-3.18-5.23.132.331.2.682.2 1.045 0 .707-.235 1.363-.682 1.894a2.7 2.7 0 0 1-.565.51c.435.489.648 1.191.648 2.111 0 1.944-.982 2.918-2.947 2.918h-1.818v2.809H23.3l3.91 7.596c.292.568.706.853 1.241.853.526 0 1.032-.197 1.513-.59.472-.397.715-.823.715-1.279a.7.7 0 0 0-.138-.443z" _fill="#33CA97"/><path pid="3" d="M26.356 13.005a1.7 1.7 0 0 1-.4 1.121c-.273.317-.574.476-.908.476h-.857c-.857 0-1.597.381-2.24 1.137-.64.761-.958 1.748-.958 2.964v4.544h-.004v.452c-.013.263-.197.51-.552.74a2.295 2.295 0 0 1-1.279.363c-.498 0-.916-.117-1.25-.347-.335-.234-.498-.497-.498-.794V12.713c0-.335.163-.598.485-.807.322-.201.75-.305 1.263-.305.464 0 .848.104 1.154.309.305.209.46.481.46.811v1.154c.296-.652.756-1.225 1.387-1.71.615-.472 1.275-.719 1.978-.727h.054c.44.004.853.029 1.246.079.184.075.355.205.518.393.263.322.401.686.401 1.095z" _fill="#F36"/>'
  }
})
