/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-view-on': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Group 3830"><path pid="0" data-name="Rectangle 5802" _fill="#919396" d="M0 0h20v20H0z"/><path pid="1" data-name="Line 2085" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 5h12"/><path pid="2" data-name="Line 2086" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 10.455h12"/><path pid="3" data-name="Line 2087" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 16h12"/></g>'
  }
})
