/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logos/web-logo': {
    width: 42.09,
    height: 37.1,
    viewBox: '0 0 42.09 37.1',
    data: '<g data-name="Group 2"><path pid="0" data-name="Path 1" d="M42.09 0v30.1a7 7 0 0 1-7 7H7a7 7 0 0 1-7-7V0z" _fill="#efa620"/><g data-name="Group 1"><path pid="1" data-name="Path 2" d="M29.99 24.933l-2.251-4.174a7.867 7.867 0 0 0 1.144-1.291 8.939 8.939 0 0 0 1.5-5.278c0-3.491-1.514-6.167-4.273-7.547-.071-.042-.141-.084-.228-.126a9.792 9.792 0 0 0-4.263-.955 8.119 8.119 0 0 0-1.383.105l-.033.007a5.938 5.938 0 0 0-1.782.6 4.951 4.951 0 0 0-2.266-.515 5.216 5.216 0 0 0-2.813.765 3.771 3.771 0 0 0-1.779 3.192v17.713a3.752 3.752 0 0 0 1.97 3.3 5.614 5.614 0 0 0 5.378-.022 3.752 3.752 0 0 0 1.922-3.207l.848 1.647a4.167 4.167 0 0 0 3.77 2.4 5.257 5.257 0 0 0 3.344-1.257 4.43 4.43 0 0 0 1.729-3.456 3.508 3.508 0 0 0-.534-1.901z" _fill="#fff"/><g data-name="Group 1109"><path pid="2" data-name="Path 3" d="M16.158 23.006a3.363 3.363 0 0 1-1.748-.443v4.866a.927.927 0 0 0 .535.828 2.79 2.79 0 0 0 2.521 0 .929.929 0 0 0 .523-.828v-4.916a3.505 3.505 0 0 1-1.831.493zm11.383 3.378l-3.608-6.689a4.824 4.824 0 0 0 2.63-1.872 6.147 6.147 0 0 0 .978-3.633q0-4.02-3.181-5.23a2.787 2.787 0 0 1 .2 1.045 2.9 2.9 0 0 1-.681 1.894 2.7 2.7 0 0 1-.565.51 3.073 3.073 0 0 1 .648 2.111q0 2.916-2.947 2.918H19.2v2.809h1.1l3.909 7.6a1.36 1.36 0 0 0 1.242.853 2.365 2.365 0 0 0 1.513-.59 1.694 1.694 0 0 0 .715-1.279.7.7 0 0 0-.138-.447z" _fill="#33ca97"/></g><path pid="3" data-name="Path 4" d="M23.356 10.005a1.7 1.7 0 0 1-.4 1.121 1.183 1.183 0 0 1-.907.476h-.857a2.871 2.871 0 0 0-2.241 1.137 4.451 4.451 0 0 0-.957 2.964V20.7a1 1 0 0 1-.552.74 2.3 2.3 0 0 1-1.279.363 2.156 2.156 0 0 1-1.25-.347.974.974 0 0 1-.5-.794V9.713a.929.929 0 0 1 .485-.807 2.371 2.371 0 0 1 1.26-.306 2.029 2.029 0 0 1 1.154.309.951.951 0 0 1 .459.811v1.154a4.5 4.5 0 0 1 1.388-1.71 3.263 3.263 0 0 1 1.978-.727h.054a10.645 10.645 0 0 1 1.246.079 1.387 1.387 0 0 1 .518.393 1.689 1.689 0 0 1 .401 1.096z" _fill="#f36"/></g></g>'
  }
})
