/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flags/flag-en': {
    width: 1000,
    height: 600,
    viewBox: '0 0 50 30',
    data: '<g paint-order="markers fill stroke"><path pid="0" _fill="#fff" d="M0 0h50v30H0z"/><path pid="1" d="M0 0v2.332L12.779 10h3.887zm22 0v11.999H0v5.996h22V30h6V17.995h22V12H28V0zm24.112 0L29.999 9.667V10h3.334L49.999 0zM16.624 20L-.001 30h3.887l16.112-9.668V20zm16.709 0l16.666 10v-2.332L37.222 20z" _fill="#c8102e"/><path pid="2" d="M5.85 0L20 8.5V0zM30 0v8.502L44.17 0zM0 3.498V10h10.836zm50 0L39.165 10H50zM0 20v6.502L10.835 20zm39.169 0L50 26.576V20zM30 21.498V30h14.17zm-10 0L5.831 30h14.17z" _fill="#012169"/></g>'
  }
})
