/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 17.119,
    height: 17.249,
    viewBox: '0 0 17.119 17.249',
    data: '<g data-name="Group 1816" transform="translate(-2364.396 -1348.5)" _fill="none" _stroke="#0f9f8f" stroke-miterlimit="10" stroke-width="1.5"><circle pid="0" data-name="Ellipse 765" cx="6.314" cy="6.314" r="6.314" transform="translate(2365.146 1349.25)"/><path pid="1" data-name="Line 1162" stroke-linecap="round" d="M2375.858 1360.092l4.596 4.596"/></g>'
  }
})
