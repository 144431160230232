/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/battery': {
    width: 11.332,
    height: 4.422,
    viewBox: '0 0 11.332 4.422',
    data: '<g data-name="Group 1111"><g data-name="Group 219" _fill="#fff"><path pid="0" data-name="Path 221" d="M5.301 4.426H.716a.651.651 0 0 1-.715-.708v-3A.641.641 0 0 1 .72.003h9.17a.64.64 0 0 1 .719.726v2.983a.647.647 0 0 1-.709.711zm.02-4.117H.82a.429.429 0 0 0-.458.474q-.006 1.422 0 2.844a.477.477 0 0 0 .114.343.514.514 0 0 0 .425.149h8.81c.055 0 .11-.007.165-.009a.34.34 0 0 0 .309-.238.823.823 0 0 0 .062-.305c.005-.884 0-1.768.005-2.652 0-.471-.2-.615-.609-.612-1.44.009-2.881.002-4.322.002z"/><path pid="1" data-name="Path 222" d="M10.751 2.197c0-.267-.006-.533.005-.8 0-.069.051-.185.1-.194.068-.015.154.04.227.077.035.017.054.062.085.089a.5.5 0 0 1 .171.44c-.013.3 0 .607-.007.91a.508.508 0 0 1-.091.228 2.358 2.358 0 0 1-.163.186c-.063.08-.149.14-.234.086a.286.286 0 0 1-.085-.209c-.017-.271-.008-.542-.008-.813z"/><path pid="2" data-name="Path 223" d="M5.3.553h4.363a.278.278 0 0 1 .311.31v2.678c0 .232-.1.328-.335.328H.996a.5.5 0 0 1-.2-.031.245.245 0 0 1-.162-.245c.005-.916 0-1.832 0-2.747a.273.273 0 0 1 .3-.291z"/></g></g>'
  }
})
