/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-thinking': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><circle pid="3" cx="7.36" cy="10.92" r="3.06" _fill="#fff"/><path pid="4" d="M9.5 8.73a3.05 3.05 0 0 0-3.69-.46.76.76 0 0 0-.17.4c-.06.64.76 1.24 1.84 1.34s2-.34 2.06-1a.73.73 0 0 0-.04-.28z" _fill="#2c2c2c"/><circle pid="5" cx="19.35" cy="10.92" r="3.06" _fill="#fff"/><path pid="6" d="M21.5 8.73a3.05 3.05 0 0 0-3.69-.46.76.76 0 0 0-.17.4c-.06.64.76 1.24 1.84 1.34s2-.34 2.06-1a.73.73 0 0 0-.04-.28z" _fill="#2c2c2c"/><path pid="7" d="M18.32 16.41a5.38 5.38 0 0 0-2.52-1.34 6.82 6.82 0 0 0-2.88-.07 7.21 7.21 0 0 0-2.64 1.1 6.26 6.26 0 0 0-1.94 2 .31.31 0 0 0 .41.44l.25-.09c.69-.34 1.34-.66 2-.92a12.51 12.51 0 0 1 2.24-.67 11.05 11.05 0 0 1 2.31-.2 14.49 14.49 0 0 1 2.46.28.31.31 0 0 0 .28-.53z" _fill="#654735"/></g></g>'
  }
})
