/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-switch': {
    width: 27,
    height: 25,
    viewBox: '0 0 27 25',
    data: '<rect pid="0" width="27" height="25" rx="2" _fill="#5147A7"/><path pid="1" _stroke="#fff" stroke-width="2" stroke-linecap="round" d="M4 20h1M10 20h1M4 13h1M10 13h1M4 6h1M10 6h1M16 6h1M22 6h1"/><mask id="svgicon_calendar-switch_a" _fill="#fff"><rect pid="2" x="15" y="12" width="9" height="9" rx="1"/></mask><rect pid="3" x="15" y="12" width="9" height="9" rx="1" _stroke="#fff" stroke-width="4" mask="url(#svgicon_calendar-switch_a)"/>'
  }
})
