/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-question-mark': {
    width: 119.969,
    height: 81.658,
    viewBox: '0 0 119.969 81.658',
    data: '<g data-name="Group 3828"><g data-name="Group 2611"><path pid="0" data-name="Path 1806" d="M117.288 58.085h1.931a.75.75 0 0 0 0-1.5h-1.931v-1.931a.75.75 0 1 0-1.5 0v1.931h-1.931a.75.75 0 0 0 0 1.5h1.931v1.931a.75.75 0 0 0 1.5 0zM30.256 4.564h2.314a.75.75 0 0 0 0-1.5h-2.314V.75a.75.75 0 0 0-1.5 0v2.314h-2.314a.75.75 0 0 0 0 1.5h2.314v2.314a.75.75 0 0 0 1.5 0zM5.043 70.931v-2.793h2.792a.75.75 0 1 0 0-1.5H5.043v-2.792a.75.75 0 0 0-1.5 0v2.792H.749a.75.75 0 1 0 0 1.5h2.794v2.793a.75.75 0 1 0 1.5 0z" _fill="#5147a7"/><g data-name="Group 2376"><path pid="1" data-name="Path 1807" d="M56.301 53.006a2.259 2.259 0 0 1-.491-1.541 9.241 9.241 0 0 1 .934-4.345 22.233 22.233 0 0 1 2.709-3.876 22 22 0 0 0 2.056-2.8 4.92 4.92 0 0 0 .653-2.522 2.971 2.971 0 0 0-1.143-2.452 4.853 4.853 0 0 0-3.107-.911 7.5 7.5 0 0 0-3.224.724 29.862 29.862 0 0 0-3.6 2.078 4.2 4.2 0 0 1-1.915.654 1.729 1.729 0 0 1-1.378-.723 2.742 2.742 0 0 1 .537-3.9 14.664 14.664 0 0 1 4.625-2.639 15.774 15.774 0 0 1 5.371-.958 12.418 12.418 0 0 1 5.068.981 8.237 8.237 0 0 1 3.457 2.709 6.65 6.65 0 0 1 1.238 3.971 7.592 7.592 0 0 1-1.168 4.227 26.016 26.016 0 0 1-3.409 4.041 30.222 30.222 0 0 0-2.873 3.293 6.619 6.619 0 0 0-1.192 3.012 1.854 1.854 0 0 1-.63 1.145 1.9 1.9 0 0 1-1.238.4 1.62 1.62 0 0 1-1.28-.568zm-1.1 9.366a3.674 3.674 0 0 1 0-5 3.551 3.551 0 0 1 4.951 0 3.434 3.434 0 0 1 .981 2.5 3.394 3.394 0 0 1-1 2.5 3.526 3.526 0 0 1-4.928 0z" _fill="#5147a7"/></g><path pid="2" data-name="Path 1808" d="M58.007 81.658a35.034 35.034 0 1 1 35.034-35.034 35.073 35.073 0 0 1-35.034 35.034zm0-68.068a33.034 33.034 0 1 0 33.034 33.034A33.071 33.071 0 0 0 58.007 13.59z" _fill="#5147a7"/></g></g>'
  }
})
