<template>
  <div class="licence-purchase-card flex-global" @click="handleLicenceClick">
    <div v-if="isBestOption" class="is-best-option-badge">
      {{ $t('licences.best_option_message') }}
    </div>
    <div class="custom-radio flex-global">
      <div class="active" v-if="isSelected"></div>
    </div>
    <div class="licence-purchase-card__content">
      <p class="title">{{ licence.name }}</p>
      <p class="price">
        <span class="currency">€</span>
        <span class="big">{{ licence.price }}</span>
        <span class="cycle-type">/{{ licence.duration }}</span>
        <span class="save-price-badge" v-if="isSavePrice">{{ licence.saveMessage }}</span>
      </p>
      <p class="description">{{ licence.description }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'LicencePurchaseCard',
    props: ['licence'],
    components: {},
    data() {
      return {};
    },
    computed: {
      licenceId() {
        return this.licence && this.licence.id;
      },
      isBestOption() {
        return this.licence && this.licence.isBestOption;
      },
      isSavePrice() {
        return this.licence && this.licence.saveMessage;
      },
      isSelected() {
        return this.licence && this.licence.isSelected;
      },
    },
    methods: {
      handleLicenceClick() {
        this.$emit('onLicenceSelect', this.licenceId);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .licence-purchase-card {
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 424px;
    height: 164px;
    padding: 16px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    background: #ffffff;
    &:not(:last-child) {
      margin-right: 20px;
    }
    .is-best-option-badge {
      position: absolute;
      top: -7px;
      right: 16px;
      padding: 2px 8px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      border-radius: 9999px;
      color: #ffffff;
      background-color: #f49c30;
    }
    .custom-radio {
      margin-right: 12px;
      width: 16px;
      height: 16px;
      border: 2px solid #000;
      border-radius: 50%;
      .active {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $violet-3;
      }
    }
    &__content {
      p {
        &.title {
          margin-top: -3px;
          font-size: 16px;
          font-weight: 600;
        }
        &.price {
          font-size: 14px;
          font-weight: 500;
          .big {
            margin-left: 2px;
            font-size: 20px;
            font-weight: 700;
          }
          .cycle-type {
            color: #a5a5a5;
          }
          .save-price-badge {
            margin-left: 5px;
            font-size: 12px;
            font-weight: 600;
            color: #ff2e5d;
          }
        }
        &.description {
          margin-top: 30px;
          width: 340px;
          font-size: 14px;
          font-weight: 300;
          color: #888888;
        }
      }
    }
  }
</style>
