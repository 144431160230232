<template>
  <div
    v-if="!loading"
    :class="[
      'member-answer-time',
      { 'member-answer-time--expanded': answerExpanded && currentView === 'list' },
    ]"
  >
    <div class="member-answer-time__header">
      <div class="member-answer-time__top method-item">
        <span class="method-item__number">{{ currentView === 'list' ? number + 1 : number }}</span>
        <svgicon
          class="method-item__icon"
          :name="methodIconName"
          :original="true"
          width="2em"
        ></svgicon>
        <div class="method-item__question">
          <header-h2 class="method-item__title">{{ method.title.toLowerCase() }}</header-h2>
          <header-h4>{{ question.title }}</header-h4>
        </div>
      </div>
    </div>
    <svgicon
      v-if="currentView === 'list'"
      :class="[
        'member-answer-time__arrow',
        { 'member-answer-time__arrow--expanded': answerExpanded },
      ]"
      name="green-arrow"
      :original="true"
      height="16px"
      width="16px"
      color="#3B328B"
      :fill="false"
      @click="expandAnswer"
    ></svgicon>
    <div class="member-answer-component" v-if="answerExpanded || currentView === 'tabs'">
      <!-- <div class="answer-component" v-if="method.id !== 5">
        <percentage-bar :percentage="percentage"></percentage-bar>
        <list-chart :answers="filteredStats" v-if="method.id !== 5"></list-chart>
      </div>
      <list-chart-filter @checkStatus="checkStatus" :percentage="percentage"></list-chart-filter>-->
      <div class="full-width-container">
        <member-answer-time-item
          v-if="!questionParts"
          :statsInTime="rawStats"
          :question="question"
          :method="method"
          :isCalendarStat="isCalendarStat"
          :showCalendar="showCalendar"
          @toggleCalendarView="toggleCalendarView"
        ></member-answer-time-item>
        <member-answer-time-item
          v-else
          :statsInTime="questionParts[0]"
          :question="question"
          :method="method"
          :isCalendarStat="isCalendarStat"
          :showCalendar="showCalendar"
          @toggleCalendarView="toggleCalendarView"
        ></member-answer-time-item>
      </div>
    </div>
    <!-- <member-answer-time-item
      v-if="!questionParts"
      :statsInTime="rawStats"
      :question="question"
      :method="method"
    ></member-answer-time-item>
    <member-answer-time-item :statsInTime="questionParts[0]" :question="question" :method="method"></member-answer-time-item>-->
    <!-- <member-answer-time-item
      v-for="(part, index) in questionParts"
      :key="`in-time-part--${index}`"
      :statsInTime="part"
      :question="question"
      :method="method"
    ></member-answer-time-item>-->
  </div>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters } from 'vuex';

  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
  import Loading from '@/components/base/Loading.vue';

  import MemberAnswerTimeItem from '@/components/stats/member-stats/MemberAnswerTimeItem.vue';

  // import ListChart from '@/components/stats/chart-templates/ListChart.vue';
  // import PercentageBar from '@/components/stats/chart-templates/PercentageBar.vue';
  // import ListChartFilter from '@/components/stats/chart-templates/ListChartFilter.vue';

  export default {
    name: 'MemberAnswerTime',
    components: {
      HeaderH2,
      HeaderH4,
      // ListChart,
      // PercentageBar,
      // ListChartFilter,
      Loading,
      MemberAnswerTimeItem,
    },
    props: ['number', 'expand', 'question', 'stats', 'loading', 'rawStats'],
    data() {
      return {
        answerExpanded: this.expand,
        filterTerms: [],
        showCalendar: true,
      };
    },
    watch: {
      expand(newVal) {
        this.answerExpanded = newVal;
      },
    },
    computed: {
      ...mapGetters('members', ['selectedAnswer', 'currentTab', 'currentView']),
      ...mapGetters('methods', ['questionTypeList']),
      method() {
        return this.questionTypeList.find((method) => method.id === this.questionMethodId) || '💩';
      },
      // this checks if question is old question method with select answer type
      questionMethodId() {
        return this.question.methodId === 4 && this.question.answerTemplate === 'select'
          ? 16
          : this.question.methodId;
      },
      methodIconName() {
        if (this.method.id === 4) {
          return `methods/question-text`;
        }
        if (this.method.id === 16) {
          return `methods/question-select`;
        }
        return `methods/${this.method.template}`;
      },
      percentage() {
        if (!this.stats) return false;
        const occurances = {};
        this.stats.forEach((stat) => {
          const amount = this.stats.filter((item) => item.value === stat.value);
          occurances[stat.value] = { count: amount.length, color: stat.color };
        });
        const statArray = Object.entries(occurances).map(([key, value]) => {
          return {
            value: key,
            count: value.count,
            color: value.color,
          };
        });

        const total = statArray.reduce((acc, value) => acc + value.count, 0);

        return statArray.map((stat) => ({
          ...stat,
          percentage: Math.floor((stat.count / total) * 100),
        }));
      },
      filteredStats() {
        if (!this.stats) return false;
        return this.stats.filter((stat) => !this.filterTerms.includes(stat.value));
      },
      questionParts() {
        if (!this.rawStats || !this.rawStats.parts) return false;
        return this.rawStats.parts;
      },
      isCalendarStat() {
        return (
          this.method.id === 2 ||
          this.method.id === 3 ||
          this.method.id === 7 ||
          this.method.id === 16 ||
          (this.method.id === 4 && this.question.answerTemplate === 'select') ||
          false
        );
      },
    },
    methods: {
      expandAnswer() {
        this.answerExpanded = !this.answerExpanded;
      },
      checkStatus({ value, checked }) {
        if (checked) {
          const termIndex = this.filterTerms.findIndex((term) => term.value);
          this.filterTerms.splice(termIndex, 1);
        } else {
          this.filterTerms.push(value);
        }
      },
      toggleCalendarView(type) {
        if (type === 'calendar') this.showCalendar = true;
        if (type === 'list') this.showCalendar = false;
      },
    },
  };
</script>
<style lang="scss">
  .member-answer-time {
    background-color: #fff;

    width: 100%;
    max-width: 840px;
    min-height: 73px;
    padding: 10px 20px 20px 20px;
    padding-left: 30px;
    margin-top: 3px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__header {
      align-self: flex-start;
      .method-item {
        display: flex;
        align-items: center;
        position: relative;

        &__title {
          color: $violet-2;
          text-transform: capitalize;
        }

        &__number {
          position: absolute;
          left: -18px;
          font-family: Nunito-SemiBold;
          color: $violet-2;
          font-size: 12px;
        }

        &__question {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
        }
      }
    }

    &__arrow {
      transform: rotate(-90deg);
      position: absolute;
      right: 20px;
      top: 25px;
      cursor: pointer;
      &.group-stats {
        // background: red;
        position: relative;
        top: 4px;
        right: 0;
        margin-left: 20px;
      }
    }
    &__arrow--expanded {
      transform: rotate(90deg);
    }
    .calendar-switch-button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
    .member-stat-no-data-message {
      margin: 2rem 0;
    }
  }
  .member-answer-component {
    align-self: flex-start;
    width: 100%;
    background-color: #fff;
    display: flex;
    .full-width-container {
      width: 100%;
    }

    .answer-component {
      margin-top: 30px;
      &.yes-no {
        width: 100%;
        .percentage-bar {
          margin-left: 0;
          width: 290px;
        }
        .list-chart-filter {
          margin-top: 0;
        }
      }
    }

    .list-chart-filter {
      margin-top: 70px;
    }

    &--individual {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
</style>
