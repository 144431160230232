/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bin-2': {
    width: 10,
    height: 12,
    viewBox: '0 0 10 12',
    data: '<path pid="0" d="M7.333 4.25v5.833H2.666V4.25h4.667zM6.458.75H3.541l-.583.583H.917V2.5h8.166V1.333H7.041L6.458.75zM8.5 3.083h-7v7a1.17 1.17 0 0 0 1.167 1.167h4.666A1.17 1.17 0 0 0 8.5 10.083v-7z"/>'
  }
})
