import axios from 'axios';
import router from '../../router';
import i18n from '../../plugins/i18n'

import {
  handleResponse,
  handleCommitResponse,
  handleResponseError,
} from './helpers/responseHelpers';

const getDefaultState = () => ({
  groups: [],
  group: null,
  selectedGraph: 'PieChart',
  memberStatType: 'TimeStats',
  selectedMember: null,
});

const state = getDefaultState();

const mutations = {
  SELECT_GRAPH(state, graph) {
    state.selectedGraph = graph;
  },
  SET_MEMBER_STAT_TYPE(state, type) {
    state.memberStatType = type;
  },
  ADD_GROUP(state, group) {
    state.groups.push(group);
  },
  GET_GROUP_LIST(state, groups) {
    state.groups = groups;
  },
  REMOVE_GROUP(state, index) {
    state.groups.splice(index, 1);
  },
  GET_GROUP(state, group) {
    state.group = group;
  },
  SET_MEMBER(state, member) {
    state.selectedMember = member;
  },
  GET_TOKEN(state, token) {
    state.group.token = token;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  selectGraph({ commit }, payload) {
    commit('SELECT_GRAPH', payload);
  },
  setMemberStatType({ commit }, payload) {
    commit('SET_MEMBER_STAT_TYPE', payload);
  },
  addGroup({ commit }, payload) {
    const { title, memberTitle } = payload;
    if (!title.length || !memberTitle.length) {
      return Promise.resolve({ success: false, msg: i18n.t("warnings.title_member_titles_req_message") });
    }

    return axios({
      method: 'post',
      url: '/client/group/create',
      data: {
        title,
        memberTitle,
      },
    })
      .then(({ data: { success, data } }) => {
        if (!success) return Promise.resolve({ success: false });
        const { id } = data;

        commit('ADD_GROUP', data);
        commit('GET_GROUP', data);
        router.push({ name: 'Group', params: { groupId: id, creation: true } });
        return Promise.resolve({ success: true, data });
      })
      .catch(handleResponseError);
  },
  getGroupList({ commit }, payload) {
    const { q } = payload;
    return axios({
      method: 'get',
      url: '/client/group/list',
      params: {
        q,
      },
    })
      .then((res) => {
        const {
          data: { success, data },
        } = res;
        if (!success) return Promise.resolve({ success: false, msg: i18n.t("warnings.cant_get_groups_message") });
        const groups = data.map((group) => ({
          ...group,
          value: group.title,
          type: 'class',
        }));
        commit('GET_GROUP_LIST', groups);
        return Promise.resolve({ success: true, data });
      })
      .catch(handleResponseError);
  },
  getGroup({ commit }, payload) {
    return axios({
      method: 'get',
      url: `/client/group/get/${payload}`,
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  updateGroup({ commit }, payload) {
    const { id, title, memberTitle, tokenValidationDate } = payload;
    const updatedGroup = {};
    if (title) updatedGroup.title = title;
    if (memberTitle) updatedGroup.memberTitle = memberTitle;
    if (tokenValidationDate) updatedGroup.tokenValidationDate = tokenValidationDate;

    return axios({
      method: 'PUT',
      url: `client/group/update/${id}`,
      data: updatedGroup,
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  removeGroup({ commit, state }, payload) {
    return axios
      .delete(`/client/group/delete/${payload}`)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          commit('GET_GROUP', null);
          const { groups } = state;
          if (groups.length) {
            const index = groups.findIndex((group) => group.id === payload);
            commit('REMOVE_GROUP', index);
          }
          return Promise.resolve({ success });
        }
        return Promise.resolve({ success: false, msg: i18n.t("warnings.cant_remove_group_message") });
      })
      .catch(handleResponseError);
  },
  getToken({ commit }, id) {
    return axios
      .post(`/client/group/generateToken/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  removeToken({ commit, state }) {
    const { id } = state.group;
    return axios
      .delete(`/client/group/removeToken/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  enableToken({ commit }, id) {
    return axios
      .put(`/client/group/enableToken/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  disableToken({ commit }, id) {
    return axios
      .delete(`/client/group/disableToken/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_GROUP'))
      .catch(handleResponseError);
  },
  getMembers({ state }) {
    const { id } = state.group;
    return axios
      .get(`/client/group/getMembers/${id}`)
      .then(handleResponse)
      .catch(handleResponseError);
  },
  getMemberById({ commit, rootGetters }, payload) {
    const selectedMember = rootGetters['members/members'].find((member) => member.id === payload);
    if (selectedMember) {
      commit('SET_MEMBER', selectedMember);
      return Promise.resolve({ success: true, data: selectedMember });
    }
    return Promise.resolve({ success: false, msg: i18n.t("warnings.cant_find_member_message") });
  },
  resetGroupsState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  groupList(state) {
    const groups = state.groups;
    groups.map((group) => {
      group.value = group.title;
      return group;
    });
    return groups;
  },
  currentGroup(state) {
    return state.group;
  },
  group(state) {
    return state.group;
  },
  reflectionList(state) {
    return state.reflections;
  },
  membersList(state) {
    return state.members;
  },
  selectedGraph(state) {
    return state.selectedGraph;
  },
  memberStatType(state) {
    return state.memberStatType;
  },
  selectedMember(state) {
    return state.selectedMember;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
