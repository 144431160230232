/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/closed': {
    width: 15.123,
    height: 13.055,
    viewBox: '0 0 15.123 13.055',
    data: '<g data-name="Group 2460"><g data-name="Group 1286" _fill="none" _stroke="#919396" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" data-name="Path 930" d="M12.555.5a7.062 7.062 0 1 1-9.986 0"/><path pid="1" data-name="Path 931" d="M11.005 2.434a4.614 4.614 0 1 1-6.525 0"/></g></g>'
  }
})
