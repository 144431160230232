/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stars': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 8.361L10.26 9.44l-.991 4.043-1.03-4.043-3.702-1.078 3.701-1.04 1.03-4.08.993 4.08L14 8.362zM7.13 3.63l-2.658.733L3.74 7.26l-.693-2.896L.389 3.63l2.658-.773L3.74 0l.732 2.857 2.658.773zM5.574 11.99l-2.211.61-.599 2.437-.599-2.437L0 11.99l2.165-.609.6-2.437.598 2.437 2.211.61z" _fill="#fff"/>'
  }
})
