/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blue-info-circle': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<circle pid="0" cx="8.5" cy="8.5" r="8.5" _fill="#3B328B"/><path pid="1" d="M9.416 14.072H7.434V6.676h1.982v7.396zm-2.099-9.31c0-.297.098-.54.294-.732.2-.191.472-.287.814-.287.337 0 .606.096.806.287.201.192.301.435.301.732 0 .3-.102.547-.307.738-.2.191-.468.287-.8.287-.333 0-.602-.096-.807-.287a.975.975 0 0 1-.3-.738z" _fill="#fff"/>'
  }
})
