<template>
  <paginate
    v-model="currentPage"
    :page-count="totalPages"
    :page-range="15"
    :hide-prev-next="true"
    :click-handler="selectPage"
    :prev-text="prevContent"
    :next-text="nextContent"
    :container-class="displayPagination ? 'pagination' : 'pagination hide-pagination'"
    :page-class="'page-wrapper'"
    :page-link-class="'page-number'">
  </paginate>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['totalPages', 'activePage'],
  data() {
    return {
      currentPage: this.activePage,
      prevContent: `<div class="icon-wrapper page-prev"></div>`,
      nextContent: `<div class="icon-wrapper page-next"></div>`,
      displayPagination: true
    };
  },
  methods: {
    selectPage(page) {
      this.displayPagination = false
      this.$emit('selectPage', page)
      setTimeout(() => {
        this.displayPagination = true
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.pagination{
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  opacity: 1;
  transition: visibility 0.2s linear, opacity 0.2s linear;
  &.hide-pagination{
    visibility: hidden;
    opacity: 0;
  }
  li{
    list-style-type: none;
    a{
      &:first-child,
      &:last-child{
        &:focus,
        &:active{
          outline: 0;
          -moz-outline-style: none;
        }
      }
    }
  }
  .page-wrapper {
    margin: 0 5px;
    font-family: Nunito-Regular;
    font-size: 14px;
    color: $grey-5;
    .page-number {
      padding: 5px 8px;
      border-radius: 50%;
      cursor: pointer;
      &:focus,
      &:active{
        outline: 0;
        -moz-outline-style: none;
      }
    }
    &:hover,
    &.active {
      .page-number{
        background-color: #6666ca;
        color: #fff;
      }
    }
  }
  .icon-wrapper{
    width: 14.289px;
    height: 13.646px;
    &.page-prev {
      margin-right: 20px;
      background-image: url('~@/assets/icons/page_prev.svg');
    }
    &.page-next {
      margin-left: 20px;
      background-image: url('~@/assets/icons/page_next.svg');
    }
  }
}
</style>
