<template>
  <div class="group__member">
    <div class="member__main" @click="handleMemberSelect">
      <div class="member-index">
        <span>{{memberIndex}}</span>
      </div>
      <div class="member__main__image">
        <div class="member__main__image__inner" v-if="memberInitials">
          <span>{{ memberInitials }}</span>
        </div>
        <svgicon name="student" :original="true" v-else></svgicon>
      </div>
      <div class="member__main__info">
        <router-link
          tag="div"
          :to="{ name: 'GroupMember', params: { memberId: member.id } }"
          class="member__main__title"
        >
          <header-h4>{{ `${member.name} ${member.lastName}` }}</header-h4>
        </router-link>
        <div class="member__main__details">
          <span>
            <svgicon name="folder" :original="true" height="15px"></svgicon>{{ $t("group.group_member_reflected") }}
            <span
              class="time-span"
            >{{ member.activeReflections }} / {{ member.totalReflections }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="member__action" @click="handleModalToggle">
      <svgicon name="bin" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t("actions.remove_from_group_action") }}</span>
    </div>
    <global-modal v-if="modalIsVisible" @handleModalToggle="handleModalToggle">
      <template v-slot:modal-main-icon>
        <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
      </template>
      <template v-slot:modal-header>{{ $t("group.group_remove_member_confirmation_message") }}</template>
      <template v-slot:modal-warning>{{ $t("group.group_remove_member_confirmation_content") }}</template>
      <template v-slot:modal-actions>
        <button-4 @click.native="handleModalToggle">{{ $t("no_cap") }}</button-4>
        <button-3 @click.native="handleMemberRemove">{{ $t("actions.remove_action") }}</button-3>
      </template>
    </global-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
import GlobalModal from '@/components/base/GlobalModal.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'GroupMember',
  components: {
    HeaderH4,
    GlobalModal,
    Button3,
    Button4,
  },
  props: ['member', 'group', 'index'],
  data() {
    return {
      modalIsVisible: false,
    };
  },
  computed: {
    memberInitials() {
      const { name, lastName } = this.member;
      if (name && lastName) return `${name.charAt(0)}${lastName.charAt(0)}`;
      return false;
    },
    memberIndex() {
      return `${this.index}.`;
    },
  },
  methods: {
    ...mapActions('meta', ['setLoading']),
    ...mapActions('members', ['removeMember']),
    handleMemberSelect() {
      const { id } = this.member;
      this.$router.push({
        name: 'GroupMember',
        params: { id: this.$route.params.id, memberId: id },
      });
    },
    handleModalToggle() {
      this.modalIsVisible = !this.modalIsVisible;
    },
    handleMemberRemove() {
      this.setLoading(true);
      this.removeMember({ group: this.group, member: this.member });
      this.handleModalToggle();
      setTimeout(() => {
        this.setLoading(false);
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@import 'mixins';

.group {
  &__member {
    display: flex;
    cursor: pointer;
    width: 100%;
    max-height: 58px;

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }

  .member {
    &__main {
      display: flex;
      background-color: #fff;
      width: 100%;
      align-items: center;
      .member-index {
        margin-left: 15px;
        width: 31px;
        span {
          font-size: 14px;
        }
      }

      &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 68px;
        svg {
          height: 30px;
          width: 30px;
        }

        &__inner {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: $grey-2;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            text-transform: uppercase;
            font-family: Roboto-Regular;
            font-size: 16px;
            color: #fff;
          }
        }
      }

      &__info {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px 0;

        h4 {
          font-size: 16px;
        }
      }

      &__details {
        margin-left: auto;
        .time-span {
          font-family: Roboto-Medium;
        }
        span {
          margin-right: 15px;
          font-family: Roboto-Regular;
          font-size: 14px;
          color: $grey-5;
          &:first-of-type {
            svg {
              margin-right: 5px;
            }
          }
        }
      }

      @include handleHover();
    }

    &__action {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-left: 5px;
      padding: 0 20px;
      span {
        font-family: Roboto-Medium;
        font-size: 12px;
        color: $violet-5;
        margin-top: 15px;
      }

      @include handleHover();
    }
  }
}
</style>
