<template>
  <div class="reflections">
    <div class="reflections__container" v-if="questions.length">
      <div class="reflections__actions">
        <div
          class="reflections__actions__add-question"
          v-if="currentView === 'list' && editStatus === 'edit'"
          @click="handleAddQuestion"
        >
          <svgicon
            class="reflections__actions__add-question__icon"
            name="add_question"
            :original="true"
          ></svgicon>
          <span class="reflections__actions__add-question__title">
            {{ $t('actions.add_question_action') }}
          </span>
        </div>
        <div class="list-expand" v-if="currentView === 'list'">
          <svgicon
            :class="['list-expand__icon', { 'list-expand__icon--expanded': expanded }]"
            name="expand-questions"
            :original="true"
            @click="expandComponents"
          ></svgicon>
          <span>
            {{ expanded ? $t('actions.collapse_all_action') : $t('actions.expand_all_action') }}
          </span>
        </div>
        <div class="list-type-icons">
          <svgicon
            :name="currentView === 'list' ? 'list-view-on' : 'list-view-off'"
            height="16px"
            width="16px"
            :original="true"
            @click="setCurrentView('list')"
          ></svgicon>
          <svgicon
            :name="currentView === 'tabs' ? 'tab-view-on' : 'tab-view-off'"
            height="16px"
            width="16px"
            :original="true"
            @click="setCurrentView('tabs')"
          ></svgicon>
        </div>
      </div>
      <draggable
        v-if="currentView === 'tabs' && (currentQuestion || type !== 'new')"
        class="question-tabs"
        :list="questions"
        :disabled="!enabled"
        handle=".reflection-tab-draggable"
        :animation="200"
        @start="dragging = true"
        @end="dragging = false"
      >
        <question-tab
          v-for="(question, i) in questions"
          :key="`tab--${i}`"
          @click.native="switchQuestion(i)"
          :number="i"
          :error="question.error"
        ></question-tab>
        <div
          class="reflection-tab reflection-tab--active"
          v-if="!selectedMethod && (type !== 'draft' || type !== 'template')"
        >
          <div class="reflection-tab__number">{{ questions.length + 1 }}</div>
        </div>
        <question-tab
          :add="true"
          v-if="(type !== 'draft' || type !== 'template') && editStatus === 'edit'"
        ></question-tab>
      </draggable>
      <article class="tab-view-questionnaire" v-show="currentView === 'tabs' && selectedMethod">
        <create-question
          v-if="currentQuestion"
          :key="
            `question--${currentTab}${questionErrorCount ? `errors--${questionErrorCount}` : ''}`
          "
          :error="currentQuestion.error"
          :number="currentTab"
          :expanded="expanded"
          :questionWithErrors="questionWithErrors"
          :questionWithPartErrors="questionWithPartErrors"
          :questionWithOptionErrors="questionWithOptionErrors"
          @handleDisplayExamplesModalAction="handleDisplayExamplesModal"
        ></create-question>
      </article>
      <article class="list-view-questionnaire" v-if="currentView === 'list'">
        <draggable
          :list="questions"
          :disabled="!enabled"
          ghost-class="ghost-on-drag"
          handle=".question-draggable-area"
          :animation="200"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
            <create-question
              v-for="(question, i) in questions"
              :key="`question--${i}`"
              :error="question.error"
              :number="i"
              :expanded="i === questions.length - 1 ? true : expanded"
              @handleDisplayExamplesModalAction="handleDisplayExamplesModal"
            ></create-question>
          </transition-group>
        </draggable>
      </article>
      <add-question
        v-if="currentView !== 'tabs' && editStatus === 'edit'"
        :expanded="expanded"
        :type="type"
      ></add-question>
      <add-question
        v-if="
          currentView === 'tabs' && (!questions.length || !selectedMethod) && editStatus === 'edit'
        "
        :expanded="expanded"
        :type="type"
      ></add-question>
    </div>
    <div class="reflections__container" v-if="!questions.length">
      <span
        :class="[
          'reflections__container__error',
          { 'reflections__container__error--list': currentView === 'list' },
        ]"
      >
        {{ questionnaireErrors.questions }}
      </span>
      <div class="reflections__actions">
        <div
          class="reflections__actions__add-question"
          v-if="currentView === 'list' && editStatus === 'edit'"
          @click="handleAddQuestion"
        >
          <svgicon
            class="reflections__actions__add-question__icon"
            name="add_question"
            :original="true"
          ></svgicon>
          <span class="reflections__actions__add-question__title">
            {{ $t('actions.add_question_action') }}
          </span>
        </div>
        <div class="list-expand" v-if="currentView === 'list'">
          <svgicon
            :class="['list-expand__icon', { 'list-expand__icon--expanded': expanded }]"
            name="expand-questions"
            :original="true"
            @click="expandComponents"
          ></svgicon>
          <span>
            {{ expanded ? $t('actions.collapse_all_action') : $t('actions.expand_all_action') }}
          </span>
        </div>
        <div class="list-type-icons">
          <svgicon
            :name="currentView === 'list' ? 'list-view-on' : 'list-view-off'"
            height="16px"
            width="16px"
            :original="true"
            @click="setCurrentView('list')"
          ></svgicon>
          <svgicon
            :name="currentView === 'tabs' ? 'tab-view-on' : 'tab-view-off'"
            height="16px"
            width="16px"
            :original="true"
            @click="setCurrentView('tabs')"
          ></svgicon>
        </div>
      </div>
      <div v-if="currentView === 'tabs'" class="question-tabs">
        <question-tab :empty="true"></question-tab>
        <question-tab v-if="editStatus === 'edit'" :add="true"></question-tab>
      </div>
    </div>
    <add-question
      v-if="!questions.length && editStatus === 'edit'"
      :expanded="expanded"
      :type="type"
    ></add-question>
    <create-reflection-buttons
      :type="type"
      :personal="personal"
      ref="createButtons"
      @handleQuestionExpand="alwaysExpandComponents"
    ></create-reflection-buttons>
    <question-example-modal
      v-if="examplesModal"
      :method="currentMethod"
      @handleAssignExampleAction="handleExampleAssign"
    />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import draggable from 'vuedraggable';

  import QuestionTab from '@/components/reflections/question/QuestionTab.vue';
  import CreateQuestion from '@/components/reflections/question/CreateQuestion.vue';
  import AddQuestion from '@/components/reflections/question/AddQuestion.vue';
  import CreateReflectionButtons from './CreateReflectionButtons.vue';
  import QuestionExampleModal from '@/components/base/ExampleQuestions/QuestionExampleModal.vue';

  export default {
    name: 'Reflections',
    components: {
      QuestionTab,
      CreateReflectionButtons,
      CreateQuestion,
      AddQuestion,
      QuestionExampleModal,
      draggable,
    },
    props: ['type', 'personal'],
    data() {
      return {
        expanded: false,
        questionWithErrors: false,
        questionWithPartErrors: false,
        questionWithOptionErrors: false,
        dragging: false,
        currentMethodId: false,
      };
    },
    updated() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'questionnaire/SET_QUESTION_ERROR' && mutation.payload.error) {
          this.questionWithErrors = true;
        } else this.questionWithErrors = false;

        if (mutation.type === 'questionnaire/SET_PART_ERROR' && mutation.payload.error) {
          this.questionWithPartErrors = true;
        } else this.questionWithPartErrors = false;

        if (mutation.type === 'questionnaire/SET_OPTION_ERROR' && mutation.payload.error) {
          this.questionWithOptionErrors = true;
        } else this.questionWithOptionErrors = false;
      });
    },
    computed: {
      ...mapGetters('methods', ['selectedMethod', 'questionTypeList']),
      ...mapGetters('reflections', ['editStatus']),
      ...mapGetters('meta', ['examplesModal']),
      ...mapGetters('questionnaire', [
        'questionnaire',
        'questions',
        'currentView',
        'currentQuestion',
        'currentTab',
        'questionnaireErrors',
        'questionErrorCount',
      ]),
      enabled() {
        return this.type !== 'template' || false;
      },
      currentMethod() {
        if (this.currentMethodId) {
          const method = this.questionTypeList.find((item) => item.id === this.currentMethodId);
          return method;
        }
        return null;
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'setCurrentTab',
        'setCurrentQuestion',
        'setCurrentView',
        'setQuestionError',
        'handleQuestionErrors',
        'editQuestionWithSelectedExample',
      ]),
      ...mapActions('methods', ['onMethodUnset']),
      ...mapActions('meta', ['toggleExamplesModal']),
      switchQuestion(i) {
        this.setCurrentTab(i);
        this.setCurrentQuestion();
      },
      expandComponents() {
        this.expanded = !this.expanded;
      },
      alwaysExpandComponents() {
        if (!this.expanded) this.expanded = true;
      },
      handleAddQuestion() {
        const { questions } = this.questionnaire;
        if (!questions.length) {
          this.onMethodUnset();
          return;
        }
        if (questions.length >= 20) {
          this.maxQuestionsError = this.$t('warnings.max_question_amount_warning');
          setTimeout(() => {
            this.maxQuestionsError = null;
          }, 4500);
          return;
        }
        const question = this.questionnaire.questions[this.currentTab];
        if (question) {
          this.handleQuestionErrors({
            question,
            i: this.currentTab,
          }).then((res) => {
            if (!res) this.onMethodUnset();
          });
        }
      },
      callCreateButtonsRef() {
        this.$refs.createButtons.goToOptions();
      },
      callUseTemplateRef() {
        this.$refs.createButtons.handleCloneTemplatePublic();
      },
      handleDisplayExamplesModal(methodId) {
        this.currentMethodId = methodId;
        this.toggleExamplesModal();
      },
      handleExampleAssign(activeQuestion) {
        this.editQuestionWithSelectedExample(activeQuestion);
        this.toggleExamplesModal();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .reflections {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__container {
      margin-top: 15px;
      position: relative;

      .question-tabs {
        display: flex;
        position: absolute;
        top: -25px;
        margin-left: 10px;
      }

      &__error {
        position: absolute;
        font-size: 14px;
        color: $red-4;
        top: -22px;
        left: 85px;

        &--list {
          left: 160px;
        }
      }
    }
    &__details {
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      min-height: 160px;

      .reflections__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        h4,
        p {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }
        h4 {
          font-family: Roboto-Medium;
          font-size: 14px;
          color: #919396;
        }
        p {
          margin-top: 10px;
          font-family: Roboto-Regular;
          font-size: 12px;
          color: #899ba3;
        }
        svg {
          margin-top: 10px;
        }
      }
    }

    &__actions {
      top: -24px;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;

      .list-expand {
        display: flex;
        align-items: center;
        min-width: 102px;
        span {
          margin-left: 8px;
          font-family: Nunito-Bold;
          font-size: 10px;
          text-transform: uppercase;
          color: $grey-3;
        }

        &__icon {
          width: 10px;
          height: 12px;
          transition: all 0.3s;

          &--expanded {
            transform: rotate(180deg);
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .list-type-icons {
        margin-left: 20px;

        svg {
          &:last-of-type {
            margin-left: 5px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &__add-question {
        display: flex;
        align-items: center;
        position: relative;
        left: -95%;
        min-width: 150px;

        &__icon {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          transition: all 0.3s;

          &:hover {
            cursor: pointer;
            transform: translateY(-2px) scale(1.25);
          }
        }

        &__title {
          font-family: Roboto-Medium;
          font-size: 14px;
          color: $violet-5;
        }
      }
    }
  }
</style>
