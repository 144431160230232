/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smileys/Patenkintas': {
    width: 80.455,
    height: 80.455,
    viewBox: '0 0 80.455 80.455',
    data: '<g data-name="Group 3830"><g data-name="Group 2576"><g data-name="Group 396"><g data-name="Group 393"><ellipse pid="0" data-name="Ellipse 84" cx="30.789" cy="30.789" rx="30.789" ry="30.789" transform="rotate(-22.5 59.235 11.783)" _fill="#ffce53"/></g><g data-name="Group 394"><path pid="1" data-name="Path 386" d="M56.336 13.949a30.65 30.65 0 0 1 4.843 16.585 30.788 30.788 0 0 1-30.788 30.788 30.635 30.635 0 0 1-16.108-4.547 30.766 30.766 0 0 0 25.945 14.2 30.789 30.789 0 0 0 30.788-30.789 30.77 30.77 0 0 0-14.68-26.237z" _fill="#ffc248"/></g><g data-name="Group 395"><path pid="2" data-name="Path 387" d="M13.623 43.475a28.586 28.586 0 0 1 28.586-28.586 28.537 28.537 0 0 1 22.66 11.16 28.577 28.577 0 0 0-24.788-14.337 28.585 28.585 0 0 0-28.586 28.585 28.459 28.459 0 0 0 5.926 17.426 28.454 28.454 0 0 1-3.798-14.248z" _fill="#ffd57a"/></g></g><path pid="3" data-name="Path 388" d="M27.608 43.402a1.016 1.016 0 0 0-1.653 1.107 15.431 15.431 0 0 0 14.328 9.7 15.43 15.43 0 0 0 14.212-9.415 1.015 1.015 0 0 0-1.64-1.12c-4.507 4.367-14.072 10.584-25.247-.272z" _fill="#654735"/><g data-name="Group 397" _fill="#2c2c2c"><path pid="4" data-name="Path 389" d="M48.176 33.493a5.678 5.678 0 0 0 5.3 3.584 5.7 5.7 0 0 0 5.253-3.481.905.905 0 0 0-.326-1.111.908.908 0 0 0-1.155.1c-1.708 1.656-4.409 2.986-7.576-.09a.917.917 0 0 0-.64-.262.916.916 0 0 0-.511.157.913.913 0 0 0-.345 1.103z"/><path pid="5" data-name="Path 390" d="M21.723 33.493a5.678 5.678 0 0 0 5.3 3.584 5.7 5.7 0 0 0 5.252-3.48.905.905 0 0 0-.326-1.112.907.907 0 0 0-1.155.1c-1.708 1.656-4.409 2.986-7.576-.09a.916.916 0 0 0-.64-.262.916.916 0 0 0-.511.157.913.913 0 0 0-.344 1.103z"/></g><g data-name="Group 398" transform="translate(12.216 39.138)" _fill="#ffa4ab"><circle pid="6" data-name="Ellipse 85" cx="5.375" cy="5.375" r="5.375"/><circle pid="7" data-name="Ellipse 86" cx="5.375" cy="5.375" r="5.375" transform="translate(45.273)"/></g></g></g>'
  }
})
