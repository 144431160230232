<template>
  <div class="faq__contact" v-if="!contact">
    <header-h2>{{ $t("faq.more_questions_message") }}</header-h2>
    <paragraph>{{ $t("faq.more_questions_content") }}</paragraph>
    <button-4 @click.native="contact = !contact">{{ $t("actions.contact_2_action") }}</button-4>
  </div>
  <div class="faq__contact-form" v-else>
    <div class="form__fields">
      <div class="field">
        <span>{{ $t("inputs.first_name") }}*</span>
        <input type="text" :class="{'required-input': $v.inquiryInfo.name.$invalid }" v-model="inquiryInfo.name" @blur="$v.inquiryInfo.name.$touch()" />
      </div>
      <div class="field">
        <span>{{ $t("inputs.email_address") }}*</span>
        <input type="text" :class="{'required-input': $v.inquiryInfo.email.$invalid }" v-model="inquiryInfo.email" @blur="$v.inquiryInfo.email.$touch()" />
        <span class="error-message" v-if="$v.inquiryInfo.email.$invalid">{{ $t("warnings.invalid_email") }}</span>
      </div>
      <div class="field">
        <span>{{ $t("inputs.info_question") }}</span>
        <textarea :class="{'required-input': $v.inquiryInfo.question.$invalid }" v-model="inquiryInfo.question"></textarea>
      </div>
      <span class="error-message" v-if="!inquiryIsValid">{{ $t("warnings.all_fields_required_warning") }}</span>
    </div>
    <button-4 @click.native="handleInquirySend" :disabled="!inquiryIsValid">{{ $t("actions.send_action") }}</button-4>
    <global-modal v-if="isModal" @handleModalToggle="handleModalToggle">
      <template v-slot:modal-main-icon>
        <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
      </template>
      <template v-slot:modal-header>{{ $t("messages.faq_message_success_message") }}</template>
      <template v-slot:modal-actions>
        <button-4 @click.native="handleModalToggle">{{ $t("actions.ok_action") }}</button-4>
      </template>
    </global-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import Paragraph from '@/components/elements/typography/Paragraph.vue';
import GlobalModal from '@/components/base/GlobalModal.vue';

export default {
  name: 'FaqContact',
  components: {
    HeaderH2,
    Button4,
    Paragraph,
    GlobalModal
  },
  props: ['user'],
  data() {
    return {
      contact: false,
      inquiryInfo: {
        name: `${this.user.name} ${this.user.lastName}`,
        email: this.user.email,
        question: ''
      },
      isModal: false
    };
  },
  validations: {
    inquiryInfo: {
      name: {
        required
      },
      email: {
        required,
        email,
      },
      question: {
        required
      }
    }
  },
  computed: {
    inquiryIsValid() {
      return !this.$v.inquiryInfo.$invalid;
    }
  },
  methods: {
    ...mapActions('user', ['sendInquiry']),
    handleInquirySend(){
      if(this.inquiryIsValid)
      this.sendInquiry(this.inquiryInfo).then((res) => {
        const { success } = res;
        if (success) {
          this.isModal = true;
        }
      });
    },
    handleModalToggle(){
      this.isModal = !this.isModal;
      this.contact = false;
      this.inquiryInfo.question = '';
    }
  }
};
</script>
<style lang="scss">
.faq__contact {
  width: 300px;
  height: 310px;
  padding: 17px;
  padding-top: 0;
  text-align: left;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 24px;
  }

  p {
    margin-top: 20px;
  }

  button {
    width: 120px;
    margin-top: 20px;
    flex-grow: 0;
  }
}

.faq__contact-form {
  .form__fields {
    padding: 20px;
    width: 300px;
    height: 310px;
    background-color: #fff;
    .field {
      text-align: left;
      span {
        font-family: Nunito-Bold;
        font-size: 9px;
        text-transform: uppercase;
        color: $grey-4;
      }
      .error-message{
        margin-top: 3px;
        font-size: 12px;
        font-family: Roboto-Regular;
        color: $red-4;
        text-transform: initial;
      }

      input[type='text'] {
        height: 28px;
      }

      textarea {
        height: 116px;
        resize: none;
      }

      input[type='text'],
      textarea {
        border: 1px solid $grey-3;
        width: 260px;
        margin-top: 5px;
        padding: 10px;
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-5;
        &.required-input{
          border: 1px solid $red-4;
        }
      }
    }
    .error-message{
      margin-top: 3px;
      font-size: 12px;
      font-family: Roboto-Regular;
      color: $red-4;
      text-transform: initial;
    }
  }
  button {
    margin-top: 20px;
  }
}
</style>
