<template>
  <div class="day__reflection__container">
    <li
      class="day__reflection"
      :class="{ 'day__reflection--active': viewed }"
      v-if="reflectionData"
      @click="handleReflectionClick"
      v-tooltip="`${$t('questionnaire.questionnaire')}: ${reflectionData.questionnaire.title}`"
    >
      <div class="reflection__time" @click.stop>
        <span>
          <slot name="time" />
        </span>
        <svgicon
          v-if="reflectionStatus === 'passed'"
          name="green-stats"
          :original="true"
          height="1em"
        ></svgicon>
        <svgicon
          v-if="reflectionStatus === 'current'"
          name="clock"
          height="1em"
          :original="true"
          :fill="false"
          color="#13A85B"
        ></svgicon>
        <svgicon
          class="reflection__time__remove"
          v-if="reflectionStatus === 'coming'"
          name="multiply"
          :original="true"
          :fill="false"
          :color="'#bababa'"
          height="10px"
          @click.native="toggleModal"
        ></svgicon>
      </div>
      <div class="reflection__class">
        <img
          v-if="reflectionData.questionnaire.icon"
          class="reflection__class__image"
          :src="reflectionData.questionnaire.icon"
          alt="Reflection Icon"
        />
        <svgicon
          v-else
          class="reflection__class__image"
          name="methods/logo-grey"
          :original="true"
          height="1.8em"
        ></svgicon>
        <span>
          <slot name="class" />
        </span>
      </div>
      <div class="reflection__status">
        <div
          class="progress-bar"
          v-if="reflectionStatus === 'passed' || reflectionStatus === 'current'"
        >
          <div class="progress-bar__completion" :style="{ width: progressionPercent }"></div>
        </div>
        <div class="progress-bar" v-else>
          <div class="progress-bar__completion" :style="{ width: 0 }"></div>
        </div>
        <div
          class="completion-rate"
          v-if="reflectionStatus === 'passed' || reflectionStatus === 'current'"
        >
          <span>{{ memberProgress }}</span>
        </div>
        <div class="completion-rate" v-else>
          <span>{{ memberProgress }}</span>
        </div>
      </div>
      <div
        :class="['reflection__tooltip', { 'reflection__tooltip--friday': isFriday }]"
        v-if="reflectionStatus === 'current'"
      >
        <span>Liko {{ timeLeft }}</span>
      </div>
    </li>
    <modal
      v-show="isModalVisible"
      @close="
        () => {
          toggleModal(), (reflection = false);
        }
      "
      :reflection="reflectionModal"
      :data="data"
      :reflectionData="reflectionData"
    ></modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/calendar/Modal.vue';

export default {
  name: 'CalendarReflection',
  props: ['viewed', 'complete', 'current', 'data', 'progress', 'reflectionData'],
  components: {
    Modal,
  },
  data() {
    return {
      isModalVisible: false,
      reflection: false,
      completion: null,
    };
  },
  created() {
    this.calculatePercentage();
  },
  computed: {
    reflectionModal() {
      return this.reflection;
    },
    reflectionStatus() {
      const start = this.moment(this.reflectionData.start).format('YYYY-MM-DD HH:mm:ss');
      const end = this.moment(this.reflectionData.end).format('YYYY-MM-DD HH:mm:ss');
      const today = this.moment().format('YYYY-MM-DD HH:mm:ss');

      let reflectionStatus;

      if (today > start && today < end) {
        reflectionStatus = 'current';
      } else if (today < start) {
        reflectionStatus = 'coming';
      } else if (today > end) {
        reflectionStatus = 'passed';
      }

      return reflectionStatus;
    },
    timeLeft() {
      if (this.reflectionStatus === 'current') {
        const today = this.moment();
        const end = this.moment(this.reflectionData.end);
        const timeLeft = end.diff(today, 'miliseconds');
        const duration = this.moment.duration(timeLeft);
        let reflectionDuration;

        if (timeLeft / 3.6e6 >= 24) {
          reflectionDuration = `${duration.days()} d ${duration.hours()} val ${duration.minutes()} min ${duration.seconds()} sec`;
        } else if (timeLeft / 3.6e6 >= 1) {
          reflectionDuration = this.moment.utc(timeLeft).format('HH [val] mm [min] ss [sec]');
        } else if (timeLeft / 60000 >= 1) {
          reflectionDuration = this.moment.utc(timeLeft).format('mm [min] ss [sec]');
        } else if (timeLeft / 1000 >= 1) {
          reflectionDuration = this.moment.utc(timeLeft).format('ss [sec]');
        } else if (timeLeft <= 0) {
          return null;
        }

        return reflectionDuration;
      }
      return null;
    },
    memberProgress() {
      const { membersCount, activeMembersCount } = this.reflectionData;
      return `${activeMembersCount}/${membersCount}`;
    },
    progressionPercent() {
      const { membersCount, activeMembersCount } = this.reflectionData;
      const percentage = Math.floor((activeMembersCount / membersCount) * 100);
      return `${percentage}%`;
    },
    isFriday() {
      const { day } = this.reflectionData;
      return day === 'penktadienis';
    },
  },
  methods: {
    ...mapActions('stats', ['setType', 'toggleView']),
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    toggleReflection() {
      this.reflection = !this.reflection;
    },
    calculatePercentage() {
      const percentage = ((this.progress / 30) * 100).toFixed(1);
      const result = `${percentage}%`;
      this.completion = result;
    },
    handleReflectionClick() {
      if (this.reflectionStatus === 'passed' || this.reflectionStatus === 'current') {
        const { clientGroupId, questionnaireId, id } = this.reflectionData;
        this.setType('group');
        this.toggleView('tabs');
        this.$router.push({
          name: 'GroupReflectionStats',
          params: { groupId: clientGroupId, questionnaireId, reflectionId: id }
        });
      } else {
        this.toggleModal();
        this.toggleReflection();
      }
    }
  },
};
</script>
<style lang="scss">
.day {
  &__reflection {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 85px;
    padding: 10px;
    background-color: #fff;
    position: relative;
    transition: all 0.3s;

    &--active {
      background-color: #fff;
    }

    &__container {
      &:not(:first-of-type) {
        margin-top: 8px;
      }
    }

    .reflection {
      &__time,
      &__class,
      &__status {
        display: flex;
        align-items: center;
      }

      &__tooltip {
        opacity: 0;
        position: absolute;
        top: 3px;
        right: -18px;

        background-color: $green-3;
        padding: 3px 10px;
        z-index: 1;

        span {
          font-family: Roboto-Regular;
          font-size: 11px;
          color: #fff;
        }

        &--friday {
          right: 0;
        }
      }

      &__time {
        justify-content: space-between;

        &__remove {
          transition: all 0.3s;

          &:hover {
            cursor: pointer;
            transform: translateY(-3px) scale(1.25);
          }
        }
        span {
          font-size: 11px;
          font-family: Roboto-Black;
          color: $green-3;
        }
      }

      &__class {
        margin-top: 5px;
        max-height: 30px;

        span {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-left: 5px;
          font-size: 11px;
          font-family: Roboto-Regular;
          color: $grey-5;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          max-width: 116px;
          max-height: 40px;
        }

        &:hover {
          cursor: pointer;
        }

        &__image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transition: all 0.3s;
        }
      }

      &__status {
        .progress-bar {
          position: relative;
          height: 4px;
          width: 110px;
          background-color: $grey-1;
          border-radius: 25px;

          &__completion {
            position: absolute;
            left: 0;
            top: 0;
            width: 77%;
            height: 4px;
            background-color: $green-3;
            border-radius: 25px;
          }
        }

        .completion-rate {
          margin-left: 5px;
          span {
            font-family: Roboto-Medium;
            font-size: 13px;
            color: $grey-5;
          }
        }
      }
    }
    &:hover {
      .reflection__tooltip {
        opacity: 1;
      }
    }

    &:hover {
      transform: translateY(-3px) scale(1.05);
      cursor: pointer;
      background-color: $grey-1;
      z-index: 3;
      box-shadow: 3px 3px 6px 3px rgba($grey-3, 0.55);

      .reflection__class {
        &__image {
          transform: translateY(-3px) rotate(9deg) scale(1.25);
        }

        span {
          font-family: Roboto-Black;
        }
      }

      .progress-bar {
        background-color: $grey-2;
      }
    }
  }
}
</style>
