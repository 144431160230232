/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/plane': {
    width: 9.518,
    height: 9.518,
    viewBox: '0 0 9.518 9.518',
    data: '<g data-name="Group 1115"><g data-name="Group 209" _fill="none" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round" stroke-width=".527"><path pid="0" data-name="Path 210" d="M9.255.26L.264 3.818l3.636 1.8z"/><path pid="1" data-name="Path 211" d="M9.255.26L5.697 9.251l-1.8-3.636z"/></g></g>'
  }
})
