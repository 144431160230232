<template>
  <section id="create__custom" class="create__custom" v-if="!loading">
    <template-header
      :type="'draft'"
      @templateSelect="templateSelect"
      @handleTemplateSearch="handleTemplateSearch"
    ></template-header>
    <transition-group
      tag="div"
      class="custom__container"
      name="template-transition"
      v-if="templatesAll"
    >
      <template-card
        v-for="template in templatesAll"
        :key="template.id"
        :iconName="template.icon"
        :personal="true"
        :draft="true"
        :template="template"
        :type="'draft'"
        @handleDelete="handleDelete"
      >
        <template v-slot:header>{{ template.title }}</template>
        <template v-slot:time>{{ template.duration }}</template>
        <template v-slot:author>{{ template.owner.name }} {{ template.owner.lastName }}</template>
        <template v-slot:content>{{ template.description }}</template>
      </template-card>
    </transition-group>
    <pagination
      v-if="totalPages > 1"
      :activePage="selectedTemplateDraftPage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
    <div class="custom__list-container" v-if="!templatesAll.length && !templateSearch">
      <div class="custom__list-card">
        <svgicon name="broken-pen" :original="true" height="3em"></svgicon>
        <header-h2>{{ $t('templates.no_new_templates_message') }}</header-h2>
        <router-link :to="{ name: 'CreateReflection' }">
          <button-4>{{ $t('actions.create_new_questionnaire_action') }}</button-4>
        </router-link>
      </div>
    </div>
    <div class="custom__list-container" v-else-if="!templatesAll.length && templateSearch">
      <svgicon class="custom__list-container__icon" name="large-search"></svgicon>
      <p class="custom__list-container__title">{{ $t('messages.no_search_results_message') }}</p>
    </div>
  </section>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import TemplateHeader from '@/components/templates/TemplateHeader.vue';
  import TemplateCard from '@/components/templates/TemplateCard.vue';
  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Pagination from '@/components/base/Pagination.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'TemplatesDraft',
    components: {
      TemplateHeader,
      TemplateCard,
      HeaderH2,
      Button4,
      Pagination,
      Loading,
    },
    data() {
      return {
        queries: {
          q: null,
          clientGroupId: null,
          limit: 10,
          offset: null,
          draft: true,
        },
        totalCount: null,
        defaultPageValue: 1,
        groupListQuery: {
          q: null,
        },
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('reflections', [
        'displayTemplates',
        'templatesAll',
        'selectedTemplateDraftPage',
      ]),
      ...mapGetters('groups', ['groupList']),
      ...mapGetters('meta', ['templateSearch', 'loading']),
      ...mapGetters('questionnaire', ['questionnaireFilterData']),
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
    },
    methods: {
      ...mapActions('reflections', ['getAllTemplates', 'setTemplateDraftPage']),
      ...mapActions('groups', ['getGroupList']),
      ...mapActions('meta', ['setLoading']),
      ...mapActions('questionnaire', ['getQuestionnaireFilterData']),
      async handlePageInit() {
        this.queries.offset =
          this.selectedTemplateDraftPage === 1
            ? 0
            : this.selectedTemplateDraftPage * this.queries.limit - this.queries.limit;

        this.queries.q = this.templateSearch || null;

        await this.getAllTemplates(this.queries).then(this.handleRequest);
        if (!this.groupList.length) {
          await this.getGroupList(this.groupListQuery);
        }
        if (!this.questionnaireFilterData) {
          await this.getQuestionnaireFilterData();
        }
        this.setLoading(false);
      },
      selectPage(page) {
        const request = {
          q: this.queries.q,
          limit: this.queries.limit,
          offset: page === 1 ? 0 : page * this.queries.limit - this.queries.limit,
          draft: this.queries.draft,
        };
        this.getAllTemplates(request).then(() => {
          this.setTemplateDraftPage(page);
        });
      },
      templateSelect(type) {
        const initialQueries = {
          q: null,
          clientGroupId: null,
          limit: 10,
          offset: null,
        };
        this.totalCount = null;
        this.setTemplateDraftPage(this.defaultPageValue);
        this.queries = initialQueries;

        switch (type) {
          case 'draft':
            this.queries.draft = true;
            this.getAllTemplates(this.queries).then(this.handleRequest);
            break;
          case 'active':
            this.queries.draft = false;
            this.getAllTemplates(this.queries).then(this.handleRequest);
            break;
          case 'all':
            this.queries.draft = null;
            this.getAllTemplates(this.queries).then(this.handleRequest);
            break;
          default:
            break;
        }
      },
      handleRequest(response) {
        const { totalCount, success } = response;
        if (totalCount && success) {
          this.totalCount = totalCount;
        }
      },
      handleTemplateSearch(value) {
        this.queries.q = value;
        this.queries.offset = null;
        this.totalCount = null;
        this.setTemplateDraftPage(this.defaultPageValue);
        this.getAllTemplates(this.queries).then(this.handleRequest);
      },
      handleDelete(success) {
        if (success) this.getAllTemplates(this.queries).then(this.handleRequest);
      },
    },
  };
</script>
<style lang="scss">
  #create__custom {
    max-width: 940px;
    margin: 0 auto;
    .custom__container {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .custom__list-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .custom__list-card {
      text-align: center;
      width: 260px;
      height: 120px;
      margin: 0 auto;
      h2 {
        margin-top: 10px;
      }
      button {
        margin-top: 15px;
        padding: 5px 20px;
      }
    }

    &__icon {
      width: 120px;
      height: 80px;
    }

    &__title {
      margin-top: 26px;
    }
  }
</style>
