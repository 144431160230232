/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dialog-check': {
    width: 41.891,
    height: 50.972,
    viewBox: '0 0 41.891 50.972',
    data: '<g data-name="Group 3777" _fill="none" _stroke="#474eb9"><path pid="0" data-name="Line 1210" stroke-linecap="round" stroke-width="1.5" d="M19.88 44.556l-.617 5.588"/><path pid="1" data-name="Line 1211" stroke-linecap="round" stroke-width="1.5" d="M25.8 43.721l2.463 5.423"/><path pid="2" data-name="Line 1948" stroke-linecap="round" stroke-width="1.5" d="M10.334 1.228l.873 5.892"/><path pid="3" data-name="Line 1947" stroke-linecap="round" stroke-width="1.5" d="M2.263 4.144l4 5"/><path pid="4" data-name="Path 1175" d="M29.427 19.144a4.283 4.283 0 0 0-.634-.525c-2.199-1.49-4.827-2.118-7.283-3.037a40.868 40.868 0 0 0-5.34-1.903 7.387 7.387 0 0 0-5.494.638 9.216 9.216 0 0 0-3.023 3.766 24.184 24.184 0 0 0-2.606 7.482 9.609 9.609 0 0 0 .164 4.191A6.155 6.155 0 0 0 6.3 31.835a3.423 3.423 0 0 0 .872.804c.558.331.442.9.255 1.408a12.407 12.407 0 0 0-.673 2.412 1.683 1.683 0 0 0-.005.693.638.638 0 0 0 .977.384l4.706-2.081a2.866 2.866 0 0 1 1.044-.31 3 3 0 0 1 1.14.277 41.941 41.941 0 0 0 4.568 1.427 9.23 9.23 0 0 0 4.902.263 6.439 6.439 0 0 0 3.116-2.26 14.2 14.2 0 0 0 1.866-3.422A22.8 22.8 0 0 0 30.89 24.1a6.432 6.432 0 0 0-1.462-4.957z" stroke-miterlimit="10" stroke-width="2"/><path pid="5" data-name="Path 3647" d="M11.98 20.989l3.558 8.624 23.75-10.41" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></g>'
  }
})
