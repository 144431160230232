/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comments': {
    width: 42.086,
    height: 42.086,
    viewBox: '0 0 42.086 42.086',
    data: '<g data-name="Group 3922" transform="translate(-948.33 -56.887)"><rect pid="0" data-name="Rectangle 5467" width="42.086" height="42.086" rx="21.043" transform="translate(948.33 56.887)" _fill="#33ca97"/><path pid="1" data-name="Path 3348" d="M958.017 70.109v11.3a2.26 2.26 0 0 0 2.26 2.26h1.13v4.286a.565.565 0 0 0 .965.4l4.686-4.686h10.171a2.26 2.26 0 0 0 2.26-2.26v-11.3a2.26 2.26 0 0 0-2.26-2.26h-16.952a2.26 2.26 0 0 0-2.26 2.26z" _fill="none" _stroke="#fff" stroke-width="1.5"/></g>'
  }
})
