/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '404/oval': {
    width: 11.143,
    height: 11.143,
    viewBox: '0 0 11.143 11.143',
    data: '<g data-name="Group 3909"><circle pid="0" data-name="Ellipse 1875" cx="5.571" cy="5.571" r="5.571" _fill="#66c"/></g>'
  }
})
