/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bin': {
    width: 16,
    height: 16,
    viewBox: '0 0 10.46 13.73',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#939598" stroke-linejoin="round" stroke-width=".75"><path pid="0" d="M8.81 13.36H1.63L.38 4.05h9.69l-1.26 9.31z"/><path pid="1" stroke-linecap="round" d="M.38 1.96h9.7M3.62 6.06l.38 5.3M6.83 6.06l-.38 5.3"/><rect pid="2" x="3.17" y=".38" width="4.13" height="1.59" rx=".5" ry=".5" stroke-linecap="round"/></g></g>'
  }
})
