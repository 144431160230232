/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-edit': {
    width: 10,
    height: 9,
    viewBox: '0 0 10 9',
    data: '<path pid="0" d="M.5 9h1.875l5.53-5.53L6.03 1.597.5 7.126V9zm1-1.46l4.53-4.53.46.46L1.96 8H1.5v-.46zM8.185.146a.498.498 0 0 0-.705 0l-.915.915L8.44 2.936l.915-.915a.498.498 0 0 0 0-.705L8.185.146z" _fill="#3371F2"/>'
  }
})
