/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-happy': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.69" cy="9.7" rx="1.24" ry="1.53" _fill="#2c2c2c"/><ellipse pid="4" cx="21.09" cy="9.7" rx="1.24" ry="1.53" _fill="#2c2c2c"/><path pid="5" d="M4.38 14.62a9 9 0 0 0 17.95 0z" _fill="#71523f"/><path pid="6" d="M7.16 20.16a9 9 0 0 0 12.18.2l-.08-.19c-6.03-2.95-12.02-.05-12.1-.01z" _fill="#ff6669"/></g></g>'
  }
})
