<template>
  <div class="lessons__video">
    <iframe
      width="320"
      height="188"
      :src="`https://www.youtube.com/embed/${videoId}`"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="video__title">
      <a target="_blank" :href="lesson.youtubeUrl">
        <header-h4>{{lesson.title}}</header-h4>
      </a>
    </div>
  </div>
</template>
<script>
import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

export default {
  name: 'VideoLesson',
  components: {
    HeaderH4,
  },
  props: ['lesson'],
  computed: {
    videoId() {
      const videoId = this.lesson.youtubeUrl.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        return videoId.substring(0, ampersandPosition);
      }
      return videoId;
    },
  },
};
</script>
<style lang="scss" scoped>
.lessons__video {
  display: flex;
  flex-direction: column;
  width: 320px;
  .video {
    position: relative;
    width: 100%;
    height: 188px;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  h4 {
    margin-top: 15px;
    text-align: left;
    &:hover {
      color: #3f4a52;
    }
  }

  &:nth-of-type(3n + 2) {
    margin-left: 20px;
    margin-right: 20px;
  }
  &:nth-of-type(n + 4) {
    margin-top: 20px;
  }
}
</style>
