import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lt from '@/locales/lt.json'
import en from '@/locales/en.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'lt',
  fallbackLocale: 'lt',
  messages: {
    'lt': lt,
    'en': en
  }
})

const ltRequire = require('../locales/lt')
const enRequire = require('../locales/en')

if (module.hot) {
  module.hot.accept(['../locales/lt', '../locales/en'], function accept() {
    i18n.setLocaleMessage('lt', ltRequire.default)
    i18n.setLocaleMessage('en', enRequire.default)
  })
}

export default i18n
