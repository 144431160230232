/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-check': {
    width: 119.968,
    height: 80.638,
    viewBox: '0 0 119.968 80.638',
    data: '<g data-name="Group 2051"><g data-name="Group 2045" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round"><path pid="0" data-name="Path 1187" d="M92.746 38.226q.21.813.378 1.643a32.94 32.94 0 1 1-32.278-26.353 32.787 32.787 0 0 1 19.3 6.245" stroke-width="2.5"/><path pid="1" data-name="Path 1188" d="M46.219 40.521l14.627 14.925 40.6-41.553" stroke-width="5"/><path pid="2" data-name="Line 1202" stroke-width="1.5" d="M113.856 57.335h5.362"/><path pid="3" data-name="Line 1203" stroke-width="1.5" d="M116.537 54.654v5.362"/><path pid="4" data-name="Line 1204" stroke-width="1.5" d="M26.441 3.814h6.128"/><path pid="5" data-name="Line 1205" stroke-width="1.5" d="M29.505.75v6.128"/><path pid="6" data-name="Line 1206" stroke-width="1.5" d="M.75 67.388h7.085"/><path pid="7" data-name="Line 1207" stroke-width="1.5" d="M4.292 63.846v7.085"/></g></g>'
  }
})
