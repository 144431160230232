/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-angry': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ff6d15"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ff6412"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ff8249"/><ellipse pid="3" cx="8.43" cy="10.87" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="10.87" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M18.83 19.29a.44.44 0 0 0 .72-.48 6.69 6.69 0 0 0-12.38-.12.44.44 0 0 0 .71.49c1.95-1.9 6.12-4.6 10.95.11z" _fill="#654735"/><path pid="6" _fill="#2c2c2c" d="M7.32 6.466l.899-.898 2.99 2.991-.897.898zM18.488 5.575l.898.898-2.99 2.99-.899-.897z"/></g></g>'
  }
})
