<template>
  <div class="calendar__header">
    <div></div>
    <div class="calendar__date arrows">
      <div class="arrows__left">
        <div
          class="calendar__switch-month"
          @click="setDate(moment(currentDate).subtract(1, 'months'))"
        >
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
        </div>
        <div class="calendar__switch-week" @click="setDate(moment(currentDate).subtract(1, 'weeks'))">
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
        </div>
      </div>
      <p class="calendar__month">
        {{ moment(currentDate).format('MMMM YYYY') }}
      </p>
      <div class="arrows__right">
        <div class="calendar__switch-week" @click="setDate(moment(currentDate).add(1, 'weeks'))">
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
        </div>
        <div class="calendar__switch-month" @click="setDate(moment(currentDate).add(1, 'months'))">
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
          <svgicon name="green-arrow" :original="true" height="1.2em"></svgicon>
        </div>
      </div>
    </div>
    <router-link :to="{name: 'CreateReflection'}">
      <button-7>{{ $t("actions.new_questionnaire_action") }}</button-7>
    </router-link>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import Button7 from '@/components/elements/buttons/Button7.vue';

export default {
  name: 'CalendarHeader',
  components: {
    Button7,
  },
  props: ['date'],
  updated() {
    this.getReflections();
  },
  computed: {
    ...mapGetters('reflections', ['currentDate']),
  },
  methods: {
    ...mapActions('reflections', ['setDate', 'getReflections']),
  },
};
</script>
<style lang="scss">
.calendar__header {
  display: flex;
  justify-content: space-between;
  max-width: 940px;
  margin: 20px auto 0 auto;

  & > div {
    flex: 1;
  }

  .calendar__date {
    align-items: flex-start;
    display: flex;
    flex: 1;

    .calendar__month {
      font-family: Nunito-SemiBold;
      color: $green-4;
      font-size: 14px;
      text-transform: capitalize;
      text-align: center;
      width: 120px;

      padding: 0 10px;
    }

    .arrows {
      &__right {
        display: flex;
        align-items: center;
        svg {
          transform: rotate(180deg);
        }
        .calendar__switch-week {
          margin-right: 10px;
          transition: all .3s;
          cursor: pointer;

          &:hover {
             transform: translateX(3px) scale(1.15);
          }
        }

        .calendar__switch-month {
          transition: all .3s;
          cursor: pointer;

          &:hover {
            transform: translateX(5px) scale(1.15);
          }
        }
      }

      &__left {
        display: flex;
        align-items: center;
        .calendar__switch-week {
          margin-left: 10px;
          transition: all .3s;
          cursor: pointer;

          &:hover {
             transform: translateX(-3px) scale(1.15);
          }
        }

        .calendar__switch-month {
          transition: all .3s;
          cursor: pointer;

          &:hover {
            transform: translateX(-5px) scale(1.15);
          }
        }
      }
    }

    .calendar__switch-week,
    .calendar__switch-month {
      cursor: pointer;
    }
  }
  button {
    font-size: 14px;
  }
}
</style>
