<template>
  <div class="home__callendar-day">
    <div
      class="day__header"
      :class="{ 'day__header--active': day === moment().format('dddd DD'), 'day__header--weekend': weekend}"
    >
      <span>
        {{day}}
      </span>
    </div>
    <ul class="day__reflections day__reflections--empty">
      <div class="empty-reflection" v-for="n in mockReflections" :key="n"></div>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'CalendarDayEmpty',
  props: ['currentDay', 'weekend', 'day', 'empty', 'mockReflections', 'reflections'],
  computed: {
    showDay() {
      const reflections = this.reflections;
      const weekend = this.weekend;
      return weekend && !reflections;
    },
  },
};
</script>
