/* eslint-disable */
require('./3-2-1')
require('./3k')
require('./calc')
require('./closed')
require('./cobeweb')
require('./color-pick-blank')
require('./color-pick-close')
require('./color-pick')
require('./diary')
require('./faces')
require('./hands')
require('./image-pick-blank')
require('./image-pick-close')
require('./incomplete_sentence')
require('./indicator')
require('./lego')
require('./logo-grey')
require('./open')
require('./palms')
require('./paper')
require('./pick-plus')
require('./pie')
require('./progress_matrix')
require('./question-select')
require('./question-text')
require('./question')
require('./sentence')
require('./smiley-select-blank')
require('./smiley-select')
require('./smileys')
require('./target')
require('./termometer')
require('./thermometer')
require('./tools')
require('./traffic_light')
require('./yes_no')
