/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/3k': {
    width: 33.974,
    height: 33.974,
    viewBox: '0 0 33.974 33.974',
    data: '<g data-name="Group 3832"><g data-name="Group 3831"><g data-name="Group 1445" transform="translate(-701.572 -255.011)"><circle pid="0" data-name="Ellipse 278" cx="16.559" cy="16.559" r="16.559" transform="matrix(1 -.026 .026 1 701.572 255.878)" _fill="#6666ca"/><g data-name="Group 1444"><g data-name="Group 1443"><g data-name="Group 1441"><path pid="1" data-name="Path 978" d="M716.79 274.5c0 2.48-1.44 3.85-4.07 3.85a3.827 3.827 0 0 1-3.74-2.29.913.913 0 0 1 .39-1.13.874.874 0 0 1 .69-.05.926.926 0 0 1 .56.52 2.056 2.056 0 0 0 2.1 1.16c1.65 0 2.3-.61 2.3-2.16 0-1.17-.77-1.81-2.16-1.81a.826.826 0 0 1-.71-.4.652.652 0 0 1-.15-.44.876.876 0 0 1 .95-.85 1.66 1.66 0 0 0 1.77-1.63c0-1.26-.58-1.81-1.93-1.81a1.683 1.683 0 0 0-1.74 1.01.912.912 0 0 1-1.09.52.812.812 0 0 1-.51-.4.954.954 0 0 1-.02-.76 3.34 3.34 0 0 1 3.36-2.07c2.35 0 3.65 1.23 3.67 3.46a3 3 0 0 1-1.19 2.42 3.285 3.285 0 0 1 1.52 2.86z" _fill="#fff"/></g><g data-name="Group 1442"><path pid="2" data-name="Path 979" d="M728.27 277.6a.933.933 0 0 1-.35.61.91.91 0 0 1-.56.17h-.11a1.011 1.011 0 0 1-.7-.37l-4.18-5.06-.91.96v3.52a.908.908 0 0 1-.92.93.921.921 0 0 1-.93-.93v-10.72a.925.925 0 1 1 1.85 0v4.48l4.71-5.02a1.13 1.13 0 0 1 1.41-.24.917.917 0 0 1 .37.66.977.977 0 0 1-.25.73l-4.03 4.26 4.38 5.33a.912.912 0 0 1 .22.69z" _fill="#fff"/></g></g></g></g></g></g>'
  }
})
