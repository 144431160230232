/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'green-check': {
    width: 15.476,
    height: 15.476,
    viewBox: '0 0 15.476 15.476',
    data: '<g data-name="Group 1915" transform="translate(-449.197 -317.197)"><circle pid="0" data-name="Ellipse 755" cx="7.738" cy="7.738" r="7.738" transform="translate(449.197 317.197)" _fill="#13a85b"/><path pid="1" data-name="Path 1116" d="M460.759 322.524l-5.182 5.629-2.533-2.818" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g>'
  }
})
