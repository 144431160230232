<template>
  <div class="steps-wrapper flex-global">
    <div class="steps-wrapper__step" v-for="(step, index) in steps" :key="step.id">
      <router-link tag="span" :to="step.route">{{ step.name }}</router-link>
      <svgicon
        v-if="index !== steps.length - 1"
        name="chevron-big"
        :original="true"
        width="8px"
        height="12px"
        :fill="false"
      ></svgicon>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'StepViewer',
    components: {},
    data() {
      return {
        steps: [
          {
            id: 'licence',
            name: this.$t('profile.licences'),
            route: '/purchase-licence/licence',
          },
          {
            id: 'checkout',
            name: this.$t('orders.payment'),
            route: '/purchase-licence/checkout',
          },
        ],
      };
    },
    computed: {},
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .steps-wrapper {
    margin-bottom: 64px;
    &__step {
      pointer-events: none;
      span {
        font-size: 16px;
        font-weight: 400;
        color: $grey-3;
        &.router-link-active {
          border-bottom: 2px solid $violet-3;
          font-weight: 600;
          color: $violet-3;
        }
      }
      svg {
        stroke: none;
      }
      &:not(:first-child) {
        span {
          margin-left: 48px;
        }
      }
      &:not(:last-child) {
        span {
          margin-right: 48px;
        }
      }
    }
  }
</style>
