/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile-circle-grey': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M7.267 0C3.26 0 0 3.26 0 7.267c0 4.006 3.26 7.265 7.267 7.265 4.006 0 7.265-3.26 7.265-7.265C14.532 3.26 11.272 0 7.267 0zm4.718 11.376a4.852 4.852 0 0 0-3.988-3.685 2.573 2.573 0 0 0-.73-5.045 2.573 2.573 0 1 0-.73 5.044 4.851 4.851 0 0 0-3.99 3.686A6.232 6.232 0 0 1 1 7.266 6.274 6.274 0 0 1 7.267 1a6.273 6.273 0 0 1 6.265 6.267 6.232 6.232 0 0 1-1.547 4.11z" _fill="#919396"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h14.532v14.532H0z"/></clipPath></defs>'
  }
})
