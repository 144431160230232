/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/pick-plus': {
    width: 18.99,
    height: 18.99,
    viewBox: '0 0 18.99 18.99',
    data: '<g data-name="Group 3922" _fill="none" _stroke="#5454c4"><path pid="0" data-name="Rectangle 4461" stroke-linejoin="round" d="M.5.5h17.99v17.99H.5z"/><g data-name="Group 2406" stroke-linecap="round" stroke-miterlimit="10"><path pid="1" data-name="Path 32" d="M14.287 9.087l-4.74.083-.082-4.74"/><path pid="2" data-name="Path 33" d="M4.808 9.253l4.74-.083.082 4.74"/></g></g>'
  }
})
