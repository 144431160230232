/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<defs><clipPath id="svgicon_warning_a"><path pid="0" _fill="none" d="M0 0h16v17H0z"/></clipPath></defs><g data-name="Group 3828"><g data-name="Component 7 – 7" clip-path="url(#svgicon_warning_a)"><ellipse pid="1" data-name="Ellipse 985" cx="8" cy="8.5" rx="8" ry="8.5" _fill="#bc0000"/><path pid="2" d="M8.072 11.127a.857.857 0 0 1 .655.232.982.982 0 0 1 .218.7 1.3 1.3 0 0 1-.218.7.857.857 0 0 1-.655.232.857.857 0 0 1-.655-.232.982.982 0 0 1-.218-.7 1.123 1.123 0 0 1 .218-.7c.074-.155.365-.232.655-.232zm.582-1H7.272l-.145-6.109h1.745z" _fill="#fff"/></g></g>'
  }
})
