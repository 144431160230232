/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-wink': {
    width: 16,
    height: 16,
    viewBox: '0 0 28.76 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" d="M18.4 15.05c.14 0 .22.16.22.29a4.07 4.07 0 0 1-.47 1.8 5 5 0 0 1-1.2 1.49 5.72 5.72 0 0 1-3.59 1.3 5.44 5.44 0 0 1-3.62-1.3 4.56 4.56 0 0 1-1.65-3.29c0-.14.09-.31.24-.29s.23.19.32.27l.44.34a8.93 8.93 0 0 0 .95.58 7.09 7.09 0 0 0 3.93.87 6.78 6.78 0 0 0 2.49-.73 8.71 8.71 0 0 0 1.32-.84c.16-.12.42-.44.61-.48z" _fill="#654735"/><ellipse pid="4" cx="8.28" cy="10.96" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M20.23 11.51a2.47 2.47 0 0 0-4.57 0 .4.4 0 0 0 .64.44 2.12 2.12 0 0 1 3.29 0 .4.4 0 0 0 .65-.43z" _fill="#2c2c2c"/><path pid="6" d="M24 1.09A7.57 7.57 0 0 1 19.32 7 7.57 7.57 0 0 1 24 12.87 7.57 7.57 0 0 1 28.76 7 7.57 7.57 0 0 1 24 1.09z" _fill="#fff"/><path pid="7" d="M24 1.09A7.57 7.57 0 0 1 19.32 7s5.02.71 4.68-5.91z" _fill="#f0f1f1"/></g></g>'
  }
})
