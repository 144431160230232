/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/pie': {
    width: 33.406,
    height: 33.406,
    viewBox: '0 0 33.406 33.406',
    data: '<g data-name="Group 3829"><g data-name="Group 2483" transform="translate(-377.933 -624.932)"><circle pid="0" data-name="Ellipse 276" cx="16.559" cy="16.559" r="16.559" transform="rotate(.5 -71422.367 43653.125)" _fill="#6666ca"/><g data-name="Group 1436"><path pid="1" data-name="Path 975" d="M400.916 643.127a7.921 7.921 0 0 1-.23 1.84 7.68 7.68 0 0 1-7.45 5.83 7.774 7.774 0 0 1-1.84-.22 7.672 7.672 0 0 1 1.83-15.12.512.512 0 0 1 .52.5v6.67h6.67a.5.5 0 0 1 .5.5z" _fill="#fff"/></g><g data-name="Group 1437"><path pid="2" data-name="Path 976" d="M403.706 640.147a.508.508 0 0 1-.5.5h-7.17a.5.5 0 0 1-.5-.5v-7.17a.5.5 0 0 1 .5-.5 7.663 7.663 0 0 1 7.67 7.67z" _fill="#fff"/></g></g></g>'
  }
})
