/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-type-org': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M14 8V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v2H2C.9 4 0 4.9 0 6v10c0 1.1.9 2 2 2h5c.55 0 1-.45 1-1v-3h2v3c0 .55.45 1 1 1h5c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-2zM4 16H2v-2h2v2zm0-4H2v-2h2v2zm0-4H2V6h2v2zm4 4H6v-2h2v2zm0-4H6V6h2v2zm0-4H6V2h2v2zm4 8h-2v-2h2v2zm0-4h-2V6h2v2zm0-4h-2V2h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z" _fill="#919396" fill-opacity=".7"/>'
  }
})
