/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-switch': {
    width: 56,
    height: 27,
    viewBox: '0 0 56 27',
    data: '<rect pid="0" x="1" y="1" width="54" height="25" rx="2" _stroke="#5147A7" stroke-width="1.5"/><path pid="1" d="M5 6h14M5 16h14M5 11h14M5 21h14M24 21h-1M24 16h-1M24 11h-1M24 6h-1" _stroke="#5147A7" stroke-width="2" stroke-linecap="round"/>'
  }
})
