/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate_question': {
    width: 23,
    height: 24,
    viewBox: '0 0 23 24',
    data: '<path pid="0" d="M15.5 23H1V1h14.5" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M22.14 4.487H7.338V19.29H22.14V4.487z" _fill="#fff" _stroke="#919396" stroke-linejoin="round"/><path pid="2" d="M11.781 11.884h5.9M14.743 8.962v5.916" _stroke="#919396" stroke-linecap="round"/>'
  }
})
