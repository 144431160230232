/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-out': {
    width: 119.699,
    height: 82.573,
    viewBox: '0 0 119.699 82.573',
    data: '<g data-name="Group 3163"><g data-name="Group 2462" transform="translate(750.6 -443.788)"><g data-name="Group 2461"><path pid="0" data-name="Path 2546" d="M-633.5 501.688h1.9a.684.684 0 0 0 .7-.7.684.684 0 0 0-.7-.7h-1.9v-2a.684.684 0 0 0-.7-.7.684.684 0 0 0-.7.7v1.9h-1.9a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2h1.9v1.9a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.684.684 0 0 0 .7-.7zm-87-53.5h2.3a.684.684 0 0 0 .7-.7.684.684 0 0 0-.7-.7h-2.3v-2.3a.684.684 0 0 0-.7-.7.684.684 0 0 0-.7.7v2.3h-2.3a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2h2.3v2.3a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.684.684 0 0 0 .7-.7zm-25.2 66.4v-2.8h2.8a.684.684 0 0 0 .7-.7.684.684 0 0 0-.7-.7h-2.8v-2.8a.684.684 0 0 0-.7-.7.684.684 0 0 0-.7.7v2.8h-2.8a.684.684 0 0 0-.7.7.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2h2.8v2.8a.764.764 0 0 0 .2.5.764.764 0 0 0 .5.2.684.684 0 0 0 .7-.7z" _fill="#474eb9"/></g><circle pid="1" data-name="Ellipse 1037" cx="11.85" cy="11.85" r="11.85" transform="translate(-682.85 455.437)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="2.2"/><path pid="2" data-name="Path 2547" d="M-660.023 486.278a34.033 34.033 0 0 1-28.767 38.587 34.033 34.033 0 0 1-38.587-28.765 34.033 34.033 0 0 1 28.767-38.59 34.071 34.071 0 0 1 8.99-.119" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.2"/><path pid="3" data-name="Path 2548" d="M-671 461v7.417h6.667" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.2"/></g></g>'
  }
})
