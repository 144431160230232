/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 16,
    height: 16,
    viewBox: '0 0 16.56 22.58',
    data: '<g data-name="Layer 2"><path pid="0" d="M8.28.38a7.91 7.91 0 0 0-7.9 7.9c0 5.41 7.07 13.35 7.37 13.72a.71.71 0 0 0 1.06 0c.3-.34 7.38-8.28 7.38-13.69A7.91 7.91 0 0 0 8.28.38zm0 11.88a4 4 0 1 1 4-4 4 4 0 0 1-4 4z" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width=".75" data-name="Layer 1"/></g>'
  }
})
