<template>
  <div class="reflection-tab reflection-tab--active" v-if="empty && !add">
    <div class="reflection-tab__number">1</div>
  </div>
  <div
    :class="['reflection-tab reflection-tab-draggable', { 'reflection-tab--active': active}]"
    v-else-if="!add && !empty"
  >
    <div :class="['reflection-tab__number', { 'reflection-tab__number--error' : error }]" :style="{'padding': `5px ${10 - bonusQuestions}px`}">{{ number + 1 }}</div>
  </div>
  <div class="reflection-tab__number reflection-tab__number--add" v-else @click="addMethod">
    <svgicon class="reflection-tab__number__icon" name="add_question" :original="true"></svgicon>
    <span class="reflection-tab__number__error" v-if="maxQuestionsError">{{maxQuestionsError}}</span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'QuestionTab',
  props: ['number', 'add', 'empty', 'error'],
  data() {
    return {
      maxQuestionsError: null,
    };
  },
  computed: {
    ...mapGetters('methods', ['selectedMethod']),
    ...mapGetters('questionnaire', ['currentTab', 'questionnaire', 'questionError']),
    active() {
      return this.number === this.currentTab && this.selectedMethod;
    },
    bonusQuestions() {
      const number = this.questionnaire.questions.length;
      const max = 12;
      const bonusQuestions = number - max;
      if (bonusQuestions >= 1) {
        return bonusQuestions;
      } return false;
    },
  },
  methods: {
    ...mapActions('methods', ['onMethodUnset']),
    ...mapActions('questionnaire', ['setQuestionError', 'handleQuestionErrors']),
    addMethod() {
      const { questions } = this.questionnaire;
      if (!questions.length) {
        this.onMethodUnset();
        return;
      }
      if (questions.length >= 20) {
        this.maxQuestionsError = 'Maksimalus klausimų kiekis pasiektas';
        setTimeout(() => {
          this.maxQuestionsError = null;
        }, 4500);
        return;
      }
      const question = this.questionnaire.questions[this.currentTab];
      if (question) {
        this.handleQuestionErrors({
          question,
          i: this.currentTab,
        }).then((res) => {
          if (!res) this.onMethodUnset();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.reflection-tab {
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-family: Nunito-SemiBold;
  font-size: 12px;
  color: $grey-4;
  position: relative;

  &--active {
    background-color: #fff;
    color: $grey-5;
    z-index: 3;
    border-bottom: #fff;

    &::before,
    &::after {
      content: '';
      background: #fff;
      width: 12px;
      height: 12px;
      z-index: 1;
    }

    &::before {
      left: -10px;
    }
    &::after {
      right: -10px;
    }

    .reflection-tab__number {
      &::before,
      &::after {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $grey-6;
        z-index: 2;
        bottom: 3px;
      }

      &::before {
        left: -14px;
      }

      &::after {
        right: -14px;
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
  }

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  &__number {
    padding: 7px 10px;

    &::before,
    &::after {
      position: absolute;
    }

    &:hover {
      cursor: pointer;
    }

    &--add {
      margin-left: 10px;
      margin-top: -5px;
    }

    &--error {
      color: $red-4;
      font-family: Nunito-Bold;
    }

    &__icon {
      height: 18px;
      width: 18px;
      transition: all .3s;

      &:hover {
        transform: translateY(-3px) scale(1.25);
      }
    }

    &__error {
      font-size: 12px;
      color: $red-4;
      position: absolute;
      top: 55px;
      right: 52px;
      z-index: 1;
    }
  }
}
</style>
