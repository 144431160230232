<template>
  <div class="options-modal" @click="toggleOptionsModal" v-if="type === 'errors'">
    <div class="options-modal__bg">
      <div class="options-modal__modal" @click.stop>
        <div class="options-modal__container">
          <svgicon
            @click="toggleOptionsModal"
            color="grey"
            :fill="false"
            name="multiply"
            class="options-modal__close"
          ></svgicon>
          <svgicon
            class="options-modal__container__icon"
            name="large-remove"
            :original="true"
          ></svgicon>
          <h4 class="options-modal__container__heading">
            {{ $t('questionnaire.questionnaire_finish_message') }}
          </h4>
          <div class="options-modal__container__items" v-if="questionnaireErrors">
            <div
              class="options-modal__schedule"
              v-for="(error, index) in questionnaireErrors"
              :key="index"
            >
              <p class="options-modal__schedule__title options-modal__schedule__title--error">
                {{ error }}
              </p>
            </div>
          </div>
          <div class="options-modal__container__actions">
            <button-3 @click.native="toggleOptionsModal">{{ $t('actions.ok_action') }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="options-modal" @click="toggleOptionsModal" v-else>
    <div class="options-modal__bg">
      <div class="options-modal__modal" @click.stop>
        <div class="options-modal__container">
          <svgicon
            @click="toggleOptionsModal"
            color="grey"
            :fill="false"
            name="multiply"
            class="options-modal__close"
          ></svgicon>
          <svgicon
            v-if="incomplete || !isValidSchedule || !isValidDateTime"
            class="options-modal__container__icon"
            name="large-remove"
            :original="true"
          ></svgicon>
          <svgicon
            v-else
            class="options-modal__container__icon"
            name="large-check"
            :original="true"
          ></svgicon>
          <h4
            class="options-modal__container__heading"
            v-if="!success && !type && !incomplete && isValidSchedule && isValidDateTime"
          >
            {{ $t('modals.options_modal_your_quest_will_be_sent') }}
          </h4>
          <div v-if="success && !type">
            <h4 class="options-modal__container__heading">
              {{ $t('modals.options_modal_your_quest_sent') }}
            </h4>
            <h4 class="options-modal__container__heading second-heading">
              {{ $t('modals.options_modal_your_quest_sent_2') }}
            </h4>
          </div>
          <h4 class="options-modal__container__heading" v-if="type === 'template'">
            {{ $t('modals.options_modal_your_template_saved') }}
          </h4>
          <h4 class="options-modal__container__heading" v-if="type === 'draft'">
            {{ $t('modals.options_modal_your_quest_saved') }}
          </h4>
          <h4 class="options-modal__container__heading" v-if="type === 'draftUpdate'">
            {{ $t('modals.options_modal_your_quest_updated') }}
          </h4>
          <h4 class="options-modal__container__heading" v-if="type === 'templateUpdate'">
            {{ $t('modals.options_modal_your_template_updated') }}
          </h4>
          <h4 class="options-modal__container__heading" v-if="type === 'templateCloned'">
            {{ $t('modals.options_modal_template_copied') }}
          </h4>
          <h4
            class="options-modal__container__heading header-error"
            v-if="incomplete && isValidSchedule"
          >
            {{ $t('modals.options_modal_add_group_and_options') }}
          </h4>
          <h4 class="options-modal__container__heading header-error" v-if="!isValidSchedule">
            {{ $t('modals.options_modal_no_end_time') }}
          </h4>
          <h4 class="options-modal__container__heading header-error" v-if="!isValidDateTime">
            {{ $t('modals.options_modal_start_time_past_warning') }}
          </h4>
          <div
            class="options-modal__container__items schedule-items"
            v-if="!success && !type && isValidSchedule && isValidDateTime"
          >
            <h5 class="options-modal__schedule__group">{{ group }}</h5>
            <div
              class="options-modal__schedule"
              v-for="(schedule, index) in schedulesForDisplay"
              :key="index"
            >
              <p class="options-modal__schedule__title">
                {{ schedule.toDate ? `${$t('from_cap')} ${schedule.fromDate}` : schedule.fromDate }}
                <span v-if="schedule.toDate">{{ $t('to') }} {{ schedule.toDate }}</span>
                <span v-else>{{ schedule.time }}</span>
              </p>
              <p
                class="options-modal__schedule__title"
                v-if="schedule.daysOfWeek && schedule.weekStep"
              >
                <span v-for="day in schedule.daysOfWeek" :key="day">{{ day }},</span>
                {{ schedule.time }}, {{ $t('every') }} {{ schedule.weekStep }} {{ $t('week_abb') }}.
              </p>
            </div>
          </div>
          <div
            class="options-modal__container__actions"
            v-if="!success && !type && !incomplete && isValidSchedule && isValidDateTime"
          >
            <button-4 @click.native="submitOptions" :disabled="disableButton">
              {{ $t('actions.confirm_action') }}
            </button-4>
            <button-3 @click.native="toggleOptionsModal">
              {{ $t('actions.change_options_action') }}
            </button-3>
          </div>
          <div
            class="options-modal__container__actions"
            v-if="
              type === 'draft' ||
                type === 'template' ||
                incomplete ||
                !isValidSchedule ||
                !isValidDateTime
            "
          >
            <button-3 @click.native="toggleOptionsModal">{{ $t('actions.ok_action') }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'OptionsModal',
    components: {
      Button3,
      Button4,
    },
    props: ['type', 'errors'],
    data() {
      return {
        optionsModal: false,
        success: false,
        incomplete: false,
        disableButton: false,
      };
    },
    updated() {
      const { name } = this.$route;
      if (name === 'ReflectionOptions') {
        const { clientGroupId, schedules } = this.questionnaire;
        if (!clientGroupId || !schedules.length) this.incomplete = true;
        else this.incomplete = false;
      }
    },
    computed: {
      ...mapGetters('questionnaire', [
        'questionnaire',
        'questionnaireSummary',
        'questionnaireErrors',
      ]),
      schedules() {
        if (this.questionnaireSummary) return this.questionnaireSummary.schedules;
        return [];
      },
      schedulesForDisplay() {
        return this.schedules.filter(
          (schedule) =>
            !(
              (schedule.id &&
                schedule.repeatType === 'custom' &&
                schedule.parentScheduleId === 0) ||
              (!schedule.id && schedule.repeatType === 'custom' && !schedule.isChild)
            )
        );
      },
      group() {
        if (!this.questionnaireSummary) return false;
        return this.questionnaireSummary.group;
      },
      isValidSchedule() {
        let isValid = 0;
        this.schedules.forEach((schedule) => {
          if (schedule.repeatType === 'daily' || schedule.repeatType === 'custom') {
            if (!schedule.toDate) {
              isValid = 1;
            }
          }
        });
        return isValid === 0;
      },
      isValidDateTime() {
        let isValid = 0;
        const today = this.moment().format('YYYY-MM-DD HH:mm');
        this.schedules.forEach((schedule) => {
          if (!schedule.active) {
            const scheduleDateTime = this.moment(
              schedule.fromDate + schedule.time,
              'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm');
            if (scheduleDateTime <= today) {
              isValid = 1;
            }
          }
          // else {
          //   const scheduleDate = this.moment(schedule.fromDate).format('YYYY-MM-DD');
          //   if (scheduleDate === today) {
          //     isValid = 1;
          //   }
          // }
        });
        return isValid === 0;
      },
    },
    methods: {
      ...mapActions('questionnaire', ['updateQuestionnaire']),
      toggleOptionsModal() {
        this.optionsModal = false;
        this.$emit('closeModal');
      },
      submitOptions() {
        this.disableButton = true;
        this.updateQuestionnaire().then(({ success }) => {
          if (success) {
            this.success = success;
            setTimeout(() => {
              this.disableButton = false;
              this.$router.push({ name: 'Home' });
            }, 3000);
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import 'mixins';
  .options-modal {
    &__bg {
      margin: 0;
      z-index: 5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__modal {
      @include hardBoxShadow();
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }

    &__container {
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;

      &__items {
        &.schedule-items {
          width: 100%;
          max-height: 360px;
          overflow: auto;
        }
      }

      &__icon {
        width: 120px;
        height: 82px;
      }

      &__heading {
        margin-top: 20px;
        font-family: Nunito-Bold;
        font-size: 16px;
        color: $violet-5;
        max-width: 260px;
        &.header-error {
          color: $red-4;
        }
        &.second-heading {
          margin-top: 10px;
        }
      }

      &__actions {
        margin-top: 20px;

        button {
          padding: 5px 20px;
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }

    &__schedule {
      margin-top: 14px;

      &__group {
        margin-top: 14px;
        font-family: Roboto-Black;
        font-size: 14px;
        color: $grey-5;
      }

      &__title {
        margin-top: 14px;

        &--error {
          color: $red-4;
        }
      }
    }

    &__close {
      position: absolute;
      height: 10px;
      width: 10px;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
  }
</style>
