<template>
  <div class="options__form-container reflection-options">
    <div class="reflection-options__header" v-if="groupList.length">
      <span class="reflection-options__option-title">
        {{ $t('schedule.target_group') }}
        <span class="reflection-options__option-title--details">
          ({{ $t('schedule.target_group_explanation') }})
        </span>
      </span>
      <multi-select
        :options="groupList"
        :width="280"
        :placeholder="$t('group.select_group_placeholder')"
        :selected="groupList.find((group) => parseInt(group.id) === questionnaire.clientGroupId)"
        @selectedValue="groupSelectHandler"
        :id="'class'"
        :class="[
          'schedule-group-select',
          { 'no-group-error': !scheduleGroup && scheduleErrorCheck },
        ]"
      ></multi-select>
      <p class="schedule-error-message" v-if="!scheduleGroup && scheduleErrorCheck">
        {{ $t('group.select_group_warning_message') }}
      </p>
    </div>
    <options-type-switch :scheduleCountValues="scheduleCountValues" />
    <options-schedule
      v-for="(schedule, i) in schedules"
      :key="`schedule--${i}`"
      :i="i"
      :currentScheduleType="currentScheduleType"
      :isScheduleChecked="scheduleErrorCheck"
      :isScheduleCustomChild="schedule.parentScheduleId || schedule.isChild"
      @handleScheduleRemove="handleScheduleRemove"
    ></options-schedule>
    <div class="modal" @click="handleModalToggle" v-if="removeOptionModal">
      <div class="modal__bg">
        <div class="modal__modal" @click.stop>
          <div class="modal__container">
            <svgicon
              @click="handleModalToggle"
              color="grey"
              :fill="false"
              name="multiply"
              class="modal__close"
            ></svgicon>
            <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
            <h4 class="modal__container__heading">{{ $t('modals.delete_option_question') }}</h4>
            <div class="modal__container__actions">
              <button-4 @click.native="removeScheduleOption">{{ $t('yes_cap') }}</button-4>
              <button-3 @click.native="handleModalToggle">{{ $t('no_cap') }}</button-3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import { lt } from 'vuejs-datepicker/dist/locale';

  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import OptionsSchedule from '@/components/reflections/options/OptionsSchedule.vue';
  import OptionsTypeSwitch from '@/components/reflections/options/OptionsTypeSwitch.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'OptionsForm',
    components: {
      MultiSelect,
      OptionsSchedule,
      OptionsTypeSwitch,
      Button3,
      Button4,
    },
    props: ['groupList'],
    mounted() {
      // if (this.schedules.length === 0) this.addSchedule();
    },
    data() {
      return {
        language: lt,
        values: {
          days: [],
          start: {},
          skip: {},
        },
        reflectionDuration: ['Minučių', 'Valandų', 'Dienų'],
        reflectionRepeat: ['Vieną kartą', 'Kasdien', 'Skirtingomis dienomis'],
        weekStep: [1, 2, 3, 4],
        weekly: false,
        biWeekly: false,
        removeOptionModal: false,
        optionPayload: { i: null, schedule: null },
      };
    },
    computed: {
      ...mapGetters('questionnaire', [
        'questionnaire',
        'schedules',
        'scheduleErrorCheck',
        'currentScheduleType',
      ]),
      scheduleGroup() {
        return this.questionnaire.clientGroupId;
      },
      filteredSchedules() {
        return this.schedules.filter((schedule) => !schedule.parentScheduleId);
      },
      onceTypeScheduleCount() {
        return this.schedules && this.schedules.length
          ? this.schedules.filter(
              (schedule) => schedule.repeatType === 'once' && !schedule.initialEditStatus
            ).length
          : 0;
      },
      recurrentTypeScheduleCount() {
        return this.schedules && this.schedules.length
          ? this.schedules.filter(
              (schedule) =>
                (schedule.repeatType === 'custom' || schedule.repeatType === 'daily') &&
                !schedule.initialEditStatus &&
                ((schedule.id && schedule.parentScheduleId === null) ||
                  (!schedule.id && !schedule.isChild))
            ).length
          : 0;
      },
      scheduleCountValues() {
        return { once: this.onceTypeScheduleCount, recurrent: this.recurrentTypeScheduleCount };
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'handleRemoveSchedule',
        'setQuestionnaireProperty',
        'setScheduleIsEdit',
      ]),
      openPicker() {
        this.$refs.dateStart.showCalendar();
      },
      groupSelectHandler(value) {
        const clientGroupId = parseInt(value.id, 10);
        const payload = {
          type: 'clientGroupId',
          property: clientGroupId,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleModalToggle() {
        this.removeOptionModal = false;
      },
      handleScheduleRemove({ i, schedule }) {
        this.removeOptionModal = true;
        this.optionPayload = { i, schedule };
      },
      removeScheduleOption() {
        this.handleRemoveSchedule(this.optionPayload);
        this.handleModalToggle();
        this.setScheduleIsEdit(false);
      },
    },
  };
</script>
<style lang="scss">
  .reflection-options {
    margin-top: 10px;
    width: 940px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;

      height: 90px;
      padding: 20px;
      margin-bottom: 5px;
      .schedule-group-select {
        &.no-group-error {
          .multiselect__tags {
            border: 1px solid $red-4;
          }
        }
      }
    }

    &__tabs {
      margin-bottom: 5px;
      height: 57px;
      justify-content: space-between;
      background: #fff;
    }

    &__tab-item {
      position: relative;
      flex: 1;
      height: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: $grey-5;
      cursor: pointer;
      svg {
        margin-right: 5px;
        &.custom-schedule-icon {
          path {
            &:nth-child(odd) {
              stroke: $grey-5;
            }
            &:nth-child(even) {
              stroke: none;
              fill: $grey-5;
            }
          }
        }
      }
      .schedule-count-display {
        display: inline-flex;
        margin-left: 10px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 12px;
        background-color: $violet-3;
        color: #fff;
      }
      .tab-border {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
      }
      &.tab-active {
        color: $green-4;
        svg {
          path {
            stroke: $green-4;
          }
          &.custom-schedule-icon {
            path {
              &:nth-child(odd) {
                stroke: $green-4;
              }
              &:nth-child(even) {
                stroke: none;
                fill: $green-4;
              }
            }
          }
        }
        .tab-border {
          background-color: $green-4;
        }
      }
    }

    &__option-title {
      font-family: Nunito-Bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $grey-3;
      margin-bottom: 6px;
      &.white-text {
        color: #ffffff;
      }

      &--details {
        font-family: Nunito-Regular;
        text-transform: initial;
        margin-left: 5px;
      }

      &--delete {
        margin-bottom: 0;
      }

      &--custom {
        margin: 0 0 0 10px;
        align-self: center;
      }
    }

    &__option {
      width: 100%;
      padding: 20px;
      background-color: #fff;
      margin-bottom: 8px;
      &.hide-option {
        display: none;
      }
    }

    &__option-header {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &__option-date {
      font-family: Nunito-SemiBold;
      font-size: 16px;
      color: $grey-5;
    }

    &__delete,
    &__edit {
      display: flex;
      align-items: center;

      &__icon {
        margin-right: 5px;
        height: 27px;
        width: 27px;
        transition: all 0.3s;

        &:hover {
          cursor: pointer;
          transform: translateY(-3px) scale(1.25);
        }
      }
    }

    &__edit {
      margin-right: 20px;
      &__icon {
        margin-right: 0;
        stroke: none;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      margin-top: 14px;
    }

    &__step-buttons {
      margin-top: 16px;
      margin-bottom: 10px;
      justify-content: flex-end;
      button {
        height: 27px;
        &:last-child {
          margin-left: 20px;
        }
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      &--offset {
        margin-left: 200px;
      }
      &.max-width-input {
        max-width: 103px;
        margin-left: 0;
        .reflection-options__option-title {
          white-space: nowrap;
        }
      }
    }

    &__inputs {
      display: flex;
      position: relative;

      .vdp-datepicker {
        width: 103px;
        height: 30px;

        > div {
          input {
            width: 103px;
            height: 30px;
            color: $grey-5;
            border: 1px solid $grey-2;
            font-family: Roboto-Regular;
            font-size: 14px;
            padding-left: 14px;

            &::placeholder {
              color: $grey-4;
            }
          }
        }
      }

      .multiselect {
        margin-left: 20px;
      }
      &--weeks {
        .multiselect {
          margin: 0;
        }
      }
      &--skip {
        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &__remove {
        position: absolute;
        top: 6px;
        right: 6px;
        height: 16px;
        width: 16px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          transform: translateY(-3px) scale(1.15);
        }
      }
    }

    &__input {
      height: 30px;
      color: $grey-5;
      border: 1px solid $grey-2;
      font-family: Roboto-Regular;
      font-size: 14px;
      padding-left: 5px;

      &::placeholder {
        color: $grey-4;
      }

      &--small {
        width: 94px;
      }

      &--large {
        width: 180px;
      }

      &__time-picker {
        border: none;
        background: transparent;
        width: 100%;
      }

      &--time-picker {
        margin-right: 5px;
        width: 105px;
        display: flex;
        align-items: center;
        font-family: Roboto-Regular;
        font-size: 14px;
        border-radius: 0;
        color: $grey-5;
      }
    }

    .week-days {
      display: flex;
      height: 30px;
      .day {
        align-items: center;
        display: flex;
        justify-content: center;
        font-family: Roboto-Regular;
        color: $grey-5;
        font-size: 14px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: $grey-1;
        cursor: pointer;

        &:not(:first-of-type) {
          margin-left: 2px;
        }

        &:hover,
        &--active {
          background-color: $green-3;
          color: #fff;
        }

        &--error {
          border: 1px solid $red-4;
        }
      }
    }
    .schedule-error-message {
      font-size: 14px;
      color: $red-4;
    }
  }
</style>
