/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'triangle-arrow': {
    width: 9,
    height: 4,
    viewBox: '0 0 9 4',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.568 3.969L9 .188H.136l4.432 3.78z" _fill="#fff"/>'
  }
})
