/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-dead': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" _fill="#2c2c2c" d="M4.7 9.35l.862-.863 3.89 3.89-.863.862z"/><path pid="4" _fill="#2c2c2c" d="M8.6 8.49l.863.862-3.89 3.89-.862-.863zM17.254 9.345l.862-.862 3.89 3.889-.863.863z"/><path pid="5" _fill="#2c2c2c" d="M21.145 8.484l.862.862-3.889 3.89-.863-.863z"/><ellipse pid="6" cx="13.35" cy="17.58" rx="2.41" ry="4.09" _fill="#71523f"/><path pid="7" d="M15.39 19.77a3.7 3.7 0 0 0-4 .13c.44 1.07 1.16 1.77 2 1.77s1.61-.76 2-1.9z" _fill="#ff6669"/></g></g>'
  }
})
