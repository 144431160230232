<template>
  <div class="lessons__page">
    <section id="lessons" class="lessons">
      <lessons-header></lessons-header>
      <lessons-filter
        :filterItems="filterItems"
        :currentFilter="currentFilter"
        @handleFilterChange="handleFilterChange"
      >
      </lessons-filter>
      <div class="lessons__container">
          <div v-if="(categories && categories.length) && filteredLessons">
            <transition-group tag="div" class="lessons__videos" name="lesson-list">
              <video-lesson v-for="lesson in filteredLessons[1]" :key="lesson.id" :lesson="lesson" />
            </transition-group>
          </div>
        <div class="no-lessons-message" v-else>
          <p>{{ $t("messages.no_tutorial_content_message") }}</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LessonsHeader from '@/components/lessons/LessonsHeader.vue';
import LessonsFilter from '@/components/lessons/LessonsFilter.vue'
import VideoLesson from '@/components/lessons/VideoLesson.vue';

export default {
  name: 'Lessons',
  components: {
    LessonsHeader,
    LessonsFilter,
    VideoLesson,
  },
  data() {
    return {
      currentFilter: 0
    };
  },
  created() {
    this.handleGetLessons();
  },
  computed: {
    ...mapGetters('meta', ['lessons']),
    filterItems() {
      return Object.keys(this.lessons);
    },
    categories() {
      return Object.entries(this.lessons);
    },
    filteredLessons() {
      return this.categories.find((category, index) => index === this.currentFilter);
    }
  },
  methods: {
    ...mapActions('meta', ['getLessons']),
    handleGetLessons() {
      this.getLessons();
    },
    handleFilterChange(index) {
      this.currentFilter = index;
    }
  },
};
</script>
<style lang="scss" scoped>
#lessons {
  max-width: 1000px;
  margin: 20px auto 0 auto;
  position: relative;

  .lessons__container {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    .lesson-list-enter-active, .list-leave-active {
      transition: all .1s;
    }
    .lesson-list-enter, .lesson-list-leave-to {
      opacity: 0;
    }

    .lessons__videos {
      display: flex;
      flex-wrap: wrap;

      .lessons__contact {
        width: inherit;
      }
    }
    .no-lessons-message {
      width: 100%;
      margin-top: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
