<template>
  <div class="member-answer__answer-matrix">
    <div class="progress-matrix">
      <div class="progress-matrix__header">
        <div class="header-label header-label--main">
          <span class="label-level">Lygis</span>
          <div class="label-break-line"></div>
          <span class="label-question">Klausimas</span>
        </div>
        <div class="header-label">
          <span>Nereflektavo</span>
        </div>
        <div class="header-label">
          <span>Pirmieji žingsniai</span>
        </div>
        <div class="header-label">
          <span>Judu pirmyn</span>
        </div>
        <div class="header-label">
          <span>Man pavyksta</span>
        </div>
        <div class="header-label">
          <span>Galiu dalintis su kitais</span>
        </div>
      </div>
      <div class="progress-matrix__row" v-for="answer in method.answers" :key="answer.type">
        <div class="progress-matrix__col progress-matrix__col--question">
          <span class="matrix-answer matrix-answer--question">{{ answer.type }}</span>
        </div>
        <div
          class="progress-matrix__col"
          v-for="selection in answer.selections"
          :key="selection.title"
        >
          <span :class="['matrix-answer', { 'matrix-answer--selected': selection.selected }]">{{
            selection.title
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TemplateMatrix',
  props: ['method'],
};
</script>
<style lang="scss">
.member-answer {
  &__answer-matrix {
    margin-top: 20px;

    .progress-matrix {
      display: flex;
      flex-direction: column;

      &__header {
        display: flex;

        .header-label {
          flex: 1;
          text-align: center;

          padding: 15px 0;
          border-bottom: 2px solid $grey-6;

          &:not(:last-of-type) {
            border-right: 2px solid $grey-6;
          }

          span {
            font-family: Roboto-Medium;
            font-size: 13px;
            color: $violet-6;
          }

          &--main {
            position: relative;
            min-width: 130px;

            span {
              font-family: Roboto-Regular;
              font-size: 13px;
              color: $grey-3;
              position: absolute;
            }

            .label-level {
              right: 11px;
              top: 5px;
            }

            .label-question {
              left: 20px;
              bottom: 5px;
            }

            .label-break-line {
              position: absolute;
              right: -7px;
              bottom: 19px;
              width: 100px;
              border-bottom: 2px solid $grey-6;
              transform: rotate(24deg);
            }
          }

          &:last-of-type {
            span {
              font-size: 12px;
            }
          }
        }
      }

      &__row {
        display: flex;
        border-bottom: 2px solid $grey-6;

        .progress-matrix__col {
          flex: 1;
          text-align: center;
          padding: 20px 0;

          .matrix-answer {
            font-family: Roboto-Regular;
            font-size: 13px;
            color: $grey-2;

            &--question {
              color: $grey-5;
            }

            &--selected {
              font-family: Roboto-Medium;
              color: $green-3;
            }
          }

          &:not(:last-of-type) {
            border-right: 2px solid $grey-6;
          }
        }
      }
    }
  }
}
</style>
