/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/sentence': {
    width: 43.271,
    height: 43.271,
    viewBox: '0 0 43.271 43.271',
    data: '<g data-name="Group 1119" transform="translate(-56.963 -744.205)"><circle pid="0" data-name="Ellipse 176" cx="16.559" cy="16.559" r="16.559" transform="rotate(-22.5 1931.025 235.253)" _fill="#6666ca"/><path pid="1" data-name="Line 87" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M69.543 760.718h6.758"/><path pid="2" data-name="Line 88" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M78.666 760.718h1.757"/><path pid="3" data-name="Line 89" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M82.383 760.718h5.271"/><path pid="4" data-name="Line 90" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M69.543 763.727h10.34"/><path pid="5" data-name="Line 91" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M81.707 763.727h5.947"/><path pid="6" data-name="Line 92" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M69.543 766.736h3.379"/><path pid="7" data-name="Line 93" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75" d="M75.052 766.736h12.602"/><path pid="8" data-name="Path 755" d="M76.292 770.217a.745.745 0 1 1-.746-.746.746.746 0 0 1 .746.746z" _fill="#fff"/><path pid="9" data-name="Path 756" d="M79.412 770.217a.746.746 0 1 1-.746-.746.746.746 0 0 1 .746.746z" _fill="#fff"/><path pid="10" data-name="Path 757" d="M82.531 770.217a.745.745 0 1 1-.745-.746.746.746 0 0 1 .745.746z" _fill="#fff"/></g>'
  }
})
