<template>
  <div class="member-reflection__header">
    <div class="header__top">
      <div class="header__title">
        <svgicon
          class="header__title__icon"
          v-if="!questionnaire.icon"
          name="logos/grey-logo"
          :original="true"
        ></svgicon>
        <img class="header__title__icon" v-else :src="questionnaire.icon" alt="klausimyno ikona" />
        <header-h1>{{ questionnaire.title }}</header-h1>
      </div>
      <div class="header__actions">
        <router-link
          tag="span"
          :to="{ name: 'ReflectionOptions', params: { id: questionnaire.id } }"
        >
          <svgicon
            name="plane"
            :original="true"
            height="1em"
            :fill="false"
            color="#919396"
          ></svgicon>
          {{ $t('group.group_schedule_settings') }}
        </router-link>
        <router-link
          tag="span"
          :to="{ name: 'EditReflection', params: { id: questionnaire.id } }"
          @click.native="() => setEditStatus('edit')"
        >
          <svgicon name="pen" :original="true" height="1em" :fill="false" color="#919396"></svgicon>
          {{ $t('actions.edit_questionnaire_action') }}
        </router-link>
      </div>
    </div>
    <div class="header__bottom" v-if="member">
      <span>
        <svgicon name="person" :original="true" width="13px" heigh="13px"></svgicon>
        <router-link
          v-if="questionnaire.clientGroupId"
          tag="span"
          class="member-link"
          :to="{
            name: 'GroupMember',
            params: { groupId: questionnaire.clientGroupId, memberId: member.id },
          }"
        >
          {{ `${member.name} ${member.lastName}` }}
        </router-link>
        <span v-else>{{ `${member.name} ${member.lastName}` }}</span>
      </span>
      <div
        class="group-reflection-header__bottom__reflection-list"
        v-if="memberReflections && isStatsPage"
      >
        <svgicon
          v-if="isValidPrev && !questionLoading"
          name="page_prev"
          class="group-reflection-header__bottom__reflection-list__arrow"
          @click="handleReflectionChange('prev')"
        ></svgicon>
        <span class="group-reflection-header__bottom__reflection-list__reflection">
          {{ reflectionName }}
        </span>
        <svgicon
          v-if="isValidNext && !questionLoading"
          name="page_next"
          class="group-reflection-header__bottom__reflection-list__arrow"
          @click="handleReflectionChange('next')"
        ></svgicon>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

  export default {
    name: 'MemberReflectionHeader',
    components: {
      HeaderH1,
    },
    props: ['member', 'questionLoading'],
    data() {
      return {
        currentReflectionIndex: null,
      };
    },
    created() {
      this.handleCurrentReflection();
    },
    computed: {
      ...mapGetters('stats', ['currentTab']),
      ...mapGetters('reflections', ['currentReflection']),
      ...mapGetters('members', ['memberReflections']),
      ...mapGetters('questionnaire', ['questionnaire']),
      isStatsPage() {
        return this.$route.name === 'GroupMemberStats';
      },
      isValidNext() {
        if (this.currentReflectionIndex <= 0) return false;
        return true;
      },
      isValidPrev() {
        const total = this.memberReflections.length;
        if (total <= this.currentReflectionIndex + 1) return false;
        return true;
      },
      reflectionName() {
        if (!this.memberReflections.length) return false;
        if (!this.currentReflection) return false;

        const { name, start } = this.currentReflection;
        return name || start;

        // const { id, name, start } = this.currentReflection;
        // return name || `Refleksijai id: ${id} nepriskirtas pavadinimas`;
      },
      rawReflectionDate() {
        const { start } = this.currentReflection;
        return start;
      },
    },
    methods: {
      ...mapActions('reflections', ['setEditStatus', 'setCurrentReflection']),
      ...mapActions('questionnaire', ['getQuestionnaire']),
      handleCurrentReflection() {
        if (!this.currentReflection) return;
        if (!this.memberReflections.length) return;
        this.currentReflectionIndex = this.memberReflections.findIndex(
          (reflection) => reflection.id === this.currentReflection.id
        );
      },
      async handleReflectionChange(value) {
        const {
          params: { questionnaireId },
        } = this.$route;
        if (value === 'prev') {
          if (!this.isValidPrev) return;
          this.currentReflectionIndex += 1;
        } else {
          if (!this.isValidNext) return;
          this.currentReflectionIndex -= 1;
        }
        await this.setCurrentReflection(this.memberReflections[this.currentReflectionIndex]).then(
          () => {
            this.getQuestionnaire({ questionnaireId, reflectionDate: this.rawReflectionDate });
          }
        );
        this.$emit('handleReflectionChange');
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import 'mixins';
  .member-reflection {
    &__header {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .header {
        &__top {
          display: flex;
        }

        &__title {
          display: flex;
          text-align: left;
          flex: 22%;
          display: flex;
          h1 {
            flex: 1;
            margin-left: 15px;
            max-width: 543px;
          }

          &__icon {
            height: 42px;
            width: 42px;
            border-radius: 50%;
          }
        }

        &__actions {
          display: flex;
          flex-direction: column;
          text-align: right;
          span {
            font-family: Nunito-Bold;
            font-size: 10px;
            text-transform: uppercase;
            color: $grey-3;

            @include handleHover();

            svg {
              margin-right: 5px;
            }
            &:last-of-type {
              margin-top: 10px;
            }
          }
        }

        &__bottom {
          display: flex;
          margin-top: 25px;
          padding-right: 222px;
          justify-content: flex-start;
          align-items: baseline;

          span {
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;

            &:not(:first-of-type) {
              margin-left: 10px;
            }
            svg {
              margin-right: 5px;
            }
            &.member-link {
              color: $green-4;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
