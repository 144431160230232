/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/wifi': {
    width: 7.071,
    height: 4.771,
    viewBox: '0 0 7.071 4.771',
    data: '<g data-name="Group 1109"><g data-name="Group 220" _fill="#fff"><path pid="0" data-name="Path 224" d="M0 1.522a.756.756 0 0 1 .217-.24 4.828 4.828 0 0 1 1.187-.8A4.916 4.916 0 0 1 2.717.07 4.978 4.978 0 0 1 4.224.051a4.875 4.875 0 0 1 2.734 1.337c.041.041.075.091.113.137v.067c-.069.081-.133.167-.206.243-.157.162-.319.319-.477.479-.06.06-.123.111-.213.079a.293.293 0 0 1-.1-.067 3.59 3.59 0 0 0-1.022-.7 3.531 3.531 0 0 0-1.07-.3 3.607 3.607 0 0 0-2.242.443A3.513 3.513 0 0 0 1 2.327a.19.19 0 0 1-.2.071.287.287 0 0 1-.106-.068 51.942 51.942 0 0 1-.621-.621.671.671 0 0 1-.072-.112z"/><path pid="1" data-name="Path 225" d="M5.923 2.715a.557.557 0 0 1-.067.123c-.213.218-.429.432-.644.647a.176.176 0 0 1-.258 0 2.134 2.134 0 0 0-.495-.36 2.068 2.068 0 0 0-2.392.357.182.182 0 0 1-.278-.01l-.59-.589c-.056-.056-.107-.116-.08-.2a.222.222 0 0 1 .053-.09 3.274 3.274 0 0 1 1.934-.936 3.318 3.318 0 0 1 1.128.055 3.238 3.238 0 0 1 1.427.713 2.535 2.535 0 0 1 .189.169.586.586 0 0 1 .073.121z"/><path pid="2" data-name="Path 226" d="M2.436 3.782a1.637 1.637 0 0 1 2.162.017l-.132.12-.906.812c-.058.052-.06.053-.116 0q-.5-.463-.991-.929a.123.123 0 0 1-.017-.02z"/></g></g>'
  }
})
