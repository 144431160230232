<template>
  <div class="methods__filter">
    <div class="filter__types">
      <header-h3>Tipai</header-h3>
      <multi-select
        :placeholder="'Visi'"
        :width="178"
        :options="type"
        @selectedValue="handleTypeSelect"
      ></multi-select>
    </div>
    <div class="filter__time">
      <header-h3>Laikas</header-h3>
      <multi-select
        :placeholder="'Visi'"
        :width="178"
        :options="time"
        @selectedValue="handleTimeSelect"
      ></multi-select>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import HeaderH3 from '@/components/elements/typography/HeaderH3.vue';
import MultiSelect from '@/components/elements/select/MultiSelect.vue';

export default {
  name: 'MethodFilter',
  components: {
    HeaderH3,
    MultiSelect,
  },
  data() {
    return {
      time: [
        { value: 'Visi', data: 'all' },
        { value: '1 min', data: 60 },
        { value: '3 min', data: 180 },
        { value: '5 min', data: 300 },
        { value: '5+ min', data: 301 },
      ],
      type: [{ value: 'Atviri', data: true }, { value: 'Uždari', data: false }, { value: 'Visi', data: 'all' }],
    };
  },
  computed: {
    ...mapGetters('methods', ['methodsList']),
  },
  methods: {
    ...mapActions('methods', ['setFilteredMethods']),
    handleTimeSelect(value) {
      const payload = {
        type: 'time',
        value: value.data,
      };
      this.setFilteredMethods(payload);
    },
    handleTypeSelect(value) {
      const payload = {
        type: 'type',
        value: value.data,
      };
      this.setFilteredMethods(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.methods__filter {
  margin-top: 20px;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  h3 {
    color: #919396;
    margin-right: 10px;
  }
  .filter__types,
  .filter__time {
    display: flex;
    align-items: center;
  }
  .filter__time {
    margin-left: 30px;
  }
}
</style>
