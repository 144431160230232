/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search-grey': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" d="M6.314.5a6.314 6.314 0 0 1 6.315 6.314 6.33 6.33 0 0 1-1.516 4.11l.262.262h.768L17 16.043 15.543 17.5l-4.857-4.857v-.768l-.263-.262a6.33 6.33 0 0 1-4.109 1.516A6.315 6.315 0 0 1 6.314.5zm0 1.943a4.353 4.353 0 0 0-4.371 4.371 4.353 4.353 0 0 0 4.371 4.372 4.353 4.353 0 0 0 4.372-4.372 4.353 4.353 0 0 0-4.372-4.371z" _fill="#AAA"/>'
  }
})
