<template>
  <transition name="modal__fade">
    <div class="modal__backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="calendarModal"
        aria-describedby="calendarModal"
        @click.stop
      >
        <calendar-modal
          @close="close"
          :reflection="reflection"
          :data="data"
          :loginModal="login"
          :reflectionData="reflectionData"
        ></calendar-modal>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

import CalendarModal from '@/components/calendar/CalendarModal.vue';

export default {
  name: 'Modal',
  components: {
    CalendarModal,
  },
  props: ['reflection', 'data', 'login', 'reflectionData'],
  computed: {
    ...mapGetters('user', ['loginModal']),
  },
  methods: {
    close() {
      if (this.loginModal) return null;
      return this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';
.modal__backdrop {
  margin: 0 !important;
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
}

.modal__fade-enter,
.modal__fade-leave-active {
  opacity: 0;
}
.modal__fade-enter-active,
.modal__face-leave-active {
  transition: opacity 0.5s ease-in-out;
}
</style>
