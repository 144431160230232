/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/cobeweb': {
    width: 33.044,
    height: 33.118,
    viewBox: '0 0 33.044 33.118',
    data: '<g data-name="Group 3834"><g data-name="Group 1447" _fill="#6666ca"><path pid="0" data-name="Path 980" d="M15.156 17.337H9.729l2.714 4.7z"/><path pid="1" data-name="Path 981" d="M16.522 18.126l-2.714 4.7h5.427z"/><path pid="2" data-name="Path 982" d="M12.941 8.769h7.161l1.739-3.012H11.202z"/><path pid="3" data-name="Path 983" d="M21.463 9.559l3.58 6.2h3.479l-5.319-9.213z"/><path pid="4" data-name="Path 984" d="M17.888 17.337l2.714 4.7 2.714-4.7z"/><path pid="5" data-name="Path 985" d="M17.888 15.758h5.427l-2.714-4.7z"/><path pid="6" data-name="Path 986" d="M24.073 5.046l6.208 10.751h2.762a16.524 16.524 0 0 0-7.58-13.161z"/><path pid="7" data-name="Path 987" d="M15.156 15.758l-2.714-4.7-2.714 4.7z"/><path pid="8" data-name="Path 988" d="M16.522 14.97l2.714-4.7h-5.429z"/><path pid="9" data-name="Path 989" d="M9.015 28.126L2.763 17.297H0a16.525 16.525 0 0 0 7.636 13.219z"/><path pid="10" data-name="Path 990" d="M7.997 15.758l3.58-6.2-1.74-3.013-5.319 9.213z"/><path pid="11" data-name="Path 991" d="M2.763 15.797L9.012 4.975 7.64 2.599a16.529 16.529 0 0 0-7.639 13.2z"/><path pid="12" data-name="Path 992" d="M30.281 17.297l-6.212 10.759 1.4 2.423a16.526 16.526 0 0 0 7.576-13.182z"/><path pid="13" data-name="Path 993" d="M10.33 4.257h12.466l1.373-2.377a16.483 16.483 0 0 0-15.23-.031z"/><path pid="14" data-name="Path 994" d="M22.789 28.838H10.337l-1.4 2.428a16.482 16.482 0 0 0 15.239-.03z"/><path pid="15" data-name="Path 995" d="M25.047 17.337l-3.58 6.2 1.74 3.013 5.319-9.213z"/><path pid="16" data-name="Path 996" d="M11.577 23.536l-3.58-6.2H4.518l5.319 9.213z"/><path pid="17" data-name="Path 997" d="M20.101 24.326h-7.159l-1.739 3.013h10.638z"/></g></g>'
  }
})
