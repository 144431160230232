/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-green': {
    width: 12,
    height: 10,
    viewBox: '0 0 12 10',
    data: '<path pid="0" d="M3.863 7.583l-2.78-2.78-.946.94L3.863 9.47l8-8-.94-.94-7.06 7.053z" _fill="#3CAC47"/>'
  }
})
