/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'face': {
    width: 16,
    height: 16,
    viewBox: '0 0 12.39 12.39',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#5454c6" stroke-linejoin="round"><circle pid="0" cx="6.19" cy="6.19" r="5.69" transform="rotate(-76.12 6.196 6.19)"/><circle pid="1" cx="4.44" cy="4.71" r=".92"/><circle pid="2" cx="7.95" cy="4.71" r=".92"/><path pid="3" d="M4 7.52a2.22 2.22 0 0 0 4.44 0z"/></g></g>'
  }
})
