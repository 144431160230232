import Vue from 'vue';
import Router from 'vue-router';
import i18n from './plugins/i18n';

import Home from './views/Home.vue';
import Login from './views/auth/Login.vue';
import Registration from './views/auth/Registration/Registration.vue';
import PassReset from './views/auth/PassReset.vue';
import EmailConfirm from './views/auth/EmailConfirm.vue';
import EmailResend from './views/auth/EmailResend.vue';
import ProfileMain from './views/auth/Profile/ProfileMain.vue';
import ProfileInfo from './views/auth/Profile/Pages/ProfileInfo.vue';
import ProfileLicence from './views/auth/Profile/Pages/ProfileLicence.vue';
import ProfileServices from './views/auth/Profile/Pages/ProfileServices.vue';
import ProfileHistory from './views/auth/Profile/Pages/ProfileHistory.vue';
import ProfileSettings from './views/auth/Profile/Pages/ProfileSettings.vue';
import OrganizationInfo from './views/auth/Profile/Organization/OrganizationInfo.vue';
import OrganizationHistory from './views/auth/Profile/Organization/OrganizationHistory.vue';
// import OrganizationServices from './views/auth/Profile/Organization/OrganizationServices.vue';
import OrganizationUsers from './views/auth/Profile/Organization/OrganizationUsers.vue';
import OrganizationLicence from './views/auth/Profile/Organization/OrganizationLicence.vue';
import OrderInner from './views/auth/Profile/Orders/OrderInner.vue';
import PurchaseLicence from './views/auth/Profile/Licences/PurchaseLicence.vue';
import StepLicences from './views/auth/Profile/Licences/StepLicences.vue';
import StepCheckout from './views/auth/Profile/Licences/StepCheckout.vue';
import CheckoutCallback from './views/auth/Profile/Licences/CheckoutCallback.vue';
import PurchaseService from './views/auth/Profile/Services/PurchaseService.vue';
import ServiceCallback from './views/auth/Profile/Services/ServiceCallback.vue';
import Terms from './views/auth/Terms.vue';
import MyGroups from './views/groups/MyGroups.vue';
import Group from './views/groups/Group.vue';
import GroupMember from './views/groups/GroupMember.vue';
import GroupMembers from './views/groups/GroupMembers.vue';
import GroupMemberReflection from './views/groups/GroupMemberReflection.vue';
import GroupMemberStats from './views/groups/GroupMemberStats.vue';
import GroupReflection from './views/groups/GroupReflection.vue';
import GroupReflectionStats from './views/groups/GroupReflectionStats.vue';
import NewGroup from './views/groups/NewGroup.vue';
import GroupConfirm from './views/groups/GroupConfirm.vue';
import GroupToken from './views/groups/GroupToken.vue';
import NewReflection from './views/reflections/NewReflection.vue';
import CreateReflection from './views/reflections/CreateReflection.vue';
import ReflectionOptions from './views/reflections/ReflectionOptions.vue';
import CreateReflectionExample from './views/reflections/CreateReflectionExample.vue';
import EditReflection from './views/reflections/EditReflection.vue';
import EditTemplate from './views/templates/EditTemplate.vue';
import Templates from './views/templates/Templates.vue';
import TemplatePersonal from './views/templates/TemplatePersonal.vue';
import TemplatesDraft from './views/templates/TemplatesDraft.vue';
import TemplateOptions from './views/templates/TemplateOptions.vue';
import Methods from './views/Methods.vue';
import AboutUs from './views/AboutUs.vue';
import FAQ from './views/FAQ.vue';
import Lessons from './views/Lessons.vue';
import NotFound from './views/NotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_home')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_login')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: false, redirect: 'Home' },
          { role: 'teacher', access: false, redirect: 'Home' },
        ],
      },
    },
    {
      path: '/register',
      name: 'Register',
      component: Registration,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_register')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: false, redirect: 'Home' },
          { role: 'teacher', access: false, redirect: 'Home' },
        ],
      },
    },
    {
      path: '/slaptazodzio-priminimas/',
      name: 'PassReset',
      component: PassReset,
      alias: '/mokinio-slaptazodzio-priminimas/',
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_pass_reset')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: false, redirect: 'Home' },
          { role: 'teacher', access: false, redirect: 'Home' },
        ],
      },
    },
    {
      path: '/email-confirmation/',
      name: 'EmailConfirm',
      component: EmailConfirm,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_email_confirmation')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: true, redirect: 'Home' },
          { role: 'teacher', access: true, redirect: 'Home' },
        ],
      },
    },
    {
      path: '/resend-email-confirmation/:email',
      name: 'EmailResend',
      component: EmailResend,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_email_resend'), null],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: true, redirect: 'Home' },
          { role: 'teacher', access: true, redirect: 'Home' },
        ],
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileMain,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_profile')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
      children: [
        {
          path: 'view',
          name: 'ProfileInfo',
          component: ProfileInfo,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'org/:orgId/view',
          name: 'OrganizationInfo',
          component: OrganizationInfo,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'org/:orgId/users',
          name: 'OrganizationUsers',
          component: OrganizationUsers,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'licence',
          name: 'ProfileLicence',
          component: ProfileLicence,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'org/:orgId/licence',
          name: 'OrganizationLicence',
          component: OrganizationLicence,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'services',
          name: 'ProfileServices',
          component: ProfileServices,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'org/:orgId/services',
          name: 'OrganizationServices',
          component: ProfileServices,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'history',
          name: 'ProfileHistory',
          component: ProfileHistory,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'org/:orgId/history',
          name: 'OrganizationHistory',
          component: OrganizationHistory,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'settings',
          name: 'ProfileSettings',
          component: ProfileSettings,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'order/:orderId',
          name: 'OrderInner',
          component: OrderInner,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
      ],
    },
    {
      path: '/purchase-licence',
      name: 'PurchaseLicence',
      component: PurchaseLicence,
      meta: {
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
      children: [
        {
          path: 'licence',
          name: 'StepLicences',
          component: StepLicences,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'checkout',
          name: 'StepCheckout',
          component: StepCheckout,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'accept',
          name: 'CheckoutCallback',
          component: CheckoutCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'cancel',
          name: 'CheckoutCallback',
          component: CheckoutCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'callback',
          name: 'CheckoutCallback',
          component: CheckoutCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
      ],
    },
    {
      path: '/purchase-services',
      name: 'PurchaseService',
      component: PurchaseService,
      meta: {
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
      children: [
        {
          path: 'accept',
          name: 'ServiceCallback',
          component: ServiceCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'cancel',
          name: 'ServiceCallback',
          component: ServiceCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
        {
          path: 'callback',
          name: 'ServiceCallback',
          component: ServiceCallback,
          meta: {
            permissions: [
              { role: 'guest', access: false, redirect: 'Login' },
              { role: 'student', access: true },
              { role: 'teacher', access: true },
            ],
          },
        },
      ],
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_terms')],
        permissions: [
          { role: 'guest', access: true },
          { role: 'student', access: true },
        ],
      },
    },
    {
      path: '/my-groups',
      name: 'MyGroups',
      component: MyGroups,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_my_groups')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId',
      name: 'Group',
      component: Group,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_my_groups'), null],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/members',
      name: 'GroupMembers',
      component: GroupMembers,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          i18n.t('breadcrumbs.crumb_members'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/members/:memberId',
      name: 'GroupMember',
      component: GroupMember,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          i18n.t('breadcrumbs.crumb_members'),
          null,
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/members/:memberId/:questionnaireId',
      name: 'GroupMemberReflection',
      component: GroupMemberReflection,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          i18n.t('breadcrumbs.crumb_members'),
          null,
          null,
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/members/:memberId/:questionnaireId?/stats/:reflectionId?',
      name: 'GroupMemberStats',
      component: GroupMemberStats,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          i18n.t('breadcrumbs.crumb_members'),
          null,
          null,
          i18n.t('breadcrumbs.crumb_stats'),
          null,
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/:questionnaireId',
      name: 'GroupReflection',
      component: GroupReflection,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_my_groups'), null, null],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/:questionnaireId/stats/:reflectionId?',
      name: 'GroupReflectionStats',
      component: GroupReflectionStats,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          null,
          i18n.t('breadcrumbs.crumb_stats'),
          null,
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/new-group',
      name: 'NewGroup',
      component: NewGroup,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_new_group')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/new-group/confirm',
      name: 'GroupConfirm',
      component: GroupConfirm,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_new_group'),
          i18n.t('breadcrumbs.crumb_confirm'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/my-groups/:groupId/token',
      name: 'GroupToken',
      component: GroupToken,
      props: { default: true, GroupToken: true },
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_my_groups'),
          null,
          i18n.t('breadcrumbs.crumb_group_token'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/reflections/new',
      name: 'NewReflection',
      component: NewReflection,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_reflections'),
          i18n.t('breadcrumbs.crumb_new_reflection'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/reflections/options/:id?',
      name: 'ReflectionOptions',
      component: ReflectionOptions,
      props: { default: true, ReflectionOptions: true },
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_reflections'),
          i18n.t('breadcrumbs.crumb_options'),
          null,
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/reflections/example',
      name: 'CreateReflectionExample',
      component: CreateReflectionExample,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_reflections'),
          i18n.t('breadcrumbs.crumb_example'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },

    {
      path: '/reflections',
      name: 'CreateReflection',
      component: CreateReflection,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_reflections')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/reflections/:id',
      name: 'EditReflection',
      component: EditReflection,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_reflections'), null],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/templates',
      name: 'Templates',
      component: Templates,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_templates')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/templates/personal',
      name: 'TemplatePersonal',
      component: TemplatePersonal,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_templates'),
          i18n.t('breadcrumbs.crumb_templates_personal'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/templates/draft',
      name: 'TemplatesDraft',
      component: TemplatesDraft,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_templates'),
          i18n.t('breadcrumbs.crumb_templates_draft'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/templates/:id',
      name: 'EditTemplate',
      component: EditTemplate,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_templates'), null],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/templates/:id/options',
      name: 'TemplateOptions',
      component: TemplateOptions,
      meta: {
        breadCrumb: [
          '',
          i18n.t('breadcrumbs.crumb_templates'),
          null,
          i18n.t('breadcrumbs.crumb_options'),
        ],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/methods',
      name: 'Methods',
      component: Methods,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_methods')],
        permissions: [
          { role: 'guest', access: false, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/faq',
      name: 'Faq',
      component: FAQ,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_faq')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutUs,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_about_us')],
        permissions: [
          { role: 'guest', access: true, redirect: 'Login' },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/faq/lessons',
      name: 'Lessons',
      component: Lessons,
      meta: {
        breadCrumb: ['', i18n.t('breadcrumbs.crumb_faq'), i18n.t('breadcrumbs.crumb_lessons')],
        permissions: [
          { role: 'guest', access: true },
          { role: 'student', access: true },
          { role: 'teacher', access: true },
        ],
      },
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound,
    },
  ],
});
