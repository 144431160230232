import Vue from 'vue';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import i18n from '../../plugins/i18n';
import { handleCommitResponse, handleResponseError } from './helpers/responseHelpers';

const getDefaultState = () => ({
  user: {
    acceptNewsletter: null,
    city: null,
    dateOfBirth: null,
    email: null,
    lastName: null,
    name: null,
    organization: null,
    phone: null,
    role: null,
    token: null,
  },
  userRegisterForm: {
    name: null,
    lastName: null,
    email: null,
    dateOfBirth: null,
    organization: null,
    city: null,
    password: null,
    password2: null,
    acceptNewsletter: null,
  },
  userRegisterFormMain: {
    name: null, // teacher name
    lastName: null, // teacher lastName
    email: null, // teacher email
    phone: null, // teacher phone
    password: null, // teacher password
    password2: null, // teacher password2
    standalonePassword: null, // teacher standalone password
    organization: null, // org name
    orgCode: null, // org code
    orgEmail: null, // org email
    orgPhone: null, // org phone
    acceptNewsletter: null,
    currentUserType: null,
    isTeacherData: false,
  },
  error: null,
  loginModal: false,
  registerModal: false,
  privacyAgreed: false,
  loginMode: true,
  welcome: false,
  navigationKey: 0,
  userOrganizations: null,
  organizationForm: {
    title: null,
    code: null,
    phone: null,
    email: null,
  },
  organizationUserList: [],
  isProfileSidebarOpen: true,
  currentActiveOrganization: null,
});

const state = getDefaultState();

const mutations = {
  SET_USER(state, user) {
    state.user = user;
    state.navigationKey = Math.random();
  },
  LOGOUT_USER(state) {
    state.user = {
      acceptNewsletter: null,
      city: null,
      dateOfBirth: null,
      email: null,
      lastName: null,
      name: null,
      organization: null,
      phone: null,
      role: null,
      token: null,
    };
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_MODAL(state, { type, status }) {
    if (type === 'login') state.loginModal = status;
    if (type === 'register') state.registerModal = status;
  },
  TOGGLE_PRIVACY(state, status) {
    state.privacyAgreed = status;
  },
  TOGGLE_LOGIN_MODE(state, status) {
    state.loginMode = status;
  },
  SET_WELCOME(state, status) {
    state.welcome = status;
  },
  SET_REGISTRATION_USER_TYPE(state, value) {
    state.userRegisterFormMain.currentUserType = value;
  },
  SET_IS_TEACHER_DATA(state, value) {
    state.userRegisterFormMain.isTeacherData = value;
  },
  GET_USER_ORGANIZATIONS(state, value) {
    const list = value.map((obj) => ({ ...obj, isOpen: false }));
    state.userOrganizations = list;
  },
  SET_ORGANIZATION_FORM(state, value) {
    state.organizationForm = value;
  },
  GET_ORGANIZATION_USER_LIST(state, value) {
    // filter out self from the users list
    // const userId = state.user.id;
    // const filteredUsersList =
    //   value && value.length ? value.filter((user) => user.id !== userId) : [];

    state.organizationUserList = value;
  },
  TOGGLE_SIDEBAR(state, value) {
    const { isProfile, id } = value;
    if (!isProfile) {
      const organization = state.userOrganizations.find((org) => org.id === id);
      organization.isOpen = !organization.isOpen;
    } else {
      state.isProfileSidebarOpen = !state.isProfileSidebarOpen;
    }
  },
  SET_CURRENT_ACTIVE_ORGANIZATION(state, value) {
    const foundOrganization = state.userOrganizations.find((org) => org.id === parseInt(value, 10));
    if (foundOrganization) state.currentActiveOrganization = foundOrganization;
  },
  RESET_CURRENT_ACTIVE_ORGANIZATION(state) {
    state.currentActiveOrganization = null;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  login({ commit, state, dispatch }, payload) {
    const currentLocale = localStorage.getItem('locale');
    const isFromRegistration = payload.isFromRegistration;
    const organizationForm = payload.organizationObject;

    const formData = {
      password: payload.password,
      email: payload.email,
      role: 'teacher',
    };
    commit('SET_ERROR', null);
    return axios
      .post('/client/client/login', formData)
      .then((response) => {
        const { token } = response.data.data;
        const decodedToken = jwt.decode(token);
        const { uid } = decodedToken.data;
        commit('SET_USER', {
          ...response.data.data,
          id: parseInt(uid, 10),
        });
        Vue.prototype.$user.set({ role: response.data.data.role });

        localStorage.setItem(
          'user',
          JSON.stringify({ ...response.data.data, id: parseInt(uid, 10) })
        );
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('locale', currentLocale || 'lt');

        axios.defaults.headers.common['Digi-Token'] = response.data.data.token;

        commit('SET_ERROR', null);
        if (state.loginModal) {
          dispatch('toggleModal', { type: 'login', status: false });
        }

        if (isFromRegistration) {
          dispatch('registerOrganization', organizationForm);
        }

        return Promise.resolve({ success: true });
      })
      .catch((error) => {
        const { message, data } = error.response.data;
        if (data) {
          const { email } = data;

          Object.entries(data).forEach(([value]) => {
            commit('SET_ERROR', value);
          });

          commit('SET_ERROR', email || message);
        } else {
          commit('SET_ERROR', message);
        }

        if (data) return Promise.resolve({ success: false, data });
        return Promise.reject(error);
      });
  },
  silentLogin({ commit }) {
    const checkUser = localStorage.getItem('user');
    if (!checkUser) return Promise.resolve({ role: 'guest' });
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      commit('SET_USER', user);
      return Promise.resolve({ role: user.role });
    }
    return Promise.resolve({ role: 'guest' });
  },
  refreshUser({ commit }, data) {
    const currentLocale = localStorage.getItem('locale');
    const { token } = data;
    const decodedToken = jwt.decode(token);
    const { uid } = decodedToken.data;
    commit('SET_USER', {
      ...data,
      id: parseInt(uid, 10),
    });
    Vue.prototype.$user.set({ role: data.role });

    localStorage.setItem('user', JSON.stringify({ ...data, id: parseInt(uid, 10) }));
    localStorage.setItem('token', data.token);
    localStorage.setItem('locale', currentLocale || 'lt');
    axios.defaults.headers.common['Digi-Token'] = data.token;
  },
  logout({ commit, dispatch }) {
    commit('LOGOUT_USER');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.clear();
    Vue.prototype.$user.set({ role: 'guest' });
    dispatch('groups/resetGroupsState', null, { root: true });
    dispatch('members/resetMembersState', null, { root: true });
    dispatch('methods/resetMethodsState', null, { root: true });
    dispatch('questionnaire/resetQuestionnaireState', null, { root: true });
    dispatch('reflections/resetReflectionsState', null, { root: true });
    dispatch('licences/resetLicencesState', null, { root: true });
    dispatch('services/resetServicesState', null, { root: true });
    dispatch('resetUserState');
  },
  register({ commit, dispatch }, payload) {
    const formData = payload;
    return axios
      .post('/client/client/registration', formData)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          commit('SET_WELCOME', true);
          commit('SET_ERROR', null);

          dispatch('refreshUser', data);
          dispatch('toggleModal', { type: 'register', status: true });

          return Promise.resolve({ success: true, user: data });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.user_register_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        commit('SET_ERROR', data.email);
        if (data) return Promise.resolve({ success: false, data });
        return Promise.reject(error);
      });
  },
  registerOrganization({ commit }, payload) {
    const formData = payload;
    return axios
      .post('/client/organization/create', formData)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          commit('SET_WELCOME', true);
          commit('SET_ERROR', null);

          return Promise.resolve({ success: true, user: data });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.user_register_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        if (data) return Promise.resolve({ success: false, data });
        return Promise.reject(error);
      });
  },
  updateUser({ getters, dispatch }, payload) {
    return axios
      .put('/client/client/update', payload)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          const { token } = getters.user;
          const user = data;
          data.token = token;
          dispatch('refreshUser', user);
          return Promise.resolve({ success });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.cant_update_user_message'),
        });
      })
      .catch((error) => {
        const { success, message } = error.response.data;
        return Promise.resolve({ success, msg: message });
      });
  },
  updatePassword(context, payload) {
    const { password, newPassword } = payload;
    return axios
      .put('/client/client/updatePassword', { password, newPassword })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.cant_update_password_message'),
        });
      })
      .catch((error) => {
        const { success, message } = error.response.data;
        return Promise.resolve({ success, msg: message });
      });
  },
  resetPassword({ commit }, payload) {
    const email = payload;
    return axios
      .post('/client/client/passwordResetRequest', email)
      .then((res) => {
        const { success } = res.data;
        if (!success)
          return Promise.resolve({ message: i18n.t('warnings.something_went_wrong_message') });
        return Promise.resolve({
          message: i18n.t('warnings.new_password_email_check_message'),
        });
      })
      .catch((error) => {
        const { message } = error.response.data;
        const { email } = error.response.data;
        if (email) {
          commit('SET_ERROR', email);
        } else {
          commit('SET_ERROR', message);
        }
        setTimeout(() => {
          commit('SET_ERROR', null);
        }, 3000);
        return Promise.reject(error);
      });
  },
  resetPasswordConfirmation({ commit }, payload) {
    return axios
      .post('/client/client/passwordReset', { password: payload.password, token: payload.token })
      .then((res) => {
        const { success } = res.data;
        // const { token } = res.data.data;
        if (!success)
          return Promise.resolve({ msg: i18n.t('warnings.something_went_wrong_message'), success });
        // const decodedToken = jwt.decode(token);
        // const { uid } = decodedToken.data;

        // commit('SET_USER', {
        //   ...res.data.data,
        //   id: parseInt(uid, 10),
        // });
        // Vue.prototype.$user.set({ role });

        // localStorage.setItem('user', JSON.stringify({ ...res.data.data, id: parseInt(uid, 10) }));
        // localStorage.setItem('token', token);
        // axios.defaults.headers.common['Digi-Token'] = token;

        return Promise.resolve({
          msg: i18n.t('modals.pass_reset_modal_pass_changed_message'),
          success: true,
        });
      })
      .catch((error) => {
        const { message } = error.response.data;
        commit('SET_ERROR', message);
        setTimeout(() => {
          commit('SET_ERROR', null);
        }, 3000);
        return Promise.reject(error);
      });
  },
  confirmEmail(context, payload) {
    return axios
      .post('/client/client/emailConfirm', { token: payload })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success, data: res.data });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.email_confirm_failed_message'),
        });
      })
      .catch((error) => {
        const { response } = error;
        return Promise.resolve({ success: false, data: response });
      });
  },
  resendEmail(context, payload) {
    return axios
      .post('/client/client/resendEmailConfirmation', { email: payload })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success, data: res.data });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.email_resend_failed_message'),
        });
      })
      .catch((error) => {
        const { response } = error;
        return Promise.resolve({ success: false, data: response });
      });
  },
  sendInquiry(context, payload) {
    return axios
      .post('/client/inquiry/ask', payload)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success, data: res.data });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.inquiry_send_fail_message'),
        });
      })
      .catch((error) => {
        const { response } = error;
        return Promise.resolve({ success: false, data: response });
      });
  },
  getUserOrganizations({ commit }) {
    return axios.get('/client/organization/list').then((res) => {
      const { success, data } = res.data;
      if (success) {
        commit('GET_USER_ORGANIZATIONS', data);
        return Promise.resolve({ success: true, data: res.data });
      }
      return Promise.resolve({ success: false, lessons: [] });
    });
  },
  updateOrganization(context, payload) {
    const { form, id } = payload;

    return axios
      .put(`/client/organization/update/${id}`, form)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.cant_update_org_message'),
        });
      })
      .catch((error) => {
        const { success, message } = error.response.data;
        return Promise.resolve({ success, msg: message });
      });
  },
  getOrganizationUserList({ commit }, id) {
    return axios
      .get(`client/organization/listMembers/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_ORGANIZATION_USER_LIST'))
      .catch(handleResponseError);
  },
  addUsersToOrganization(context, payload) {
    return axios
      .post('/client/organization/addUsers', payload)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success: true });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.add_users_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        if (data) return Promise.resolve({ success: false });
        return Promise.reject(error);
      });
  },
  editOrganizationMember(context, payload) {
    return axios
      .post('/client/organization/addUsers', payload)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success: true });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.member_updated_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        if (data) return Promise.resolve({ success: false });
        return Promise.reject(error);
      });
  },
  removeUserFromOrganization(context, payload) {
    const { organizationId, clientId } = payload;

    return axios
      .delete(`/client/organization/removeUser/${organizationId}/${clientId}`)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success: true });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.remove_user_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        if (data) return Promise.resolve({ success: false });
        return Promise.reject(error);
      });
  },
  setCurrentActiveOrganization({ commit }, payload) {
    commit('SET_CURRENT_ACTIVE_ORGANIZATION', payload);
  },
  resetCurrentActiveOrganization({ commit }) {
    commit('RESET_CURRENT_ACTIVE_ORGANIZATION');
  },
  setOrganizationForm({ commit }, payload) {
    commit('SET_ORGANIZATION_FORM', payload);
  },
  setIsTeacherData({ commit }, payload) {
    commit('SET_IS_TEACHER_DATA', payload);
  },
  setRegistrationUserType({ commit }, payload) {
    commit('SET_REGISTRATION_USER_TYPE', payload);
  },
  toggleModal({ commit }, payload) {
    commit('SET_MODAL', payload);
  },
  togglePrivacy({ commit }, payload) {
    commit('TOGGLE_PRIVACY', payload);
  },
  toggleLoginMode({ commit }, payload) {
    commit('TOGGLE_LOGIN_MODE', payload);
  },
  setWelcome({ commit }, payload) {
    commit('SET_WELCOME', payload);
  },
  toggleSidebar({ commit }, payload) {
    commit('TOGGLE_SIDEBAR', payload);
  },
  setError({ commit }, payload) {
    commit('SET_ERROR', payload);
  },
  resetUserState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  user(state) {
    return state.user;
  },
  token(state) {
    return state.user.token;
  },
  error(state) {
    return state.error;
  },
  loginModal(state) {
    return state.loginModal;
  },
  registerModal(state) {
    return state.registerModal;
  },
  privacyAgreed(state) {
    return state.privacyAgreed;
  },
  loginMode(state) {
    return state.loginMode;
  },
  userRegisterForm(state) {
    return state.userRegisterForm;
  },
  userRegisterFormMain(state) {
    return state.userRegisterFormMain;
  },
  welcome(state) {
    return state.welcome;
  },
  navigationKey(state) {
    return state.navigationKey;
  },
  isTeacherData(state) {
    return state.userRegisterFormMain.isTeacherData;
  },
  userOrganizations(state) {
    return state.userOrganizations;
  },
  organizationForm(state) {
    return state.organizationForm;
  },
  organizationUserList(state) {
    return state.organizationUserList;
  },
  isProfileSidebarOpen(state) {
    return state.isProfileSidebarOpen;
  },
  currentActiveOrganization(state) {
    return state.currentActiveOrganization;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
