/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'certified': {
    width: 35.457,
    height: 33.91,
    viewBox: '0 0 35.457 33.91',
    data: '<g data-name="Group 1915"><g data-name="Group 1133"><path pid="0" data-name="Path 909" d="M34.856 16.955c0 1.727-2.458 3.044-2.964 4.6-.524 1.615.672 4.122-.306 5.467s-3.749.99-5.107 1.978-1.842 3.723-3.457 4.247c-1.559.506-3.567-1.405-5.294-1.405s-3.736 1.911-5.294 1.405c-1.615-.524-2.113-3.269-3.457-4.247s-4.118-.62-5.106-1.978.218-3.852-.306-5.466c-.506-1.559-2.964-2.876-2.964-4.6s2.458-3.044 2.964-4.6c.524-1.615-.672-4.122.307-5.467s3.748-.99 5.106-1.978S10.82 1.188 12.435.664c1.558-.506 3.567 1.405 5.294 1.405S21.465.158 23.023.664c1.615.524 2.113 3.269 3.457 4.247s4.118.62 5.107 1.978-.218 3.851.306 5.466c.506 1.555 2.963 2.873 2.963 4.6z" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="1.2"/></g><path pid="1" data-name="Path 910" d="M25.673 12.307L15.519 22.462l-4.8-4.8" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g>'
  }
})
