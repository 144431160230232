import i18n from '../../../plugins/i18n'

export const handleReflectionsResponse = (res) => {
  const { success, data } = res.data;
  if (success) {
    return Promise.resolve({ success, data });
  }
  return Promise.resolve({ success: false });
};

export const handleReflectionsResponseError = ({ response }) => Promise.resolve({ success: false, data: response });

export const handleEmptyQuestionProperties = (questions) => {
  questions.forEach((question) => {
    if (question.parts && question.parts.length) {
      question.parts.forEach((part, i) => {
        if (!part || !part.title) {
          question.parts.splice(i, 1);
        }
      });
    }
  });
};

export const reflectionDays = [
  {
    day: i18n.t("date_and_time.day_monday_cap"),
    weekend: false,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_tuesday_cap"),
    weekend: false,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_wednesday_cap"),
    weekend: false,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_thursday_cap"),
    weekend: false,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_friday_cap"),
    weekend: false,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_saturday_cap"),
    weekend: true,
    reflections: [],
  },
  {
    day: i18n.t("date_and_time.day_sunday_cap"),
    weekend: true,
    reflections: [],
  },
];
