/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/progress_matrix': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 3830"><g data-name="Group 1439" transform="translate(-701.857 -176.261)"><circle pid="0" data-name="Ellipse 277" cx="16.559" cy="16.559" r="16.559" transform="rotate(-.5 20581.825 -80337.988)" _fill="#6666ca"/><path pid="1" data-name="Path 977" d="M721.28 182.21a11.038 11.038 0 1 0-5.3 21.43 11.5 11.5 0 0 0 2.65.32 11.037 11.037 0 0 0 2.65-21.75zm-.05 17.77a1.27 1.27 0 1 1-2.54 0v-10.34l-2.61 2.61a1.312 1.312 0 0 1-1.79.01 1.267 1.267 0 0 1 0-1.8l4.67-4.67a1.293 1.293 0 0 1 1.79 0l.06.06a1.278 1.278 0 0 1 .42.96z" _fill="#fff"/></g></g>'
  }
})
