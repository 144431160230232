<template>
  <multiselect
    :id="id"
    :key="id"
    v-model="multipleValue"
    :options="options"
    :maxHeight="200"
    :searchable="false"
    :showLabels="false"
    :placeholder="placeholder"
    class="multiple-type-select"
    :style="{ width: `${width}px` || 'inherit' }"
    :disabled="disabled"
    track-by="title"
    label="title"
    :multiple="true"
    :close-on-select="false"
  >
    <template v-slot:caret>
      <svgicon
        class="arrow-icon"
        name="green-arrow"
        width="12px"
        height="12px"
        color="#BABABA"
        :stroke="true"
        :fill="false"
      ></svgicon>
    </template>
    <template slot="option" slot-scope="props">
      <span class="option__checkbox">
        <checkbox :checked="false"></checkbox>
      </span>
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import Checkbox from '@/components/elements/Checkbox.vue';

  export default {
    name: 'MultiSelect',
    components: {
      Multiselect,
      Checkbox,
    },
    props: ['options', 'placeholder', 'width', 'id', 'selected', 'disabled'],
    data() {
      return {
        value: this.selected || null,
        multipleValue: [],
      };
    },
    created() {
      if (this.selected) {
        this.value = this.selected;
      }
    },
    watch: {
      value(newVal) {
        this.value = newVal;
      },
      multipleValue(newVal) {
        const isAllSelected = newVal && newVal.length ? newVal.find((value) => value.isAll) : false;

        // if isAll is selected set all options to selected except isAll
        if (isAllSelected) {
          this.multipleValue = this.allOptions;
        }
        this.$emit('selectedValue', { value: newVal, id: this.id });
      },
    },
    computed: {
      typeOfProperty() {
        return typeof this.options[0];
      },
      allOptions() {
        return this.options ? this.options.filter((option) => !option.isAll) : [];
      },
    },
    methods: {
      // selectedValue(value) {
      //   if (this.typeOfProperty !== 'object') {
      //     this.$emit('selectedValue', { value, id: this.id });
      //   } else {
      //     this.$emit('selectedValue', value);
      //   }
      // },
    },
  };
</script>

<style lang="scss" scoped></style>
