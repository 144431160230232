/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-lol': {
    width: 16,
    height: 16,
    viewBox: '0 0 31.39 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="15.7" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M22.68 2A13.36 13.36 0 0 1 4.44 20.53 13.35 13.35 0 1 0 22.68 2z" _fill="#ffc448"/><path pid="2" d="M4.16 14.76A12.4 12.4 0 0 1 26.38 7.2 12.4 12.4 0 1 0 5.8 20.94a12.34 12.34 0 0 1-1.64-6.18z" _fill="#ffd77a"/><path pid="3" d="M8.49 13.57a.57.57 0 0 1-.35-1l1.93-1.5-2-1.17a.57.57 0 0 1 .57-1l2.71 1.59a.57.57 0 0 1 .06.93l-2.58 2a.56.56 0 0 1-.34.15zM22.9 13.57a.56.56 0 0 1-.35-.12l-2.58-2a.57.57 0 0 1 .03-.95l2.71-1.59a.57.57 0 1 1 .57 1l-2 1.17 1.93 1.5a.57.57 0 0 1-.35 1z" _fill="#2c2c2c"/><path pid="4" d="M24.13 13c-1 5.93.75 7.9.75 7.9a3.52 3.52 0 0 0 6-3.7s-1-2.46-6.75-4.2z" _fill="#47c4e9"/><path pid="5" d="M26.89 22.4a3.52 3.52 0 0 0 4-5.22 5.82 5.82 0 0 0-2.27-2.23c1.77 4.24-.23 6.45-1.73 7.45z" _fill="#00bbe9"/><path pid="6" d="M7.11 12.88C1.41 14.79.48 17.25.48 17.25a3.52 3.52 0 1 0 6.07 3.55s1.69-2.02.56-7.92z" _fill="#47c4e9"/><path pid="7" d="M.07 19.73a3.52 3.52 0 0 0 6.48 1.07 5.82 5.82 0 0 0 .9-3.05c-2.9 3.58-5.79 2.85-7.38 1.98z" _fill="#00bbe9"/><path pid="8" d="M8 15.12a7.7 7.7 0 0 0 15.3 0z" _fill="#71523f"/><path pid="9" d="M10.41 19.85A7.69 7.69 0 0 0 20.8 20l-.07-.17a12.7 12.7 0 0 0-10.32.02z" _fill="#ff6669"/></g></g>'
  }
})
