<template>
  <div class="group__header">
    <div class="spacer"></div>
    <div class="group__header-title" v-if="currentGroup">
      <switch-nav
        class="group__header__nav"
        :key="'groupNav'"
        :links="[
          {
            link: { name: 'Group', params: { groupId: currentGroup.id } },
            description: navigationTitles[0],
          },
          {
            link: { name: 'GroupMembers', params: { groupId: currentGroup.id } },
            description: navigationTitles[1],
          },
        ]"
      ></switch-nav>
    </div>
    <div class="group__header-actions">
      <div class="orange-button">
        <router-link :to="{ name: 'CreateReflection' }">
          <button-4>{{ $t("actions.new_questionnaire_action") }}</button-4>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import SwitchNav from '@/components/Menu/SwitchNav.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'GroupHeader',
  components: {
    SwitchNav,
    Button4,
  },
  props: ['currentGroup'],
  data() {
    return {
      isModalVisible: false,
      editTitle: false,
      editMemberTitle: false,
      navigationTitles: [this.$t("group.group_questionnaires"), this.$t("group.group_members")]
    };
  },
  computed: {
    tokenValid() {
      const { tokenValidationDate } = this.currentGroup;
      const today = this.moment();
      const expiryDate = this.moment(tokenValidationDate);
      return expiryDate.isAfter(today);
    },
    token(){
      return this.tokenValid ? this.currentGroup.token : this.$t("group.group_inactive");
    }
  },
  methods: {
    ...mapActions('groups', ['updateGroup', 'removeGroup', 'getGroupList']),
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    handleGroupDelete() {
      this.removeGroup(this.currentGroup.id).then(({ success }) => {
        if (success) {
          this.toggleModal();
          this.$router.push({
            name: 'MyGroups',
          });
        }
      });
    }
  },
};
</script>
<style lang="scss">
.group {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-align: center;
    width: 100%;
    margin-top: 30px;

    &__nav {
      max-width: fit-content;
      margin: 0;
    }

    .spacer{
      width: 168px;
    }

    &-title {
      display: flex;
      flex-direction: column;
      min-width: 285px;

      &__field {
        display: flex;
        align-items: center;
        justify-content: center;

        &__input {
          margin: 0;
          padding: 0;
          color: #0f9f8f;
          font-family: Nunito-SemiBold;
          font-size: 18px;
          letter-spacing: 0.8px;
          border: none;
          background: none;
          resize: none;

          &--small {
            font-size: 16px;
          }
        }

        &__icon {
          height: 14px;
          width: 14px;

          &:hover {
            cursor: pointer;
          }

          &--small {
            height: 12px;
            width: 12px;
          }
        }
      }

      nav {
        margin-top: 20px;
      }
      a.router-link-active {
        button {
          background: $green-3;
          cursor: pointer;
          color: #fff;
        }
      }
      span {
        margin-top: 8px;
        color: $green-4;
        font-family: Nunito-SemiBold;
        font-size: 16px;
      }
      button {
        margin-top: 20px;
      }
    }

    &-delete {
      position: absolute;
      top: 54px;
      left: 20px;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      margin-top: 5px;
      span {
        margin-top: 2px;
        margin-left: 5px;
        font-family: Nunito-Bold;
        font-size: 10px;
        text-transform: uppercase;
        color: $grey-3;
      }
      svg {
        width: 10px;
        height: 14px;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.25);
      }
    }

    &-actions {
      display: flex;
      flex-direction: column;
      .orange-button {
        button {
          font-size: 14px;
          background-color: $orange-2;
        }
      }

      button:last-of-type {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  &__code {
    font-family: Nunito-Bold;
    text-transform: uppercase;
    color: $grey-3;

    &-confirm {
      align-self: baseline;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button {
        display: flex;
        align-items: center;
        svg {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
