<template>
  <div class="profile-inner">
    <div class="header-wrapper flex-global">
      <div class="header-wrapper__info flex-global">
        <p class="title">{{ $t('profile.licences') }}</p>
        <!-- <p class="info">
          {{ $t('profile.unassigned_licences_message_1') }}
          <span>14</span>
          {{ $t('profile.unassigned_licences_message_2') }}
        </p> -->
      </div>
      <div class="header-wrapper__action">
        <button-4 class="new-users-button" @click.native="handleAddNewLicences">
          <svgicon name="add-big" width="13px" height="12px"></svgicon>
          {{ $t('actions.add_licences_action') }}
        </button-4>
      </div>
    </div>
    <Table :data="tableData" :columns="tableColumns" :isRowClickable="true" @rowAction="handleRowClick" />
    <licence-modal v-if="isModal" :licence="activeLicence" @handleModalToggle="handleModalToggle"
      @handleAction="handleAssignUser" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/tables/Table.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import LicenceModal from '@/components/user/licences/LicenceModal.vue';

export default {
  name: 'OrganizationLicence',
  components: {
    Table,
    Button4,
    LicenceModal,
  },
  data() {
    return {
      isModal: false,
      activeLicenceId: null,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('licences', ['orgLicences']),
    orgId() {
      return this.$route.params.orgId && parseInt(this.$route.params.orgId, 10);
    },
    tableData() {
      return this.orgLicences && this.orgLicences.length
        ? this.orgLicences.map((licence) => {
          return {
            id: licence.relationId,
            number: licence.relationId,
            title: `<span>${licence.type} <span class="small">(${licence.duration})</span></span>`,
            validUntil: licence.validUntil,
            assignedEmail: licence.assignedTo || '-',
          };
        })
        : [];
    },
    activeLicence() {
      return (
        this.orgLicences.find((licence) => licence.relationId === this.activeLicenceId) || null
      );
    },
    tableColumns() {
      return [
        {
          label: this.$t('orders.number'),
          key: 'number',
        },
        {
          label: this.$t('profile.licence'),
          key: 'title',
          rawHTML: true,
        },
        {
          label: this.$t('licences.licence_valid_until'),
          key: 'validUntil',
        },
        {
          label: this.$t('licences.assigned_licence_alt'),
          key: 'assignedEmail',
        },
      ];
    },
  },
  methods: {
    ...mapActions('licences', [
      'resetLicencesForm',
      'getOrganizationLicences',
      'assignLicenceToUser',
    ]),
    handleAddNewLicences() {
      this.resetLicencesForm();
      this.$router.push({ name: 'StepLicences', params: { isFromOrg: true } });
    },
    handleGetOrganizationLicences() {
      this.getOrganizationLicences(this.orgId);
    },
    handlePageInit() {
      this.handleGetOrganizationLicences();
    },
    handleRowClick(value) {
      this.activeLicenceId = value;
      this.toggleModal();
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    handleModalToggle() {
      this.activeLicenceId = null;
      this.toggleModal();
    },
    handleAssignUser(users) {
      this.assignLicenceToUser({ relationId: this.activeLicenceId, clientId: users }).then(
        (res) => {
          const { success, msg } = res;
          if (success) {
            this.$toast.success(this.$t('warnings.assign_licence_success_message'));
            this.toggleModal();
            this.handleGetOrganizationLicences();
          }
          if (msg) {
            this.$toast.error(msg);
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 28px;

  &__info {
    .title {
      font-size: 16px;
      font-weight: 600;
      color: $grey-9;
    }

    .info {
      margin-left: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #686868;

      span {
        font-weight: 600;
      }
    }

    .link {
      margin-left: 37px;
      font-size: 14px;
      font-weight: 400;
      color: $blue-5;
    }
  }

  &__action {
    margin-left: 5px;

    button {
      padding: 5px 38px;
      height: 40px;

      &.import-button {
        margin-right: 18px;
      }

      &.new-users-button {
        background-color: $violet-3;

        svg {
          margin-right: 5px;
          margin-bottom: 1px;
        }

        &:hover {
          background: $violet-5;
        }
      }
    }
  }

  @media screen and (max-width: 1226px) {
    &__action {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
</style>
