/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person': {
    width: 11.617,
    height: 13.875,
    viewBox: '0 0 11.617 13.875',
    data: '<g data-name="Group 3241"><g data-name="Group 2251" transform="translate(-201.589 -472.431)" _fill="none" _stroke="#505e67" stroke-miterlimit="10" stroke-width=".822"><circle pid="0" data-name="Ellipse 8" cx="2.776" cy="2.776" r="2.776" transform="translate(204.622 472.842)"/><path pid="1" data-name="Path 21" d="M212.795 483.791a5.4 5.4 0 1 0-10.795 0 2.1 2.1 0 0 0 2.1 2.1h6.586a2.1 2.1 0 0 0 2.109-2.1z"/></g></g>'
  }
})
