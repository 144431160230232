<template>
  <div class="sidebar-section">
    <div class="sidebar-section__title flex-global" @click="handleSectionToggle">
      <p>{{ section.title }}</p>
      <svgicon
        :class="{ 'inactive-icon': !isOpen }"
        name="chevron-small"
        :original="true"
        width="11px"
        height="7px"
        :fill="false"
      ></svgicon>
    </div>
    <div class="sidebar-section__links-wrapper flex-global" v-show="isOpen">
      <router-link
        class="sidebar-section__link"
        v-for="(link, index) in section.links"
        :key="index"
        :to="link.route"
      >
        <svgicon
          :name="link.icon"
          :original="true"
          :width="link.iconWidth"
          :height="link.iconHeight"
          :fill="false"
        ></svgicon>
        {{ link.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'SidebarSection',
    props: ['section'],
    data() {
      return {};
    },
    computed: {
      ...mapGetters('user', ['isProfileSidebarOpen']),
      isOpen() {
        return this.section.isProfile ? this.isProfileSidebarOpen : this.section.isOpen;
      },
    },
    methods: {
      ...mapActions('user', ['toggleSidebar']),
      handleSectionToggle() {
        this.toggleSidebar({ isProfile: this.section.isProfile, id: this.section.id });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar-section {
    margin-bottom: 35px;
    &__title {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
      cursor: pointer;
      p {
        font-size: 16px;
        font-weight: 600;
        color: $grey-5;
      }
      svg {
        stroke: none;
        transition: transform 0.2s ease-in-out;
        &.inactive-icon {
          transform: rotate(180deg);
        }
      }
    }
    &__links-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
    &__link {
      width: 100%;
      margin-bottom: 2px;
      padding: 6px 5px;
      padding-left: 15px;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      transition: background-color 0.2s ease-in-out;
      color: $grey-5;
      svg {
        margin-right: 15px;
        stroke: none;
      }
      &.router-link-active {
        background-color: $grey-8;
      }
      &:hover {
        background-color: $grey-8;
      }
    }
  }
</style>
