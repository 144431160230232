/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-envelope-happy': {
    width: 72.003,
    height: 41.312,
    viewBox: '0 0 72.003 41.312',
    data: '<g data-name="Group 3829" transform="translate(6748 2391.153)"><g data-name="Group 2051" _fill="none" _stroke="#474eb9" stroke-linejoin="round" stroke-width="2"><g data-name="Group 2050"><path pid="0" data-name="Line 1208" d="M-6746.87-2350.928l22.448-17.385"/><path pid="1" data-name="Line 1209" d="M-6676.997-2350.928l-22.596-17.499"/><path pid="2" data-name="Rectangle 4191" d="M-6747-2390.153h70.003v39.312H-6747z"/></g><path pid="3" data-name="Path 1189" d="M-6676.996-2390.153l-28.743 27.636a8.8 8.8 0 0 1-12.439 0c-10.8-9.7-18.021-17.939-28.821-27.636"/></g><path pid="4" data-name="Path 1190" d="M-6708.11-2373.106l-9.275.99h0s1.278 4.418 5.309 3.846 3.967-4.835 3.967-4.835z" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.00104"/><circle pid="5" data-name="Ellipse 841" cx="1.948" cy="1.948" r="1.948" transform="translate(-6722.232 -2381.32)" _fill="#474eb9"/><path pid="6" data-name="Path 1191" d="M-6720.111-2385.078s-3.378.671-4.711 3.435" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.500375"/><circle pid="7" data-name="Ellipse 842" cx="1.948" cy="1.948" r="1.948" transform="translate(-6705.66 -2381.32)" _fill="#474eb9"/><path pid="8" data-name="Path 1192" d="M-6704.48-2384.8s3.434.253 5.095 2.832" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g>'
  }
})
