<template>
  <div class="answer-component__yes-no">
    <!-- <div class="stat-data">
      <div class="stat-bar">
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.yes}%` }">
          <div class="bar-percentage">{{ calcPercentage.yes }}%</div>
          <div class="bar-bar"></div>
        </div>
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.no}%` }">
          <div class="bar-percentage">{{ calcPercentage.no }}%</div>
          <div class="bar-bar"></div>
        </div>
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.null}%` }" v-if="calcPercentage.null">
          <div class="bar-percentage">{{ calcPercentage.null }}%</div>
          <div class="bar-bar"></div>
        </div>
      </div>
      <div class="stat-labels">
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Taip ({{ calcPercentage.yesCount }})</div>
        </div>
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Ne ({{ calcPercentage.noCount }})</div>
        </div>
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Nereflektavo ({{ calcPercentage.nullCount }})</div>
        </div>
      </div>
    </div>-->
    <div class="stat-chart">
      <!-- <span class="stat-chart__labelX">Data / Tema</span> -->
      <span class="stat-chart__labelY">{{ $t("yes_cap") }} (+1) / {{ $t("no_cap") }} (-1)</span>
      <line-chart
        :chartdata="chartData"
        :options="chartOptions"
        class="stat-chart__chart"
        :height="330"
        :width="490"
      ></line-chart>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/charts/LineChart.vue';

export default {
  name: 'StatsYesNo',
  components: {
    LineChart,
  },
  props: ['method', 'question'],
  computed: {
    positiveValue(){
      return this.question.find(question => question.positive);
    },
    negativeValue(){
      return this.question.find(question => !question.positive);
    },
    calcPercentage() {
      const total = this.chartDataValues.length;
      let yesCount = 0;
      let noCount = 0;
      let nullCount = 0;
      this.chartDataValues.forEach((value) => {
        if (value === 1) yesCount += 1;
        if (value === -1) noCount += 1;
        if (value === 0) nullCount += 1;
      });
      const yesPerc = (yesCount / total) * 100;
      const noPerc = (noCount / total) * 100;
      const nullPerc = (nullCount / total) * 100;

      return {
        yes: Math.floor(yesPerc),
        no: Math.floor(noPerc),
        null: Math.floor(nullPerc),
        yesCount,
        noCount,
        nullCount,
      };
    },
    dataAnswers() {
      return this.method;
    },
    chartLabels() {
      return this.dataAnswers.map((item) => item.date);
    },
    chartDataValues() {
      const calcAnswers = [];
      let indicator = 0;
      this.dataAnswers.forEach((e) => {
        if (e.value === this.negativeValue.value) {
          calcAnswers.push(indicator - 1);
          indicator -= 1;
        } else if (e.value === this.positiveValue.value) {
          calcAnswers.push(indicator + 1);
          indicator += 1;
        } else {
          calcAnswers.push(null);
        }
      });
      return calcAnswers;
    },
    maxValue() {
      const arr = this.chartDataValues.slice();
      let max = arr[0];
      arr.forEach((element) => {
        if (max < element) {
          max = element;
        }
      });
      return max;
    },
    minValue() {
      const arr = this.chartDataValues.slice();
      let min = arr[0];
      arr.forEach((element) => {
        if (min > element) {
          min = element;
        }
      });
      return min;
    },
    valuesAreEqual(){
      return this.minValue === this.maxValue
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            label: `${this.$t("yes_cap")}(+1) ${this.$t("no_cap")}(-1)`,
            data: this.chartDataValues,
            borderColor: '#ac92ed',
            backgroundColor: 'transparent',
            pointBackgroundColor: '#ac92ed',
            spanGaps: false,
            lineTension: 0,
            borderWidth: 2,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
        legend: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                offsetGridLines: true,
                drawBorder: false,
              },
              ticks: {
                autoSkip: true,
                minRotation: 45,
                // labelOffset: 20,
                // padding: -120,
              },
              // offset: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'transparent',
                zeroLineColor: '#707070',
                zeroLineWidth: 1,
              },
              ticks: {
                precision: 0,
                beginAtZero: true,
                // maxTicksLimit: 0,
                // steps: 20,
                max: this.valuesAreEqual ? 1 : this.maxValue,
                min: this.valuesAreEqual ? -1 : this.minValue,
              },
            },
          ],
        },
      };
    },
  },
};
</script>
<style lang="scss">
.answer-component {
  &__yes-no {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .stat-data {
      display: flex;
      justify-content: center;
      align-items: center;
      .stat-bar {
        display: flex;

        width: 300px;
        &__bar {
          display: flex;
          flex-direction: column;

          .bar-percentage {
            text-align: center;
            color: $green-7;
            font-family: Roboto-Medium;
            font-size: 14px;
          }

          .bar-bar {
            margin-top: 8px;
            height: 15px;
            width: 100%;

            &:first-of-type {
              background-color: $green-7;
            }

            &:nth-of-type(2) {
              background-color: $red-5;
            }

            &:nth-of-type(3) {
              background-color: $blue-4;
            }
          }

          &:first-of-type {
            .bar-bar {
              background-color: $green-7;
            }
            .bar-percentage {
              color: $green-7;
            }
          }

          &:nth-of-type(2) {
            .bar-bar {
              background-color: $red-5;
              border-left: 3px solid #fff;
            }
            .bar-percentage {
              color: $red-5;
            }
          }

          &:nth-of-type(3) {
            .bar-bar {
              background-color: $blue-4;
              border-left: 3px solid #fff;
            }
            .bar-percentage {
              color: $blue-4;
            }
          }
        }
      }
      .stat-labels {
        margin-left: 60px;
        display: flex;
        flex-direction: column;

        &__label {
          display: flex;
          align-items: center;

          .label-icon {
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }

          .label-value {
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;
            margin-left: 10px;
          }

          &:not(:first-of-type) {
            margin-top: 10px;
          }

          &:first-of-type {
            .label-icon {
              background-color: $green-7;
            }
          }

          &:nth-of-type(2) {
            .label-icon {
              background-color: $red-5;
            }
          }
          &:nth-of-type(3) {
            .label-icon {
              background-color: $blue-4;
            }
          }
        }
      }
    }
    .stat-chart {
      position: relative;
      width: 100%;

      padding: 20px;

      &__labelY,
      &__labelX {
        position: absolute;
        font-family: Roboto-Medium;
        color: $grey-5;
        font-size: 12px;
      }

      &__labelY {
        left: 0;
        top: 10px;
      }

      &__labelX {
        right: 5px;
        top: 51%;
      }

      &__chart {
        max-height: 250px;
        max-width: 685px;
        margin: 20px auto 0 0;
      }
    }
  }
}
</style>
