<template>
  <section id="member-reflection-stats" class="member-reflection-stats" v-if="!loading">
    <member-reflection-header
      v-if="questionnaire && member"
      :member="member"
      :questionnaire="questionnaire"
      :questionLoading="questionLoading"
      @handleReflectionChange="handleReflectionChange"
    ></member-reflection-header>
    <member-reflection-comment v-if="currentReflection"></member-reflection-comment>
    <div class="member-reflection-stats__container">
      <stat-display-toggle
        v-if="answerType !== 'groupMessages'"
        @statDisplayToggle="expandComponents"
        :answerType="answerType"
        :expanded="expanded"
      ></stat-display-toggle>
      <member-stat-types :questionLoading="questionLoading"></member-stat-types>
      <div class="member-reflection-stats__items" v-if="answerType === 'group'">
        <div class="member-reflection-stats__items__item" v-if="currentView === 'tabs'">
          <div class="member-reflection-stats__tabs">
            <stats-tab
              v-for="(question, index) in questions"
              :key="question.id"
              :number="index"
              @click.native="handleTabChange(index)"
            ></stats-tab>
          </div>
          <member-answer
            v-if="!questionLoading"
            :method="answers[currentTab]"
            :number="currentTab"
            :question="questions[currentTab]"
            :loading="questionLoading"
          ></member-answer>
        </div>
        <div class="member-reflection-stats__items__item" v-else>
          <member-answer
            v-for="(question, index) in questions"
            :key="question.id"
            :method="answers[index]"
            :question="question"
            :number="index"
            :loading="questionLoading"
          ></member-answer>
        </div>
      </div>
      <div class="member-reflection-stats__items" v-else-if="answerType === 'inTime'">
        <div class="member-reflection-stats__items__item" v-if="currentView === 'list'">
          <group-reflection-filter
            :currentReflectionDates="currentReflectionDates"
            @handleReflectionFilter="handleReflectionFilter"
          ></group-reflection-filter>
          <member-answer-time
            v-for="(question, index) in questions"
            :key="question.id"
            :question="question"
            :stats="statsInTime[index]"
            :rawStats="memberStatsInTime[index]"
            :number="index"
            :expand="expanded"
            :loading="statsInTimeLoading"
          ></member-answer-time>
        </div>
        <div class="member-reflection-stats__items__item" v-else>
          <div class="member-reflection-stats__tabs">
            <stats-tab
              v-for="(question, index) in questions"
              :key="question.id"
              :number="index"
              @click.native="handleTabChange(index)"
            ></stats-tab>
          </div>
          <group-reflection-filter
            :currentReflectionDates="currentReflectionDates"
            @handleReflectionFilter="handleReflectionFilter"
          ></group-reflection-filter>
          <member-answer-time
            :key="questions[currentTab].id"
            :number="currentTab"
            :question="questions[currentTab]"
            :stats="statsInTime[currentTab]"
            :rawStats="memberStatsInTime[currentTab]"
            :expand="expanded"
            :loading="statsInTimeLoading"
          ></member-answer-time>
        </div>
      </div>
      <div class="member-reflection-stats__items" v-else>
        <div class="member-reflection-stats__items__item">
          <stat-messages
            :questionnaireId="questionnaireId"
            :memberId="memberId"
            :messages="memberMessages"
            :defaultQueries="defaultQueries"
            :totalCount="totalCount"
            :activePage="activePage"
            @updateTotalCount="updateTotalCount"
            @updateActivePage="updateActivePage"
          />
        </div>
      </div>
    </div>
  </section>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import { handleGetMethods, handleGetGroup } from '@/helpers/groups.helpers';

  import MemberReflectionHeader from '@/components/groups/member/MemberReflectionHeader.vue';
  import MemberReflectionComment from '@/components/groups/member/MemberReflectionComment.vue';
  import GroupReflectionFilter from '@/components/groups/GroupReflectionFilter.vue';
  import MemberStatTypes from '@/components/groups/member/MemberStatTypes.vue';
  import MemberAnswer from '@/components/groups/member/MemberAnswer.vue';
  import MemberAnswerTime from '@/components/groups/member/MemberAnswerTime.vue';
  import StatDisplayToggle from '@/components/stats/stat-components/StatDisplayToggle.vue';
  import StatsTab from '@/components/stats/StatsTab.vue';
  import Loading from '@/components/base/Loading.vue';
  import StatMessages from '@/components/stats/stat-components/StatMessages.vue';

  export default {
    name: 'GroupMemberStats',
    components: {
      MemberReflectionHeader,
      MemberReflectionComment,
      GroupReflectionFilter,
      MemberStatTypes,
      MemberAnswer,
      MemberAnswerTime,
      StatDisplayToggle,
      StatsTab,
      Loading,
      StatMessages,
    },
    data() {
      return {
        listView: true,
        expanded: true,
        questionLoading: false,
        statsInTimeLoading: false,
        inTimeDates: {
          from: this.moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          to: this.moment()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        },
        defaultQueries: {
          limit: 10,
          offset: null,
        },
        totalCount: null,
        activePage: 1,
      };
    },
    created() {
      this.handlePageInit();
    },
    beforeDestroy() {
      this.handlePageDestroy();
    },
    computed: {
      ...mapGetters('meta', ['loading']),
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('reflections', ['currentReflection']),
      ...mapGetters('groups', ['memberStatType', 'currentGroup']),
      ...mapGetters('stats', [
        'answerType',
        'currentView',
        'currentTab',
        'memberStatsInTime',
        'memberMessages',
      ]),
      ...mapGetters('questionnaire', ['questionnaire']),
      ...mapGetters('members', [
        'answers',
        'tabbedAnswers',
        'selectedAnswer',
        'members',
        'member',
        'memberReflections',
        'memberAnswers',
      ]),
      groupNotLoaded() {
        return !this.currentGroup || this.currentGroup.id !== this.groupId;
      },
      memberNotLoaded() {
        return !this.member || this.member.id !== this.memberId;
      },
      computedCurrentReflection() {
        return this.currentReflection;
      },
      groupId() {
        const {
          params: { groupId },
        } = this.$route;
        return +groupId;
      },
      memberId() {
        const {
          params: { memberId },
        } = this.$route;
        return +memberId;
      },
      reflectionId() {
        const {
          params: { reflectionId },
        } = this.$route;
        const firstReflection = this.memberReflections[0].id;

        return +reflectionId || +firstReflection;
      },
      questionnaireId() {
        const {
          params: { questionnaireId },
        } = this.$route;
        return +questionnaireId;
      },
      questions() {
        return this.questionnaire.questions;
      },
      answerPayload() {
        return {
          questionId: this.questions[this.currentTab].id,
          reflectionId: this.currentReflection.id || this.reflectionId,
          memberId: this.memberId || null,
        };
      },
      answerInTimePayload() {
        if (!this.questions.length) return false;
        const {
          params: { questionnaireId },
        } = this.$route;
        const { id } = this.questions[this.currentTab];
        return {
          questionnaireId: +questionnaireId,
          questionId: id,
          clientId: this.memberId,
          ...this.inTimeDates,
        };
      },
      statsInTime() {
        if (!this.memberStatsInTime.length) return false;
        const filteredData = this.memberStatsInTime.map((stats) => {
          if (!stats || !stats.length) return false;
          return stats.map((stat) => {
            const {
              reflection: { date },
              data,
            } = stat;
            const answer = data.find((item) => item.count > 0);
            return {
              date,
              value: (answer ? answer.value : undefined) || 'No bueno',
              color: (answer ? answer.color : undefined) || '#48CFAE',
            };
          });
        });
        return filteredData;
      },
      currentReflectionDates() {
        const { start, end } = this.currentReflection;
        return {
          start: this.moment(start).format('YYYY-MM-DD'),
          end: this.moment(end).format('YYYY-MM-DD'),
        };
      },
      currentReflectionFrom() {
        const { start } = this.currentReflection;
        return start
          ? this.moment(start)
              .subtract(1, 'months')
              .format('YYYY-MM-DD')
          : this.moment()
              .subtract(1, 'months')
              .format('YYYY-MM-DD');
      },
      currentReflectionTo() {
        const { start } = this.currentReflection;
        return start
          ? this.moment(start).format('YYYY-MM-DD')
          : this.moment()
              .add(1, 'days')
              .format('YYYY-MM-DD');
      },
    },
    watch: {
      answerType() {
        this.handleGetAnswers();
      },
    },
    methods: {
      ...mapActions('groups', ['getGroup']),
      ...mapActions('meta', ['setLoading']),
      ...mapActions('methods', ['getQuestionList']),
      ...mapActions('questionnaire', ['getQuestionnaire']),
      ...mapActions('reflections', ['setCurrentReflection']),
      ...mapActions('stats', [
        'toggleView',
        'setTab',
        'setMemberStatsInTime',
        'setAllMemberStatsInTime',
        'resetMemberStatsInTime',
        'setMemberStatsInTimeParts',
        'getAllMessages',
        'resetAllMessages',
      ]),
      ...mapActions('members', [
        'getMembers',
        'getMember',
        'getMemberReflections',
        'getMemberAnswers',
        'getAllMemberAnswers',
        'resetMemberAnswers',
      ]),
      handleGetMethods,
      handleGetGroup,
      async handlePageInit() {
        if (!this.loading) this.setLoading(true);
        this.toggleView('list');
        try {
          // await this.handleSetCurrentReflection();
          await this.handleGetMethods();
          await this.handleGetGroup();
          await this.handleGetMembers();
          // await this.handleSetCurrentReflection();
          await this.handleSetCurrentDates();
          await this.handleGetMemberReflections();
          await this.handleGetQuestionnaire();
          await this.handleGetAnswers();
          await this.handleGetMemberMessages();
        } catch {
          this.setLoading(false);
        } finally {
          // this.setLoading(false);
        }
      },
      handlePageDestroy() {
        this.resetMemberAnswers();
        this.resetMemberStatsInTime();
        this.resetAllMessages();
      },
      updateTotalCount(count) {
        this.totalCount = +count;
      },
      updateActivePage(page) {
        this.activePage = page;
      },
      async handleGetMembers() {
        await this.getMembers(this.groupId);
        if (this.memberNotLoaded) await this.getMember(this.memberId);
        return Promise.resolve();
      },
      async handleGetMemberReflections() {
        const {
          params: { questionnaireId },
        } = this.$route;
        await this.getMemberReflections({ memberId: this.memberId, questionnaireId });
        // await this.getMemberReflections({ memberId: this.memberId, questionnaireId, from: this.currentReflectionFrom, to: this.currentReflectionTo });
        return Promise.resolve();
      },
      async handleGetMemberMessages() {
        const {
          params: { questionnaireId },
        } = this.$route;
        const { success, totalCount } = await this.getAllMessages({
          questionnaireId,
          clientId: this.memberId,
          ...this.defaultQueries,
        });
        this.updateTotalCount(totalCount);
        return Promise.resolve(success);
      },
      async handleGetQuestionnaire() {
        const { start } = this.currentReflection;
        const {
          params: { questionnaireId },
        } = this.$route;
        const { success } = await this.getQuestionnaire({ questionnaireId, reflectionDate: start });
        return Promise.resolve(success);
      },
      async handleSetCurrentReflection() {
        if (!this.memberReflections.length) return Promise.resolve();

        const currentReflection = this.memberReflections.find(
          (reflection) => reflection.id === this.reflectionId
        );
        await this.setCurrentReflection(currentReflection);
        return Promise.resolve();
      },
      async handleGetAnswers() {
        this.setQuestionLoading(true);

        if (this.answerType === 'group') await this.handleGetAllMemberAnswers();
        else {
          this.setStatsInTimeLoading(true);
          this.resetMemberStatsInTime();
          await this.handleGetAllStatsInTime();
          this.setStatsInTimeLoading(false);
        }

        this.setQuestionLoading(false);
        if (this.loading) this.setLoading(false);
        return Promise.resolve();
      },
      async handleGetAllMemberAnswers() {
        if (this.memberAnswers.length) return Promise.resolve();
        for (const question of this.questions) {
          const payload = {
            ...this.answerPayload,
            questionId: question.id,
          };

          /**
           * @todo - if question has parts, get all answers for parts
           */
          await this.getAllMemberAnswers(payload);
        }
        return Promise.resolve();
      },
      async handleGetAllStatsInTime() {
        if (this.memberStatsInTime.length) return Promise.resolve();
        for (const [index, question] of this.questions.entries()) {
          const { id, parts } = question;
          const payload = {
            ...this.answerInTimePayload,
            questionId: id,
            questionIndex: index,
          };

          const hasParts = parts && parts.length;

          if (hasParts) await this.handleStatInTimeParts(parts, index, payload);
          else {
            delete payload.questionIndex;
            await this.setAllMemberStatsInTime(payload);
          }
        }
        return Promise.resolve();
      },
      async handleStatInTimeParts(parts, questionIndex, payload) {
        for (const part of parts) {
          await this.setMemberStatsInTimeParts({
            ...payload,
            questionPartId: part.id,
            questionIndex,
          });
        }
        return Promise.resolve();
      },
      async handleSetCurrentDates() {
        this.inTimeDates.from = this.currentReflectionFrom;
        this.inTimeDates.to = this.currentReflectionTo;
        return Promise.resolve();
      },
      handleTabChange(key) {
        this.setTab(key);
      },
      async handleReflectionChange() {
        this.resetMemberAnswers();
        this.handleGetAnswers();
        this.handleSetCurrentDates();
      },
      expandComponents(value) {
        this.expanded = value.checked;
      },
      setQuestionLoading(status) {
        this.questionLoading = status;
      },
      setStatsInTimeLoading(status) {
        this.statsInTimeLoading = status;
      },
      async handleReflectionFilter({ fromDate, toDate }) {
        this.setQuestionLoading(true);
        this.setStatsInTimeLoading(true);
        this.resetMemberStatsInTime();
        if (fromDate) this.inTimeDates.from = this.moment(fromDate).format('YYYY-MM-DD');
        if (toDate) this.inTimeDates.to = this.moment(toDate).format('YYYY-MM-DD');
        // this.handleMemberAnswersInTime();
        await this.handleGetAllStatsInTime();
        this.setQuestionLoading(false);
        this.setStatsInTimeLoading(false);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .member-reflection-stats {
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    width: 100%;

    &__actions {
      top: -24px;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;

      .list-expand {
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
          font-family: Nunito-Bold;
          font-size: 10px;
          text-transform: uppercase;
          color: $grey-3;
        }
      }

      .list-type-icons {
        margin-left: 20px;

        svg {
          &:last-of-type {
            margin-left: 5px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      position: relative;
    }
    &__items {
      width: 100%;
      margin-left: 6px;
      position: relative;
    }

    &__tabs {
      display: flex;
      align-items: center;
      position: absolute;
      top: -24px;
      left: 10px;
    }
  }
</style>
