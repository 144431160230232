<template>
  <div class="member-answer__answer-indicator">
    <span class="indicator-answer-wrapper"><span class="indicator-answer" v-html="indicator"></span>({{scale[0]}} - {{scale[1]}})</span>
  </div>
</template>
<script>
export default {
  name: 'TemplateIndicator',
  props: ['method', 'question', 'memberAnswer'],
  computed: {
    indicator() {
      return this.memberAnswer
    },
    scale(){
      return [this.question.answerOptions.min, this.question.answerOptions.max]
    }
  },
};
</script>
<style lang="scss">
.member-answer {
  &__answer-indicator {
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 14px;
    color: $grey-2;
  }
  .indicator-answer-wrapper{
    .indicator-answer{
      font-family: Roboto-Medium;
      color: $green-3;
      margin-right: 10px;
    }
  }
}
</style>
