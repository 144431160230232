/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/smiley-select-blank': {
    width: 36.989,
    height: 18.99,
    viewBox: '0 0 36.989 18.99',
    data: '<g data-name="Group 3151"><g data-name="Group 2467"><g data-name="Group 3149"><g data-name="Group 3148"><g data-name="Group 2416" _stroke="#5454c4"><path pid="0" data-name="Rectangle 4461" _fill="none" stroke-linejoin="round" d="M18.5.5h17.99v17.99H18.5z"/><path pid="1" data-name="Rectangle 4479" _fill="#5454c4" stroke-linejoin="round" d="M.5.5h17.99v17.99H.5z"/><g data-name="Group 2406" _fill="none" stroke-linecap="round" stroke-miterlimit="10"><path pid="2" data-name="Path 32" d="M27.548 4.431v4.74h-4.74"/><path pid="3" data-name="Path 33" d="M27.548 13.911v-4.74h4.74"/></g></g></g></g><g data-name="Group 2465" _fill="#fff"><path pid="4" data-name="Path 464" d="M15.708 11.505a6.766 6.766 0 1 1-4.946-8.193 6.767 6.767 0 0 1 4.946 8.193z"/><path pid="5" data-name="Path 465" d="M8.105 8.025a1.134 1.134 0 1 1-1.133-1.133 1.134 1.134 0 0 1 1.133 1.133z" _stroke="#5454c4" stroke-linejoin="round"/><path pid="6" data-name="Path 466" d="M12.446 8.025a1.134 1.134 0 1 1-1.133-1.133 1.134 1.134 0 0 1 1.133 1.133z" _stroke="#5454c4" stroke-linejoin="round"/><path pid="7" data-name="Path 467" d="M6.395 11.495a2.744 2.744 0 0 0 5.488 0z" _stroke="#5454c4" stroke-linejoin="round"/></g></g></g>'
  }
})
