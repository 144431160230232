/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'line-chart': {
    width: 42.433,
    height: 27.24,
    viewBox: '0 0 42.433 27.24',
    data: '<g data-name="Group 1956"><g data-name="Group 1942" transform="translate(-2799.262 -1141.751)" _fill="none" _stroke="#3b328b" stroke-miterlimit="10" stroke-width="1.5"><circle pid="0" data-name="Ellipse 796" cx="3.063" cy="3.063" r="3.063" transform="translate(2800.012 1162.116)"/><circle pid="1" data-name="Ellipse 797" cx="3.063" cy="3.063" r="3.063" transform="translate(2811.896 1150.845)"/><circle pid="2" data-name="Ellipse 798" cx="3.063" cy="3.063" r="3.063" transform="translate(2825.078 1152.324)"/><circle pid="3" data-name="Ellipse 799" cx="3.063" cy="3.063" r="3.063" transform="translate(2834.82 1142.501)"/><path pid="4" data-name="Line 1167" d="M2812.755 1156.032l-7.502 6.996"/><path pid="5" data-name="Line 1168" d="M2818.005 1154.224l7.073.789"/><path pid="6" data-name="Line 1169" d="M2835.796 1147.801l-5.496 5.414"/></g></g>'
  }
})
