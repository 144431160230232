<template>
  <div class="dropdown__custom">
    <div class="current-locale-holder">
      <svgicon :original="true" :name='`flags/flag-${currentLocale.key}`' height="19px"></svgicon>
      <span class="current-locale-title">{{currentLocale.title}}</span>
      <span class="dropdown-icon"><svgicon :original="true" name="triangle-arrow" height="4px"></svgicon></span>
    </div>
    <ul>
      <li v-for="locale in locales" :key="locale.key" @click="changeUserLanguage(locale.key)"><svgicon :original="true" :name='`flags/flag-${locale.key}`' height="19px"></svgicon>{{locale.title}}</li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MenuDropdownLanguage',
  computed: {
    ...mapGetters('meta', ['locales']),
    currentLocale(){
      return this.locales.find(locale => locale.key === this.$i18n.locale);
    }
  },
  methods: {
    ...mapActions('meta', ['resetTermsAndConditions', 'setLoading']),
    changeUserLanguage(locale) {
      this.setLoading(true);
      setTimeout(() => {
        this.resetTermsAndConditions();
        this.$i18n.locale = locale;
        localStorage.setItem('locale', locale);
        window.location.reload();
        this.setLoading(false);
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown__custom {
  position: relative;
  background-color: #11ab99;
  color: #fff;
  width: 103px;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .current-locale-holder {
    display: flex;
    align-items: center;
    font-family: Roboto-Medium;
    font-size: 11px;
    text-decoration: none;
    color: #fff;
    padding-top: 6px;
    .current-locale-title{
      margin-left: 10px;
    }
    .dropdown-icon {
      color: #fff;
      margin-left: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  ul {
    display: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    position: absolute;
    top: 32px;
    z-index: 2;
    margin-top: -2.5px;
    border-top: 1px solid #fff;
    li {
      list-style: none;
      width: 103px;
      height: 31px;
      background-color: #11ab99;
      border: 1px solid transparent;
      margin-top: 1px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-left: 6px;
        margin-right: 9px;
        height: 19px;
        width: 26px;
      }
      a {
        padding-top: 0;
      }
      &:hover {
        background-color: #33ca97;
      }
    }
  }
  &:hover ul {
    display: flex;
  }
  &:hover{
    .current-locale-holder{
      .dropdown-icon{
        svg{
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
