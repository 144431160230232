/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'globe': {
    width: 18.686,
    height: 18.686,
    viewBox: '0 0 18.686 18.686',
    data: '<path pid="0" data-name="Path 1154" d="M15.949 2.737a9.282 9.282 0 0 1 2.736 6.607 9.282 9.282 0 0 1-2.736 6.606 9.282 9.282 0 0 1-6.606 2.736 9.282 9.282 0 0 1-6.606-2.736A9.282 9.282 0 0 1 0 9.343a9.282 9.282 0 0 1 2.736-6.607A9.282 9.282 0 0 1 9.343 0a9.282 9.282 0 0 1 6.606 2.737zm-.826 12.387a8.119 8.119 0 0 0 2.231-4.143c-.246.362-.481.5-.627-.314-.15-1.321-1.363-.477-2.126-.946-.8.541-2.608-1.052-2.3.745.473.811 2.555-1.085 1.517.63-.662 1.2-2.42 3.849-2.192 5.224.029 2-2.046.418-2.761-.247-.481-1.331-.164-3.656-1.421-4.308-1.365-.059-2.537-.183-3.066-1.709a2.483 2.483 0 0 1 1.509-2.968c1.713-1.076 2.324 1.26 3.93 1.3.5-.522 1.858-.688 1.971-1.273-1.054-.186 1.337-.886-.1-1.284-.793.093-1.3.823-.883 1.441-1.537.358-1.586-2.225-3.064-1.41-.038 1.288-2.413.418-.822.156.547-.239-.892-.931-.115-.805.382-.021 1.667-.471 1.319-.774.715-.444 1.317 1.064 2.017-.034.506-.844-.212-1-.846-.572-.357-.4.631-1.264 1.5-1.638a1.732 1.732 0 0 1 .78-.173c.439.507 1.251.595 1.293-.061a8.145 8.145 0 0 0-3.527-.8 8.114 8.114 0 0 0-4.878 1.613c.377.173.591.388.228.662-.282.84-1.426 1.969-2.431 1.809a8.085 8.085 0 0 0-1.012 2.929c.841.278 1.035.829.855 1.014a2.767 2.767 0 0 0-.828 1.484 8.114 8.114 0 0 0 2.286 4.445 8.121 8.121 0 0 0 5.781 2.394 8.121 8.121 0 0 0 5.783-2.387z" _fill="#919396"/>'
  }
})
