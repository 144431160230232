<template>
  <section id="mobile" :style="`background-image: url(${MobileFrame})`">
    <div class="mobile__screen">
      <img :src="selectedComponent || null" />
    </div>
    <div class="duration" v-if="!modalView">
      <svgicon class="duration__icon" name="clock" :original="true" width="18px"></svgicon>
      <span class="duration__time">{{ totalDuration }}</span>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex';

  import MobileFrame from '@/assets/mobile-frame.png';
  import QuestionNumber from '@/assets/img/QuestionNumber.png';
  import QuestionSelectColor from '@/assets/img/QuestionSelectColor.png';
  import QuestionSelectImage from '@/assets/img/QuestionSelectImage.png';
  import ProgressMatrix from '@/assets/img/ProgressMatrix.png';
  import ThreeTwoOne from '@/assets/img/ThreeTwoOne.png';
  import Threek from '@/assets/img/Threek.png';

  export default {
    name: 'Mobile',
    data() {
      return {
        MobileFrame,
        components: {
          yes_no: this.getImageSrc('yes_no', 'YesNo'),
          yes_no_img: this.getImageSrc('yes_no_img', 'YesNoImg'),
          yes_no_color: this.getImageSrc('yes_no_color', 'YesNoColor'),
          traffic_light: this.getImageSrc('traffic_light', 'TrafficLight'),
          smileys: this.getImageSrc('smileys', 'Smileys'),
          thermometer: this.getImageSrc('thermometer', 'Thermometer'),
          indicator: this.getImageSrc('indicator', 'Indicator'),
          incomplete_sentence: this.getImageSrc('incomplete_sentence', 'IncompleteSentence'),
          question: this.getImageSrc('question', 'Question'),
          choice: this.getImageSrc('choice', 'QuestionSelectText'),
          question_number: QuestionNumber,
          question_text: this.getImageSrc('question_text', 'QuestionSelectText'),
          question_color: QuestionSelectColor,
          question_image: QuestionSelectImage,
          pie: this.getImageSrc('pie', 'Pie'),
          progress_matrix: ProgressMatrix,
          '3-2-1': ThreeTwoOne,
          '3k': Threek,
        },
      };
    },
    props: ['modalView'],
    computed: {
      ...mapGetters('methods', ['selectedMethod']),
      ...mapGetters('questionnaire', ['totalDuration', 'currentQuestion']),
      template() {
        if (!this.selectedMethod) return false;
        return this.selectedMethod.template;
      },
      answerTemplate() {
        if (!this.selectedMethod) return false;
        return this.currentQuestion ? this.currentQuestion.answerTemplate : null;
      },
      answerType() {
        if (!this.currentQuestion) return false;
        const {
          answerOptions: { type },
        } = this.currentQuestion;
        return type;
      },
      isMethods() {
        const { name } = this.$route;
        return name === 'Methods';
      },
      selectedComponent() {
        let component;
        if (this.isMethods) {
          component = this.components[this.template];
          return component;
        }

        switch (this.template) {
          case 'yes_no':
            switch (this.answerType) {
              case 'color':
                component = this.components.yes_no_color;
                break;
              case 'img':
                component = this.components.yes_no_img;
                break;
              default:
                component = this.components[this.template];
                break;
            }
            break;
          case 'question':
            switch (this.answerTemplate) {
              case 'number':
                component = this.components.question_number;
                break;
              case 'select':
                switch (this.answerType) {
                  case 'text':
                    component = this.components.question_text;
                    break;
                  case 'color':
                    component = this.components.question_color;
                    break;
                  case 'img':
                    component = this.components.question_image;
                    break;

                  default:
                    break;
                }
                break;
              default:
                component = this.components[this.template];
                break;
            }
            break;
          default:
            component = this.components[this.template];
            break;
        }
        return component;
      },
    },
    methods: {
      getImageSrc(key, name) {
        import(`@/assets/img/${name}_${localStorage.getItem('locale')}.png`).then((src) => {
          this.components[key] = src.default;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  #mobile {
    height: 500px;
    width: 245px;
    background-repeat: no-repeat;
    position: sticky;
    top: 40px;
    .mobile__screen {
      position: absolute;
      top: 56px;
      left: 14px;
      height: 387px;
      width: 218px;
      .mobile__header {
        width: 100%;
        height: 75px;
        background-color: #11ab99;
        .mobile__top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
          height: 8px;
          .mobile__info {
            svg {
              padding: 1px;
            }
          }
        }
        .mobile__header-content {
          display: flex;
          padding: 0 15px;
          align-items: center;
          p {
            font-family: Roboto-Regular;
            font-size: 8.5px;
            color: #f0f0f0;
            margin-left: 5px;
          }
        }
      }

      .mobile__content {
        height: 282px;
        background-color: #ebebeb;
        display: flex;
        flex-direction: column;
        position: relative;
        .mobile__progress {
          margin: 260px auto 0 auto;
          display: flex;
          flex-direction: column;
          span {
            font-family: Roboto-Regular;
            font-size: 7.5px;
            color: #505e67;
          }
          .mobile__progress-bar {
            width: 136.5px;
            height: 2px;
            background-color: #fff;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              width: 120px;
              height: 2px;
              background-color: #ffc100;
            }
          }
        }
      }
      .mobile__menu {
        display: flex;
        background-color: #f3f3f3;
        justify-content: space-around;
        height: 29px;
        align-items: center;
        .mobile__menu-action {
          display: flex;
          flex-direction: column;
          span {
            font-family: Roboto-Regular;
            font-size: 5.5px;
            color: #919396;
          }
        }
      }
    }

    .duration {
      position: absolute;
      bottom: -30px;
      left: 50%;
      display: flex;
      align-items: center;
      transform: translateX(-50%);

      &__time {
        margin-left: 10px;
        font-family: Roboto-Medium;
        color: $grey-5;
        font-size: 15px;
      }
    }
  }
</style>
