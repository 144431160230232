<template>
  <div id="group-reflection" class="group-reflection" v-if="!loading">
    <group-reflection-header
      :questionnaire="questionnaire"
      :group="currentGroup"
      :schedule="schedule"
      @handleDeleteQuestionnaireModal="handleDeleteQuestionnaireModal"
    ></group-reflection-header>
    <group-reflection-filter
      :disabledDates="disabledDates"
      @handleReflectionFilter="handleReflectionFilter"
    ></group-reflection-filter>
    <transition-group class="group-reflection__list" name="template-transition">
      <group-reflection-date
        v-for="(reflection, index) in groupReflectionList"
        :complete="reflection.completion"
        :reflection="reflection"
        :key="reflection.id"
        @handleDeleteModal="handleDeleteModal"
        @handleSendReflectionModal="toggleSendAgainModal"
      >
        <template v-slot:index>{{ queries.offset + index + 1 }}.</template>
      </group-reflection-date>
    </transition-group>
    <div v-if="!groupReflectionList.length" class="no-data-message-wrapper flex-global">
      <h3>{{ $t('messages.no_data_message_alt') }}</h3>
    </div>
    <pagination
      v-if="totalPages > 1"
      :activePage="activePage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
    <delete-modal
      v-if="deleteModal"
      :type="deleteModalType"
      @handleDeleteAction="handleDeleteActionWrapper"
    />
    <send-reflection-again-modal
      v-if="sendAgainModal"
      :questionnaire="questionnaire"
      @toggleModal="toggleSendAgainModal"
      @handleSendReflectionAgainAction="handleSendReflectionAgain"
    />
    <global-modal v-if="isResentModal" @handleModalToggle="toggleResentModal">
      <template v-slot:modal-main-icon>
        <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
      </template>
      <template v-slot:modal-header>
        {{ $t('group.group_reflection_send_again_success_message') }}
      </template>
      <template v-slot:modal-actions>
        <button-4 @click.native="toggleResentModal">{{ $t('actions.ok_action') }}</button-4>
      </template>
    </global-modal>
  </div>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import {
    handleGetGroup,
    handleGetReflections,
    handleGetQuestionnaire,
    handleGetQuestionnaireSchedule,
  } from '@/helpers/groups.helpers';

  import GroupReflectionHeader from '@/components/groups/GroupReflectionHeader.vue';
  import GroupReflectionFilter from '@/components/groups/GroupReflectionFilter.vue';
  import GroupReflectionDate from '@/components/groups/GroupReflectionDate.vue';
  import Pagination from '@/components/base/Pagination.vue';
  import DeleteModal from '@/components/base/DeleteModal.vue';
  import SendReflectionAgainModal from '@/components/base/SendReflectionAgainModal.vue';
  import GlobalModal from '@/components/base/GlobalModal.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'GroupReflection',
    components: {
      GroupReflectionHeader,
      GroupReflectionFilter,
      GroupReflectionDate,
      DeleteModal,
      SendReflectionAgainModal,
      GlobalModal,
      Pagination,
      Button4,
      Loading,
    },
    beforeRouteEnter(to, from, next) {
      const { questionnaireId } = to.params;
      if (questionnaireId === 'Token') {
        next({ name: 'Group' });
      } else {
        next();
      }
    },
    data() {
      return {
        totalCount: 0,
        activePage: 1,
        disabledDates: {
          from: this.moment()
            .add(1, 'days')
            .toDate(),
          to: null,
        },
        filterDates: {
          from: null,
          to: null,
        },
        deleteModalType: null,
        reflectionToDelete: null,
        sendAgainModal: false,
        isResentModal: false,
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('groups', ['currentGroup']),
      ...mapGetters('reflections', ['groupReflectionList', 'unparticipatedStudents']),
      ...mapGetters('questionnaire', ['schedules']),
      ...mapGetters('meta', ['loading', 'deleteModal']),
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
      questionnaire() {
        if (!this.groupReflectionList.length) return null;
        const reflection = this.groupReflectionList[0];
        return {
          ...reflection.questionnaire,
          id: reflection.questionnaireId,
        };
      },
      queries() {
        const {
          params: { questionnaireId, groupId },
        } = this.$route;
        return {
          id: +groupId,
          questionnaireId: +questionnaireId,
          limit: 10,
          offset: 0,
          ...this.filterDates,
        };
      },
      schedule() {
        if (!this.schedules || !this.schedules.length) return false;
        return this.schedules[0];
      },
    },
    methods: {
      ...mapActions('reflections', [
        'getReflectionsByGroup',
        'deleteReflection',
        'setCurrentSelectedReflection',
        'resetUnparticipatedStudents',
        'resendReflection',
      ]),
      ...mapActions('questionnaire', [
        'getQuestionnaireSchedule',
        'getQuestionnaire',
        'deleteGroupQuestionnaire',
      ]),
      ...mapActions('groups', ['getGroup']),
      ...mapActions('meta', ['setLoading', 'toggleDeleteModal']),
      handleGetGroup,
      handleGetReflections,
      handleGetQuestionnaire,
      handleGetQuestionnaireSchedule,
      async handlePageInit() {
        const {
          params: { questionnaireId },
        } = this.$route;
        this.queries.questionnaireId = questionnaireId;
        this.setLoading(true);
        try {
          await this.handleGetGroup();
          // await this.handleGetReflections();
          await this.handleGetQuestionnaire();
          await this.handleGetQuestionnaireInner().then(this.handleGetQuestionnaireSchedule);
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      selectPage(page) {
        this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
        this.getReflectionsByGroup(this.queries);
        this.activePage = page;
      },
      handleReflectionFilter({ toDate, fromDate }) {
        if (!toDate || !fromDate) return;

        this.filterDates = {
          toDate: this.moment(toDate).format('YYYY-MM-DD'),
          fromDate: this.moment(fromDate).format('YYYY-MM-DD'),
        };
        this.activePage = 1;
        this.handleGetQuestionnaireInner();
      },
      async handleGetQuestionnaireInner() {
        const { totalCount } = await this.getReflectionsByGroup(this.queries);
        this.totalCount = totalCount || null;
        return Promise.resolve();
      },
      handleDeleteModal(id) {
        this.reflectionToDelete = id;
        this.deleteModalType = 'reflection';
        this.toggleDeleteModal();
      },
      handleDeleteQuestionnaireModal() {
        this.deleteModalType = 'questionnaire';
        this.toggleDeleteModal();
      },
      async handleReflectionDelete() {
        this.toggleDeleteModal();
        this.setLoading(true);
        const result = await this.deleteReflection(this.reflectionToDelete);
        if (result) {
          this.getReflectionsByGroup(this.queries);
        }
        this.setLoading(false);
        return Promise.resolve();
      },
      async handleQuestionnaireDelete() {
        const {
          params: { questionnaireId },
        } = this.$route;
        this.toggleDeleteModal();
        this.setLoading(true);
        const result = await this.deleteGroupQuestionnaire(questionnaireId);

        if (result) {
          setTimeout(() => {
            this.$router.push({ name: 'Group' });
            this.setLoading(false);
          }, 1000);
        } else {
          this.setLoading(false);
        }
        return Promise.resolve();
      },
      handleDeleteActionWrapper() {
        if (this.deleteModalType === 'reflection') {
          this.handleReflectionDelete();
        } else {
          this.handleQuestionnaireDelete();
        }
      },
      toggleSendAgainModal() {
        if (this.sendAgainModal) {
          this.setCurrentSelectedReflection(null);
          this.resetUnparticipatedStudents();
        }
        this.sendAgainModal = !this.sendAgainModal;
      },
      handleSendReflectionAgain(payload) {
        this.resendReflection(payload).then((res) => {
          const { success } = res;
          if (success) {
            // reset current selected reflection on successful resend
            this.toggleSendAgainModal();
            this.toggleResentModal();
          }
        });
      },
      toggleResentModal() {
        this.isResentModal = !this.isResentModal;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .group-reflection {
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    width: 100%;

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    .no-data-message-wrapper {
      h3 {
        width: 350px;
      }
    }
  }
</style>
