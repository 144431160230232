<template>
  <div class="answer-component answer-component--available">
    <div class="matrix-chart">
      <div class="matrix-chart__row">
        <div class="matrix-chart__row__col">
          <span class="matrix-chart__row__col__title matrix-chart__row__col__title--bottom">
            Klausimas
          </span>
          <span class="matrix-chart__row__col__separator"></span>
          <span class="matrix-chart__row__col__title matrix-chart__row__col__title--top">
            Lygis
          </span>
        </div>
        <div class="matrix-chart__row__col" v-for="variant in variants" :key="variant.value">
          <span
            class="matrix-chart__row__col__variant matrix-chart__row__col__variant--main"
            v-tooltip="variant.tooltip"
          >
            {{ variant.value }}
          </span>
        </div>
      </div>
      <div class="matrix-chart__row" v-for="part in parts" :key="part.id">
        <div class="matrix-chart__row__col">
          <span class="matrix-chart__row__col__variant">{{ part.title }}</span>
        </div>
        <div class="matrix-chart__row__col">
          <span class="matrix-chart__row__col__value">{{ Math.floor(Math.random() * 100) }}%</span>
        </div>
        <div class="matrix-chart__row__col">
          <span class="matrix-chart__row__col__value">{{ Math.floor(Math.random() * 100) }}%</span>
        </div>
        <div
          class="matrix-chart__row__col"
          v-for="option in part.answerOptions.options"
          :key="option.show"
        >
          <span class="matrix-chart__row__col__value">{{ Math.floor(Math.random() * 100) }}%</span>
          <span class="matrix-chart__row__col__variant">{{ option.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MatrixChart',
  props: ['question', 'groupStatQuestion'],
  data() {
    return {
      mockData: {
        id: 1281,
        title: 'Shmatrica',
        methodId: 12,
        answerTemplate: 'select',
        position: 8,
        active: true,
        answerCount: 1,
        answerOptions: {
          type: 'text',
          view: 'box',
          options: [
            { show: 'Pagrindai', value: '1', name: 'Pagrindai' },
            { show: 'Vidutiniškos žinios', value: '1', name: 'Vidutiniškos žinios' },
            { show: 'Pažengęs naudotojas', value: '1', name: 'Pažengęs naudotojas' },
            { show: 'Profesionalas', value: '1', name: 'Profesionalas' },
          ],
          errors: [],
        },
        parts: [
          {
            id: 617,
            title: 'Vienas',
            position: 0,
            answerCount: 1,
            answerOptions: {
              type: 'text',
              view: 'box',
              options: [
                { show: 'Pagrindai', value: '1', name: 'Pagrindai' },
                { show: 'Vidutiniškos žinios', value: '1', name: 'Vidutiniškos žinios' },
                { show: 'Pažengęs naudotojas', value: '1', name: 'Pažengęs naudotojas' },
                { show: 'Profesionalas', value: '1', name: 'Profesionalas' },
              ],
            },
          },
          {
            id: 618,
            title: 'Dur',
            position: 1,
            answerCount: 1,
            answerOptions: {
              type: 'text',
              view: 'box',
              options: [
                { show: 'Pagrindai', value: '2', name: 'Pagrindai' },
                { show: 'Vidutiniškos žinios', value: '2', name: 'Vidutiniškos žinios' },
                { show: 'Pažengęs naudotojas', value: '2', name: 'Pažengęs naudotojas' },
                { show: 'Profesionalas', value: '2', name: 'Profesionalas' },
              ],
              errors: [],
            },
          },
        ],
      },
      variants: [
        { value: 'Nere', tooltip: 'Nereflektavo' },
        { value: 'Pral', tooltip: 'Praleido klausimą' },
        { value: 'Pirmieji žingsniai', tooltip: 'Pirmieji žingsniai' },
        { value: 'Judu pirmyn', tooltip: 'Judu pirmyn' },
        { value: 'Man pavyksta', tooltip: 'Man pavyksta' },
        { value: 'Galiu dalintis su kitais', tooltip: 'Galiu dalintis su kitais' },
      ],
    };
  },
  computed: {
    parts() {
      return this.question.parts;
    },
  },
};
</script>
<style lang="scss">
$border: 1px solid $grey-1;

.matrix-chart {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  margin-top: 20px;

  &__row {
    display: grid;
    grid-template-columns: 140px repeat(2, minmax(0, 1fr)) repeat(4, 140px);
    padding: 0 10px;
    height: 40px;

    &:not(:first-of-type) {
      height: 60px;
    }

    &__col {
      padding: 10px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: $border;
      position: relative;

      &:not(:first-of-type) {
        border-left: $border;
      }

      &:not(:last-of-type) {
        border-right: $border;
      }

      &__variant {
        font-size: 13px;
        word-break: break-all;

        &--main {
          color: #5454c4;
          font-family: Roboto-Medium;
        }
      }

      &__value {
        font-family: Roboto-Medium;
      }

      &__separator {
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translate(4%, -50%) rotate(16deg);
        background-color: $grey-2;
      }

      &__title {
        font-size: 13px;

        &--top,
        &--bottom {
          position: absolute;
          color: $grey-2;
        }

        &--top {
          top: 6px;
          right: 6px;
        }

        &--bottom {
          bottom: 6px;
          left: 6px;
        }
      }
    }
  }
}
</style>
