<template>
  <div class="stat-value-list">
    <div class="stat-value-list__item" v-for="(item, index) in groupStatQuestion" :key="index">
      <div class="stat-value-list__item__color" :style="{ 'background-color': item.color }"></div>
      <div class="stat-value-list__item__value">
        {{ item.value }}
        <span class="stat-value-list__item__count">({{ item.count }})</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatValueList',
  props: ['groupStatQuestion'],
};
</script>
<style lang="scss">
.stat-value-list {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &__color {
      min-height: 16px;
      min-width: 16px;
      border-radius: 50%;
    }

    &__value,
    &__count {
      margin-left: 10px;
      word-break: break-word;
    }
  }
}
</style>
