<template>
  <div class="member-answer__answer-pie">
    <div v-if="!isNoReflection" class="answer-pie">
      <div class="answer-pie__list">
        <div class="pie-item" v-for="(answer, index) in memberAnswer" :key="index">
          <div :class="['pie-item__icon', { 'pie-item__icon--null': !answer.percent }]"></div>
          <div class="pie-item__member">
            <span class="item-member item-member--null" v-if="answer.title === null">
              Neužpildyta
            </span>
            <span class="item-member" v-else>{{ answer.title }}</span>
          </div>
          <div :class="['pie-item__percentage', { 'pie-item__percentage--null': !answer.percent }]">
            {{ answer.percent }}%
          </div>
        </div>
      </div>
      <div class="answer-pie__chart" v-if="pieChartData">
        <doughnut-chart :chart-data="pieChartData" :options="chartOptions"></doughnut-chart>
      </div>
    </div>
    <div v-else>
      <span class="no-reflection-message">{{ memberAnswer || $t('member_skipped_alt') }}</span>
    </div>
  </div>
</template>
<script>
  import DoughnutChart from '@/components/charts/DoughnutChart.vue';

  export default {
    name: 'TemplatePie',
    components: {
      DoughnutChart,
    },
    props: ['method', 'question', 'memberAnswer'],
    data() {
      return {
        chartOptions: {
          responsive: true,
          legend: false,
        },
      };
    },
    computed: {
      isNoReflection() {
        return typeof this.memberAnswer === 'string' || !this.memberAnswer;
      },
      pieChartData() {
        if (this.question.answerTemplate !== 'pie') return false;
        const answers = this.memberAnswer;
        return {
          labels: answers.map((item) => item.title),
          datasets: [
            {
              data: answers.map((item) => item.percent),
              backgroundColor: [
                '#ac92ed',
                '#fb6e52',
                '#48cfae',
                '#ec87bf',
                '#656d78',
                '#64b5f6',
                '#ac92ed',
                '#fb6e52',
                '#48cfae',
                '#ec87bf',
                '#656d78',
                '#64b5f6',
              ],
              borderColor: 'transparent',
              borderWidth: 0,
            },
          ],
        };
      },
    },
  };
</script>
<style lang="scss">
  .member-answer {
    &__answer-pie {
      margin-top: 10px;

      .answer-pie {
        display: flex;
        align-items: center;

        &__list {
          display: flex;
          flex-direction: column;

          .pie-item {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            background-color: $grey-6;

            width: 473px;

            &__icon {
              height: 16px;
              width: 16px;
              border-radius: 50%;
            }

            &__icon--null {
              background-color: #ccd0d9;
            }

            &:nth-of-type(1),
            &:nth-of-type(7) {
              .pie-item__icon {
                background-color: #ac92ed;
              }
            }
            &:nth-of-type(2),
            &:nth-of-type(8) {
              .pie-item__icon {
                background-color: #fb6e52;
              }
            }
            &:nth-of-type(3),
            &:nth-of-type(9) {
              .pie-item__icon {
                background-color: #48cfae;
              }
            }
            &:nth-of-type(4),
            &:nth-of-type(10) {
              .pie-item__icon {
                background-color: #ec87bf;
              }
            }
            &:nth-of-type(5),
            &:nth-of-type(11) {
              .pie-item__icon {
                background-color: #656d78;
              }
            }
            &:nth-of-type(6),
            &:nth-of-type(12) {
              .pie-item__icon {
                background-color: #64b5f6;
              }
            }

            &__member {
              margin-left: 15px;
              font-family: Roboto-Regular;
              font-size: 14px;

              .item-member {
                &--null {
                  color: $grey-4;
                }
              }
            }

            &__percentage {
              margin-left: auto;
              font-family: Roboto-Medium;
              color: $green-3;
            }

            &__percentage--null {
              color: $grey-4;
            }

            &:nth-of-type(2n) {
              background-color: #e5e6e6;
            }
          }
        }

        &__chart {
          height: 160px;
          width: 160px;
          margin: 0 auto;
        }
      }
      .no-reflection-message {
        margin-left: 20px;
        font-family: 'Roboto-Medium';
        font-size: 14px;
        color: $green-3;
      }
    }
  }
</style>
