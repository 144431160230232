/* eslint-disable */
require('./emote-agitated')
require('./emote-angry')
require('./emote-blue')
require('./emote-blush')
require('./emote-cool')
require('./emote-crying')
require('./emote-dead')
require('./emote-down')
require('./emote-evil')
require('./emote-happy')
require('./emote-heart')
require('./emote-injured')
require('./emote-kiss')
require('./emote-lick')
require('./emote-lol')
require('./emote-love')
require('./emote-mad')
require('./emote-saint')
require('./emote-scared')
require('./emote-shocked')
require('./emote-smile')
require('./emote-star')
require('./emote-surprised')
require('./emote-tear')
require('./emote-thinking')
require('./emote-thumbsup')
require('./emote-unwell')
require('./emote-upset')
require('./emote-wink')
