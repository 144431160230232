<template>
  <div class="methods__header">
    <div class="header__title">
      <header-h1>Metodai</header-h1>
      <router-link :to="{name: 'CreateReflection'}">
        <button-7>Naujas klausimynas</button-7>
      </router-link>
    </div>
    <div class="methods__description">
      <div class="methods__types">
        <header-h3>
          APIE METODŲ
          <span>TIPUS</span>
        </header-h3>
        <paragraph>
          <span>Uždari</span> - kuriant klausimyną iš anksto sugalvojamos
          ir įvedamos galimos atsakymų vertės. Reflektuojantis atsako
          pasirinkdamas labiausiai tinkantį atsakymo variantą. Uždaro tipo
          klausimuose jūs iš karto galėsite matyti sistemos sugeneruotą
          duomenų analizę.
        </paragraph>
        <paragraph>
          <span>Atviri</span> - kuriant klausimyną sugalvojama, kokios
          apimties (dažniausiai simbolių skaičius) bus atsakymas į klausimą.
          Duomenų analizė bus atliekama priskiriant jūsų susikurtas reikšmes
          kiekvienam atsakymui
        </paragraph>
      </div>
      <div class="methods__time">
        <header-h3>
          APIE METODŲ
          <span>LAIKĄ</span>
        </header-h3>
        <paragraph>
          Kiekvienas metodas turi automatiškai jam priskirtą pildymo
          trukmę, t.y. apytikslis laikas, kiek reflektuojantiems
          vidutiniškai užtrunka atsakyti į tokio tipo klausimą. Kuriant
          naują klausimyną matysite kiek apytiksliai laiko galėtų užtrukti
          jūsų kuriamo klausimyno pildymas. Tai padės nustatyti tikslesnius
          refleksijos klausimyno siuntimo parametrus (pavyzdžiui, žinodami,
          kad refleksija užtruks 3 minutes, galite nustatyti išsiuntimo
          laiką 3 min iki suplanuoto užsiėmimo pabaigos). Refleksijos
          metodus klausimyno kūrimo metu pagal jų pildymo laiką galite
          filtruoti taip:
          <span>iki 1 min</span>,
          <span>iki 3 min</span>,
          <span>iki 5 min</span>,
          <span>5+ min.</span>
        </paragraph>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import HeaderH3 from '@/components/elements/typography/HeaderH3.vue';
import Paragraph from '@/components/elements/typography/Paragraph.vue';
import Button7 from '@/components/elements/buttons/Button7.vue';

export default {
  name: 'MethodHeader',
  components: {
    HeaderH1,
    HeaderH3,
    Paragraph,
    Button7,
  },
};
</script>
<style lang="scss" scoped>
.methods__header {
  text-align: left;
  max-width: 890px;
  margin: 0 auto;
  .header__title {
    display: flex;
    justify-content: space-between;
  }
  .methods__description {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    span {
      font-family: Nunito-Black;
    }
    .methods__types,
    .methods__time {
      flex: 1;
      span {
        font-family: Roboto-Medium;
      }
    }
    .methods__time {
      margin-left: 20px;
    }
    p {
      margin-top: 15px;
    }
  }
}
</style>
