/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/group': {
    width: 17.211,
    height: 9.938,
    viewBox: '0 0 17.211 9.938',
    data: '<g data-name="Group 1116"><g data-name="Group 215" transform="translate(-885.634 -433.53)" stroke-width=".527" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round" _fill="none"><circle pid="0" data-name="Ellipse 56" cx="2.001" cy="2.001" r="2.001" transform="translate(892.238 433.794)"/><path pid="1" data-name="Path 214" d="M898.13 441.688a3.892 3.892 0 1 0-7.783 0 1.518 1.518 0 0 0 1.518 1.517h4.748a1.517 1.517 0 0 0 1.517-1.517z"/><g data-name="Group 214"><g data-name="Group 212"><circle pid="2" data-name="Ellipse 57" cx="1.739" cy="1.739" r="1.739" transform="translate(897.461 434.277)"/><path pid="3" data-name="Path 215" d="M898.891 442.454h2.372a1.318 1.318 0 0 0 1.318-1.318 3.382 3.382 0 0 0-5.048-2.942"/></g><g data-name="Group 213"><circle pid="4" data-name="Ellipse 58" cx="1.739" cy="1.739" r="1.739" transform="translate(887.539 434.277)"/><path pid="5" data-name="Path 216" d="M889.587 442.454h-2.372a1.318 1.318 0 0 1-1.318-1.318 3.382 3.382 0 0 1 5.048-2.942"/></g></g></g></g>'
  }
})
