export async function handleGetGroupList(payload) {
  const defaultPayload = {
    q: null,
  };
  await this.getGroupList(payload || defaultPayload);
  return Promise.resolve();
}

export async function handleGetGroup() {
  const {
    params: { groupId },
  } = this.$route;
  if (!this.currentGroup || this.currentGroup.id !== +groupId) {
    const { success } = await this.getGroup(groupId);
    return Promise.resolve(success);
  }
  return Promise.resolve({ success: true });
}

export async function handleGetMembers() {
  const {
    params: { groupId },
  } = this.$route;
  // if (!this.members.length) {
  const { success } = await this.getMembers(groupId);
  return Promise.resolve(success);
  // }
  // return Promise.resolve({ success: true });
}

export async function handleGetMember() {
  const { clientId } = this.queries;
  if (this.member && this.member.id === +clientId) return Promise.resolve({ success: true });
  const success = await this.getMember(clientId);
  return Promise.resolve(success);
}

export async function handleGetReflections() {
  const {
    params: { groupId, questionnaireId },
  } = this.$route;

  // if (!this.groupReflectionList.length) {
  const { success } = await this.getReflectionsByGroup({
    id: groupId,
    questionnaireId,
    limit: 1000,
    offset: 0,
    fromDate: '2020-01-01',
  });
  return Promise.resolve(success);
  // }
  // return false
}

export async function handleSetReflection() {
  const {
    params: { reflectionId },
  } = this.$route;
  if (!this.groupReflectionList.length) return false;
  if (!reflectionId) {
    const { success } = await this.setCurrentReflection(this.groupReflectionList[0]);
    return Promise.resolve(success);
  }
  const reflection = this.groupReflectionList.find((reflection) => reflection.id === +reflectionId);
  const { success } = await this.setCurrentReflection(reflection);
  return Promise.resolve(success);
}

export async function handleGetQuestionnaire() {
  const {
    params: { questionnaireId },
  } = this.$route;
  const { success } = await this.getQuestionnaire({ questionnaireId });
  return Promise.resolve(success);
}

export async function handleGetQuestionnaires(success) {
  if (!success) {
    this.handleNotFound();
    return Promise.resolve();
  }
  await this.getMemberGroupList(this.queries.clientId);
  const questionnaires = await this.getQuestionnairesByGroup(this.queries);
  return Promise.resolve(questionnaires);
}

export async function handleGetMethods() {
  if (!this.questionTypeList.length) {
    const { success } = await this.getQuestionList();
    return Promise.resolve(success);
  }
  return Promise.resolve();
}

export async function handleGetQuestionnaireSchedule() {
  const {
    params: { questionnaireId },
  } = this.$route;
  const { success } = await this.getQuestionnaireSchedule(questionnaireId);
  return Promise.resolve(success);
}
