/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/color-pick': {
    width: 18.989,
    height: 18.99,
    viewBox: '0 0 18.989 18.99',
    data: '<g data-name="Group 3149"><path pid="0" data-name="Rectangle 4451" _fill="#e4f7ff" _stroke="#5454c4" stroke-linejoin="round" d="M.5.5h17.99v17.99H.5z"/></g>'
  }
})
