/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add_question': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g data-name="Group 3478"><g data-name="Group 3337" transform="translate(-562 -271)"><circle pid="0" data-name="Ellipse 1568" cx="9" cy="9" r="9" transform="translate(562 271)" _fill="#5147a7"/><g data-name="Group 84"><g data-name="Group 83" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"><path pid="1" data-name="Path 32" d="M570.824 275.925v3.9h-3.9"/><path pid="2" data-name="Path 33" d="M570.825 283.723v-3.9h3.9"/></g></g></g></g>'
  }
})
