/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/question-select': {
    width: 16,
    height: 16,
    viewBox: '0 0 33.127 33.127',
    data: '<circle pid="0" cx="16.563" cy="16.563" r="16.559" _fill="#6666ca"/><path pid="1" _fill="#fff" d="M9.675 12.171a3.614 3.614 0 0 1-2.477-.976 3.618 3.618 0 0 1-1.16-2.534 3.647 3.647 0 0 1 3.64-3.774 3.648 3.648 0 0 1 3.64 3.51V8.4c.035.971-.31 1.899-.973 2.61a3.613 3.613 0 0 1-2.67 1.162zm.004-6.233h-.094a2.597 2.597 0 0 0-2.497 2.685c.025.692.318 1.332.825 1.804s1.169.708 1.858.692c.693-.025 1.333-.317 1.805-.824s.718-1.167.693-1.86a2.596 2.596 0 0 0-2.59-2.497zM9.678 22.179a3.648 3.648 0 0 1-3.64-3.511 3.647 3.647 0 0 1 3.64-3.774 3.648 3.648 0 0 1 3.64 3.51v.001a3.647 3.647 0 0 1-3.64 3.774zm0-6.235l-.093.001a2.597 2.597 0 0 0-2.497 2.685c.052 1.428 1.274 2.555 2.684 2.497a2.597 2.597 0 0 0 2.497-2.685 2.596 2.596 0 0 0-2.59-2.498zM27.059 17.413H16.23a1.123 1.123 0 1 0 0 2.246H27.06a1.123 1.123 0 1 0 0-2.246zM27.059 7.406H16.23a1.123 1.123 0 1 0 0 2.246H27.06a1.123 1.123 0 1 0 0-2.246z"/><circle pid="2" _fill="#fff" cx="12.008" cy="26.381" r="1.333"/><circle pid="3" _fill="#fff" cx="16.563" cy="26.381" r="1.333"/><circle pid="4" _fill="#fff" cx="21.119" cy="26.381" r="1.333"/><g><path pid="5" _fill="#6666ca" d="M9.68 21.132a1.994 1.994 0 0 1-1.414-.586l-2.824-2.824a2 2 0 1 1 2.828-2.828l1.41 1.41 3.724-3.724a2 2 0 1 1 2.828 2.828l-5.137 5.138c-.391.39-.903.586-1.414.586z"/><path pid="6" _fill="#fff" d="M9.68 19.882a.747.747 0 0 1-.53-.22l-2.824-2.824a.75.75 0 1 1 1.06-1.06l2.295 2.293 4.607-4.607a.75.75 0 1 1 1.06 1.06l-5.137 5.138a.747.747 0 0 1-.53.22z"/></g>'
  }
})
