/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-settings': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M12.764 8.694c.029-.227.05-.453.05-.694 0-.24-.021-.468-.05-.694l1.495-1.169a.357.357 0 0 0 .085-.453l-1.417-2.451a.354.354 0 0 0-.432-.156l-1.763.708a5.176 5.176 0 0 0-1.198-.694l-.269-1.877a.345.345 0 0 0-.347-.297H6.085a.345.345 0 0 0-.347.297l-.27 1.877a5.442 5.442 0 0 0-1.197.694l-1.763-.708a.401.401 0 0 0-.128-.021.35.35 0 0 0-.304.177L.659 5.683a.35.35 0 0 0 .085.454l1.495 1.169c-.029.226-.05.46-.05.694 0 .234.021.467.05.694L.744 9.863a.357.357 0 0 0-.085.453l1.417 2.451a.354.354 0 0 0 .432.156l1.763-.708c.369.283.765.517 1.198.694l.269 1.877c.021.17.17.297.347.297h2.833a.345.345 0 0 0 .347-.297l.27-1.877a5.442 5.442 0 0 0 1.197-.694l1.763.708a.4.4 0 0 0 .128.021.35.35 0 0 0 .304-.177l1.417-2.45a.357.357 0 0 0-.085-.454l-1.495-1.169zm-1.402-1.211c.028.22.035.368.035.517 0 .149-.014.305-.035.517l-.1.8.631.496.765.595-.496.857-.9-.36-.736-.298-.637.481a4.147 4.147 0 0 1-.886.517l-.75.305-.114.8-.142.957h-.991l-.248-1.757-.751-.305a4.019 4.019 0 0 1-.871-.503l-.645-.495-.75.304-.9.362-.496-.858.765-.595.63-.495-.099-.8A5.698 5.698 0 0 1 3.606 8c0-.142.014-.305.035-.517l.1-.8-.631-.496-.765-.595.496-.857.9.36.736.298.637-.481c.305-.227.595-.397.886-.517l.75-.305.114-.8.142-.957h.984l.248 1.757.751.305c.305.127.588.29.871.502l.645.496.75-.304.9-.362.496.858-.758.602-.63.495.099.8zm-3.86-2.316a2.833 2.833 0 1 0 0 5.666 2.833 2.833 0 1 0 0-5.666zm0 4.25A1.42 1.42 0 0 1 6.084 8c0-.78.637-1.417 1.416-1.417.78 0 1.417.638 1.417 1.417a1.42 1.42 0 0 1-1.417 1.417z" _fill="#11AB99"/>'
  }
})
