<template>
  <div class="method">
    <div class="method__left">
      <svgicon :original="true" :name="`methods/${method.template}`" height="2.5em"></svgicon>
    </div>
    <div class="method__right">
      <div class="method__title">
        <div class="method__name">
          <span>
            <slot name="name" />
          </span>
        </div>
        <div class="method__time">
          <svgicon name="clock" :original="true" height="1em"></svgicon>
          <span>
            <slot name="time" />
          </span>
          <svgicon
            :name="method.allowUnlimitedPartCount ? 'methods/open' : 'methods/closed'"
            :original="true"
            height="1em"
          ></svgicon>
        </div>
      </div>
      <div class="method__actions" id="show-modal">
        <button-3 @click.native="selectMethod(method)">Apie Metodą</button-3>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="toggleModal" :method="method"></modal>
    <div class="coming-soon-tooltip" v-if="!methodActive">
      <span class="coming-soon-tooltip__text">Jau greitai!</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Modal from '@/components/methods/Modal.vue';

export default {
  name: 'MethodCard',
  components: {
    Button3,
    Modal,
  },
  props: ['method'],
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    methodActive() {
      let methodActive;
      const { template } = this.method;
      switch (template) {
        case 'target':
          methodActive = false;
          break;
        case 'cobeweb':
          methodActive = false;
          break;
        case 'palms':
          methodActive = false;
          break;
        case 'diary':
          methodActive = false;
          break;

        default:
          methodActive = true;
          break;
      }
      return methodActive;
    },
  },
  methods: {
    ...mapActions('methods', ['onMethodSelect']),
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    selectMethod(method) {
      this.onMethodSelect(method);
      this.toggleModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.method {
  display: flex;
  margin-top: 10px;
  padding: 15px;
  height: 88px;
  width: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  .method__left {
    display: flex;
    align-items: baseline;
  }
  .method__right {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .method__title {
      display: flex;
      justify-content: space-between;
      .method__name {
        font-family: Roboto-Medium;
        font-size: 14px;
        color: #505e67;

        &::first-letter {
          text-transform: capitalize;
        }
      }
      .method__time {
        display: flex;
        align-items: center;
        svg {
          &:first-of-type {
            margin-right: 5px;
          }
          &:last-of-type {
            margin-left: 5px;
          }
        }
        span {
          font-family: Roboto-Regular;
          font-size: 12px;
          color: #505e67;
        }
      }
    }
    .method__actions {
      margin-top: 10px;
      display: flex;
    }
  }

  .coming-soon-tooltip {
   
    padding: 4px 14px 4px 20px;
    background-color: $blue-3;
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: Nunito-SemiBold;
    font-size: 14px;
    color: #fff;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 26px solid #53bbf4;
      border-left: 17px solid #fff;
      width: 0;
    }
  }
}
</style>
