/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-question': {
    width: 68.373,
    height: 68.372,
    viewBox: '0 0 68.373 68.372',
    data: '<g data-name="Group 2052"><g data-name="Group 2045"><path pid="0" data-name="Path 1187" d="M66.087 25.96q.21.813.378 1.643A32.94 32.94 0 1 1 34.187 1.25a32.787 32.787 0 0 1 19.3 6.245" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/></g><g data-name="Group 2050" transform="translate(-28.952 -395.919)"><g data-name="Group 2051"><path pid="1" data-name="Path 1189" d="M50.043 423.345c0-7.212 6.967-13.068 13.924-13.068s12.6 5.218 12.6 11.654c0 4.561-2.839 7.988-7.045 10.471s-6.92 7.458-6.92 11.068v1.423" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5"/></g><circle pid="2" data-name="Ellipse 841" cx="2.447" cy="2.447" r="2.447" transform="translate(60.119 449.728)" _fill="#474eb9"/></g></g>'
  }
})
