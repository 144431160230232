/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page_prev': {
    width: 14.289,
    height: 13.646,
    viewBox: '0 0 14.289 13.646',
    data: '<g data-name="Group 3910"><g data-name="Group 3909"><g data-name="Group 3908"><g data-name="Group 3318" _fill="none" _stroke="#6666ca" stroke-linecap="round" stroke-width="1.5"><path pid="0" data-name="Line 136" stroke-miterlimit="10" d="M13.54 6.823H.751"/><path pid="1" data-name="Path 834" d="M6.512 12.585L.75 6.823l5.762-5.762" stroke-linejoin="round"/></g></g></g></g>'
  }
})
