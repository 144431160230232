<template>
  <div class="template__question" v-if="question && answerTemplate === 'select'">
    <div
      class="template__input-field"
      v-for="(option, i) in options"
      :key="`question--${number}_answerOptions--${i}`"
    >
      <svgicon
        v-if="type === 'color' && !option.show"
        class="template__color-toggle"
        name="methods/color-pick-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleColorPicker(i)"
      ></svgicon>
      <svgicon
        v-if="type === 'img' && !option.show"
        class="template__color-toggle"
        name="methods/image-pick-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleImageModal(i)"
      ></svgicon>
      <div
        class="template__color-show"
        v-if="type === 'img' && option.show"
        @click="toggleImageModal(i)"
      >
        <img class="template__color-show-color" :src="option.show" />
        <svgicon
          class="template__color-show-icon"
          name="methods/color-pick-close"
          :original="true"
        ></svgicon>
      </div>
      <div class="template__color-show" v-if="type === 'color' && option.show">
        <div
          class="template__color-show-color"
          :style="{ 'background-color': option.show }"
          @click="toggleColorPicker(i)"
        ></div>
        <svgicon
          v-if="edit"
          class="template__color-show-icon"
          name="methods/color-pick-close"
          :original="true"
        ></svgicon>
      </div>
      <auto-resize-input>
        <textarea
          :class="[
            'template__input-text',
            { 'template__input-text--color': type === 'color' || type === 'img', 'template__input-text--error' : option.error },
          ]"
          rows="1"
          type="text"
          @input="
            setQuestionPropertyHandler($event, { type: 'value', index: number, optionIndex: i })"
          :placeholder='$t("insert_value_placeholder")'
          :value="option.value"
          :disabled="!edit"
          :maxlength="inputLength"
          ref="answerValueRef"
        />
      </auto-resize-input>
      <svgicon
        v-if="options.length > 1 && i !== options.length - 1 && options.length !== 2 && edit"
        class="template__input-text__remove"
        name="multiply"
        :original="true"
        @click="handleRemoveOption(i)"
      ></svgicon>
      <span class="template__input-field__error" v-if="option.error">{{option.error}}</span>
      <span class="template__input-field__error" v-if="edit && (maxReached.length && maxReached[i].status)">{{ $t('warnings.max_char_count_reached_warning') }}</span>
    </div>
    <div class="picker-modal" v-show="isVisible" @click="toggleColorPicker">
      <div class="picker-modal__bg">
        <div class="picker-modal__modal" @click.stop>
          <div class="picker-modal__container">
            <svgicon
              @click="toggleColorPicker"
              color="grey"
              :fill="false"
              name="multiply"
              class="picker-modal__close"
            ></svgicon>
            <sketch-color-picker v-model="colors[i]" @input="colorChange"></sketch-color-picker>
            <button-3
              class="picker-modal__action"
              :disabled="!options[i].show"
              @click.native="toggleColorPicker"
              >{{ $t("actions.select_action") }}</button-3
            >
          </div>
        </div>
      </div>
    </div>
    <question-image-picker
      :type="'question'"
      v-show="showImageModal"
      @click="toggleImageModal"
      @closeImageModal="toggleImageModal"
      @onImageSelect="onImageSelect"
      :key="`image--${i}`"
      :label="`Image${images[i]}`"
      :selectedImages="selectedImages"
    ></question-image-picker>
  </div>
  <div class="template__question" v-else-if="question && answerTemplate === 'text'">
    <div class="template__input-field">
      <input
        :class="['input-box', 'input-box--small', {'input-box--error': questionErrors.maxLength}]"
        type="number"
        :placeholder='$t("insert_value_placeholder")'
        v-model="question.answerOptions.maxLength"
        @input="handleMaxLengthInput"
        @keydown="handleMaxLengthInputValidation($event)"
        :disabled="!edit"
        min="1"
        max="700"
      />
      <span class="template__input-field__error" v-if="questionErrors.maxLength">{{questionErrors.maxLength}}</span>
    </div>
  </div>
  <div class="template__question" v-else-if="question && answerTemplate === 'number'">
    <div class="template__input-field template__input-field--small">
      <div class="template__input-field__field">
        <span class="uppercase-title">{{ $t("from_cap") }}</span>
        <input
          :class="['input-box', 'input-box--small', {'input-box--error': questionErrors.min}]"
          type="number"
          :placeholder='$t("insert_value_placeholder")'
          v-model="question.answerOptions.min"
          @input="handleNumberInput($event, 'min')"
          min="1"
          :max="question.answerOptions.max"
           :disabled="!edit"
        />
        <span class="template__input-field__error" v-if="questionErrors.min">{{questionErrors.min}}</span>
      </div>
      <div class="template__input-field__field">
        <span class="uppercase-title">{{ $t("to_cap") }}</span>
        <input
          :class="['input-box', 'input-box--small', {'input-box--error': questionErrors.max}]"
          type="number"
          :placeholder='$t("insert_value_placeholder")'
          v-model="question.answerOptions.max"
          @input="handleNumberInput($event, 'max')"
          :min="question.answerOptions.min"
          :disabled="!edit"
        />
        <span class="template__input-field__error" v-if="questionErrors.max">{{questionErrors.max}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Sketch } from 'vue-color';

import QuestionImagePicker from '@/components/reflections/question/QuestionImagePicker.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import AutoResizeInput from '@/helpers/autoResizeInput';

export default {
  name: 'TemplateQuestion',
  components: {
    'sketch-color-picker': Sketch,
    QuestionImagePicker,
    Button3,
    AutoResizeInput
  },
  props: ['number', 'question', 'type', 'edit', 'questionExpanded'],
  data() {
    return {
      colors: ['#11ab99', '#ff3366', '#505f21'],
      images: [null],
      isVisible: false,
      showImageModal: false,
      i: 0,
      selectedImages: [],
      inputLength: 120
    };
  },
  created() {
    const { maxLength, errors } = this.question.answerOptions;
    if (!errors) this.question.answerOptions.errors = {};
    if (this.answerTemplate === 'text' && !maxLength) this.question.answerOptions.maxLength = 0;
    if (this.answerTemplate === 'select' && this.edit) {
      if (this.options[this.options.length - 1].value && this.edit) {
        this.options.push({ value: null, show: null });
      }
    }
  },
  updated() {
    if (this.answerTemplate === 'select' && this.edit) {
      if (this.options[this.options.length - 1].value && this.edit) {
        if (this.type === 'color') this.options.push({ value: null, show: '#26619c' });
        else this.options.push({ value: null, show: null });
      }
    }
  },
  watch: {
    questionExpanded(newVal){
      if(newVal === true && this.answerTemplate === 'select'){
        this.$refs.answerValueRef.forEach(element => {
          element.style.height = `auto`
          element.style.height = `${element.scrollHeight}px`
        });
      }
    },
    answerTemplate(newVal) {
      if (!this.question || !this.answerTemplate) return;
      this.question.answerOptions.type = newVal;
      switch (newVal) {
        case 'text':
          this.question.answerOptions = {
            maxLength: 500,
            errors: {},
          };
          break;
        case 'number':
          this.question.answerOptions = {
            min: 1,
            max: 100,
            errors: {},
          };
          break;
        case 'select':
          this.options = {
            options: [{ value: null, show: null }, { value: null, show: null }],
            type: 'text',
            view: 'box',
            errors: {},
          };
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire']),
    answerTemplate() {
      return this.question ? this.question.answerTemplate : null;
    },
    options: {
      get() {
        return this.question.answerOptions.options;
      },
      set(newVal) {
        this.question.answerOptions = newVal;
      },
    },
    maxReached: {
      get() {
        return this.options.map((option, index) => {
          return {
            index,
            option,
            status: (option.value && option.value.length === 120) || false
          }
        })
      },
      set(newVal) {
        this.maxReached = newVal;
      },
    },
    view() {
      return this.question.answerOptions.view;
    },
    questionErrors() {
      return this.questionnaire.questions[this.number].answerOptions.errors;
    },
  },
  methods: {
    ...mapActions('questionnaire', ['setQuestionProperty', 'setOptionError', 'setQuestionError', 'setAnswerOptionError', 'setQuestionErrorCount', 'questionErrorCount']),
    toggleColorPicker(i) {
      if (!this.edit) return;
      this.isVisible = !this.isVisible;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    colorChange(color) {
      this.options[this.i].show = color.hex;
    },
    toggleImageModal(i) {
      if (!this.edit) return;
      this.showImageModal = !this.showImageModal;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    onImageSelect(image) {
      this.images[this.i] = image.url;
      this.options[this.i].show = image.url;
      if (this.selectedImages[this.i]) this.selectedImages.splice(this.i, 1, image);
      else this.selectedImages.push(image);
    },
    setQuestionPropertyHandler($event, payload) {
      const { optionIndex } = payload;
      let { value } = $event.target;
      if (value) value = value.trim();
      if (!value) {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: this.$t('warnings.finish_typing')
        });
      }else if(value.length === this.inputLength){
        this.maxReached[optionIndex].status = true
      }else{
        delete this.options[optionIndex].error
        this.maxReached[optionIndex].status = false
      }
      payload.property = value;
      this.setQuestionProperty(payload);
      if (this.type === 'text') {
        payload.type = 'show';
        this.setQuestionProperty(payload);
      }
    },
    handleInputValidation(event) {
      const regex = new RegExp('^[a-zA-Z \\b]+$');
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
      }
    },
    handleOptionInput(event, i) {
      const { value } = event.target;
      if (this.options[i].value && this.options[i].value.length && !this.options[i + 1] && this.edit) {
        if (this.type === 'color') {
          this.options.push({ value: null, show: '#26619c' });
          this.colors.push('#26619c');
        } else this.options.push({ value: null, show: null });
      }
      if (!value && this.options[i + 1]) {
        this.options.splice(i + 1, 1);
        if (this.type === 'color') this.colors.splice(i + 1, 1);
        if (this.type === 'img') this.images.splice(i + 1, 1);
      }
    },
    handleNumberInput(event, type) {
      const { value } = event.target;
      const { min, max } = this.question.answerOptions;
      const questionIndex = this.number;
      const optionKey = type;
      if (!this.questionErrorCount) this.setQuestionErrorCount(0);
      let errorCount = 0;

      if (!value) {
        errorCount += 1;
        this.setAnswerOptionError({ questionIndex, optionKey, error: this.$t('warnings.finish_typing') });
        this.setQuestionErrorCount(errorCount);
      } else {
        if (errorCount !== 0) errorCount -= 1;
        this.setAnswerOptionError({ questionIndex, optionKey, error: null });
        this.setQuestionErrorCount(errorCount);
      }

      if (type === 'min') {
        if (value > max) {
          errorCount += 1;
          this.setAnswerOptionError({ questionIndex, optionKey, error: this.$t('warnings.no_more_than_max_warning') });
          this.setQuestionErrorCount(errorCount);
        } else {
          if (errorCount !== 0) errorCount -= 1;
          this.setQuestionErrorCount(errorCount);
          this.setAnswerOptionError({ questionIndex, optionKey, error: null });
        }
      } else if (type === 'max') {
        if (value < min) {
          errorCount += 1;
          this.setAnswerOptionError({ questionIndex, optionKey, error: this.$t('warnings.no_less_than_min_warning') });
          this.setQuestionErrorCount(errorCount);
        } else {
          if (errorCount !== 0) errorCount -= 1;
          this.setAnswerOptionError({ questionIndex, optionKey, error: null });
          this.setQuestionErrorCount(errorCount);
        }
      }
    },
    handleRemoveOption(j) {
      this.options.splice(j, 1);
      if (this.type === 'color') this.colors.splice(j, 1);
      if (this.type === 'img') this.images.splice(j, 1);
      this.i = 0;
    },
    handleMaxLengthInput(e) {
      const { value } = e.target;
      const questionIndex = this.number;
      const optionKey = 'maxLength';
      if (!value || value <= 0) {
        this.setAnswerOptionError({ questionIndex, optionKey, error: this.$t('warnings.finish_typing') });
      } else if(value > 700) {
        this.setAnswerOptionError({ questionIndex, optionKey, error: this.$t('warnings.cant_exceed_700_message') });
      } else this.setAnswerOptionError({ questionIndex, optionKey, error: null });
    },
    handleMaxLengthInputValidation(event){
      const { keyCode } = event;
      if (!((keyCode > 95 && keyCode < 106) || (keyCode > 47 && keyCode < 58) || keyCode === 8)) {
        event.preventDefault();
      }
    }
  },
};
</script>
<style lang="scss">
.template {
  &__question {
    .template__input-field {
      position: relative;

      .template__color-toggle {
        top: 6px;
        position: absolute;

        &:hover {
          cursor: pointer;
        }
      }
      .template__input-text {
        height: initial;
        min-height: 30px;
        &--color {
          padding-left: 40px;
        }
      }
      .template__input-field__error{
        position: initial;
      }
    }
  }

  &__color-show {
    display: flex;
    width: 36px;
    height: 18px;
    position: absolute;
    top: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &__color-show-color {
    width: 18px;
    height: 100%;
    border: 0.5px solid $violet-6;
    border-right: none;

  }

  &__color-show-icon {
    width: 18px;
    height: 100%;
  }
}
</style>
