/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-users': {
    width: 15,
    height: 10,
    viewBox: '0 0 15 10',
    data: '<path pid="0" d="M5.375 6.24c-1.657 0-4.958.828-4.958 2.479v1.24h9.916v-1.24c0-1.65-3.3-2.48-4.958-2.48zm-3.3 2.301c.594-.41 2.032-.885 3.3-.885 1.268 0 2.706.475 3.3.885h-6.6zM5.374 5a2.482 2.482 0 0 0 2.48-2.48A2.482 2.482 0 0 0 5.374.043a2.482 2.482 0 0 0-2.48 2.479A2.482 2.482 0 0 0 5.376 5zm0-3.542c.588 0 1.063.475 1.063 1.063s-.475 1.062-1.063 1.062a1.061 1.061 0 0 1-1.062-1.062c0-.588.474-1.063 1.062-1.063zm4.987 4.824c.821.595 1.388 1.388 1.388 2.437v1.24h2.833v-1.24c0-1.431-2.479-2.246-4.221-2.437zM9.625 5a2.482 2.482 0 0 0 2.48-2.48A2.482 2.482 0 0 0 9.624.043a2.44 2.44 0 0 0-1.062.247c.446.63.708 1.403.708 2.232 0 .828-.262 1.6-.708 2.23.325.157.68.249 1.062.249z" _fill="#11AB99"/>'
  }
})
