/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-type-teacher': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M8.5 2.4a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H2.4v-1.1c0-.64 3.13-2.1 6.1-2.1zM8.5.5c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z" _fill="#919396" fill-opacity=".5"/>'
  }
})
