/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/faces': {
    width: 43.271,
    height: 43.271,
    viewBox: '0 0 43.271 43.271',
    data: '<g data-name="Group 1115" transform="translate(-56.963 -554.036)"><circle pid="0" data-name="Ellipse 166" cx="16.559" cy="16.559" r="16.559" transform="rotate(-22.5 1453.003 140.169)" _fill="#6666ca"/><path pid="1" data-name="Path 745" d="M86.474 577.621a8.112 8.112 0 1 1-5.93-9.821 8.113 8.113 0 0 1 5.93 9.821z" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"/><path pid="2" data-name="Path 746" d="M76.587 574.319a.827.827 0 1 1-.827-.827.827.827 0 0 1 .827.827z" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"/><path pid="3" data-name="Path 747" d="M82.264 574.319a.827.827 0 1 1-.827-.827.827.827 0 0 1 .827.827z" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"/><path pid="4" data-name="Path 748" d="M76.282 578.064a2.316 2.316 0 1 0 4.632 0z" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"/></g>'
  }
})
