import { render, staticRenderFns } from "./Paragraph.vue?vue&type=template&id=8ba1cf6e&scoped=true&"
import script from "./Paragraph.vue?vue&type=script&lang=js&"
export * from "./Paragraph.vue?vue&type=script&lang=js&"
import style0 from "./Paragraph.vue?vue&type=style&index=0&id=8ba1cf6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ba1cf6e",
  null
  
)

export default component.exports