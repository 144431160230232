/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'excel': {
    width: 21.908,
    height: 20.605,
    viewBox: '0 0 21.908 20.605',
    data: '<g data-name="Group 3243"><g data-name="Group 2121"><g data-name="Group 2120" _stroke="#5147a7" stroke-miterlimit="10"><path pid="0" data-name="Path 1208" d="M21.408 3.77v13.068a.654.654 0 0 1-.653.653h-8.494V3.117h8.494a.654.654 0 0 1 .653.653z" _fill="#fff"/><path pid="1" data-name="Path 1209" d="M12.024.654a.642.642 0 0 0-.537-.138L1.033 2.476a.651.651 0 0 0-.533.641v14.374a.653.653 0 0 0 .533.642l10.454 1.96a.616.616 0 0 0 .12.012.653.653 0 0 0 .653-.653V1.157a.652.652 0 0 0-.236-.503z" _fill="#fff"/><path pid="2" data-name="Line 1305" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M3.99 7.2l5.657 6.372"/><path pid="3" data-name="Line 1306" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M9.395 6.523l-5.789 7.41"/><path pid="4" data-name="Line 1307" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M12.779 6.221h2.624"/><path pid="5" data-name="Line 1308" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M12.779 8.943h2.624"/><path pid="6" data-name="Line 1309" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M12.779 11.665h2.624"/><path pid="7" data-name="Line 1310" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M12.779 14.387h2.624"/><path pid="8" data-name="Line 1311" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M17.625 6.221h.901"/><path pid="9" data-name="Line 1312" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M17.625 8.943h.901"/><path pid="10" data-name="Line 1313" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M17.625 11.665h.901"/><path pid="11" data-name="Line 1314" _fill="none" stroke-linecap="round" stroke-width="1.3" d="M17.625 14.387h.901"/></g></g></g>'
  }
})
