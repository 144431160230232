<template>
  <div class="template__header">
    <div class="template__title">
      <header-h1>{{ pageTitle[0] }}</header-h1>
      <router-link v-if="type === 'draft'" :to="{ name: 'CreateReflection' }">
        <button-7>{{ $t('actions.new_questionnaire_action') }}</button-7>
      </router-link>
    </div>
    <paragraph>{{ pageTitle[1] }}</paragraph>
    <div class="template__buttons">
      <!-- <switch-nav
        :key="'TemplateNav'"
        :links="[
          { link: { name: 'Templates' }, description: 'Ruošinių duomenų bazė' },
          { link: { name: 'TemplatePersonal' }, description: 'Mano ruošiniai' },
          { link: { name: 'TemplatesDraft' }, description: 'Klausimynai' },
        ]"
      ></switch-nav>-->
      <!-- <switch-nav
        :key="'TemplateNav'"
        :links="[
          { link: { name: 'Templates' }, description: 'Ruošinių duomenų bazė' },
          { link: { name: 'TemplatesDraft' }, description: 'Klausimynai' }
        ]"
      ></switch-nav> -->
    </div>
    <div class="template__search" :class="{ 'public-templates': !type }">
      <div v-if="!type" class="public-template-filter-wrapper">
        <button
          class="filter-button"
          :class="{ activeFilter: this.selectedTemplateType === 'filter' }"
          @click="handleTypeSelect('filter')"
        >
          {{ $t('templates.public_reflectus_questionnaires') }}
        </button>
        <button
          class="filter-button"
          :class="{ activeFilter: this.selectedTemplateType === 'exclude' }"
          @click="handleTypeSelect('exclude')"
        >
          {{ $t('templates.public_community_questionnaires') }}
        </button>
      </div>

      <div class="template__search__search-bar" v-if="type">
        <input
          type="text"
          :placeholder="$t('questionnaire_search_placeholder')"
          :value="templateSearch"
          v-debounce:1000="handleTemplateSearch"
        />
        <svgicon
          name="search"
          :original="true"
          height="1em"
          :fill="false"
          :color="'#0F9F8F'"
        ></svgicon>
      </div>
      <!-- <div class="template__search__types" v-if="type === 'draft'">
        <button
        @click="templateSelect('all')"
          :class="[
            'template__search__types__type',
            { 'template__search__types__type--active': displayTemplates === 'all' },
          ]"
        >
          Visi
        </button>
        <button
        @click="templateSelect('draft')"
          :class="[
            'template__search__types__type',
            { 'template__search__types__type--active': displayTemplates === 'draft' },
          ]"
        >
          Juodraščiai
        </button>
        <button
        @click="templateSelect('active')"
          :class="[
            'template__search__types__type',
            { 'template__search__types__type--active': displayTemplates === 'active' },
          ]"
        >
          Siųsti klausimynai
        </button>
      </div>-->
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
  import Paragraph from '@/components/elements/typography/Paragraph.vue';
  import Button7 from '@/components/elements/buttons/Button7.vue';
  // import SwitchNav from '@/components/Menu/SwitchNav.vue';

  export default {
    name: 'TemplatesHeader',
    components: {
      HeaderH1,
      Paragraph,
      Button7,
      // SwitchNav,
    },
    props: ['type'],
    computed: {
      ...mapGetters('reflections', [
        'templatesPrivate',
        'templatesPublic',
        'templatesAll',
        'displayTemplates',
      ]),
      ...mapGetters('meta', ['templateSearch']),
      ...mapGetters('reflections', ['selectedTemplateType']),
      showSearchBar() {
        let display = false;
        const routeName = this.$route.name;
        switch (routeName) {
          case 'TemplatePersonal':
            display = this.templatesPrivate.length;
            break;
          case 'Templates':
            display = this.templatesPublic.length;
            break;
          case 'TemplatesDraft':
            display = this.templatesAll;
            break;
          default:
            break;
        }
        return display;
      },
      pageTitle() {
        const title = ['', ''];
        const routeName = this.$route.name;
        switch (routeName) {
          case 'TemplatePersonal':
            title[0] = this.$t('my_templates');
            title[1] = this.$t('templates.personal_templates_description');
            break;
          case 'Templates':
            title[0] = this.$t('templates.public_questionnaire_templates');
            title[1] = this.$t('templates.questionnaire_templates_description');
            break;
          case 'TemplatesDraft':
            title[0] = this.$t('my_questionnaires');
            title[1] = this.$t('questionnaire.my_questionnaire_description');
            break;
          default:
            break;
        }
        return title;
      },
    },
    methods: {
      ...mapActions('meta', ['setTemplateSearch']),
      ...mapActions('reflections', ['setTemplateDisplay', 'setTemplateType']),
      templateSelect(type) {
        this.$emit('templateSelect', type);
        this.setTemplateDisplay(type);
      },
      handleTemplateSearch(value) {
        this.setTemplateSearch(value);
        this.$emit('handleTemplateSearch', value);
      },
      handleTypeSelect(type) {
        this.setTemplateSearch('');
        this.setTemplateType(type);
        this.$emit('handleTypeSelect', type);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .template__header {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;

    .template__title {
      position: relative;
      display: flex;
      align-items: center;

      button {
        position: absolute;
        width: 196px;
        top: 0;
        left: 170%;
      }
    }

    p {
      margin-top: 20px;
    }

    .template__buttons {
      margin-top: 20px;
    }

    .template__buttons {
      a.router-link-active {
        button {
          background: $green-3;
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .template__search {
      display: flex;
      align-items: center;
      &.public-templates {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .public-template-filter-wrapper {
          display: flex;
          flex-direction: row;
          .filter-button {
            padding: 4px 16px;
            font-family: 'Roboto-Medium';
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            border: 1px solid #b5b5b5;
            border-radius: 5px;
            background: rgba(132, 132, 132, 0.1);
            color: #888;
            cursor: pointer;
            &:first-child {
              margin-right: 16px;
            }
            &.activeFilter {
              border: 1px solid $orange;
              background: rgba(239, 166, 32, 0.1);
              color: $orange;
            }
          }
        }
      }

      &__search-bar {
        position: relative;
        background-color: #fff;
        input {
          padding: 10px;
          height: 28px;
          width: 256px;
          border: none;

          &::placeholder {
            color: $grey-2;
          }
        }

        svg {
          position: absolute;
          top: 20%;
          right: 4%;
          cursor: pointer;
        }
      }

      &__types {
        display: flex;
        margin-left: 20px;

        &__type {
          padding: 8px 16px;
          border: 1px solid $grey-2;
          border-radius: 3px;
          color: $grey-2;
          font-family: Roboto-Regular;
          font-size: 14px;
          background: none;
          cursor: pointer;

          &:not(:first-of-type) {
            margin-left: 10px;
          }

          &:hover {
            color: $green-3;
            border-color: $green-3;
          }

          &--active {
            color: $green-3;
            border-color: $green-3;
            cursor: initial;
          }
        }
      }
    }
  }
</style>
