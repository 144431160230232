<template>
  <div class="personal-licences">
    <p class="personal-licences__title">{{ $t('licences.my_licences') }}</p>
    <div v-if="licences && licences.length">
      <licence-card v-for="licence in licences" :key="licence.id" :licence="licence" />
    </div>
    <div v-else class="personal-licences__empty">
      <p>{{ $t('licences.no_personal_licences_message') }}</p>
      <p class="small">{{ $t('licences.personal_licences_error_message') }}</p>
    </div>
  </div>
</template>
<script>
  import LicenceCard from '@/components/user/licences/LicenceCard.vue';

  export default {
    name: 'MyLicences',
    components: {
      LicenceCard,
    },
    data() {
      return {
        licences: [
          // inactive yearly
          // {
          //   id: 0,
          //   title: 'Mokymų platforma',
          //   type: 0,
          //   durationType: 2,
          //   customer: 'Mindaugas Leskauskas',
          //   validUntil: '2022-10-15',
          //   price: 45.99,
          //   nextPayment: '2022-12-30',
          // },
        ],
      };
    },
    computed: {},
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .personal-licences {
    color: $grey-9;
    &__empty {
      p {
        font-size: 14px;
        font-weight: 400;
        &.small {
          font-size: 12px;
          font-weight: 300;
          color: #888888;
        }
      }
    }
  }
</style>
