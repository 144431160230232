/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 35.695,
    height: 35.695,
    viewBox: '0 0 35.695 35.695',
    data: '<g data-name="Group 1955"><g data-name="Group 1480" _fill="none" _stroke="#3b328b"><g data-name="Ellipse 281" transform="translate(-.001 -.001)"><circle pid="0" cx="17.847" cy="17.847" r="17.847" _stroke="none"/><circle pid="1" cx="17.847" cy="17.847" r="17.347"/></g><g data-name="Group 1454" stroke-linecap="round" stroke-miterlimit="10"><path pid="2" data-name="Path 32" d="M17.939 10.381v7.645h-7.646"/><path pid="3" data-name="Path 33" d="M17.939 25.672v-7.646h7.646"/></g></g></g>'
  }
})
