/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-smile': {
    width: 16,
    height: 16,
    viewBox: '0 0 38.07 38.07',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="19.04" cy="19.04" r="19.04" _fill="#ffd053"/><path pid="1" d="M29 2.79A19 19 0 0 1 3 29.27 19 19 0 1 0 29 2.79z" _fill="#ffc448"/><path pid="2" d="M2.59 21a17.67 17.67 0 0 1 31.68-10.73A17.67 17.67 0 1 0 4.94 29.85 17.59 17.59 0 0 1 2.59 21z" _fill="#ffd77a"/><path pid="3" d="M11.23 21.92a.63.63 0 0 0-1 .69 9.54 9.54 0 0 0 17.65.18.63.63 0 0 0-1-.69c-2.82 2.68-8.74 6.53-15.65-.18z" _fill="#654735"/><ellipse pid="4" cx="12.02" cy="15.63" rx="1.4" ry="2.36" _fill="#2c2c2c"/><ellipse pid="5" cx="26.05" cy="15.63" rx="1.4" ry="2.36" _fill="#2c2c2c"/></g></g>'
  }
})
