<template>
  <h1>
    <slot/>
  </h1>
</template>
<script>
export default {
  name: 'HeaderH1',
};
</script>
<style lang="scss">
h1 {
  margin: 0;
  padding: 0;
  color: $green-4;
  text-transform: uppercase;
  font-family: Nunito-SemiBold;
  font-size: 18px;
  letter-spacing: 0.8px;
}
</style>
