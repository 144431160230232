/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 11.179,
    height: 14.419,
    viewBox: '0 0 11.179 14.419',
    data: '<g data-name="Group 1959"><g data-name="Group 1627" _fill="none" _stroke="#505e67" stroke-linejoin="round"><path pid="0" data-name="Path 1096" d="M10.679 4.485v8.847a.587.587 0 0 1-.587.587H1.088a.587.587 0 0 1-.587-.587V1.087A.587.587 0 0 1 1.088.5h5.608"/><path pid="1" data-name="Path 1097" d="M6.694.5v3.445a.539.539 0 0 0 .539.539h3.445z"/><path pid="2" data-name="Line 199" stroke-linecap="round" d="M2.367 11.256h6.445"/><path pid="3" data-name="Line 200" stroke-linecap="round" d="M2.367 8.981h6.445"/><path pid="4" data-name="Line 201" stroke-linecap="round" d="M2.367 6.705h6.445"/></g></g>'
  }
})
