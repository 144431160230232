<template>
  <h2 class="header-h2">
    <slot />
  </h2>
</template>
<script>
export default {
  name: 'HeaderH2',
};
</script>
<style lang="scss">
.header-h2, h2 {
    color: $violet-5;
    font-family: Nunito-Bold;
    font-size: 16px;
    border-bottom: 0;
    font-weight: inherit;
}
</style>
