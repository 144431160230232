/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-switch-inactive': {
    width: 16,
    height: 16,
    viewBox: '0 0 27 25',
    data: '<mask id="svgicon_calendar-switch-inactive_a" fill="#fff"><rect pid="0" x="15" y="12" width="9" height="9" rx="1"/></mask><rect pid="1" width="27" height="25" rx="2" _fill="transparent"/><path pid="2" stroke-width="2" stroke-linecap="round" _stroke="#5147a7" d="M4 20h1M10 20h1M4 13h1M10 13h1M4 6h1M10 6h1M16 6h1M22 6h1"/><rect pid="3" x="15" y="12" width="9" height="9" rx="1" stroke-width="4" mask="url(#svgicon_calendar-switch-inactive_a)" _stroke="#5147a7"/>'
  }
})
