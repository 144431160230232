<template>
  <div class="smileys-modal" @click="close">
    <div class="smileys-modal__bg">
      <div class="smileys-modal__modal" @click.stop>
        <div class="smileys-modal__container">
          <svgicon
            @click="close"
            color="grey"
            :fill="false"
            name="multiply"
            class="smileys-modal__close"
          ></svgicon>
          <div class="smileys-modal__gallery">
            <div
              class="smileys-modal__gallery__item"
              v-for="(smiley, key) in filteredSmileys"
              :key="smiley.id"
            >
              <div
                :class="[
                  'smileys-modal__gallery__item__icon',
                  { 'smileys-modal__gallery__item__icon--selected': selected === key },
                ]"
              >
                <img :src="smiley.url" :alt="smiley.title" @click="selectSmiley(key)" />
              </div>
              <span class="smileys-modal__gallery__item__name">
                {{ smiley.title }}
              </span>
            </div>
          </div>
          <div class="smileys-modal__actions">
            <button-3 @click.native="close">{{ $t("actions.cancel_action") }}</button-3>
            <button-4 @click.native="submitSmiley">{{ $t("actions.select_action") }}</button-4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'SmileysModal',
  components: {
    Button3,
    Button4,
  },
  props: ['selectedSmileys', 'smileyOptions'],
  data() {
    return {
      selected: null,
      selectedSmiley: null,
    };
  },
  created() {
    this.getGallery('faces');
  },
  computed: {
    ...mapGetters('meta', ['smileys']),
    filteredSmileys() {
      const filteredSmileys = this.smileys.filter(
        (smiley) => !this.smileyOptions.find((item) => item.value === smiley.title),
      );
      return filteredSmileys;
    },
  },
  methods: {
    ...mapActions('meta', ['getGallery']),
    close() {
      this.$emit('close');
    },
    selectSmiley(key) {
      this.selected = key;
      const smiley = this.filteredSmileys[key];
      this.selectedSmiley = smiley;
    },
    submitSmiley() {
      this.$emit('selectedSmiley', this.selectedSmiley);
      this.close();
    },
  },
};
</script>

<style lang="scss">
@import 'mixins';
.smileys-modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &__container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    max-width: 460px;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-height: 280px;
    overflow-y: auto;
    width: 425px;

    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: red;
      border-radius: 10px;
    }

    &__item {
      padding: 6px 6px 12px 6px;
      background-color: #f8f8f8;
      width: 94px;
      height: 120px;

      &__icon {
        background-color: #ececec;
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.25) translateY(-3px) rotate(3deg);
          }
        }

        &--selected {
          background-color: $green-3;
          border-radius: 15%;
        }
      }

      &__name {
        font-size: 12px;
      }

      &:not(:nth-of-type(4n + 1)) {
        margin-left: 10px;
      }

      &:not(:nth-of-type(-n + 4)) {
        margin-top: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8));
    }
  }

  &__actions {
    margin-top: 20px;

    button {
      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
</style>
