/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-warning-big': {
    width: 142,
    height: 90,
    viewBox: '0 0 142 90',
    data: '<path pid="0" d="M79.221 9.164L76.02 3.618c-.77-1.333-2.694-1.333-3.464 0L25.35 85.382c-.77 1.333.192 3 1.732 3h94.471c1.526 0 2.49-1.641 1.746-2.974L103.87 50.58" _stroke="#474EB9" stroke-width="3" stroke-linecap="round"/><path pid="1" d="M1.783 69.303l5.448-8.915 5.449 8.915H1.783zM29.394 12.766l5.449-8.915 5.448 8.915H29.394zM129.32 56.155l5.449-8.916 5.448 8.916H129.32z" _stroke="#474EB9" stroke-width="2" stroke-linecap="round"/><path pid="2" _stroke="#474EB9" stroke-width="10" stroke-linecap="round" d="M93.093 13.507v13.667M93.093 41.118v.519"/>'
  }
})
