/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 16,
    height: 16,
    viewBox: '0 0 12.39 11.26',
    data: '<g data-name="Layer 2"><path pid="0" d="M10.75 1.65H8.93L7.9.5H4.49l-1 1.15H1.64A1.14 1.14 0 0 0 .5 2.78v6.83a1.14 1.14 0 0 0 1.14 1.15h9.11a1.14 1.14 0 0 0 1.13-1.15V2.78a1.13 1.13 0 0 0-1.13-1.13zm-4.56 6.8a2.29 2.29 0 1 1 2.3-2.29 2.29 2.29 0 0 1-2.3 2.29z" _fill="none" _stroke="#5454c6" stroke-linejoin="round" data-name="Layer 1"/></g>'
  }
})
