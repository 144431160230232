<template>
  <div class="my-groups__list-item flex-global" v-if="group">
    <div class="my-groups__list-info-wrapper">
      <div v-if="isFromList" class="top">
        <router-link :to="{ name: 'Group', params: { groupId: group.id } }">
          <header-h4 class="title">{{ groupTitle }}</header-h4>
        </router-link>
        <header-h4 class="subtitle">{{ groupMemberTitle }}</header-h4>
      </div>
      <div class="top" v-else>
        <div class="group-title-wrapper">
          <p class="grey-subtitle">
            {{ $t('group.group_title_self') }}
            <svgicon
              name="info-circle-grey"
              height="15px"
              width="15px"
              v-tooltip="$t('group.group_title_self_tooltip')"
            ></svgicon>
          </p>
          <div class="title-edit flex-global" @click="toggleNameChangeModal('main')">
            <span>{{ groupTitle }}</span>
            <div class="icon-wrapper flex-global">
              <svgicon name="edit" height="12px" width="12px" :fill="false"></svgicon>
            </div>
          </div>
        </div>
        <div class="group-title-wrapper">
          <p class="grey-subtitle">
            {{ $t('group.group_title_user') }}
            <svgicon
              name="info-circle-grey"
              height="15px"
              width="15px"
              v-tooltip="$t('group.group_title_user_tooltip')"
            ></svgicon>
          </p>
          <div class="title-edit flex-global" @click="toggleNameChangeModal('member')">
            <span>{{ groupMemberTitle }}</span>
            <div class="icon-wrapper flex-global">
              <svgicon name="edit" height="12px" width="12px" :fill="false"></svgicon>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="grey-subtitle">
          <svgicon name="profile-circle-grey" height="15px" width="15px"></svgicon>
          {{ $t('group.group_active_questionnaires') }}: {{ groupActiveQuestCount }}
        </p>
        <p class="grey-subtitle">
          <svgicon name="question-circle-grey" height="15px" width="15px"></svgicon>
          {{ $t('group.group_members_online') }}: {{ groupMembersCount }}
        </p>
      </div>
    </div>
    <div class="my-groups__list-token-wrapper">
      <p class="grey-subtitle">
        {{ $t('group.login_token') }}
        <svgicon
          name="info-circle-grey"
          height="15px"
          width="15px"
          v-tooltip="$t('group.login_token_tooltip')"
        ></svgicon>
      </p>
      <div class="token-display-wrapper flex-global">
        <div class="token-display flex-global">
          <span>{{ activeGroupToken || group.token }}</span>
        </div>
        <div class="refresh-button flex-global" @click="toggleTokenRefreshModal">
          <svgicon name="refresh-green" height="17px" width="17px"></svgicon>
        </div>
      </div>
      <div class="token-toggle-wrapper flex-global">
        <p class="grey-subtitle">{{ $t('group.group_ability_to_connect') }}</p>
        <toggle
          class="token-toggle"
          :boxValue="'isActiveToken'"
          :checked="isActiveToken"
          @checkStatus="checkStatus"
        ></toggle>
      </div>
      <name-change-modal
        v-if="nameChangeModal && !isFromList"
        :loading="nameChangeLoading"
        :disableButton="!editTitle || !editMemberTitle"
        @toggleModal="toggleNameChangeModal"
        @handleNameChangeAction="handleNameChange"
      >
        <template v-slot:name-change-form>
          <div class="info-field flex-global" v-if="modalType === 'main'">
            <span class="uppercase-title">{{ $t('group.group_title_self') }}</span>
            <input :class="['info-field__input']" type="text" v-model="editTitle" />
          </div>
          <div class="info-field flex-global" v-else>
            <span class="uppercase-title">{{ $t('group.group_title_user') }}</span>
            <input :class="['info-field__input']" type="text" v-model="editMemberTitle" />
          </div>
        </template>
      </name-change-modal>
      <global-modal
        class="token-refresh-modal"
        v-if="tokenRefreshModal"
        @handleModalToggle="toggleTokenRefreshModal"
      >
        <template v-slot:modal-main-icon>
          <svgicon class="modal__container__icon" name="large-question" :original="true"></svgicon>
        </template>
        <template v-slot:modal-header>{{ $t('group.group_token_confirm_message') }}</template>
        <template v-slot:modal-actions>
          <button-3 @click.native="toggleTokenRefreshModal">
            {{ $t('actions.cancel_action') }}
          </button-3>
          <button-4 @click.native="handleTokenRefresh">{{ $t('yes_cap') }}</button-4>
        </template>
      </global-modal>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
  import Toggle from '@/components/elements/Toggle.vue';
  import NameChangeModal from '@/components/base/NameChangeModal.vue';
  import GlobalModal from '@/components/base/GlobalModal.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import { handleGetGroupList } from '@/helpers/groups.helpers';

  export default {
    name: 'GroupListItem',
    components: {
      HeaderH4,
      Toggle,
      NameChangeModal,
      GlobalModal,
      Button3,
      Button4,
    },
    props: ['group', 'isFromList'],
    data() {
      return {
        activeToken: false,
        nameChangeModal: false,
        tokenRefreshModal: false,
        modalType: null,
        nameChangeLoading: false,
        editTitle: '',
        editMemberTitle: '',
        activeGroupToken: null,
      };
    },
    computed: {
      ...mapGetters('meta', ['groupSearch']),
      groupId() {
        return this.group.id;
      },
      groupTitle() {
        return this.group ? this.group.title : '-';
      },
      groupMemberTitle() {
        return this.group ? this.group.memberTitle : '-';
      },
      groupActiveQuestCount() {
        return this.group ? this.group.activeQuestionnairesCount : '-';
      },
      groupMembersCount() {
        return this.group ? this.group.groupMembersCount : '-';
      },
      isActiveToken() {
        return !!this.group.tokenValidationDate;
      },
      searchQuery() {
        return this.groupSearch ? { q: this.groupSearch } : null;
      },
    },
    methods: {
      ...mapActions('groups', [
        'getGroupList',
        'updateGroup',
        'getToken',
        'enableToken',
        'disableToken',
      ]),
      handleGetGroupList,
      checkStatus(value) {
        const { checked } = value;
        if (checked) {
          this.handleEnableToken();
        } else {
          this.handleDisableToken();
        }
      },
      handleEnableToken() {
        this.enableToken(this.groupId).then(() => {
          this.handleGetGroupList(this.searchQuery);
        });
      },
      handleDisableToken() {
        this.disableToken(this.groupId).then(() => {
          this.handleGetGroupList(this.searchQuery);
        });
      },
      toggleTokenRefreshModal() {
        this.tokenRefreshModal = !this.tokenRefreshModal;
      },
      handleTokenRefresh() {
        this.getToken(this.groupId).then((payload) => {
          const { data } = payload;
          this.activeGroupToken = data.token;
          this.toggleTokenRefreshModal();
        });
      },
      handleNameChange() {
        this.nameChangeLoading = true;
        const payload = {
          title: this.editTitle,
          memberTitle: this.editMemberTitle,
          id: this.group.id,
        };
        this.updateGroup(payload).then(() => {
          setTimeout(() => {
            this.toggleNameChangeModal(null);
            this.nameChangeLoading = false;
          }, 2000);
        });
      },
      toggleNameChangeModal(type) {
        this.modalType = type;
        this.nameChangeModal = !this.nameChangeModal;

        if (this.nameChangeModal) {
          this.editTitle = this.group.title;
          this.editMemberTitle = this.group.memberTitle;
        }
      },
    },
  };
</script>

<style lang="scss">
  .my-groups {
    &__list {
      &-item {
        width: 100%;
        margin-bottom: 20px;
        padding: 30px;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        text-transform: uppercase;
        background: white;
        .grey-subtitle {
          font-family: 'Nunito-Bold';
          font-size: 10px;
          font-weight: bold;
          line-height: 14px;
          letter-spacing: 0.5px;
          color: $grey-3;
        }
        .token-display-wrapper {
          flex-direction: row;
          height: 36px;
          margin-top: 5px;
          margin-bottom: 20px;
          .token-display {
            width: 238px;
            height: 100%;
            font-family: 'Nunito-Bold';
            font-size: 20px;
            font-weight: bold;
            line-height: 27px;
            letter-spacing: 1.2px;
            text-align: center;
            border-radius: 10px 0 0 10px;
            color: $grey-5;
            background-color: #e6e6e6;
          }
          .refresh-button {
            width: 36px;
            height: 100%;
            border-radius: 0 10px 10px 0;
            background-color: #d4f5f1;
            cursor: pointer;
          }
          svg {
            path {
              &:nth-child(odd) {
                fill: none;
              }
            }
          }
        }
      }

      &-info-wrapper {
        .top {
          margin-bottom: 20px;
          .group-title-wrapper {
            .title-edit {
              width: fit-content;
              margin-top: 5px;
              margin-left: -10px;
              padding-left: 10px;
              border-radius: 16px;
              color: $green-4;
              cursor: pointer;
              span {
                margin-right: 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-transform: initial;
              }
              .icon-wrapper {
                visibility: hidden;
                width: 28px;
                height: 28px;
                border-radius: 16px;
                background-color: #e6e6e6;
                svg {
                  path {
                    &:not(:last-child) {
                      stroke: none;
                    }
                  }
                }
              }
              &:hover {
                .icon-wrapper {
                  visibility: visible;
                }
                background-color: rgba(230, 230, 230, 0.5);
              }
            }
            &:first-child {
              margin-bottom: 16px;
            }
          }
        }
        .bottom {
          p {
            svg {
              margin-right: 5px;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
        h4 {
          color: $green-4;
          &.title {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.08em;
            &:hover {
              text-decoration: underline;
            }
          }
          &.subtitle {
            line-height: 22px;
            letter-spacing: 0.08em;
          }
        }
      }
      &-token-wrapper {
        .grey-subtitle {
          svg {
            margin-left: 5px;
          }
        }
        .token-toggle-wrapper {
          justify-content: flex-start;
          .token-toggle {
            margin-left: 12px;
            height: 27px;
            width: 48px;
            .toggle__slider {
              &:before {
                height: 21px;
                width: 21px;
                border: none;
                top: 3px;
                left: 3px;
              }
            }
          }
        }
        .token-refresh-modal {
          .modal__container__heading {
            text-transform: initial;
          }
        }
      }
    }
  }
</style>
