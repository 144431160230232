/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/target': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 1433" transform="translate(-381.856 -334.33)"><circle pid="0" data-name="Ellipse 274" cx="16.559" cy="16.559" r="16.559" transform="rotate(-.5 38535.198 -43589.887)" _fill="#6666ca"/><path pid="1" data-name="Path 970" d="M401.25 340.13a11.233 11.233 0 1 0 8.21 13.6 11.221 11.221 0 0 0-8.21-13.6zm5.18 12.85a8.112 8.112 0 1 1-5.92-9.82 8.112 8.112 0 0 1 5.92 9.82z" _fill="#fff"/><path pid="2" data-name="Path 971" d="M403.573 351.009a5.014 5.014 0 1 1-5.038-4.99 5.014 5.014 0 0 1 5.038 4.99z" _fill="#fff"/><path pid="3" data-name="Path 972" d="M400.727 351.022a2.168 2.168 0 1 1-2.179-2.157 2.168 2.168 0 0 1 2.179 2.157z" _fill="#6666ca"/></g>'
  }
})
