/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/yes_no': {
    width: 33.12,
    height: 33.12,
    viewBox: '0 0 33.12 33.12',
    data: '<g data-name="Group 3830"><g data-name="Group 1403" _fill="#6666ca"><path pid="0" data-name="Path 933" d="M16.56 0a16.562 16.562 0 0 0-4.74 32.43L19.83.33A16.05 16.05 0 0 0 16.56 0zm-4.42 14.87L7.4 19.61a.76.76 0 0 1-1.07 0l-2.5-2.51a.75.75 0 0 1 1.06-1.06l1.97 1.98 4.21-4.21a.753.753 0 0 1 1.07 1.06z"/><path pid="1" data-name="Path 934" d="M21.29.69l-8.01 32.1a16.148 16.148 0 0 0 3.28.33A16.56 16.56 0 0 0 21.29.69zm7.65 19.79a.748.748 0 0 1-1.06 0l-2.72-2.71-2.71 2.71a.748.748 0 0 1-1.06 0 .754.754 0 0 1 0-1.06l2.71-2.71-2.71-2.72a.75.75 0 0 1 1.06-1.06l2.71 2.72 2.72-2.72a.75.75 0 0 1 1.06 1.06l-2.71 2.72 2.71 2.71a.755.755 0 0 1 0 1.06z"/></g></g>'
  }
})
