/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.696 0H2.133A2.133 2.133 0 0 0 0 2.133v11.564c0 1.178.955 2.133 2.133 2.133h5.703l.01-5.657h-1.47a.347.347 0 0 1-.347-.345l-.007-1.824c0-.192.155-.348.347-.348h1.467V5.894c0-2.044 1.249-3.158 3.072-3.158h1.497c.191 0 .347.156.347.347v1.538a.347.347 0 0 1-.347.346l-.918.001c-.992 0-1.184.471-1.184 1.163v1.525h2.18c.207 0 .368.181.344.388l-.216 1.823a.347.347 0 0 1-.345.306h-1.954l-.01 5.657h3.394a2.133 2.133 0 0 0 2.133-2.133V2.133A2.133 2.133 0 0 0 13.696 0z"/>'
  }
})
