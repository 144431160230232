/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'page_next': {
    width: 14.289,
    height: 13.646,
    viewBox: '0 0 14.289 13.646',
    data: '<g data-name="Group 3911"><g data-name="Group 1120" _fill="none" _stroke="#6666ca" stroke-linecap="round" stroke-width="1.5"><path pid="0" data-name="Line 136" stroke-miterlimit="10" d="M.75 6.823h12.789"/><path pid="1" data-name="Path 834" d="M7.778 1.061l5.762 5.762-5.762 5.762" stroke-linejoin="round"/></g></g>'
  }
})
