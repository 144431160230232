<template>
  <div class="pass-reset__page">
    <section class="pass-reset" id="pass-reset">
      <div class="pass-reset__header">
        <header-h1>{{ $t("profile.password_change_cap") }}</header-h1>
      </div>
      <div class="pass-reset__container" @mouseleave="handleMouseLeave">
        <div class="info__field">
          <span>{{ $t("inputs.password") }}*</span>
          <input type="password" v-model="password" @blur="$v.password.$touch()" @keydown.enter="resetPassConfirm" />
          <svgicon
            v-if="$v.password.$error"
            name="warning"
            :original="true"
            height="16px"
          ></svgicon>
          <div class="info__error" v-if="$v.password.$error">
            <span class="info__error-message" v-if="!$v.password.required"
              >{{ $t("warnings.password_is_required") }}</span
            >
            <span class="info__error-message" v-if="!$v.password.minLength"
              >{{ $t("warnings.password_length_warning") }}</span
            >
          </div>
          <div class="info__error" v-if="resetError">
            <span class="info__error-message" v-if="resetError">{{ resetError }}</span>
          </div>
        </div>
        <div class="info__field">
          <span>{{ $t("inputs.password_repeat") }}*</span>
          <input type="password" v-model="password2" @blur="$v.password2.$touch()" @keydown.enter="resetPassConfirm" />
          <svgicon
            v-if="$v.password2.$error"
            name="warning"
            :original="true"
            height="16px"
          ></svgicon>
          <div class="info__error" v-if="$v.password2.$error">
            <span class="info__error-message" v-if="!$v.password2.required"
              >{{ $t("warnings.password_repeat_is_required") }}</span
            >
            <span class="info__error-message" v-if="!$v.password2.sameAs"
              >{{ $t("warnings.password_mismatch") }}</span
            >
          </div>
        </div>
      </div>
      <div class="pass-reset__action">
        <button-4 @click.native="resetPassConfirm" :disabled="!passIsValid">{{ $t("actions.confirm_action") }}</button-4>
      </div>
      <modal :passResetModal="true" :isStudent="isStudent" v-show="isModalVisible" @close="toggleModal"></modal>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';


import Modal from '@/components/base/Modal.vue';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'PassReset',
  components: {
    Modal,
    HeaderH1,
    Button4,
  },
  data() {
    return {
      password: null,
      password2: null,
      isModalVisible: false,
      resetError: null,
      userType: 0,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    password2: {
      required,
      sameAs: sameAs('password'),
      minLength: minLength(8),
    },
  },
  computed: {
    passIsValid() {
      return !this.$v.password.$invalid && !this.$v.password2.$invalid;
    },
    isStudent() {
      const { path } = this.$route;
      return path === '/mokinio-slaptazodzio-priminimas/'
    }
  },
  methods: {
    ...mapActions('user', ['resetPasswordConfirmation']),
    resetPassConfirm() {
      const { secret } = this.$route.query;
      // const { path } = this.$route;

      // if(path === '/mokinio-slaptazodzio-priminimas/'){
      //   console.log('mokinio reset')
      //   this.userType = 1
      // }else{
      //   console.log('teacher reset')
      //   this.userType = 0
      // }

      if (!secret) {
        this.resetError = 'Netinkama nuoroda';
        return;
      }
      const formData = {
        password: this.password,
        token: secret,
      };
      if (secret && this.passIsValid) {
        this.resetPasswordConfirmation(formData)
          .then((res) => {
            const { success } = res;
            if (success) {
              this.toggleModal();
            }
          })
          .catch((error) => {
            this.resetError = error.response.data.message;
          });
      } else {
        this.$v.password.$touch();
        this.$v.password2.$touch();
      }
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    handleMouseLeave() {
      this.$v.password.$touch();
      this.$v.password2.$touch();
    },
  },
};
</script>
<style lang="scss">
.pass-reset {
  max-width: 940px;
  margin: 60px auto 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    margin-top: 20px;
    min-height: 160px;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info__field {
      position: relative;
      display: flex;
      flex-direction: column;

      .info__error {
        display: flex;
        flex-direction: column;
        .info__error-message {
          margin-top: 3px;
          font-family: Roboto-Regular;
          font-size: 12px;
          color: $red-4;
          text-transform: initial;
        }
      }

      svg {
        position: absolute;
        right: 6px;
        top: 26px;
      }

      span {
        text-align: left;
        font-family: Nunito-Bold;
        font-size: 10px;
        text-transform: uppercase;
        color: $grey-3;
      }
      input {
        margin-top: 6px;
        min-height: 28px;
        border: 1px solid $grey-1;
        padding: 0 10px;
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-5;
        &:hover,
        &:active {
          border: 1px solid $grey-2;
        }
        &::placeholder {
          color: $grey-2;
        }

        &[type='password'] {
          font-family: Roboto-Regular;
          font-size: 22px;
          color: $green-3;
          letter-spacing: 8px;
        }
      }

      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }
  }

  &__action {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    button {
      min-width: 102px;
      margin: 0 auto;
      padding: 5px 20px;
    }
  }
}
</style>
