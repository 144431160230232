<template>
  <div class="reflection-options__option">
    <div class="reflection-options__option-header">
      <h3 class="reflection-options__option-date">
        {{ schedule.fromDate }}, {{ schedule.time
        }}{{ schedule.weekStep ? `, ${$t("every")} ${schedule.weekStep} ${$t("week_abb")}` : null }}
      </h3>
      <div class="reflection-options__delete" v-if="!schedule.active || (schedule.active && allowToDelete)" @click="handleScheduleRemove(i, schedule.id)">
        <svgicon
          class="reflection-options__delete__icon"
          name="delete"
          :original="true"
          v-tooltip='$t("actions.remove_schedule_time_action")'
        ></svgicon>
      </div>
    </div>
    <div class="reflection-options__row">
      <div class="reflection-options__col">
        <span class="reflection-options__option-title">{{ $t("schedule.start_of_reflection") }}*</span>
        <div class="reflection-options__inputs">
          <date-time-picker
            class="reflection-options__input reflection-options__input--time-picker"
            v-model="schedule.time"
            :format="'HH:mm'"
            :only-time="true"
            :no-value-to-custom-elem="true"
            :color="'#11ac9a'"
            :overlay="false"
            :class="{ 'input-error-active': !isValidDateTime && isScheduleChecked }"
          >
            <input
              type="time"
              class="reflection-options__input__time-picker"
              v-model="schedule.time"
              @input="handleTimeInput"
              placeholder="HH:mm"
              @click="(e) => e.preventDefault()"
            />
          </date-time-picker>
          <vue-datepicker
            :monday-first="true"
            :bootstrap-styling="true"
            :full-month-name="true"
            :language="currentCalendarLocale.locale"
            :placeholder="'2019-05-29'"
            :format="customFormatter"
            @selected="handleDate($event, i, 'fromDate')"
            :value="schedule.fromDate"
            :disabled-dates="disabledFromDates"
          ></vue-datepicker>
        </div>
        <p class="schedule-error-message" v-if="!isValidDateTime && isScheduleChecked">{{ $t("modals.options_modal_start_time_past_warning") }}</p>
      </div>
      <div class="reflection-options__col">
        <span class="reflection-options__option-title">{{ $t("schedule.duration_of_reflection") }}*</span>
        <div class="reflection-options__inputs">
          <input
            class="reflection-options__input reflection-options__input--small"
            type="number"
            :value="schedule.duration"
            :id="`duration${i}`"
            :name="`duration${i}`"
            @input="handleDuration($event, i)"
            min="1"
          />
          <multi-select
            :id="`durationTypeSelect${i}`"
            :key="`durationTypeSelect${i}`"
            :options="reflectionDuration"
            :placeholder='`- ${$t("select_option_placeholder")} -`'
            :width="180"
            :selected="durationType"
            @selectedValue="handleDurationSelect"
          ></multi-select>
        </div>
      </div>
    </div>
    <div class="reflection-options__row">
      <div class="reflection-options__col">
        <span class="reflection-options__option-title">{{ $t("schedule.times_repeated") }}</span>
        <multi-select
          :id="`repeatSelect${i}`"
          :key="`repeatSelect${i}`"
          :options="reflectionRepeat"
          :placeholder='`- ${$t("select_option_placeholder")} -`'
          :width="180"
          :selected="repeatType"
          @selectedValue="handleRepeatSelect"
        ></multi-select>
      </div>
      <div class="reflection-options__col" v-if="schedule.repeatType === 'custom'">
        <span class="reflection-options__option-title">{{ $t("schedule.repeat_option_label_one") }}</span>
        <div class="week-days">
          <span
            :class="['day', { 'day--active': activeDay.includes(index + 1) }]"
            v-for="(day, index) in weekDays"
            @click="
              (e) => {
                handleDaySelect(day.value), e.target.classList.toggle('day--active');
              }
            "
            :key="`day--${day.value}`"
          >{{ day.display }}</span>
        </div>
      </div>
      <div class="reflection-options__col" v-if="schedule.repeatType === 'custom'">
        <span class="reflection-options__option-title">{{ $t("schedule.repeat_option_label_two") }}</span>
        <div class="reflection-options__inputs reflection-options__inputs--weeks">
          <multi-select
            :id="`weekStepSelect${i}`"
            :key="`weekStepSelect${i}`"
            :options="weekStep"
            :placeholder='`${$t("select_option_placeholder")}`'
            :width="80"
            :selected="schedule.weekStep"
            @selectedValue="handleWeekStepSelect"
          ></multi-select>
          <span
            class="reflection-options__option-title reflection-options__option-title--custom"
          >{{ $t("schedule.custom_repeat_weeks_label") }}</span>
        </div>
      </div>
    </div>
    <div
      class="reflection-options__row"
      v-if="schedule.repeatType === 'daily' || schedule.repeatType === 'custom'"
    >
      <div class="reflection-options__col">
        <span class="reflection-options__option-title">{{ $t("schedule.repeat_option_label_three") }}</span>
        <div class="reflection-options__inputs">
          <vue-datepicker
            :monday-first="true"
            :bootstrap-styling="true"
            :full-month-name="true"
            :language="currentCalendarLocale.locale"
            :placeholder="'YYYY-mm-dd'"
            :format="customFormatter"
            @selected="handleDate($event, i, 'toDate')"
            :disabled-dates="disabledToDates"
            :value="schedule.toDate"
            :class="{'empty-date-input' : (schedule.toDateEmpty && isScheduleChecked)}"
          ></vue-datepicker>
        </div>
        <p class="schedule-error-message" v-if="schedule.toDateEmpty && isScheduleChecked">{{ $t("modals.options_modal_no_end_time") }}</p>
        <p class="schedule-error-message" v-if="(schedule.repeatType === 'custom' && noWeekDaysSelected) && isScheduleChecked">{{ $t("modals.options_modal_no_weekdays_warning") }}</p>
      </div>
      <div
        class="reflection-options__col"
        v-if="schedule.repeatType === 'daily' || schedule.repeatType === 'custom'"
      >
        <span class="reflection-options__option-title">{{ $t("skip") }}</span>
        <div
          class="reflection-options__inputs reflection-options__inputs--skip"
          v-for="(skipDate, skipIndex) in disabledSkipDates.dates"
          :key="`skip--${skipDate}`"
        >
          <svgicon
            name="bin"
            class="reflection-options__inputs__remove"
            @click="handleSkipDelete(skipIndex)"
          ></svgicon>
          <vue-datepicker
            :monday-first="true"
            :bootstrap-styling="true"
            :full-month-name="true"
            :language="currentCalendarLocale.locale"
            :placeholder="'YYYY-mm-dd'"
            :format="customFormatter"
            @selected="handleDate($event, i, 'skip', skipIndex)"
            :disabled-dates="disabledSkipDates"
            :value="skipDate"
          ></vue-datepicker>
        </div>
        <div class="reflection-options__inputs reflection-options__inputs--skip">
          <vue-datepicker
            :monday-first="true"
            :bootstrap-styling="true"
            :full-month-name="true"
            :language="currentCalendarLocale.locale"
            :placeholder="'YYYY-mm-dd'"
            @selected="handleSkip($event, schedule.skip.length || 0, i)"
            :value="placeholderDate"
            :format="customFormatter"
            :disabled-dates="disabledSkipDates"
            :key="`skip-placeholder--${schedule.skip.length || -1}`"
          ></vue-datepicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lt, en } from 'vuejs-datepicker/dist/locale';
import VueDatepicker from 'vuejs-datepicker';
import DateTimePicker from 'vue-ctk-date-time-picker';

import MultiSelect from '@/components/elements/select/MultiSelect.vue';

export default {
  name: 'OptionsScheduleOld',
  components: {
    VueDatepicker,
    MultiSelect,
    DateTimePicker,
  },
  props: ['i', 'isScheduleChecked'],
  created() {
    const { skip, repeatType, weekDays } = this.schedule;
    skip.forEach((date) => this.disabledSkipDates.dates.push(this.moment(date).toDate()));

    if (repeatType === 'once') {
      this.disabledToDates.days = [];
      this.disabledSkipDates.days = [];
      this.disabledToDates.from = null;
      this.disabledToDates.to = null;
      this.disabledSkipDates.from = null;
      this.disabledSkipDates.to = null;
    } else if (this.schedule.repeatType === 'daily') {
      this.disabledToDates.days = [];
      this.disabledSkipDates.days = [];
      this.disabledToDates.from = null;
      this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
      this.disabledSkipDates.from = null;
      this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
    } else if (this.schedule.repeatType === 'custom') {
      weekDays.forEach((day) => {
        if (day === 7) day = 0;
        const dayIndex = this.disabledToDates.days.findIndex((i) => i === day);
        this.disabledToDates.days.splice(dayIndex, 1);
        this.disabledSkipDates.days.splice(dayIndex, 1);
      });
      this.disabledToDates.from = null;
      this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
      this.disabledSkipDates.from = null;
      this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
    }
  },
  data() {
    return {
      time: null,
      reflectionDuration: [
        { value: this.$t("schedule.duration_minutes"), type: 'minutes' },
        { value: this.$t("schedule.duration_hours"), type: 'hours' },
        { value: this.$t("schedule.duration_days"), type: 'days' },
      ],
      reflectionRepeat: [
        { value: this.$t("schedule.repeat_type_once"), type: 'once' },
        { value: this.$t("schedule.repeat_type_daily"), type: 'daily' },
        { value: this.$t("schedule.repeat_type_custom"), type: 'custom' },
      ],
      weekStep: [1, 2, 3, 4],
      weekDays: [
        { value: 1, type: 'days', display: this.$t("date_and_time.day_monday_abb") },
        { value: 2, type: 'days', display: this.$t("date_and_time.day_tuesday_abb") },
        { value: 3, type: 'days', display: this.$t("date_and_time.day_wednesday_abb") },
        { value: 4, type: 'days', display: this.$t("date_and_time.day_thursday_abb") },
        { value: 5, type: 'days', display: this.$t("date_and_time.day_friday_abb") },
        { value: 6, type: 'days', display: this.$t("date_and_time.day_saturday_abb") },
        { value: 7, type: 'days', display: this.$t("date_and_time.day_sunday_abb") },
      ],
      durationToDisplay: null,
      disabledFromDates: {
        to: new Date(this.moment().subtract(1, 'day')),
        from: null,
      },
      disabledToDates: {
        to: null,
        from: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: [],
      },
      placeholderDate: null,
      disabledSkipDates: {
        to: null,
        from: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: [],
      },
      hello: null,
      calendarLocales: [
        {key: 'lt', locale: lt},
        {key: 'en', locale: en}
      ],
      currentLocale: localStorage.getItem('locale'),
    };
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire']),
    schedule() {
      return this.questionnaire.schedules[this.i];
    },
    durationType() {
      const durationType = { type: this.schedule.durationType || 'minutes', value: null };
      switch (durationType.type) {
        case 'minutes':
          durationType.value = this.$t("schedule.duration_minutes");
          break;
        case 'hours':
          durationType.value = this.$t("schedule.duration_hours");
          break;
        case 'days':
          durationType.value = this.$t("schedule.duration_days");
          break;
        default:
          break;
      }

      return durationType;
    },
    repeatType() {
      const repeatType = { type: this.schedule.repeatType || 'once', value: null };
      switch (repeatType.type) {
        case 'once':
          repeatType.value = this.$t("schedule.repeat_type_once");
          break;
        case 'daily':
          repeatType.value = this.$t("schedule.repeat_type_daily");
          break;
        case 'custom':
          repeatType.value = this.$t("schedule.repeat_type_custom");
          break;
        default:
          break;
      }

      return repeatType;
    },
    activeDay() {
      const { weekDays } = this.schedule;
      if (weekDays) {
        return weekDays;
      }
      return [];
    },
    currentCalendarLocale(){
      return this.calendarLocales.find(locale => locale.key === this.currentLocale);
    },
    allowToDelete(){
      const dateAndTime = this.moment(this.schedule.fromDate + this.schedule.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
      return dateAndTime > this.moment().format('YYYY-MM-DD HH:mm');
    },
    isValidDateTime() {
      let isValid = 0;
      if (!this.schedule.active) {
        const scheduleDateTime = this.moment(
          this.schedule.fromDate + this.schedule.time,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY-MM-DD HH:mm');
        if (scheduleDateTime <= this.moment().format('YYYY-MM-DD HH:mm')) {
          isValid = 1;
        }
      }else{
        const scheduleDateTime = this.moment(
          this.schedule.fromDate + this.schedule.time,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY-MM-DD HH:mm');
        if (scheduleDateTime === this.moment().format('YYYY-MM-DD HH:mm')) {
          isValid = 1;
        }
      }
      return isValid === 0;
    },
    noWeekDaysSelected() {
      return this.disabledToDates.days.length === 7 || false;
    }
  },
  methods: {
    ...mapActions('questionnaire', [
      'removeSchedule',
      'setQuestionnaireProperty',
      'setScheduleProperty',
    ]),
    customFormatter(value) {
      return this.moment(value).format('YYYY-MM-DD');
    },
    handleScheduleRemove(i, id) {
      this.$emit('handleScheduleRemove', {i, id});
    },
    timeChangeHandler(value, i) {
      const property = value;
      const payload = {
        type: 'time',
        index: i,
        property,
      };
      this.setQuestionnaireProperty(payload);
    },
    handleDate(value, i, type, skipIndex) {
      const selectedDate = this.moment(value).format('YYYY-MM-DD');
      const payload = {
        type,
        index: i,
        property: selectedDate,
      };
      if (skipIndex > -1) {
        payload.skipIndex = skipIndex;
      }
      if (type !== 'skip') {
        this.setQuestionnaireProperty(payload);
      } else {
        this.setScheduleProperty(payload);
      }

      if (type === 'toDate') {
        // this.disabledToDates.from = this.moment(value).toDate();
        this.disabledSkipDates.from = this.moment(value).toDate();
      }
      if (type === 'fromDate') {
        this.disabledSkipDates.to = this.moment(value).toDate();
        this.disabledToDates.to = this.moment(value).toDate();
      }
    },
    handleSkip(value, skipIndex, index) {
      const selectedDate = this.moment(value).format('YYYY-MM-DD');
      this.setScheduleProperty({
        type: 'skip',
        index,
        skipIndex,
        property: selectedDate,
      });
      this.disabledSkipDates.dates.push(new Date(selectedDate));
      this.placeholderDate = null;
    },
    handleSkipDelete(skipIndex) {
      this.schedule.skip.splice(skipIndex, 1);
      this.disabledSkipDates.dates.splice(skipIndex, 1);
    },
    handleDuration(e, i) {
      const property = parseInt(e.target.value, 10);
      const payload = {
        type: 'duration',
        index: i,
        property,
      };
      this.setQuestionnaireProperty(payload);
    },
    handleTimeInput(value) {
      console.log(value);
    },
    handleDurationSelect(value) {
      const {
        params: { type: routeType },
      } = this.$route;

      const payload = {
        type: 'durationType',
        index: this.i,
        property: value.type,
        routeType,
      };
      this.setQuestionnaireProperty(payload);
    },
    handleRepeatSelect(value) {
      const type = value.type;
      const payload = {
        type: 'repeatType',
        index: this.i,
        property: type,
      };
      this.setQuestionnaireProperty(payload);
      if (type === 'once') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = null;
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = null;
      } else if (type === 'daily') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      } else if (type === 'custom') {
        this.disabledToDates.days = [0, 1, 2, 3, 4, 5, 6];
        this.disabledSkipDates.days = [0, 1, 2, 3, 4, 5, 6];
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      }
    },
    handleDaySelect(day) {
      const payload = {
        type: 'weekDays',
        index: this.i,
        property: day,
      };
      this.setQuestionnaireProperty(payload);
      if (day === 7) day = 0;
      const skipDays = this.disabledSkipDates.days;
      const toDays = this.disabledToDates.days;
      const dayIndex = skipDays.findIndex((skipDay) => skipDay === day);
      if (dayIndex > -1) {
        skipDays.splice(dayIndex, 1);
        toDays.splice(dayIndex, 1);
      } else {
        skipDays.push(day);
        toDays.push(day);
      }
    },
    handleWeekStepSelect(value) {
      const weekStep = value.value;
      const payload = {
        type: 'weekStep',
        index: this.i,
        property: weekStep,
      };
      this.setQuestionnaireProperty(payload);
    },
  },
};
</script>
<style lang="scss">
.reflection-options__inputs {
  .vdp-datepicker {
    &.empty-date-input {
      input {
        border: 1px solid $red-4;
      }
    }
  }
  .input-error-active{
    border: 1px solid $red-4;
  }
}
</style>
