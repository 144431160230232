<template>
  <div class="profile-inner">
    <p class="profile-inner__title">{{ $t('profile.licences') }}</p>
    <licences :user="user" :userOrganizations="userOrganizations" :licenceData="userLicenceData" />
    <my-licences />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Licences from '@/components/user/licences/Licences.vue';
import MyLicences from '@/components/user/licences/MyLicences.vue';

export default {
  name: 'ProfileLicence',
  components: {
    Licences,
    MyLicences,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['user', 'userOrganizations']),
    userLicenceData() {
      return (this.user && this.user.licenseData) || false;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.my-licences,
.personal-licences {
  margin-bottom: 35px;

  &__title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    color: $grey-9;
  }
}
</style>
