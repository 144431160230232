/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit-button': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<circle pid="0" cx="14.5" cy="14.5" r="13.5" _stroke="#919396"/><path pid="1" d="M20.33 9.696l-.444-.444a.86.86 0 0 0-1.216 0l-.608.608 1.66 1.66.608-.608a.86.86 0 0 0 0-1.216zM19.259 12.03l-1.66-1.66-4.501 4.5-.462 1.97a.17.17 0 0 0 .21.203l1.912-.512 4.5-4.5z" _fill="#919396"/><path pid="2" d="M14.159 11.053h-3.452a.707.707 0 0 0-.707.707v7.045c0 .39.317.707.707.707h6.31c.392 0 .708-.316.708-.707v-2.829" _stroke="#919396" stroke-miterlimit="10" stroke-linecap="round"/>'
  }
})
