/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-kiss': {
    width: 16,
    height: 16,
    viewBox: '0 0 28.24 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="14.89" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M21.87 2A13.36 13.36 0 0 1 3.64 20.53 13.35 13.35 0 1 0 21.87 2z" _fill="#ffc448"/><path pid="2" d="M3.35 14.76A12.4 12.4 0 0 1 25.58 7.2 12.4 12.4 0 1 0 5 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" d="M11.44 10.56a2.47 2.47 0 0 0-4.58 0 .4.4 0 0 0 .64.44 2.12 2.12 0 0 1 3.29 0 .4.4 0 0 0 .65-.43zM22.91 10.56a2.47 2.47 0 0 0-4.57 0A.4.4 0 0 0 19 11a2.12 2.12 0 0 1 3.29 0 .4.4 0 0 0 .65-.43z" _fill="#2c2c2c"/><path pid="4" data-name="&lt;Path&gt;" d="M8.92 15.87a2.46 2.46 0 0 0-2.41-2.57 2.12 2.12 0 0 0-2 1.6 2.12 2.12 0 0 0-2-1.6A2.46 2.46 0 0 0 0 15.85c0 .29 0 3.24 4.46 5.64 4.46-2.38 4.47-5.33 4.46-5.62z" _fill="#ff5083"/><path pid="5" d="M14.89 20.78a2.2 2.2 0 0 1-1.68-3.62 2.2 2.2 0 1 1 3.51-2.64.38.38 0 0 1-.63.42A1.45 1.45 0 1 0 14 16.86a.38.38 0 0 1 0 .59 1.45 1.45 0 1 0 2.1 1.94.38.38 0 0 1 .63.42 2.2 2.2 0 0 1-1.84.97z" _fill="#654735"/></g></g>'
  }
})
