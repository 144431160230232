/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'green-stats': {
    width: 15.476,
    height: 15.476,
    viewBox: '0 0 15.476 15.476',
    data: '<g data-name="Group 3999"><g data-name="Group 3994" transform="translate(-194.197 -133.197)"><circle pid="0" data-name="Ellipse 823" cx="7.738" cy="7.738" r="7.738" transform="translate(194.197 133.197)" _fill="#13a85b"/><g data-name="Group 3992" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.2"><path pid="1" data-name="Line 168" d="M197.761 139.895v4.747"/><path pid="2" data-name="Line 169" d="M200.599 141.111v3.53"/><path pid="3" data-name="Line 170" d="M206.276 137.5v7.141"/><path pid="4" data-name="Line 171" d="M203.437 139.316v5.325"/></g></g></g>'
  }
})
