<template>
  <div class="template__sentences" v-if="question">
    <div class="template__sentences__input-field">
      <input
        type="number"
        :disabled="!edit"
        :placeholder="$t('insert_value_placeholder')"
        v-model="part.answerOptions.maxLength"
        @input="handleLengthInput"
      />
      <p class="max-length-error-message" v-if="part.answerOptions.maxLength > 250">
        {{ $t('warnings.question_part_length_warning') }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TemplateSentences',
    props: ['number', 'question', 'partIndex', 'edit', 'questionParts'],
    data() {
      return {
        i: 0,
      };
    },
    created() {
      if (!this.options.maxLength) {
        this.options.maxLength = 45;
      }
      if (this.part && !this.part.answerOptions) {
        this.part.answerOptions = {
          maxLength: 45,
        };
      }
    },
    computed: {
      options() {
        return this.question.answerOptions;
      },
      parts() {
        return this.question.parts;
      },
      part() {
        return this.questionParts;
      },
      type() {
        return this.question.answerOptions.type;
      },
      view() {
        return this.question.answerOptions.view;
      },
    },
    methods: {
      handleLengthInput(event) {
        const { value } = event.target;
        this.part.maxLength = parseInt(value, 10);
      },
    },
  };
</script>
<style lang="scss">
  .template {
    &__sentences {
      &__input-field {
        margin-top: 10px;
        input {
          width: 100px;
          height: 28px;
          padding: 8px;
          font-family: Roboto-Regular;
          font-size: 14px;
          border: 1px solid $grey-2;
          color: $grey-5;

          &::placeholder {
            color: $grey-4;
          }
        }
        .max-length-error-message {
          color: $red-4;
          font-size: 14px;
        }
      }
    }
  }
</style>
