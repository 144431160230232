<template>
  <div class="method-answer__container">
    <div
      :class="['method-answer', { 'method-answer--selected': option.value === memberAnswer }]"
      v-for="(option, index) in options"
      :key="index"
    >
      <div class="method-answer__smileys">
        <img
          v-if="option.show"
          class="method-answer__smileys__icon"
          :src="option.show"
          :alt="option.value"
        />
        <span class="method-answer__value">{{ option.value }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TemplateSmileys',
  props: ['question', 'memberAnswer'],
  computed: {
    options() {
      const {
        answerOptions: { options },
      } = this.question;
      const addition = [
        {
          show: null,
          value: this.$t("member_did_not_reflect"),
        },
        {
          show: null,
          value: this.$t("member_skipped_alt"),
        },
      ];
      return [...options, ...addition];
    },
  },
};
</script>
<style lang="scss">
.method-answer {
  &__smileys {
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin-right: 20px;

    &__icon {
      height: 34px;
      min-width: 34px;
      padding: 4px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
</style>
