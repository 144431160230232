/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-view-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Group 3829"><path pid="0" data-name="Rectangle 4398" _fill="#d1d1d1" d="M0 0h20v20H0z"/><path pid="1" data-name="Line 1369" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 5h12"/><path pid="2" data-name="Line 1370" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 10.455h12"/><path pid="3" data-name="Line 1371" _fill="none" _stroke="#fff" stroke-linecap="round" d="M4 16h12"/></g>'
  }
})
