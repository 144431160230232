<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
          <h4 class="modal__container__heading">{{ message || '-' }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WarningModal',
    props: ['message'],
    methods: {
      handleModalToggle() {
        this.$emit('handleToggleAction');
      },
    },
  };
</script>
