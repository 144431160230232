/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-clock': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M9.681 6.293L7.5 8.475 5.318 6.293l-1.006.999L7.5 10.479l3.187-3.187-1.005-1zM7.5.917A7.086 7.086 0 0 0 .417 8 7.086 7.086 0 0 0 7.5 15.083 7.086 7.086 0 0 0 14.583 8 7.086 7.086 0 0 0 7.5.917zm0 12.75A5.665 5.665 0 0 1 1.833 8 5.665 5.665 0 0 1 7.5 2.333 5.665 5.665 0 0 1 13.166 8 5.665 5.665 0 0 1 7.5 13.667z" _fill="#11AB99"/>'
  }
})
