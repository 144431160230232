/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'purple-arrow': {
    width: 12,
    height: 7,
    viewBox: '0 0 12 7',
    data: '<path pid="0" d="M11.25 2.958H2.984l2.088-1.944L4.25.25.75 3.5l3.5 3.25.822-.764-2.088-1.944h8.266V2.958z" _fill="#5147A7"/>'
  }
})
