/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/open': {
    width: 15.33,
    height: 15.33,
    viewBox: '0 0 15.33 15.33',
    data: '<g data-name="Group 2459"><g data-name="Group 1287" transform="translate(-633.246 -420.31)" _fill="none" _stroke="#919396"><path pid="0" data-name="Path 932" d="M641.01 423.457a4.518 4.518 0 1 1-4.518 4.518 4.518 4.518 0 0 1 4.518-4.518z"/><ellipse pid="1" data-name="Ellipse 264" cx="7.165" cy="7.165" rx="7.165" ry="7.165" transform="translate(633.746 420.81)" stroke-miterlimit="10"/></g></g>'
  }
})
