/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-history': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M8.563.625A6.376 6.376 0 0 0 2.186 7H.063l2.755 2.755.05.1L5.729 7H3.604a4.955 4.955 0 0 1 4.958-4.958A4.955 4.955 0 0 1 13.521 7a4.955 4.955 0 0 1-4.959 4.958A4.918 4.918 0 0 1 5.063 10.5l-1.005 1.006a6.343 6.343 0 0 0 4.505 1.87 6.376 6.376 0 0 0 0-12.75zm-.709 3.542v3.541l3.01 1.785.546-.906-2.493-1.48v-2.94H7.854z" _fill="#11AB99"/>'
  }
})
