<template>
  <div class="step licences flex-global">
    <div :class="['licence-type-switch flex-global', { reverse: purchaseType === purchaseTypes.org }]">
      <span :class="{ 'active-type': purchaseType === purchaseTypes.teacher }"
        @click="handlePurchaseTypeChange(purchaseTypes.teacher)">
        {{ $t('licences.individual_licences') }}
      </span>
      <span v-if="areOrganizationsPresent" :class="{ 'active-type': purchaseType === purchaseTypes.org }"
        @click="handlePurchaseTypeChange(purchaseTypes.org)">
        {{ $t('licences.org_licences') }}
      </span>
    </div>
    <div v-if="purchaseType === purchaseTypes.teacher" class="payment-cycle-switch flex-global">
      <span class="title">{{ $t('licences.payment_cycle') }}:</span>
      <div class="switch-wrapper flex-global">
        <!-- rf-403 hide monthly licences -->
        <!-- <div
          :class="['switch-wrapper__item', { 'active-cycle': cycleType === cycleTypes.monthly }]"
          @click="handleCycleTypeChange(cycleTypes.monthly)"
        >
          <span>{{ $t('licences.cycle_monthly') }}</span>
        </div> -->
        <div :class="['switch-wrapper__item', { 'active-cycle': cycleType === cycleTypes.yearly }]"
          @click="handleCycleTypeChange(cycleTypes.yearly)">
          <span>{{ $t('licences.cycle_yearly') }}</span>
        </div>
      </div>
    </div>
    <div v-if="purchaseType === purchaseTypes.org" class="organization-settings flex-global">
      <div class="organization-settings__select flex-global">
        <span>{{ $t('organization') }}:</span>
        <select name="organization" v-model="currentSelectedOrg">
          <option v-for="organization in userOrganizations" :key="organization.id" :value="organization.id">
            {{ organization.title }}
          </option>
        </select>
      </div>
      <div class="organization-settings__amount flex-global">
        <span>{{ $t('licences.required_amount') }}:</span>
        <div class="amount-wrapper flex-global">
          <div v-for="amount in licenceForOrgAmountsList" :key="amount"
            :class="['amount-value', { active: currentAmountSelected === amount }]" @click="handleAmountSelect(amount)">
            {{ amount }}
          </div>
          <div :class="['amount-value custom', { active: isCustomInputActive }]" @click="currentAmountSelected = null">
            <input type="text" v-model="amountSelectedValue" @input="handleCustomAmountSelect"
              :placeholder="$t('enter_amount')" />
          </div>
        </div>
      </div>
    </div>
    <div class="licences-list-wrapper flex-global">
      <licence-purchase-card v-for="licence in licencesForPurchaseData" :key="licence.id" :licence="licence"
        @onLicenceSelect="handleLicenceSelect" />
    </div>
    <button-4 @click.native="handleNextStep" :disabled="!activeLicence" class="buy-button">
      {{ $t('actions.buy_action') }}
    </button-4>
    <div class="licences-info-wrapper flex-global">
      <div class="licences-info-wrapper__box">
        <p class="title">{{ $t('reflectus_platform') }}</p>
        <div class="content">
          <div v-for="(point, index) in reflectusPlatformPoints" :key="index">
            <svgicon name="checkbox-green" :original="true" width="12px" height="10px" :fill="false"></svgicon>
            <span>{{ point.text }}</span>
          </div>
        </div>
      </div>
      <div class="licences-info-wrapper__box">
        <p class="title">{{ $t('teaching_platform') }}</p>
        <div class="content">
          <div v-for="(point, index) in teachingPoints" :key="index">
            <svgicon name="checkbox-green" :original="true" width="12px" height="10px" :fill="false"></svgicon>
            <span>{{ point.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="get-in-touch flex-global">
      <p>{{ $t('licences.select_a_licence_message_alt') }}</p>
      <a href="https://share.hsforms.com/1z55MNM4ESEyCY13gMNJgHw4iwze" target="_blank">
        {{ $t('actions.get_in_touch_action') }}
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LicencePurchaseCard from '@/components/user/licences/LicencePurchaseCard.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import {
  LICENCE_TYPE_TEACHER,
  LICENCE_TYPE_ORG,
  LICENCE_DURATION_TYPE_MONTHLY,
  LICENCE_DURATION_TYPE_ANNUAL,
} from '@/helpers/constants/licence';

export default {
  name: 'StepLicences',
  components: { LicencePurchaseCard, Button4 },
  data() {
    return {
      purchaseTypes: {
        teacher: LICENCE_TYPE_TEACHER,
        org: LICENCE_TYPE_ORG,
      },
      cycleTypes: {
        monthly: LICENCE_DURATION_TYPE_MONTHLY,
        yearly: LICENCE_DURATION_TYPE_ANNUAL,
      },
      purchaseType: LICENCE_TYPE_TEACHER,
      // rf-403 hide monthly licences (change default to monthly here)
      cycleType: LICENCE_DURATION_TYPE_ANNUAL,
      isLoading: false,
      currentSelectedOrg: null,
      licenceForOrgAmountsList: [5, 10, 25, 50],
      currentAmountSelected: 5,
      amountSelectedValue: '',
      isCustomInputActive: false,
      reflectusPlatformPoints: [
        {
          text: this.$t('licences.platform_info_1'),
        },
        {
          text: this.$t('licences.platform_info_2'),
        },
        {
          text: this.$t('licences.platform_info_3'),
        },
        {
          text: this.$t('licences.platform_info_4'),
        },
        {
          text: this.$t('licences.platform_info_5'),
        },
      ],
      teachingPoints: [
        {
          text: this.$t('licences.teaching_platform_1'),
        },
        {
          text: this.$t('licences.teaching_platform_2'),
        },
        {
          text: this.$t('licences.teaching_platform_3'),
        },
      ],
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('licences', [
      'licencesForPurchase',
      'licencesForm',
      'defaultPurchaseAmount',
      'defaultPurchaseAmountOrg',
    ]),
    ...mapGetters('user', ['userOrganizations', 'currentActiveOrganization']),
    areOrganizationsPresent() {
      return (this.userOrganizations && this.userOrganizations.length) || false;
    },
    licencesForPurchaseData() {
      return this.licencesForPurchase
        .filter((licence) => licence.licenseDuration === this.cycleType)
        .map((licence) => {
          return {
            id: licence.id,
            name: licence.title,
            description: licence.description,
            price: licence.price,
            duration:
              licence.licenseDuration === this.cycleTypes.monthly
                ? this.$t('licences.licence_duration_monthly')
                : this.$t('licences.licence_duration_annual'),
            isBestOption: false,
            saveMessage: licence.discountText,
            type: licence.type,
            isSelected: (this.activeLicence && this.activeLicence.id) === licence.id,
          };
        });
    },
    activeLicence() {
      const formValue = this.licencesForm.selectedLicence;
      return formValue;
    },
    isFromOrganization() {
      return (this.$route.params && this.$route.params.isFromOrg) || false;
    },
    defaultPaymentType() {
      return this.isFromOrganization ? this.purchaseTypes.org : this.purchaseTypes.teacher;
    },
  },
  methods: {
    ...mapActions('licences', [
      'getLicencesForPurchase',
      'updateLicenceForm',
      'addLicenceToCart',
    ]),
    ...mapActions('user', ['getUserOrganizations', 'setCurrentActiveOrganization']),
    handleGetLicencesForPurchase() {
      this.isLoading = true;
      this.getLicencesForPurchase().then((res) => {
        const { success, data } = res;
        if (success && data && data.length) {
          const foundLicence = this.licencesForPurchase.find(
            (licence) => licence.id === this.licencesForPurchaseData[0].id
          );
          this.updateLicenceForm({ type: 'selectedLicence', property: foundLicence });
          this.addLicenceToCart(foundLicence);
        }
        this.isLoading = false;
      });
    },
    handleGetUserOrganizations() {
      if (!this.userOrganizations) {
        this.getUserOrganizations().then((res) => {
          const { success, data } = res;
          if (success) {
            const organizations = data && data.data && data.data.length ? data.data : null;

            if (organizations && organizations.length) {
              const foundOrg = this.currentActiveOrganization
                ? organizations.find((org) => org.id === this.currentActiveOrganization.id)
                : organizations[0];

              this.currentSelectedOrg = foundOrg ? foundOrg.id : null;
            } else {
              this.currentSelectedOrg = null;
            }
          }
        });
      } else {
        const organizations =
          this.userOrganizations && this.userOrganizations.length ? this.userOrganizations : null;

        if (organizations && organizations.length) {
          const foundOrg = this.currentActiveOrganization
            ? organizations.find((org) => org.id === this.currentActiveOrganization.id)
            : organizations[0];
          this.currentSelectedOrg = foundOrg ? foundOrg.id : null;
        } else {
          this.currentSelectedOrg = null;
        }
      }
    },
    handlePurchaseTypeChange(value) {
      this.purchaseType = value;

      if (value === this.purchaseTypes.teacher) {
        this.handleAmountSelect(this.defaultPurchaseAmount);
      } else {
        this.handleAmountSelect(this.defaultPurchaseAmountOrg);
      }

      this.updateLicenceForm({ type: 'licenceType', property: value });
    },
    handleCycleTypeChange(value) {
      this.cycleType = value;
      const foundLicence = this.licencesForPurchase.find(
        (licence) => licence.id === this.licencesForPurchaseData[0].id
      );
      this.updateLicenceForm({ type: 'selectedLicence', property: foundLicence });
      this.addLicenceToCart(foundLicence);
    },
    handleLicenceSelect(licenceId) {
      const foundLicence = this.licencesForPurchase.find((licence) => licence.id === licenceId);
      this.updateLicenceForm({ type: 'selectedLicence', property: foundLicence });
      this.addLicenceToCart(foundLicence);
    },
    handleNextStep() {
      this.$router.push({ name: 'StepCheckout' });
    },
    handlePageInit() {
      this.handlePurchaseTypeChange(this.defaultPaymentType);
      this.handleGetLicencesForPurchase();
      this.handleGetUserOrganizations();
    },
    handleAmountSelect(value) {
      this.isCustomInputActive = false;
      this.amountSelectedValue = '';
      this.currentAmountSelected = value;
      this.updateLicenceForm({ type: 'quantity', property: value });
    },
    handleCustomAmountSelect(e) {
      this.isCustomInputActive = true;
      this.currentAmountSelected = 0;
      const value = parseInt(e.target.value, 10);
      setTimeout(() => {
        const amount = value >= 1 ? value : 1;
        this.currentAmountSelected = amount;
        this.amountSelectedValue = amount;
        this.updateLicenceForm({ type: 'quantity', property: amount });
      }, 1000);
    },
    handleOrganizationSelect(value) {
      this.updateLicenceForm({ type: 'selectedOrg', property: value });
      this.setCurrentActiveOrganization(value);
    },
  },
  watch: {
    currentSelectedOrg(newVal) {
      if (this.purchaseType === this.purchaseTypes.org) {
        this.handleOrganizationSelect(newVal);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.step {
  &.licences {
    flex-direction: column;

    .licence-type-switch {
      &.reverse {
        flex-direction: row-reverse;
      }

      span {
        margin: 0 16px;
        font-size: 14px;
        font-weight: 400;
        color: $violet-3;
        cursor: pointer;

        &:hover {
          &:not(.active-type) {
            text-decoration: underline;
          }
        }

        &.active-type {
          font-size: 18px;
          font-weight: 600;
          color: $grey-9;
          cursor: default;
        }
      }
    }

    .payment-cycle-switch {
      margin-top: 37px;

      .title {
        margin-right: 10px;
        font-size: 16px;
        font-weight: 600;
      }

      .switch-wrapper {
        padding: 2px;
        border: 2px solid #dedfe0;
        border-radius: 25.5px;

        &__item {
          padding: 0 27px;
          border: 2px solid #fff;
          border-radius: 25.5px;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 20px;
          }

          span {
            font-size: 14px;
            font-weight: 600;
            color: $grey-3;
          }

          &.active-cycle {
            border: 2px solid $violet-3;

            span {
              color: $violet-3;
            }
          }
        }
      }
    }

    .organization-settings {
      margin-top: 37px;
      flex-direction: column;
      width: 100%;

      &__select {
        width: 100%;

        span {
          font-size: 16px;
          font-weight: 600;
        }

        select {
          margin-left: 60px;
          padding-left: 8px;
          width: 222px;
          height: 37px;
          border: 1px solid $grey-10;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          color: $grey-3;
        }
      }

      &__amount {
        margin-top: 40px;

        span {
          font-size: 16px;
          font-weight: 600;
        }

        .amount-wrapper {
          margin-left: 22px;
          padding: 8px;
          border: 1px solid $grey-10;
          border-radius: 30px;

          .amount-value {
            padding: 12px 22px;
            border: 1px solid $grey-10;
            border-radius: 30px;
            font-family: 'Open Sans Regular';
            font-size: 14px;
            font-weight: 400;
            color: $grey-3;
            cursor: pointer;

            input {
              width: 87px;
              padding-left: 8px;
              border: none;
              font-family: 'Open Sans Regular';
              font-size: 14px;
              font-weight: 400;
              color: $grey-3;
            }

            &.active {
              border: 2px solid $violet-3;
              font-weight: 600;
              color: $violet-3;

              input {
                font-weight: 600;
                color: $violet-3;
              }
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            &.custom {
              padding: 12px 10px;
            }
          }
        }
      }
    }

    .licences-list-wrapper {
      margin-top: 40px;
    }

    .buy-button {
      margin-top: 30px;
      min-width: 202px;
      padding: 12px;
    }

    .licences-info-wrapper {
      margin-top: 50px;
      align-items: flex-start;

      &__box {
        .title {
          text-align: center;
          font-size: 16px;
          font-weight: 700;
        }

        .content {
          padding: 20px 52px;

          div {
            margin-bottom: 4px;

            svg {
              stroke: none;
              margin-right: 12px;
            }

            span {
              font-size: 14px;
              font-weight: 400;
              color: #888888;
            }
          }
        }

        &:not(:last-child) {
          .content {
            border-right: 1px solid #ccc;
          }
        }
      }
    }

    .get-in-touch {
      justify-content: space-between;
      width: 866px;
      margin-top: 47px;
      padding: 20px 36px;
      border-radius: 999px;
      background-color: $grey-6;

      a {
        height: 35px;
        padding: 7px 40px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        border-radius: 999px;
        border: 1px solid $violet-3;
        color: $violet-3;
        background-color: #fff;

        &:hover {
          color: #fff;
          background-color: $violet-3;
        }
      }
    }
  }
}
</style>
