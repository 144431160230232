/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stats': {
    width: 16.61,
    height: 22.634,
    viewBox: '0 0 16.61 22.634',
    data: '<g data-name="Group 1956"><g data-name="Group 1567" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Line 168" d="M.75 10.392v11.492"/><path pid="1" data-name="Line 169" d="M5.787 13.338v8.546"/><path pid="2" data-name="Line 170" d="M15.86.75v21.134"/><path pid="3" data-name="Line 171" d="M10.824 8.992v12.892"/></g></g>'
  }
})
