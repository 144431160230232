/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-injured': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.43" cy="12.44" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="12.44" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M9 18.47a5.38 5.38 0 0 1 2.52-1.34 6.82 6.82 0 0 1 2.88-.05 7.21 7.21 0 0 1 2.64 1.1 6.26 6.26 0 0 1 1.94 2 .31.31 0 0 1-.41.44l-.29-.14c-.69-.34-1.34-.66-2-.92a12.51 12.51 0 0 0-2.24-.67 11.05 11.05 0 0 0-2.31-.2 14.5 14.5 0 0 0-2.44.31.31.31 0 0 1-.29-.53z" _fill="#654735"/><path pid="6" d="M26.31 9.89a13.4 13.4 0 0 0-2.78-5.26L4.1 3.61A13.46 13.46 0 0 0 .78 8.56z" _fill="#f0f1f1"/><path pid="7" _fill="#e5e7e8" d="M5.18 8.77l14.66-4.34L7.6 8.91l-2.42-.14z"/><path pid="8" d="M3.65 4.06a13.41 13.41 0 0 0-3.39 6.17l22.32-6.61a13.41 13.41 0 0 0-6.2-3.34z" _fill="#fff"/><path pid="9" d="M3 4.76l16.12-3.52a13.34 13.34 0 0 0-2.74-1L3.65 4.06c-.22.22-.43.46-.65.7z" _fill="#f8f8f8"/><path pid="10" d="M5.23 9.94l.42-.12c.3-.08.58-.16.86-.26a7.6 7.6 0 0 0 1.13-.48 6.69 6.69 0 0 0 1-.66 10.06 10.06 0 0 0 1-.89.06.06 0 0 1 .1 0 2.54 2.54 0 0 1-.52 1.36 3.33 3.33 0 0 1-1.13 1 3.52 3.52 0 0 1-1.36.39h-.09A2.94 2.94 0 0 1 5.22 10a.06.06 0 0 1 0-.11z" _fill="#3f3e40"/></g></g>'
  }
})
