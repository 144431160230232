/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people': {
    width: 23.946,
    height: 13.866,
    viewBox: '0 0 23.946 13.866',
    data: '<g data-name="Group 57" transform="translate(-201.589 -247.342)" _fill="none" _stroke="#505e67" stroke-miterlimit="10" stroke-width=".822"><circle pid="0" data-name="Ellipse 15" cx="2.774" cy="2.774" r="2.774" transform="translate(210.788 247.753)"/><path pid="1" data-name="Path 22" d="M218.955 258.694a5.394 5.394 0 1 0-10.787 0 2.1 2.1 0 0 0 2.1 2.1h6.581a2.1 2.1 0 0 0 2.106-2.1z"/><g data-name="Group 49"><g data-name="Group 47"><circle pid="2" data-name="Ellipse 16" cx="2.41" cy="2.41" r="2.41" transform="translate(218.028 248.423)"/><path pid="3" data-name="Path 23" d="M220.009 259.756h3.291a1.827 1.827 0 0 0 1.828-1.827 4.688 4.688 0 0 0-7-4.078"/></g><g data-name="Group 48"><circle pid="4" data-name="Ellipse 17" cx="2.41" cy="2.41" r="2.41" transform="translate(204.276 248.423)"/><path pid="5" data-name="Path 24" d="M207.114 259.756h-3.287a1.827 1.827 0 0 1-1.827-1.827 4.687 4.687 0 0 1 7-4.078"/></g></g></g>'
  }
})
