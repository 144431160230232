/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-saint': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 29.52',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="16.17" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 4.77A13.36 13.36 0 0 1 2.1 23.34 13.35 13.35 0 1 0 20.34 4.77z" _fill="#ffc448"/><path pid="2" d="M1.81 17.58A12.4 12.4 0 0 1 24 10 12.4 12.4 0 1 0 3.46 23.75a12.34 12.34 0 0 1-1.65-6.17z" _fill="#ffd77a"/><path pid="3" d="M7.88 18.19a.44.44 0 0 0-.72.48 6.69 6.69 0 0 0 12.38.12.44.44 0 0 0-.71-.49c-1.95 1.9-6.1 4.59-10.95-.11z" _fill="#654735"/><path pid="4" d="M9.91 14.25a2.47 2.47 0 0 0-4.58 0 .4.4 0 0 0 .64.44 2.12 2.12 0 0 1 3.29 0 .4.4 0 0 0 .65-.43zM21.38 14.25a2.47 2.47 0 0 0-4.57 0 .4.4 0 0 0 .64.44 2.12 2.12 0 0 1 3.29 0 .4.4 0 0 0 .65-.43z" _fill="#2c2c2c"/><path pid="5" d="M21.87 2.27a2.89 2.89 0 0 0-.94-.93A7.59 7.59 0 0 0 18.7.43 16.43 16.43 0 0 0 14.07 0a.28.28 0 0 0 0 .55 19.57 19.57 0 0 1 4.23.94 6 6 0 0 1 1.7.89 2.39 2.39 0 0 1 .27.26 1.16 1.16 0 0 1 .16.24.42.42 0 0 1 0 .37 2.25 2.25 0 0 1-1.13.85 9.89 9.89 0 0 1-1.89.59 17.29 17.29 0 0 1-2.1.31c-.67.07-1.37.1-2.14.1A21.16 21.16 0 0 1 11 5a17.53 17.53 0 0 1-2-.33 9.85 9.85 0 0 1-1.89-.59A2.2 2.2 0 0 1 6 3.23a.42.42 0 0 1 0-.36 1.6 1.6 0 0 1 .44-.5 6.1 6.1 0 0 1 1.7-.86 19.63 19.63 0 0 1 4.21-.94.28.28 0 0 0 0-.55A16.51 16.51 0 0 0 7.7.43a7.74 7.74 0 0 0-2.23.9 2.94 2.94 0 0 0-.95.92 1.84 1.84 0 0 0-.25.68 1.62 1.62 0 0 0 .06.74 2.5 2.5 0 0 0 .79 1.12 5.31 5.31 0 0 0 1.06.69 10.74 10.74 0 0 0 2.28.81 20.1 20.1 0 0 0 4.72.53 20.14 20.14 0 0 0 4.72-.53 10.84 10.84 0 0 0 2.28-.81 5.47 5.47 0 0 0 1.06-.69 3 3 0 0 0 .46-.48 2 2 0 0 0 .3-.61 1.7 1.7 0 0 0-.13-1.43z" _fill="#47c4e9"/><path pid="6" d="M21.24 4.8a3 3 0 0 0 .46-.48 2 2 0 0 0 .3-.62 1.65 1.65 0 0 0 0-1.07c-.82 3.34-7.81 4-10 4.16h1.11a20.14 20.14 0 0 0 4.72-.53 10.84 10.84 0 0 0 2.28-.81 5.47 5.47 0 0 0 1.13-.65z" _fill="#22bcdc"/></g></g>'
  }
})
