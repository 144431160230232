/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pie-chart': {
    width: 27.482,
    height: 27.489,
    viewBox: '0 0 27.482 27.489',
    data: '<g data-name="Group 1955"><g data-name="Group 1941" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 1164" d="M12.187 15.301V3.863a11.437 11.437 0 1 0 11.43 11.438z"/><path pid="1" data-name="Path 1165" d="M26.732 12.186A11.434 11.434 0 0 0 15.302.749v11.437z"/></g></g>'
  }
})
