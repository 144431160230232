<template>
  <section id="main" v-if="!loading">
    <div class="main__container">
      <create-reflection :type="'new'"></create-reflection>
      <mobile v-if="selectedMethod"></mobile>
      <method-list v-else></method-list>
    </div>
    <navigation-modal v-show="questionnaireNavigation.modal"></navigation-modal>
  </section>
  <loading v-else></loading>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import CreateReflection from '@/components/reflections/CreateReflection.vue';
  import MethodList from '@/components/methods/MethodList.vue';
  import Mobile from '@/components/mobile/Mobile.vue';
  import NavigationModal from '@/components/base/NavigationModal.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'NewReflection',
    components: {
      CreateReflection,
      MethodList,
      Mobile,
      NavigationModal,
      Loading,
    },
    created() {
      this.handlePageInit();
    },
    beforeDestroy() {
      this.handlePageDestroy();
    },
    computed: {
      ...mapGetters('methods', ['selectedMethod', 'questionTypeList']),
      ...mapGetters('reflections', ['editStatus']),
      ...mapGetters('questionnaire', ['answerTemplate', 'questionExamples']),
      ...mapGetters('meta', ['questionnaireNavigation', 'loading']),
    },
    methods: {
      ...mapActions('meta', ['setLoading']),
      ...mapActions('methods', ['getQuestionList']),
      ...mapActions('reflections', ['setEditStatus']),
      ...mapActions('questionnaire', [
        'getAnswerTemplate',
        'resetQuestionnaireState',
        'getQuestionExamples',
      ]),
      async handlePageInit() {
        this.setLoading(true);
        if (this.editStatus !== 'edit') this.setEditStatus('edit');
        try {
          if (!this.questionTypeList.length) await this.getQuestionList();
          if (!this.answerTemplate.length) await this.getAnswerTemplate();
          if (!this.questionExamples.length) await this.getQuestionExamples();
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      handlePageDestroy() {
        this.resetQuestionnaireState();
        this.setEditStatus('view');
      },
    },
  };
</script>
<style lang="scss">
  #main {
    .main__container {
      display: flex;
      max-width: 940px;
      margin: 20px auto 0 auto;
      justify-content: space-between;
    }
  }
</style>
