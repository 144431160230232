<template>
  <div v-if="isPasswordField" class="advanced-input-wrapper flex-global">
    <div class="advanced-input-wrapper__title">
      <span class="title">{{ title }}</span>
      <span class="value">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
    </div>
    <div class="advanced-input-wrapper__button-wrapper">
      <button-4 class="edit-button" @click.native="togglePasswordModal">
        {{ $t('actions.edit_action') }}
      </button-4>
      <div v-if="isPasswordEdit" class="modal" @click="togglePasswordModal">
        <div class="modal__bg">
          <div class="modal__modal" @click.stop>
            <div class="modal__container">
              <svgicon
                @click="togglePasswordModal"
                color="#000000"
                :fill="false"
                name="multiply"
                class="modal__close"
              ></svgicon>
              <div class="modal__title">
                <p>{{ $t('actions.edit_action') }}</p>
              </div>
              <!-- current password input -->
              <div class="modal__input-field flex-global">
                <span :class="['input-title', { 'error-message': $v.passReset.password.$error }]">
                  {{ $t('profile.old_password') }}
                </span>
                <input
                  type="password"
                  :class="{ error: $v.passReset.password.$error }"
                  v-model="passReset.password"
                />
                <div class="error-messages-wrapper flex-global">
                  <span
                    class="error-message"
                    v-if="!$v.passReset.password.required && $v.passReset.password.$error"
                  >
                    {{ $t('warnings.finish_typing') }}
                  </span>
                  <span
                    class="error-message"
                    v-if="!$v.passReset.password.minLength && $v.passReset.password.$error"
                  >
                    {{ $t('warnings.min_symbols_8') }}
                  </span>
                  <span
                    class="error-message"
                    v-if="!$v.passReset.password.MaxLength && $v.passReset.password.$error"
                  >
                    {{ $t('warnings.max_symbols_30') }}
                  </span>
                </div>
              </div>
              <!-- new password input -->
              <div class="modal__input-field flex-global">
                <span
                  :class="['input-title', { 'error-message': $v.passReset.newPassword.$error }]"
                >
                  {{ $t('profile.new_password') }}
                </span>
                <input
                  type="password"
                  :class="{ error: $v.passReset.newPassword.$error }"
                  v-model="passReset.newPassword"
                />
                <div class="error-messages-wrapper flex-global">
                  <span
                    class="error-message"
                    v-if="!$v.passReset.newPassword.required && $v.passReset.newPassword.$error"
                  >
                    {{ $t('warnings.finish_typing') }}
                  </span>
                  <span
                    class="error-message"
                    v-if="!$v.passReset.newPassword.minLength && $v.passReset.newPassword.$error"
                  >
                    {{ $t('warnings.min_symbols_8') }}
                  </span>
                  <span
                    class="error-message"
                    v-if="!$v.passReset.newPassword.MaxLength && $v.passReset.newPassword.$error"
                  >
                    {{ $t('warnings.max_symbols_30') }}
                  </span>
                </div>
              </div>
              <!-- new password repeat input -->
              <div class="modal__input-field flex-global">
                <span
                  :class="['input-title', { 'error-message': $v.passReset.newPassword2.$error }]"
                >
                  {{ $t('profile.new_password_repeat') }}
                </span>
                <input
                  type="password"
                  :class="{ error: $v.passReset.newPassword2.$error }"
                  v-model="passReset.newPassword2"
                />
                <div class="error-messages-wrapper flex-global">
                  <span
                    class="error-message"
                    v-if="!$v.passReset.newPassword2.required && $v.passReset.newPassword2.$error"
                  >
                    {{ $t('warnings.finish_typing') }}
                  </span>
                  <span
                    class="error-message"
                    v-if="!$v.passReset.newPassword2.sameAs && $v.passReset.newPassword2.$error"
                  >
                    {{ $t('warnings.password_mismatch') }}
                  </span>
                </div>
              </div>
              <div class="modal__buttons flex-global">
                <button-3 class="profile__actions__button" @click.native="togglePasswordModal">
                  {{ $t('actions.back_action') }}
                </button-3>
                <button-4 class="profile__actions__button" @click.native="handlePasswordUpdate">
                  {{ $t('actions.change_password_action') }}
                </button-4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="advanced-input-wrapper flex-global">
    <div class="advanced-input-wrapper__title flex-global">
      <span class="title">{{ title }}</span>
      <span v-if="organizations">
        <p v-for="(org, index) in organizations" :key="index">{{ org }}</p>
      </span>
      <span v-else class="value">
        {{ valueToDisplay }}
      </span>
    </div>
    <div class="advanced-input-wrapper__button-wrapper">
      <button-4 v-if="!readOnly" class="edit-button" @click.native="toggleEditModal">
        {{ $t('actions.edit_action') }}
      </button-4>
      <div v-if="isEdit" class="modal" @click="toggleEditModal">
        <div class="modal__bg">
          <div class="modal__modal" @click.stop>
            <div class="modal__container">
              <svgicon
                @click="toggleEditModal"
                color="#000000"
                :fill="false"
                name="multiply"
                class="modal__close"
              ></svgicon>
              <div class="modal__title">
                <p>{{ $t('actions.edit_action') }}</p>
              </div>
              <div class="modal__input-field flex-global">
                <span :class="['input-title', { 'error-message': isError }]">{{ title }}</span>
                <input type="text" :class="{ error: isError }" v-model="currentValue" />
                <slot name="input-error"></slot>
              </div>
              <div class="modal__buttons flex-global">
                <button-3 class="profile__actions__button" @click.native="toggleEditModal">
                  {{ $t('actions.back_action') }}
                </button-3>
                <button-4 class="profile__actions__button" @click.native="handleEdit">
                  {{ $t('actions.save_action') }}
                </button-4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'AdvancedField',
    props: [
      'title',
      'valueTitle',
      'value',
      'valueToDisplay',
      'isError',
      'readOnly',
      'isPasswordField',
      'organizations',
    ],
    components: {
      Button3,
      Button4,
    },
    data() {
      return {
        isEdit: false,
        isPasswordEdit: false,
        currentValue: this.value,
        passReset: {
          password: null,
          newPassword: null,
          newPassword2: null,
        },
      };
    },
    validations: {
      passReset: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(30),
        },
        newPassword: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(30),
        },
        newPassword2: {
          required,
          sameAs: sameAs('newPassword'),
        },
      },
    },
    computed: {
      updatePassIsValid() {
        return !this.$v.passReset.$invalid;
      },
    },
    methods: {
      ...mapActions('user', ['updatePassword']),
      toggleEditModal() {
        this.isEdit = !this.isEdit;
      },
      togglePasswordModal() {
        this.isPasswordEdit = !this.isPasswordEdit;
      },
      closeEditModal() {
        this.isEdit = false;
      },
      closePasswordModal() {
        this.isPasswordEdit = false;
      },
      handleEdit() {
        this.$emit('handleEdit', { name: this.valueTitle, value: this.currentValue });
      },
      handlePasswordUpdate() {
        this.$v.passReset.$touch();
        if (!this.updatePassIsValid) return;

        this.updatePassword(this.passReset).then((res) => {
          const { success } = res;
          if (success) {
            this.closePasswordModal();
            this.$toast.success(this.$t('modals.pass_reset_modal_pass_changed_message'));
            this.resetPasswordFields();
            this.$v.$reset();
          }
        });
      },
      resetPasswordFields() {
        this.passReset = {
          password: null,
          newPassword: null,
          newPassword2: null,
        };
      },
    },
  };
</script>
<style lang="scss">
  .advanced-input-wrapper {
    justify-content: space-between;
    padding: 18px 0;
    max-width: 865px;
    border-top: 1px solid #dedfe0;
    &__title {
      align-items: flex-start;
      span {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        color: $grey-5;
        &.title {
          width: 165px;
          margin-right: 35px;
          font-weight: 600;
          text-transform: capitalize;
          &.non-capitalized {
            text-transform: none;
          }
        }
      }
    }
    &__button-wrapper {
      .edit-button {
        font-family: 'Nunito-Regular';
        font-size: 14px;
        font-weight: 600;
        color: $violet-3;
        background: none;
        &:hover {
          border-radius: 3px;
          background-color: $grey-6;
        }
      }
    }
    .modal {
      &__modal {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &__container {
        padding: 20px 27px;
        width: 500px;
        border-radius: 5px;
      }
      &__close {
        width: 14px;
        height: 14px;
        top: 25px;
        right: 32px;
      }
      &__title {
        width: 100%;
        margin-bottom: 18px;
        p {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: $grey-9;
        }
      }
      &__input-field {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 36px;
        span {
          font-size: 12px;
          font-weight: 400;
          &.input-title {
            color: $grey-5;
          }
          &.error-message {
            color: $red-4;
          }
        }
        input {
          width: 100%;
          height: 33px;
          padding-left: 5px;
          border: 1px solid #d1d1d1;
          border-radius: 2px;
          &.error {
            border: 1px solid $red-4;
          }
        }
        .error-messages-wrapper {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      &__buttons {
        justify-content: flex-end;
        width: 100%;
        button {
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>
