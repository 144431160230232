/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '404/x': {
    width: 14.507,
    height: 14.507,
    viewBox: '0 0 14.507 14.507',
    data: '<g data-name="Group 3909"><path pid="0" data-name="Path 3868" d="M14.055 10.553L8.636 6.971l3.582-5.419a1 1 0 0 0-1.669-1.1l-3.582 5.42L1.548 2.29a1 1 0 1 0-1.1 1.668L5.867 7.54l-3.582 5.419a1 1 0 0 0 1.669 1.1l3.582-5.42 5.419 3.582a1 1 0 1 0 1.1-1.668z" _fill="#66c"/></g>'
  }
})
