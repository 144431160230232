/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 16,
    height: 16,
    viewBox: '0 0 11.79 11.79',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#3b328d" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M5.89.5v5.39H.5M5.89 11.29v-5.4h5.4"/></g></g>'
  }
})
