<template>
  <figure class="doughnut-chart__container" v-if="groupStatQuestion">
    <doughnut-chart
      class="doughnut-chart"
      :chart-data="pieChartData"
      :options="pieChartOptions"
    ></doughnut-chart>
  </figure>
</template>
<script>
import DoughnutChart from '@/components/stats/chart-templates/DoughnutChart.vue';

export default {
  name: 'DoughnutTemplate',
  components: {
    DoughnutChart,
  },
  props: ['groupStatQuestion'],
  computed: {
    pieChartOptions() {
      return {
        responsive: true,
        legend: false,
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const indice = tooltipItem.index;
              return `${data.labels[indice]}: ${data.datasets[0].data[indice]}%`;
            },
          },
        },
      };
    },
    pieChartData() {
      return {
        labels: this.groupStatQuestion.map((item) => item.value),
        datasets: [
          {
            data: this.groupStatQuestion.map((item) => item.percentage),
            backgroundColor: this.groupStatQuestion.map((item) => item.color),
            borderColor: 'transparent',
            borderWidth: 0,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss">
.doughnut-chart {
  &__container {
    height: 200px;
    width: 200px;
  }
}
</style>
