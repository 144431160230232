/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/diary': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 3833"><g data-name="Group 1452" transform="translate(-701.857 -413.465)"><circle pid="0" data-name="Ellipse 279" cx="16.559" cy="16.559" r="16.559" transform="rotate(-.5 47763.343 -80219.386)" _fill="#6666ca"/><g data-name="Group 1451"><g data-name="Group 1450"><g data-name="Group 1449" _fill="#fff"><path pid="1" data-name="Path 998" d="M711.634 422.589a.71.71 0 0 0-.706.71v10.645a.712.712 0 0 0 .711.71 8.778 8.778 0 0 1 6.33 2.347v-11.14a.678.678 0 0 0-.1-.36c-1.569-2.524-4.579-2.908-6.235-2.912z"/><path pid="2" data-name="Path 999" d="M726.111 433.944V423.3a.709.709 0 0 0-.706-.71c-1.656 0-4.668.388-6.236 2.912a.679.679 0 0 0-.1.36V437a8.778 8.778 0 0 1 6.33-2.347.713.713 0 0 0 .712-.709z"/><path pid="3" data-name="Path 1000" d="M727.728 425.044h-.515v8.9a1.816 1.816 0 0 1-1.81 1.812 7.869 7.869 0 0 0-5.349 1.826 17.081 17.081 0 0 1 7.515.146.71.71 0 0 0 .868-.692v-11.282a.71.71 0 0 0-.709-.71z"/><path pid="4" data-name="Path 1001" d="M709.824 433.944v-8.9h-.515a.711.711 0 0 0-.71.71v11.282a.71.71 0 0 0 .869.691 17.1 17.1 0 0 1 7.515-.145 7.869 7.869 0 0 0-5.349-1.826 1.816 1.816 0 0 1-1.81-1.812z"/></g></g></g></g></g>'
  }
})
