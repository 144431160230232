import i18n from '../../../plugins/i18n'

export const handleQuestionTitle = (question, commit, i) => {
  if (question.title) question.title = question.title.trim();
  if (!question.title) {
    commit('SET_QUESTION_ERROR', { index: i, error: i18n.t("questionnaire.question_type_placeholder") });
  } else delete question.error;
};

export const handleOptionError = (
  option,
  optionIndex,
  optionsLength,
  questionIndex,
  answerCount,
  commit,
) => {
  if (option.value) option.value = option.value.trim();
  if (option.show) option.show = option.show.trim();
  if (option.minTitle) option.minTitle = option.minTitle.trim();
  if (option.maxTitle) option.maxTitle = option.maxTitle.trim();

  if (!option.value || !option.show) {
    if (answerCount > -1) {
      commit('SET_OPTION_ERROR', {
        questionIndex,
        optionIndex,
        error: i18n.t("warnings.finish_answer_option_message"),
      });
    } else if (optionIndex >= 0 && optionIndex !== optionsLength - 1) {
      commit('SET_OPTION_ERROR', {
        questionIndex,
        optionIndex,
        error: i18n.t("warnings.finish_answer_option_message"),
      });
    }
  } else commit('SET_OPTION_ERROR', { questionIndex, optionIndex, error: null });
};

export const handleQuestionOptionErrors = (options, commit, i, answerCount) => {
  if (answerCount === '-1') {
    if (options.length < 3) {
      commit('SET_QUESTION_ERROR', { index: i, error: i18n.t("warnings.add_at_least_two_options_message") });
      return;
    }
  }
  if (options.length < 2) {
    commit('SET_QUESTION_ERROR', { index: i, error: i18n.t("warnings.add_at_least_two_options_message") });
    return;
  }

  options.forEach((option, optionIndex) => {
    handleOptionError(option, optionIndex, options.length, i, answerCount, commit);
  });

  if (options.filter((option) => option.error).length) {
    commit('SET_QUESTION_ERROR', { index: i, error: i18n.t("warnings.finish_filling_out_options_message") });
  }
};

const setAnswerOptionError = (errorCount, questionIndex, optionKey, error, commit) => {
  commit('SET_QUESTION_ERROR_COUNT', errorCount);
  commit('SET_ANSWER_OPTION_ERROR', { questionIndex, optionKey, error });
};

export const handleAnswerOptionsErrors = (answerOptions, i, commit) => {
  const options = Object.entries(answerOptions);
  let errorCount = 0;

  options.forEach(([key, value]) => {
    if (value && typeof value === 'string') value = value.trim();
    const error = [
      i18n.t("warnings.finish_typing"),
      i18n.t("warnings.cant_be_0_message"),
      null,
      i18n.t("warnings.cant_exceed_700_message"),
      i18n.t("warnings.between_2_and_8_message"),
    ];

    if (key === 'min' || key === 'max' || key === 'step') {
      if (!value) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[0], commit);
        // } else if (value * 1 === 0) {
        //   errorCount += 1;
        //   setAnswerOptionError(errorCount, i, key, error[1], commit);
      } else {
        if (errorCount !== 0) errorCount -= 1;
        setAnswerOptionError(errorCount, i, key, error[2], commit);
      }
    } else if (key === 'minTitle' || key === 'maxTitle') {
      if (!value) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[0], commit);
      } else {
        if (errorCount !== 0) errorCount -= 1;
        setAnswerOptionError(errorCount, i, key, error[2], commit);
      }
    } else if (key === 'maxLength') {
      if (!value) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[0], commit);
      } else if (value * 1 === 0) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[1], commit);
      } else if (value * 1 > 700) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[3], commit);
      } else {
        if (errorCount !== 0) errorCount -= 1;
        setAnswerOptionError(errorCount, i, key, error[2], commit);
      }
    } else if (key === 'partCount') {
      if (!value) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[0], commit);
      } else if (value * 1 === 0) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[1], commit);
      } else if (value * 1 < 2 || value * 1 > 8) {
        errorCount += 1;
        setAnswerOptionError(errorCount, i, key, error[4], commit);
      } else {
        if (errorCount !== 0) errorCount -= 1;
        setAnswerOptionError(errorCount, i, key, error[2], commit);
      }
    }
  });

  return errorCount > 0 ? Promise.resolve(true) : Promise.resolve(false);
};

export const handleQuestionPartErrors = (allowUnlimitedPartCount, question, i, commit) => {
  if (!allowUnlimitedPartCount) {
    question.parts.forEach((part, partIndex) => {
      if (part.title) part.title = part.title.trim();
      if (part.show) part.show = part.show.trim();
      if (!part.title) {
        commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: i18n.t("warnings.incomplete_sentence_at_symbol_warning_message") });
      } else if (part.title.indexOf('@') === -1) {
        commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: i18n.t("warnings.incomplete_sentence_at_symbol_warning_message") });
      } else if (part.answerOptions.maxLength > 250) {
        commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: ' ' });
      } else delete commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: null });
    });
  } else {
    question.parts.forEach((part, partIndex) => {
      if (!part.title) {
        if (partIndex === 0) {
          commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: i18n.t("warnings.finish_typing") });
        }
        if (partIndex >= 0 && partIndex !== question.parts.length - 1 && !part.title) {
          commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: i18n.t("warnings.finish_typing") });
        }
      } else commit('SET_PART_ERROR', { questionIndex: i, partIndex, error: null });
    });
  }

  if (question.parts.filter((part) => part.error).length) {
    // @todo temp error fix for parts
    // commit('SET_QUESTION_ERROR', { index: i, error: i18n.t("warnings.finish_creating_question_message") });
    commit('SET_QUESTION_ERROR', { index: i, error: ' ' });
  }
};

export const handleAllQuestionnaireErrors = (questionnaire, commit, question, part, option) => {
  const { title, questions, icon, description } = questionnaire;

  if (title) questionnaire.title = title.trim();
  if (description) questionnaire.description = description.trim();

  if (!title || !questions.length || !icon || !description || question || part || option) {
    if (!title) {
      const msg = i18n.t("warnings.create_title_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'title', error: msg });
    } else {
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'title', error: null });
    }
    if (!description) {
      const msg = i18n.t("warnings.create_desc_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'description', error: msg });
    } else {
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'description', error: null });
    }
    if (!questions.length) {
      const msg = i18n.t("warnings.add_at_least_one_question_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'questions', error: msg });
    } else {
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'questions', error: null });
    }
    if (!icon) {
      const msg = i18n.t("warnings.add_icon_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'icon', error: msg });
    } else {
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'icon', error: null });
    }
    if (part) {
      const msg = i18n.t("warnings.not_all_multi_questions_filled_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'part', error: msg });
    } else commit('SET_QUESTIONNAIRE_ERRORS', { type: 'part', error: null });
    if (question) {
      const msg = i18n.t("warnings.not_all_questions_filled_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'question', error: msg });
    } else commit('SET_QUESTIONNAIRE_ERRORS', { type: 'question', error: null });
    if (option) {
      const msg = i18n.t("warnings.add_question_option_message");
      commit('SET_QUESTIONNAIRE_ERRORS', { type: 'option', error: msg });
    } else commit('SET_QUESTIONNAIRE_ERRORS', { type: 'option', error: null });
  }
};

export const handleQuestionErrors = (questions, commit) => {
  const questionsWithErrors = questions.filter((question) => question.error);
  const questionsWithPartErrors = questions.filter((question) => {
    if (question.parts) return question.parts.findIndex((part) => part.error) > -1;
    return false;
  });
  const questionsWithOptionErrors = questions.filter((question) => {
    const { options } = question.answerOptions;
    if (options) return options.findIndex((option) => option.error) > -1;
    return false;
  });

  const questionsWithAnswerOptionsErrors = questions.map((question) => {
    const { errors } = question.answerOptions;
    const errorArray = Object.values(errors).filter((error) => error !== null);
    return errorArray || [];
  });
  const mergedQuestionsWithAnswerOptionsErrors = [].concat(...questionsWithAnswerOptionsErrors)

  const question = questionsWithErrors.length >= 1;
  const part = questionsWithPartErrors.length >= 1;
  const option = questionsWithOptionErrors.length >= 1;
  const answerOption = mergedQuestionsWithAnswerOptionsErrors.length >= 1;
  const errorTotalCount = [
    questionsWithErrors.length,
    questionsWithPartErrors.length,
    questionsWithOptionErrors.length,
    mergedQuestionsWithAnswerOptionsErrors.length,
  ];
  const errorsLength = errorTotalCount.reduce((a, b) => a + b);
  if (!question && !part && !option && !answerOption && !errorsLength) {
    commit('SET_QUESTION_ERROR_COUNT', null);
    return Promise.resolve(false);
  }
  commit('SET_QUESTION_ERROR_COUNT', errorsLength);
  return Promise.resolve(errorsLength);
};

export const calculateDuration = (methods, questions, commit, template) => {
  commit(template ? 'questionnaire/RESET_TOTAL_DURATION' : 'RESET_TOTAL_DURATION', null, {
    root: !!template,
  });
  let duration = 0;

  questions.forEach(({ methodId }) => {
    methods.forEach((method) => {
      if (methodId === method.id) {
        duration += method.duration;
      }
    });
  });

  commit(template ? 'questionnaire/SET_TOTAL_DURATION' : 'SET_TOTAL_DURATION', duration, {
    root: !!template,
  });
};

export const handleEmptyParts = (question) => {
  if (question.parts && question.parts.length) {
    question.parts.forEach((part, i) => {
      if (!part || !part.title) {
        question.parts.splice(i, 1);
      }
    });
  }
};

export const handleEmptyAnswerOptions = (question) => {
  if (question.answerOptions.options && question.answerOptions.options.length) {
    question.answerOptions.options.forEach((option, i) => {
      if (!option || !option.value) {
        question.answerOptions.options.splice(i, 1);
      }
    });
  }
  // add default number type value for old scale method without number type value
  else if (question.methodId === 8) {
    if (question.answerOptions && !question.answerOptions.numberType) {
      question.answerOptions.numberType = 'integers';
    }
  }
};

export const handleEmptyPartsAndOptions = (question) => {
  handleEmptyParts(question);
  handleEmptyAnswerOptions(question);
};

export const handleQuestions = (questions) => {
  questions.forEach((question) => handleEmptyPartsAndOptions(question));
};

export const handleChangeMethodId = (questions) => {
  questions.forEach((question) => {
    if (question.methodId === 4 && question.answerTemplate === 'select') {
      if (question.id) {
        question.methodId = 16
      }
    }
  });
};

export const handleGetQuestionnaireParams = (questionnaire) => {
  questionnaire.schedules = [];
  questionnaire.questions.forEach((question) => {
    const { errors } = question.answerOptions;
    if (!errors) question.answerOptions.errors = {};
  });
};
