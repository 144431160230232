/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit': {
    width: 14.777,
    height: 14.745,
    viewBox: '0 0 14.777 14.745',
    data: '<g data-name="Group 988" _fill="none" _stroke="#fff" stroke-linecap="round"><path pid="0" data-name="Path 833" d="M7.373 14.245h-5.58A1.293 1.293 0 0 1 .5 12.952V1.793A1.293 1.293 0 0 1 1.793.5h5.58" stroke-miterlimit="10"/><path pid="1" data-name="Line 136" stroke-miterlimit="10" d="M5.56 7.413h8.717"/><path pid="2" data-name="Path 834" d="M10.349 3.486l3.927 3.927-3.927 3.927" stroke-linejoin="round"/></g>'
  }
})
