/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/smileys': {
    width: 33.974,
    height: 33.974,
    viewBox: '0 0 33.974 33.974',
    data: '<g data-name="Group 3832"><g data-name="Group 1409" transform="translate(-61.611 -255.012)"><circle pid="0" data-name="Ellipse 268" cx="16.559" cy="16.559" r="16.559" transform="matrix(1 .026 -.026 1 62.478 255.011)" _fill="#6666ca"/><g data-name="Group 1408"><path pid="1" data-name="Path 943" d="M88.216 266.2a11.236 11.236 0 1 0-12.32 16.7 10.915 10.915 0 0 0 2.715.329 11.226 11.226 0 0 0 9.605-17.029zm-15.753 2.714a2.21 2.21 0 1 1 2.216 2.2 2.205 2.205 0 0 1-2.216-2.196zm6.136 9.9c-3.007 0-5.442-1.887-5.442-4.225a.743.743 0 0 1 .742-.73h9.4a.749.749 0 0 1 .73.73c-.001 2.34-2.436 4.227-5.429 4.227zm3.761-7.694a2.2 2.2 0 1 1 2.2-2.2 2.2 2.2 0 0 1-2.2 2.202z" _fill="#fff"/></g></g></g>'
  }
})
