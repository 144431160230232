/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-star': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffcb00"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc000"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd954"/><path pid="3" d="M21.65 11.27a.62.62 0 0 0-.51-.43l-5-.73-2.23-4.52a.63.63 0 0 0-1.12 0l-2.24 4.53-5 .73a.63.63 0 0 0-.35 1.07l3.62 3.53-.85 5a.63.63 0 0 0 .91.66l4.47-2.35 4.47 2.35a.63.63 0 0 0 .91-.66l-.85-5 3.62-3.53a.62.62 0 0 0 .15-.65z" _fill="#fff"/></g></g>'
  }
})
