/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g data-name="Group 3477"><g data-name="Group 3476"><g data-name="Group 3447" transform="translate(-934.5 -450.5)" _fill="none" _stroke="#919396"><circle pid="0" data-name="Ellipse 1611" cx="13.5" cy="13.5" r="13.5" transform="translate(935 451)"/><g data-name="Group 3441" transform="translate(786.21 -60.303)" stroke-linejoin="round"><path pid="1" data-name="Path 41" d="M166.566 532.299h-8.35l-1.464-10.821h11.277z"/><path pid="2" data-name="Line 11" stroke-linecap="round" d="M156.763 519.052h11.277"/><path pid="3" data-name="Line 12" stroke-linecap="round" d="M160.524 523.811l.438 6.163"/><path pid="4" data-name="Line 13" stroke-linecap="round" d="M164.256 523.811l-.438 6.163"/><rect pid="5" data-name="Rectangle 44" width="4.801" height="1.847" rx=".504" transform="translate(160.001 517.206)" stroke-linecap="round"/></g></g></g></g>'
  }
})
