/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 13.292,
    height: 17.375,
    viewBox: '0 0 13.292 17.375',
    data: '<g data-name="Group 1958"><g data-name="Group 1943" transform="translate(-947.5 -261)"><rect pid="0" data-name="Rectangle 4049" width="12.292" height="9.563" rx="1.618" transform="translate(948 268.312)" _fill="#919396" _stroke="#919396" stroke-miterlimit="10"/><g data-name="Group 1931"><g data-name="Group 1930"><path pid="1" data-name="Path 1151" d="M954.108 269.553a2.027 2.027 0 0 0-1.1 3.731v2.406a1.1 1.1 0 0 0 2.191 0v-2.4a2.027 2.027 0 0 0-1.094-3.734z" _fill="#fff" _stroke="#919396" stroke-miterlimit="10"/></g></g><path pid="2" data-name="Path 1152" d="M951.197 264.91a2.911 2.911 0 0 1 5.822 0" _fill="none" _stroke="#919396" stroke-miterlimit="10" stroke-width="2"/><path pid="3" data-name="Path 1153" d="M957.019 264.91v4.067h-5.822v-4.067" _fill="none" _stroke="#919396" stroke-miterlimit="10" stroke-width="2"/></g></g>'
  }
})
