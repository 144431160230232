/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-yes': {
    width: 15.476,
    height: 15.477,
    viewBox: '0 0 15.476 15.477',
    data: '<g data-name="Group 3242"><g data-name="Group 3182" transform="translate(-524.197 -462.197)"><circle pid="0" data-name="Ellipse 823" cx="7.738" cy="7.738" r="7.738" transform="translate(524.197 462.197)" _fill="#13a85b"/><path pid="1" data-name="Path 1170" d="M535.76 467.524l-5.182 5.629-2.533-2.818" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></g>'
  }
})
