<template>
  <div class="group-reflection__filter">
    <div class="group-reflection__filter__item">
      <span class="uppercase-title">{{ $t('reflection.reflection_date') }}:</span>
    </div>
    <div class="group-reflection__filter__item">
      <span class="group-reflection__filter__item__title uppercase-title">{{ $t('from') }}</span>
      <vue-datepicker
        :monday-first="true"
        :bootstrap-styling="true"
        :full-month-name="true"
        :language="currentCalendarLocale.locale"
        :placeholder="
          moment()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
        "
        :format="customFormatter"
        v-model="fromDate"
        :disabled-dates="disabledDatesFrom"
        v-on:selected="handleSelectFrom"
      ></vue-datepicker>
    </div>
    <div class="group-reflection__filter__item">
      <span class="group-reflection__filter__item__title uppercase-title">{{ $t('to') }}</span>
      <vue-datepicker
        :monday-first="true"
        :bootstrap-styling="true"
        :full-month-name="true"
        :language="currentCalendarLocale.locale"
        :placeholder="moment().format('YYYY-MM-DD')"
        :format="customFormatter"
        v-model="toDate"
        :disabled-dates="disabledDatesTo"
        v-on:selected="handleSelectTo"
      ></vue-datepicker>
    </div>
    <div class="group-reflection__filter__item">
      <button-4 @click.native="handleReflectionFilter">{{ $t('actions.show_action') }}</button-4>
    </div>
  </div>
</template>
<script>
  import VueDatepicker from 'vuejs-datepicker';
  import { lt, en } from 'vuejs-datepicker/dist/locale';

  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'GroupReflectionFilter',
    components: {
      Button4,
      VueDatepicker,
    },
    props: ['disabledDates', 'currentReflectionDates'],
    data() {
      return {
        fromDate: this.moment()
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
        toDate: this.moment().format('YYYY-MM-DD'),
        disabledDatesFrom: {
          from: new Date(),
        },
        disabledDatesTo: {
          to: new Date(this.moment().subtract(1, 'months')),
        },
        calendarLocales: [
          { key: 'lt', locale: lt },
          { key: 'en', locale: en },
        ],
        currentLocale: localStorage.getItem('locale'),
      };
    },
    created() {
      if (this.fromStats) this.handleDateAssign();
    },
    computed: {
      currentCalendarLocale() {
        return this.calendarLocales.find((locale) => locale.key === this.currentLocale);
      },
      fromStats() {
        return this.currentReflectionDates || false;
      },
      fromDateStats() {
        return this.currentReflectionDates && this.currentReflectionDates.start
          ? this.moment(this.currentReflectionDates.start)
              .subtract(1, 'months')
              .format('YYYY-MM-DD')
          : false;
      },
      toDateStats() {
        return this.currentReflectionDates && this.currentReflectionDates.start
          ? this.currentReflectionDates.start
          : false;
      },
    },
    methods: {
      handleDate($event, type) {
        const date = this.moment($event);
        switch (type) {
          case 'fromDate':
            this.fromDate = date;
            break;
          case 'toDate':
            this.toDate = date;
            break;
          default:
            break;
        }
      },
      customFormatter(value) {
        return this.moment(value).format('YYYY-MM-DD');
      },
      handleReflectionFilter() {
        this.$emit('handleReflectionFilter', { fromDate: this.fromDate, toDate: this.toDate });
      },
      handleSelectFrom(value) {
        this.disabledDatesTo.to = value;
      },
      handleSelectTo(value) {
        this.disabledDatesFrom.from = value;
      },
      handleDateAssign() {
        if (this.fromDateStats) {
          this.fromDate = this.fromDateStats;
          this.disabledDatesFrom = { from: new Date(this.fromDateStats) };
        }
        if (this.toDateStats) {
          this.toDate = this.toDateStats;
          this.disabledDatesTo = { to: new Date(this.toDateStats) };
        }
      },
    },
  };
</script>
<style lang="scss">
  .group-reflection__filter {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    background-color: #fff;
    height: 50px;
    border-radius: 5px;

    &__item {
      display: flex;
      align-items: baseline;

      &__title {
        margin-right: 5px;
      }

      .vdp-datepicker {
        width: 180px;
        height: 30px;

        .input-group {
          height: 100%;

          > input {
            padding: 6px 10px;
          }
        }

        > div {
          input {
            width: 180px;
            height: 30px;
            color: $grey-5;
            border: 1px solid $grey-2;
            font-family: Roboto-Regular;
            font-size: 14px;
            padding-left: 5px;

            &::placeholder {
              color: $grey-4;
            }
          }
        }
      }
    }
  }
</style>
