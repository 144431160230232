<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <svgicon class="modal__container__icon" name="large-question" :original="true"></svgicon>
          <h4 class="modal__container__heading" v-if="$route.name === 'NewReflection'">
            {{ $t('modals.nav_modal_finish_quest_create_question') }}
          </h4>
          <h4 class="modal__container__heading" v-if="$route.name === 'EditReflection'">
            {{ $t('modals.nav_modal_finish_quest_edit_question') }}
          </h4>
          <h4 class="modal__container__heading" v-if="$route.name === 'EditTemplate'">
            {{ $t('modals.nav_modal_finish_template_question') }}
          </h4>
          <h4
            class="modal__container__heading reflectionOptionsHeader"
            v-if="$route.name === 'ReflectionOptions'"
          >
            {{ $t('warnings.schedule_unsaved_changes_warning') }}
          </h4>
          <p class="modal__container__warning"></p>
          <div class="modal__container__actions">
            <button-3 @click.native="handleModalToggle">{{ $t('actions.cancel_action') }}</button-3>
            <button-4 @click.native="handleNavigation">{{ $t('yes_cap') }}</button-4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'NavigationModal',
    components: {
      Button3,
      Button4,
    },
    computed: {
      ...mapGetters('meta', ['questionnaireNavigation']),
    },
    methods: {
      ...mapActions('meta', ['setQuestionnaireNavigation']),
      handleModalToggle() {
        this.setQuestionnaireNavigation({ modal: false });
      },
      handleNavigation() {
        this.setQuestionnaireNavigation({ modal: false });
        const { route } = this.questionnaireNavigation;
        if (route === 'Back') {
          this.$router.back();
        } else {
          this.$router.push({ name: this.questionnaireNavigation.route });
        }
      },
    },
  };
</script>
<style scoped>
  .reflectionOptionsHeader {
    width: 260px;
  }
</style>
