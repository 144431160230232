/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plane': {
    width: 22.209,
    height: 22.209,
    viewBox: '0 0 22.209 22.209',
    data: '<g data-name="Group 1957"><g data-name="Group 1566" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 43" d="M21.459.746L.75 8.946l8.375 4.139z"/><path pid="1" data-name="Path 44" d="M21.459.746l-8.195 20.709-4.139-8.375z"/></g></g>'
  }
})
