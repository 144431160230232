<template>
  <nav class="navigation">
    <router-link
      class="navigation__link"
      v-for="link in links"
      :to="link.link"
      :key="link.link.name"
      exact
    >{{link.description}}</router-link>
  </nav>
</template>
<script>
export default {
  name: 'SwitchNav',
  props: ['links'],
};
</script>
<style lang="scss">
.navigation {
  padding: 3px;
  background-color: $grey-2;
  border-radius: 45px;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;

  &__link {
    border: none;
    background: none;
    color: #fff;
    font-family: Roboto-Medium;
    font-size: 14px;
    padding: 4px 20px;
    text-decoration: none;
    cursor: pointer;

    &--active,
    &:hover {
      background: #fff;
      color: $violet-5;
      border-radius: 45px;
    }
  }
  .router-link-active {
    background: #fff;
    color: $violet-5;
    border-radius: 45px;
  }
}
</style>
