export default {
  responsive: true,
  maintainAspectRatio: false,
  height: 'inherit',
  legend: false,
  tension: 0,
  tooltips: {
    callbacks: {
      label(tooltipItems) {
        return `${tooltipItems.yLabel}%`;
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: 'transparent',
          zeroLineColor: '#ccc',
          zeroLineWidth: 1,
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
  },
};
