/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-heart': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ff4141"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ff3636"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ff6363"/><path pid="3" data-name="&lt;Path&gt;" d="M21.21 11.8A4.33 4.33 0 0 0 17 7.26a3.74 3.74 0 0 0-3.61 2.82 3.74 3.74 0 0 0-3.64-2.82 4.33 4.33 0 0 0-4.25 4.5c0 .51 0 5.71 7.86 9.95 7.85-4.2 7.86-9.4 7.85-9.91z" _fill="#fff"/></g></g>'
  }
})
