/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-cart': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.788 10.833c.625 0 1.175-.341 1.458-.858l2.984-5.408a.83.83 0 0 0-.725-1.234H5.17l-.783-1.666H1.663v1.666H3.33l3 6.325-1.125 2.034c-.609 1.116.191 2.475 1.458 2.475h10V12.5h-10l.917-1.667h6.208zM5.963 5h10.125l-2.3 4.167h-5.85L5.963 5zm.7 10c-.917 0-1.658.75-1.658 1.667 0 .916.741 1.666 1.658 1.666.917 0 1.667-.75 1.667-1.666C8.33 15.75 7.58 15 6.663 15zm8.333 0c-.916 0-1.658.75-1.658 1.667 0 .916.742 1.666 1.658 1.666.917 0 1.667-.75 1.667-1.666 0-.917-.75-1.667-1.667-1.667z" _fill="#000"/>'
  }
})
