<template>
  <div class="purchase flex-global">
    <div class="">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PurchaseService',
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>
<style lang="scss">
  .purchase {
    margin-top: -10px;
    padding-top: 68px;
    padding-bottom: 60px;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Nunito-Regular;
    color: $grey-9;
    background-color: #fff;
  }
</style>
