/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-send': {
    width: 33,
    height: 52,
    viewBox: '0 0 33 52',
    data: '<path pid="0" d="M19.598 44.87l-.617 5.588M25.517 44.035l2.463 5.423M10.052 1.542l.873 5.892M1.98 4.458l4 5" _stroke="#474EB9" stroke-width="1.5" stroke-linecap="round"/><path pid="1" d="M28.513 18.933c-2.198-1.49-4.826-2.117-7.283-3.037-1.77-.664-3.493-1.491-5.34-1.903-1.845-.412-3.89-.364-5.494.638-1.382.864-2.273 2.319-3.023 3.766-1.221 2.355-2.213 4.858-2.606 7.482-.209 1.394-.242 2.841.165 4.19a6.155 6.155 0 0 0 1.089 2.08c.246.303.53.601.871.803.559.332.442.9.253 1.407-.295.79-.542 1.579-.674 2.414-.036.23-.064.469-.004.694.132.493.561.566.976.383l4.706-2.079c.334-.148.678-.298 1.043-.31.393-.012.771.136 1.14.276a41.939 41.939 0 0 0 4.569 1.427c1.607.41 3.317.742 4.905.262 1.247-.377 2.31-1.235 3.115-2.26.805-1.025 1.372-2.216 1.867-3.421.96-2.34 1.679-4.804 1.82-7.329.1-1.77-.202-3.688-1.462-4.958-.077-.078-.553-.47-.633-.525z" _stroke="#474EB9" stroke-width="2"/><path pid="2" d="M24.035 22.126L12.58 20.961c-1.04-.088-1.631 1.122-.851 1.863l2.194 2.153c.414.382.995.546 1.574.47l6.312-1.471-5.89 2.697c-.528.22-.886.706-.952 1.333l-.405 3.04c-.135 1.014 1.103 1.68 1.82.95l8.253-7.898c.717-.729.32-1.883-.599-1.972z" _fill="#474EB9"/>'
  }
})
