/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 14.573,
    height: 14.573,
    viewBox: '0 0 14.573 14.573',
    data: '<g data-name="Group 1109"><g data-name="Group 1017" transform="translate(-927.512 -98)"><circle pid="0" data-name="Ellipse 226" cx="7.287" cy="7.287" r="7.287" transform="translate(927.512 98)" _fill="#919396"/><g data-name="Group 1009"><g data-name="Group 1008" _fill="#fff"><path pid="1" data-name="Path 863" d="M934.069 101.924a1.649 1.649 0 0 0 .091.167c.03.045.076.091.106.136a1.364 1.364 0 0 0 .151.136.51.51 0 0 0 .182.091.749.749 0 0 0 .2.061.93.93 0 0 0 .2.015.862.862 0 0 0 .2-.015.542.542 0 0 0 .167-.061h.015a1.129 1.129 0 0 0 .182-.106.98.98 0 0 0 .136-.121l.015-.015a.831.831 0 0 0 .106-.136.474.474 0 0 0 .076-.151c0-.015 0-.015.015-.03a.976.976 0 0 0 .061-.2 1.285 1.285 0 0 0 0-.394 1.4 1.4 0 0 0-.061-.2.859.859 0 0 0-.182-.273l-.03-.03a1.322 1.322 0 0 0-.151-.121c-.061-.03-.106-.061-.167-.091a.852.852 0 0 0-.182-.061.931.931 0 0 0-.2-.015.864.864 0 0 0-.2.015.853.853 0 0 0-.182.061h-.015a1.651 1.651 0 0 0-.167.091 1.324 1.324 0 0 0-.151.121.979.979 0 0 0-.121.136 1.649 1.649 0 0 0-.091.167.852.852 0 0 0-.061.182.93.93 0 0 0-.015.2.862.862 0 0 0 .015.2 1.371 1.371 0 0 0 .058.241z"/><path pid="2" data-name="Path 864" d="M936.173 107.463h-.348v-3.71a.391.391 0 0 0-.394-.394h-1.59a.391.391 0 0 0-.394.394v.818a.391.391 0 0 0 .394.394h.318v2.513h-.318a.391.391 0 0 0-.394.394v.818a.391.391 0 0 0 .394.394h2.332a.391.391 0 0 0 .394-.394v-.818a.4.4 0 0 0-.394-.409z"/></g></g></g></g>'
  }
})
