<template>
  <div class="answer-component answer-component--available" v-if="!isNotDoughnut">
    <doughnut-template :groupStatQuestion="filteredQuestion"></doughnut-template>
    <div v-if="questionMethod.id === 5 || questionMethod.id === 4 || questionMethod.id === 11">
      <unassigned-values-message v-if="isUnassigned" />
      <stat-value-list :groupStatQuestion="filteredQuestion"></stat-value-list>
    </div>
    <stat-value-list v-else :groupStatQuestion="filteredQuestion"></stat-value-list>
  </div>
  <bar-template
    v-else-if="isIndicator"
    :groupStatQuestion="filteredIndicatorQuestion"
  ></bar-template>
  <!-- <bar-template v-else-if="isIndicator" :groupStatQuestion="filteredQuestion"></bar-template> -->
  <matrix-chart v-else :groupStatQuestion="filteredQuestion" :question="question"></matrix-chart>
</template>

<script>
  import DoughnutTemplate from '@/components/stats/group-stats/DoughnutTemplate.vue';
  import BarTemplate from '@/components/stats/group-stats/BarTemplate.vue';
  import StatValueList from '@/components/stats/chart-templates/StatValueList.vue';
  import MatrixChart from '@/components/stats/chart-templates/MatrixChart.vue';
  import UnassignedValuesMessage from '@/components/base/UnassignedValuesMessage.vue';

  export default {
    name: 'StatComponentsGroup',
    components: {
      DoughnutTemplate,
      BarTemplate,
      StatValueList,
      MatrixChart,
      UnassignedValuesMessage,
    },
    props: ['groupStatQuestion', 'groupIndividualStats', 'questionMethod', 'question'],
    computed: {
      isNotDoughnut() {
        return this.questionMethod.id === 12 || this.questionMethod.id === 8;
      },
      isMatrix() {
        return this.questionMethod.id === 12;
      },
      isIndicator() {
        return this.questionMethod.id === 8;
      },
      isUnassigned() {
        if (!this.groupStatQuestion.length) return false;
        const filteredAnswers = this.filteredQuestion.filter(
          (q) => q.value === this.$t('unassigned_values')
        );
        if (filteredAnswers.length > 0) return true;
        return false;
      },
      total() {
        if (!this.groupStatQuestion.length) return false;
        return this.groupStatQuestion.reduce((acc, question) => acc + question.count, 0);
      },
      filteredIndicatorQuestion() {
        if (this.groupIndividualStats === undefined) {
          return false;
        }

        if (!this.isIndicator) return false;
        return this.groupIndividualStats.map((item) => {
          return {
            min: this.question.answerOptions.min,
            max: this.question.answerOptions.max,
            value: `${item.name} ${item.lastName}`,
            percentage: parseInt(item.value, 10),
          };
        });
      },
      filteredQuestion() {
        if (!this.groupStatQuestion.length) return false;
        return this.groupStatQuestion.map((item) => {
          const percentage = (item.count / this.total) * 100;
          return {
            ...item,
            color: item.color,
            percentage: percentage.toFixed(0),
          };
        });
      },
    },
  };
</script>
