/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron': {
    width: 11,
    height: 7,
    viewBox: '0 0 11 7',
    data: '<path pid="0" d="M1 1l4.75 4.75L10.5 1" _stroke="#5147A7" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
