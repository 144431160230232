/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sim-card': {
    width: 8,
    height: 11,
    viewBox: '0 0 8 11',
    data: '<path pid="0" d="M7 .5H3l-3 3v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1zm0 1v8H1V3.915L3.415 1.5H7z" _fill="#5147A7"/><path pid="1" d="M6 6L4 8 2 6l.705-.705.795.79V4.01l1-.01v2.085l.795-.795L6 6z" _fill="#5147A7"/>'
  }
})
