<template>
  <section class="my-profile">
    <aside class="profile-sidebar">
      <profile-sidebar :user="user" :organizations="organizations" />
    </aside>
    <section class="profile-content-wrapper">
      <router-view></router-view>
    </section>
  </section>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import ProfileSidebar from '@/components/Menu/ProfileSidebar.vue';

  export default {
    name: 'ProfileMain',
    components: {
      ProfileSidebar,
    },
    data() {
      return {};
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('user', ['user', 'userOrganizations']),
      organizations() {
        return this.userOrganizations && this.userOrganizations.length
          ? this.userOrganizations
          : [];
      },
    },
    methods: {
      ...mapActions('user', ['getUserOrganizations']),
      handlePageInit() {
        if (!this.userOrganizations) this.getUserOrganizations();
      },
    },
  };
</script>
<style lang="scss">
  .my-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: -10px;
    margin-bottom: 0 !important;
    font-family: 'Nunito-Regular';
    .profile-sidebar {
      flex: 1;
      max-width: 343px;
      padding: 80px 65px;
    }
    .profile-content-wrapper {
      flex: 3;
      padding: 80px 55px;
      background-color: #fff;
      .profile-inner {
        &__title {
          margin-bottom: 15px;
          font-size: 16px;
          font-weight: 600;
          color: $grey-9;
        }
        &__description {
          font-size: 14px;
          font-weight: 400;
          color: $grey-3;
        }
      }
    }
  }
</style>
