/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share_template': {
    width: 13.779,
    height: 15.502,
    viewBox: '0 0 13.779 15.502',
    data: '<g data-name="Group 3480"><g data-name="Group 3338" transform="translate(-602.809 -556.107)"><circle pid="0" data-name="Ellipse 838" cx="2.845" cy="2.845" r="2.845" transform="translate(602.809 561.012)" _fill="#fff"/><g data-name="Group 2033" transform="translate(610.898 556.106)" _fill="#fff"><circle pid="1" data-name="Ellipse 839" cx="2.845" cy="2.845" r="2.845"/><circle pid="2" data-name="Ellipse 840" cx="2.845" cy="2.845" r="2.845" transform="translate(0 9.811)"/></g><path pid="3" data-name="Line 1200" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width="1.3" d="M613.743 558.541l-8.089 5.73"/><path pid="4" data-name="Line 1201" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width="1.3" d="M613.743 569.174l-8.089-5.73"/></g></g>'
  }
})
