<template>
  <div>
    <div class="service-card flex-global" @click="toggleModal">
      <div class="service-card__image" :style="{ backgroundImage: `url(${service.imageUrl})` }">
        <div class="sale-chip flex-global" v-if="service.isOnSale">
          <svgicon name="stars" width="14px" height="16px"></svgicon>
          <span>{{ $t('on_sale') }}</span>
          <span>{{ service.salePercentage }}</span>
        </div>
      </div>
      <div class="content-wrapper flex-global">
        <p class="service-card__title" :title="service.title">{{ service.title }}</p>
        <div class="service-card__price">
          <span class="current-price" :class="['current-price', { 'on-sale': service.isOnSale }]">
            <span class="currency">€</span>
            {{ service.isOnSale ? service.salePrice : service.priceBeforeDiscount }}
          </span>
          <span v-if="service.isOnSale" class="old-price">
            <span class="currency">€</span>
            {{ service.priceBeforeDiscount }}
          </span>
        </div>
        <div class="service-card__description">
          <p :title="service.description">{{ service.description }}</p>
        </div>
        <div class="service-card__button" @click.stop>
          <button-4 @click.native="handleAction">{{ service.actionHandler.actionTitle }}</button-4>
        </div>
      </div>
    </div>
    <service-modal v-if="isModal" :service="service" @handleModalToggle="handleModalToggle" />
  </div>
</template>
<script>
  import ServiceModal from './ServiceModal.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'UserService',
    props: ['service'],
    components: {
      ServiceModal,
      Button4,
    },
    data() {
      return {
        isModal: false,
      };
    },
    computed: {},
    methods: {
      handleAction() {
        this.service.actionHandler.action();
      },
      toggleModal() {
        this.isModal = !this.isModal;
      },
      handleModalToggle() {
        this.toggleModal();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .service-card {
    flex-direction: column;
    width: 310px;
    padding-top: 0;
    border-radius: 3px;
    cursor: pointer;
    background: $grey-6;
    &__image {
      border-radius: 3px 3px 0 0;
      width: 100%;
      height: 146px;
      min-height: 146px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $grey-6;
      .sale-chip {
        position: relative;
        top: 12px;
        left: 12px;
        justify-content: space-between;
        padding: 6px;
        width: 114px;
        height: 24px;
        border-radius: 999px;
        background: #ff2e5d;

        span {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.4px;
          color: #fff;
        }
      }
    }
    .content-wrapper {
      flex-direction: column;
      padding: 24px;
      padding-top: 16px;
      width: 100%;
      height: 100%;
    }
    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      margin-bottom: 16px;
      width: 100%;
      height: 84px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    &__price {
      margin-bottom: 16px;
      width: 100%;
      .current-price,
      .old-price {
        font-size: 14px;
        .currency {
          font-size: 12px;
        }
      }
      .current-price {
        font-weight: 700;
        color: #000;
        &.on-sale {
          color: #ff2e5d;
        }
      }
      .old-price {
        margin-left: 14px;
        font-weight: 600;
        text-decoration: line-through;
        color: $grey-5;
      }
    }
    &__description {
      margin-bottom: 24px;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 50px;
      min-height: 50px;
      word-break: break-word;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #525252;
      }
    }
    &__button {
      margin-top: auto;
      width: 100%;
      button {
        width: 100%;
        height: 39px;
        background: $violet-3;
        &:hover {
          background: $violet-4;
        }
      }
    }
  }
</style>
