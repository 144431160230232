/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-no': {
    width: 15.476,
    height: 15.477,
    viewBox: '0 0 15.476 15.477',
    data: '<g data-name="Group 3243"><g data-name="Group 3183"><g data-name="Group 2232"><circle pid="0" data-name="Ellipse 823" cx="7.738" cy="7.738" r="7.738" _fill="#f36"/></g><path pid="1" data-name="Line 1365" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width="1.5" d="M4.303 4.303l7 7"/><path pid="2" data-name="Line 1366" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-width="1.5" d="M11.303 4.303l-7 7"/></g></g>'
  }
})
