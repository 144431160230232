<template>
  <div class="profile-inner">
    <p class="profile-inner__title">{{ orgTitle }}</p>
    <!-- title -->
    <advanced-field
      :title="$t('inputs.org_name')"
      :valueTitle="'title'"
      :value="organizationForm.title"
      :valueToDisplay="valuesToDisplay.title"
      :isError="$v.organizationForm.title.$error"
      :ref="`advancedField-title`"
      @handleEdit="handleOrganizationEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.organizationForm.title.$error">
          <span class="error-message" v-if="!$v.organizationForm.title.required">
            {{ $t('warnings.org_name_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.title.maxLength">
            {{ $t('warnings.org_name_length_warning') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.title.minLength">
            {{ $t('warnings.org_name_length_warning') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- code -->
    <advanced-field
      :title="$t('inputs.org_code')"
      :valueTitle="'code'"
      :value="organizationForm.code"
      :valueToDisplay="valuesToDisplay.code"
      :isError="$v.organizationForm.code.$error"
      :ref="`advancedField-code`"
      @handleEdit="handleOrganizationEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.organizationForm.code.$error">
          <span class="error-message" v-if="!$v.organizationForm.code.required">
            {{ $t('warnings.org_code_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.code.numeric">
            {{ $t('warnings.org_code_numeric_warning') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- phone -->
    <advanced-field
      :title="$t('inputs.org_phone_alt')"
      :valueTitle="'phone'"
      :value="organizationForm.phone"
      :valueToDisplay="valuesToDisplay.phone"
      :isError="$v.organizationForm.phone.$error"
      :ref="`advancedField-phone`"
      @handleEdit="handleOrganizationEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.organizationForm.phone.$error">
          <span class="error-message" v-if="!$v.organizationForm.phone.required">
            {{ $t('warnings.phone_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.phone.minLength">
            {{ $t('warnings.invalid_phone') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.phone.numeric">
            {{ $t('warnings.invalid_phone') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- email -->
    <advanced-field
      :title="$t('inputs.email_address')"
      :valueTitle="'email'"
      :value="organizationForm.email"
      :valueToDisplay="valuesToDisplay.email"
      :isError="$v.organizationForm.email.$error"
      :ref="`advancedField-email`"
      @handleEdit="handleOrganizationEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.organizationForm.email.$error">
          <span class="error-message" v-if="!$v.organizationForm.email.required">
            {{ $t('warnings.email_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.organizationForm.email.email">
            {{ $t('warnings.invalid_email') }}
          </span>
        </div>
      </template>
    </advanced-field>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
  import AdvancedField from '@/components/elements/fields/AdvancedField.vue';

  export default {
    name: 'OrganizationInfo',
    components: {
      AdvancedField,
    },
    data() {
      return {
        valuesToDisplay: {
          title: null,
          code: null,
          phone: null,
          email: null,
        },
      };
    },
    validations: {
      organizationForm: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(120),
        },
        code: {
          required,
          numeric,
        },
        phone: {
          required,
          numeric,
          minLength: minLength(9),
        },
        email: {
          required,
          email,
        },
      },
    },
    created() {
      this.handlePageInit();
    },
    mounted() {
      if (this.organizationForm) {
        this.handleTitleChange('title');
        this.handleTitleChange('code');
        this.handleTitleChange('phone');
        this.handleTitleChange('email');
      }
    },
    computed: {
      ...mapGetters('user', ['userOrganizations', 'organizationForm']),
      orgId() {
        return this.$route.params.orgId && parseInt(this.$route.params.orgId, 10);
      },
      orgTitle() {
        return this.organization ? this.organization.title : '-';
      },
      organization() {
        return this.userOrganizations && this.userOrganizations.length
          ? this.userOrganizations.find((org) => org.id === this.orgId)
          : null;
      },
    },
    methods: {
      ...mapActions('user', ['setOrganizationForm', 'updateOrganization']),
      handlePageInit() {
        this.setOrganizationForm(this.organization);
      },
      handleOrganizationEdit(newValue) {
        this.organizationForm[newValue.name] = newValue.value;
        this.$v.organizationForm[newValue.name].$touch();

        if (!this.$v.organizationForm[newValue.name].$error) {
          this.handleOrganizationUpdate(newValue);
        }
      },
      handleOrganizationUpdate(value) {
        const payload = { [value.name]: value.value };

        this.updateOrganization({ form: payload, id: this.orgId }).then((res) => {
          const { success, msg } = res;
          if (success) {
            this.$refs[`advancedField-${value.name}`].closeEditModal();
            this.$toast.success(this.$t('profile.successful_profile_update'));
            this.handleTitleChange(value.name);
          }
          if (msg) {
            this.$toast.error(msg);
          }
        });
      },
      handleTitleChange(value) {
        const nameValue = this.organizationForm[value];
        this.valuesToDisplay[value] = nameValue;
      },
    },
  };
</script>
<style lang="scss" scoped></style>
