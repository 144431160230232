<template>
  <div class="home__page">
    <calendar-header :date="moment().format('MMMM YYYY')"></calendar-header>
    <section id="home" class="home">
      <div v-if="loading || welcome" class="home__calendar">
        <transition-group tag="div" name="calendar-transition">
          <calendar-day-empty
            v-for="(n, i) in 5"
            :day="
              moment(currentDate)
                .startOf('isoWeek')
                .add(i, 'days')
                .format('dddd DD')
            "
            :key="
              moment(currentDate)
                .startOf('isoWeek')
                .add(i, 'days')
                .format('dddd DD')
            "
            :empty="true"
            :mockReflections="mockReflections[i]"
          ></calendar-day-empty>
        </transition-group>
        <welcome v-if="!welcome" :loading="true"></welcome>
        <welcome v-else></welcome>
      </div>
      <div v-else-if="!reflectionList.length">
        <div class="home__calendar">
          <transition-group tag="div" name="calendar-transition">
            <calendar-day-empty
              v-for="(n, i) in 5"
              :day="
                moment(currentDate)
                  .startOf('isoWeek')
                  .add(i, 'days')
                  .format('dddd DD')
              "
              :key="
                moment(currentDate)
                  .startOf('isoWeek')
                  .add(i, 'days')
                  .format('dddd DD')
              "
              :empty="true"
              :mockReflections="mockReflections[i]"
            ></calendar-day-empty>
          </transition-group>
        </div>
        <welcome :noReflections="reflectionsByDay.length"></welcome>
      </div>
      <div class="home__calendar" v-else>
        <transition-group tag="div" name="calendar-transition">
          <calendar-day
            v-for="(day, index) in reflectionsByDay"
            :key="
              moment(currentDate)
                .startOf('isoWeek')
                .add(index, 'days')
                .format('dddd DD')
            "
            :weekend="day.weekend"
            :day="
              moment(currentDate)
                .startOf('isoWeek')
                .add(index, 'days')
                .format('dddd DD')
            "
            :reflections="day.reflections.length"
          >
            <template v-slot:day>{{ day.day }}</template>
            <template v-slot:reflection>
              <calendar-reflection
                v-for="(reflection, index) in day.reflections"
                :key="index"
                :viewed="moment().isAfter(reflection.start, 'day')"
                :complete="reflection.status !== 'pending'"
                :current="moment().isSame(reflection.start, 'day')"
                :data="reflection"
                :progress="reflection.progress"
                :reflectionData="reflection"
              >
                <template v-slot:time>{{ moment(reflection.start).format('HH:mm') }}</template>
                <template v-slot:class>{{ reflection.clientGroup.title }}</template>
              </calendar-reflection>
            </template>
          </calendar-day>
        </transition-group>
        <welcome v-if="loading" :loading="true"></welcome>
      </div>
    </section>
    <register-modal v-show="registerModal"></register-modal>
    <onboarding-modal v-if="welcome && showOnboarding" @handleOnboardingModal="handleOnboardingModal"></onboarding-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import CalendarHeader from '@/components/calendar/CalendarHeader.vue';
import CalendarDay from '@/components/calendar/CalendarDay.vue';
import CalendarDayEmpty from '@/components/calendar/CalendarDayEmpty.vue';
import CalendarReflection from '@/components/calendar/CalendarReflection.vue';
import Welcome from '@/components/base/Welcome.vue';
import RegisterModal from '@/components/elements/RegisterModal.vue';
import OnboardingModal from '@/components/base/OnboardingModal.vue'

export default {
  name: 'Home',
  components: {
    CalendarHeader,
    CalendarDay,
    CalendarDayEmpty,
    CalendarReflection,
    Welcome,
    RegisterModal,
    OnboardingModal
  },
  data() {
    return {
      mockReflections: [3, 2, 1, 3, 2]
    };
  },
  created() {
    this.setDate();
    this.getReflections().then(() => this.setLoading(false));
  },
  computed: {
    ...mapGetters('reflections', ['currentDate', 'reflectionList', 'reflectionsByDay']),
    ...mapGetters('meta', ['loading', 'showOnboarding']),
    ...mapGetters('user', ['welcome', 'registerModal']),
    ...mapGetters('methods', ['questionTypeList']),
  },
  methods: {
    ...mapActions('reflections', ['getReflections', 'setDate']),
    ...mapActions('user', ['setWelcome']),
    ...mapActions('meta', ['setLoading', 'hideOnboardingModal']),
    handleOnboardingModal(){
      this.hideOnboardingModal();
    }
  },
};
</script>
<style lang="scss" scoped>
#home {
  max-width: 940px;
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 535px;
  .home__calendar {
    & > div {
      display: flex;
      margin-top: 30px;
      padding-bottom: 50px;
      position: relative;
    }
  }
  &::-webkit-scrollbar {
    border: none;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 45px;
  }
  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 45px;
  }
}
</style>
