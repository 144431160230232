/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/image-pick-blank': {
    width: 37.049,
    height: 18.99,
    viewBox: '0 0 37.049 18.99',
    data: '<g data-name="Group 3828"><g data-name="Group 2416"><path pid="0" data-name="Rectangle 4461" _fill="none" _stroke="#5454c4" stroke-linejoin="round" d="M18.559.5h17.99v17.99h-17.99z"/><g data-name="Group 2406" _fill="none" _stroke="#5454c4" stroke-linecap="round" stroke-miterlimit="10"><path pid="1" data-name="Path 32" d="M27.607 4.431v4.74h-4.74"/><path pid="2" data-name="Path 33" d="M27.607 13.911v-4.74h4.74"/></g><path pid="3" data-name="Rectangle 4462" _fill="#aac0ff" _stroke="#3b328b" stroke-linejoin="round" d="M.499.5h17.99v17.99H.499z"/><g data-name="Group 2407" transform="translate(.499 .5)" _fill="none" _stroke="#3b328b" stroke-linejoin="round"><path pid="4" data-name="Rectangle 4451" d="M0 0h17.99v17.99H0z"/><path pid="5" data-name="Path 2255" d="M-.004 12.842L4.46 8.321l3.6 3.514 5.367-5.319 4.559 4.616"/><ellipse pid="6" data-name="Ellipse 1035" cx="1.463" cy="1.463" rx="1.463" ry="1.463" transform="translate(6.601 3.583)"/></g><g data-name="Group 2412"><g data-name="Group 2411"><path pid="7" data-name="Rectangle 4465" _fill="#5454c4" _stroke="#5454c4" stroke-linejoin="round" d="M.499.499h17.983v17.983H.499z"/></g><g data-name="Group 2410" stroke-linejoin="round" transform="translate(.499 .499)"><path pid="8" data-name="Path 2256" d="M13.419 6.52l-5.366 5.321-3.6-3.533-4.45 4.539v5.143h17.978v-6.864z" _fill="#fff" _stroke="#707070"/><path pid="9" data-name="Rectangle 4465" _fill="none" _stroke="#5454c4" d="M0 0h17.983v17.983H0z"/><path pid="10" data-name="Path 2257" d="M-.004 12.838l4.463-4.519 3.6 3.513 5.365-5.317 4.558 4.615" _fill="none" _stroke="#5454c4"/><circle pid="11" data-name="Ellipse 1036" cx="1.462" cy="1.462" r="1.462" transform="translate(6.598 3.581)" _fill="#fff" _stroke="#fff"/></g></g></g></g>'
  }
})
