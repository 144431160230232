<template>
  <multiselect
    v-if="typeOfProperty === 'object'"
    :id="id"
    :key="id"
    v-model="value"
    :options="options"
    :maxHeight="200"
    :searchable="false"
    :showLabels="false"
    :placeholder="placeholder"
    :style="{ width: `${width}px` || 'inherit' }"
    :allow-empty="false"
    track-by="value"
    label="value"
    @select="selectedValue"
    :disabled="disabled"
  >
    <template v-slot:caret>
      <svgicon
        class="arrow-icon"
        name="green-arrow"
        width="12px"
        height="12px"
        color="#BABABA"
        :stroke="true"
        :fill="false"
      ></svgicon>
    </template>
  </multiselect>
  <multiselect
    v-else
    :id="id"
    :key="id"
    v-model="value"
    :options="options"
    :maxHeight="200"
    :searchable="false"
    :showLabels="false"
    :placeholder="placeholder"
    :style="{ width: `${width}px` || 'inherit' }"
    @select="selectedValue"
    :disabled="disabled"
  >
    <template v-slot:caret>
      <svgicon
        class="arrow-icon"
        name="green-arrow"
        width="12px"
        height="12px"
        color="#BABABA"
        :stroke="true"
        :fill="false"
      ></svgicon>
    </template>
  </multiselect>
</template>

<script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'MultiSelect',
    components: {
      Multiselect,
    },
    props: ['options', 'placeholder', 'width', 'id', 'selected', 'disabled'],
    data() {
      return {
        value: this.selected || null,
      };
    },
    created() {
      if (this.selected) {
        this.value = this.selected;
      }
    },
    watch: {
      value(newVal) {
        this.value = newVal;
      },
    },
    computed: {
      typeOfProperty() {
        return typeof this.options[0];
      },
    },
    methods: {
      selectedValue(value) {
        if (this.typeOfProperty !== 'object') {
          this.$emit('selectedValue', { value, id: this.id });
        } else {
          this.$emit('selectedValue', value);
        }
      },
    },
  };
</script>

<style lang="scss">
  fieldset[disabled] .multiselect {
    pointer-events: none;
  }
  .multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block;
  }
  .multiselect__spinner:after,
  .multiselect__spinner:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: #41b883;
    box-shadow: 0 0 0 1px transparent;
  }
  .multiselect__spinner:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }
  .multiselect__spinner:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }
  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    opacity: 0;
  }
  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
  }

  .multiselect:focus {
    outline: none;
  }
  .multiselect--disabled {
    background: #ededed;
    pointer-events: none;
    opacity: 0.6;
  }
  .multiselect--active {
    z-index: 50;
  }
  .multiselect--active:not(.multiselect--above) .multiselect__current,
  .multiselect--active:not(.multiselect--above) .multiselect__input,
  .multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .multiselect--active .multiselect__select {
    transform: rotate(180deg);
  }
  .multiselect--above.multiselect--active .multiselect__current,
  .multiselect--above.multiselect--active .multiselect__input,
  .multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .multiselect__input,
  .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border 0.1s ease;

    margin-bottom: 8px;
    vertical-align: top;
  }
  .multiselect__input:-ms-input-placeholder {
    color: #35495e;
  }
  .multiselect__input::placeholder {
    color: #35495e;
  }
  .multiselect__tag ~ .multiselect__input,
  .multiselect__tag ~ .multiselect__single {
    width: auto;
  }
  .multiselect__input:hover,
  .multiselect__single:hover {
    border-color: #cfcfcf;
  }
  .multiselect__input:focus,
  .multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none;
  }
  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
  }
  .multiselect__tags-wrap {
    display: inline;
  }
  .multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
  }
  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #41b883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
  }
  .multiselect__tag-icon:after {
    content: '\D7';
    color: #266d4d;
    font-size: 14px;
  }
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #369a6e;
  }
  .multiselect__tag-icon:focus:after,
  .multiselect__tag-icon:hover:after {
    color: #fff;
  }
  .multiselect__current {
    min-height: 40px;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
  }
  .multiselect__current,
  .multiselect__select {
    line-height: 16px;

    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
  }
  .multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform 0.2s ease;
  }
  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
  }
  .multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
  }
  .multiselect--active .multiselect__placeholder {
    display: none;
  }
  .multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
  }
  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }
  .multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
  }
  .multiselect__content::webkit-scrollbar {
    display: none;
  }
  .multiselect__element {
    display: block;
  }
  .multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
  }
  .multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
  }
  .multiselect__option--highlight {
    background: #41b883;
    outline: none;
    color: #fff;
  }
  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #41b883;
    color: #fff;
  }
  .multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700;
  }
  .multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }
  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }
  .multiselect--disabled .multiselect__current,
  .multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
  }
  .multiselect__option--disabled {
    background: #ededed !important;
    color: #a6a6a6 !important;
    cursor: text;
    pointer-events: none;
  }
  .multiselect__option--group {
    background: #ededed;
    color: #35495e;
  }
  .multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff;
  }
  .multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e;
  }
  .multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede;
  }
  .multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }
  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }
  .multiselect-enter-active,
  .multiselect-leave-active {
    transition: all 0.15s ease;
  }
  .multiselect-enter,
  .multiselect-leave-active {
    opacity: 0;
  }
  .multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
  }
  [dir='rtl'] .multiselect {
    text-align: right;
  }
  [dir='rtl'] .multiselect__select {
    right: auto;
    left: 1px;
  }
  [dir='rtl'] .multiselect__tags {
    padding: 8px 8px 0 40px;
  }
  [dir='rtl'] .multiselect__content {
    text-align: right;
  }
  [dir='rtl'] .multiselect__option:after {
    right: auto;
    left: 0;
  }
  [dir='rtl'] .multiselect__clear {
    right: auto;
    left: 12px;
  }
  [dir='rtl'] .multiselect__spinner {
    right: auto;
    left: 1px;
  }
  @keyframes spinning {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(2turn);
    }
  }

  .multiselect {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: $grey-5;
    min-height: 30px;

    &__tags {
      min-height: 30px;
      border: 1px solid $grey-2;
      padding-top: 0px;
      border-radius: 2px;
    }

    &.multiple-type-select {
      .multiselect {
        &__tags {
          padding-top: 0;
        }
        &__tag {
          margin-top: 4px;
          margin-bottom: 0;
          border-radius: 2px;
          background: $grey-7;
        }
        &__tag-icon:after {
          content: '\D7';
          color: #fff;
          font-size: 22px;
        }
        &__tag-icon:focus,
        &__tag-icon:hover {
          background: #b1b1b1;
        }

        // selected option makes checkbox checked with this css instead of a value
        &__option {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-content: center;
          .option__checkbox {
            margin-right: 10px;
            .custom-checkbox {
              label {
                pointer-events: none;
              }
            }
          }
          .option__desc {
            margin-top: 1px;
          }
          &--selected {
            .option__checkbox {
              .custom-checkbox {
                input[type='checkbox'] + label:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    &.template-sort-select {
      .multiselect__tags {
        min-height: 28px;
      }
      .multiselect__single {
        top: 4px;
      }
      .arrow-icon {
        top: 9px;
      }
    }

    &.filter-type-select {
      .multiselect__tags {
        border: none;
      }
      .multiselect__tags,
      .multiselect__single {
        background-color: #efefef;
      }
    }

    &__single {
      margin: 0;
      font-size: 14px;
      top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__option {
      &--highlight {
        background: $grey-1;
        color: $grey-5;
      }
      &--selected {
        background: $grey-2;
        color: $grey-5;
      }
    }
    .multiselect__option--selected.multiselect__option--highlight {
      background: $grey-3;
      color: $grey-5;
    }
    .multiselect__option--selected.multiselect__option--highlight:after {
      background: $grey-3;
      content: attr(data-deselect);
      color: $grey-5;
    }

    &__content-wrapper {
      &::-webkit-scrollbar {
        border: none;
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 45px;
      }
      &::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 0;
      }
    }

    &__placeholder {
      padding-top: 6px;
      margin-bottom: 0;
    }

    .arrow-icon {
      position: absolute;
      width: 12px;
      height: 12px;
      right: 12px;
      top: 11px;
      transform: rotate(-90deg);
      transition: all 0.2s;
    }

    &--active {
      .arrow-icon {
        transform: rotate(90deg);
      }
      .multiselect__placeholder {
        display: inline-block;
      }
    }
  }
</style>
