export const answers = [
  {
    id: 1,
    template: 'yes_no',
    title: 'Taip / Ne',
    question: 'Ar vasarą skaitėte knygas iš užduoto sąrašo?',
    answers: [
      {
        value: 'Taip',
        selected: true,
      },
      {
        value: 'Ne',
        selected: false,
      },
    ],
  },
  {
    id: 2,
    template: 'traffic_light',
    title: 'šviesoforas',
    question: 'Kaip įvertintumėte užduoto knygų sąrašo knygas pagal sudėtingumą skaityti?',
    answers: [
      {
        value: 'Per sudėtingos',
        selected: false,
      },
      {
        value: 'Vidutiniškos',
        selected: true,
      },
      {
        value: ' Lengvos skaityti',
        selected: false,
      },
    ],
  },
  {
    id: 7,
    template: 'thermometer',
    title: 'TERMOMETRAS',
    question: 'Per pamoką aš buvau',
    answers: [
      {
        value: 'Dėmesingas',
        selected: true,
      },
      {
        value: 'Susikaupęs',
        selected: false,
      },
      {
        value: 'Šiek tiek susikaupęs',
        selected: false,
      },
      {
        value: ' Blaškomas',
        selected: false,
      },
      {
        value: 'Visai nesusikaupęs',
        selected: false,
      },
    ],
  },
  {
    id: 3,
    template: 'smileys',
    title: 'VEIDUKAI',
    question: 'Kaip jauteisi pristatinėdamas(-a) skaidres?',
    answers: [
      {
        value: 'Užtikrintas',
        icon: 'emote-cool',
        selected: true,
      },
      {
        value: 'Linksmas',
        icon: 'emote-smile',
        selected: false,
      },
      {
        value: 'Patenkintas',
        icon: 'emote-blush',
        selected: false,
      },
      {
        value: 'Nusivylęs',
        icon: 'emote-down',
        selected: false,
      },
    ],
  },
  {
    id: 5,
    template: 'incomplete_sentence',
    title: 'NEUŽBAIGTI SAKINIAI',
    question:
      'Eilėraščius man sekasi analizuoti @ nei / kaip ir prozos ir dramos kūrinius, kadangi @.',
    answers: [
      {
        value: 'lėčiau ',
      },
      {
        value: 'juos tenka rečiau skaityti',
      },
    ],
  },
  {
    id: 12,
    template: 'progress_matrix',
    title: 'PROGRESO MATRICA',
    question: 'Kaip tau sekasi mokytis?',
    answers: [
      {
        type: 'Gramatika',
        selections: [
          {
            title: 'Nereflektavo',
            selected: true,
          },
          {
            title: 'Pirmieji žingsniai',
            selected: false,
          },
          {
            title: 'Judu pirmyn',
            selected: false,
          },
          {
            title: 'Man pavyksta',
            selected: false,
          },
          {
            title: 'Galiu dalintis su kitais',
            selected: false,
          },
        ],
      },
      {
        type: 'Skyryba',
        selections: [
          {
            title: 'Nereflektavo',
            selected: false,
          },
          {
            title: 'Nemoku taisyklių',
            selected: true,
          },
          {
            title: 'Judu pirmyn',
            selected: false,
          },
          {
            title: 'Man pavyksta',
            selected: false,
          },
          {
            title: 'Be klaidų dedu skyrybos ženklus',
            selected: false,
          },
        ],
      },
      {
        type: 'Rašyba',
        selections: [
          {
            title: 'Nereflektavo',
            selected: false,
          },
          {
            title: 'Mokausi taisykles',
            selected: true,
          },
          {
            title: 'Turiu pagrindus',
            selected: false,
          },
          {
            title: 'Retai klystu',
            selected: false,
          },
          {
            title: 'Nedarau klaidų',
            selected: false,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    template: '3-2-1',
    title: '3-2-1',
    question: 'Pagalvokite, ką sakysite  pažįstamiems apie šiandienos pamoką:',
    parts: [
      {
        id: 1,
        answerCount: 3,
        title: '3 svarbūs dalykai, kurių aš išmokau pamokoje:',
        answers: [
          {
            value: 'Nauji apibrėžimai',
          },
          {
            value: 'Taisyklės',
          },
          {
            value: '',
          },
        ],
      },
      {
        id: 2,
        answerCount: 2,
        title: '2 dalykai, kurių turėčiau pasimokyti:',
        answers: [
          {
            value: 'Nauji apibrėžimai',
          },
          {
            value: 'Taisyklės',
          },
        ],
      },
      {
        id: 3,
        answerCount: 1,
        title: '1 dalykas, kurio visiškai nesupratau:',
        answers: [
          {
            value: null,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Papasakok, kaip sekėsi skaityti knygas iš sąrašo.',
    type: 'text',
    answers: [
      {
        value:
          'Vestibulum vehicula ex in tellus rutrum convallis. In molestie, nunc et ultricies faucibus, felis nibh tincidunt mauris, id molestie elit dolor ut arcu. In mi elit, suscipit vitae gravida vitae, fermentum sed eros. Nullam ac lacinia sapien. Mauris sit amet lectus vel ipsum rhoncus',
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Kaip įvertintumėte savo pristymą apie knygas?',
    type: 'select',
    answers: [
      {
        value: 'Išsamus',
        selected: true,
      },
      {
        value: 'Trūksta įžvalgų',
        selected: false,
      },
      {
        value: 'Netinkamas',
        selected: false,
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Kaip vertini savo gebėjimus analizuoti literatūros kūrinius?',
    type: 'interval',
    answers: [
      {
        value: 70,
      },
    ],
  },
  {
    id: 13,
    template: '3k',
    title: '3K',
    question: 'Kaip vertini šią pamoką?',
    answers: [
      {
        question: 'Ką žinau (prieš pamoką)?',
        answer:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      },
      {
        question: 'Ką noriu sužinoti?',
        answer:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      },
      {
        question: 'Ką sužinojau?',
        answer:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      },
    ],
  },
  {
    id: 11,
    template: 'pie',
    title: 'PYRAGO DALINIMAS',
    question:
      'Padalink „pyragą“ į tokias dalis, kurios rodytų kiekvieno grupės nario indėlį į atliktą užduotį.',
    answers: [
      {
        member: 'Aurimas Juodeika',
        value: 48,
      },
      {
        member: 'Tomas G.',
        value: 40,
      },
      {
        member: 'Rimas Rimaitis',
        value: 12,
      },
      {
        member: null,
        value: 0,
      },
      {
        member: null,
        value: 0,
      },
    ],
  },
  {
    id: 8,
    template: 'indicator',
    title: 'SKALĖ',
    question: 'Kaip vertini savo gebėjimus analizuoti literatūros kūrinius?',
    answers: null,
  },
];

export const memberAnswers = [
  {
    id: 1,
    template: 'yes_no',
    title: 'Taip / Ne',
    question: 'Ar vasarą skaitėte knygas iš užduoto sąrašo?',
    answers: [
      {
        date: '2014-09-02',
        value: 1,
      },
      {
        date: '2015-09-06',
        value: -1,
      },
      {
        date: '2016-09-15',
        value: 1,
      },
      {
        date: '2016-10-15',
        value: 1,
      },
      {
        date: '2017-09-03',
        value: 0,
      },
      {
        date: '2018-09-21',
        value: -1,
      },
      {
        date: '2019-06-02',
        value: -1,
      },
      {
        date: '2019-09-13',
        value: 1,
      },
    ],
  },
  {
    id: 2,
    template: 'traffic_light',
    title: 'ŠVIESOFORAS',
    question: 'Kaip įvertintumėte užduoto knygų sąrąšo knygas pagal sudėtingumą skaityti?',
    answers: [
      {
        date: '2019-06-13',
        value: 'Lengvos skaityti',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value: 'Lengvos skaityti',
      },
      {
        date: '2016-10-15',
        value: 'Vidutiniškos',
      },
      {
        date: '2016-09-15',
        value: 'Lengvos skaityti',
      },
      {
        date: '2015-09-06',
        value: 'Vidutiniškos',
      },
      {
        date: '2015-09-02',
        value: 'Per sudėtingos',
      },
      {
        date: '2015-05-15',
        value: 'Lengvos skaityti',
      },
      {
        date: '2015-03-07',
        value: null,
      },
      {
        date: '2014-12-13',
        value: 'Lengvos skaityti',
      },
      {
        date: '2014-05-14',
        value: 'Per sudėtingos',
      },
      {
        date: '2014-04-12',
        value: 'Lengvos skaityti',
      },
      {
        date: '2014-01-02',
        value: 'Vidutiniškos',
      },
    ],
  },
  {
    id: 7,
    template: 'thermometer',
    title: 'TERMOMETRAS',
    question: 'Per pamoką aš buvau',
    answers: [
      {
        date: '2019-06-13',
        value: 'Dėmesingas',
      },
      {
        date: '2018-09-21',
        value: 'Susikaupęs',
      },
      {
        date: '2017-09-03',
        value: 'Blaškomas',
      },
      {
        date: '2016-10-15',
        value: 'Šiek tiek susikaupęs',
      },
      {
        date: '2016-09-15',
        value: 'Visai nesusikaupęs',
      },
      {
        date: '2015-09-06',
        value: 'Dėmesingas',
      },
      {
        date: '2015-09-02',
        value: 'Susikaupęs',
      },
      {
        date: '2015-05-15',
        value: 'Visai nesusikaupęs',
      },
      {
        date: '2015-03-07',
        value: null,
      },
      {
        date: '2014-12-13',
        value: 'Visai nesusikaupęs',
      },
      {
        date: '2014-05-14',
        value: 'Susikaupęs',
      },
      {
        date: '2014-04-12',
        value: 'Dėmesingas',
      },
      {
        date: '2014-01-02',
        value: null,
      },
      {
        date: '2013-09-22',
        value: null,
      },
    ],
  },
  {
    id: 3,
    template: 'smileys',
    title: 'VEIDUKAI',
    question: 'Kaip jauteisi pristatinėdamas(-a) skaidres?',
    answers: [
      {
        date: '2019-06-13',
        value: 'Patenkintas',
      },
      {
        date: '2018-09-21',
        value: 'Nustebintas',
      },
      {
        date: '2017-09-03',
        value: 'Patenkintas',
      },
      {
        date: '2016-10-15',
        value: 'Nusivylęs',
      },
      {
        date: '2016-09-15',
        value: 'Nusivylęs',
      },
      {
        date: '2015-09-06',
        value: 'Patenkintas',
      },
      {
        date: '2015-09-02',
        value: 'Nustebintas',
      },
      {
        date: '2015-05-15',
        value: 'Patenkintas',
      },
      {
        date: '2015-03-07',
        value: null,
      },
      {
        date: '2014-12-13',
        value: 'Nustebintas',
      },
      {
        date: '2014-05-14',
        value: 'Nustebintas',
      },
      {
        date: '2014-04-12',
        value: 'Patenkintas',
      },
      {
        date: '2014-01-02',
        value: null,
      },
      {
        date: '2013-09-22',
        value: 'Patenkintas',
      },
    ],
  },
  {
    id: 5,
    template: 'incomplete_sentence',
    title: 'NEUŽBAIGTI SAKINIAI',
    question:
      'Eilėraščius man sekasi analizuoti @ nei / kaip ir prozos ir dramos kūrinius, kadangi @.',
    answers: [
      {
        date: '2019-06-13',
        value: ['lėčiau', 'juos tenka rečiau skaityti'],
        note: 'Aktyviai skaito poeziją',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value: ['sunkiau', 'nemėgstu poezijos'],
        note: 'Neskaito poezijos',
      },
      {
        date: '2016-10-15',
        value: ['panašiai ', 'kartais paskaitau poeziją'],
        note: 'Vidutiniškai skaito poeziją',
      },
      {
        date: '2016-09-15',
        value: ['vienodai ', 'dažniau skaitau ir deklamuoju eilėraščius'],
        note: null,
      },
      {
        date: '2016-04-11',
        value: null,
      },
      {
        date: '2015-10-21',
        value: ['panašiai', 'nemėgstu poezijos'],
        note: null,
      },
      {
        date: '2019-06-13',
        value: ['lėčiau', 'juos tenka rečiau skaityti'],
        note: 'Aktyviai skaito poeziją',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value: ['sunkiau', 'nemėgstu poezijos'],
        note: 'Neskaito poezijos',
      },
      {
        date: '2016-10-15',
        value: ['panašiai ', 'kartais paskaitau poeziją'],
        note: 'Vidutiniškai skaito poeziją',
      },
      {
        date: '2016-09-15',
        value: ['vienodai ', 'dažniau skaitau ir deklamuoju eilėraščius'],
        note: null,
      },
      {
        date: '2016-04-11',
        value: null,
      },
      {
        date: '2015-10-21',
        value: ['panašiai', 'nemėgstu poezijos'],
        note: null,
      },
    ],
  },
  {
    id: 12,
    template: 'progress_matrix',
    title: 'PROGRESO MATRICA',
    question: 'Kaip tau sekasi mokytis?',
    gramatika: [
      {
        date: '2019-06-13',
        section: 'Gramatika',
        value: null,
        answer: null,
      },
      {
        date: '2018-09-21',
        section: 'Gramatika',
        value: 'Judu pirmyn',
        answer: 'Judu pirmyn',
      },
      {
        date: '2017-09-03',
        section: 'Gramatika',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2016-10-15',
        section: 'Gramatika',
        value: 'Galiu dalintis su kitais',
        answer: 'Galiu dalintis su kitais',
      },
      {
        date: '2016-09-15',
        section: 'Gramatika',
        value: null,
        answer: null,
      },
      {
        date: '2016-09-06',
        section: 'Gramatika',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2015-09-02',
        section: 'Gramatika',
        value: 'Pirmieji žingsniai',
        answer: 'Pirmieji žingsniai',
      },
      {
        date: '2015-05-15',
        section: 'Gramatika',
        value: 'Judu pirmyn',
        answer: 'Judu pirmyn',
      },
      {
        date: '2015-03-07',
        section: 'Gramatika',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2014-12-13',
        section: 'Gramatika',
        value: 'Judu pirmyn',
        answer: 'Judu pirmyn',
      },
      {
        date: '2014-05-14',
        section: 'Gramatika',
        value: null,
        answer: null,
      },
      {
        date: '2014-04-12',
        section: 'Gramatika',
        value: 'Galiu dalintis su kitais',
        answer: 'Galiu dalintis su kitais',
      },
    ],
    skyryba: [
      {
        date: '2019-06-13',
        section: 'Skyryba',
        value: 'Pirmieji žingsniai',
        answer: 'Nemoku taisyklių',
      },
      {
        date: '2018-09-21',
        section: 'Skyryba',
        value: 'Judu pirmyn',
        answer: 'Judu pirmyn',
      },
      {
        date: '2017-09-03',
        section: 'Skyryba',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2016-10-15',
        section: 'Skyryba',
        value: 'Pirmieji žingsniai',
        answer: 'Nemoku taisyklių',
      },
      {
        date: '2016-09-15',
        section: 'Skyryba',
        value: null,
        answer: null,
      },
      {
        date: '2016-09-06',
        section: 'Skyryba',
        value: 'Pirmieji žingsniai',
        answer: 'Nemoku taisyklių',
      },
      {
        date: '2015-09-02',
        section: 'Skyryba',
        value: 'Pirmieji žingsniai',
        answer: 'Nemoku taisyklių',
      },
      {
        date: '2015-05-15',
        section: 'Skyryba',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2015-03-07',
        section: 'Skyryba',
        value: 'Galiu dalintis su kitais',
        answer: 'Be klaidų dedu skyrybos ženklus',
      },
      {
        date: '2014-12-13',
        section: 'Skyryba',
        value: 'Man pavyksta',
        answer: 'Man pavyksta',
      },
      {
        date: '2014-05-14',
        section: 'Skyryba',
        value: 'Pirmieji žingsniai',
        answer: 'Nemoku taisyklių',
      },
      {
        date: '2014-04-12',
        section: 'Skyryba',
        value: 'Judu pirmyn',
        answer: 'Judu pirmyn',
      },
    ],
    Rašyba: [
      {
        date: '2019-06-13',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2018-09-21',
        section: 'Rašyba',
        value: 'Galiu dalintis su kitais',
        answer: 'Nedarau klaidų',
      },

      {
        date: '2017-09-03',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2016-10-15',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2016-09-15',
        section: 'Rašyba',
        value: 'Man pavyksta',
        answer: 'Retai klystu',
      },

      {
        date: '2016-09-06',
        section: 'Rašyba',
        value: 'Judu pirmyn',
        answer: 'Turiu pagrindus',
      },

      {
        date: '2015-09-02',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2015-05-15',
        section: 'Rašyba',
        value: null,
        answer: null,
      },

      {
        date: '2015-03-07',
        section: 'Rašyba',
        value: 'Man pavyksta',
        answer: 'Retai klystu',
      },

      {
        date: '2014-12-13',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2014-05-14',
        section: 'Rašyba',
        value: 'Pirmieji žingsniai',
        answer: 'Mokausi taisykles',
      },

      {
        date: '2014-04-12',
        section: 'Rašyba',
        value: 'Judu pirmyn',
        answer: 'Turiu pagrindus',
      },
    ],
  },
  {
    id: 6,
    template: '3-2-1',
    title: '3-2-1',
    question: 'Pagalvokite, ką sakysite  pažįstamiems apie šiandienos pamoką:',
    q3: '3 svarbūs dalykai, kurių aš išmokau pamokoje',
    q2: '2 dalykai, kurių turėčiau pasimokyti',
    q1: '1 dalykas, kurio visiškai nesupratau',
    three: [
      {
        date: '2019-06-13',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Išmoko viską, ką reikėjo',
      },
      {
        date: '2018-09-21',
        data: null,
        note: null,
      },
      {
        date: '2017-09-03',
        data: ['Nauji apibrėžimai', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2016-10-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra', null],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2016-09-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra', null],
        note: 'Nieko naujo neišmoko',
      },
      {
        date: '2016-09-06',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2015-09-02',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2015-05-15',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2015-03-07',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: 'Beveik viską išmoko',
      },
      {
        date: '2014-12-13',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: null,
      },
      {
        date: '2014-05-14',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: null,
      },
      {
        date: '2014-04-12',
        data: ['Nauji apibrėžima', 'Taisyklės', 'Rašybos įgūdžių gerinimas'],
        note: null,
      },
    ],
    two: [
      {
        date: '2019-06-13',
        data: ['Nauji apibrėžimai', 'Taisyklės'],
        note: 'Žino, ką pasimokyti',
      },
      {
        date: '2018-09-21',
        data: null,
        note: null,
      },
      {
        date: '2017-09-03',
        data: ['Nauji apibrėžimai', 'Taisyklės'],
        note: 'Nežino',
      },
      {
        date: '2016-10-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Nežino',
      },
      {
        date: '2016-09-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Žino, ką pasimokyti',
      },
      {
        date: '2016-09-06',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Žino, ką pasimokyti',
      },
      {
        date: '2015-09-02',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: null,
      },
      {
        date: '2015-05-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: null,
      },
      {
        date: '2015-03-07',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Nežino',
      },
      {
        date: '2014-12-13',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Nežino',
      },
      {
        date: '2014-05-14',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: 'Nežino',
      },
      {
        date: '2014-04-12',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą', 'Rašinio struktūra '],
        note: null,
      },
    ],
    one: [
      {
        date: '2019-06-13',
        data: ['Nauji apibrėžimai'],
        note: 'Teisingai',
      },
      {
        date: '2018-09-21',
        data: null,
      },
      {
        date: '2017-09-03',
        data: ['Nauji apibrėžimai'],
        note: 'Beveik teisingai',
      },
      {
        date: '2016-10-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Neteisingai',
      },
      {
        date: '2016-09-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Beveik teisingai',
      },
      {
        date: '2016-09-06',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Puikiai ',
      },
      {
        date: '2015-09-02',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Puikiai ',
      },
      {
        date: '2015-05-15',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: null,
      },
      {
        date: '2015-03-07',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Beveik teisingai',
      },
      {
        date: '2014-12-13',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: null,
      },
      {
        date: '2014-05-14',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: 'Beveik teisingai',
      },
      {
        date: '2014-04-12',
        data: ['Nauji apibrėžimai ir taisyklės apie skyrybą ir rašybą'],
        note: null,
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Papasakok, kaip sekėsi skaityti knygas iš sąrašo.',
    type: 'text',
    answers: [
      {
        date: '2019-06-13',
        value:
          'Pasirinkau tas knygas, kurių aprašymai mane labiausiai sudomino. Rinkausi ir pagal egzamino programą',
        note: 'Puikiai',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value:
          'Skaičiau knygas eilės tvarka pagal sąrašą. Kasdien stengdavausi perskaityti bent po 50 puslapių, kadangi norėjau perskaityti visas.',
        note: 'Puikiai',
      },
      {
        date: '2016-10-15',
        value:
          'Pasirinkau tas knygas, kurių aprašymai mane labiausiai sudomino. Rinkausi ir pagal egzamino programą.',
        note: null,
      },
      {
        date: '2016-09-15',
        value:
          'Pirmiausia, skaičiau tas knygas, kurios man pasirodė įdomiausios arba parekomendavo draugai.',
        note: 'Vidutiniškai',
      },
      {
        date: '2016-09-06',
        value:
          'Neskaičiau knygų iš sąrašo, kadangi neatradau knygų, kurios atitiktų mano mėgiamos rūšies literatūrą. Neturėjau jokių rekomendacijų.',
        note: 'Prastai',
      },
      {
        date: '2015-09-02',
        value:
          'Pasirinkau tas knygas, kurių aprašymai mane labiausiai sudomino. Rinkausi ir pagal egzamino programą',
        note: 'Puikiai',
      },
      {
        date: '2015-05-15',
        value: null,
      },
      {
        date: '2015-03-07',
        value:
          'Pasirinkau tas knygas, kurių aprašymai mane labiausiai sudomino. Rinkausi ir pagal egzamino programą.',
        note: null,
      },
      {
        date: '2014-12-13',
        value:
          'Pirmiausia, skaičiau tas knygas, kurios man pasirodė įdomiausios arba parekomendavo draugai.',
        note: 'Vidutiniškai',
      },
      {
        date: '2014-05-14',
        value:
          'Neskaičiau knygų iš sąrašo, kadangi neatradau knygų, kurios atitiktų mano mėgiamos rūšies literatūrą. Neturėjau jokių rekomendacijų.',
        note: 'Prastai',
      },
      {
        date: '2014-04-12',
        value:
          'Pasirinkau tas knygas, kurių aprašymai mane labiausiai sudomino. Rinkausi ir pagal egzamino programą',
        note: 'Puikiai',
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Kaip įvertintumėte savo pristymą apie knygas?',
    type: 'select',
    answers: [
      {
        date: '2019-06-13',
        value: 'Išsamus',
      },
      {
        date: '2018-09-21',
        value: 'Trūksta įžvalgų',
      },
      {
        date: '2017-09-03',
        value: 'Netinkamas',
      },
      {
        date: '2016-10-15',
        value: 'Trūksta įžvalgų',
      },
      {
        date: '2016-09-15',
        value: null,
      },
      {
        date: '2016-09-06',
        value: 'Netinkamas',
      },
      {
        date: '2015-09-02',
        value: 'Išsamus',
      },
      {
        date: '2015-05-15',
        value: 'Netinkamas',
      },
      {
        date: '2015-03-07',
        value: 'Trūksta įžvalgų',
      },
      {
        date: '2014-12-13',
        value: 'Trūksta įžvalgų',
      },
      {
        date: '2014-05-14',
        value: null,
      },
      {
        date: '2014-04-12',
        value: 'Netinkamas',
      },
    ],
  },
  {
    id: 4,
    template: 'question',
    title: 'KLAUSIMAS',
    question: 'Kaip vertini savo gebėjimus analizuoti literatūros kūrinius?',
    type: 'interval',
    answers: [
      {
        date: '2019-06-13',
        value: 70,
      },
      {
        date: '2018-09-21',
        value: 77,
      },
      {
        date: '2017-09-03',
        value: 66,
      },
      {
        date: '2016-10-15',
        value: 91,
      },
      {
        date: '2016-09-15',
        value: 12,
      },
      {
        date: '2016-09-06',
        value: 80,
      },
      {
        date: '2015-09-02',
        value: 81,
      },
      {
        date: '2015-05-15',
        value: 99,
      },
      {
        date: '2015-03-07',
        value: 12,
      },
      {
        date: '2014-12-13',
        value: 45,
      },
      {
        date: '2014-05-14',
        value: 92,
      },
      {
        date: '2014-04-12',
        value: 100,
      },
    ],
  },
  {
    id: 13,
    template: '3k',
    title: '3K',
    question: 'Kaip vertini šią pamoką?',
    q1: 'Ką žinau (prieš pamoką)?',
    q2: 'Ką noriu sužinoti?',
    q3: 'Ką sužinojau?',
    one: [
      {
        date: '2019-06-13',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Nieko nežino',
      },
      {
        date: '2016-10-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
      },
      {
        date: '2016-09-15',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2016-09-06',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2015-09-02',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2015-05-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2015-03-07',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Daug žino',
      },
      {
        date: '2014-12-13',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2014-05-14',
        value: null,
        note: null,
      },
      {
        date: '2014-04-12',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
    ],
    two: [
      {
        date: '2019-06-13',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Nieko nežino',
      },
      {
        date: '2016-10-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
      },
      {
        date: '2016-09-15',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2016-09-06',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2015-09-02',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2015-05-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2015-03-07',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Daug žino',
      },
      {
        date: '2014-12-13',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2014-05-14',
        value: null,
        note: null,
      },
      {
        date: '2014-04-12',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
    ],
    three: [
      {
        date: '2019-06-13',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Nieko nežino',
      },
      {
        date: '2016-10-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
      },
      {
        date: '2016-09-15',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2016-09-06',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
      {
        date: '2015-09-02',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Daug žino',
      },
      {
        date: '2015-05-15',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2015-03-07',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Daug žino',
      },
      {
        date: '2014-12-13',
        value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ',
        note: 'Nieko nežino',
      },
      {
        date: '2014-05-14',
        value: null,
        note: null,
      },
      {
        date: '2014-04-12',
        value:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        note: 'Pakankamai žino',
      },
    ],
  },
  {
    id: 11,
    template: 'pie',
    title: 'PYRAGO DALINIMAS',
    question:
      'Padalink „pyragą“ į tokias dalis, kurios rodytų kiekvieno grupės nario indėlį į atliktą užduotį.',
    members: ['Aurimas Juodeika', 'Tomas G.', 'Rimas Rimaitis'],
    answers: [
      {
        date: '2019-06-13',
        value: [48, 40, 12],
        note: 'Puikiai padalijo',
      },
      {
        date: '2018-09-21',
        value: null,
      },
      {
        date: '2017-09-03',
        value: [48, 40, 12],
        note: null,
      },
      {
        date: '2016-10-15',
        value: [48, 40, 12],
        note: 'Vidutiniškai padalijo',
      },
      {
        date: '2016-09-15',
        value: [48, 40, 12],
        note: 'Prastai padalijo',
      },
      {
        date: '2016-09-06',
        value: [48, 40, 12],
        note: 'Vidutiniškai padalijo',
      },
      {
        date: '2015-09-02',
        value: [48, 40, 12],
        note: 'Puikiai padalijo',
      },
      {
        date: '2015-05-15',
        value: [48, 40, 12],
        note: 'Vidutiniškai padalijo',
      },
      {
        date: '2015-03-07',
        value: [48, 40, 12],
        note: null,
      },
      {
        date: '2014-12-13',
        value: [48, 40, 12],
        note: 'Puikiai padalijo',
      },
      {
        date: '2014-05-14',
        value: [48, 40, 12],
        note: 'Vidutiniškai padalijo',
      },
      {
        date: '2014-04-12',
        value: [48, 40, 12],
        note: null,
      },
    ],
  },
  {
    id: 8,
    template: 'indicator',
    title: 'SKALĖ',
    question: 'Kaip vertini savo gebėjimus analizuoti literatūros kūrinius?',
    answers: [
      {
        date: '2019-06-13',
      },
      {
        date: '2018-09-21',
      },
      {
        date: '2017-09-03',
      },
      {
        date: '2016-10-15',
      },
      {
        date: '2016-09-15',
      },
      {
        date: '2016-09-06',
      },
      {
        date: '2015-09-02',
      },
      {
        date: '2015-05-15',
      },
      {
        date: '2015-03-07',
      },
      {
        date: '2014-12-13',
      },
      {
        date: '2014-05-14',
      },
      {
        date: '2014-04-12',
      },
    ],
  },
];
