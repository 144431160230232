<template>
  <section id="custom__list" class="custom__list" v-if="!loading">
    <template-header @handleTemplateSearch="handleTemplateSearch"></template-header>
    <transition-group
      tag="div"
      v-if="templatesPrivate"
      name="template-transition"
      class="template__card__container"
    >
      <template-card
        v-for="template in templatesPrivate"
        :key="template.id"
        :iconName="template.icon"
        :personal="true"
        :draft="!template.active"
        :id="template.id"
        :template="template"
        :type="'private'"
        @handleDelete="handleDelete"
      >
        <template v-slot:header>{{ template.title }}</template>
        <template v-slot:time>{{ template.duration }}</template>
        <template v-slot:author>{{ template.owner.name }} {{ template.owner.lastName }}</template>
        <template v-slot:content>{{ template.description }}</template>
      </template-card>
    </transition-group>
    <div class="custom__list-container" v-if="!templatesPrivate.length && !templateSearch">
      <div class="custom__list-card">
        <svgicon name="broken-pen" :original="true" height="3em"></svgicon>
        <header-h2>Kol kas jokių ruošinių neturite</header-h2>
        <router-link :to="{ name: 'CreateReflection' }">
          <button-4>Kurti naują klausimyną</button-4>
        </router-link>
      </div>
    </div>
    <div class="custom__list-container" v-else-if="!templatesPrivate.length && templateSearch">
      <svgicon class="custom__list-container__icon" name="large-search"></svgicon>
      <p class="custom__list-container__title">Paieškos rezultatų pagal tokią užklausą nerasta</p>
    </div>
    <pagination
      v-if="totalPages > 1"
      :activePage="activePage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
  </section>
  <loading v-else></loading>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import TemplateHeader from '@/components/templates/TemplateHeader.vue';
import TemplateCard from '@/components/templates/TemplateCard.vue';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import Pagination from '@/components/base/Pagination.vue';
import Loading from '@/components/base/Loading.vue';

export default {
  name: 'Custom',
  components: {
    TemplateHeader,
    HeaderH2,
    Button4,
    TemplateCard,
    Pagination,
    Loading,
  },
  data() {
    return {
      queries: {
        limit: 10,
        q: null,
        offset: null,
      },
      totalCount: null,
      activePage: 1,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('reflections', ['templatesPrivate']),
    ...mapGetters('meta', ['templateSearch', 'loading']),
    totalPages() {
      return Math.ceil(this.totalCount / this.queries.limit) || 0;
    },
  },
  methods: {
    ...mapActions('reflections', ['getPrivateTemplates']),
    ...mapActions('meta', ['setLoading']),
    async handlePageInit() {
      await this.getPrivateTemplates(this.queries).then(this.handleRequest);
      this.setLoading(false);
    },
    handleRequest({ totalCount, success }) {
      if (totalCount && success) this.totalCount = totalCount;
    },
    selectPage(page) {
      this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
      this.getPrivateTemplates(this.queries).then(this.handleRequest);
      this.activePage = page;
    },
    handleTemplateSearch(value) {
      this.queries.q = value;
      this.queries.offset = null;
      this.totalCount = null;
      this.activePage = 1;
      this.getPrivateTemplates(this.queries).then(this.handleRequest);
    },
    handleDelete(success) {
      if (success) this.getPrivateTemplates(this.queries).then(this.handleRequest);
    },
  },
};
</script>
<style lang="scss" scoped>
.template {
  &__card {
    &__container {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
.custom {
  &__list {
    max-width: 940px;
    margin: 0 auto;

    &-container {
      margin-top: 30px;
    }

    &-card {
      text-align: center;
      width: 260px;
      height: 120px;
      margin: 0 auto;
      h2 {
        margin-top: 10px;
      }
      button {
        margin-top: 15px;
        padding: 5px 20px;
      }
    }
  }
}
</style>
