<template>
  <div class="faq__header">
    <header-h1>{{ $t("faq.faq") }}</header-h1>
    <!-- <div class="header__actions">
      <switch-nav
        :key="'faq-nav'"
        :links="[{ link: {name: 'Faq'}, description: 'DUK' }, { link: {name: 'Lessons'}, description: 'Mokomoji medžiaga' }]"
      ></switch-nav>
    </div>-->
  </div>
</template>
<script>
// import SwitchNav from '@/components/Menu/SwitchNav.vue';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

export default {
  name: 'FaqHeader',
  components: {
    HeaderH1,
    // SwitchNav,
  },
};
</script>
<style lang="scss" scoped>
.faq__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  .header__actions {
    margin-top: 20px;
    a.router-link-active {
      button {
        background: $green-3;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}
</style>
