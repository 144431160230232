/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-circle-grey': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M7.267 0C3.26 0 0 3.26 0 7.266c0 4.007 3.26 7.266 7.267 7.266 4.006 0 7.265-3.26 7.265-7.266S11.272 0 7.267 0zm0 13.532A6.274 6.274 0 0 1 1 7.266 6.273 6.273 0 0 1 7.267 1a6.272 6.272 0 0 1 6.265 6.266 6.273 6.273 0 0 1-6.265 6.266zm.795-3.924c.225.23.338.514.338.851 0 .337-.113.62-.338.85-.226.23-.512.345-.857.345-.337 0-.618-.115-.844-.345a1.161 1.161 0 0 1-.339-.85c0-.337.113-.62.34-.85.225-.23.506-.345.843-.345.345 0 .63.115.857.344zm1.707-5.786c.238.325.357.693.357 1.104 0 .303-.064.583-.191.838a2.476 2.476 0 0 1-.462.653c-.181.18-.415.398-.702.653a5.71 5.71 0 0 0-.624.573c-.143.16-.236.327-.277.499a.648.648 0 0 1-.24.419.712.712 0 0 1-.45.148c-.213 0-.38-.07-.498-.21-.12-.14-.18-.337-.18-.59 0-.388.074-.71.223-.969.147-.258.353-.523.616-.794.188-.206.33-.382.425-.53a.897.897 0 0 0 .141-.493.527.527 0 0 0-.197-.432c-.13-.106-.312-.16-.542-.16-.189 0-.376.035-.56.105-.185.07-.393.17-.623.301l-.296.149c-.279.156-.504.234-.677.234a.653.653 0 0 1-.524-.265.984.984 0 0 1-.216-.647c0-.173.03-.316.092-.431.062-.115.162-.226.303-.332.37-.272.79-.484 1.263-.635a4.61 4.61 0 0 1 1.423-.229c.534 0 1.01.093 1.43.278.418.184.747.439.986.763z" _fill="#919396"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h14.532v14.532H0z"/></clipPath></defs>'
  }
})
