/* eslint-disable */
require('./404')
require('./add_question')
require('./add-big')
require('./add')
require('./app-logo-navigation')
require('./arrow-right')
require('./bin-2')
require('./bin')
require('./blue-arrow')
require('./blue-info-circle')
require('./breadcrumb-arrow')
require('./broken-pen')
require('./calendar-switch-inactive')
require('./calendar-switch')
require('./camera')
require('./certified')
require('./check-no')
require('./check-yes')
require('./checkbox-green')
require('./chevron-big')
require('./chevron-small')
require('./chevron')
require('./clock-green')
require('./clock')
require('./collapse')
require('./comments')
require('./copy_template')
require('./delete-question')
require('./delete')
require('./dialog-check')
require('./dialog-heart')
require('./duplicate_question')
require('./duplicate')
require('./edit-button')
require('./edit')
require('./emotes')
require('./envelope')
require('./excel')
require('./exit')
require('./expand-questions')
require('./expand')
require('./face')
require('./facebook')
require('./faces')
require('./filter-icon')
require('./flags')
require('./folder')
require('./globe')
require('./green-arrow')
require('./green-check')
require('./green-stats')
require('./grip-tab')
require('./grip')
require('./house')
require('./info-circle-grey')
require('./info')
require('./large-check')
require('./large-confirm')
require('./large-envelope-happy')
require('./large-logo')
require('./large-question-mark')
require('./large-question')
require('./large-remove')
require('./large-search-2')
require('./large-search')
require('./large-send')
require('./line-chart')
require('./link')
require('./list-switch')
require('./list-view-off')
require('./list-view-on')
require('./location')
require('./lock')
require('./logos')
require('./member-answers')
require('./methods')
require('./minus')
require('./mobile')
require('./multiply')
require('./oval')
require('./page_next')
require('./page_prev')
require('./paysera-logo')
require('./pen')
require('./people')
require('./person')
require('./phone')
require('./pie-chart')
require('./plane')
require('./play-button')
require('./plus')
require('./profile-circle-grey')
require('./purple-arrow')
require('./question-circle-grey')
require('./question-example')
require('./question')
require('./R')
require('./refresh-green')
require('./repeat')
require('./save')
require('./search-grey')
require('./search')
require('./send-again')
require('./share_template')
require('./shopping-cart')
require('./sim-card')
require('./smileys')
require('./stars')
require('./stats')
require('./student')
require('./tab-view-off')
require('./tab-view-on')
require('./time-out')
require('./triangle-arrow')
require('./user-clock')
require('./user-edit')
require('./user-history')
require('./user-icon')
require('./user-services')
require('./user-settings')
require('./user-suspend')
require('./user-trial-info')
require('./user-type-org')
require('./user-type-teacher')
require('./user-users')
require('./user-warning-big')
require('./warning-small')
require('./warning')
