/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-scared': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="13.35" cy="17.58" rx="3.54" ry="6.01" _fill="#71523f"/><path pid="4" d="M16.34 20.8a5.44 5.44 0 0 0-5.91.19c.64 1.57 1.71 2.6 2.92 2.6s2.37-1.12 2.99-2.79z" _fill="#ff6669"/><ellipse pid="5" cx="7.06" cy="10.18" rx="4.59" ry="3.25" transform="rotate(-63.86 7.065 10.184)" _fill="#fff"/><ellipse pid="6" cx="19.64" cy="10.18" rx="3.25" ry="4.59" transform="rotate(-26.14 19.63 10.186)" _fill="#fff"/><path pid="7" d="M20.85 6.22a.39.39 0 0 1-.39-.39V.94a.39.39 0 1 1 .78 0v4.89a.39.39 0 0 1-.39.39zM22.65 5.43a.39.39 0 0 1-.39-.43V1.52a.39.39 0 1 1 .78 0V5a.39.39 0 0 1-.39.43zM24.48 8a.39.39 0 0 1-.39-.39V2.37a.39.39 0 0 1 .78 0v5.27a.39.39 0 0 1-.39.36z" _fill="#3f3e40"/></g></g>'
  }
})
