/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-shocked': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.43" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M17 4.82l.42.12c.3.08.58.16.86.26a7.6 7.6 0 0 1 1.13.48 6.69 6.69 0 0 1 1 .66 10.06 10.06 0 0 1 1 .89.06.06 0 0 0 .1 0A2.54 2.54 0 0 0 21 5.81a3.33 3.33 0 0 0-1.13-1 3.52 3.52 0 0 0-1.36-.39h-.05a2.94 2.94 0 0 0-1.46.29.06.06 0 0 0 0 .11zM9.67 4.82l-.42.12c-.3.08-.58.16-.86.26a7.6 7.6 0 0 0-1.13.48 6.69 6.69 0 0 0-1 .66 10.06 10.06 0 0 0-1 .89.06.06 0 0 1-.1 0 2.54 2.54 0 0 1 .52-1.36 3.33 3.33 0 0 1 1.13-1 3.52 3.52 0 0 1 1.39-.41h.05a2.94 2.94 0 0 1 1.42.25.06.06 0 0 1 0 .11z" _fill="#3f3e40"/><path pid="6" d="M18.27 19.27C18 16.32 15.89 14 13.34 14s-4.64 2.3-4.91 5.25z" _fill="#71523f"/><path pid="7" d="M8.43 19.29s4.14-4.16 9.84 0z" _fill="#ff6669"/></g></g>'
  }
})
