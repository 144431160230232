/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-remove': {
    width: 118.35,
    height: 79.825,
    viewBox: '0 0 118.35 79.825',
    data: '<g data-name="Group 3909"><path pid="0" data-name="Path 3368" d="M59.925 79.825a34.007 34.007 0 0 1-11.7-2.1 34.091 34.091 0 0 1-19.3-17.6 33.811 33.811 0 0 1-1.2-26.1 34.091 34.091 0 0 1 17.6-19.3 38.684 38.684 0 0 1 6.5-2.3 16.47 16.47 0 0 1 3.2-.6 1.244 1.244 0 0 1 1.4 1.1 1.244 1.244 0 0 1-1.1 1.4 13.564 13.564 0 0 0-2.9.6 30.446 30.446 0 0 0-6 2.1 31.7 31.7 0 0 0-16.4 17.9 31.262 31.262 0 0 0 1.1 24.2 31.723 31.723 0 0 0 17.9 16.4 31.263 31.263 0 0 0 24.2-1.1 31.5 31.5 0 0 0 18.2-27.7 1.181 1.181 0 0 1 1.3-1.2 1.24 1.24 0 0 1 1.2 1.3 34.172 34.172 0 0 1-19.7 29.9 34.671 34.671 0 0 1-14.3 3.1zm56.8-23.3l1.4-1.4a.785.785 0 0 0 0-1.1.785.785 0 0 0-1.1 0l-1.4 1.4-1.4-1.4a.785.785 0 0 0-1.1 0 .785.785 0 0 0 0 1.1l1.4 1.4-1.4 1.4a.785.785 0 0 0 0 1.1.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2l1.4-1.4 1.4 1.4a.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2.785.785 0 0 0 0-1.1zm-87.1-53.6l1.6-1.6a.785.785 0 0 0 0-1.1.785.785 0 0 0-1.1 0l-1.6 1.6-1.6-1.6a.785.785 0 0 0-1.1 0 .785.785 0 0 0 0 1.1l1.6 1.6-1.6 1.6a.785.785 0 0 0 0 1.1.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2l1.6-1.6 1.6 1.6a.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2.785.785 0 0 0 0-1.1zm-23.2 65.6l-2-2 2-2a.785.785 0 0 0 0-1.1.785.785 0 0 0-1.1 0l-2 2-2-2a.785.785 0 0 0-1.1 0 .785.785 0 0 0 0 1.1l2 2-2 2a.785.785 0 0 0 0 1.1.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2l2-2 2 2a.764.764 0 0 0 .5.2.764.764 0 0 0 .5-.2.674.674 0 0 0 .2-1.1zm83.7-35.7l-9.2-9 9-9.2a2.5 2.5 0 0 0 0-3.5 2.5 2.5 0 0 0-3.5 0l-9 9.2-9.2-9a2.5 2.5 0 0 0-3.5 0 2.5 2.5 0 0 0 0 3.5l9.2 9-9 9.2a2.5 2.5 0 0 0 0 3.5 2.342 2.342 0 0 0 1.7.7 2.688 2.688 0 0 0 1.8-.8l9-9.2 9.2 9a2.342 2.342 0 0 0 1.7.7 2.689 2.689 0 0 0 1.8-.8 2.189 2.189 0 0 0 0-3.3z" _fill="#474eb9"/></g>'
  }
})
