<template>
  <div class="template-filter-container">
    <div
      class="filter-card flex-global"
      v-for="(mainFilter, index) in preparedFilterData"
      v-show="displayTargetGroupFilters(index)"
      :key="mainFilter.id"
    >
      <p class="filter-title">{{ mainFilter.name }}</p>
      <div
        class="filter-items-wrapper select-type-wrapper"
        v-if="isSelectTypeInput(mainFilter.value)"
      >
        <multi-select-filter
          :options="mainFilter.filterItems"
          :id="`questionnaire-select`"
          :class="`filter-type-select`"
          @selectedValue="(args) => handleSelectChange(mainFilter.value, ...args)"
          :selected="
            selectedPayload.questionnaireGroup || selectedPayload.defaultQuestionnaireGroupValue
          "
        ></multi-select-filter>
      </div>
      <div v-else class="filter-items-wrapper">
        <div
          class="filter-item"
          :class="{ active: isAllValues.classes }"
          v-if="mainFilter.value === filterTypes.classes"
        >
          <div class="custom-checkbox" :class="{ disabled: isAllValues.classes }">
            <input
              type="checkbox"
              :id="`all-classes`"
              :value="isAllValues.classes"
              v-model="isAllValues.classes"
              @change="(e) => handleIsAll(mainFilter.value, e.target.checked)"
            />
            <label :for="`all-classes`"></label>
          </div>
          {{ $t('all_2') }}
        </div>
        <div
          class="filter-item"
          :class="{ active: isAllValues.studySubject }"
          v-if="mainFilter.value === filterTypes.studySubject"
        >
          <div class="custom-checkbox" :class="{ disabled: isAllValues.studySubject }">
            <input
              type="checkbox"
              :id="`all-study-subjects`"
              :value="isAllValues.studySubject"
              v-model="isAllValues.studySubject"
              @change="(e) => handleIsAll(mainFilter.value, e.target.checked)"
            />
            <label :for="`all-study-subjects`"></label>
          </div>
          {{ $t('all_1') }}
        </div>
        <div
          class="filter-item"
          :class="{ active: isAllValues.reflectionObjective }"
          v-if="mainFilter.value === filterTypes.reflectionObjective"
        >
          <div class="custom-checkbox" :class="{ disabled: isAllValues.reflectionObjective }">
            <input
              type="checkbox"
              :id="`all-reflection-objective`"
              :value="isAllValues.reflectionObjective"
              v-model="isAllValues.reflectionObjective"
              @change="(e) => handleIsAll(mainFilter.value, e.target.checked)"
            />
            <label :for="`all-reflection-objective`"></label>
          </div>
          {{ $t('all_1') }}
        </div>
        <div
          class="filter-item"
          :class="{ active: isAllValues.fillingFrequency }"
          v-if="mainFilter.value === filterTypes.fillingFrequency"
        >
          <div class="custom-checkbox" :class="{ disabled: isAllValues.fillingFrequency }">
            <input
              type="checkbox"
              :id="`all-filling-frequency`"
              :value="isAllValues.fillingFrequency"
              v-model="isAllValues.fillingFrequency"
              @change="(e) => handleIsAll(mainFilter.value, e.target.checked)"
            />
            <label :for="`all-filling-frequency`"></label>
          </div>
          {{ $t('all_1') }}
        </div>

        <div
          class="filter-item"
          :class="{ active: filterItem.checked }"
          v-for="filterItem in mainFilter.filterItems"
          :key="filterItem.value"
        >
          <div class="custom-checkbox">
            <!-- <input
              type="radio"
              v-if="isRadioTypeInput(mainFilter.value)"
              :id="`${filterItem.value}`"
              :value="{ type: mainFilter.value, value: filterItem.value }"
              v-model="fillingFreqValues"
            /> -->
            <input
              type="checkbox"
              :id="`${filterItem.value}`"
              :value="{ type: mainFilter.value, value: filterItem.value }"
              v-model="checkedValues"
              @change="
                (e) =>
                  handleFilterItemCheckWrapper(mainFilter.value, {
                    checked: e.target.checked,
                    value: filterItem.value,
                  })
              "
            />
            <label
              :class="{ radioLabel: isRadioTypeInput(mainFilter.value) }"
              :for="`${filterItem.value}`"
            ></label>
          </div>
          {{ filterItem.value }}
        </div>
      </div>
    </div>
    <div class="filter-card action-buttons flex-global">
      <span class="remove-filter-action-button hidden" @click="handleRemoveFilter">
        {{ $t('templates_filter.delete_filter_action') }}
      </span>
      <div class="flex-global">
        <button-3 class="cancel-filter-action-button" @click.native="handleCloseFilter">
          {{ $t('actions.cancel_action') }}
        </button-3>
        <button-4 class="apply-filter-action-button" @click.native="handleApplyFilter">
          {{ $t('templates_filter.apply_filter_action') }}
        </button-4>
      </div>
    </div>
  </div>
</template>
<script>
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import MultiSelectFilter from '@/components/elements/select/MultiSelectFilter.vue';
  import {
    FILTER_TYPE_GROUP,
    FILTER_TYPE_CLASSES,
    FILTER_TYPE_STUDY,
    FILTER_TYPE_FREQ,
    FILTER_TYPE_OBJECTIVE,
  } from '@/helpers/constants/template';

  export default {
    name: 'TemplateFilter',
    components: {
      Button3,
      Button4,
      MultiSelectFilter,
    },
    props: ['filterData'],
    data() {
      return {
        fillingFreqValues: [],
        checkedValues: [],
        filterTypes: {
          questionnaireGroup: FILTER_TYPE_GROUP,
          classes: FILTER_TYPE_CLASSES,
          studySubject: FILTER_TYPE_STUDY,
          fillingFrequency: FILTER_TYPE_FREQ,
          reflectionObjective: FILTER_TYPE_OBJECTIVE,
        },
        filterPayload: [],
        selectedPayload: {
          questionnaireGroup: null,
          defaultQuestionnaireGroupValue: null,
        },
        isAllValues: {
          classes: true,
          studySubject: true,
          reflectionObjective: true,
          fillingFrequency: true,
        },
      };
    },
    computed: {
      preparedFilterData() {
        return this.filterData
          ? Object.entries(this.filterData).map((value, index) => {
              const filterName = this.getFilterName(value[0]);
              const filterItems = value[1].map((filterValue, valueIndex) => {
                if (this.isSelectTypeInput(value[0]) && valueIndex === 0) {
                  this.setQuestionnaireGroupDefaultValue(filterValue);
                }
                const isChecked =
                  this.filterPayload && this.filterPayload.length
                    ? this.filterPayload.find(
                        (payloadValue) =>
                          payloadValue.type === value[0] && payloadValue.value === filterValue
                      )
                    : false;

                const isCheckedRadio =
                  (this.fillingFreqValues.type === value[0] &&
                    this.fillingFreqValues.value === filterValue) ||
                  false;

                return {
                  value: filterValue,
                  checked: this.isRadioTypeInput(value[0]) ? !!isCheckedRadio : !!isChecked,
                };
              });
              return {
                id: `${index}-${value[0]}`,
                value: value[0],
                name: filterName,
                filterItems,
              };
            })
          : [];
      },
      preparedFilterPayload() {
        return {
          questionnaireGroup:
            this.selectedPayload && this.selectedPayload.questionnaireGroup
              ? this.selectedPayload.questionnaireGroup.value
              : this.selectedPayload.defaultQuestionnaireGroupValue.value,
          classes: this.getFilterPayloadValue(this.filterTypes.classes) || null,
          studySubject: this.getFilterPayloadValue(this.filterTypes.studySubject) || null,
          fillingFrequency: this.getFilterPayloadValue(this.filterTypes.fillingFrequency) || null,
          reflectionObjective:
            this.getFilterPayloadValue(this.filterTypes.reflectionObjective) || null,
        };
      },
      isTargetGroupSelected() {
        return !!(
          !this.selectedPayload.questionnaireGroup ||
          (this.selectedPayload.questionnaireGroup &&
            this.selectedPayload.defaultQuestionnaireGroupValue &&
            this.selectedPayload.questionnaireGroup.value ===
              this.selectedPayload.defaultQuestionnaireGroupValue.value)
        );
      },
    },
    methods: {
      getFilterName(name) {
        switch (name) {
          case this.filterTypes.questionnaireGroup:
            return this.$t('templates_filter.input_group_title');
          case this.filterTypes.classes:
            return this.$t('templates_filter.input_class_title');
          case this.filterTypes.studySubject:
            return this.$t('templates_filter.input_subject_title');
          case this.filterTypes.fillingFrequency:
            return this.$t('templates_filter.input_frequency_title');
          case this.filterTypes.reflectionObjective:
            return this.$t('templates_filter.input_objective_title');
          default:
            return '';
        }
      },
      getFilterPayloadValue(name) {
        return this.filterPayload && this.filterPayload.length
          ? this.filterPayload
              .filter((filterValue) => filterValue.type === name)
              .map((filterValue) => filterValue.value)
              .join(',')
          : null;
      },
      setQuestionnaireGroupDefaultValue(filterValue) {
        this.selectedPayload.defaultQuestionnaireGroupValue = { value: filterValue };
      },
      handleFilterItemCheckWrapper(filterValue, value) {
        this.handleFilterItemCheck(filterValue, value);
      },
      handleFilterItemCheck(filterValue, value) {
        if (value.checked) {
          this.filterPayload.push({ type: filterValue, value: value.value });
          this.isAllValues[filterValue] = false;
        } else {
          const updatedFilterPayload = this.filterPayload.filter(
            (payloadItem) =>
              !(payloadItem.type === filterValue && payloadItem.value === value.value)
          );
          this.filterPayload = updatedFilterPayload;
        }
      },
      handleIsAll(filterValue, isChecked) {
        if (isChecked) {
          this.isAllValues[filterValue] = true;

          // update checked values
          const updatedCheckedValues = this.checkedValues.filter(
            (value) => value.type !== filterValue
          );
          this.checkedValues = updatedCheckedValues;

          // update filter payload
          const updatedFilterPayload = this.filterPayload.filter(
            (payloadItem) => payloadItem.type !== filterValue
          );
          this.filterPayload = updatedFilterPayload;
        } else {
          this.isAllValues[filterValue] = false;
        }
      },
      handleSelectChange(type, value) {
        const selectedValue = value.value;

        this.selectedPayload[type] = { value: selectedValue };
        if (!this.isTargetGroupSelected) {
          this.setDefaultFilterValues();
        }
      },
      isRadioTypeInput() {
        return false;
      },
      isSelectTypeInput(type) {
        return type === this.filterTypes.questionnaireGroup;
      },
      displayTargetGroupFilters(index) {
        return (!this.isTargetGroupSelected && index === 0) || this.isTargetGroupSelected;
      },
      setDefaultFilterValues() {
        this.filterPayload = [];
        this.checkedValues = [];
        this.fillingFreqValues = [];
        this.isAllValues = {
          classes: true,
          studySubject: true,
          reflectionObjective: true,
          fillingFrequency: true,
        };
      },
      handleRemoveFilter() {
        this.setDefaultFilterValues();
        this.selectedPayload.questionnaireGroup = this.selectedPayload.defaultQuestionnaireGroupValue;
      },
      handleCloseFilter() {
        this.setDefaultFilterValues();
        this.selectedPayload.questionnaireGroup = this.selectedPayload.defaultQuestionnaireGroupValue;
        this.$emit('handleCloseFilter');
      },
      handleApplyFilter() {
        const filterObjectEntries = Object.entries(this.preparedFilterPayload);
        filterObjectEntries.forEach(([key, value]) => {
          if (!value) {
            this.isAllValues[key] = true;
          }
        });
        this.$emit('handleApplyFilter', this.preparedFilterPayload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .template-filter-container {
    .filter-card {
      justify-content: flex-start;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      padding: 25px 20px;
      background: #fff;
      .filter-title {
        flex: 1;
        margin-right: 20px;
      }
      .filter-items-wrapper {
        flex: 4;
        display: grid;
        grid-template-columns: repeat(5, auto);
        row-gap: 15px;
        column-gap: 5px;
        .filter-item {
          display: flex;
          font-size: 14px;
          color: $grey-5;
          &.active {
            color: #000;
          }
          .custom-checkbox {
            margin-right: 10px;
            &.disabled {
              pointer-events: none;
            }
          }
        }
        &.select-type-wrapper {
          .multiselect {
            width: 200px;
            color: #000;
          }
        }
      }
      &.action-buttons {
        justify-content: space-between;
        .remove-filter-action-button {
          height: 29px;
          font-size: 14px;
          font-weight: normal;
          line-height: 28px;
          color: $red-5;
          cursor: pointer;
          &.hidden {
            visibility: hidden;
          }
        }
        .apply-filter-action-button {
          margin-left: 10px;
        }
      }
    }
  }
</style>
