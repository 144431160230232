<template>
  <div class="lessons__filter">
    <div class="lessons-filter-wrapper" v-if="filterItems && filterItems.length">
      <button
        class="filter-button"
        :class="{'activeFilter': currentFilter === index}"
        v-for="(filterItem, index) in filterItems"
        :key="index"
        @click="handleTypeSelect(index)"
      >
        {{filterItem}}
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'LessonsFilter',
  props: ['filterItems', 'currentFilter', 'handleFilterChange'],
  methods: {
    handleTypeSelect(index){
      this.$emit('handleFilterChange', index);
    }
  },
};
</script>
<style lang="scss" scoped>
  .lessons__filter {
    margin: 40px 0;
    .lessons-filter-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
        .filter-button{
          margin: 5px 10px;
          padding: 4px 16px;
          font-family: 'Roboto-Medium';
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          border: 1px solid #b5b5b5;
          border-radius: 5px;
          background: rgba(132, 132, 132, .1);
          color: #888;
          cursor: pointer;
          &.activeFilter{
            border: 1px solid $orange;
            background: rgba(239, 166, 32, .1);
            color: $orange;
          }
        }
    }
  }
</style>
