/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'collapse': {
    width: 23.111,
    height: 23.112,
    viewBox: '0 0 23.111 23.112',
    data: '<g data-name="Group 2054" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-width="1.5"><g data-name="Group 1120"><path pid="0" data-name="Line 136" stroke-miterlimit="10" d="M22.05 1.06l-6.99 6.991"/><path pid="1" data-name="Path 834" d="M21.359 8.051h-6.3V1.752" stroke-linejoin="round"/></g><g data-name="Group 2008"><path pid="2" data-name="Line 136" stroke-miterlimit="10" d="M22.051 22.051l-6.99-6.99"/><path pid="3" data-name="Path 834" d="M15.06 21.36v-6.3h6.3" stroke-linejoin="round"/></g><g data-name="Group 2007"><path pid="4" data-name="Line 136" stroke-miterlimit="10" d="M1.06 22.051l6.991-6.99"/><path pid="5" data-name="Path 834" d="M1.752 15.06h6.3v6.3" stroke-linejoin="round"/></g><g data-name="Group 2009"><path pid="6" data-name="Line 136" stroke-miterlimit="10" d="M1.06 1.06l6.99 6.991"/><path pid="7" data-name="Path 834" d="M8.05 1.752v6.3H1.751" stroke-linejoin="round"/></g></g>'
  }
})
