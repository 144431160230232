<template>
  <div class="group__page" v-if="!loading">
    <section id="group" class="group">
      <group-upper-header :currentGroup="currentGroup" />
      <group-list-item :group="currentGroup" :isFromList="false" />
      <group-header :currentGroup="currentGroup"></group-header>
      <transition-group class="group__reflections" name="template-transition">
        <group-reflection
          v-for="questionnaire in groupQuestionnaireList.items"
          :key="questionnaire.id"
          :questionnaire="questionnaire"
          :groupId="currentGroupId"
        ></group-reflection>
      </transition-group>
      <pagination
        v-if="totalPages > 1"
        :activePage="groupQuestionnairePage"
        :totalPages="totalPages"
        @selectPage="selectPage"
      ></pagination>
    </section>
    <global-modal
      class="creation-modal"
      v-if="creation && creationModal"
      @handleModalToggle="handleCreationModalToggle"
    >
      <template v-slot:modal-main-icon>
        <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
      </template>
      <template v-slot:modal-header>{{ $t('messages.group_success_message_title') }}</template>
      <template v-slot:modal-warning>{{ $t('messages.group_success_message') }}</template>
      <template v-slot:modal-actions>
        <button-4 @click.native="handleCreationModalToggle">{{ $t('actions.ok_action') }}</button-4>
      </template>
    </global-modal>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import { handleGetGroup } from '@/helpers/groups.helpers';

  import GroupHeader from '@/components/groups/GroupHeader.vue';
  import GroupUpperHeader from '@/components/groups/GroupUpperHeader.vue';
  import GroupReflection from '@/components/groups/GroupReflection.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import GroupListItem from '@/components/groups/GroupListItem.vue';
  import Pagination from '@/components/base/Pagination.vue';
  import GlobalModal from '@/components/base/GlobalModal.vue';

  export default {
    name: 'Group',
    components: {
      GroupHeader,
      GroupUpperHeader,
      GroupReflection,
      Button4,
      GroupListItem,
      Pagination,
      GlobalModal,
    },
    data() {
      return {
        queries: {
          limit: 10,
          q: null,
          offset: null,
          id: null,
        },
        totalCount: null,
        creationModal: false,
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('meta', ['loading', 'groupQuestionnairePage']),
      ...mapGetters('groups', ['currentGroup']),
      ...mapGetters('questionnaire', ['groupQuestionnaireList']),
      currentGroupId() {
        const {
          params: { groupId },
        } = this.$route;
        return +groupId || this.currentGroup.id;
      },
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
      creation() {
        const { creation } = this.$route.params;
        return creation || false;
      },
    },
    methods: {
      ...mapActions('meta', ['setLoading', 'setGroupQuestionnairePage']),
      ...mapActions('groups', ['getGroup']),
      ...mapActions('questionnaire', ['getQuestionnairesByGroup']),
      handleGetGroup,
      async handlePageInit() {
        this.setLoading(true);
        this.creationModal = this.creation;
        this.queries.offset =
          this.groupQuestionnairePage === 1
            ? 0
            : this.groupQuestionnairePage * this.queries.limit - this.queries.limit;
        try {
          await this.handleGetGroup();
          await this.handleGetQuestionnaires();
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      selectPage(page) {
        this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
        this.setGroupQuestionnairePage(page);
        this.getQuestionnairesByGroup(this.queries);
      },
      async handleGetQuestionnaires() {
        const {
          params: { groupId },
        } = this.$route;
        this.queries.id = groupId;
        const {
          payload: { totalCount },
        } = await this.getQuestionnairesByGroup(this.queries);
        this.totalCount = +totalCount;
        return Promise.resolve();
      },
      handleCreationModalToggle() {
        this.creationModal = false;
      },
    },
  };
</script>
<style lang="scss">
  .group {
    max-width: 940px;
    margin: 20px auto 0 auto;
    position: relative;
    &__reflections {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
    }
  }
  .creation-modal {
    .modal__container {
      .modal__container__warning {
        margin-top: 15px;
        max-width: 400px;
      }
    }
  }
</style>
