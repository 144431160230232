/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logos/reflectus-logo': {
    width: 37.466,
    height: 51.339,
    viewBox: '0 0 37.466 51.339',
    data: '<g data-name="Group 970"><path pid="0" data-name="Path 803" d="M36.412 38.282l-4.449-8.25a15.5 15.5 0 0 0 2.26-2.552 17.658 17.658 0 0 0 2.97-10.43c0-6.9-2.991-12.188-8.444-14.914a5.861 5.861 0 0 0-.45-.25A19.347 19.347 0 0 0 19.875 0a16.025 16.025 0 0 0-2.734.207l-.065.014A11.8 11.8 0 0 0 13.553 1.4 9.794 9.794 0 0 0 9.075.383a10.306 10.306 0 0 0-5.559 1.512A7.449 7.449 0 0 0 0 8.203v35.012a7.414 7.414 0 0 0 3.894 6.517 11.092 11.092 0 0 0 10.628-.043 7.413 7.413 0 0 0 3.792-6.339l1.675 3.256a8.239 8.239 0 0 0 7.451 4.732 10.392 10.392 0 0 0 6.61-2.484 8.756 8.756 0 0 0 3.416-6.83 6.931 6.931 0 0 0-1.054-3.742z" _fill="#fff"/><path pid="1" data-name="Path 804" d="M9.074 34.474a6.644 6.644 0 0 1-3.453-.876v9.616a1.831 1.831 0 0 0 1.058 1.636 5.513 5.513 0 0 0 4.982 0 1.835 1.835 0 0 0 1.033-1.636v-9.716a6.923 6.923 0 0 1-3.62.976zm22.5 6.675l-7.13-13.219a9.527 9.527 0 0 0 5.2-3.7 12.144 12.144 0 0 0 1.933-7.179q0-7.943-6.287-10.335a5.47 5.47 0 0 1 .4 2.065 5.733 5.733 0 0 1-1.346 3.742 5.387 5.387 0 0 1-1.115 1.008 6.077 6.077 0 0 1 1.281 4.172q0 5.762-5.824 5.766h-3.594v5.552h2.181l7.724 15.011a2.684 2.684 0 0 0 2.454 1.685 4.672 4.672 0 0 0 2.99-1.165 3.349 3.349 0 0 0 1.413-2.528 1.383 1.383 0 0 0-.284-.875z" _fill="#3c9"/><path pid="2" data-name="Path 805" d="M23.3 8.781a3.351 3.351 0 0 1-.793 2.214 2.334 2.334 0 0 1-1.793.942h-1.693a5.668 5.668 0 0 0-4.428 2.247 8.787 8.787 0 0 0-1.892 5.857v8.98h-.008v.892a1.978 1.978 0 0 1-1.091 1.462 4.525 4.525 0 0 1-2.528.719 4.266 4.266 0 0 1-2.47-.686 1.928 1.928 0 0 1-.983-1.569V8.206a1.837 1.837 0 0 1 .958-1.594 4.685 4.685 0 0 1 2.495-.6 4.007 4.007 0 0 1 2.28.612 1.878 1.878 0 0 1 .909 1.6v2.28a8.876 8.876 0 0 1 2.743-3.379 6.444 6.444 0 0 1 3.907-1.437h.107a21 21 0 0 1 2.462.157 2.748 2.748 0 0 1 1.024.777 3.332 3.332 0 0 1 .794 2.159z" _fill="#f36"/></g>'
  }
})
