/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 13.816,
    height: 22.58,
    viewBox: '0 0 13.816 22.58',
    data: '<g data-name="Group 3791"><g data-name="Group 3790"><g data-name="Group 11" transform="translate(-529.669 -571.345)"><rect pid="0" data-name="Rectangle 5" width="13.066" height="21.83" rx=".996" transform="translate(530.044 571.719)" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width=".75"/><path pid="1" data-name="Line 5" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width=".75" d="M530.044 574.906h13.066"/><path pid="2" data-name="Line 6" _fill="none" _stroke="#fff" stroke-linejoin="round" stroke-width=".75" d="M530.044 589.446h13.066"/><circle pid="3" data-name="Ellipse 4" cx=".582" cy=".582" r=".582" transform="translate(536.012 590.922)" _fill="#fff"/></g></g></g>'
  }
})
