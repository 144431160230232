/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy_template': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g data-name="Group 3479"><g data-name="Group 3460" transform="translate(-619.5 -48.5)" _fill="none" _stroke="#919396"><circle pid="0" data-name="Ellipse 1599" cx="13.5" cy="13.5" r="13.5" transform="translate(620 49)"/><path pid="1" data-name="Rectangle 5526" stroke-linejoin="round" d="M630 59h9v11h-9z"/><path pid="2" data-name="Path 3988" d="M627 67V56h9" stroke-linecap="round" stroke-linejoin="round"/></g></g>'
  }
})
