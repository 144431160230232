<template>
  <div
    v-if="questions.length"
    :class="[
      'question',
      {
        'question--expanded':
          (questionExpanded && currentView === 'list') || currentView === 'tabs',
        'question-has-errors': this.error,
      },
    ]"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="question-draggable-area" :class="{ active: editStatus === 'edit' }">
      <div class="question__header" v-if="method">
        <div class="question__top question-item">
          <!-- <span class="question-item__number">{{ currentView === 'list' ? number + 1 : null }}</span> -->
          <svgicon
            v-if="currentView === 'list' && editStatus === 'edit'"
            class="question-item__drag-handle"
            :class="{ active: hover }"
            name="grip"
            :original="true"
            width="8px"
            height="13px"
          ></svgicon>
          <svgicon
            v-if="currentView === 'tabs'"
            class="question-item__icon"
            :name="methodIconName"
            :original="true"
            width="2em"
          ></svgicon>
          <svgicon
            v-if="currentView === 'list'"
            class="question-item__icon"
            :name="methodIconName"
            :original="true"
            width="2em"
            @click="switchQuestion"
          ></svgicon>
          <div class="question-item__question" v-if="currentView === 'list'">
            <header-h2 class="question-item__title list-title">
              {{ currentView === 'list' ? `${number + 1}. ` : null
              }}{{ question.title ? question.title : '' }}
            </header-h2>
            <header-h4 class="question-item__placeholder list-placeholder">
              {{ method.title.toLowerCase() }}
            </header-h4>
          </div>
          <div class="question-item__question" v-else>
            <header-h2 class="question-item__title">{{ method.title.toLowerCase() }}</header-h2>
            <header-h4 class="question-item__placeholder">
              {{ question.title ? question.title : 'Placeholder question' }}
            </header-h4>
          </div>
        </div>
        <p
          class="question__item__explanation"
          v-if="question.methodId === 5 && currentView !== 'list'"
        >
          {{ $t('messages.incomplete_sentence_note') }}
        </p>
      </div>
      <question-actions
        v-if="(questionExpanded || currentView === 'tabs') && editStatus === 'edit'"
        :number="number"
        :currentView="currentView"
        @handleDisplayExamplesModalAction="handleDisplayExamplesModal"
      ></question-actions>
    </div>
    <div class="question__question" v-if="questionExpanded || currentView === 'tabs'">
      <div
        class="question__select"
        v-if="
          method &&
            method.availableAnswerTemplates.length > 1 &&
            question.methodId !== 4 &&
            question.methodId !== 16 &&
            question.methodId !== 8
        "
      >
        <span class="question__select-title">{{ $t('questionnaire.answer_type') }}</span>
        <!-- <multi-select-question
          v-if="question.methodId === 4 || question.methodId === 16"
          :options="method.availableAnswerTemplates"
          :id="`availableAnswerTemplates-${number}`"
          :key="`availableAnswerTemplates-${number}`"
          :width="140"
          :placeholder='$t("actions.select_action")'
          @selectedValue="selectedAnswerTemplate"
          :selected="answerTemplateSelected"
          :disabled="editStatus !== 'edit'"
        ></multi-select-question> -->
        <multi-select
          :options="method.availableAnswerTemplates"
          :id="`availableAnswerTemplates-${number}`"
          :key="`availableAnswerTemplates-${number}`"
          :width="140"
          :placeholder="$t('actions.select_action')"
          @selectedValue="selectedAnswerTemplate"
          :selected="question.answerTemplate"
          :disabled="editStatus !== 'edit'"
        ></multi-select>
      </div>
      <!-- HIDE FOR NOW/ONLY ONE AVAILABLE -->
      <!-- <div
        class="question__select--select"
        v-if="question.methodId === 4 && question.answerTemplate === 'select'"
      >
        <multi-select-question
          v-if="selectedQuestion.length > 0"
          :options="answerTypes"
          :id="`methodAnswerTypes-${number}`"
          :key="`methodAnswerTypes-${number}`"
          :width="140"
          :placeholder='$t("actions.select_action")'
          @selectedValue="selectedValue"
          :selected="selectedQuestion"
          :disabled="editStatus !== 'edit'"
        ></multi-select-question>
      </div> -->
    </div>
    <div
      class="question__select"
      v-if="
        question.answerOptions &&
          answerTypes.length > 1 &&
          question.methodId !== 4 &&
          question.methodId !== 8
      "
    >
      <span class="question__select-title">{{ $t('questionnaire.answer_type') }}</span>
      <multi-select-yes-no
        v-if="question.methodId === 1"
        :options="answerTypes"
        :id="`methodAnswerTypes-${number}`"
        :key="`methodAnswerTypes-${number}`"
        :width="140"
        :placeholder="$t('actions.select_action')"
        @selectedValue="selectedValue"
        :selected="selectedYesNo"
        :disabled="editStatus !== 'edit'"
      ></multi-select-yes-no>
      <multi-select
        v-else
        :options="answerTypes"
        :id="`methodAnswerTypes-${number}`"
        :key="`methodAnswerTypes-${number}`"
        :width="140"
        :placeholder="$t('actions.select_action')"
        @selectedValue="selectedValue"
        :selected="question.answerOptions.type"
        :disabled="editStatus !== 'edit'"
      ></multi-select>
    </div>
    <div class="question__main" v-if="question">
      <div class="question__border"></div>
      <div class="question__question">
        <span
          v-if="questionError && questionError !== $t('questionnaire.question_type_placeholder')"
          class="question__question__error"
        >
          {{ questionError }}
        </span>
        <auto-resize-input>
          <textarea
            v-if="editStatus === 'edit'"
            :class="[
              'question__question-input',
              { 'question__question-input--error': question.error || error },
            ]"
            rows="1"
            ref="question__question_input"
            :placeholder="question.error || $t('questionnaire.question_type_placeholder')"
            v-model="question.title"
            @input="handleQuestionTitle"
            maxlength="191"
          ></textarea>
          <p v-else class="question__question-input">{{ question.title }}</p>
        </auto-resize-input>
        <div class="question__question__parts">
          <div
            class="question__question__parts__part"
            v-for="(part, index) in parts"
            :key="`part--${index}${part.error ? `error--true` : ''}`"
          >
            <auto-resize-input>
              <textarea
                v-if="editStatus === 'edit'"
                :class="[
                  'question__question-input',
                  'question__question-input--part',
                  { 'question__question-input--selected': index === selectedPartIndex },
                  { 'question__question-input--error': part.error },
                ]"
                rows="1"
                ref="question__question_part"
                :placeholder="part.error || placeholderValue"
                v-model="part.title"
                maxlength="250"
                @click="handlePartSelect(index)"
                @input="handlePartInput($event, index)"
              ></textarea>
              <p v-else class="question__question-input question__question-input--part">
                {{ part.title }}
              </p>
            </auto-resize-input>
            <svgicon
              v-if="parts.length > 1 && part.title && edit && method.allowUnlimitedPartCount"
              class="question__question__parts__part__remove"
              name="multiply"
              :original="true"
              @click="handleRemovePart(index)"
            ></svgicon>
            <p
              v-if="
                part.error === $t('warnings.incomplete_sentence_at_symbol_warning_message') &&
                  part.title.length > 0
              "
              style="font-size: 14px; color: #bc0000"
            >
              {{ part.error }}
            </p>
            <p v-if="part.title.length >= 250" style="font-size: 14px; color: #bc0000">
              {{ $t('warnings.question_part_length_warning') }}
            </p>
          </div>
        </div>
      </div>
      <div class="question__side-border"></div>
      <div class="question__inputs">
        <span
          class="question__inputs-header"
          v-if="question.methodId === 5 || question.methodId === 13 || question.methodId === 6"
        >
          {{ $t('questionnaire.max_symbol_count_warning') }}
        </span>
        <span class="uppercase-title" v-else-if="question.answerTemplate === 'slider'">
          {{ $t('questionnaire.scale_question_range') }}
        </span>
        <span class="uppercase-title" v-else-if="question.answerTemplate === 'pie'">
          {{ $t('questionnaire.pie_question_parts') }}
        </span>
        <span class="uppercase-title" v-else-if="question.answerTemplate === 'number'">
          {{ $t('questionnaire.answer_interval') }}
        </span>
        <span class="uppercase-title" v-else-if="question.answerTemplate === 'text'">
          {{ $t('questionnaire.max_symbol_count_warning') }}
        </span>
        <span class="uppercase-title" v-else-if="question.answerTemplate === 'select'">
          {{ $t('questionnaire.answer_options') }}
        </span>
        <span class="question__inputs-header" v-else>{{ $t('questionnaire.answer_options') }}</span>
        <div class="template">
          <!-- draggable fix for not updating template component -->
          <!-- <keep-alive> -->
          <component
            :is="component"
            :type="questionAnswerType"
            :key="
              `methodTemplate-${number}${questionErrorCount ? `errors--${questionErrorCount}` : ''}`
            "
            :number="number"
            :question="question"
            :method="method"
            :edit="edit"
            :part="selectedPart"
            :partIndex="selectedPartIndex"
            :questionExpanded="questionExpanded"
            :questionParts="question && question.parts ? question.parts[0] : null"
          ></component>
          <!-- </keep-alive> -->
        </div>
      </div>
    </div>
    <svgicon
      v-if="currentView === 'list'"
      :class="['question__arrow', { 'question__arrow--expanded': questionExpanded }]"
      name="green-arrow"
      :original="true"
      height="16px"
      width="16px"
      color="#3B328B"
      :fill="false"
      @click="expandAnswer"
    ></svgicon>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import AutoResizeInput from '@/helpers/autoResizeInput';

  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
  import QuestionActions from '@/components/reflections/question/QuestionActions.vue';
  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import MultiSelectYesNo from '@/components/elements/select/MultiSelectYesNo.vue';
  import MultiSelectQuestion from '@/components/elements/select/MultiSelectQuestion.vue';
  import TemplateYesNo from '@/components/reflections/templates/TemplateYesNo.vue';
  import TemplateThermometer from '@/components/reflections/templates/TemplateThermometer.vue';
  import TemplateTrafficLights from '@/components/reflections/templates/TemplateTrafficLights.vue';
  import TemplateSmileys from '@/components/reflections/templates/TemplateSmileys.vue';
  import TemplateIncompleteSentence from '@/components/reflections/templates/TemplateIncompleteSentence.vue';
  import TemplateIndicator from '@/components/reflections/templates/TemplateIndicator.vue';
  import Template3k from '@/components/reflections/templates/Template3k.vue';
  import Template321 from '@/components/reflections/templates/Template321.vue';
  import TemplatePie from '@/components/reflections/templates/TemplatePie.vue';
  import TemplateMatrix from '@/components/reflections/templates/TemplateMatrix.vue';
  import TemplateQuestion from '@/components/reflections/templates/TemplateQuestion.vue';

  export default {
    name: 'CreateQuestion',
    components: {
      HeaderH2,
      HeaderH4,
      QuestionActions,
      MultiSelect,
      MultiSelectYesNo,
      MultiSelectQuestion,
      TemplateYesNo,
      TemplateThermometer,
      TemplateTrafficLights,
      TemplateSmileys,
      TemplateIncompleteSentence,
      TemplateIndicator,
      Template3k,
      Template321,
      TemplatePie,
      TemplateMatrix,
      TemplateQuestion,
      AutoResizeInput,
    },
    props: [
      'number',
      'expanded',
      'propsQuestion',
      'error',
      'questionWithErrors',
      'questionWithPartErrors',
      'questionWithOptionErrors',
    ],
    data() {
      return {
        questionExpanded: this.expanded,
        colors: '#194d33',
        selectedPart: null,
        selectedPartIndex: 0,
        hover: false,
      };
    },
    created() {
      if (this.question) {
        const { title } = this.question;
        if (!title && this.method.defaultQuestion && !this.question.error) {
          this.question.title = this.method.defaultQuestion;
        }
        if (
          this.question.methodId === 13 ||
          this.question.methodId === 6 ||
          this.question.methodId === 12 ||
          this.question.methodId === 5
        ) {
          if (!this.question.parts || !this.question.parts.length) {
            const { parts } = this.method;
            let answerOptions;
            if (this.method.answerOptions.defaults.incomplete_sentence) {
              answerOptions = { ...this.method.answerOptions.defaults.incomplete_sentence };
            } else answerOptions = { ...this.method.answerOptions.defaults.text };

            this.question.parts = parts.map((part) => ({
              title: part.defaultQuestion,
              position: part.position,
              answerCount: part.answerCount,
              answerOptions,
            }));
          }
        }
      }
    },
    computed: {
      ...mapGetters('questionnaire', [
        'currentView',
        'questions',
        'questionnaireErrors',
        'questionErrorCount',
      ]),
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('reflections', ['editStatus']),
      method() {
        if (this.question) {
          const method = this.questionTypeList.find((item) => item.id === this.question.methodId);
          return method;
        }
        return null;
      },
      methodIconName() {
        if (this.method.id === 4) {
          return `methods/question-text`;
        }
        if (this.method.id === 16) {
          return `methods/question-select`;
        }
        return `methods/${this.method.template}`;
      },
      question() {
        return this.questions[this.number];
      },
      questionError() {
        return this.questions[this.number].error || null;
      },
      parts() {
        return this.question.parts ? this.question.parts : false;
      },
      answerTypes() {
        let answerTypes = [];
        if (this.method && this.method.answerOptions && this.method.answerOptions.available) {
          const types = this.method.answerOptions.available.select
            ? this.method.answerOptions.available.select.type
            : this.method.answerOptions.available.slider.numberType;
          if (this.question.methodId === 1) {
            answerTypes = [
              { value: 'text', name: this.$t('methods.yes_no_method_text') },
              { value: 'img', name: this.$t('methods.yes_no_method_picture') },
              { value: 'color', name: this.$t('methods.yes_no_method_colour') },
            ];
          } else {
            answerTypes = Object.keys(types);
          }
        } else if (this.question.methodId === 4) {
          // @TEMP_ONE temporary removal of question template answer templates
          // answerTypes = ['color', 'img', 'text'];

          answerTypes = [{ value: 'text', name: this.$t('methods.question_method_text') }];
        } else if (this.question.methodId === 16) {
          answerTypes = [{ value: 'select', name: this.$t('methods.question_method_select') }];
        } else answerTypes = false;
        return answerTypes;
      },
      selectedYesNo() {
        return this.answerTypes.filter((obj) => obj.value === this.question.answerOptions.type);
      },
      selectedQuestion() {
        return this.answerTypes.filter((obj) => obj.value === this.question.answerOptions.type);
      },
      questionAnswerType() {
        return this.question.answerOptions.type ? this.question.answerOptions.type : null;
      },
      answerTemplateSelected() {
        return this.method.availableAnswerTemplates.filter(
          (obj) => obj.value === this.question.answerTemplate
        );
      },
      component() {
        let component;
        const { methodId } = this.question;

        switch (methodId) {
          case 1:
            component = TemplateYesNo;
            break;
          case 2:
            component = TemplateTrafficLights;
            break;
          case 3:
            component = TemplateSmileys;
            break;
          case 4:
            component = TemplateQuestion;
            break;
          case 5:
            component = TemplateIncompleteSentence;
            break;
          case 6:
            component = Template321;
            break;
          case 7:
            component = TemplateThermometer;
            break;
          case 8:
            component = TemplateIndicator;
            break;
          case 11:
            component = TemplatePie;
            break;
          case 12:
            component = TemplateMatrix;
            break;
          case 13:
            component = Template3k;
            break;
          case 16:
            component = TemplateQuestion;
            break;

          default:
            break;
        }
        return component;
      },
      edit() {
        return this.editStatus === 'edit';
      },
      placeholderValue() {
        return this.method.id === 5
          ? this.$t('incomplete_sentence_placeholder')
          : this.$t('insert_value_placeholder');
      },
    },
    watch: {
      expanded(newVal) {
        this.questionExpanded = newVal;
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'setCurrentTab',
        'setCurrentQuestion',
        'setOptionType',
        'setAnswerTemplate',
        'setQuestionProperty',
        'setQuestionError',
      ]),
      expandAnswer() {
        this.questionExpanded = !this.questionExpanded;
        if (this.questionExpanded) {
          this.switchQuestion();
        }
      },
      switchQuestion() {
        this.setCurrentTab(this.number);
        this.setCurrentQuestion(this.question);
      },
      selectedValue(value) {
        const type = value.value;
        const index = this.number;
        const payload = { index, type };
        this.setOptionType(payload);
      },
      selectedAnswerTemplate(value) {
        const property = value.value;
        const index = this.number;
        const type = 'answerTemplate';
        const payload = { index, type, property };
        this.setQuestionProperty(payload);
      },
      handlePartSelect(index) {
        this.selectedPart = this.parts[index];
        this.selectedPartIndex = index;
      },
      handlePartInput(event, i) {
        const { allowUnlimitedPartCount } = this.method;
        const { value } = event.target;
        if (this.parts[i].title && this.parts[i].title.length && !this.parts[i + 1]) {
          if (allowUnlimitedPartCount) {
            if (this.question.answerOptions) {
              const options = JSON.parse(JSON.stringify(this.question.answerOptions));
              this.parts.push({ title: null, answerOptions: { ...options } });
            } else {
              const options = JSON.parse(JSON.stringify(this.question.answerOptions));
              this.parts.push({ title: null, answerOptions: { ...options } });
            }
          }
        }
        if (!value && this.parts[i + 1] && allowUnlimitedPartCount) {
          this.parts.splice(i + 1, 1);
        }
      },
      handleRemovePart(j) {
        this.parts.splice(j, 1);
        this.i = 0;
      },
      handleQuestionTitle(event) {
        const { value } = event.target;
        if (!value)
          this.setQuestionError({
            index: this.number,
            error: this.$t('questionnaire.question_type_placeholder'),
          });
        else {
          this.setQuestionError({ index: this.number, error: null });
          this.setQuestionProperty({ type: 'title', index: this.number, property: value });
        }
      },
      handleDisplayExamplesModal(methodId) {
        this.$emit('handleDisplayExamplesModalAction', methodId);
      },
    },
  };
</script>

<style lang="scss">
  .question {
    background-color: #fff;

    width: 100%;
    max-width: 840px;
    min-height: 73px;
    padding: 20px;
    padding-left: 15px;
    margin-top: 3px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .question-draggable-area {
      width: 100%;
      &.active {
        cursor: grab;
      }
    }

    &__header {
      align-self: flex-start;
      .question-item {
        display: flex;
        align-items: center;
        position: relative;

        &__title {
          color: $violet-2;
          text-transform: capitalize;
          &.list-title {
            text-transform: initial;
            color: $grey-5;
          }
        }

        &__drag-handle {
          display: none;
          margin-right: 20px;
          &.active {
            display: block;
          }
        }

        &__icon {
          min-width: 2em;
        }

        &__number {
          position: absolute;
          left: -18px;
          font-family: Nunito-SemiBold;
          color: $violet-2;
          font-size: 12px;
        }

        &__question {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          width: 400px;
          word-break: break-word;
          .question-item__placeholder {
            word-break: break-all;
            &.list-placeholder {
              text-transform: capitalize;
              color: $violet-2;
            }
          }
        }
      }
    }

    &__item {
      &__explanation {
        font-family: Nunito-Regular;
        font-size: 12px;
        margin-top: 14px;
        color: $grey-5;
      }
    }

    &__arrow {
      transform: rotate(-90deg);
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    &__arrow--expanded {
      transform: rotate(90deg);
    }

    &__select {
      align-items: center;
      display: none;
      margin-left: 45px;

      &--select {
        position: absolute;
        right: -145px;
        top: 0;
      }
    }

    &__select-title,
    &__inputs-header {
      margin-right: 12px;
      font-family: Nunito-Bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $grey-3;
    }

    &__main {
      margin-top: 17px;
      width: 100%;
      background-color: #fff;
      padding: 20px 0;
      display: none;
      position: relative;

      .question {
        &__question {
          padding-right: 20px;
          flex: 1;

          &-input {
            border: none;
            display: none;
            width: 100%;
            font-family: Roboto-Medium;
            font-size: 15px;
            color: $grey-5;
            padding: 8px 10px 8px 0;
            outline: none;
            max-height: 100%;

            overflow-y: hidden;
            min-height: 30px;

            &--part {
              font-family: Roboto-Regular;
              font-size: 14px;
            }

            &--selected {
              background-color: $grey-1;
              width: 113%;
              margin-left: -15px;
              padding-left: 30px;
              padding-right: 34px;
              border: none !important;

              &:hover,
              &:active,
              &:focus {
                border: none !important;
              }
            }

            &::placeholder {
              color: $grey-4;
            }

            &:hover,
            &:active,
            &:focus {
              border-bottom: 1px solid $violet-6;

              &::placeholder {
                color: $grey-4;
              }
            }

            &--error {
              border-bottom: 1px solid $red-4;

              &::placeholder {
                font-size: 12px;
                font-family: Roboto-Regular;
                color: $red-4;
              }
            }
          }

          &__error {
            position: absolute;
            color: $red-4;
            font-size: 14px;
            top: -12px;
          }

          &__parts {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            &__part {
              position: relative;

              &__remove {
                position: absolute;
                right: 0;
                top: 16px;
                height: 8px;
                width: 8px;
                transition: all 0.3s;

                &:hover {
                  transform: translateY(-3px) scale(1.25);
                  cursor: pointer;
                }
              }

              &:not(:first-of-type) {
                margin-top: 14px;
              }

              &:before {
                content: '';
                position: absolute;
                left: -14px;
                top: 50%;
                height: 1.5px;
                width: 10px;
                background-color: $grey-5;
                font-family: Roboto-Regular;
                font-size: 14px;
              }
            }
          }
        }

        &__inputs {
          flex: 1;
          padding-left: 20px;
        }

        &__error {
          position: absolute;
          color: $red-4;
          font-size: 14px;
          top: -12px;
        }
      }
    }

    &__inputs {
      position: relative;
      .template {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        &__input-field {
          width: 100%;
          position: relative;

          &.numberTypeWrapper {
            width: 271px;
          }

          &--column {
            display: flex;
            flex-direction: column;
          }

          &--small {
            display: flex;
            align-items: center;
          }

          &:not(:first-of-type) {
            margin-top: 10px;
          }

          &__field {
            span {
              margin-right: 5px;
            }

            &:first-of-type {
              margin-right: 10px;
            }
          }

          &__header {
            font-family: Roboto-Regular;
            font-size: 12px;
            color: $violet-6;
            margin-bottom: 6px;
          }

          &__delete {
            position: absolute;
            height: 8px;
            width: 8px;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
          }

          &__error {
            position: absolute;
            font-size: 14px;
            color: $red-4;
            top: 32px;
            left: 0;
            z-index: 1;
          }
        }
        &__input-text {
          border: none;
          width: 100%;
          font-family: Roboto-Medium;
          font-size: 15px;
          color: $grey-5;
          padding: 0px;
          outline: none;
          height: 30px;
          max-height: 100%;

          overflow-y: hidden;

          &::placeholder {
            color: $grey-4;
          }

          &:hover,
          &:active,
          &:focus {
            border-bottom: 1px solid $violet-6;

            &::placeholder {
              color: $grey-4;
            }
          }

          &:disabled {
            background-color: transparent;
          }

          &--light {
            font-family: Roboto-Regular;
          }

          &--error {
            border-bottom: 1px solid $red-4;

            &::placeholder {
              color: $red-4;
              font-family: Roboto-Regular;
              font-size: 14px;
            }
          }

          &__remove {
            position: absolute;
            height: 8px;
            width: 8px;
            right: 0;
            top: 12px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
      &__error {
        font-size: 14px;
        color: $red-4;
        position: absolute;
        top: -17px;
        z-index: 1;
      }
    }

    &__border {
      height: 3px;
      width: calc(100% + 50px);
      left: -30px;
      top: 0;
      background-color: $grey-6;
      position: absolute;
    }

    &__side-border {
      height: calc(100% + 20px);
      width: 3px;
      position: absolute;
      top: 0;
      left: 50%;
      background-color: $grey-6;
    }

    &__question {
      position: relative;
    }

    .question-component {
      align-self: flex-start;
      width: 100%;
      background-color: #fff;

      .answer-component {
        margin-top: 30px;
      }
    }

    &--expanded {
      .question__select {
        display: flex;
      }

      .question-item__placeholder {
        display: none;
      }

      .question__main {
        display: flex;
        .question__question {
          .question__question-input {
            display: flex;
            word-break: break-word;
          }
        }
        .question__inputs {
          display: flex;
          flex-direction: column;
          .question__inputs-header {
            text-align: center;
            margin: 0;
          }
        }
      }
    }
  }
</style>
