/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-upset': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.43" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="10.11" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" d="M21.24 7.31l-.42-.12c-.3-.08-.58-.16-.86-.26a7.6 7.6 0 0 1-1.13-.48 6.69 6.69 0 0 1-1-.66 10.06 10.06 0 0 1-1-.89.06.06 0 0 0-.1 0 2.54 2.54 0 0 0 .52 1.36 3.33 3.33 0 0 0 1.13 1 3.52 3.52 0 0 0 1.36.39h.05a2.94 2.94 0 0 0 1.42-.25.06.06 0 0 0 0-.11zM5.47 7.31l.42-.12c.3-.08.58-.16.86-.26a7.6 7.6 0 0 0 1.13-.48 6.69 6.69 0 0 0 1-.66 10.06 10.06 0 0 0 1-.89.06.06 0 0 1 .12.1 2.54 2.54 0 0 1-.52 1.36 3.33 3.33 0 0 1-1.13 1 3.52 3.52 0 0 1-1.36.39h-.11a2.94 2.94 0 0 1-1.42-.25.06.06 0 0 1 0-.11z" _fill="#3f3e40"/><path pid="6" d="M8.3 19.1c-.14 0-.22-.16-.22-.29A4.07 4.07 0 0 1 8.56 17a5 5 0 0 1 1.2-1.49 5.72 5.72 0 0 1 3.59-1.3A5.44 5.44 0 0 1 17 15.52a4.56 4.56 0 0 1 1.65 3.29c0 .14-.09.31-.24.29s-.23-.19-.32-.27l-.44-.34a8.93 8.93 0 0 0-.95-.58 7.09 7.09 0 0 0-3.96-.91 6.78 6.78 0 0 0-2.49.73 8.71 8.71 0 0 0-1.32.84c-.16.12-.42.44-.61.48z" _fill="#654735"/></g></g>'
  }
})
