/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'repeat': {
    width: 16.664,
    height: 13.954,
    viewBox: '0 0 16.664 13.954',
    data: '<g data-name="Group 1955"><g data-name="Group 1569"><g data-name="Group 1568" _fill="none" _stroke="#505e67" stroke-linecap="round"><path pid="0" data-name="Path 1010" d="M5.685 6.321L3.748 9.57.499 7.633" stroke-linejoin="round"/><path pid="1" data-name="Path 1011" d="M3.718 9.5a6.484 6.484 0 1 1 5.968 3.956" stroke-miterlimit="10"/></g></g></g>'
  }
})
