/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/traffic_light': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 3831"><g data-name="Group 1407" transform="translate(-61.896 -176.261)"><circle pid="0" data-name="Ellipse 267" cx="16.559" cy="16.559" r="16.559" transform="rotate(-.5 20261.844 -7004.325)" _fill="#6666ca"/><g data-name="Group 1406" _fill="#fff"><path pid="1" data-name="Path 935" d="M83 184.38h-8.85a.95.95 0 0 0-.95.95v17.48a.943.943 0 0 0 .95.95H83a.943.943 0 0 0 .95-.95v-17.48a.95.95 0 0 0-.95-.95zm-4.43 18.12a2.545 2.545 0 1 1 2.55-2.55 2.546 2.546 0 0 1-2.55 2.55zm0-5.73a2.545 2.545 0 1 1 2.55-2.54 2.544 2.544 0 0 1-2.55 2.54zm0-5.72a2.545 2.545 0 1 1 2.55-2.54 2.544 2.544 0 0 1-2.55 2.54z"/><g data-name="Group 1404"><path pid="2" data-name="Path 936" d="M70.027 185.96a.634.634 0 0 0-.63.63 4.426 4.426 0 0 0 2.35 3.93v-4.56z"/><path pid="3" data-name="Path 937" d="M70.027 191.68a.636.636 0 0 0-.63.64 4.426 4.426 0 0 0 2.35 3.92v-4.56z"/><path pid="4" data-name="Path 938" d="M70.027 197.41a.627.627 0 0 0-.63.63 4.423 4.423 0 0 0 .7 2.4 4.489 4.489 0 0 0 1.65 1.53v-4.56z"/></g><g data-name="Group 1405"><path pid="5" data-name="Path 939" d="M87.123 185.96a.634.634 0 0 1 .63.63 4.426 4.426 0 0 1-2.35 3.93v-4.56z"/><path pid="6" data-name="Path 940" d="M87.123 191.68a.636.636 0 0 1 .63.64 4.426 4.426 0 0 1-2.35 3.92v-4.56z"/><path pid="7" data-name="Path 941" d="M87.123 197.41a.627.627 0 0 1 .63.63 4.423 4.423 0 0 1-.7 2.4 4.489 4.489 0 0 1-1.65 1.53v-4.56z"/></g><path pid="8" data-name="Path 942" d="M81.81 181.766a.941.941 0 0 0-.95-.94h-4.57a.941.941 0 0 0-.95.94v1.27h6.47z"/></g></g></g>'
  }
})
