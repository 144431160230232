<template>
  <div class="reflection__details">
    <div class="reflection__details__field reflection__details__field--title">
      <div class="reflection__details__field__input">
        <h4 class="reflection__details__field__input__title uppercase-title">
        {{ $t("questionnaire.reflection_title") }}
        </h4>
        <auto-resize-input>
          <textarea
            v-if="editStatus === 'edit'"
            :class="[
              'reflection__textarea',
              { 'reflection__textarea--error': questionnaireErrors.title },
            ]"
            v-model="questionnaire.title"
            @input="handleInput($event, 'title')"
            :placeholder='questionnaireErrors.title || $t("insert_value_placeholder")'
            ref="questionnaire__title"
            rows="1"
          ></textarea>
          <p v-else class="reflection__textarea">{{ questionnaire.title }}</p>
        </auto-resize-input>
      </div>
      <label class="reflection__details__field__icon" @click="toggleImageModal">
        <img
          class="reflection__details__field__icon__image"
          v-if="image"
          :src="image"
          alt="photo"
        />
        <img
          class="reflection__details__field__icon__image"
          v-else-if="questionnaire.icon"
          :src="questionnaire.icon"
          alt="selected"
        />
        <img
          :class="['reflection__details__field__icon__image', {'reflection__details__field__icon__image--error' : questionnaireErrors.icon}]"
          v-else
          src="../../assets/photo.png"
          alt="photo"
        />
        <svgicon
          v-if="editStatus === 'edit'"
          class="reflection__details__field__icon__icon"
          :original="true"
          name="pen"
          height="1em"
        ></svgicon>
      </label>
      <span v-if="questionnaireErrors.icon" class="reflection__details__field__error">{{questionnaireErrors.icon}}</span>
    </div>
    <div class="reflection__details__field reflection__details__field--description">
      <div class="reflection__details__field__input">
        <h4 class="reflection__details__field__input__title uppercase-title">
        {{ $t("questionnaire.reflection_title_for_member") }}
        </h4>
        <auto-resize-input>
          <textarea
            v-if="editStatus === 'edit'"
            :class="[
              'reflection__textarea',
              'reflection__textarea--large',
              { 'reflection__textarea--error': questionnaireErrors.description },
            ]"
            :placeholder='questionnaireErrors.description || $t("insert_value_placeholder")'
            ref="questionnaire__description"
            rows="1"
            v-model="questionnaire.description"
            @input="handleInput($event, 'description')"
          ></textarea>
          <p v-else class="reflection__textarea">{{ questionnaire.description }}</p>
        </auto-resize-input>
      </div>
    </div>
    <question-image-picker
      :type="'main'"
      v-show="showImageModal"
      @click="toggleImageModal"
      @closeImageModal="toggleImageModal"
      @onFileChange="onFileChange"
      @onImageSelect="onImageSelect"
      :key="'QuestionnaireHeader'"
      :label="'QuestionnaireHeader'"
    ></question-image-picker>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import AutoResizeInput from '@/helpers/autoResizeInput';

import QuestionImagePicker from '@/components/reflections/question/QuestionImagePicker.vue';

export default {
  name: 'CreateReflectionDetails',
  components: {
    QuestionImagePicker,
    AutoResizeInput,
  },
  props: ['type'],
  data() {
    return {
      showImageModal: false,
      image: null,
    };
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire', 'questionnaireErrors']),
    ...mapGetters('reflections', ['editStatus']),
  },
  methods: {
    ...mapActions('questionnaire', [
      'setQuestionnaireIcon',
      'uploadIcon',
      'setQuestionnaireErrors',
    ]),
    toggleImageModal() {
      if (this.editStatus !== 'edit') return;
      this.showImageModal = !this.showImageModal;
    },
    onFileChange(image) {
      const { url, id } = image;
      if (url) {
        this.image = image.url;
        this.setQuestionnaireIcon(image.url);
        this.questionnaire.icon = image.url;
        this.setQuestionnaireErrors({ type: 'icon', error: null });
        if (id) this.questionnaire.iconFileId = parseInt(id, 10);
      }
    },
    onImageSelect() {
      const payload = {
        id: this.questionnaire.id,
        type: this.type,
      };
      if (this.questionnaire.id) this.uploadIcon(payload);
    },
    textareaResize(e, type) {
      let textarea;
      if (type === 'title') {
        textarea = this.$refs.questionnaire__title;
      } else if (type === 'description') {
        textarea = this.$refs.questionnaire__description;
      }
      textarea.style.minHeight = `${textarea.scrollHeight}px`;
      if (!textarea.value) {
        textarea.style.minHeight = '40px';
      }
    },
    handleInput(e, type) {
      const { value } = e.target;
      if (type === 'title' && !value) {
        this.setQuestionnaireErrors({ type });
      } else if (type === 'title' && value) this.setQuestionnaireErrors({ type, error: null });
      if (type === 'description' && !value) {
        this.setQuestionnaireErrors({ type });
      } else if (type === 'description' && value) { this.setQuestionnaireErrors({ type, error: null }); }
    },
  },
};
</script>
<style lang="scss" scoped>
.reflection {
  &__details {
    display: flex;
    flex-direction: column;
    width: 620px;
    margin-top: 15px;
    background-color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

    &__field {
      display: flex;
      align-items: center;
      position: relative;

      &__icon {
        margin-left: auto;
        margin-right: 20px;
        position: relative;
        width: 60px;
        height: 60px;

        &__icon {
          position: absolute;
          top: 0;
          right: -8px;
          height: 16px;
          width: 16px;
        }

        &__image {
          width: 60px;
          height: 60px;
          border-radius: 50%;

          &--error {
            border: 2px solid $red-4;
            border-radius: 50%;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      &--large {
        width: 100%;
      }

      &__input {
        position: relative;
        width: 100%;

        &__error {
          position: absolute;
          font-family: Roboto-Regular;
          font-size: 12px;
          color: $red-4;
          text-transform: none;
          top: 10px;
        }
      }

      &__error {
        position: absolute;
        color: $red-4;
        font-size: 14px;
        top: 64px;
        right: 0;
        z-index: 1;
      }
    }
  }

  &__textarea {
    padding: 10px;
    height: 40px;
    width: 420px;

    font-family: Roboto-Medium;
    font-size: 14px;
    resize: none;
    border: none;
    outline: none;
    overflow: hidden;
    &::placeholder {
      color: #899ba3;
    }
    &:active,
    &:hover,
    &:focus {
      border-bottom: 1px solid $violet-6;
      &::placeholder {
        color: #899ba3;
      }
    }
    &:disabled {
      visibility: hidden;
    }

    &--large {
      width: 100%;
      height: 40px;
    }

    &--error {
      border-bottom: 1px solid $red-4;

      &::placeholder {
          font-family: Roboto-Regular;
          font-size: 12px;
          color: $red-4;
      }
    }
  }
}
</style>
