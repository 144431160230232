<template>
  <div class="questionnaire-messages-wrapper">
    <div class="container header">
      <p>{{ $t('questionnaire_messages.messages') }}: {{ messagesCount }}</p>
      <button-4 @click.native="handleNewMessage">
        + {{ $t('questionnaire_messages.new_message') }}
      </button-4>
    </div>
    <div v-if="!isLoading">
      <transition name="ease">
        <div class="new-message-form" v-show="isNewMessage">
          <div class="message-form-header">{{ $t('questionnaire_messages.new_message') }}</div>
          <div class="message-form-wrapper">
            <textarea
              v-model="newMessageValue"
              :class="{ 'error-input': error }"
              :placeholder="$t('questionnaire_messages.message_placeholder')"
            ></textarea>
            <p class="error-message" v-if="error">{{ error }}</p>
            <div class="action-buttons-wrapper flex-global">
              <button-4 @click.native="handleSendMessage">
                {{ $t('questionnaire_messages.send_message') }}
              </button-4>
              <button-3 class="cancel-message-button" @click.native="handleCancelMessage">
                <svgicon name="bin-2" class="bin-icon" height="14px"></svgicon>
                {{ $t('actions.cancel_action') }}
              </button-3>
            </div>
          </div>
        </div>
      </transition>
      <div v-if="messages && messages.length" class="messages-wrapper">
        <transition-group tag="div" name="ease">
          <div v-for="message in messages" :key="message.id" class="container message-container">
            <p class="content date">{{ message.dateCreated }}</p>
            <p class="content message">{{ message.text }}</p>
          </div>
        </transition-group>
        <pagination
          v-if="totalPages > 1"
          :activePage="activePage"
          :totalPages="totalPages"
          @selectPage="selectPage"
        ></pagination>
      </div>
      <div v-else class="no-messages-wrapper flex-global">
        <p>{{ $t('questionnaire_messages.no_messages') }}</p>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Loading from '@/components/base/Loading.vue';
  import Pagination from '@/components/base/Pagination.vue';

  export default {
    name: 'StatMessages',
    components: {
      Button3,
      Button4,
      Loading,
      Pagination,
    },
    props: [
      'questionnaireId',
      'memberId',
      'messages',
      'defaultQueries',
      'activePage',
      'totalCount',
    ],
    data() {
      return {
        MESSAGES_TYPE_MEMBER: 1,
        MESSAGES_TYPE_GROUP: 2,
        newMessageValue: '',
        isNewMessage: false,
        isLoading: false,
        error: false,
        queries: {
          limit: 10,
          offset: null,
        },
      };
    },
    computed: {
      type() {
        return this.memberId ? this.MESSAGES_TYPE_MEMBER : this.MESSAGES_TYPE_GROUP;
      },
      messagesCount() {
        return this.totalCount || 0;
      },
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
    },
    methods: {
      ...mapActions('stats', ['createMessage', 'getAllMessages']),
      toggleIsNewMessage() {
        this.isNewMessage = !this.isNewMessage;
      },
      handleNewMessage() {
        this.toggleIsNewMessage();
      },
      async handleSendMessage() {
        if (!this.newMessageValue) {
          this.error = this.$t('warnings.field_is_required_warning');
          return;
        }
        this.error = false;
        this.isLoading = true;
        const payload = {
          text: this.newMessageValue,
          questionnaireId: this.questionnaireId,
          ...(this.type === this.MESSAGES_TYPE_MEMBER && { clientId: this.memberId }),
        };
        const { success } = await this.createMessage(payload);
        if (success) {
          this.handleMessagesRefresh();
          this.toggleIsNewMessage();
          setTimeout(() => {
            this.newMessageValue = '';
            this.isLoading = false;
          }, 2000);
        }
      },
      handleCancelMessage() {
        this.newMessageValue = '';
        this.error = false;
        this.toggleIsNewMessage();
      },
      async handleMessagesRefresh() {
        const payload = {
          questionnaireId: this.questionnaireId,
          ...(this.type === this.MESSAGES_TYPE_MEMBER && { clientId: this.memberId }),
          ...this.defaultQueries,
        };
        const { totalCount } = await this.getAllMessages(payload);
        this.$emit('updateTotalCount', totalCount);
        this.$emit('updateActivePage', 1);
      },
      selectPage(page) {
        this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
        this.$emit('updateActivePage', page);
        this.getAllMessages({
          questionnaireId: this.questionnaireId,
          ...(this.type === this.MESSAGES_TYPE_MEMBER && { clientId: this.memberId }),
          ...this.queries,
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ease-enter-active,
  .ease-leave-active {
    transition: opacity 0.3s ease;
  }

  .ease-enter,
  .ease-leave-to {
    opacity: 0;
  }
  .questionnaire-messages-wrapper {
    position: relative;
    width: 100%;
    .container {
      width: 100%;
      background: #fff;
      &.header {
        margin-bottom: 6px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
          font-family: 'Nunito-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $grey-3;
        }
      }
      &.message-container {
        margin-bottom: 6px;
        padding: 15px 20px;
        .content {
          font-size: 14px;
          line-height: 16px;
          &.date {
            margin-bottom: 10px;
            font-weight: 500;
            color: #868686;
          }
          &.message {
            font-weight: 400;
            color: black;
          }
        }
      }
    }
    .new-message-form {
      margin-bottom: 6px;
      background: #fff;
      .message-form-header {
        padding: 9px 20px;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.5px;
        color: #fff;
        background: $green-9;
      }
      .message-form-wrapper {
        padding: 20px;
        textarea {
          width: 100%;
          resize: none;
          border: 1px solid $grey-2;
          font-family: Roboto-Regular;
          font-size: 14px;
          color: $grey-5;
          min-height: 106px;
          padding: 10px;
          &.error-input {
            border: 1px solid #bc0000;
          }
        }
        .error-message {
          font-size: 14px;
          color: #bc0000;
        }
        .action-buttons-wrapper {
          justify-content: flex-start;
          margin-top: 20px;
          .cancel-message-button {
            margin-left: 20px;
            border: 1px solid $red-4;
            color: $red-4;
            .bin-icon {
              stroke: none;
              margin-right: 3px;
            }
            &:hover {
              border: 1px solid $red-3;
              color: $red-3;
              background: #fff;
            }
          }
        }
      }
    }
    .messages-wrapper {
      display: flex;
      flex-direction: column;
    }
    .no-messages-wrapper {
      margin-top: 30px;
      p {
        font-size: 16px;
        text-transform: uppercase;
        color: $grey-4;
      }
    }
  }
</style>
