/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'oval': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g data-name="Group 1957"><circle pid="0" data-name="Ellipse 741" cx="8" cy="8" r="8" _fill="#71dbb8"/></g>'
  }
})
