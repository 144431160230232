/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-mad': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><ellipse pid="3" cx="8.43" cy="10.87" rx=".98" ry="1.66" _fill="#2c2c2c"/><ellipse pid="4" cx="18.27" cy="10.87" rx=".98" ry="1.66" _fill="#2c2c2c"/><path pid="5" _fill="#2c2c2c" d="M7.32 6.466l.899-.898 2.99 2.991-.897.898zM18.488 5.575l.898.898-2.99 2.99-.899-.897z"/><path pid="6" d="M18.27 19.46c-.29-2.95-2.39-5.24-4.93-5.23s-4.64 2.3-4.91 5.25z" _fill="#71523f"/><path pid="7" d="M8.43 19.48s4.14-4.16 9.84 0z" _fill="#ff6669"/></g></g>'
  }
})
