/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/signal': {
    width: 7.604,
    height: 5.238,
    viewBox: '0 0 7.604 5.238',
    data: '<g data-name="Group 1110"><g data-name="Group 221" _fill="#fff"><path pid="0" data-name="Path 227" d="M6.37 2.612V.53c0-.374.159-.53.537-.53h.2a.452.452 0 0 1 .5.5v4.22a.458.458 0 0 1-.5.511H6.88a.449.449 0 0 1-.5-.5c-.011-.703-.01-1.411-.01-2.119z"/><path pid="1" data-name="Path 228" d="M5.469 3.194c0 .463-.015.926 0 1.387.02.487-.168.687-.649.653h-.085a.452.452 0 0 1-.5-.5V2.285v-.68a.443.443 0 0 1 .482-.485h.227c.362 0 .522.163.523.53z"/><path pid="2" data-name="Path 229" d="M3.342 3.734v.99a.457.457 0 0 1-.506.509h-.269a.436.436 0 0 1-.457-.441q-.01-1.053 0-2.108a.424.424 0 0 1 .412-.439 2.6 2.6 0 0 1 .395 0 .439.439 0 0 1 .425.473c.003.336 0 .676 0 1.016z"/><path pid="3" data-name="Path 230" d="M.004 4.281v-.468a.443.443 0 0 1 .46-.452h.269a.425.425 0 0 1 .447.417c.011.344.01.689 0 1.033a.421.421 0 0 1-.391.415 1.748 1.748 0 0 1-.4 0A.436.436 0 0 1 0 4.777c-.002-.166.004-.331.004-.496z"/></g></g>'
  }
})
