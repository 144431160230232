/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logos/grey-logo': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<g data-name="Group 1111" transform="translate(-62 -429)"><circle pid="0" data-name="Ellipse 11" cx="16.5" cy="16.5" r="16.5" transform="translate(62 429)" _fill="#bababa"/><g data-name="Group 35"><path pid="1" data-name="Path 19" d="M84.708 449.708l-1.525-2.806a5.217 5.217 0 0 0 .779-.868 6.026 6.026 0 0 0 1-3.552 5.317 5.317 0 0 0-2.872-5.077.982.982 0 0 0-.156-.089 6.494 6.494 0 0 0-2.861-.646 5.411 5.411 0 0 0-.935.078h-.023a4.008 4.008 0 0 0-1.2.4 3.366 3.366 0 0 0-1.514-.345 3.566 3.566 0 0 0-1.893.512 2.538 2.538 0 0 0-1.2 2.149v11.924a2.507 2.507 0 0 0 1.325 2.216 3.778 3.778 0 0 0 3.619-.011 2.5 2.5 0 0 0 1.291-2.16l.568 1.113a2.813 2.813 0 0 0 2.539 1.6 3.516 3.516 0 0 0 2.249-.846 2.947 2.947 0 0 0 1.169-2.316 2.419 2.419 0 0 0-.36-1.276zm-8.083 1.678a.615.615 0 0 1-.345.557 1.912 1.912 0 0 1-1.7 0 .627.627 0 0 1-.357-.557v-3.273a2.274 2.274 0 0 0 1.181.3 2.321 2.321 0 0 0 1.224-.334zm0-7.894v3.363a.691.691 0 0 1-.367.5 1.515 1.515 0 0 1-.857.245 1.434 1.434 0 0 1-.847-.234.66.66 0 0 1-.334-.535v-7.37a.616.616 0 0 1 .323-.535 1.537 1.537 0 0 1 .858-.211 1.285 1.285 0 0 1 .768.211.622.622 0 0 1 .312.546v.779a3 3 0 0 1 .935-1.158 2.273 2.273 0 0 1 1.325-.49h.044a6.78 6.78 0 0 1 .835.056.939.939 0 0 1 .345.267 1.116 1.116 0 0 1 .279.735 1.148 1.148 0 0 1-.279.757.772.772 0 0 1-.612.312h-.568a1.934 1.934 0 0 0-1.514.768 3.039 3.039 0 0 0-.646 1.994zm6.046 8.35a1.587 1.587 0 0 1-1.024.4.931.931 0 0 1-.835-.579l-2.628-5.11h-.746v-1.893h1.225a1.742 1.742 0 0 0 1.982-1.959 2.094 2.094 0 0 0-.435-1.425 1.526 1.526 0 0 0 .379-.346 1.927 1.927 0 0 0 .456-1.269 1.867 1.867 0 0 0-.133-.7 3.36 3.36 0 0 1 2.137 3.518 4.055 4.055 0 0 1-.656 2.438 3.217 3.217 0 0 1-1.771 1.27l2.427 4.5a.443.443 0 0 1 .1.3 1.157 1.157 0 0 1-.478.855z" _fill="#fff"/></g></g>'
  }
})
