/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'member-answers': {
    width: 40.736,
    height: 27.803,
    viewBox: '0 0 40.736 27.803',
    data: '<g data-name="Group 3247"><g data-name="Group 3246"><g data-name="Group 2180" _fill="none" _stroke="#3b328b" stroke-width="1.5"><g data-name="Group 1814" transform="translate(-182.251 -470.259)" stroke-linejoin="round" stroke-miterlimit="10"><circle pid="0" data-name="Ellipse 8" cx="5.203" cy="5.203" r="5.203" transform="translate(206.915 472.842)"/><path pid="1" data-name="Path 21" d="M222.237 493.368a10.119 10.119 0 1 0-20.237 0 3.944 3.944 0 0 0 3.944 3.944h12.347a3.946 3.946 0 0 0 3.946-3.944z"/></g><path pid="2" data-name="Union 2" d="M.749 8.298A7.822 7.822 0 0 1 8.82.75a7.894 7.894 0 0 1 8.022 6.722l3.839 2.219-4.371 1.421a8.085 8.085 0 0 1-7.49 4.738A7.822 7.822 0 0 1 .749 8.298z" stroke-linejoin="round"/><path pid="3" data-name="Line 1320" stroke-linecap="round" d="M5.421 4.665h6.721"/><path pid="4" data-name="Line 1321" stroke-linecap="round" d="M5.421 7.798h6.721"/><path pid="5" data-name="Line 1322" stroke-linecap="round" d="M5.421 10.93h6.721"/></g></g></g>'
  }
})
