/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand-questions': {
    width: 10.414,
    height: 11.844,
    viewBox: '0 0 10.414 11.844',
    data: '<g data-name="Group 3908"><g data-name="Group 3767" _fill="none" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round"><path pid="0" data-name="Path 3644" d="M9.707 6.707l-4.5 4.636-4.5-4.636"/><path pid="1" data-name="Path 3645" d="M9.707.707l-4.5 4.636L.707.707"/></g></g>'
  }
})
