/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/time': {
    width: 8.941,
    height: 3.97,
    viewBox: '0 0 8.941 3.97',
    data: '<g data-name="Group 1112"><g data-name="Group 223" _fill="#fff"><path pid="0" data-name="Path 231" d="M.325 3.539a2.131 2.131 0 0 0 .419-.018 1.448 1.448 0 0 0 .8-.372 1.684 1.684 0 0 0 .508-1.022h-.018a1.126 1.126 0 0 1-.9.413A1.112 1.112 0 0 1 0 1.365 1.317 1.317 0 0 1 1.311 0c.786 0 1.271.638 1.271 1.619a2.428 2.428 0 0 1-.662 1.8 1.92 1.92 0 0 1-1.117.514 2.445 2.445 0 0 1-.478.029zm.189-2.21a.739.739 0 0 0 .738.816.861.861 0 0 0 .757-.4.349.349 0 0 0 .047-.189C2.056.9 1.814.398 1.27.398.827.396.514.786.514 1.329z"/><path pid="1" data-name="Path 232" d="M3.096 1.518a.353.353 0 1 1 .7 0 .352.352 0 1 1-.7 0zm0 2.086a.353.353 0 1 1 .7 0 .352.352 0 1 1-.7 0z"/><path pid="2" data-name="Path 233" d="M5.885 3.905V2.86H4.101v-.343L5.814.065h.562v2.387h.537v.408h-.537v1.045zm0-1.453V1.17q0-.3.018-.6h-.018c-.118.224-.212.39-.319.567l-.939 1.306v.012z"/><path pid="3" data-name="Path 234" d="M8.438.549h-.012L7.759.91l-.1-.4.839-.449h.444v3.84h-.5z"/></g></g>'
  }
})
