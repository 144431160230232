/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-thumbsup': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#0085d6"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#007fc4"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#1f9adf"/><path pid="3" d="M21 10.2h-4.59a.18.18 0 0 1-.17-.22 7.75 7.75 0 0 0 .21-2.08 12.13 12.13 0 0 0 0-1.22A2.2 2.2 0 0 0 16 5.52a1.49 1.49 0 0 0-1.14-.62c-1.06 0-1.23 1-1.33 1.88a9 9 0 0 1-.53 2.9 2.7 2.7 0 0 1-1.41 1.44 7.59 7.59 0 0 1-1.45.47.18.18 0 0 0-.15.18v6.85a.18.18 0 0 0 .18.18H12a.18.18 0 0 1 .14.07 2 2 0 0 0 1.65.83h5.94a1.58 1.58 0 0 0 1.58-1.58 1.38 1.38 0 0 0-.15-.62.18.18 0 0 1 0-.22 1.81 1.81 0 0 0 .61-1.28v-.18a1.38 1.38 0 0 0-.15-.62.18.18 0 0 1 0-.22 1.39 1.39 0 0 0 .53-1.09v-.18a1.38 1.38 0 0 0-.15-.62.18.18 0 0 1 0-.22 1.39 1.39 0 0 0 .53-1.09A1.58 1.58 0 0 0 21 10.2z" _fill="#fff"/><rect pid="4" x="5.34" y="10.82" width="3.66" height="8.69" rx=".43" ry=".43" _fill="#fff"/></g></g>'
  }
})
