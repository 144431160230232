/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-trial-info': {
    width: 59,
    height: 49,
    viewBox: '0 0 59 49',
    data: '<path pid="0" d="M33.027 28.235h20.029c2.047 0 3.324-2.22 2.3-3.99L45.342 6.944c-1.024-1.768-3.578-1.768-4.602 0L30.726 24.245c-1.024 1.77.253 3.99 2.3 3.99zm10.014-9.31c-.731 0-1.33-.598-1.33-1.33v-2.659c0-.731.599-1.33 1.33-1.33.732 0 1.33.599 1.33 1.33v2.66c0 .731-.598 1.33-1.33 1.33zm1.33 5.32h-2.66v-2.66h2.66v2.66z" _fill="#FFEDE6"/><path pid="1" d="M24.02 10.996L39.09 37.037H8.947l15.072-26.04zM5.484 35.036c-1.542 2.661.38 6.004 3.462 6.004h30.144c3.083 0 5.004-3.343 3.463-6.005L27.482 8.995c-1.541-2.662-5.384-2.662-6.925 0L5.485 35.035zm16.533-14.012v4.003c0 1.101.9 2.002 2.001 2.002s2.002-.9 2.002-2.002v-4.003c0-1.1-.9-2.001-2.002-2.001-1.1 0-2.001.9-2.001 2.001zm0 10.008h4.003v4.003h-4.003v-4.003z" _fill="#FFDECC"/>'
  }
})
