<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <div class="modal-content-header flex-global">
            <header-h2>
              {{ $t('modals.question_example_modal_title') }} -
              <span>{{ title }}</span>
            </header-h2>
          </div>
          <div class="modal-content">
            <div class="example-questions-container">
              <question-category
                v-for="(category, index) in categories"
                :category="category"
                :key="index"
                :questions="filteredQuestionExamples"
                @handleExampleQuestionSelect="handleExampleQuestionSelect"
              />
            </div>
          </div>
          <div class="modal__container__actions">
            <button-4 @click.native="handleModalAction">{{ $t('actions.add_action') }}</button-4>
            <button-3 @click.native="handleModalToggle">{{ $t('actions.cancel_action') }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import _ from 'lodash';
  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import QuestionCategory from '@/components/base/ExampleQuestions/QuestionCategory.vue';

  export default {
    name: 'QuestionExampleModal',
    components: {
      HeaderH2,
      Button4,
      Button3,
      QuestionCategory,
    },
    props: ['method'],
    data() {
      return {
        activeQuestionId: null,
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['questionExamples']),
      title() {
        return this.method.title.toLowerCase() || '';
      },
      methodId() {
        return this.method.id;
      },
      computedQuestionExamples() {
        return this.questionExamples.map((question) => ({
          ...question,
          isActive: question.id === this.activeQuestionId || false,
        }));
      },
      filteredQuestionExamples() {
        return this.computedQuestionExamples && this.computedQuestionExamples.length
          ? this.computedQuestionExamples.filter((example) => example.methodId === this.methodId)
          : [];
      },
      categories() {
        const categories =
          this.filteredQuestionExamples && this.filteredQuestionExamples.length
            ? this.filteredQuestionExamples.map((example) => example.category)
            : [];
        return _.uniq(categories);
      },
      activeQuestion() {
        return this.computedQuestionExamples.find(
          (question) => question.id === this.activeQuestionId
        );
      },
    },
    methods: {
      ...mapActions('meta', ['toggleExamplesModal']),
      handleModalToggle() {
        this.toggleExamplesModal();
      },
      handleModalAction() {
        this.$emit('handleAssignExampleAction', this.activeQuestion);
      },
      handleExampleQuestionSelect(selectedQuestion) {
        this.activeQuestionId = selectedQuestion.id;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    &__modal {
      width: 800px;
    }
    .modal-content-header {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      h2 {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.7px;
        span {
          text-transform: capitalize;
        }
      }
    }
    .modal-content {
      width: 100%;
      .example-questions-container {
        width: 100%;
        height: 500px;
        padding-right: 20px;
        overflow: auto;
      }
    }
  }
</style>
