<template>
  <div class="custom__card" v-if="type !== 'draft'">
    <div class="card__left">
      <img v-if="template.icon" class="template-image" :src="template.icon" alt="icon" />
      <svgicon v-else :name="'methods/logo-grey'" :original="true" height="2em"></svgicon>
    </div>
    <div class="card__right">
      <div class="card__header">
        <header-h4>
          <slot name="header" />
        </header-h4>
        <div class="card__icon" v-if="!draft && !template.public">
          <svgicon
            class="card__icon__icon"
            v-tooltip="$t('templates.template_share_message')"
            @click.native="toggleTemplateModal('share')"
            name="link"
            :original="true"
            height="22px"
          ></svgicon>
        </div>
        <div class="card__details">
          <span>
            <svgicon name="clock" :original="true" height="1em"></svgicon>
            {{ moment(template.duration * 1000).format('mm [min] ss [sec]') }}
          </span>
          <span v-if="!templateIsPersonal">
            <svgicon
              name="person"
              height="1em"
              :original="true"
              :fill="false"
              :color="'#000'"
            ></svgicon>
            <slot name="author" />
          </span>
        </div>
      </div>
      <div class="card__content">
        <paragraph>
          <slot name="content" />
        </paragraph>
      </div>
      <div class="card__buttons">
        <div class="button-wrapper">
          <button-4 class="card__buttons__button" @click.native="openQuestionnaire">
            {{ $t('actions.open_action') }}
          </button-4>
        </div>
        <svgicon
          v-if="!templateIsCopied && !templateIsPersonal"
          v-tooltip="$t('templates.template_copy_message')"
          class="card__buttons__icon"
          name="copy_template"
          :original="true"
          @click.native="handleTemplateCopy"
        ></svgicon>
        <svgicon
          v-if="templateIsPersonal"
          v-tooltip="$t('templates.template_delete_message')"
          class="card__buttons__icon"
          name="delete"
          :original="true"
          @click.native="toggleTemplateModal('delete')"
        ></svgicon>
      </div>
    </div>
    <template-modal
      :template="template"
      :type="modalType"
      :templateType="type"
      v-show="templateModal"
      @close="toggleTemplateModal"
      :key="`type-${modalType}`"
      @handleDelete="handleDelete"
    ></template-modal>
  </div>
  <div class="custom__card" v-else>
    <div class="card__left">
      <img v-if="template.icon" class="template-image" :src="template.icon" alt="icon" />
      <svgicon v-else :name="'methods/logo-grey'" :original="true" height="2em"></svgicon>
    </div>
    <div class="card__right">
      <div class="card__header">
        <header-h4>
          <slot name="header" />
        </header-h4>
        <div class="card__icon" v-if="!template.public">
          <svgicon
            class="card__icon__icon"
            v-tooltip="$t('templates.template_share_to_public_message')"
            @click.native="handleNewShare"
            name="link"
            :original="true"
            height="22px"
          ></svgicon>
        </div>
        <div class="card__details">
          <span>
            <svgicon name="clock" :original="true" height="1em"></svgicon>
            {{ moment(template.duration * 1000).format('mm [min] ss [sec]') }}
          </span>
          <span v-if="template.clientGroupId">
            <svgicon name="people" :original="true" height="1em"></svgicon>
            {{ group ? group.title : '-' }}
          </span>
        </div>
      </div>
      <div class="card__content">
        <paragraph>
          <slot name="content" />
        </paragraph>
      </div>
      <div class="card__buttons">
        <div class="button-wrapper">
          <button-4 class="card__buttons__button" @click.native="openQuestionnaire">
            {{ $t('actions.open_action') }}
          </button-4>
          <button-4
            class="card__buttons__button send-questionnaire-button"
            @click.native="sendQuestionnaire"
          >
            {{ $t('actions.send_action') }}
          </button-4>
        </div>
        <!-- <svgicon
          v-tooltip="'Kopijuoti į „Mano ruošinius“'"
          class="card__buttons__icon"
          name="copy_template"
          :original="true"
          @click.native="handleQuestionnaireCopy"
        ></svgicon>-->
        <svgicon
          v-tooltip="$t('questionnaire.questionnaire_copy_message')"
          class="card__buttons__icon"
          name="copy_template"
          :original="true"
          @click.native="handleQuestionnaireCopy"
        ></svgicon>
        <svgicon
          v-if="!template.clientGroupId"
          v-tooltip="$t('questionnaire.questionnaire_delete_message')"
          class="card__buttons__icon"
          name="delete"
          :original="true"
          @click.native="toggleTemplateModal('deleteDraft')"
        ></svgicon>
      </div>
    </div>
    <template-modal
      :template="template"
      :questionnaireTemplate="questionnaireTemplate"
      :type="modalType"
      :templateType="type"
      v-show="templateModal"
      @close="toggleTemplateModal"
      @handleDelete="handleDelete"
      :key="`type-${modalType}`"
    ></template-modal>
    <template-share-modal
      :template="template"
      :questionnaireTemplate="questionnaireTemplate"
      :templateType="type"
      :filterData="questionnaireFilterData"
      v-show="templateShareModal"
      @close="toggleTemplateShareModal"
      :key="`type-share`"
    ></template-share-modal>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';
  import Paragraph from '@/components/elements/typography/Paragraph.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import TemplateModal from '@/components/templates/TemplateModal.vue';
  import TemplateShareModal from '@/components/templates/TemplateShareModal.vue';

  export default {
    name: 'TemplateCard',
    components: {
      HeaderH4,
      Paragraph,
      Button4,
      TemplateModal,
      TemplateShareModal,
    },
    data() {
      return {
        templateModal: false,
        templateShareModal: false,
        modalType: null,
        questionnaireTemplate: null,
      };
    },
    props: ['locked', 'draft', 'template', 'type'],
    computed: {
      ...mapGetters('reflections', ['templatesPrivate', 'templatesPublic', 'editStatus']),
      ...mapGetters('user', ['user']),
      ...mapGetters('groups', ['groupList']),
      ...mapGetters('questionnaire', ['questionnaireFilterData']),
      templateIsCopied() {
        if (this.type === 'draft') return false;
        const { id } = this.template;
        const isCopied = this.templatesPrivate.filter((template) => template.id === id).length >= 1;
        return isCopied;
      },
      templateIsPersonal() {
        if (this.type === 'draft') return false;
        const userId = parseInt(this.user.id, 10);
        const templateIsPersonal = this.template.owner.id === userId;
        return templateIsPersonal;
      },
      route() {
        return this.$route.name;
      },
      group() {
        return this.groupList.find((group) => group.id === this.template.clientGroupId);
      },
    },
    methods: {
      ...mapActions('reflections', [
        'cloneTemplate',
        'deleteTemplate',
        'getPrivateTemplates',
        'getTemplateById',
        'setEditStatus',
        'createFromQuestionnaire',
      ]),
      ...mapActions('questionnaire', [
        'setQuestionnaireProperty',
        'setCurrentView',
        'getQuestionnaire',
        'deleteDraft',
        'createFromTemplate',
      ]),
      toggleTemplateModal(type) {
        if (type) {
          this.modalType = type;
        }
        // if (type === 'copy') {
        //   // this.handleQuestionnaireCopy();
        //   this.handleTemplateCopy()
        // }
        this.templateModal = !this.templateModal;
      },
      toggleTemplateShareModal() {
        this.templateShareModal = !this.templateShareModal;
      },
      sendQuestionnaire() {
        const { id } = this.template;

        this.$router.push({
          name: 'ReflectionOptions',
          params: { id, type: 'draft' },
        });
      },
      openQuestionnaire() {
        if (this.type === 'draft') {
          const { id } = this.template;
          this.getQuestionnaire({ questionnaireId: id });
          this.$router.push({ name: 'EditReflection', params: { id } });
          if (this.editStatus !== 'view') this.setEditStatus('view');
          return;
        }
        const { id } = this.template;
        this.getTemplateById(id).then((res) => {
          const { success, data } = res;
          if (success) {
            const { title, description, questions } = data;
            const properties = [
              { type: 'title', property: title },
              { type: 'description', property: description },
              { type: 'questions', property: questions },
            ];
            properties.forEach((property) => {
              this.setQuestionnaireProperty(property);
            });
            this.setCurrentView('list');
            this.$router.push({ name: 'EditTemplate', params: { id } });
            if (this.editStatus !== 'view') this.setEditStatus('view');
          }
        });
      },
      handleQuestionnaireCopy() {
        this.createFromQuestionnaire(this.template.id).then((res) => {
          const { success } = res;
          const { id: templateId } = res.data;
          if (success) {
            this.createFromTemplate(templateId);
            this.toggleTemplateModal('copyFromQuestionnaire');
          }
        });
      },
      handleTemplateCopy() {
        this.cloneTemplate({ id: this.template.id }).then((res) => {
          const { success } = res;
          const { id: templateId } = res.data;
          if (success) {
            this.createFromTemplate(templateId);
            this.toggleTemplateModal('copy');
          }
        });
      },
      handleNewShare() {
        this.createFromQuestionnaire(this.template.id).then((res) => {
          const { success } = res;
          this.questionnaireTemplate = res.data;
          if (success) {
            // this.toggleTemplateModal('share');
            this.toggleTemplateShareModal();
          }
        });
      },
      handleDelete(success) {
        this.$emit('handleDelete', success);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .custom__card {
    margin-top: 20px;
    padding: 20px;
    width: 460px;
    height: 232px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    text-align: left;

    &:nth-of-type(2n) {
      margin-left: 20px;
    }

    .card__right {
      margin-left: 15px;
      position: relative;
      flex: 1;
      .card__header {
        position: relative;

        h4 {
          padding-right: 45px;
          min-height: 34px;
        }

        .card__icon {
          cursor: pointer;
          svg {
            position: absolute;
            top: 0;
            right: 0;
          }

          &__icon {
            transition: all 0.3s;

            &:hover {
              transform: translateY(3px) scale(1.25);
            }
          }
        }

        .card__details {
          margin-top: 10px;
          display: flex;
          align-items: center;
          span {
            font-family: Roboto-Regular;
            font-size: 12px;
            color: #505e67;

            &:nth-of-type(2) {
              margin-left: 20px;
            }

            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .card__content {
        margin-top: 10px;

        p {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: 60px;
          min-height: 60px;
          word-break: break-word;
        }
      }
      .card__buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .button-wrapper {
          margin-right: auto;
          button:first-child {
            margin-right: 10px;
          }
        }

        &__icon {
          height: 27px;
          width: 27px;
          transition: all 0.3s;

          &:hover {
            cursor: pointer;
            transform: translateY(3px) scale(1.25);
          }
          &:not(:first-of-type) {
            margin-left: 10px;
          }
        }
      }
    }

    .template-image {
      height: 33px;
      width: 33px;
      border-radius: 50%;
    }
  }
</style>
