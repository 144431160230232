/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate': {
    width: 16,
    height: 16,
    viewBox: '0 0 11.79 11.87',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#939598" stroke-linejoin="round" stroke-width=".75"><path pid="0" d="M3.11 3.19h8.31v8.31H3.11z"/><path pid="1" stroke-linecap="round" d="M.38 8.68V.38h8.3"/></g></g>'
  }
})
