<template>
  <div class="template-modal" v-if="template" @click="close">
    <div class="template-modal__bg">
      <div class="template-modal__modal" @click.stop>
        <div
          class="template-modal__container"
          :class="{ 'success-container': success, 'fixed-height': !hideAllFields }"
        >
          <svgicon
            @click="close"
            color="grey"
            :fill="false"
            name="multiply"
            class="template-modal__close"
          ></svgicon>
          <div class="template-modal__container__icon" v-if="success">
            <svgicon
              class="template-modal__container__icon__svg"
              name="large-check"
              :original="true"
            ></svgicon>
          </div>
          <div class="flex-global share-modal-header-container" v-if="type === 'share' && !success">
            <div class="template-modal__container__icon">
              <svgicon
                class="template-modal__container__icon__svg template-modal__container__icon__svg--envelope"
                name="large-envelope-happy"
                :original="true"
              ></svgicon>
              <svgicon
                v-if="!template.public"
                class="template-modal__container__icon__svg template-modal__container__icon__svg--dialog"
                name="dialog-heart"
                :original="true"
              ></svgicon>
              <svgicon
                v-else
                class="template-modal__container__icon__svg template-modal__container__icon__svg--dialog"
                name="dialog-check"
                :original="true"
              ></svgicon>
            </div>
            <div class="template-modal__container__content">
              <h4 class="template-modal__container__content__header" v-if="!template.public">
                {{ $t('modals.template_modal_share_quest_header') }}
              </h4>
              <h4
                class="template-modal__container__content__header"
                v-if="template.public && !success"
              >
                {{ $t('modals.template_modal_share_again_question') }}
              </h4>
            </div>
          </div>
          <div class="template-modal__container__content success-message" v-if="success">
            <h4 class="template-modal__container__content__header">
              {{ $t('modals.template_modal_template_share_success_message') }}
            </h4>
          </div>
          <div
            class="template-modal__container__edit"
            v-if="type === 'share' && !template.public && !success"
          >
            <div class="template-modal__container__edit__border"></div>
            <div class="template-modal__container__edit__header">
              <div class="flex-global template-info-header-container">
                <div class="template-modal__container__edit__header__title">
                  <img
                    class="template-modal__container__edit__header__icon"
                    v-if="template.icon"
                    :src="template.icon"
                    alt="template-icon"
                  />
                  <svgicon
                    class="template-modal__container__edit__header__icon"
                    v-else
                    name="methods/logo-grey"
                    :original="true"
                  ></svgicon>
                  <p class="template-modal__container__edit__header__title__title">
                    {{ template.title }}
                  </p>
                </div>
                <p class="template-modal__container__edit__header__info">
                  <span>
                    <svgicon name="clock" :original="true"></svgicon>
                    {{ moment(template.duration * 1000).format('mm [min] ss [sec]') }}
                  </span>
                  <span>
                    <svgicon name="person" :original="true"></svgicon>
                    {{ questionnaireTemplateOwnerName }}
                  </span>
                </p>
              </div>
              <div class="flex-global form-container">
                <div class="flex-global form-wrappers">
                  <div class="form-wrapper">
                    <div class="select-wrapper">
                      <p class="select-label">{{ $t('share_modal.input_group_title') }}</p>
                      <multi-select
                        :options="questionnaireGroupOptions"
                        :id="`${filterTypes.questionnaireGroup}`"
                        :placeholder="$t('share_modal.input_group_placeholder')"
                        @selectedValue="handleSelectChange"
                        :selected="payload.questionnaireGroup"
                      ></multi-select>
                    </div>
                    <div class="select-wrapper" v-if="!hideAdditionalFields && !hideAllFields">
                      <p class="select-label">{{ $t('share_modal.input_subject_title') }}</p>
                      <multi-select-multiple
                        :options="studySubjectOptions"
                        :id="`${filterTypes.studySubject}`"
                        :placeholder="$t('share_modal.input_subject_placeholder')"
                        @selectedValue="handleSelectChange"
                        :selected="payload.studySubject"
                      ></multi-select-multiple>
                    </div>
                    <div class="select-wrapper" v-if="!hideAdditionalFields && !hideAllFields">
                      <p class="select-label">{{ $t('share_modal.input_objective_title') }}</p>
                      <multi-select-multiple
                        :options="reflectionObjectiveOptions"
                        :id="`${filterTypes.reflectionObjective}`"
                        :placeholder="$t('share_modal.input_objective_placeholder')"
                        @selectedValue="handleSelectChange"
                        :selected="payload.reflectionObjective"
                      ></multi-select-multiple>
                    </div>
                  </div>
                  <div class="form-wrapper" v-if="!hideAdditionalFields && !hideAllFields">
                    <div class="select-wrapper">
                      <p class="select-label">{{ $t('share_modal.input_class_title') }}</p>
                      <multi-select-multiple
                        :options="classesOptions"
                        :id="`${filterTypes.classes}`"
                        :placeholder="$t('share_modal.input_class_placeholder')"
                        @selectedValue="handleSelectChange"
                        :selected="payload.classes"
                      ></multi-select-multiple>
                    </div>
                    <div class="select-wrapper">
                      <p class="select-label">{{ $t('share_modal.input_frequency_title') }}</p>
                      <multi-select-multiple
                        :options="fillingFrequencyOptions"
                        :id="`${filterTypes.fillingFrequency}`"
                        :placeholder="$t('share_modal.input_frequency_placeholder')"
                        @selectedValue="handleSelectChange"
                        :selected="payload.fillingFrequency"
                      ></multi-select-multiple>
                    </div>
                  </div>
                </div>
                <div class="template-modal__container__edit__description" v-if="!hideAllFields">
                  <p class="template-modal__container__edit__description__title">
                    {{ $t('modals.template_modal_decription_for_users') }}
                  </p>
                  <textarea
                    class="template-modal__container__edit__description__input"
                    v-model="payload.recommendations"
                    :placeholder="$t('share_modal.input_recommendation_placeholder')"
                    :maxlength="descriptionMaxLength"
                  ></textarea>
                  <p v-if="payload.recommendations && payload.recommendations.length >= descriptionMaxLength" class="info-message">{{ $t('share_modal.input_recommendation_max_reached') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="template-modal__container__actions"
            :class="{ hidden: hideAllFields }"
            v-if="type === 'share' && !success"
          >
            <button-3 @click.native="close">{{ $t('actions.cancel_action') }}</button-3>
            <button-4 @click.native="updateHandler" v-if="!template.public">
              {{ $t('actions.share_action') }}
            </button-4>
            <button-4 @click.native="close" v-else>
              {{ $t('actions.share_action') }}
            </button-4>
          </div>
          <p class="error-message" v-if="displayErrorMessage">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import MultiSelectMultiple from '@/components/elements/select/MultiSelectMultiple.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import {
    QUESTIONNAIRE_GROUP_FOR_STUDENTS,
    QUESTIONNAIRE_GROUP_FOR_TEACHERS,
    QUESTIONNAIRE_GROUP_FOR_PARENTS,
    FILTER_TYPE_GROUP,
    FILTER_TYPE_CLASSES,
    FILTER_TYPE_STUDY,
    FILTER_TYPE_FREQ,
    FILTER_TYPE_OBJECTIVE,
  } from '@/helpers/constants/template';

  export default {
    name: 'TemplateShareModal',
    components: {
      Button3,
      Button4,
      MultiSelect,
      MultiSelectMultiple,
    },
    props: ['template', 'templateType', 'questionnaireTemplate', 'filterData'],
    data() {
      return {
        success: false,
        type: 'share',
        currentGroup: null,
        errorMessage: this.$t('warnings.all_fields_are_required_warning'),
        displayErrorMessage: false,
        groupTypes: {
          forStudents: QUESTIONNAIRE_GROUP_FOR_STUDENTS,
          forTeachers: QUESTIONNAIRE_GROUP_FOR_TEACHERS,
          forParents: QUESTIONNAIRE_GROUP_FOR_PARENTS,
        },
        filterTypes: {
          questionnaireGroup: FILTER_TYPE_GROUP,
          classes: FILTER_TYPE_CLASSES,
          studySubject: FILTER_TYPE_STUDY,
          fillingFrequency: FILTER_TYPE_FREQ,
          reflectionObjective: FILTER_TYPE_OBJECTIVE,
        },
        payload: {
          recommendations: null,
          questionnaireGroup: null,
          classes: null,
          studySubject: null,
          fillingFrequency: null,
          reflectionObjective: null,
        },
        descriptionMaxLength: 800
      };
    },
    created() {
      this.resetPayload();
    },
    computed: {
      questionnaireTemplateOwnerName() {
        return this.questionnaireTemplate && this.questionnaireTemplate.owner
          ? `${this.questionnaireTemplate.owner.name} ${this.questionnaireTemplate.owner.lastName}`
          : '-';
      },
      questionnaireGroupOptions() {
        return this.filterData
          ? this.filterData.questionnaireGroup.map((filterValue, index) => ({
              value: filterValue,
              type: index + 1,
              id: 'questionnaireGroup',
            }))
          : [];
      },
      hideAllFields() {
        return !this.currentGroup;
      },
      hideAdditionalFields() {
        return this.currentGroup !== this.groupTypes.forStudents;
      },
      isFormValid() {
        return this.hideAdditionalFields
          ? !!(this.payload.recommendations && this.payload.questionnaireGroup)
          : !!(
              this.payload.recommendations &&
              this.payload.questionnaireGroup &&
              this.payload.classes &&
              this.payload.studySubject &&
              this.payload.fillingFrequency &&
              this.payload.reflectionObjective
            );
      },
      preparedPayload() {
        return this.hideAdditionalFields
          ? {
              questionnaireGroup: [this.payload.questionnaireGroup],
              recommendations: this.payload.recommendations,
            }
          : {
              ...this.payload,
              questionnaireGroup: [this.payload.questionnaireGroup],
              classes: this.payload.classes ? this.payload.classes.map((value) => value.title) : [],
              studySubject: this.payload.studySubject
                ? this.payload.studySubject.map((value) => value.title)
                : [],
              fillingFrequency: this.payload.fillingFrequency
                ? this.payload.fillingFrequency.map((value) => value.title)
                : [],
              reflectionObjective: this.payload.reflectionObjective
                ? this.payload.reflectionObjective.map((value) => value.title)
                : [],
            };
      },
      studySubjectOptions() {
        const studySubjects = this.filterData
          ? this.filterData.studySubject.map((value) => {
              return { title: value, isAll: false };
            })
          : [];
        return [
          { title: this.$t('share_modal.isAll_value_title_2'), isAll: true },
          ...studySubjects,
        ];
      },
      reflectionObjectiveOptions() {
        const reflectionObjectives = this.filterData
          ? this.filterData.reflectionObjective.map((value) => {
              return { title: value, isAll: false };
            })
          : [];
        return [
          { title: this.$t('share_modal.isAll_value_title_2'), isAll: true },
          ...reflectionObjectives,
        ];
      },
      classesOptions() {
        const classes = this.filterData
          ? this.filterData.classes.map((value) => {
              return { title: value, isAll: false };
            })
          : [];
        return [{ title: this.$t('share_modal.isAll_value_title_1'), isAll: true }, ...classes];
      },
      fillingFrequencyOptions() {
        const fillingFrequencies = this.filterData
          ? this.filterData.fillingFrequency.map((value) => {
              return { title: value, isAll: false };
            })
          : [];
        return [
          { title: this.$t('share_modal.isAll_value_title_2'), isAll: true },
          ...fillingFrequencies,
        ];
      },
    },
    methods: {
      ...mapActions('reflections', ['shareTemplate', 'deleteTemplate']),
      ...mapActions('questionnaire', ['deleteDraft', 'deleteQuestionnaire']),
      close(success) {
        if (this.success) {
          this.resetPayload();
        }
        this.success = false;
        this.$emit('close', success);
      },
      updateHandler() {
        const { id: questionnaireId } = this.questionnaireTemplate;
        if (this.isFormValid) {
          this.shareTemplate({ id: questionnaireId, payload: this.preparedPayload }).then((res) => {
            const { success } = res;
            if (success) {
              this.success = true;
              this.displayErrorMessage = false;
              this.currentGroup = null;
              this.resetPayload();
            }
          });
        } else {
          this.displayErrorMessage = true;
        }
      },
      resetPayload() {
        this.payload = {
          recommendations: null,
          questionnaireGroup: null,
          classes: null,
          studySubject: null,
          fillingFrequency: null,
          reflectionObjective: null,
        };
      },
      handleSelectChange(value) {
        const selectedValue = value.value;
        const selectedType = value.id;

        switch (selectedType) {
          case this.filterTypes.questionnaireGroup:
            this.payload.questionnaireGroup = selectedValue;
            this.currentGroup = value.type;
            break;
          case this.filterTypes.classes:
            this.payload.classes = selectedValue && selectedValue.length ? selectedValue : null;
            break;
          case this.filterTypes.studySubject:
            this.payload.studySubject =
              selectedValue && selectedValue.length ? selectedValue : null;
            break;
          case this.filterTypes.fillingFrequency:
            this.payload.fillingFrequency =
              selectedValue && selectedValue.length ? selectedValue : null;
            break;
          case this.filterTypes.reflectionObjective:
            this.payload.reflectionObjective =
              selectedValue && selectedValue.length ? selectedValue : null;
            break;
          default:
            break;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import 'mixins';
  .info-message {
    margin-top: 5px;
    font-size: 14px;
    text-align: right;
    color: $grey-5;
  }
  .template-modal {
    &__bg {
      margin: 0;
      z-index: 5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__modal {
      @include hardBoxShadow();
      overflow-x: unset;
      display: flex;
      flex-direction: column;
    }

    &__container {
      max-width: 742px;
      width: 742px;
      min-height: 324px;
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      @media screen and (max-height: 1100px) {
        &.fixed-height {
          overflow-y: scroll;
          max-height: 830px;
        }
      }
      @media screen and (max-height: 920px) {
        &.fixed-height {
          overflow-y: scroll;
          max-height: 720px;
        }
      }
      @media screen and (max-height: 800px) {
        &.fixed-height {
          overflow-y: scroll;
          max-height: 500px;
        }
      }

      &.success-container {
        width: 410px;
        min-height: 206px;
      }

      .share-modal-header-container {
        flex-direction: row;
        .template-modal__container__icon {
          margin-right: 50px;
        }
        .template-modal__container__content {
          text-align: left;
          margin-top: 15px;
          h4 {
            max-width: 250px;
          }
        }
      }

      &__icon {
        margin-top: 10px;
        position: relative;

        &__svg {
          width: 120px;
          height: 82px;

          &--envelope {
            width: 70px;
            height: 40px;
          }

          &--dialog {
            width: 32px;
            height: 48px;
            position: absolute;
            top: -20px;
          }
        }
      }

      &__content {
        margin-top: 20px;

        &.success-message {
          margin-bottom: 10px;
        }

        &__header {
          font-family: Nunito-Bold;
          font-size: 16px;
          color: $violet-5;
          max-width: 320px;
        }
      }

      &__edit {
        margin-top: 20px;
        position: relative;
        width: 100%;

        &__border {
          position: absolute;
          height: 3px;
          width: calc(100% + 40px);
          left: -20px;
          background-color: rgba(0, 0, 0, 0.3);
        }

        &__header {
          margin-top: 20px;
          display: flex;
          flex-direction: column;

          .template-info-header-container {
            justify-content: space-between;
            &__title {
              display: flex;
              align-items: center;
              svg {
                width: 34px;
                height: 34px;
              }

              &__title {
                margin-left: 14px;
                font-family: Roboto-Medium;
                font-size: 14px;
                color: $grey-5;
              }
            }

            &__info {
              text-align: left;

              span {
                svg {
                  width: 14px;
                  height: 14px;
                  margin-right: 2px;
                }

                &:last-of-type {
                  margin-left: 24px;
                }
              }
            }
          }

          .form-container {
            flex-direction: column;
            margin-top: 24px;
            .form-wrappers {
              width: 100%;
              align-items: flex-start;
              .form-wrapper {
                flex: 1;
                &:first-child {
                  margin-right: 30px;
                }
                .select-wrapper {
                  margin-bottom: 20px;
                  text-align: left;
                  .select-label {
                    margin-bottom: 4px;
                    font-family: Nunito-Bold;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 0.45px;
                    color: $grey-3;
                  }
                  .multiselect {
                    width: 336px;
                  }
                }
              }
            }
          }

          &__icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        &__description {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          width: 100%;

          &__title {
            text-transform: initial;
            font-family: Nunito-Bold;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 0.45px;
            color: $grey-3;
            text-align: left;
          }

          &__input {
            margin-top: 4px;
            width: 100%;
            resize: none;
            border: 1px solid $grey-2;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;
            min-height: 110px;
            padding: 10px;

            &::placeholder {
              color: #adadad;
            }

            &:active,
            &:focus,
            &:hover {
              border-color: $grey-3;
            }
          }
        }
      }

      &__actions {
        visibility: visible;
        margin-top: 30px;
        margin-left: auto;

        button {
          padding: 5px 20px;
          min-width: 102px;
          &:last-of-type {
            margin-left: 10px;
          }
        }
        &.hidden {
          visibility: hidden;
        }
      }

      .error-message {
        margin-top: 5px;
        font-size: 14px;
        color: $red-4;
      }
    }

    &__close {
      position: absolute;
      height: 10px;
      width: 10px;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
  }
</style>
