/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '404/half-circle': {
    width: 14.589,
    height: 14.593,
    viewBox: '0 0 14.589 14.593',
    data: '<g data-name="Group 3911"><path pid="0" data-name="Path 3866" d="M11.545 14.593a.829.829 0 0 1-.587-1.416 6.747 6.747 0 1 0-9.542-9.542.83.83 0 0 1-1.173-1.173A8.406 8.406 0 0 1 12.131 14.35a.826.826 0 0 1-.586.243z" _fill="#66c"/></g>'
  }
})
