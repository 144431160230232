<template>
  <div class="member__reflection">
    <div class="reflection__main" @click="handleNavigation('GroupMemberReflection')">
      <img v-if="questionnaire.icon" class="reflection__image" :src="questionnaire.icon" />
      <svgicon v-else class="reflection__image" name="methods/logo-grey" :original="true"></svgicon>
      <div class="reflection__info">
        <div class="reflection__title">
          <header-h4>{{ questionnaire.title }}</header-h4>
        </div>
        <div class="reflection__details">
          <span>
            {{ $t('group.group_reflection_count') }}:
            <span class="details-second">
              {{ questionnaire.answeredReflectionCount || 0 }}/{{
                questionnaire.reflectionCount || 0
              }}
            </span>
          </span>
          <span>|</span>
          <span>
            <svgicon name="people" :original="true" width="24px"></svgicon>
            <router-link
              tag="span"
              :to="{ name: 'Group', params: { groupId: questionnaire.clientGroupId } }"
              class="details-second details-second--link"
            >
              {{ groupTitle }}
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <div
      @click="handleNavigation('GroupMemberReflection')"
      v-if="member"
      class="reflection__action"
    >
      <svgicon name="R" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('group.group_member_reflections') }}</span>
    </div>
    <div @click="handleNavigation('GroupMemberStats')" v-if="member" class="reflection__action">
      <svgicon
        name="line-chart"
        :original="true"
        height="20px"
        :fill="false"
        color="#3B328B"
      ></svgicon>
      <span>{{ $t('statistics_in_time.statistics_in_time') }}</span>
    </div>
    <router-link
      tag="div"
      :to="{ name: 'ReflectionOptions', params: { id: questionnaire.id } }"
      class="reflection__action"
    >
      <svgicon name="plane" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('group.group_schedule_settings') }}</span>
    </router-link>
    <router-link
      tag="div"
      :to="{ name: 'EditReflection', params: { id: questionnaire.id } }"
      class="reflection__action"
      @click.native="() => setEditStatus('edit')"
    >
      <svgicon name="pen" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('actions.edit_questionnaire_action') }}</span>
    </router-link>
    <div class="reflection__action" @click="handleMemberMessages">
      <svgicon
        name="envelope"
        :original="true"
        height="20px"
        :fill="false"
        color="#3B328B"
      ></svgicon>
      <span>{{ $t('questionnaire_messages.for_member') }}</span>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

  export default {
    name: 'MemberReflection',
    components: {
      HeaderH4,
    },
    props: ['member', 'questionnaire'],
    computed: {
      ...mapGetters('members', ['memberGroups', 'memberReflections']),
      groupTitle() {
        if (!this.memberGroups.length) return false;
        const { title } = this.memberGroups.find(
          (group) => group.id === this.questionnaire.clientGroupId
        );
        return title;
      },
    },
    methods: {
      ...mapActions('reflections', ['setEditStatus', 'setCurrentReflection']),
      ...mapActions('members', ['getMemberReflections']),
      ...mapActions('stats', ['setType']),
      async handleGetMemberReflections() {
        const params = {
          memberId: this.member.id,
          questionnaireId: this.questionnaire.id,
        };
        await this.getMemberReflections({
          memberId: params.memberId,
          questionnaireId: params.questionnaireId,
        });
        return Promise.resolve();
      },
      async handleNavigation(name) {
        const params = {
          memberId: this.member.id,
          questionnaireId: this.questionnaire.id,
          groupId: this.questionnaire.clientGroupId,
        };

        if (name === 'GroupMemberStats') {
          this.setType('inTime');
          await this.handleGetMemberReflections().then(() => {
            const currentReflection = this.memberReflections[0];
            this.setCurrentReflection(currentReflection);
            this.$router.push({ name, params });
          });
        } else {
          this.$router.push({ name, params });
        }
      },
      handleMemberMessages() {
        this.setType('groupMessages');
        this.$router.push({
          name: 'GroupMemberStats',
          params: {
            memberId: this.member.id,
            questionnaireId: this.questionnaire.id,
            groupId: this.questionnaire.clientGroupId,
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import 'mixins';
  .member {
    &__reflection {
      display: flex;
      justify-content: space-between;
      &:not(:first-of-type) {
        margin-top: 12px;
      }

      .reflection {
        &__main {
          display: flex;
          background-color: #fff;
          width: 650px;
          height: 110px;
        }

        &__image {
          margin: 20px 20px auto 20px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }

        &__info {
          text-align: left;
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          padding-right: 70px;

          h4 {
            font-size: 16px;
          }
        }

        &__details {
          margin-top: 15px;

          .details-second {
            margin: 0;
            &--link {
              cursor: pointer;
            }
          }
          span {
            margin-right: 15px;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;

            svg {
              margin-right: 10px;
            }
          }
        }

        &__action {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          width: 94px;
          height: 110px;
          margin-left: 5px;
          span {
            font-family: Roboto-Medium;
            font-size: 12px;
            color: $violet-5;
            padding: 0 20px;
            margin-top: 15px;
          }
        }

        &__main,
        &__action {
          @include handleHover();
        }
      }
    }
  }
</style>
