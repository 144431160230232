/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blue-arrow': {
    width: 18.739,
    height: 17.034,
    viewBox: '0 0 18.739 17.034',
    data: '<g data-name="Group 1916"><g data-name="Group 1126" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-width="1.5"><path pid="0" data-name="Line 136" stroke-miterlimit="10" d="M.75 8.518h17.239"/><path pid="1" data-name="Path 834" d="M10.222.751l7.767 7.767-7.767 7.767" stroke-linejoin="round"/></g></g>'
  }
})
