/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question': {
    width: 16,
    height: 16,
    viewBox: '0 0 33.12 33.12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="16.56" cy="16.56" r="16.56" _fill="#66c"/><circle pid="1" cx="16.56" cy="16.03" r="8.11" transform="rotate(-76.12 16.557 16.033)" _stroke="#fff" stroke-miterlimit="10" _fill="none" stroke-linecap="round" stroke-width=".75"/><path pid="2" d="M14.65 13.89a1.78 1.78 0 0 1 1.92-1.72 1.69 1.69 0 0 1 1.65 2.53c-.53.73-1.86 1.65-1.86 2.53" _stroke="#fff" stroke-miterlimit="10" _fill="none" stroke-linecap="round" stroke-width=".75"/><circle pid="3" cx="16.37" cy="19.33" r=".5" _fill="#5454c6" _stroke="#fff" stroke-miterlimit="10"/></g></g>'
  }
})
