/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-unwell': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#67b82f"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#50ab37"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#7bc228"/><path pid="3" d="M21.62 17.39a2.42 2.42 0 0 0-2.2-1.9 2 2 0 0 0-1.06.32 2.79 2.79 0 0 0-.61.59l-.26.29a1.84 1.84 0 0 1-.65.42 1.48 1.48 0 0 1-1.66-.54 2.29 2.29 0 0 0-1.84-1.07 2 2 0 0 0-1.44.65 3.37 3.37 0 0 0-.27.32 3.07 3.07 0 0 1-.22.26 1.8 1.8 0 0 1-.53.33 1.32 1.32 0 0 1-.77.12 1.72 1.72 0 0 1-.92-.49L9 16.42a3 3 0 0 0-.64-.61 2 2 0 0 0-1.05-.31 2.42 2.42 0 0 0-2.18 1.84.45.45 0 0 0 .2.54.36.36 0 0 0 .46-.1 1.89 1.89 0 0 1 2-.84 2.4 2.4 0 0 1 .95.79 2.19 2.19 0 0 0 1.36.8h.26a2.15 2.15 0 0 0 1.64-.86l.08-.1a1.77 1.77 0 0 1 .45-.46 1.74 1.74 0 0 1 .68-.25 1.31 1.31 0 0 1 .75.14 2.12 2.12 0 0 1 .38.24 2.74 2.74 0 0 1 .37.39 3.28 3.28 0 0 0 .44.46 1.94 1.94 0 0 0 2.61-.09l.29-.3c.16-.17.46-.48.53-.53a1.42 1.42 0 0 1 1.72 0 3.41 3.41 0 0 1 .65.65.36.36 0 0 0 .46.11.45.45 0 0 0 .21-.54z" _fill="#654735"/><path pid="4" _fill="#2c2c2c" d="M4.702 9.172l.863-.862 3.89 3.889-.864.862z"/><path pid="5" _fill="#2c2c2c" d="M8.598 8.302l.862.863-3.889 3.89-.862-.864zM17.247 9.168l.862-.862L22 12.195l-.863.862z"/><path pid="6" _fill="#2c2c2c" d="M21.142 8.307l.862.862-3.889 3.89-.862-.863z"/></g></g>'
  }
})
