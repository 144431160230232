/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play-button': {
    width: 45.696,
    height: 45.696,
    viewBox: '0 0 45.696 45.696',
    data: '<g data-name="Group 1956"><path pid="0" data-name="Path 904" d="M32.536 20.9l-15.637-9.028a1.7 1.7 0 0 0-2.543 1.468v18.057a1.7 1.7 0 0 0 2.543 1.468l15.637-9.028a1.7 1.7 0 0 0 0-2.937z" _fill="#fff"/><g data-name="Ellipse 243" _fill="none" _stroke="#fff" stroke-width="1.5"><circle pid="1" cx="22.848" cy="22.848" r="22.848" _stroke="none"/><circle pid="2" cx="22.848" cy="22.848" r="22.098"/></g></g>'
  }
})
