<template>
  <div class="member-answer-component" v-if="isListBased">
    <div class="answer-component answer-component--member">
      <percentage-bar
        :percentage="percentage"
        v-if="methodTemplate !== 'indicator'"
      ></percentage-bar>
      <list-chart
        :answers="filteredIndividualStats"
        :individual="true"
        :isIndicator="methodTemplate === 'indicator'"
      ></list-chart>
    </div>
    <list-chart-filter
      @checkStatus="checkStatus"
      :percentage="percentage"
      v-if="methodTemplate !== 'indicator'"
    ></list-chart-filter>
  </div>
  <div class="member-answer-component" v-else>
    <incomplete-sentence
      v-if="methodTemplate === 'incomplete_sentence'"
      :answers="filteredIndividualStats"
      :question="question"
      :answerList="answerList"
      @addSentenceValue="addSentenceValue"
    ></incomplete-sentence>
    <question-method-list
      v-else-if="methodTemplate === 'question' && methodAnswerTemplate === 'text'"
      :answers="filteredIndividualStats"
      :question="question"
      :answerList="answerList"
      @addSentenceValue="addSentenceValue"
    ></question-method-list>
    <pie-method-list
      v-else-if="methodTemplate === 'pie'"
      :answers="filteredIndividualStats"
      :question="question"
      :answerList="answerList"
      @addSentenceValue="addSentenceValue"
    ></pie-method-list>
  </div>
</template>

<script>
  import ListChart from '@/components/stats/chart-templates/ListChart.vue';
  import PercentageBar from '@/components/stats/chart-templates/PercentageBar.vue';
  import ListChartFilter from '@/components/stats/chart-templates/ListChartFilter.vue';
  import IncompleteSentence from '@/components/stats/chart-templates/IncompleteSentence.vue';
  import QuestionMethodList from '@/components/stats/chart-templates/QuestionMethodList.vue';
  import PieMethodList from '@/components/stats/chart-templates/PieMethodList.vue';

  export default {
    name: 'StatComponentsIndividual',
    components: {
      ListChart,
      PercentageBar,
      ListChartFilter,
      IncompleteSentence,
      QuestionMethodList,
      PieMethodList,
    },
    props: ['question', 'groupIndividualStats', 'questionMethod', 'distinctAnswers'],
    data() {
      return {
        filterTerms: [],
      };
    },
    computed: {
      filteredIndividualStats() {
        if (!this.groupIndividualStats.length) return false;
        return this.groupIndividualStats.filter((stat) => !this.filterTerms.includes(stat.value));
      },
      percentage() {
        if (!this.groupIndividualStats.length) return false;
        const occurances = {};
        this.groupIndividualStats.forEach((stat) => {
          const amount = this.groupIndividualStats.filter((item) => item.value === stat.value);
          occurances[stat.value] = { count: amount.length, color: stat.color };
        });
        const statArray = Object.entries(occurances).map(([key, value]) => {
          return {
            value: key,
            count: value.count,
            color: value.color,
          };
        });

        const total = statArray.reduce((acc, value) => acc + value.count, 0);

        return statArray.map((stat) => ({
          ...stat,
          percentage: Math.floor((stat.count / total) * 100),
        }));
      },
      methodTemplate() {
        return this.questionMethod.template;
      },
      methodAnswerTemplate() {
        return this.question.answerTemplate;
      },
      isListBased() {
        switch (this.methodTemplate) {
          case 'incomplete_sentence':
            return false;
          case 'question':
            return this.methodAnswerTemplate !== 'text';
          case 'pie':
            return false;
          default:
            return true;
        }
      },
      answerList() {
        return this.distinctAnswers[this.question.id] || [];
      },
    },
    methods: {
      checkStatus({ value, checked }) {
        if (checked) {
          const termIndex = this.filterTerms.findIndex((term) => term === value);
          this.filterTerms.splice(termIndex, 1);
        } else {
          this.filterTerms.push(value);
        }
      },
      addSentenceValue(value) {
        if (this.answerList && this.answerList.find((answer) => answer === value) === undefined) {
          if (value !== '') {
            this.answerList.push(value);
          }
        }
      },
    },
  };
</script>
