<template>
  <label
    :class="[
      'toggle__switch',
      { 'toggle__switch--disabled': disabled },
      { 'toggle__switch--disabled-positive': disabledPositive },
    ]"
    :for="`${boxValue}-${uniqueId}`"
  >
    <input
      type="checkbox"
      :id="`${boxValue}-${uniqueId}`"
      v-model="toggled"
      @change="emitValue"
      :disabled="disabled || disabledPositive"
    />
    <span class="toggle__slider"></span>
  </label>
</template>
<script>
  export default {
    name: 'Toggle',
    props: ['checked', 'boxValue', 'disabled', 'disabledPositive'],
    data() {
      return {
        toggled: null,
        uniqueId: null,
      };
    },
    created() {
      this.toggled = this.checked;
      this.uniqueId = Math.random() * 1000;
    },
    methods: {
      emitValue() {
        this.$emit('checkStatus', { checked: this.toggled, value: this.boxValue });
      },
    },
  };
</script>
<style lang="scss">
  .toggle__switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 14px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
    }
    .toggle__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;
      &:before {
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        left: -3px;
        top: 0;
        background-color: #fff;
        transition: 0.4s;
        border-radius: 50%;
        border: 1px solid #919396;
      }

      &:hover {
        cursor: pointer;
      }
    }
    input:checked + .toggle__slider {
      background-color: $green-3;
    }
    input:focus + .toggle__slider {
      box-shadow: 0 0 1px $green-3;
    }
    input:checked + .toggle__slider:before {
      transform: translateX(20px);
      border: 1px solid $green-3;
    }

    &--disabled,
    &--disabled-positive {
      .toggle__slider {
        background-color: $grey-2;

        &:before {
          border: 1px solid $grey-2;
          background-color: $grey-1;
        }
      }
    }

    &--disabled-positive {
      .toggle__slider {
        &:before {
          left: 18px;
        }
      }
    }
  }
  .registration-toggle {
    .toggle__switch {
      width: 24px;
      .toggle__slider {
        background-color: #fff;
        border: 2px solid $grey-3;
        &:before {
          width: 8px;
          height: 8px;
          left: 1px;
          top: 1px;
          border: none;
          background-color: $grey-3;
        }
      }
      input:checked + .toggle__slider {
        border: 2px solid $green-3;
        &:before {
          transform: translateX(10px);
          border: none;
          background-color: #fff;
        }
      }
    }
  }
  .profile-settings-toggle-wrapper {
    .toggle__switch {
      margin-right: 10px;
    }
    > span {
      a {
        text-decoration: underline;
      }
    }
  }
</style>
