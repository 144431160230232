<template>
  <div class="sentence-chart">
    <pie-method-list-item
      v-for="(answer, index) in answers"
      :key="answer.answerId || index"
      :answer="answer"
      :question="question"
      :answerList="answerList"
      @addValue="addValue"
      :handleNavigation="handleNavigation"
    ></pie-method-list-item>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import PieMethodListItem from '@/components/stats/chart-templates/PieMethodListItem.vue';

  export default {
    name: 'PieMethodList',
    components: {
      PieMethodListItem,
    },
    props: ['answers', 'question', 'answerList', 'addSentenceValue'],
    computed: {
      ...mapGetters('reflections', ['currentReflection']),
      routeParams() {
        return this.$route.params;
      },
    },
    methods: {
      ...mapActions('stats', ['setType', 'toggleView']),
      addValue(value) {
        this.$emit('addSentenceValue', value);
      },
      handleNavigation(memberId) {
        const params = {
          groupId: this.routeParams.groupId,
          memberId: parseInt(memberId, 10),
          questionnaireId: this.routeParams.questionnaireId,
          reflectionId: this.currentReflection.id,
        };
        this.toggleView('list');
        this.setType('inTime');
        this.$router.push({ name: 'GroupMemberStats', params });
      },
    },
  };
</script>

<style lang="scss">
  .sentence-chart {
    margin-top: 20px;
    width: 100%;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px 0;

      &__left {
        display: flex;
        align-self: flex-start;

        &__icon {
          height: 22px;
          width: 22px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey-2;
          color: #fff;
          font-size: 12px;
        }
      }

      &__middle {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        &__member {
          margin-bottom: 10px;
          h4 {
            cursor: pointer;
            &:hover {
              font-weight: bold;
              text-decoration: underline;
              color: $green-4;
            }
          }
        }

        &__question {
          max-width: 550px;
          word-break: break-word;
        }
      }

      &__right {
        display: flex;
        margin-left: auto;
        &.pie-item {
          margin: 48px 0 auto auto;
        }
        .answer-list-dropdown {
          position: absolute;
          width: 177px;
          z-index: 3;
          border: 1px solid $grey-2;
          background: #fff;
          span {
            display: block;
            padding: 5px 8px;
            font-family: 'Roboto-Regular';
            font-size: 14px;
            color: $grey-5;
            cursor: pointer;
            &:hover {
              background: $grey-6;
            }
          }
        }
      }

      &__answer {
        color: $green-3;
        font-size: 14px;
        font-family: Roboto-Medium;

        &--null {
          color: $grey-4;
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 840px;
        background-color: $grey-6;
        transform: translateX(-20px);
      }

      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
  }
</style>
