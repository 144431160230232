var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav"},[_c('div',{staticClass:"nav__container"},[_c('div',{class:['nav__logo', { 'nav__logo--login': !_vm.token }]},[_c('div',{staticClass:"nav__logo__link",on:{"click":function($event){return _vm.handleNavigation('Home')}}},[_c('svgicon',{attrs:{"name":"app-logo-navigation","original":true,"height":"44px"}})],1)]),(_vm.token)?_c('ul',{staticClass:"nav__items"},[_c('li',{staticClass:"nav__item"},[_c('p',{class:['nav__item__link', { 'nav__item__link--active': _vm.$route.name === 'Home' }],on:{"click":function($event){return _vm.handleNavigation('Home')}}},[_c('svgicon',{attrs:{"name":"house","original":true,"height":"2em"}})],1)]),_c('li',{staticClass:"nav__item"},[_c('p',{class:['nav__item__link', { 'nav__item__link--active': _vm.$route.name === 'MyGroups' }],attrs:{"to":{ name: 'MyGroups' }},on:{"click":function($event){return _vm.handleNavigation('MyGroups')}}},[_vm._v(" "+_vm._s(_vm.$t('my_groups'))+" ")])]),_c('li',{staticClass:"nav__item"},[_c('p',{class:[
          'nav__item__link',
          {
            'nav__item__link--active':
              _vm.$route.name === 'TemplatesDraft' || _vm.$route.name === 'EditReflection',
          } ],on:{"click":function($event){return _vm.handleNavigation('TemplatesDraft')}}},[_vm._v(" "+_vm._s(_vm.$t('my_questionnaires'))+" ")])]),_c('li',{staticClass:"nav__item"},[_c('p',{class:[
          'nav__item__link',
          {
            'nav__item__link--active':
              _vm.$route.name === 'Templates' || _vm.$route.name === 'EditTemplate',
          } ],on:{"click":function($event){return _vm.handleNavigation('Templates')}}},[_vm._v(" "+_vm._s(_vm.$t('questionnaire_templates'))+" ")])]),_c('li',{staticClass:"nav__item"},[_c('p',{class:['nav__item__link', { 'nav__item__link--active': _vm.$route.name === 'Lessons' }],on:{"click":function($event){return _vm.handleNavigation('Lessons')}}},[_vm._v(" "+_vm._s(_vm.$t('tutorials'))+" ")])]),_c('li',{staticClass:"nav__item"},[_c('p',{class:['nav__item__link', { 'nav__item__link--active': _vm.$route.name === 'Faq' }],on:{"click":function($event){return _vm.handleNavigation('Faq')}}},[_vm._v(" "+_vm._s(_vm.$t('help'))+" ")])])]):_vm._e(),(_vm.token)?_c('menu-dropdown',{key:_vm.navigationKey}):_vm._e(),(!_vm.token)?_c('menu-dropdown-language'):_vm._e(),(_vm.token)?_c('div',{staticClass:"license-expiration-date-info-wrapper"},[_c('span',{staticClass:"expiration-title"},[_vm._v(_vm._s(_vm.$t('license_end_title'))+":")]),_c('span',{staticClass:"expiration-date"},[_vm._v(_vm._s(_vm.licenseExpirationDate))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }