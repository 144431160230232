<template>
  <div class="options__form-container reflection-options">
    <div class="reflection-options__header" v-if="groupList.length">
      <span class="reflection-options__option-title">
        {{ $t("schedule.target_group") }}
        <span
          class="reflection-options__option-title--details"
        >({{ $t("schedule.target_group_explanation") }})</span>
      </span>
      <multi-select
        :options="groupList"
        :width="578"
        :placeholder='$t("group.select_group_placeholder")'
        :selected="groupList.find(group => parseInt(group.id) === questionnaire.clientGroupId)"
        @selectedValue="groupSelectHandler"
        :id="'class'"
        :class="['schedule-group-select', { 'no-group-error': !scheduleGroup && scheduleErrorCheck }]"
      ></multi-select>
      <p class="schedule-error-message" v-if="!scheduleGroup && scheduleErrorCheck">{{ $t("group.select_group_warning_message") }}</p>
    </div>
    <options-schedule-old v-for="(schedule, i) in schedules" :key="`schedule--${i}`" :i="i" :isScheduleChecked="scheduleErrorCheck" @handleScheduleRemove="handleScheduleRemove"></options-schedule-old>
    <div class="modal" @click="handleModalToggle" v-if="removeOptionModal">
      <div class="modal__bg">
        <div class="modal__modal" @click.stop>
          <div class="modal__container">
            <svgicon
              @click="handleModalToggle"
              color="grey"
              :fill="false"
              name="multiply"
              class="modal__close"
            ></svgicon>
            <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
            <h4 class="modal__container__heading">{{ $t("modals.delete_option_question") }}</h4>
            <div class="modal__container__actions">
              <button-4 @click.native="removeScheduleOption">{{ $t("yes_cap") }}</button-4>
              <button-3 @click.native="handleModalToggle">{{ $t("no_cap") }}</button-3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { lt } from 'vuejs-datepicker/dist/locale';

import MultiSelect from '@/components/elements/select/MultiSelect.vue';
import OptionsScheduleOld from '@/components/reflections/options/OptionsScheduleOld.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'OptionsFormOld',
  components: {
    MultiSelect,
    OptionsScheduleOld,
    Button3,
    Button4
  },
  props: ['groupList'],
  mounted() {
    if (this.schedules.length === 0) this.addSchedule();
  },
  data() {
    return {
      language: lt,
      values: {
        days: [],
        start: {},
        skip: {},
      },
      reflectionDuration: ['Minučių', 'Valandų', 'Dienų'],
      reflectionRepeat: ['Vieną kartą', 'Kasdien', 'Skirtingomis dienomis'],
      weekStep: [1, 2, 3, 4],
      weekly: false,
      biWeekly: false,
      removeOptionModal: false,
      optionPayload: {i: null, id: null}
    };
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire', 'schedules', 'scheduleErrorCheck']),
    scheduleGroup(){
      return this.questionnaire.clientGroupId;
    }
  },
  methods: {
    ...mapActions('questionnaire', ['addSchedule', 'removeSchedule', 'setQuestionnaireProperty']),
    openPicker() {
      this.$refs.dateStart.showCalendar();
    },
    groupSelectHandler(value) {
      const clientGroupId = parseInt(value.id, 10);
      const payload = {
        type: 'clientGroupId',
        property: clientGroupId,
      };
      this.setQuestionnaireProperty(payload);
    },
    handleModalToggle() {
      this.removeOptionModal = false;
    },
    handleScheduleRemove({i, id}) {
      this.removeOptionModal = true;
      this.optionPayload = {i, id};
    },
    removeScheduleOption() {
      this.removeSchedule(this.optionPayload);
      this.handleModalToggle();
    }
  },
};
</script>
<style lang="scss">
// .reflection-options {
//   margin-top: 10px;
//   max-width: 620px;
//   display: flex;
//   flex-direction: column;

//   margin: 0 auto;

//   &__header {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     background-color: #fff;

//     height: 90px;
//     padding: 20px;
//     margin-bottom: 8px;
//     .schedule-group-select{
//       &.no-group-error{
//         .multiselect__tags{
//           border: 1px solid $red-4;
//         }
//       }
//     }
//   }

//   &__option-title {
//     font-family: Nunito-Bold;
//     font-size: 10px;
//     text-transform: uppercase;
//     color: $grey-3;
//     margin-bottom: 6px;

//     &--details {
//       font-family: Nunito-Regular;
//       text-transform: initial;
//       margin-left: 5px;
//     }

//     &--delete {
//       margin-bottom: 0;
//     }

//     &--custom {
//       margin: 0 0 0 10px;
//       align-self: center;
//     }
//   }

//   &__option {
//     width: 100%;
//     padding: 20px;
//     border-left: 2px solid $green-2;
//     background-color: #fff;
//     margin-bottom: 8px;
//   }

//   &__option-header {
//     position: relative;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   &__option-date {
//     font-family: Nunito-SemiBold;
//     font-size: 14px;
//     color: $green-3;
//   }

//   &__delete {
//     display: flex;
//     align-items: center;

//     &__icon {
//       margin-right: 5px;
//       height: 27px;
//       width: 27px;
//       transition: all 0.3s;

//       &:hover {
//         cursor: pointer;
//         transform: translateY(-3px) scale(1.25);
//       }
//     }
//   }

//   &__row {
//     display: flex;
//     align-items: center;
//     margin-top: 14px;
//   }

//   &__col {
//     display: flex;
//     flex-direction: column;
//     align-self: flex-start;

//     &:not(:first-of-type) {
//       margin-left: 20px;
//     }

//     &--offset {
//       margin-left: 200px;
//     }
//   }

//   &__inputs {
//     display: flex;
//     position: relative;

//     .vdp-datepicker {
//       width: 180px;
//       height: 30px;

//       > div {
//         input {
//           width: 180px;
//           height: 30px;
//           color: $grey-5;
//           border: 1px solid $grey-2;
//           font-family: Roboto-Regular;
//           font-size: 14px;
//           padding-left: 5px;

//           &::placeholder {
//             color: $grey-4;
//           }
//         }
//       }
//     }

//     .multiselect {
//       margin-left: 5px;
//     }
//     &--weeks {
//       .multiselect {
//         margin: 0;
//       }
//     }
//     &--skip {
//       &:not(:first-of-type) {
//         margin-top: 10px;
//       }
//     }

//     &__remove {
//       position: absolute;
//       top: 6px;
//       right: 6px;
//       height: 16px;
//       width: 16px;
//       z-index: 1;
//       cursor: pointer;
//       transition: all 0.3s;

//       &:hover {
//         transform: translateY(-3px) scale(1.15);
//       }
//     }
//   }

//   &__input {
//     height: 30px;
//     color: $grey-5;
//     border: 1px solid $grey-2;
//     font-family: Roboto-Regular;
//     font-size: 14px;
//     padding-left: 5px;

//     &::placeholder {
//       color: $grey-4;
//     }

//     &--small {
//       width: 94px;
//     }

//     &--large {
//       width: 180px;
//     }

//     &__time-picker {
//       border: none;
//       background: transparent;
//       width: 100%;
//     }

//     &--time-picker {
//       margin-right: 5px;
//       width: 105px;
//       display: flex;
//       align-items: center;
//       font-family: Roboto-Regular;
//       font-size: 14px;
//       color: $grey-5;
//     }
//   }

//   .week-days {
//     display: flex;
//     height: 30px;
//     .day {
//       align-items: center;
//       display: flex;
//       justify-content: center;
//       font-family: Roboto-Regular;
//       color: $grey-5;
//       font-size: 14px;
//       height: 24px;
//       width: 24px;
//       border-radius: 50%;
//       background-color: $grey-1;
//       cursor: pointer;

//       &:not(:first-of-type) {
//         margin-left: 2px;
//       }

//       &:hover,
//       &--active {
//         background-color: $green-3;
//         color: #fff;
//       }

//       &--error {
//         border: 1px solid $red-4;
//       }
//     }
//   }
//   .schedule-error-message{
//     font-size: 14px;
//     color: $red-4;
//   }
// }
</style>
