/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-search-2': {
    width: 80.208,
    height: 57.855,
    viewBox: '0 0 80.208 57.855',
    data: '<g data-name="Group 3923" transform="translate(-475.027 -99.19)"><ellipse pid="0" data-name="Ellipse 1631" cx="16.38" cy="16.38" rx="16.38" ry="16.38" transform="translate(495.654 103.657)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="1.5"/><ellipse pid="1" data-name="Ellipse 1632" cx="3.061" cy="3.061" rx="3.061" ry="3.061" transform="translate(538.315 99.69)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10"/><ellipse pid="2" data-name="Ellipse 1633" cx="2.275" cy="2.275" rx="2.275" ry="2.275" transform="translate(530.193 146.437)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10"/><ellipse pid="3" data-name="Ellipse 1634" cx="4.176" cy="4.176" rx="4.176" ry="4.176" transform="translate(546.384 127.572)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10"/><ellipse pid="4" data-name="Ellipse 1635" cx="2.903" cy="2.903" rx="2.903" ry="2.903" transform="translate(475.743 112.589)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10"/><ellipse pid="5" data-name="Ellipse 1636" cx="18.891" cy="18.891" rx="18.891" ry="18.891" transform="translate(493.143 101.146)" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="1.5"/><path pid="6" data-name="Path 4014" d="M500.785 135.5l-20.049 20.049a2.855 2.855 0 0 1-4.026-.184h0a2.855 2.855 0 0 1-.184-4.026l20.049-20.049" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="1.5"/><path pid="7" data-name="Rectangle 5666" _fill="none" _stroke="#474eb9" stroke-miterlimit="10" stroke-width="1.5" d="M488.824 139.037l4.21 4.21-9.373 9.372-4.21-4.21z"/><ellipse pid="8" data-name="Ellipse 1637" cx="9.817" cy="9.817" rx="9.817" ry="9.817" transform="translate(502.217 110.22)" _fill="#474eb9"/><ellipse pid="9" data-name="Ellipse 1638" cx="3.001" cy="3.001" rx="3.001" ry="3.001" transform="translate(512.752 112.632)" _fill="#fff"/></g>'
  }
})
