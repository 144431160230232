/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '404/question-mark': {
    width: 7.883,
    height: 13.662,
    viewBox: '0 0 7.883 13.662',
    data: '<g data-name="Group 3910"><path pid="0" data-name="Path 3869" d="M6.507 11.699a1.015 1.015 0 0 0 .711 1.9 1.014 1.014 0 0 0-.711-1.9zM2.58.252A3.975 3.975 0 0 0 .252 5.363a1.014 1.014 0 0 0 1.9-.71 1.946 1.946 0 1 1 3.645-1.364c.212.567-.111 1.2-.668 2.194l-.025.04c-.578 1.019-1.229 2.173-.737 3.451a.989.989 0 0 0 1.29.565l.011-.005a.99.99 0 0 0 .589-1.286c-.129-.334.043-.742.593-1.715l.044-.074c.6-1.069 1.354-2.4.8-3.888A3.971 3.971 0 0 0 2.58.252z" _fill="#66c"/></g>'
  }
})
