/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'broken-pen': {
    width: 66.879,
    height: 48.447,
    viewBox: '0 0 66.879 48.447',
    data: '<g data-name="Group 966" _fill="none" _stroke="#474eb9" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Line 124" stroke-linecap="round" d="M35.427.75v10.418"/><path pid="1" data-name="Line 125" stroke-linecap="round" d="M46.998 3.894l-6.535 8.114"/><path pid="2" data-name="Line 126" stroke-linecap="round" d="M23.856 3.894l6.535 8.114"/><g data-name="Group 963"><path pid="3" data-name="Path 786" d="M50.602 43.705a1.077 1.077 0 0 0 .48.276l13.691 3.669a1.069 1.069 0 0 0 1.309-1.309l-3.668-13.692a1.074 1.074 0 0 0-.276-.479"/><path pid="4" data-name="Line 127" d="M62.124 32.157L50.589 43.692"/><path pid="5" data-name="Path 787" d="M40.933 24.203l-4.917 4.917 14.56 14.559 11.535-11.535-14.559-14.559.173 6.713-6.789-.1-4.919 4.919"/></g><g data-name="Group 964"><path pid="6" data-name="Line 128" d="M6.035 30.868L17.57 42.403"/><path pid="7" data-name="Path 788" d="M26.226 23.912l4.916 4.917-18.543 18.544a1.069 1.069 0 0 1-1.512 0L1.064 37.35a1.069 1.069 0 0 1 0-1.512l18.544-18.544 6.713-.172-.1 6.787 4.918 4.919"/></g></g>'
  }
})
