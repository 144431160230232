<template>
  <div class="group__reflection">
    <div class="reflection__main" @click="handleQuestionnaireReflection('GroupReflection')">
      <div class="reflection__image">
        <img v-if="questionnaire.icon" :src="questionnaire.icon" class="reflection__image__img" />
        <svgicon v-else name="methods/logo-grey" :original="true"></svgicon>
      </div>
      <div class="reflection__info">
        <div class="reflection__title">
          <header-h4>{{ questionnaire.title }}</header-h4>
        </div>
        <div class="reflection__details">
          <span>
            <svgicon name="repeat" :original="true" height="15px"></svgicon>
            {{ $t('group.group_reflection_sent_count') }}: {{ questionnaire.reflectionCount || 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="reflection__action" @click="handleQuestionnaireReflection('GroupReflection')">
      <svgicon name="R" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('group.group_reflections') }}</span>
    </div>
    <div class="reflection__action" @click="handleQuestionnaireReflection('GroupReflectionStats')">
      <svgicon
        name="line-chart"
        :original="true"
        height="20px"
        :fill="false"
        color="#3B328B"
      ></svgicon>
      <span>{{ $t('statistics_in_time.statistics_in_time') }}</span>
    </div>
    <router-link
      tag="div"
      :to="{ name: 'ReflectionOptions', params: { id: questionnaire.id } }"
      class="reflection__action"
    >
      <svgicon name="plane" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('group.group_schedule_settings') }}</span>
    </router-link>
    <router-link
      tag="div"
      :to="{ name: 'EditReflection', params: { id: questionnaire.id } }"
      class="reflection__action"
      @click.native="
        () => {
          toggleWarningModal();
          setEditStatus('edit');
        }
      "
    >
      <svgicon name="pen" :original="true" height="20px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t('actions.edit_questionnaire_action') }}</span>
    </router-link>
    <div class="reflection__action" @click="handleQuestionnaireMessages">
      <svgicon
        name="envelope"
        :original="true"
        height="20px"
        :fill="false"
        color="#3B328B"
      ></svgicon>
      <span>{{ $t('questionnaire_messages.for_group') }}</span>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

  export default {
    name: 'GroupReflection',
    components: {
      HeaderH4,
    },
    props: ['questionnaire', 'groupId'],
    methods: {
      ...mapActions('stats', ['setType']),
      ...mapActions('reflections', ['setEditStatus']),
      ...mapActions('meta', ['toggleWarningModal']),
      handleQuestionnaireReflection(name) {
        if (name === 'GroupReflectionStats') this.setType('inTime');
        this.$router.push({
          name,
          params: {
            groupId: this.groupId,
            questionnaireId: this.questionnaire.id,
            reflectionId: null,
          },
        });
      },
      handleQuestionnaireMessages() {
        this.setType('groupMessages');
        this.$router.push({
          name: 'GroupReflectionStats',
          params: {
            groupId: this.groupId,
            questionnaireId: this.questionnaire.id,
            reflectionId: null,
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import 'mixins';
  .group {
    &__reflection {
      display: flex;
      justify-content: space-between;
      &:not(:first-of-type) {
        margin-top: 12px;
      }
    }

    .reflection {
      &__main {
        display: flex;
        background-color: #fff;
        width: 440px;
        height: 110px;
      }

      &__main,
      &__action {
        @include handleHover();
      }

      &__image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 55px;

        svg,
        &__img {
          border-radius: 50%;
          height: 33px;
          width: 33px;
        }
      }

      &__info {
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        padding-right: 10px;

        h4 {
          font-size: 16px;
        }
      }

      &__details {
        margin-top: 15px;
        span {
          margin-right: 15px;
          font-family: Roboto-Regular;
          font-size: 14px;
          color: $grey-5;
          &:first-of-type {
            svg {
              margin-right: 5px;
            }
          }
        }
      }

      &__action {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        width: 94px;
        height: 110px;

        span {
          font-family: Roboto-Medium;
          font-size: 12px;
          color: $violet-5;
          padding: 0 20px;
          margin-top: 15px;
        }
      }
    }
  }
</style>
