/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 20.891,
    height: 19.627,
    viewBox: '0 0 20.891 19.627',
    data: '<g data-name="Group 1918"><g data-name="Group 1197" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round"><path pid="0" data-name="Path 911" d="M.5 10.446L10.445.501l9.945 9.945"/><path pid="1" data-name="Path 912" d="M17.644 11.013v8.116h-5.078V13.18H8.33v5.949H3.249v-8.116"/></g></g>'
  }
})
