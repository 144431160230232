/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 20.59 15.01',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="#fff" stroke-width=".75"><rect pid="0" x="3.17" y="-2.42" width="14.26" height="19.84" rx=".91" ry=".91" transform="rotate(90 10.295 7.505)" stroke-linejoin="round"/><path pid="1" d="M19.91.61l-9 7.9a.91.91 0 0 1-1.28 0L.68.6" stroke-miterlimit="10"/></g></g>'
  }
})
