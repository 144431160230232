/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/termometer': {
    width: 43.271,
    height: 43.271,
    viewBox: '0 0 43.271 43.271',
    data: '<g data-name="Group 1113" transform="translate(-376.924 -459.001)"><circle pid="0" data-name="Ellipse 168" cx="16.559" cy="16.559" r="16.559" transform="rotate(-22.5 1374.097 -711.625)" _fill="#6666ca"/><g data-name="Group 901" _fill="none" _stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width=".75"><path pid="1" data-name="Path 758" d="M401.477 483.173v-11.156a2.988 2.988 0 0 0-2.988-2.988h0a2.987 2.987 0 0 0-2.987 2.988v11.156a5.026 5.026 0 1 0 5.975 0z"/><path pid="2" data-name="Path 759" d="M399.969 484.29v-4.876a1.41 1.41 0 0 0-1.41-1.41h-.139a1.41 1.41 0 0 0-1.41 1.41v4.876a3.28 3.28 0 1 0 2.959 0z"/><path pid="3" data-name="Line 94" d="M396.758 474.402v2.429"/><path pid="4" data-name="Line 95" d="M396.758 472.978v.558"/><path pid="5" data-name="Line 96" d="M402.747 480.293h.907"/><path pid="6" data-name="Line 97" d="M402.747 473.369h.907"/><path pid="7" data-name="Line 98" d="M402.747 475.677h.907"/><path pid="8" data-name="Line 99" d="M402.747 477.985h.907"/></g></g>'
  }
})
