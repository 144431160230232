<template>
  <div class="chart-arithmetic-values-wrapper">
    <div class="arith-value average">
      <p class="label">{{ $t("average") }}</p>
      <p class="value">{{average}}</p>
    </div>

    <div class="arith-value median">
      <p class="label">{{ $t("median") }}</p>
      <p class="value">{{median}}</p>
    </div>

    <div class="arith-value mode">
      <p class="label">{{ $t("mode") }}</p>
      <p class="value">{{mode.toString()}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartArithValues',
  props: ['average', 'median', 'mode'],
};
</script>

<style lang="scss" scoped>
.chart-arithmetic-values-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 69px;
  margin-bottom: 20px;
  .arith-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 25px;
    background: #ebebeb;
    &:first-child {
      border-radius: 5px 0 0 5px;
      margin-right: 2px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
      margin-left: 2px;
    }
    .label {
      font-size: 14px;
      color: #505e67;
    }
    .value {
      color: #6666ca;
      font-size: 15px;
      font-weight: bold;
    }
    .mode-value-holder {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
