/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-blush': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" d="M7.88 14.73a.44.44 0 0 0-.72.48 6.69 6.69 0 0 0 12.38.12.44.44 0 0 0-.71-.49c-1.95 1.9-6.1 4.6-10.95-.11z" _fill="#654735"/><path pid="4" d="M16.8 10.43a2.47 2.47 0 0 0 4.58 0 .4.4 0 0 0-.64-.44 2.12 2.12 0 0 1-3.29 0 .4.4 0 0 0-.65.43zM5.33 10.43a2.47 2.47 0 0 0 4.57 0 .4.4 0 0 0-.64-.43A2.12 2.12 0 0 1 6 10a.4.4 0 0 0-.65.43z" _fill="#2c2c2c"/><circle pid="5" cx="3.54" cy="15.21" r="2.33" _fill="#ffa6ad"/><circle pid="6" cx="23.17" cy="15.21" r="2.33" _fill="#ffa6ad"/></g></g>'
  }
})
