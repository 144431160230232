<template>
  <div class="percentage-bar">
    <div
      class="percentage-bar__section"
      v-for="percent in percentage"
      :key="percent.value"
      :style="{ width: `${percent.percentage}%` }"
      v-tooltip="percent.value"
    >
      <div class="percentage-bar__section__value" :style="{ color: percent.color }">
        {{ percent.percentage }}%
      </div>
      <div
        class="percentage-bar__section__bar"
        :style="{ 'background-color': percent.color }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentageBar',
  props: ['percentage'],
};
</script>

<style lang="scss">
.percentage-bar {
  max-width: 290px;
  display: flex;
  margin-left: 100px;

  &__section {
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin-right: 4px;
    }

    &__value {
      font-family: Roboto-Medium;
      font-size: 14px;
      text-align: center;
    }

    &__bar {
      height: 16px;
    }
  }
}
</style>