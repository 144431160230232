/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 14.208,
    height: 1.5,
    viewBox: '0 0 14.208 1.5',
    data: '<g data-name="Group 1955"><g data-name="Group 83" _fill="none" _stroke="#3b328b" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 32" d="M7.104.75H.75"/><path pid="1" data-name="Path 33" d="M7.104.751h6.354"/></g></g>'
  }
})
