/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-crying': {
    width: 16,
    height: 16,
    viewBox: '0 0 38.07 38.07',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="19.04" cy="19.04" r="19.04" transform="rotate(-.12 19.079 19.119)" _fill="#ffd053"/><path pid="1" d="M29 2.77A19 19 0 0 1 3 29.3 19 19 0 1 0 29 2.77z" _fill="#ffc448"/><path pid="2" d="M2.59 21.08a17.67 17.67 0 0 1 31.66-10.84A17.67 17.67 0 1 0 5 29.88a17.59 17.59 0 0 1-2.41-8.8z" _fill="#ffd77a"/><ellipse pid="3" cx="19.04" cy="23.57" rx="3.44" ry="5.83" _fill="#71523f"/><path pid="4" d="M21.94 26.7a5.28 5.28 0 0 0-5.73.19c.62 1.52 1.66 2.52 2.83 2.52s2.29-1.09 2.9-2.71z" _fill="#ff6669"/><path pid="5" d="M13.15 15.25l-5 3.19.12 16.29a19 19 0 0 0 4.89 2.41zM24.93 15.25l5 3.19-.12 16.29a19 19 0 0 1-4.89 2.41z" _fill="#47c4e9"/><path pid="6" d="M8.77 19a.81.81 0 0 1-.5-1.44L11 15.42l-2.82-1.67A.81.81 0 0 1 9 12.36l3.86 2.27a.81.81 0 0 1 .09 1.37l-3.69 2.84a.8.8 0 0 1-.49.16zM29.3 19a.8.8 0 0 1-.5-.17L25.13 16a.81.81 0 0 1 .09-1.33l3.86-2.27a.81.81 0 0 1 .82 1.39l-2.84 1.67 2.75 2.14a.81.81 0 0 1-.51 1.4z" _fill="#2c2c2c"/></g></g>'
  }
})
