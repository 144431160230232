/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 18.667,
    height: 21,
    viewBox: '0 0 18.667 21',
    data: '<g data-name="Group 2051"><g data-name="Group 2035" transform="translate(-119 -373)"><circle pid="0" data-name="Ellipse 838" cx="3.854" cy="3.854" r="3.854" transform="translate(119 379.646)" _fill="#3b328b"/><g data-name="Group 2033" transform="translate(129.958 373)" _fill="#3b328b"><circle pid="1" data-name="Ellipse 839" cx="3.854" cy="3.854" r="3.854"/><circle pid="2" data-name="Ellipse 840" cx="3.854" cy="3.854" r="3.854" transform="translate(0 13.291)"/></g><path pid="3" data-name="Line 1200" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.5" d="M133.812 376.298l-10.958 7.763"/><path pid="4" data-name="Line 1201" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.5" d="M133.812 390.703l-10.958-7.763"/></g></g>'
  }
})
