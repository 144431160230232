/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/question-text': {
    width: 16,
    height: 16,
    viewBox: '0 0 33.127 33.127',
    data: '<circle pid="0" cx="16.563" cy="16.563" r="16.559" _fill="#6666ca"/><path pid="1" _fill="#fff" d="M23.617 11.11H9.904a1.118 1.118 0 1 1 0-2.235h13.713a1.118 1.118 0 1 1 0 2.235zm-6.484 2.162c0-.618-.5-1.118-1.118-1.118h-6.11a1.118 1.118 0 1 0 0 2.236h6.11c.618 0 1.118-.5 1.118-1.118zm7.602 3.278c0-.617-.5-1.118-1.118-1.118H9.904a1.118 1.118 0 1 0 0 2.236h13.713c.618 0 1.118-.5 1.118-1.118zm-7.602 3.28c0-.618-.5-1.118-1.118-1.118h-6.11a1.118 1.118 0 1 0 0 2.235h6.11c.618 0 1.118-.5 1.118-1.118zm7.602 3.304c0-.617-.5-1.118-1.118-1.118H9.904a1.118 1.118 0 1 0 0 2.236h13.713c.618 0 1.118-.5 1.118-1.118z"/>'
  }
})
