/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'green-arrow': {
    width: 7.947,
    height: 14.393,
    viewBox: '0 0 7.947 14.393',
    data: '<path pid="0" data-name="Path 834" d="M7.197 13.643L.75 7.196 7.197.75" _fill="none" _stroke="#0f9f8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>'
  }
})
