<template>
  <div
    :class="[
      'question',
      'question--empty',
      { 'question--expanded': questionExpanded || currentView === 'tabs', 'question--list': currentView === 'list' },
    ]"
  >
    <div class="question__header" v-if="!questionExpanded && currentView !== 'tabs'">
      <div class="question__top question-item">
        <span class="question-item__number">{{
          currentView === 'list' ? questions.length + 1 : null
        }}</span>
        <svgicon
          class="question-item__icon"
          :name="'plus'"
          :original="true"
          width="2em"
          @click="onMethodUnset"
        ></svgicon>
        <div class="question-item__question">
          <header-h2 class="question-item__title">{{ $t("questionnaire.new_method_message") }}</header-h2>
          <header-h4>{{ $t("questionnaire.new_method_message_exp_list_view") }}</header-h4>
        </div>
      </div>
    </div>
    <div class="question-item__question draft-question" v-if="questionExpanded || currentView === 'tabs'">
      <svgicon
        class="question-item__icon"
        :name="'plus'"
        :original="true"
        width="2em"
        @click="onMethodUnset"
      ></svgicon>
      <div class="draft-question-headers">
        <header-h2 class="question-item__title">{{ $t("questionnaire.new_method_message") }}</header-h2>
        <header-h4>{{ $t("questionnaire.new_method_message_exp") }}</header-h4>
      </div>
    </div>
    <svgicon
      v-if="currentView === 'list'"
      :class="['question__arrow', { 'question__arrow--expanded': questionExpanded }]"
      name="green-arrow"
      :original="true"
      height="16px"
      width="16px"
      color="#3B328B"
      :fill="false"
      @click="expandAnswer"
    ></svgicon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

export default {
  name: 'CreateQuestion',
  components: {
    HeaderH2,
    HeaderH4,
  },
  props: ['expanded', 'type'],
  data() {
    return {
      questionExpanded: false,
    };
  },
  watch: {
    expanded(newVal) {
      this.questionExpanded = newVal;
    },
  },
  computed: {
    ...mapGetters('questionnaire', ['questions', 'currentView']),
  },
  methods: {
    ...mapActions('methods', ['onMethodUnset']),
    expandAnswer() {
      this.questionExpanded = !this.questionExpanded;
      if (this.questionExpanded) {
        this.onMethodUnset();
      }
    },
  },
};
</script>

<style lang="scss">
.question {
  &__header {
    align-self: flex-start;
    .question-item {
      &__icon {
        cursor: pointer;
      }
    }
  }

  &--empty {
    padding-left: 34px;
    .question__header {
      .question-item {
        &__title {
          color: $grey-3;
        }

        &__question {
          h4 {
            color: $grey-4;
          }
        }
      }
    }

    .draft-question{
      display: flex;
      svg{
        cursor: pointer;
      }
      .draft-question-headers{
        margin-left: 10px;
        text-align: left;
      }
    }
    &.question--expanded {
      height: 175px;
      justify-content: center;
      .question-item__question {
        text-align: center;
        .question-item__title {
          color: $grey-3;
        }

        h4 {
          color: $grey-4;
        }
      }
    }
  }

  &--list {
    &:hover {
      border: 1px solid #6666ca;
    }
  }
}
</style>
