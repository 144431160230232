<template>
  <div class="sentence-chart__item">
    <div class="sentence-chart__item__left">
      <div class="sentence-chart__item__left__icon">{{ nameIcon }}</div>
    </div>
    <div class="sentence-chart__item__middle">
      <div class="sentence-chart__item__middle__member">
        <h4 @click="handleNavigation(answer.clientId)">
          {{ `${answer.name} ${answer.lastName}` }}
        </h4>
      </div>
      <div v-if="noReflection" class="sentence-chart__item__middle__question">
        <span class="sentence-chart__item__answer sentence-chart__item__answer--null">
          {{ noReflection }}
        </span>
      </div>
      <div v-else class="sentence-chart__item__middle__question">
        <div class="pie-list-chart">
          <ul class="pie-list-chart__list">
            <li
              v-for="(answer, index) in originalAnswers"
              :key="index"
              class="pie-list-chart__list__item"
            >
              <div class="pie-list-chart__list__item__name-container">
                <span class="pie-list-chart__list__item__name">{{ answer.title }}</span>
              </div>
              <span class="pie-list-chart__list__item__value">{{ answer.percent }}%</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sentence-chart__item__right pie-item">
      <div class="set-stat-value">
        <input
          v-if="!answer.noReflection && !answer.skipped"
          class="input-box"
          type="text"
          :placeholder="$t('insert_value_placeholder_2')"
          v-model="currentValue"
          v-debounce:500.lock="handleValueAssign"
          :debounce-events="'blur'"
          @focus="toggleDropdown(true)"
          @blur="toggleDropdown(false)"
        />
        <div class="answer-list-dropdown" v-if="isDropdown">
          <span
            v-for="(answer, index) in answerList"
            :key="index"
            @click="handleValueAssign(answer)"
          >
            {{ answer }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'PieMethodListItem',
    props: ['answer', 'question', 'answerList', 'addValue', 'handleNavigation'],
    data() {
      return {
        currentValue: this.answer.value,
        isDropdown: false,
      };
    },
    computed: {
      nameIcon() {
        const name = this.answer.name.charAt(0).toUpperCase();
        const lastName = this.answer.lastName.charAt(0).toUpperCase();
        return `${name}${lastName}`;
      },
      originalAnswers() {
        const { original } = this.answer;
        return original;
      },
      noReflection() {
        const { noReflection, skipped, original, value } = this.answer;
        if (skipped) return original;
        if (noReflection) return value;
        return false;
      },
      computedAnswer() {
        return this.answer;
      },
    },
    methods: {
      ...mapActions('stats', ['setIndividualAnswer']),
      async handleValueAssign(value) {
        const { answerId } = this.answer;
        const payload = {
          id: answerId,
          answer: {
            value: value || null,
          },
        };

        this.currentValue = value;
        this.computedAnswer.value = value;
        await this.setIndividualAnswer(payload);
        this.toggleDropdown(false);
        this.$emit('addValue', value);
      },
      toggleDropdown(value) {
        setTimeout(() => {
          this.isDropdown = value;
        }, 200);
      },
    },
  };
</script>
<style scoped lang="scss">
  .pie-list-chart {
    width: 438px;
    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-inline-start: 0;

      &__item {
        padding: 8px 15px 8px 8px;
        background-color: #e5e6e6;
        display: flex;
        align-items: center;

        &:nth-of-type(2n) {
          background-color: $grey-6;
        }

        &__value,
        &__date,
        &__name {
          font-family: Roboto-Regular;
          font-size: 14px;
        }

        &__value {
          margin-left: auto;
          text-align: right;
          word-break: break-word;
          font-weight: 600;
          letter-spacing: 0.5px;
          color: $green-3;
        }

        &__name {
          min-width: 40px;
          color: $grey-5;
        }

        &__name-container {
          display: flex;
          align-items: center;
          width: auto;

          &__icon {
            min-height: 22px;
            min-width: 22px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-2;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
