<template>
  <div class="template-actions-wrapper">
    <div class="template-actions-wrapper-inner flex-global">
      <div class="flex-global">
        <div class="template__search__search-bar">
          <input
            type="text"
            :placeholder="$t('questionnaire_search_placeholder')"
            :value="templateSearch"
            v-debounce:1000="handleTemplateSearch"
          />
          <svgicon
            name="search"
            :original="true"
            height="1em"
            :fill="false"
            :color="'#0F9F8F'"
          ></svgicon>
        </div>
        <div class="filter-switch-wrapper">
          <div
            class="filter-switch flex-global"
            :class="{ active: isFilterOpen }"
            @click="toggleFilter"
          >
            <svgicon
              name="filter-icon"
              :original="true"
              height="14px"
              width="18px"
              :fill="false"
            ></svgicon>
            <span>{{ $t('templates_filter.filter_title') }}</span>
            <svgicon
              class="arrow-icon"
              name="green-arrow"
              width="12px"
              height="12px"
              color="#5147a7"
              :stroke="true"
              :fill="false"
            ></svgicon>
          </div>
        </div>
      </div>
      <div class="flex-global">
        <div class="sort-wrapper flex-global">
          <span>{{ $t('templates_filter.sort_title') }}:</span>
          <multi-select
            :options="sortOptions"
            :id="`template-sort`"
            :class="`template-sort-select`"
            @selectedValue="handleSortSelect"
            :selected="currentSortValue || defaultSortValue"
          ></multi-select>
        </div>
      </div>
    </div>
    <div class="filter-body-wrapper" v-show="isFilterOpen">
      <template-filter
        :filterData="questionnaireFilterData"
        @handleApplyFilter="handleApplyFilter"
        @handleCloseFilter="handleCloseFilter"
      ></template-filter>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import TemplateFilter from '@/components/templates/TemplateListActions/TemplateFilter.vue';

  export default {
    name: 'TemplateListActions',
    components: {
      MultiSelect,
      TemplateFilter,
    },
    data() {
      return {
        isFilterOpen: false,
        currentSortValue: null,
        sortOptions: [
          {
            name: 'dateDesc',
            value: this.$t('templates_filter.template_sort_dateDesc'),
            isDefault: true,
          },
          {
            name: 'dateAsc',
            value: this.$t('templates_filter.template_sort_dateAsc'),
            isDefault: false,
          },
          {
            name: 'popularityDesc',
            value: this.$t('templates_filter.template_sort_popularityDesc'),
            isDefault: false,
          },
        ],
      };
    },
    computed: {
      ...mapGetters('meta', ['templateSearch']),
      ...mapGetters('questionnaire', ['questionnaireFilterData']),
      ...mapGetters('reflections', ['selectedTemplateSort']),
      defaultSortValue() {
        return this.sortOptions.find((option) => option.name === this.selectedTemplateSort);
      },
    },
    methods: {
      ...mapActions('meta', ['setTemplateSearch']),
      handleTemplateSearch(value) {
        this.setTemplateSearch(value);
        this.$emit('handleTemplateSearch', value);
      },
      toggleFilter() {
        this.isFilterOpen = !this.isFilterOpen;
      },
      handleApplyFilter(value) {
        this.$emit('handleApplyFilter', value);
      },
      handleCloseFilter() {
        this.$emit('handleApplyFilter', {});
        this.toggleFilter();
      },
      handleSortSelect(selectedSortValue) {
        const query = {
          orderBy: selectedSortValue.name,
        };
        this.$emit('handleApplySort', query);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .template-actions-wrapper {
    margin-top: 20px;
    width: 940px;
    .template-actions-wrapper-inner {
      flex-direction: row;
      justify-content: space-between;
      height: 28px;
    }
    .template__search {
      display: flex;
      align-items: center;
      &__search-bar {
        position: relative;
        background-color: #fff;
        input {
          padding: 10px;
          height: 28px;
          width: 239px;
          border: none;
          &::placeholder {
            color: $grey-2;
          }
        }
        svg {
          position: absolute;
          top: 20%;
          right: 4%;
          cursor: pointer;
        }
      }
    }
    .filter-switch-wrapper {
      margin-left: 5px;
      .filter-switch {
        width: 130px;
        height: 28px;
        justify-content: space-between;
        padding: 0 10px;
        border: 1px solid $grey-2;
        background: #fff;
        cursor: pointer;
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: $violet-3;
        }
        .arrow-icon {
          transform: rotate(-90deg);
          transition: all 0.2s;
        }

        &.active {
          .arrow-icon {
            transform: rotate(90deg);
          }
        }
      }
    }
    .filter-body-wrapper {
      margin-top: 10px;
    }
    .sort-wrapper {
      span {
        margin-right: 10px;
        font-size: 14px;
        line-height: 16px;
        color: #000;
      }
      .template-sort-select {
        min-height: 28px;
        height: 28px;
        width: 200px;
      }
    }
  }
</style>
