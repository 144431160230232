/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock-green': {
    width: 16,
    height: 16,
    viewBox: '0 0 16.42 16.42',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="none" _stroke="green" stroke-miterlimit="10"><circle pid="0" cx="8.21" cy="8.21" r="7.71"/><path pid="1" stroke-linecap="round" d="M8.21 3.18v5.03M10.37 10.87L8.21 8.21"/></g></g>'
  }
})
