/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete-question': {
    width: 19,
    height: 24,
    viewBox: '0 0 19 24',
    data: '<path pid="0" d="M15.682 23H3.514L1.382 7.231h16.433l-2.133 15.77z" _stroke="#919396" stroke-linejoin="round"/><path pid="1" d="M1.397 3.69h16.434M6.878 10.626l.639 8.98M12.317 10.626l-.639 8.98" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round"/><rect pid="2" x="6.116" y="1" width="6.996" height="2.691" rx=".504" _stroke="#919396" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
