<template>
  <div
    v-if="!loading"
    :class="['stat-components', { 'stat-components--tabs': currentView === 'tabs' }]"
  >
    <stat-components-header
      :questionTitle="question.title"
      :answerTemplate="question.answerTemplate"
      :questionMethod="questionMethod"
      :question="question.parts[0]"
      :currentView="currentView"
      :answerExpanded="componentExpanded"
      :answerType="answerType"
      @expandAnswer="expandComponent"
    ></stat-components-header>
    <div
      class="answer-component__container"
      v-if="answerType === 'group' && areGroupStatPresent && memberCount"
    >
      <stat-components-group
        v-if="!groupStatQuestion.parts"
        :question="question"
        :questionMethod="questionMethod"
        :groupStatQuestion="groupStatQuestion"
        :groupIndividualStats="groupIndividualStats"
        :key="`part--${question.id}`"
      ></stat-components-group>
      <stat-components-group
        v-else
        :question="question"
        :questionMethod="questionMethod"
        :groupIndividualStats="groupIndividualStats"
        :groupStatQuestion="groupStatQuestion.parts[0]"
        :key="`part--0`"
      ></stat-components-group>
    </div>
    <div
      class="answer-component__container"
      v-else-if="answerType === 'inTime' && groupStatInTime && memberCount"
    >
      <stat-components-in-time
        v-if="!groupStatInTime.parts"
        :groupStatInTime="groupStatInTime"
        :question="question"
        :questionMethod="questionMethod"
        :key="`${question.id}`"
        :expanded="componentExpanded"
      ></stat-components-in-time>
      <stat-components-in-time
        v-else-if="groupStatInTime && groupStatInTime.parts"
        :key="`in-time-part-${question.id}-0`"
        :groupStatInTime="groupStatInTime.parts[0]"
        :question="question"
        :questionMethod="questionMethod"
        :expanded="componentExpanded"
      ></stat-components-in-time>
    </div>
    <div
      class="member-answer-component member-answer-component--individual"
      v-else-if="answerType === 'individual' && areIndividualStatPresent && memberCount"
    >
      <stat-components-individual
        v-if="!groupIndividualStats.parts"
        :groupIndividualStats="groupIndividualStats"
        :question="question"
        :questionMethod="questionMethod"
        :distinctAnswers="distinctAnswers"
      ></stat-components-individual>
      <stat-components-individual
        v-for="(part, index) in groupIndividualStats.parts"
        :key="`individual-part--${index}`"
        :groupIndividualStats="part"
        :question="question"
        :questionMethod="questionMethod"
        :distinctAnswers="distinctAnswers"
      ></stat-components-individual>
    </div>
    <div class="stat-components__answer-null" v-else>
      <p class="answer-null" v-if="memberCount < 1">
        {{ $t('messages.no_group_or_reflection_members_message') }}
      </p>
      <p class="answer-null" v-else>{{ $t('messages.no_data_message') }}</p>
    </div>
  </div>
  <loading v-else-if="loading"></loading>
</template>

<script>
  import { mapGetters } from 'vuex';

  import StatComponentsHeader from '@/components/stats/stat-components/StatComponentsHeader.vue';
  import StatComponentsGroup from '@/components/stats/group-stats/StatComponentsGroup.vue';
  import StatComponentsInTime from '@/components/stats/group-stats/StatComponentsInTime.vue';
  import StatComponentsIndividual from '@/components/stats/group-stats/StatComponentsIndividual.vue';

  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'StatComponents',
    components: {
      StatComponentsHeader,
      StatComponentsGroup,
      StatComponentsInTime,
      StatComponentsIndividual,
      Loading,
    },
    props: [
      'number',
      'expanded',
      'question',
      'groupStatQuestion',
      'groupStatInTime',
      'groupIndividualStats',
      'distinctAnswers',
      'loading',
      'memberCount',
    ],
    data() {
      return {
        componentExpanded: true,
        filterTerms: [],
      };
    },
    beforeDestroy() {
      this.componentExpanded = true;
    },
    watch: {
      expanded(newVal) {
        this.componentExpanded = newVal;
      },
    },
    computed: {
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('stats', ['currentView', 'answerType']),
      questionMethod() {
        return this.questionTypeList.find((method) => method.id === this.questionMethodId);
      },
      // this checks if question is old question method with select answer type
      questionMethodId() {
        return this.question.methodId === 4 && this.question.answerTemplate === 'select'
          ? 16
          : this.question.methodId;
      },
      areGroupStatPresent() {
        return (
          (this.groupStatQuestion && this.groupStatQuestion.length) ||
          (this.groupStatQuestion && this.groupStatQuestion.parts) ||
          false
        );
      },
      areIndividualStatPresent() {
        return (
          (this.groupIndividualStats && this.groupIndividualStats.length) ||
          (this.groupIndividualStats && this.groupIndividualStats.parts) ||
          false
        );
      },
    },
    methods: {
      expandComponent() {
        if (this.currentView === 'tabs') return;
        this.componentExpanded = !this.componentExpanded;
      },
    },
  };
</script>

<style lang="scss">
  .stat-components {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;

    .no-stats-message-wrapper {
      .no-stats-message {
        margin-top: 5rem;
        text-align: center;
        font-family: Roboto-Regular;
        font-size: 16px;
        font-weight: bold;
        color: $grey-4;
      }
    }

    &:not(:first-of-type) {
      margin-top: 3px;
    }

    &__answer-null {
      display: flex;
      height: 100%;
      .answer-null {
        margin: auto;
        font-family: Roboto-Regular;
        font-size: 16px;
        font-weight: bold;
        color: $grey-4;
      }
    }

    .answer-component {
      width: 100%;
      min-height: 280px;

      &__input {
        min-width: 190px;
        height: 28px;
        padding: 8px;
        border: 1px solid $grey-2;
        margin-left: 20px;

        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-5;

        &::placeholder {
          color: $grey-3;
        }
      }

      &--available {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--member {
        width: auto;
      }
    }

    .component-chart {
      margin-top: 40px;
      margin-left: 150px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__legend {
        display: flex;
        flex-direction: column;
        margin-left: 100px;
      }

      &__entry {
        display: flex;
        align-items: center;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        margin-left: 15px;
      }
      &__icon {
        height: 16px;
        width: 16px;
        border-radius: 50%;
      }

      &__value {
        margin-left: 10px;
      }

      &__chart {
        width: 200px;
        height: 200px;
      }
    }

    &--tabs {
      min-height: 250px;
    }
  }
</style>
