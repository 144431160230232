<template>
  <div class="methods__container">
    <method-card
      v-for="method in filteredMethods"
      :iconName="`methods/${method.template}`"
      :key="method.id"
      :method="method"
    >
      <template v-slot:name>{{ method.title.toLowerCase() }}</template>
      <template v-slot:time>{{ method.duration }}</template>
    </method-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import MethodCard from '@/components/methods/MethodCard.vue';

export default {
  components: {
    MethodCard,
  },
  created() {
    if (!this.filteredMethods.length) this.setFilteredMethods();
  },
  computed: {
    ...mapGetters('methods', ['filteredMethods', 'questionTypeList']),
  },
  methods: {
    ...mapActions('methods', ['setFilteredMethods']),
  },
};
</script>
<style lang="scss" scoped>
.methods__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
