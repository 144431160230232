<template>
  <div class="template__matrix">
    <div class="template__input-field" v-for="(option, j) in partOptions.options" :key="j">
      <span class="template__input-field__header">{{ option.show }}</span>
      <input
        :class="['template__input-text', 'template__input-text--light']"
        type="text"
        :disabled="!edit"
        v-model="option.value"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TemplateMatrix',
  props: ['type', 'number', 'method', 'part', 'partIndex', 'edit'],
  data() {
    return {
      i: 0,
    };
  },
  created() {
    if (!this.partOptions) {
      const defaults = JSON.parse(JSON.stringify(this.defaults));
      this.partOptions = defaults;
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questions']),
    question() {
      return this.questions[this.number];
    },
    options() {
      return this.question.answerOptions.options;
    },
    defaults() {
      return this.method.answerOptions.defaults;
    },
    partOptions() {
      return this.question.parts[this.partIndex].answerOptions || null;
    },
  },
};
</script>
<style lang="scss">
.template {
  &__matrix {
    .template__input-field {

    &:not(:first-of-type) {
      margin-top: 20px !important;
    }

    .template__input-text {
      height: 26px;
    }
  }
  }
}
</style>
