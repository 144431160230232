/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/paper': {
    width: 65.75,
    height: 65.75,
    viewBox: '0 0 65.75 65.75',
    data: '<g data-name="Group 1110" transform="translate(-639.625 -101.625)"><circle pid="0" data-name="Ellipse 2" cx="32.5" cy="32.5" r="32.5" transform="translate(640 102)" _fill="none" _stroke="#474eb9" stroke-width=".75"/><g data-name="Group 8"><g data-name="Group 7"><path pid="1" d="M689.327 150.384h-29.439a5.89 5.89 0 0 1-5.888-5.888v-23.1a4.566 4.566 0 0 1 4.416-3.394 4.41 4.41 0 0 1 4.408 3.4l.008 2.483h26.495zm-2.944-23.551h-23.551v16.191a3.348 3.348 0 0 0-2.829-1.472 3.046 3.046 0 0 0-3.059 2.945 2.946 2.946 0 0 0 2.944 2.944h26.495zm-4.163 16.648h-15.227v-12.689h15.227zm-8.77-12.689V141m5.667-4.812V141m-5.667-4.812h8.77" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" data-name="Group 6"/><path pid="2" data-name="Line 4" _fill="none" _stroke="#474eb9" stroke-linecap="round" stroke-linejoin="round" d="M662.832 123.887v3.578"/></g></g></g>'
  }
})
