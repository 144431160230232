/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emotes/emote-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 26.71 26.71',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><circle pid="0" cx="13.35" cy="13.35" r="13.35" _fill="#ffd053"/><path pid="1" d="M20.34 2A13.36 13.36 0 0 1 2.1 20.53 13.35 13.35 0 1 0 20.34 2z" _fill="#ffc448"/><path pid="2" d="M1.81 14.76A12.4 12.4 0 0 1 24 7.2 12.4 12.4 0 1 0 3.46 20.94a12.34 12.34 0 0 1-1.65-6.18z" _fill="#ffd77a"/><path pid="3" d="M16.17 18.72a4.24 4.24 0 0 0-2.82-1 4.46 4.46 0 0 0-2.8 1 3.93 3.93 0 0 0-.94 1.16 3.17 3.17 0 0 0-.37 1.4.26.26 0 0 0 .46.16 5.88 5.88 0 0 1 .8-.87 4.53 4.53 0 0 1 .9-.57 4.46 4.46 0 0 1 2-.47 4.09 4.09 0 0 1 1.94.46A5 5 0 0 1 17 21.45a.26.26 0 0 0 .21.1h.08a.26.26 0 0 0 .17-.25 3.55 3.55 0 0 0-1.29-2.58z" _fill="#654735"/><path pid="4" d="M23 15.52l-.52-.15c-.36-.1-.7-.2-1-.31a9.2 9.2 0 0 1-1.36-.58 8.09 8.09 0 0 1-1.23-.8 12.15 12.15 0 0 1-1.17-1.07.13.13 0 0 0-.22.1 3.15 3.15 0 0 0 .65 1.69 4.11 4.11 0 0 0 1.39 1.19 4.34 4.34 0 0 0 1.68.48h.31a3.53 3.53 0 0 0 1.47-.31.13.13 0 0 0 0-.24zm0 .06zM9.2 12.58a.13.13 0 0 0-.14 0 12.14 12.14 0 0 1-1.17 1.07 8.07 8.07 0 0 1-1.23.8 9.19 9.19 0 0 1-1.36.58c-.33.11-.67.21-1 .31l-.51.15a.13.13 0 0 0 0 .24 3.53 3.53 0 0 0 1.52.32h.31a4.34 4.34 0 0 0 1.68-.48 4.11 4.11 0 0 0 1.33-1.17 3.15 3.15 0 0 0 .65-1.69.13.13 0 0 0-.08-.13z" _fill="#2c2c2c"/></g></g>'
  }
})
