/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile/logo': {
    width: 15.422,
    height: 15.422,
    viewBox: '0 0 15.422 15.422',
    data: '<g data-name="Group 1113" transform="translate(-751.868 -134.167)"><circle pid="0" data-name="Ellipse 55" cx="7.711" cy="7.711" r="7.711" transform="translate(751.868 134.168)" _fill="#f0f0f0"/><g data-name="Group 1003"><path pid="1" data-name="Path 19" d="M762.923 144.245l-.764-1.406a2.616 2.616 0 0 0 .39-.435 3.021 3.021 0 0 0 .5-1.78 2.665 2.665 0 0 0-1.44-2.545.492.492 0 0 0-.078-.045 3.255 3.255 0 0 0-1.434-.324 2.713 2.713 0 0 0-.469.039h-.012a2.009 2.009 0 0 0-.6.2 1.687 1.687 0 0 0-.759-.173 1.787 1.787 0 0 0-.949.257 1.272 1.272 0 0 0-.6 1.077v5.977a1.256 1.256 0 0 0 .664 1.111 1.894 1.894 0 0 0 1.814-.005 1.254 1.254 0 0 0 .647-1.083l.285.558a1.41 1.41 0 0 0 1.273.8 1.762 1.762 0 0 0 1.127-.424 1.477 1.477 0 0 0 .586-1.161 1.212 1.212 0 0 0-.181-.638zm-4.051.843a.308.308 0 0 1-.173.279.959.959 0 0 1-.854 0 .314.314 0 0 1-.179-.279v-1.637a1.14 1.14 0 0 0 .592.15 1.163 1.163 0 0 0 .614-.167zm0-3.957v1.686a.346.346 0 0 1-.184.251.759.759 0 0 1-.43.123.718.718 0 0 1-.425-.117.331.331 0 0 1-.167-.268v-3.695a.309.309 0 0 1 .162-.268.77.77 0 0 1 .43-.106.644.644 0 0 1 .385.106.312.312 0 0 1 .156.274v.39a1.5 1.5 0 0 1 .469-.58 1.139 1.139 0 0 1 .664-.246h.022a3.391 3.391 0 0 1 .419.028.47.47 0 0 1 .173.134.559.559 0 0 1 .14.368.575.575 0 0 1-.14.379.387.387 0 0 1-.307.156h-.285a.969.969 0 0 0-.759.385 1.523 1.523 0 0 0-.327 1zm3.03 4.185a.8.8 0 0 1-.513.2.466.466 0 0 1-.419-.29l-1.317-2.561h-.374v-.949h.614a.873.873 0 0 0 .993-.982 1.05 1.05 0 0 0-.218-.714.765.765 0 0 0 .19-.173.966.966 0 0 0 .229-.636.936.936 0 0 0-.067-.351 1.684 1.684 0 0 1 1.071 1.763 2.033 2.033 0 0 1-.329 1.222 1.612 1.612 0 0 1-.888.637l1.216 2.255a.222.222 0 0 1 .051.15.58.58 0 0 1-.242.429z" _fill="#bababa"/></g></g>'
  }
})
