/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/indicator': {
    width: 33.118,
    height: 33.118,
    viewBox: '0 0 33.118 33.118',
    data: '<g data-name="Group 3829"><g data-name="Group 2629" transform="translate(80.468 -423.081)"><circle pid="0" data-name="Ellipse 273" cx="16.559" cy="16.559" r="16.559" transform="translate(-80.468 423.081)" _fill="#6666ca"/><g data-name="Group 2628"><path pid="1" data-name="Path 2713" d="M-52.614 439.215h-9.44l8.2-4.74a11.01 11.01 0 0 1 1.24 4.74z" _fill="#fff"/><path pid="2" data-name="Path 2714" d="M-63.907 439.637z" _fill="#fff"/><path pid="3" data-name="Path 2716" d="M-52.614 440.215a11.09 11.09 0 0 1-1.28 4.67l-8.1-4.67z" _fill="#fff"/><path pid="4" data-name="Path 2717" d="M-75.2 440.215h9.42l-8.13 4.69a11.083 11.083 0 0 1-1.29-4.69z" _fill="#fff"/><path pid="5" data-name="Path 2718" d="M-73.4 445.775l8.22-4.75-4.73 8.19a11.453 11.453 0 0 1-3.49-3.44z" _fill="#fff"/><path pid="6" data-name="Path 2719" d="M-54.344 433.615l-8.1 4.67 4.69-8.13a11.149 11.149 0 0 1 3.41 3.46z" _fill="#fff"/><path pid="7" data-name="Path 2720" d="M-54.4 445.755a11.063 11.063 0 0 1-3.47 3.44l-4.72-8.17z" _fill="#fff"/><path pid="8" data-name="Path 2721" d="M-69.174 429.635a11.06 11.06 0 0 1 4.75-1.29v9.51z" _fill="#fff"/><path pid="9" data-name="Path 2722" d="M-65.724 439.215H-75.2a10.886 10.886 0 0 1 1.25-4.75z" _fill="#fff"/><path pid="10" data-name="Path 2723" d="M-58.614 429.645l-4.81 8.33v-9.63a11.051 11.051 0 0 1 4.81 1.3z" _fill="#fff"/><path pid="11" data-name="Path 2724" d="M-70.034 430.145l4.71 8.15-8.14-4.7a11.4 11.4 0 0 1 3.43-3.45z" _fill="#fff"/><circle pid="12" data-name="Ellipse 1143" cx="6.841" cy="6.841" r="6.841" transform="translate(-70.748 432.796)" _fill="#6666ca"/><circle pid="13" data-name="Ellipse 1144" cx="1.913" cy="1.913" r="1.913" transform="translate(-65.82 437.724)" _fill="#fff"/><path pid="14" data-name="Path 2725" d="M-67.208 436.409l1.877 3.3a.165.165 0 0 0 .26.035l1.418-1.419a.165.165 0 0 0-.035-.26l-3.3-1.877a.165.165 0 0 0-.22.221z" _fill="#fff"/></g></g></g>'
  }
})
