/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pen': {
    width: 22.209,
    height: 22.209,
    viewBox: '0 0 22.209 22.209',
    data: '<g data-name="Group 2052"><path pid="0" data-name="Path 1183" d="M.75 21.459l5.979-1.6 14.73-14.734L17.082.748 2.353 15.477z" _fill="none" _stroke="#3b328b" stroke-linejoin="round" stroke-width="1.5"/></g>'
  }
})
