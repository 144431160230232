<template>
  <h4>
    <slot/>
  </h4>
</template>
<script>
export default {
  name: 'HeaderH4',
};
</script>
<style lang="scss" scoped>
h4 {
  margin: 0;
  padding: 0;
  font-family: Roboto-Medium;
  font-size: 14px;
  color: $grey-5;
  font-weight: inherit;
}
</style>
