<template>
  <div :class="['stat-tab', { 'stat-tab--active': active }]">
    <div class="stat-tab__number">{{ number + 1 }}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StatTab',
  props: ['number'],
  computed: {
    ...mapGetters('stats', ['currentTab']),
    active() {
      return this.number === this.currentTab;
    },
  },
};
</script>
<style lang="scss">
.stat-tab {
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-family: Nunito-SemiBold;
  font-size: 12px;
  color: $grey-4;
  position: relative;

  &--active {
    background-color: #fff;
    color: $grey-5;
    z-index: 3;
    border-bottom: #fff;

    &::before,
    &::after {
      content: '';
      background: #fff;
      width: 12px;
      height: 12px;
      z-index: 1;
    }

    &::before {
      left: -10px;
    }
    &::after {
      right: -10px;
    }

    .stat-tab {
      &__number {
        &::before,
        &::after {
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: $grey-6;
          z-index: 2;
          bottom: 3px;
        }

        &::before {
          left: -14px;
        }

        &::after {
          right: -14px;
        }
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
  }

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  &__number {
    padding: 6px 10px;

    &::before,
    &::after {
      position: absolute;
    }

    &:hover {
      cursor: pointer;
    }

    &--add {
      margin-left: 10px;
      margin-top: -5px;
    }

    &--error {
      color: $red-4;
      font-family: Nunito-Bold;
    }

    &__icon {
      height: 18px;
      width: 18px;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-3px) scale(1.25);
      }
    }
  }
}
</style>
