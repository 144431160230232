/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'methods/question': {
    width: 33.405,
    height: 33.406,
    viewBox: '0 0 33.405 33.406',
    data: '<g data-name="Group 3833"><path pid="0" data-name="Path 3688" d="M16.557.144A16.559 16.559 0 0 1 33.26 16.558c.032 3.686-3.339 10.119-3.339 10.119s-7.615 6.537-13.075 6.584A16.559 16.559 0 0 1 16.557.144z" _fill="#6666ca"/><path pid="1" data-name="Path 944" d="M19.394 5.79a11.269 11.269 0 0 0-13.6 8.22 11.238 11.238 0 0 0 21.82 5.39 11.194 11.194 0 0 0-2.361-9.978 11 11 0 0 0-5.859-3.632zm-3.03 19.69a1.725 1.725 0 1 1 1.71-1.72 1.728 1.728 0 0 1-1.71 1.72zm4.42-10.21a11.253 11.253 0 0 1-1.4 1.55l-.05.05c-.68.68-1.7 1.72-1.7 2.26a1.285 1.285 0 0 1-2.57.02c-.01-1.64 1.39-3.03 2.52-4.15l.25-.26a11.065 11.065 0 0 0 .87-.97 1.785 1.785 0 0 0 0-1.62 2.034 2.034 0 0 0-1.99-.97c-2.15 0-2.21 1.69-2.21 1.88a1.3 1.3 0 0 1-1.3 1.27 1.284 1.284 0 0 1-1.27-1.3 4.88 4.88 0 0 1 9.05-2.04 4.213 4.213 0 0 1-.2 4.28z" _fill="#fff"/></g>'
  }
})
